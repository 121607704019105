import { Middleware } from '@reduxjs/toolkit'
import { gqlClient } from 'gql'
import lodash from 'lodash'

import { EmployeePreferencesSchemaFragment } from 'gql/__generated__/graphql'
import { projectEmployeePreferences } from 'gql/projects.gql'

import { isAction } from '../actionTypeGuard'
import { IProjectContext } from '../types'

const updateEmployeePreferences = lodash.debounce(
  (data: { projectId: string; settings: EmployeePreferencesSchemaFragment }) => {
    const { projectId, settings } = data

    gqlClient.core.mutate({
      mutation: projectEmployeePreferences,
      variables: { projectId, settings },
    })
  },
  1000,
)

export const employeePreferences: Middleware = (store) => (next) => (action) => {
  const result = next(action)
  if (isAction(action, 'setEmployeeToProjectPreferences')) {
    const state = store.getState().project as IProjectContext
    updateEmployeePreferences({ projectId: state.data.project.id, settings: action.payload })
  }
  return result
}
