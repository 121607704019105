import { DeviceMode } from '@leenda/editor'
import { TestHotspotSchemaType } from '@leenda/editor/lib/brand'
import cn from 'classnames'
import React from 'react'

import Icon from 'components/uiKit/Icon'
import { ElementStyleCss } from 'services/Branding/types'

import { ISpot } from '../../TestHotspotElement.types'
import { ReactComponent as Check } from '../../assets/check.svg'
import FeedbackIcon from '../FeedbackIcon'
import s from './Spot.module.scss'

interface ISpotProps {
  spot: ISpot
  deviceMode: DeviceMode
  result?: any | null
  hasIcon?: boolean
  styles: ElementStyleCss<TestHotspotSchemaType>
  onDeleteSpot?: (id: string) => void
  active?: boolean
  setActiveSpot?: (id: string | null) => void
  enableDelete?: boolean
}

const Spot: React.FC<ISpotProps> = ({
  spot,
  deviceMode,
  result,
  hasIcon,
  styles,
  onDeleteSpot,
  setActiveSpot,
  active,
  enableDelete,
}) => {
  const { x, y } = spot
  const isTrueAnswer = spot.items.length > 0

  const spotStylesObj = {
    correct: styles.correct,
    incorrect: styles.incorrect,
    default: styles.spot,
  }
  const spotStyles = result
    ? isTrueAnswer
      ? spotStylesObj.correct
      : spotStylesObj.incorrect
    : spotStylesObj.default

  let iconContent
  if (result) {
    iconContent = <FeedbackIcon deviceMode={deviceMode} isTrueAnswer={isTrueAnswer} />
  } else {
    iconContent = hasIcon ? <Check /> : <div />
  }
  const isAnimated = styles.animation.pulse
  const ringletWidth =
    (typeof styles.spot.width === 'string' ? parseInt(styles.spot.width) : styles.spot.width || 0) +
    8
  const ringletHeight =
    (typeof styles.spot.height === 'string'
      ? parseInt(styles.spot.height)
      : styles.spot.height || 0) + 8

  const onDelete = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    e.preventDefault()
    onDeleteSpot && onDeleteSpot(spot.id)
  }

  return (
    <div
      className={s.root}
      style={{
        left: `${x}%`,
        top: `${y}%`,
      }}
    >
      <div className={cn(s.ringWrapper, { [s.enableDelete]: enableDelete })}>
        <div className={s.rings}>
          {isAnimated && !result && (
            <>
              <div
                className={s.ringlet}
                style={{
                  width: ringletWidth,
                  height: ringletHeight,
                  background: styles.spot.color,
                }}
              />
              <div
                className={s.ringlet}
                style={{
                  width: ringletWidth,
                  height: ringletHeight,
                }}
              />
            </>
          )}
        </div>
        <div
          className={s.spot}
          onMouseDown={(e) => enableDelete && onDelete(e)}
          onMouseLeave={() => setActiveSpot && setActiveSpot(null)}
          style={{ ...spotStyles, width: styles.spot.width, height: styles.spot.height }}
        >
          {iconContent}
          {!active && (
            <div className={cn(s.deleteSpot)}>
              <Icon name='otherClose' />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Spot
