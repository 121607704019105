"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const constants_1 = require("../../../elements/elements/CourseStructure/constants");
const font_1 = require("../../font");
const theme_1 = require("../../theme");
const genStyle_1 = require("../genStyle");
const styleSchema = {
    icon: {
        color: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.primaryColor }),
    },
    progress: {
        backgroundColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.primaryBackgroundColor }),
        color: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.primaryColor }),
        borderColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.borderColor }),
        borderStyle: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.borderStyle }),
        borderWidth: (0, genStyle_1.genStyle)({ value: 1 }),
    },
    offsetFromIcon__var: {
        marginRight: (0, genStyle_1.genStyle)({ value: 8 }),
    },
    offsetFromSection__var: {
        marginTop: (0, genStyle_1.genStyle)({ value: 12 }),
    },
    offsetFromProgress__var: {
        marginRight: (0, genStyle_1.genStyle)({ value: 16 }),
    },
    offsetFromCollapse__var: {
        marginRight: (0, genStyle_1.genStyle)({ value: 8 }),
    },
};
const fontSchema = {
    chapter: Object.assign(Object.assign({}, font_1.FONT_SCHEMA_EMPTY.base), { fontSize: (0, genStyle_1.genStyle)({ value: 16 }), color: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.secondaryTextColor }), marginTop: (0, genStyle_1.genStyle)({ value: 0 }) }),
    section: Object.assign(Object.assign({}, font_1.FONT_SCHEMA_EMPTY.base), { fontSize: (0, genStyle_1.genStyle)({ value: 14 }), marginTop: (0, genStyle_1.genStyle)({ value: 0 }) }),
    description: Object.assign(Object.assign({}, font_1.FONT_SCHEMA_EMPTY.base), { fontSize: (0, genStyle_1.genStyle)({ value: 14 }) }),
};
exports.default = { [constants_1.TYPE]: { style: styleSchema, font: fontSchema } };
