import cn from 'classnames'
import React from 'react'
import { useContextSelector } from 'use-context-selector'

import MasonryTemplate from 'components/editor-v3/Editor/Modals/TemplatePickerModal/MasonryTemplate'
import Empty from 'components/uiKit/Empty'
import InfiniteLoadGrid from 'components/uiKit/InfiniteLoadGrid'
import { EditorTemplatesAndCollectionsQuery } from 'gql/__generated__/graphql'
import TemplateCard from 'routes/routes/App/routes/Company/routes/Templates/components/TemplateCard'
import { t } from 'services/Translation'

import { IItemProps } from '../MasonryTemplate/MasonryTemplate'
import { TemplatePickerContext } from '../hooks'
import { TemplatePickerMode } from '../types'
import s from './CollectionsList.module.scss'

const GROUP_TITLE = {
  templateCollections: t('modal.createTemplate.collections.title'),
  templates: t('modal.createTemplate.templates.title'),
}

interface ICollectionsListProps {
  templateProps: IItemProps
  data?: EditorTemplatesAndCollectionsQuery
  loading: boolean
}

const CollectionsList: React.FC<ICollectionsListProps> = ({ templateProps, data, loading }) => {
  const { companyId, mode, search, setCollection, showBrand } = useContextSelector(
    TemplatePickerContext,
    (value) => value,
  )
  const collections = data?.data.templateCollections
  const templates = data?.data.templates || []
  const isEmpty = !collections?.length && !templates.length && !loading

  return (
    <div className={s.root}>
      <div
        className={cn(s.collections, {
          [s.grow]: loading,
          [s.empty]: !collections?.length,
        })}
      >
        {search && !!collections?.length && (
          <div className={s.groupSearch}>{GROUP_TITLE.templateCollections}</div>
        )}
        <InfiniteLoadGrid
          grid={{ width: '308px' }}
          loading={loading}
          name='collectionCard'
          padding='10px'
        >
          {collections?.map((item) => (
            <TemplateCard
              color={item.color}
              companyId={companyId}
              item={item}
              key={item.id}
              onClick={setCollection}
              search={search}
              showBrand={showBrand}
            />
          ))}
        </InfiniteLoadGrid>
      </div>
      {search && (
        <div>
          {!!templates.length && <div className={s.groupSearch}>{GROUP_TITLE.templates}</div>}
          <MasonryTemplate
            itemProps={templateProps}
            items={templates}
            mode={mode || TemplatePickerMode.multi}
            showFavorite
          />
        </div>
      )}
      {isEmpty && (
        <Empty search={Boolean(search)} text={t('modal.pickerTemplate.nothingFound')} grow />
      )}
    </div>
  )
}

export default React.memo(CollectionsList)
