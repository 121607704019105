import { t } from 'services/Translation'

import s from './EmptyDemo.module.scss'
import icon from './empty.svg'

const EmptyDemo = ({ name }: { name: string }) => {
  return (
    <div className={s.root}>
      <img src={icon} />
      <div>{t('elements.emptyDemo', { name })}</div>
    </div>
  )
}
export default EmptyDemo
