import { AbsoluteNode, CommonNode, LayoutNode, ShellNode } from './data.types'
import { NodeType } from './date.enums'

export const isCommonNode = (node: LayoutNode | null): node is CommonNode =>
  Boolean(node && [NodeType.row, NodeType.column, NodeType.absolute].includes(node.type))

export const isShellNode = (node: LayoutNode | null): node is ShellNode =>
  Boolean(node && NodeType.shell === node.type)

export const isAbsoluteNode = (node: LayoutNode | null): node is AbsoluteNode =>
  Boolean(node && NodeType.absolute === node.type)
