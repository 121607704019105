import cn from 'classnames'
import React from 'react'

import { testProps } from 'utils/test/qaData'

import s from './Badge.module.scss'

interface IBadgeProps {
  overflowCount?: number
  count?: number | string
  color?: string
  offset?: [number, number]
  className?: string
  placement?: 'left' | 'right'
  size?: 'mini' | 'medium'
  styleType?: 'default' | 'dot'
  children?: React.ReactNode
}

const DEFAULT_OVERFLOW_COUNT = 99

const Badge: React.FC<IBadgeProps> = ({
  children,
  count,
  overflowCount = DEFAULT_OVERFLOW_COUNT,
  color,
  offset = [0, 0],
  placement = 'right',
  className,
  styleType = 'default',
  size = 'medium',
  ...rest
}) => {
  const [top, right] = offset || []
  const countNumber = count && Number(count) > overflowCount ? `${overflowCount}+` : count

  const cnBadge = cn(s.badge, s[styleType], s[size], s[placement])
  const cnCount = cn(s.count, s[size])

  return (
    <div className={s.root} {...rest}>
      {children}
      {!!countNumber && (
        <div
          className={cnBadge}
          style={{ top, [placement]: right, background: color }}
          {...testProps({
            el: 'badge',
            count: countNumber,
          })}
        >
          {overflowCount && <span className={cnCount}>{countNumber}</span>}
        </div>
      )}
    </div>
  )
}

export default React.memo(Badge)
