import { useCallback, useState } from 'react'

import { AppMode, PreviewMode } from 'services/Store/Project/enums'
import { useProjectContext } from 'services/Store/Project/hooks'
import { getIsActiveElement } from 'services/Store/Project/selectors'
import { IBlockMode } from 'services/Store/Project/types'

export const useGetRichTextProps = ({
  elementId,
  mode,
}: {
  elementId: string
  mode: IBlockMode
}) => {
  const isActiveElement = useProjectContext(getIsActiveElement, elementId)
  const [cursorPosition, setCursorPosition] = useState<'start' | 'end'>('start')
  const [activeIndex, setActiveIndex] = useState<string>('')
  const isFill = mode.previewMode === PreviewMode.editor && mode.editorMode === AppMode.fill

  const onLabelSelect = useCallback(
    (newIndex: string) => {
      setActiveIndex(newIndex)
      if (newIndex < activeIndex) {
        setCursorPosition('end')
      } else {
        setCursorPosition('start')
      }
    },
    [activeIndex],
  )

  return {
    isActiveElement,
    activeIndex,
    onLabelSelect,
    isFill,
    cursorPosition,
  }
}
