"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PROJECT_ROLE_PERMISSIONS_GROUPS = exports.COMPANY_ROLE_PERMISSIONS_GROUPS = exports.PROJECT_ROLE_PERMISSIONS = exports.COMMENT_ROLE_PERMISSIONS = exports.TASK_ROLE_PERMISSIONS = exports.COMPANY_ROLE_PERMISSIONS = exports.SYSTEM_ROLE_PERMISSIONS = exports.PERMISSIONS_BY_GROUP = void 0;
const permissions_1 = require("./permissions");
const enums_1 = require("./enums");
const ALL_COMPANY_PERMISSIONS = Object.values(permissions_1.PermissionsKey).filter((key) => key.startsWith('company_'));
const ALL_PROJECT_PERMISSIONS = Object.values(permissions_1.PermissionsKey).filter((key) => key.startsWith('project_'));
const exclude = (permissions, exclude) => {
    return permissions.filter((permission) => !exclude.includes(permission));
};
exports.PERMISSIONS_BY_GROUP = {
    [enums_1.PermissionGroup.company_manage]: [
        permissions_1.PermissionsKey.company_pages_employees,
        permissions_1.PermissionsKey.company_pages_settings,
        permissions_1.PermissionsKey.company_employees_manage_employees,
        permissions_1.PermissionsKey.company_employees_list_base_r,
        permissions_1.PermissionsKey.company_employees_list_base_u,
        permissions_1.PermissionsKey.company_employees_list_profile_r,
        permissions_1.PermissionsKey.company_employees_list_companyInfo_r,
        permissions_1.PermissionsKey.company_employees_list_companyInfo_u,
        permissions_1.PermissionsKey.company_employees_self_companyInfo_r,
        permissions_1.PermissionsKey.company_employees_self_companyInfo_u,
    ],
    [enums_1.PermissionGroup.company_create]: [
        permissions_1.PermissionsKey.company_pages_dashboard,
        permissions_1.PermissionsKey.company_pages_catalog,
        permissions_1.PermissionsKey.company_pages_tasks,
        permissions_1.PermissionsKey.company_pages_tags,
        permissions_1.PermissionsKey.company_employees_list_base_r,
        permissions_1.PermissionsKey.company_catalog_c,
        permissions_1.PermissionsKey.company_catalog_r,
        permissions_1.PermissionsKey.company_catalog_u,
        permissions_1.PermissionsKey.company_catalog_d,
        permissions_1.PermissionsKey.company_tags_c,
        permissions_1.PermissionsKey.company_tags_r,
        permissions_1.PermissionsKey.company_tags_u,
        permissions_1.PermissionsKey.company_tags_d,
        permissions_1.PermissionsKey.project_c,
        permissions_1.PermissionsKey.project_team_manage_owner,
    ],
    [enums_1.PermissionGroup.company_style]: [
        permissions_1.PermissionsKey.company_pages_style,
        permissions_1.PermissionsKey.company_pages_collection,
        permissions_1.PermissionsKey.company_pages_fonts,
        permissions_1.PermissionsKey.company_pages_resource,
        permissions_1.PermissionsKey.company_resource_c,
        permissions_1.PermissionsKey.company_resource_u,
        permissions_1.PermissionsKey.company_resource_d,
        permissions_1.PermissionsKey.company_collections_self,
        permissions_1.PermissionsKey.company_collections_company_c,
        permissions_1.PermissionsKey.company_collections_company_r,
        permissions_1.PermissionsKey.company_collections_company_u,
        permissions_1.PermissionsKey.company_collections_company_d,
        permissions_1.PermissionsKey.company_style_c,
        permissions_1.PermissionsKey.company_style_r,
        permissions_1.PermissionsKey.company_style_u,
        permissions_1.PermissionsKey.company_style_d,
        permissions_1.PermissionsKey.company_fonts_c,
        permissions_1.PermissionsKey.company_fonts_r,
        permissions_1.PermissionsKey.company_fonts_u,
        permissions_1.PermissionsKey.company_fonts_d,
        permissions_1.PermissionsKey.project_settings_style_u,
        permissions_1.PermissionsKey.project_settings_collections_u,
        permissions_1.PermissionsKey.project_style_c,
        permissions_1.PermissionsKey.project_style_r,
        permissions_1.PermissionsKey.project_style_u,
        permissions_1.PermissionsKey.project_style_d,
    ],
    [enums_1.PermissionGroup.project_manage]: [
        permissions_1.PermissionsKey.project_pages_home,
        permissions_1.PermissionsKey.project_settings_general_u,
        permissions_1.PermissionsKey.project_settings_tags_u,
        permissions_1.PermissionsKey.project_settings_localization_u,
        permissions_1.PermissionsKey.project_sections_c,
        permissions_1.PermissionsKey.project_sections_u,
        permissions_1.PermissionsKey.project_sections_d,
        permissions_1.PermissionsKey.project_team_list_c,
        permissions_1.PermissionsKey.project_team_list_r,
        permissions_1.PermissionsKey.project_team_list_u,
        permissions_1.PermissionsKey.project_team_list_d,
        permissions_1.PermissionsKey.project_comments_c,
        permissions_1.PermissionsKey.project_comments_r,
        permissions_1.PermissionsKey.project_comments_resolve,
        permissions_1.PermissionsKey.project_tasks_c,
        permissions_1.PermissionsKey.project_tasks_r,
        permissions_1.PermissionsKey.project_tasks_d,
        permissions_1.PermissionsKey.project_tasks_deadline,
        permissions_1.PermissionsKey.project_tasks_assign,
        permissions_1.PermissionsKey.project_tasks_comment,
        permissions_1.PermissionsKey.project_publicLink_r,
        permissions_1.PermissionsKey.project_publicLink_r,
    ],
    [enums_1.PermissionGroup.project_pro]: [
        permissions_1.PermissionsKey.project_pages_home,
        permissions_1.PermissionsKey.project_pages_editor,
        permissions_1.PermissionsKey.project_pages_pro,
        permissions_1.PermissionsKey.project_blocks_c,
        permissions_1.PermissionsKey.project_blocks_u,
        permissions_1.PermissionsKey.project_blocks_d,
        permissions_1.PermissionsKey.project_blocks_close,
        permissions_1.PermissionsKey.project_blocks_hide,
        permissions_1.PermissionsKey.project_settings_tags_u,
        permissions_1.PermissionsKey.project_settings_localization_u,
        permissions_1.PermissionsKey.project_sections_c,
        permissions_1.PermissionsKey.project_sections_u,
        permissions_1.PermissionsKey.project_sections_d,
        permissions_1.PermissionsKey.project_sections_close,
        permissions_1.PermissionsKey.project_team_list_r,
        permissions_1.PermissionsKey.project_comments_r,
        permissions_1.PermissionsKey.project_collection_c,
        permissions_1.PermissionsKey.project_collection_r,
        permissions_1.PermissionsKey.project_collection_u,
        permissions_1.PermissionsKey.project_collection_d,
        permissions_1.PermissionsKey.project_tasks_c,
        permissions_1.PermissionsKey.project_tasks_r,
        permissions_1.PermissionsKey.project_style_c,
        permissions_1.PermissionsKey.project_style_u,
        permissions_1.PermissionsKey.project_style_d,
        permissions_1.PermissionsKey.project_resource_c,
        permissions_1.PermissionsKey.project_resource_u,
        permissions_1.PermissionsKey.project_resource_d,
        permissions_1.PermissionsKey.project_publicLink_r,
    ],
    [enums_1.PermissionGroup.project_content]: [
        permissions_1.PermissionsKey.project_pages_home,
        permissions_1.PermissionsKey.project_pages_editor,
        permissions_1.PermissionsKey.project_blocks_c,
        permissions_1.PermissionsKey.project_blocks_u,
        permissions_1.PermissionsKey.project_blocks_d,
        permissions_1.PermissionsKey.project_blocks_close,
        permissions_1.PermissionsKey.project_blocks_hide,
        permissions_1.PermissionsKey.project_settings_tags_u,
        permissions_1.PermissionsKey.project_settings_localization_u,
        permissions_1.PermissionsKey.project_sections_c,
        permissions_1.PermissionsKey.project_sections_u,
        permissions_1.PermissionsKey.project_sections_d,
        permissions_1.PermissionsKey.project_sections_close,
        permissions_1.PermissionsKey.project_team_list_r,
        permissions_1.PermissionsKey.project_comments_r,
        permissions_1.PermissionsKey.project_collection_c,
        permissions_1.PermissionsKey.project_collection_r,
        permissions_1.PermissionsKey.project_collection_u,
        permissions_1.PermissionsKey.project_collection_d,
        permissions_1.PermissionsKey.project_tasks_c,
        permissions_1.PermissionsKey.project_tasks_r,
        permissions_1.PermissionsKey.project_resource_c,
        permissions_1.PermissionsKey.project_resource_u,
        permissions_1.PermissionsKey.project_resource_d,
        permissions_1.PermissionsKey.project_publicLink_r,
    ],
    [enums_1.PermissionGroup.project_share]: [
        permissions_1.PermissionsKey.project_pages_home,
        permissions_1.PermissionsKey.project_blocks_hide,
        permissions_1.PermissionsKey.project_sections_hide,
        permissions_1.PermissionsKey.project_team_list_r,
        permissions_1.PermissionsKey.project_tasks_c,
        permissions_1.PermissionsKey.project_tasks_r,
        permissions_1.PermissionsKey.project_publicLink_c,
        permissions_1.PermissionsKey.project_publicLink_r,
        permissions_1.PermissionsKey.project_publicLink_u,
        permissions_1.PermissionsKey.project_publicLink_d,
        permissions_1.PermissionsKey.project_scormExport_c,
        permissions_1.PermissionsKey.project_scormExport_r,
        permissions_1.PermissionsKey.project_scormExport_u,
        permissions_1.PermissionsKey.project_scormExport_d,
    ],
    [enums_1.PermissionGroup.project_comment]: [
        permissions_1.PermissionsKey.project_team_list_r,
        permissions_1.PermissionsKey.project_comments_c,
        permissions_1.PermissionsKey.project_comments_r,
        permissions_1.PermissionsKey.project_employeesFilter_comments_mention,
    ],
    [enums_1.PermissionGroup.project_view]: [
        permissions_1.PermissionsKey.project_r,
        permissions_1.PermissionsKey.project_pages_preview,
        permissions_1.PermissionsKey.project_blocks_r,
        permissions_1.PermissionsKey.project_settings_r,
        permissions_1.PermissionsKey.project_sections_r,
        permissions_1.PermissionsKey.project_resource_r,
        permissions_1.PermissionsKey.project_style_r,
    ],
};
exports.SYSTEM_ROLE_PERMISSIONS = {
    [enums_1.SystemRole.system_admin]: [
        permissions_1.PermissionsKey.system_admin_c,
        permissions_1.PermissionsKey.system_admin_r,
        permissions_1.PermissionsKey.system_admin_u,
        permissions_1.PermissionsKey.system_admin_d,
        permissions_1.PermissionsKey.company_r,
        permissions_1.PermissionsKey.company_c,
        permissions_1.PermissionsKey.company_pages_dashboard,
        permissions_1.PermissionsKey.company_pages_catalog,
        permissions_1.PermissionsKey.company_pages_resource,
        permissions_1.PermissionsKey.company_pages_tasks,
        permissions_1.PermissionsKey.company_pages_tags,
        permissions_1.PermissionsKey.company_pages_style,
        permissions_1.PermissionsKey.company_pages_collection,
        permissions_1.PermissionsKey.company_pages_fonts,
        permissions_1.PermissionsKey.company_pages_employees,
        permissions_1.PermissionsKey.company_pages_settings,
        permissions_1.PermissionsKey.company_settings_base_r,
        permissions_1.PermissionsKey.company_settings_owner_r,
        permissions_1.PermissionsKey.company_settings_system_r,
        permissions_1.PermissionsKey.company_settings_system_u,
        permissions_1.PermissionsKey.company_employees_list_base_r,
        permissions_1.PermissionsKey.company_employees_list_profile_r,
        permissions_1.PermissionsKey.company_employees_list_companyInfo_r,
        permissions_1.PermissionsKey.company_style_r,
        permissions_1.PermissionsKey.company_catalog_r,
        permissions_1.PermissionsKey.company_fonts_r,
        permissions_1.PermissionsKey.company_resource_r,
        permissions_1.PermissionsKey.company_tags_r,
        permissions_1.PermissionsKey.company_collections_company_r,
        permissions_1.PermissionsKey.project_r,
        permissions_1.PermissionsKey.project_pages_preview,
        permissions_1.PermissionsKey.project_pages_home,
        permissions_1.PermissionsKey.project_pages_editor,
        permissions_1.PermissionsKey.project_pages_pro,
        permissions_1.PermissionsKey.project_settings_r,
        permissions_1.PermissionsKey.project_blocks_r,
        permissions_1.PermissionsKey.project_settings_r,
        permissions_1.PermissionsKey.project_sections_r,
        permissions_1.PermissionsKey.project_team_list_r,
        permissions_1.PermissionsKey.project_comments_r,
        permissions_1.PermissionsKey.project_collection_r,
        permissions_1.PermissionsKey.project_resource_r,
        permissions_1.PermissionsKey.project_tasks_r,
        permissions_1.PermissionsKey.project_style_r,
        permissions_1.PermissionsKey.project_publicLink_r,
        permissions_1.PermissionsKey.project_scormExport_r,
    ],
    [enums_1.SystemRole.user]: [],
    [enums_1.SystemRole.visitor]: [],
};
exports.COMPANY_ROLE_PERMISSIONS = {
    [enums_1.CompanyRole.owner]: exclude([...ALL_COMPANY_PERMISSIONS, ...ALL_PROJECT_PERMISSIONS], [
        permissions_1.PermissionsKey.company_c,
        permissions_1.PermissionsKey.company_settings_system_u,
        permissions_1.PermissionsKey.company_employees_list_profile_u,
        permissions_1.PermissionsKey.project_comments_u,
    ]),
    [enums_1.CompanyRole.admin]: [
        ...exclude(ALL_COMPANY_PERMISSIONS, [
            permissions_1.PermissionsKey.company_d,
            permissions_1.PermissionsKey.company_c,
            permissions_1.PermissionsKey.company_settings_owner_r,
            permissions_1.PermissionsKey.company_settings_owner_u,
            permissions_1.PermissionsKey.company_settings_system_u,
            permissions_1.PermissionsKey.company_employees_list_profile_u,
        ]),
        ...exclude(ALL_PROJECT_PERMISSIONS, [permissions_1.PermissionsKey.project_comments_u]),
    ],
    [enums_1.CompanyRole.employee]: [
        permissions_1.PermissionsKey.company_r,
        permissions_1.PermissionsKey.company_pages_dashboard,
        permissions_1.PermissionsKey.company_pages_catalog,
        permissions_1.PermissionsKey.company_pages_resource,
        permissions_1.PermissionsKey.company_pages_tasks,
        permissions_1.PermissionsKey.company_pages_tags,
        permissions_1.PermissionsKey.company_pages_style,
        permissions_1.PermissionsKey.company_pages_collection,
        permissions_1.PermissionsKey.company_pages_fonts,
        permissions_1.PermissionsKey.company_pages_employees,
        permissions_1.PermissionsKey.company_pages_settings,
        permissions_1.PermissionsKey.company_employees_self_base_r,
        permissions_1.PermissionsKey.company_employees_self_base_u,
        permissions_1.PermissionsKey.company_employees_self_companyInfo_r,
        permissions_1.PermissionsKey.company_employees_self_profile_r,
        permissions_1.PermissionsKey.company_employees_self_profile_u,
        permissions_1.PermissionsKey.company_employees_list_profile_r,
        permissions_1.PermissionsKey.company_employees_list_companyInfo_r,
        permissions_1.PermissionsKey.company_settings_base_r,
        permissions_1.PermissionsKey.company_catalog_r,
        permissions_1.PermissionsKey.company_resource_r,
        permissions_1.PermissionsKey.company_tags_r,
        permissions_1.PermissionsKey.company_collections_self,
        permissions_1.PermissionsKey.company_collections_company_r,
        permissions_1.PermissionsKey.company_style_r,
        permissions_1.PermissionsKey.company_fonts_r,
        permissions_1.PermissionsKey.company_employees_list_base_r,
        permissions_1.PermissionsKey.project_showInProject,
        permissions_1.PermissionsKey.project_pages_preview,
        permissions_1.PermissionsKey.project_pages_home,
    ],
    [enums_1.CompanyRole.guest]: [
        permissions_1.PermissionsKey.company_r,
        permissions_1.PermissionsKey.company_pages_dashboard,
        permissions_1.PermissionsKey.company_employees_self_base_r,
        permissions_1.PermissionsKey.company_employees_self_base_u,
        permissions_1.PermissionsKey.company_settings_base_r,
        permissions_1.PermissionsKey.company_resource_r,
        permissions_1.PermissionsKey.company_collections_company_r,
        permissions_1.PermissionsKey.company_tags_r,
        permissions_1.PermissionsKey.company_style_r,
        permissions_1.PermissionsKey.company_fonts_r,
        permissions_1.PermissionsKey.project_showInProject,
    ],
    [enums_1.CompanyRole.visitor]: [
        permissions_1.PermissionsKey.company_resource_r,
        permissions_1.PermissionsKey.company_tags_r,
        permissions_1.PermissionsKey.company_style_r,
        permissions_1.PermissionsKey.company_fonts_r,
    ],
    [enums_1.CompanyRole.commentator]: [permissions_1.PermissionsKey.project_showInProject, permissions_1.PermissionsKey.company_r],
};
exports.TASK_ROLE_PERMISSIONS = {
    [enums_1.TaskRole.creator]: [
        permissions_1.PermissionsKey.project_tasks_r,
        permissions_1.PermissionsKey.project_tasks_d,
        permissions_1.PermissionsKey.project_tasks_common,
        permissions_1.PermissionsKey.project_tasks_deadline,
        permissions_1.PermissionsKey.project_tasks_status,
        permissions_1.PermissionsKey.project_tasks_comment,
        permissions_1.PermissionsKey.project_tasks_attach,
    ],
    [enums_1.TaskRole.assignee]: [
        permissions_1.PermissionsKey.project_tasks_common,
        permissions_1.PermissionsKey.project_tasks_status,
        permissions_1.PermissionsKey.project_tasks_comment,
        permissions_1.PermissionsKey.project_tasks_attach,
    ],
};
exports.COMMENT_ROLE_PERMISSIONS = {
    [enums_1.CommentRole.creator]: [
        permissions_1.PermissionsKey.project_comments_r,
        permissions_1.PermissionsKey.project_comments_u,
        permissions_1.PermissionsKey.project_comments_d,
        permissions_1.PermissionsKey.project_comments_resolve,
    ],
    [enums_1.CommentRole.mentioned]: [
        permissions_1.PermissionsKey.project_comments_r,
        permissions_1.PermissionsKey.project_comments_resolve,
    ],
};
exports.PROJECT_ROLE_PERMISSIONS = {
    [enums_1.ProjectRole.stack_holder]: exclude(ALL_PROJECT_PERMISSIONS, [
        permissions_1.PermissionsKey.project_c,
        permissions_1.PermissionsKey.project_showInProject,
        permissions_1.PermissionsKey.project_comments_u,
        permissions_1.PermissionsKey.project_comments_d,
        permissions_1.PermissionsKey.project_style_c,
        permissions_1.PermissionsKey.project_style_r,
        permissions_1.PermissionsKey.project_style_u,
        permissions_1.PermissionsKey.project_style_d,
    ]),
    [enums_1.ProjectRole.participant]: [],
    [enums_1.ProjectRole.visitor]: [],
};
exports.COMPANY_ROLE_PERMISSIONS_GROUPS = {
    [enums_1.CompanyRole.owner]: [
        enums_1.PermissionGroup.company_manage,
        enums_1.PermissionGroup.company_create,
        enums_1.PermissionGroup.company_style,
        enums_1.PermissionGroup.project_manage,
        enums_1.PermissionGroup.project_pro,
        enums_1.PermissionGroup.project_content,
        enums_1.PermissionGroup.project_share,
        enums_1.PermissionGroup.project_comment,
        enums_1.PermissionGroup.project_view,
    ],
    [enums_1.CompanyRole.admin]: [
        enums_1.PermissionGroup.company_manage,
        enums_1.PermissionGroup.company_create,
        enums_1.PermissionGroup.company_style,
        enums_1.PermissionGroup.project_manage,
        enums_1.PermissionGroup.project_pro,
        enums_1.PermissionGroup.project_content,
        enums_1.PermissionGroup.project_share,
        enums_1.PermissionGroup.project_comment,
        enums_1.PermissionGroup.project_view,
    ],
    [enums_1.CompanyRole.employee]: [enums_1.PermissionGroup.project_view, enums_1.PermissionGroup.project_comment],
    [enums_1.CompanyRole.guest]: [],
    [enums_1.CompanyRole.visitor]: [],
    [enums_1.CompanyRole.commentator]: [],
};
exports.PROJECT_ROLE_PERMISSIONS_GROUPS = {
    [enums_1.ProjectRole.stack_holder]: [
        enums_1.PermissionGroup.project_manage,
        enums_1.PermissionGroup.project_pro,
        enums_1.PermissionGroup.project_content,
        enums_1.PermissionGroup.project_comment,
        enums_1.PermissionGroup.project_share,
        enums_1.PermissionGroup.project_view,
    ],
    [enums_1.ProjectRole.participant]: [enums_1.PermissionGroup.project_view],
    [enums_1.ProjectRole.visitor]: [],
};
