import { DownloaderFontSchemaType, DownloaderSchemaType } from '@leenda/editor/lib/brand'
import { CONFIG_ELEMENTS, DownloaderElementValue } from '@leenda/editor/lib/elements'

import { DEMO_MODE } from 'services/Store/Project/constants'
import { t } from 'services/Translation'

import DemoWrapper from '../../decorators/DemoWrapper'
import { IElementDemoProps } from '../../elements.types'
import Element from './DownloaderElement'

const DEMO_VALUE: DownloaderElementValue = { file: null, label: t('elements.downloader.label') }

const DownloaderDemo: React.FC<
  IElementDemoProps<DownloaderSchemaType, DownloaderFontSchemaType>
> = ({ styles, fonts }) => (
  <DemoWrapper>
    <Element
      element={{
        id: `${CONFIG_ELEMENTS.downloader.type}-demo`,
        type: CONFIG_ELEMENTS.downloader.type,
        value: DEMO_VALUE,
        style: {},
        font: {},
      }}
      font={fonts}
      mode={DEMO_MODE}
      styles={styles}
    />
  </DemoWrapper>
)

export default DownloaderDemo
