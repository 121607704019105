import { Editor, Element } from 'slate'

export const getCurrentElementTypes = (editor: Editor) => {
  const [element] = Editor.nodes(editor, {
    match: (n) => Element.isElement(n) && Editor.isBlock(editor, n),
    mode: 'lowest',
  })
  // @ts-ignore
  return element ? element[0]?.type : undefined
}

export const isInSlate = (el?: HTMLElement) => {
  let current: HTMLElement | null = el || null
  while (current) {
    if (current.dataset?.slateEditor === 'true') {
      return true
    }
    current = current.parentElement
  }
  return false
}
