import React from 'react'

import { genDataField } from 'components/controls/Field'
import RealtimeField from 'components/controls/RealtimeField'
import Padding from 'components/editor-v3/Editor/Toolbar/Padding'
import { Typography } from 'components/uiKit/Typography'
import { FIELD_BOTTOM_INDENT } from 'components/uiKit/Typography/constants'
import { Block, MapType } from 'services/Store/Project/types'
import { t } from 'services/Translation'
import { chainName } from 'utils/form'

import { TestSingleChoiceValueFormType } from './TestSingleChoice.types'

const genField = genDataField<MapType<Block>>()

const isRandom = genField({
  name: 'isRandom',
  label: t('input.label.shuffle'),
  type: 'segmented',
  layout: 'horizontal',
})

const TestSingleChoiceForm: TestSingleChoiceValueFormType = ({ name }) => (
  <Padding sides>
    <Typography indents={FIELD_BOTTOM_INDENT} styleType='hint'>
      {t('elements.testSingleChoice.form.randomHint')}
    </Typography>
    <RealtimeField config={chainName(name, isRandom)} />
  </Padding>
)

export default TestSingleChoiceForm
