import { ListFontSchemaType, ListSchemaType } from '@leenda/editor/lib/brand'
import { CONFIG_ELEMENTS, ListElementValue } from '@leenda/editor/lib/elements'
import { textToRtValue } from '@leenda/rich-text'

import { DEMO_MODE } from 'services/Store/Project/constants'
import { t } from 'services/Translation'

import DemoWrapper from '../../decorators/DemoWrapper'
import { IElementDemoProps } from '../../elements.types'
import s from './ListDemo.module.scss'
import Element from './ListElement'

const DEMO_VALUE_NUMBER: ListElementValue = {
  items: [
    {
      label: textToRtValue(t('elements.list.value.item', { count: 1 })),
      value: 'id_number_1',
      number: 1,
      icon: null,
    },
    {
      label: textToRtValue(t('elements.list.value.item', { count: 2 })),
      value: 'id_number_2',
      number: 2,
      icon: null,
    },
    {
      label: textToRtValue(t('elements.list.value.item', { count: 3 })),
      value: 'id_number_3',
      number: 3,
      icon: null,
    },
  ],
  mode: 'number',
}

const DEMO_VALUE_POINT: ListElementValue = {
  items: [
    {
      label: textToRtValue(t('elements.list.value.item', { count: 1 })),
      value: 'id_point_1',
      number: 1,
      icon: null,
    },
    {
      label: textToRtValue(t('elements.list.value.item', { count: 2 })),
      value: 'id_point_2',
      number: 2,
      icon: null,
    },
    {
      label: textToRtValue(t('elements.list.value.item', { count: 3 })),
      value: 'id_point_3',
      number: 3,
      icon: null,
    },
  ],
  mode: 'point',
}

const DEMO_VALUE_POINT_ICON: ListElementValue = {
  items: [
    {
      label: textToRtValue(t('elements.list.value.item', { count: 1 })),
      value: 'id_point_icon_1',
      number: 1,
      icon: null,
    },
    {
      label: textToRtValue(t('elements.list.value.item', { count: 2 })),
      value: 'id_point_icon_2',
      number: 1,
      icon: null,
    },
    {
      label: textToRtValue(t('elements.list.value.item', { count: 3 })),
      value: 'id_point_icon_3',
      number: 1,
      icon: null,
    },
  ],
  mode: 'images',
}

const DemoNumber: React.FC<IElementDemoProps<ListSchemaType, ListFontSchemaType>> = ({
  styles,
  fonts,
}) => (
  <DemoWrapper center>
    <div className={s.pointWrapper}>
      <Element
        element={{
          id: `${CONFIG_ELEMENTS.list.type}-number-demo`,
          type: CONFIG_ELEMENTS.list.type,
          value: DEMO_VALUE_NUMBER,
          style: {},
          font: {},
        }}
        font={fonts}
        mode={DEMO_MODE}
        styles={styles}
      />
    </div>
  </DemoWrapper>
)

const DemoPoint: React.FC<IElementDemoProps<ListSchemaType, ListFontSchemaType>> = ({
  styles,
  fonts,
}) => (
  <DemoWrapper center>
    <Element
      element={{
        id: `${CONFIG_ELEMENTS.list.type}-point-demo`,
        type: CONFIG_ELEMENTS.list.type,
        value: DEMO_VALUE_POINT,
        style: {},
        font: {},
      }}
      font={fonts}
      mode={DEMO_MODE}
      styles={styles}
    />
  </DemoWrapper>
)

const DemoPointIcon: React.FC<IElementDemoProps<ListSchemaType, ListFontSchemaType>> = ({
  styles,
  fonts,
}) => (
  <DemoWrapper center>
    <Element
      element={{
        id: `${CONFIG_ELEMENTS.list.type}-point-icon-demo`,
        type: CONFIG_ELEMENTS.list.type,
        value: DEMO_VALUE_POINT_ICON,
        style: {},
        font: {},
      }}
      font={fonts}
      mode={DEMO_MODE}
      styles={styles}
    />
  </DemoWrapper>
)

export { DemoNumber, DemoPoint, DemoPointIcon }
