import { EditorElement } from '@leenda/editor/lib/elements'
import { generateId } from '@leenda/editor/lib/utils/id'

import { Block } from 'services/Store/Project/types'

import { ELEMENTS_WITH_CHILDREN } from '../../components/editor-v3/types/data.constants'
import { isCommonNode, isShellNode } from '../../components/editor-v3/types/data.guards'
import { LayoutNode, Nodes, Schema } from '../../components/editor-v3/types/data.types'

interface IIdMapper {
  (id: string | undefined): string
}

export const idMapper = (): IIdMapper => {
  const map = new Map()
  return (id: string | undefined) => {
    if (id === undefined) {
      return 'Error'
    }
    if (map.has(id)) {
      return map.get(id)
    }
    const newId = generateId()
    map.set(id, newId)
    return newId
  }
}

export const cloneNode = (node: LayoutNode, mapId: IIdMapper): LayoutNode => {
  const newNode: LayoutNode = {
    ...node,
    id: mapId(node.id),
  }
  if (isShellNode(newNode)) {
    if (newNode.elementId) {
      newNode.elementId = mapId(newNode.elementId)
    }
  } else if (isCommonNode(newNode)) {
    newNode.children = newNode.children.map(mapId)
  }

  return newNode
}

export const cloneElement = (element: EditorElement, mapId: IIdMapper): EditorElement => {
  const result: EditorElement = {
    ...element,
    id: mapId(element.id),
  }
  if (ELEMENTS_WITH_CHILDREN.includes(element.type)) {
    result.value = {
      ...result.value,
      items: element.value.items.map((item: any) => ({ ...item, value: mapId(item.value) })),
    }
  }

  return result
}

export const cloneBlockSchema = (block: Block) => {
  const mapId = idMapper()
  const elements: Record<string, EditorElement> = Object.values(block.elements).reduce(
    (acc: Record<string, EditorElement>, element) => {
      const newElement = cloneElement(element, mapId)
      acc[newElement.id] = newElement
      return acc
    },
    {},
  )

  const nodes: Nodes = Object.values(block.schema.nodes).reduce((acc: Nodes, node) => {
    const newNode = cloneNode(node, mapId)
    acc[newNode.id] = newNode
    return acc
  }, {})

  const schema: Schema = {
    rootId: mapId(block.schema.rootId),
    nodes,
  }

  return { ...block, uuid: mapId(block.uuid), elements, schema }
}
