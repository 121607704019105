"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const genStyle_1 = require("../genStyle");
const TYPE = 'testSectionLayout';
const styleSchema = {
    indents: {
        paddingTop: (0, genStyle_1.genStyle)({ value: 32 }),
        paddingBottom: (0, genStyle_1.genStyle)({ value: 0 }),
    },
};
exports.default = { [TYPE]: { style: styleSchema, font: {} } };
