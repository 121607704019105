"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_VALUE = exports.LanguagesEnum = exports.TYPE = void 0;
const translation_1 = require("../../../translation");
exports.TYPE = 'code';
exports.LanguagesEnum = {
    html: 'html',
    css: 'css',
    javascript: 'javascript',
    node: 'node',
    markdown: 'markdown',
    java: 'java',
    php: 'php',
    python: 'python',
    'c/c++': 'c/c++',
    sql: 'sql',
};
exports.DEFAULT_VALUE = {
    code: `<p>${(0, translation_1.t)('elements.code.label')} HTML</p>`,
    lineNumbers: true,
    theme: 'light',
    disabled: false,
    language: exports.LanguagesEnum.html,
};
