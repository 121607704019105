import React from 'react'

import Employee, { AccountMock } from 'components/uiKit/Employee/Employee'
import { KitSize } from 'components/uiKit/KitTypes'
import { ISelectLabelProps } from 'components/uiKit/Select'

export const createEmployeeLabel = <T extends AccountMock>(item: T) => {
  const EmployeeLabel: React.FC<ISelectLabelProps> = ({ type, size, ...rest }) => (
    <Employee {...rest} employee={item} size={type === 'root' ? KitSize.XS : size} type='info' />
  )
  return EmployeeLabel
}
