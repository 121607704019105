import * as R from 'ramda'

import { ProjectSchemaDeepFragment } from 'gql/__generated__/graphql'

import { MapType, Section, Block } from './types'

export const prepareDeepProject = (project?: ProjectSchemaDeepFragment | null) => {
  return {
    project,
    sections: R.indexBy(
      R.prop('id'),
      project?.sections.filter((item) => !item.isHide) || [],
    ) as MapType<Section>,
    blocks: R.indexBy(
      R.prop('uuid'),
      project?.sections?.flatMap((item) => item.blocks).filter((item) => !item.isHide) || [],
    ) as MapType<Block>,
  }
}
