import { SliderFontSchemaType, SliderSchemaType } from '@leenda/editor/lib/brand'
import { CONFIG_ELEMENTS, SliderElementValue } from '@leenda/editor/lib/elements'

import { DEMO_MODE } from 'services/Store/Project/constants'

import DemoWrapper from '../../decorators/DemoWrapper'
import { IElementDemoProps } from '../../elements.types'
import Element from './SliderElement'

const DEMO_VALUE: SliderElementValue = {
  items: [
    { label: 'label1', value: 'id1' },
    { label: 'label2', value: 'id2' },
    { label: 'label3', value: 'id3' },
  ],
  hasLoop: true,
  hasProgress: true,
}

const SliderDemo: React.FC<IElementDemoProps<SliderSchemaType, SliderFontSchemaType>> = ({
  styles,
  fonts,
}) => (
  <DemoWrapper>
    <Element
      element={{
        id: `${CONFIG_ELEMENTS.slider.type}-demo`,
        type: CONFIG_ELEMENTS.slider.type,
        value: DEMO_VALUE,
        style: {},
        font: {},
      }}
      font={fonts}
      mode={DEMO_MODE}
      styles={styles}
    />
  </DemoWrapper>
)

export default SliderDemo
