import { AccordionFontSchemaType, AccordionSchemaType } from '@leenda/editor/lib/brand'
import { CONFIG_ELEMENTS, AccordionElementValue } from '@leenda/editor/lib/elements'
import { textToRtValue } from '@leenda/rich-text'

import { DEMO_MODE } from 'services/Store/Project/constants'
import { t } from 'services/Translation'

import DemoWrapper from '../../decorators/DemoWrapper'
import { IElementDemoProps } from '../../elements.types'
import Element from './AccordionElement'

const DEMO_VALUE: AccordionElementValue = {
  items: [
    { label: textToRtValue(t('elements.accordion.value.title', { count: 1 })), value: 'id1' },
    { label: textToRtValue(t('elements.accordion.value.title', { count: 2 })), value: 'id2' },
  ],
  showFirstTab: false,
  multipleExpand: true,
}

const AccordionDemo: React.FC<IElementDemoProps<AccordionSchemaType, AccordionFontSchemaType>> = ({
  styles,
  fonts,
}) => (
  <DemoWrapper>
    <Element
      element={{
        id: `${CONFIG_ELEMENTS.accordion.type}-demo`,
        type: CONFIG_ELEMENTS.accordion.type,
        value: DEMO_VALUE,
        style: {},
        font: {},
      }}
      font={fonts}
      mode={DEMO_MODE}
      styles={styles}
    />
  </DemoWrapper>
)

export default AccordionDemo
