import { CommentChildSchemaFragment, CommentThreadSchemaFragment } from 'gql/__generated__/graphql'

export enum CommentActionUiEnum {
  RESOLVE = 'RESOLVE',
  EDIT = 'EDIT',
  UNSUBSCRIBE = 'UNSUBSCRIBE',
  SUBSCRIBE = 'SUBSCRIBE',
  DELETE = 'DELETE',
  PENDING = 'PENDING',
  PIN = 'PIN',
  CLOSE = 'CLOSE',
  COLLAPSE = 'COLLAPSE',
  LINK = 'LINK',
}

export type CommentMock = Pick<CommentThreadSchemaFragment, 'id' | 'blockUuid'> &
  Partial<CommentThreadSchemaFragment> &
  Partial<CommentChildSchemaFragment> & { section?: { name?: string | null } | null }
