import { useState, useEffect } from 'react'

export const useGetTestResultTitle = (
  resultSuccessTitle?: string,
  resultFailTitle?: string,
  resultFeedbackSuccess?: string,
  resultFeedbackFail?: string,
  isFeedbackCommon?: boolean,
) => {
  const [title, setTitle] = useState(resultSuccessTitle || resultFailTitle)

  useEffect(() => {
    setTitle(resultSuccessTitle)
  }, [resultSuccessTitle, resultFeedbackSuccess])

  useEffect(() => {
    setTitle(resultFailTitle)
  }, [resultFailTitle, resultFeedbackFail])

  useEffect(() => {
    setTitle(resultFailTitle)
  }, [resultFailTitle, resultFeedbackFail])

  useEffect(() => {
    setTitle(resultSuccessTitle)
    // eslint-disable-next-line
  }, [isFeedbackCommon])

  return title
}
