import { CommentStatus } from 'gql/__generated__/graphql'

import type { ICommentsFilterValues } from './fieldsConfig'

export const COMMENTS_FILTER_INITIAL_VALUES: ICommentsFilterValues = {
  read: null,
  answered: null,
  mentioned: null,
  createdById: null,
  resolved: CommentStatus.pending,
}
