import cn from 'classnames'
import React from 'react'

import { testProps } from 'utils/test/qaData'

import { KitSize } from '../KitTypes'
import s from './Icons.module.scss'
import { ICON_MAP } from './generate/iconMap'

type SVGDefaultElement = React.SVGProps<SVGSVGElement> & { title?: string | undefined }

export type KeyIconsType = keyof typeof ICON_MAP

/**
 * Props for the Icon component.
 */
export interface IIconProps extends SVGDefaultElement {
  /**
   * The name of the icon to display.
   */
  name: KeyIconsType
  /**
   * The size of the icon. Can be a KitSize(16/20/24/28) or a number in px.
   */
  size?: KitSize | number
  /**
   * Test data used for automated testing.
   */
  testData?: string
}

const Icon: React.FC<IIconProps> = ({ name, className, size, testData, ...rest }) => {
  const Svg = ICON_MAP[name]
  const iconClass = cn(s.root, typeof size === 'string' && s[size], className)
  const iconStyle = { ...rest.style, ...(typeof size === 'number' && { fontSize: size }) }

  return (
    <Svg
      {...testProps({ el: 'icon', icon: name, testData })}
      {...rest}
      className={iconClass}
      style={iconStyle}
    />
  )
}

export default Icon
