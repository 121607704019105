import Button from '../Button'
import { KitSize } from '../KitTypes'

interface IPageButtonProps {
  page: number | string
  onClick: () => void
  active?: boolean
}

const PageButton: React.FC<IPageButtonProps> = ({ page, onClick, active }) => (
  <Button
    active={active}
    name={`page-${page}`}
    onClick={onClick}
    size={KitSize.S}
    styleType='ghost'
    round
  >
    {page}
  </Button>
)

export default PageButton
