import lodash from 'lodash'
import React, { useCallback } from 'react'

import { RESET } from 'components/controls/Field/Field.constant'
import { IControlProps } from 'components/controls/Field/Field.types'
import { IconButton } from 'components/uiKit/Button'
import { Tooltip } from 'components/uiKit/Dropdown'
import { KitSize } from 'components/uiKit/KitTypes'
import { t } from 'services/Translation'

const FieldReset: React.FC<IControlProps<{ value?: typeof RESET }>> = ({
  name,
  defaultValue,
  value,
  onChange,
  disabled,
}) => {
  const equal = lodash.isEqual(defaultValue, value)
  const onChangeWrapper = useCallback(
    () => onChange({ name, value: defaultValue }),
    [name, defaultValue, onChange],
  )

  return (
    <Tooltip overlay={t('uiKit.tooltip.reset')}>
      <IconButton
        disabled={equal || disabled}
        icon='otherReload'
        name={`reset_${name}`}
        onClick={onChangeWrapper}
        size={KitSize.S}
        styleType='ghost'
        type='button'
      />
    </Tooltip>
  )
}

export default FieldReset
