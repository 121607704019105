import { ICON_MAP } from 'components/uiKit/Icon/generate/iconMap'
import { BrandType } from 'services/Branding/types'
import { t } from 'services/Translation'

import { PageItemsList } from './TestInfoElement.types'

export const OPTIONS = {
  PERCENTAGE: {
    name: 'percentage',
    defaultIcon: ICON_MAP.testResultsPercent || null,
    title: t('elements.test-info.form.threshold'),
    template: ({ percentage }: { percentage?: number }) => `${percentage}%`,
  },
  ATTEMPTS_LEFT: {
    name: 'attempts',
    defaultIcon: ICON_MAP.testResultsNumberOfAttempts || null,
    title: t('elements.test-info.form.attempts'),
    template: ({
      attemptsLeft,
      totalAttempts,
      limit,
      pt,
    }: {
      attemptsLeft?: number
      totalAttempts?: number
      limit?: boolean | null
      pt: (key: keyof BrandType['labels']) => string
    }) =>
      limit
        ? `${attemptsLeft} ${pt('elements.test-info.from')} ${totalAttempts}`
        : pt('elements.test-info.limit'),
  },
  CURRENT_ATTEMPT: {
    name: 'attempts',
    defaultIcon: ICON_MAP.testResultsNumberOfAttempts || null,
    title: t('elements.test-info.form.attempts'),
    template: ({
      currentAttempt,
      totalAttempts,
      limit,
      pt,
    }: {
      currentAttempt?: number
      totalAttempts?: number
      limit?: boolean | null
      pt: (key: keyof BrandType['labels']) => string
    }) =>
      limit
        ? `${currentAttempt} ${pt('elements.test-info.from')}  ${totalAttempts}`
        : pt('elements.test-info.limit'),
  },
  TEST_TIME: {
    name: 'testTime',
    defaultIcon: ICON_MAP.testResultsTestTime || null,
    title: t('elements.test-info.form.testTime'),
    template: ({
      testTime,
      pt,
    }: {
      testTime?: string
      pt: (key: keyof BrandType['labels']) => string
    }) => (testTime ? testTime : pt('elements.test-info.limit')),
  },
  TEST_TIME_SPENT: {
    name: 'testTime',
    defaultIcon: ICON_MAP.testResultsTestTime || null,
    title: t('elements.test-info.form.timeSpent'),
    template: ({
      testTimeSpent,
      pt,
    }: {
      testTimeSpent?: string
      pt: (key: keyof BrandType['labels']) => string
    }) => (testTimeSpent ? testTimeSpent : pt('elements.test-info.timeDisabled')),
  },
  QUESTIONS: {
    name: 'questions',
    defaultIcon: ICON_MAP.testResultsRightQuestions || null,
    title: t('elements.test-info.form.questions'),
    template: ({ questions }: { questions?: number }) => `${questions}`,
  },
  QUESTIONS_SUCCESS: {
    name: 'questions',
    defaultIcon: ICON_MAP.testResultsRightQuestions || null,
    title: t('elements.test-info.form.questionsSuccess'),
    template: ({
      successQuestions,
      totalQuestions,
      pt,
    }: {
      successQuestions?: number
      totalQuestions?: number
      pt: (key: keyof BrandType['labels']) => string
    }) => `${successQuestions} ${pt('elements.test-info.from')} ${totalQuestions}`,
  },
} as const

const START_PAGE_ITEMS: PageItemsList = ['PERCENTAGE', 'CURRENT_ATTEMPT', 'TEST_TIME', 'QUESTIONS']

const END_PAGE_ITEMS: PageItemsList = [
  'PERCENTAGE',
  'ATTEMPTS_LEFT',
  'TEST_TIME_SPENT',
  'QUESTIONS_SUCCESS',
]

export const PAGE_ITEMS = {
  start: START_PAGE_ITEMS,
  end: END_PAGE_ITEMS,
}
