import cn from 'classnames'
import React, { ReactNode } from 'react'

import { KitSize } from 'components/uiKit/KitTypes'
import { ITabsMain } from 'components/uiKit/Tabs/types'
import { testProps } from 'utils/test/qaData'

import s from './TabItem.module.scss'

interface ITabListProps<V> extends ITabsMain {
  isActive: boolean
  title: ReactNode | string
  onChangeTab: (tabKey: V) => void
  tabKey: V
  disabled?: boolean
  directionHorizontalTabs?: boolean
}

const TabItem = <V extends string>(
  {
    isActive,
    title,
    onChangeTab,
    styleType,
    tabKey,
    grow,
    tabPosition,
    size = KitSize.L,
    disabled,
    directionHorizontalTabs,
  }: ITabListProps<V>,
  ref: React.Ref<HTMLDivElement>,
) => {
  const rootCn = cn(s.root, s[size], s[styleType], s[tabPosition], {
    [s.horizontal]: directionHorizontalTabs,
    [s.active]: isActive,
    [s.grow]: grow,
    [s.disabled]: disabled,
  })
  return (
    <div
      {...testProps({
        el: 'tabItem',
        label: title?.toString(),
        value: tabKey,
        active: isActive,
        disabled,
      })}
      className={rootCn}
      onClick={() => !disabled && onChangeTab(tabKey)}
      ref={ref}
    >
      {title}
    </div>
  )
}

TabItem.displayName = 'TabItem'

export default React.forwardRef(TabItem) as <V extends string>(
  props: ITabListProps<V> & { ref?: React.Ref<HTMLDivElement> },
) => React.ReactElement
