import { BasePlugin } from '@uppy/core'
import { TOptions } from 'i18next'

import {
  FileMetaGroupAllQueryVariables,
  UpsertFileMetaMutationVariables,
} from 'gql/__generated__/graphql'
import { upsertFileMetaCore } from 'gql/files.apollo'
import { fileMetaUpsertUpdateFileList } from 'gql/files.cache'
import { UppyType } from 'store/models'

type ToGifOptions = TOptions & { toType?: string; gifInterval?: number }

class PreUploadCustomPlugin extends BasePlugin {
  opts: ToGifOptions

  constructor(uppy: UppyType, opts?: ToGifOptions) {
    super(uppy, opts)
    this.id = 'PreUploadCustomPlugin'
    this.type = 'modifier'

    const defaultOptions = {
      toType: 'image/jpeg',
    }

    this.opts = Object.assign({}, defaultOptions, opts)
    this.prepareUpload = this.prepareUpload.bind(this)
  }

  setOptions(newOpts: ToGifOptions) {
    super.setOptions(newOpts)
  }

  prepareUpload(fileIDs: string[]) {
    const promises = fileIDs.map((fileID) => {
      const file = this.uppy.getFile(fileID)
      const fileWithMeta = file.meta
      const { paramsUpdateCacheFiles, dataVariables } = fileWithMeta

      return upsertFileMetaCore({
        ...(dataVariables as UpsertFileMetaMutationVariables),
        data: {
          ...(dataVariables as UpsertFileMetaMutationVariables).data,
          size: file.size.toString(),
        },
      })
        .then((res) => {
          if (res?.data) {
            this.uppy.setFileMeta(fileID, {
              ...file.meta,
              fileMetaId: res.data.data.id,
            })
            fileMetaUpsertUpdateFileList(
              paramsUpdateCacheFiles as FileMetaGroupAllQueryVariables,
              res.data.data,
            )
          }
        })
        .catch((err) => {
          this.uppy.removeFile(fileID)
          throw err
        })
    })

    const emitPreprocessCompleteForAll = () => {
      fileIDs.forEach((fileID) => {
        const file = this.uppy.getFile(fileID)
        if (file?.meta?.fileMetaId) {
          this.uppy.emit('preprocess-complete', file)
        }
      })
    }

    return Promise.all(promises).then(emitPreprocessCompleteForAll)
  }

  install() {
    this.uppy.addPreProcessor(this.prepareUpload)
  }

  uninstall() {
    this.uppy.removePreProcessor(this.prepareUpload)
  }
}

export { PreUploadCustomPlugin }
