import { useCallback } from 'react'

import { useBlocksOrderUpdate } from 'gql/sections.apollo'
import { useProjectContext } from 'services/Store/Project/hooks'
import 'services/Store/Project/selectors'
import { getSection, getTestStartBlock, getProjectId } from 'services/Store/Project/selectors'
import { reorder } from 'utils/editorUtils'

export const useReorder = () => {
  const section = useProjectContext(getSection)
  const projectId = useProjectContext(getProjectId)
  const startId = useProjectContext(getTestStartBlock, section?.id)?.uuid
  const [sectionUpdate] = useBlocksOrderUpdate()

  return useCallback(
    async (from: number, to: number) => {
      if (!section) {
        return
      }

      if (startId) {
        from++
        to++
      }

      const blocksOrder = reorder(section.blocksOrder, from, to)

      await sectionUpdate({
        variables: { id: section.id, projectId, data: { blocksOrder } },
        optimisticResponse: { data: { section: { ...section, blocksOrder } } },
      })
    },
    [sectionUpdate, projectId, section, startId],
  )
}
