"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ELEMENTS_WITH_CHILDREN = exports.NodeType = exports.ShellsAlignmentDirections = exports.DeviceMode = void 0;
var DeviceMode;
(function (DeviceMode) {
    DeviceMode["desktop"] = "desktop";
    DeviceMode["tablet"] = "tablet";
    DeviceMode["mobile"] = "mobile";
})(DeviceMode || (exports.DeviceMode = DeviceMode = {}));
var ShellsAlignmentDirections;
(function (ShellsAlignmentDirections) {
    ShellsAlignmentDirections["start"] = "start";
    ShellsAlignmentDirections["center"] = "center";
    ShellsAlignmentDirections["end"] = "end";
    ShellsAlignmentDirections["normal"] = "normal";
})(ShellsAlignmentDirections || (exports.ShellsAlignmentDirections = ShellsAlignmentDirections = {}));
var NodeType;
(function (NodeType) {
    NodeType["shell"] = "shell";
    NodeType["absolute"] = "absolute";
    NodeType["column"] = "column";
    NodeType["row"] = "row";
})(NodeType || (exports.NodeType = NodeType = {}));
exports.ELEMENTS_WITH_CHILDREN = ['tabs', 'accordion', 'slider', 'layoutList'];
