"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_VALUE = exports.TYPE = void 0;
exports.TYPE = 'link';
exports.DEFAULT_VALUE = {
    linkLabel: '',
    linkURL: '',
    showPreview: false,
    image: null,
};
