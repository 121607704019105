import { AnimatePresence } from 'framer-motion'
import React, { useEffect } from 'react'
import { createPortal } from 'react-dom'

import { ModalComponent } from '../ModalContext'

interface IModalRenderProps<P, R> {
  component: ModalComponent<P, R>
  open: boolean
  index?: number
  onClose: (data?: R) => void
  params: P
  getContainer: () => HTMLElement | null
  stopPropagation?: boolean
  fullScreen?: boolean
  currentModalIndex?: number
}

export const ModalInstanceContext = React.createContext<{
  onClose: (data?: any) => void
  index: number
} | null>(null)

const ModalRender = <P, R>({
  component,
  open,
  onClose,
  getContainer,
  params,
  index = 0,
  currentModalIndex,
  stopPropagation,
}: IModalRenderProps<P, R>) => {
  const Component = component as IModalRenderProps<P, R>['component']

  const container = getContainer()

  const escActive = currentModalIndex === index && open

  useEffect(() => {
    if (escActive) {
      const closeOnEsc = (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
          onClose()
        }
      }
      document.addEventListener('keydown', closeOnEsc)
      return () => {
        document.removeEventListener('keydown', closeOnEsc)
      }
    }
    return () => {}
  }, [escActive])

  if (!container) {
    return null
  }

  const content = (
    <AnimatePresence>
      {open && (
        <div
          onClick={(e) => stopPropagation && e.stopPropagation()}
          onMouseDown={(e) => stopPropagation && e.stopPropagation()}
          style={{ zIndex: 100 + index }}
        >
          <Component {...params} onClose={onClose} params={params} />
        </div>
      )}
    </AnimatePresence>
  )
  const value = {
    onClose,
    index,
  }

  return (
    <ModalInstanceContext.Provider value={value}>
      {createPortal(content, container)}
    </ModalInstanceContext.Provider>
  )
}

export default ModalRender
