import lodash from 'lodash'
import React, { useState } from 'react'

import CourseModal from 'components/editor-v3/cource/components/CourseModal'
import { SHELL_SIZE_KEYS } from 'components/editor-v3/types/data.constants'
import Icon from 'components/uiKit/Icon'
import { DeviceMode } from 'services/Store/Project/enums'
import { useProjectContext } from 'services/Store/Project/hooks'
import { getIsInteractiveMode } from 'services/Store/Project/selectors'
import { useImageWithCrop } from 'utils/files'
import { testProps } from 'utils/test/qaData'

import ResponsiveImage from '../../ResponsiveImage'
import { useElementRootStyles } from '../../hooks/useElementRootStyles'
import { ImageElementType } from './ImageEl.types'
import s from './ImageElView.module.scss'
import Zoom from './Zoom'
import ImageFallback from './assets/imageDemo.svg'

const ImageElView: ImageElementType = ({ element, styles, mode }) => {
  const [isFullSize, setIsFullSize] = useState(false)
  const isInteractive = useProjectContext(getIsInteractiveMode)
  const zoomable = element.value.zoomable

  const file = useImageWithCrop(element.value?.image)
  const { params } = element.value.image || {}
  const { round } = params || {}
  const desktopMode = mode.deviceMode === DeviceMode.desktop
  const zoomImage = zoomable && isInteractive
  const style = {
    maxWidth: '100%',
    maxHeight: '100%',
    ...lodash.pick(styles.shell, SHELL_SIZE_KEYS),
    ...(round && { borderRadius: '50%' }),
    ...(zoomImage && desktopMode && { cursor: 'zoom-in' }),
  }

  const rootStyles = useElementRootStyles(styles.root, styles.image, style)
  const imagePath =
    (element.value.sourceType === 'url' ? element.value?.url : file?.path) || ImageFallback

  const onZoom = () => {
    if (zoomImage) {
      setIsFullSize(true)
    }
  }

  const zoomStyle: React.CSSProperties = lodash.pick(rootStyles, ['borderRadius'])

  return (
    <>
      {zoomImage && (
        <CourseModal
          component={Zoom}
          mode={mode}
          onClose={() => setIsFullSize(false)}
          props={{ imagePath, imageStyle: zoomStyle }}
          visible={isFullSize}
        />
      )}
      <ResponsiveImage
        alt={element.value.image?.accessibility}
        aria-label={element.value.image?.accessibility}
        blackout={element.value.blackout}
        file={file?.file}
        onClick={onZoom}
        src={imagePath}
        style={rootStyles}
        {...testProps({ el: 'element-image', fileName: file?.file?.fileMeta?.name })}
      />
      {zoomImage && !desktopMode && (
        <div
          className={s.iconZoomIcon}
          onClick={onZoom}
          {...testProps({ el: 'button', name: 'image-zoom', fileName: file?.file?.fileMeta?.name })}
        >
          <Icon name='otherZoom' />
        </div>
      )}
    </>
  )
}

export default ImageElView
