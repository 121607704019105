import React from 'react'

import { getShellStyle } from 'components/editor-v3/context/EditorContext/selectors/styles'
import { isShellNode } from 'components/editor-v3/types/data.guards'
import { ShellNode } from 'components/editor-v3/types/data.types'
import { NodeType } from 'components/editor-v3/types/date.enums'
import { AppMode, PreviewMode } from 'services/Store/Project/enums'
import { useProjectContext } from 'services/Store/Project/hooks'
import { getEditorMode } from 'services/Store/Project/selectors'

import { FCNode } from '../Node/types'
import EditorElementRenderer from './EditorShell'
import PreviewElementRenderer from './PreviewShell'

const BLOCK_COMPONENTS: Partial<Record<PreviewMode, FCNode<NodeType.shell>>> = {
  editor: EditorElementRenderer,
}

const ShellContainer: FCNode = React.forwardRef((props, ref) => {
  const { node, mode } = props
  const editorMode = useProjectContext(getEditorMode)

  if (!isShellNode(node)) {
    return null
  }
  const previewMode =
    mode.previewMode === PreviewMode.editor && editorMode === AppMode.fill && node.hideOnFill
      ? PreviewMode.preview
      : mode.previewMode

  const Component = BLOCK_COMPONENTS[previewMode] || PreviewElementRenderer
  const { style, wrapperStyle } = getShellStyle(node as ShellNode, mode.deviceMode)
  return <Component {...props} node={node} ref={ref} style={style} wrapperStyle={wrapperStyle} />
})

ShellContainer.displayName = 'ShellContainer'

export default React.memo(ShellContainer)
