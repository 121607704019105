import { useContextSelector } from 'use-context-selector'

import AppTextContext, { AppTextContextType } from './AppTextContext'

export const getFormat = (context: AppTextContextType) => context.format
export const getReadOnly = (context: AppTextContextType) => context.readOnly
export const getDisabled = (context: AppTextContextType) => context.disabled
export const getForm = (context: AppTextContextType) => context.form
export const getEmployees = (context: AppTextContextType) => context.employees
export const getMentionsMap = (context: AppTextContextType) => context.mentionsMap
export const getMentionSearch = (context: AppTextContextType) => context.mentionSearch
export const getMentionTarget = (context: AppTextContextType) => context.mentionTarget
export const getMentionIndex = (context: AppTextContextType) => context.mentionIndex

export const getSetForm = (context: AppTextContextType) => context.setForm
export const getOnUpdateFormat = (context: AppTextContextType) => context.onUpdateFormat

export const useAppTextSelector = <S extends (context: AppTextContextType) => ReturnType<S>>(
  selector: S,
) => useContextSelector(AppTextContext, selector)
