import lodash from 'lodash'
import queryString from 'query-string'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { MAX_TABLE_SIZE, sortTypes } from 'utils'

import { MODAL_MOVE_FINDER_TABLE_NAME } from 'components/modals/FileMove/FolderTable'
import Highlighter from 'components/uiKit/Highlighter'
import { setUserPreferencesItems } from 'services/Store/Users/reducer'
import { selectUserPreferences } from 'services/Store/Users/selectors'
import { useAppDispatch, useAppSelector } from 'services/Store/hooks'
import { InferFilterFromConfig, PARSE_FILTER } from 'utils/filters'

import { ViewResourceEnum } from './TableView/types'
import { DEFAULT_PAGE_SIZE } from './constants'
import { ColumnState, ColumnType, Highlighted, ITablesExtendsData } from './types'
import { getColumnKey, getColumnsDefaultState } from './utils'

interface ISortParams {
  sort: string | null
  page: number
  sortOrder?: string
}

const DEFAULT_MOVING_MODAL_PAGE_SIZE = 20

const MOVING_MODALS = [MODAL_MOVE_FINDER_TABLE_NAME]

export const useHighlightedItems = <
  T extends { name?: string | null; kUser?: { name?: string | null } },
>(
  data?: T[],
  query?: string,
): Highlighted<T>[] =>
  useMemo(
    () =>
      data?.map((item) => ({
        ...item,
        nodeName: <Highlighter search={query || ''} text={item.name || item.kUser?.name || ''} />,
      })) || [],
    [data, query],
  )

export const useQueryParams = <
  C extends Record<string, (typeof PARSE_FILTER)[keyof typeof PARSE_FILTER]>,
>(
  valuesTemplate: C,
): [InferFilterFromConfig<C>, (params: InferFilterFromConfig<C>) => void] => {
  const location = useLocation()
  const { push } = useHistory()
  const queryParams: queryString.ParsedQuery<string> = useMemo(
    () => queryString.parse(location.search),
    [location.search],
  )
  const filteredParams = useMemo(() => {
    const newParams: Record<string, unknown> = {}
    Object.keys(valuesTemplate).forEach((key) => {
      if (queryParams[key] !== undefined) {
        newParams[key] = valuesTemplate[key](queryParams[key] as string)
      }
    })
    return newParams as InferFilterFromConfig<C>
  }, [queryParams, valuesTemplate])

  const setQueryParams = useCallback(
    (params: InferFilterFromConfig<C>) => {
      const omittedParams = lodash.omit(queryParams, Object.keys(valuesTemplate))
      push({
        ...location,
        search: queryString.stringify(
          { ...omittedParams, ...lodash.omitBy(params, lodash.isNil) },
          { sort: false },
        ),
      })
    },
    [queryParams, valuesTemplate, push, location],
  )

  return [filteredParams, setQueryParams]
}

export const useQuerySearchParam = (): [string, (query?: string) => void] => {
  const [param, setQueryParam] = useQueryParams({ query: PARSE_FILTER.string })
  const setQuery = useCallback(
    (query?: string) => {
      if (!query) {
        setQueryParam({} as { query: string })
        return
      }
      setQueryParam({ query })
    },
    [setQueryParam],
  )
  return [param.query, setQuery]
}

export const usePageSize = (name: string) => {
  const userPreferences = useAppSelector(selectUserPreferences)
  const dispatch = useAppDispatch()
  const lsKey = `${name}-pageSize`
  const history = useHistory()
  const historyState = history.location.state
  const queryParams = queryString.parse(history.location.search)
  const page = Number(queryParams.page) || 1
  let pageSize = Number(queryParams.pageSize ?? userPreferences[lsKey]) || DEFAULT_PAGE_SIZE
  let setPageSize

  if (!MOVING_MODALS.some((tableName) => tableName === name)) {
    setPageSize = (pageSizeInput = DEFAULT_PAGE_SIZE) => {
      const pageSize = Math.min(MAX_TABLE_SIZE, Math.floor(pageSizeInput))
      dispatch(setUserPreferencesItems({ [lsKey]: pageSize }))
      history.push({
        pathname: history.location.pathname,
        search: queryString.stringify({ ...queryParams, pageSize, page: 1 }, { sort: false }),
      })
    }
  } else {
    pageSize = DEFAULT_MOVING_MODAL_PAGE_SIZE
  }

  const setPage = (page = 1) => {
    history.push({
      pathname: history.location.pathname,
      search: queryString.stringify({ ...queryParams, page }, { sort: false }),
      state: historyState,
    })
  }

  const pagination = useMemo(
    () => ({ offset: (page - 1) * pageSize, limit: pageSize }),
    [page, pageSize],
  )

  return {
    page,
    pageSize,
    setPage,
    setPageSize,
    pagination,
  }
}

export const useSort = (name: string, defaultSort: string) => {
  const userPreferences = useAppSelector(selectUserPreferences)
  const dispatch = useAppDispatch()
  const history = useHistory()

  const sortKey = `${name}-sort`
  const sortOrderKey = `${name}-sortOrder`

  const query = queryString.parse(history.location.search)
  const setItems = useCallback(
    (key: string, value: string) => dispatch(setUserPreferencesItems({ [key]: value })),
    [dispatch],
  )

  useEffect(() => {
    if (query.sort) {
      setItems(sortKey, String(query.sort))
    }
    if (query.sortOrder) {
      setItems(sortOrderKey, String(query.sortOrder))
    }
  }, [query.sort, query.sortOrder, setItems, sortKey, sortOrderKey])

  const sort = (userPreferences[sortKey] as string) || defaultSort
  const sortOrder = (userPreferences[sortOrderKey] as string) || sortTypes.descending

  const setSort = (sortByField: string, newSortOrder?: string | null) => {
    const sortParams: ISortParams = { sort: sortByField, page: 1 }
    if (newSortOrder) {
      sortParams.sortOrder = newSortOrder
      setItems(sortOrderKey, newSortOrder)
    } else if (sort !== sortByField) {
      sortParams.sortOrder = sortTypes.descending
      setItems(sortOrderKey, sortTypes.descending.toString())
    } else {
      sortParams.sortOrder =
        newSortOrder || sortOrder === sortTypes.descending
          ? sortTypes.ascending
          : sortTypes.descending

      setItems(
        sortOrderKey,
        newSortOrder ??
          (sortOrder === sortTypes.descending
            ? sortTypes.ascending
            : sortTypes.descending
          ).toString(),
      )
    }

    setItems(sortKey, String(sortByField))

    history.replace({
      pathname: history.location.pathname,
      search: queryString.stringify({ ...query, ...sortParams }, { sort: false }),
      state: history.location.state,
    })
  }

  const order = useMemo(() => [[sort, sortOrder]], [sort, sortOrder])

  return {
    setSort,
    sort,
    sortOrder,
    order,
  }
}

export const useTableState = (name: string, options: { defaultSort: string }) => {
  const userPreferences = useAppSelector(selectUserPreferences)
  const dispatch = useAppDispatch()
  const sortKey = `${name}-sort`
  const sortOrderKey = `${name}-sortOrder`
  const pageSizeKey = `${name}-pageSize`

  const sort = (userPreferences[sortKey] as string) || options.defaultSort
  const sortOrder = (userPreferences[sortOrderKey] as string) || sortTypes.descending
  const pageSize = (userPreferences[pageSizeKey] as number) || DEFAULT_PAGE_SIZE

  const setItem = useCallback(
    (key: string, value: string | number) => dispatch(setUserPreferencesItems({ [key]: value })),
    [dispatch],
  )
  const [pageState, setPageState] = useState(1)

  const setPage = useCallback((page = 1) => {
    setPageState(page)
  }, [])

  const setSort = useCallback(
    (sortByField: string) => {
      if (sort !== sortByField) {
        setItem(sortKey, sortByField)
        setItem(sortOrderKey, sortTypes.descending)
      } else {
        setItem(
          sortOrderKey,
          sortOrder === sortTypes.descending ? sortTypes.ascending : sortTypes.descending,
        )
      }

      setPage(1)
    },
    [sort, setPage, setItem, sortKey, sortOrderKey, sortOrder],
  )

  const setPageSize = useCallback(
    (pageSizeInput = DEFAULT_PAGE_SIZE) => {
      const pageSize = Math.min(MAX_TABLE_SIZE, Math.floor(pageSizeInput))
      setItem(pageSizeKey, pageSize.toString())
    },
    [pageSizeKey, setItem],
  )

  const paginationConfig = useMemo(
    () => ({
      page: Number(pageState),
      pageSize: Number(pageSize),
      setPage,
    }),
    [pageState, pageSize, setPage],
  )

  const sortConfig = useMemo(
    () => ({
      sortKey,
      sortOrder,
      setSort,
    }),
    [sortOrder, setSort, sortKey],
  )

  return {
    paginationConfig,
    setPageSize,
    pagination: { offset: (pageState - 1) * Number(pageSize), limit: pageSize },
    sortConfig,
    order: [[sort, sortOrder]],
  }
}

const useTableColumnsStorage = (name: string) => {
  const userPreferences = useAppSelector(selectUserPreferences)
  const dispatch = useAppDispatch()
  const lsKey = `${name}-columns`
  const tableColumnsState: ColumnState[] = (userPreferences[lsKey] as ColumnState[]) || []
  const setTableColumnsState = (newState: ColumnState[]) => {
    dispatch(setUserPreferencesItems({ [lsKey]: newState }))
  }

  return {
    tableColumnsState: tableColumnsState,
    setTableColumnsState,
  }
}

export const useColumns = <T extends ITablesExtendsData>(
  name: string,
  defaultColumns: ColumnType<T>[] = [],
) => {
  const { tableColumnsState, setTableColumnsState } = useTableColumnsStorage(name)

  const columnsDefaultState = useMemo(
    () => getColumnsDefaultState(defaultColumns),
    [defaultColumns],
  )

  const columns = useMemo(() => {
    const columnsState = tableColumnsState.length ? tableColumnsState : columnsDefaultState
    const columnsConfig: ColumnType<T>[] = []

    columnsState.forEach((columnState) => {
      const column = defaultColumns.find(
        (column) => column.dataIndex && getColumnKey(column.dataIndex) === columnState.key,
      )
      if (column && columnState.visible) {
        columnsConfig.push(column)
      }
    })

    return columnsConfig
  }, [defaultColumns, tableColumnsState, columnsDefaultState])

  const resetState = useCallback(() => {
    setTableColumnsState(columnsDefaultState)
  }, [columnsDefaultState, setTableColumnsState])

  return {
    columns,
    tableColumnsState: tableColumnsState.length ? tableColumnsState : columnsDefaultState,
    setTableColumnsState,
    resetState,
  }
}

export const useActualTotal = (total: number, shouldUpdate: boolean) => {
  const totalRef = useRef(total)

  if (shouldUpdate && totalRef.current !== total) {
    totalRef.current = total
  }

  return totalRef.current
}

export const useTableViewStorage = (name: string) => {
  const userPreferences = useAppSelector(selectUserPreferences)
  const dispatch = useAppDispatch()
  const lsKey = `${name}-view`
  const tableViewState = (userPreferences[lsKey] as ViewResourceEnum) || ViewResourceEnum.card
  const setTableViewState = (newView: ViewResourceEnum) =>
    dispatch(setUserPreferencesItems({ [lsKey]: newView }))

  return { tableViewState, setTableViewState }
}
