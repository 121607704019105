import lodash from 'lodash'

import { SortUiEnum } from 'components/uiKit/SortPopover/types'
import { EmployeePreferencesTab } from 'gql/__generated__/graphql'
import { SectionTypeEnum } from 'services/Store/Project/enums'
import { t } from 'services/Translation'
import { getEnumLabel, getEnumOption } from 'utils/enum'

import { TemplatePickerMode, TemplateStepperState, UiTemplateTags } from './types'

export const BASE_TAGS: Record<string, { value: UiTemplateTags; label: string }[]> = {
  [SectionTypeEnum.landing]: [
    getEnumOption('UiTemplateTags', UiTemplateTags.all),
    getEnumOption('UiTemplateTags', UiTemplateTags.text),
    getEnumOption('UiTemplateTags', UiTemplateTags.image),
    getEnumOption('UiTemplateTags', UiTemplateTags.list),
    getEnumOption('UiTemplateTags', UiTemplateTags.multimedia),
    getEnumOption('UiTemplateTags', UiTemplateTags.interactive),
    getEnumOption('UiTemplateTags', UiTemplateTags.quote),
    getEnumOption('UiTemplateTags', UiTemplateTags.separators),
    getEnumOption('UiTemplateTags', UiTemplateTags.affirmation),
    getEnumOption('UiTemplateTags', UiTemplateTags.questions),
  ],
  [SectionTypeEnum.test]: [
    getEnumOption('UiTemplateTags', UiTemplateTags.start),
    getEnumOption('UiTemplateTags', UiTemplateTags.questions),
    getEnumOption('UiTemplateTags', UiTemplateTags.end),
  ],
  [SectionTypeEnum.cover]: [getEnumOption('UiTemplateTags', UiTemplateTags.cover)],
}

const tabs: { value: EmployeePreferencesTab; label: string }[] = [
  getEnumOption('EmployeePreferencesTab', EmployeePreferencesTab.collections),
  getEnumOption('EmployeePreferencesTab', EmployeePreferencesTab.recently_used),
  getEnumOption('EmployeePreferencesTab', EmployeePreferencesTab.favorites),
  getEnumOption('EmployeePreferencesTab', EmployeePreferencesTab.archived),
]

export const getTabs = (mode: TemplatePickerMode) => {
  if (mode === TemplatePickerMode.change) {
    return tabs.filter((tab) => tab.value !== EmployeePreferencesTab.archived)
  }
  return tabs
}

export const singleTags = [UiTemplateTags.start, UiTemplateTags.end, UiTemplateTags.cover]

export const allTags = lodash.uniqBy(lodash.flatten(Object.values(BASE_TAGS)), 'value')

export function getTemplateStepperState(
  completed: boolean,
  disabled: boolean,
  alert: boolean,
  active: boolean,
) {
  if (completed) {
    return TemplateStepperState.completed
  }
  if (disabled) {
    return TemplateStepperState.disabled
  }
  if (alert) {
    return TemplateStepperState.alert
  }
  if (active) {
    return TemplateStepperState.active
  }
  return TemplateStepperState.notSelected
}

interface IBreadcrumbItem {
  id?: string
  name: string
}

export const generateBreadcrumbItems = (
  search?: string,
  activeCollection?: { id: string; name: string } | null,
  tab?: EmployeePreferencesTab,
): IBreadcrumbItem[] => {
  const baseBreadcrumbItems: IBreadcrumbItem[] = tab
    ? [{ name: getEnumLabel('EmployeePreferencesTab', tab) }]
    : [{ name: t('page.templates.title') }]

  if (activeCollection && tab === EmployeePreferencesTab.collections) {
    baseBreadcrumbItems.push({ id: activeCollection.id, name: activeCollection.name })
  }

  if (search) {
    baseBreadcrumbItems.push({ name: t('uiKit.breadcrumb.search'), id: 'search' })
  }

  return baseBreadcrumbItems
}

export const notLandingTab: string[] = [UiTemplateTags.start, UiTemplateTags.end]

export const SORT_OPTIONS = [
  getEnumOption('SortUiEnum', SortUiEnum.NAME_ASC),
  getEnumOption('SortUiEnum', SortUiEnum.NAME_DESC),
]

export const employeePreferencesToOrder = (order: any) => {
  return order && `${order.field}${lodash.capitalize(order.value)}`
}
