import { genField } from 'components/controls/Field'
import { t } from 'services/Translation'

export interface IFileFormValues {
  name: string
}

const name = genField({
  label: t('input.label.name'),
  name: 'name',
  type: 'text',
  params: { placeholder: t('input.placeholder.name'), autoFocus: true },
  rules: [
    { max: 150, message: t('input.error.folderNameLength') },
    { required: true, message: t('input.error.required', { field: t('input.label.name') }) },
    { whitespace: true, message: t('input.error.whitespace') },
  ],
})

export const FILES_AND_GROUPS_FIELDS = { name }

export const InputErrorValues = {
  GROUP_NAME_NOT_UNIQUE: t('input.error.nameExist'),
}
