import cn from 'classnames'
import React, { useCallback } from 'react'

import EditorCommentBubble from 'components/comments/EditorCommentBubble'
import EditorCreateComment from 'components/comments/EditorCreateComment'
import EditorElementComponent from 'components/editor-v2/EditorElements/EditorElementComponent'
import { useEditorCanvasMouse } from 'components/editor-v3/context/EditorContext/actions'
import { getElement } from 'components/editor-v3/context/EditorContext/selectors/block'
import { getShellName } from 'components/editor-v3/context/EditorContext/selectors/blockLabels'
import { NodeType } from 'components/editor-v3/types/date.enums'
import { scrollProps } from 'services/Scroll/ScrollService'
import { setElement, setHighlight, updateBlock } from 'services/Store/Project/actions'
import { useProjectContext, useProjectDispatch } from 'services/Store/Project/hooks'
import {
  getElementState,
  getIsActiveNode,
  getIsHighlighted,
  getBlockWaiting,
  getIsCommenting,
  getIsCommentedElement,
  getIsOpenComment,
} from 'services/Store/Project/selectors'
import { ElementState } from 'services/Store/Project/types'
import { selectRealtimeUsers } from 'services/Store/Users/selectors'
import { useAppSelector } from 'services/Store/hooks'
import { testProps } from 'utils/test/qaData'

import { AppMode } from '../../../../../../services/Store/Project/enums'
import { FCNode } from '../../Node/types'
import ControlSelection from '../../controls/ControlSelection'
import ElementControls from '../../controls/ElementControls'
import s from './EditorShell.module.scss'
import { useCustomMode } from './useCustomMode'

const EditorShell: FCNode<NodeType.shell> = React.forwardRef(
  ({ node, block, style, wrapperStyle, mode }, ref) => {
    const { uuid: blockId } = block
    const { id: nodeId, elementId, type } = node
    const dispatch = useProjectDispatch()
    const users = useAppSelector((state) => selectRealtimeUsers(state, { nodeId }))
    const waiting = useProjectContext(getBlockWaiting, blockId)
    const isActive = useProjectContext(getIsActiveNode, nodeId)
    const isHighlight = useProjectContext(getIsHighlighted, nodeId)
    const isCommented = useProjectContext(getIsCommentedElement, elementId)
    const isCommenting = useProjectContext(getIsCommenting, blockId, elementId)
    const isOpenedComment = useProjectContext(getIsOpenComment, { blockId, nodeId, elementId })
    const elementState = useProjectContext(getElementState, elementId || '')
    const [nextMode, setCustomMode] = useCustomMode(mode, isActive)
    const element = elementId ? getElement(block, elementId) : null
    const label = getShellName(block, nodeId)

    const onChange = useCallback(
      (value: unknown) => {
        dispatch(updateBlock({ id: blockId, name: `elements.${element?.id}.value`, value }))
      },
      [dispatch, blockId, element?.id],
    )

    const handleSetState = useCallback(
      (value: ElementState) => {
        if (element?.id) {
          dispatch(setElement({ id: element.id, value }))
        }
      },
      [dispatch, element?.id],
    )

    const onMouseEnter = () => !isActive && dispatch(setHighlight(nodeId))

    const onMouseLeave = () => !isActive && dispatch(setHighlight(null))

    const mouseHandlers = useEditorCanvasMouse({ mode: nextMode.editorMode, blockId, nodeId })

    return (
      <div
        {...scrollProps(nodeId)}
        {...testProps({ el: 'element', name: element?.type, id: element?.id })}
        className={cn(s.wrapper, { [s.fillMode]: mode.editorMode === AppMode.fill })}
        ref={ref}
        style={wrapperStyle}
      >
        <div
          {...mouseHandlers}
          className={cn(s.shell, [s[type], { [s.zeroLineHeight]: element?.type === 'image' }])}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          style={style}
        >
          {element && (
            <EditorElementComponent
              block={block}
              element={element}
              elementState={elementState}
              mode={nextMode}
              onChange={onChange}
              setState={handleSetState}
              shellStyles={style}
              waiting={waiting}
            />
          )}
          {isCommenting && <EditorCreateComment blockId={block.uuid} elementId={elementId} />}
          {isCommented && (
            <EditorCommentBubble blockId={blockId} elementId={elementId} nodeId={nodeId} />
          )}
          {element && (
            <ElementControls
              active={isActive}
              block={block}
              customMode={nextMode}
              element={element}
              mode={mode}
              onChange={onChange}
              setAppMode={setCustomMode}
            />
          )}
          {Boolean(
            element && (isActive || isHighlight || isCommenting || isOpenedComment || users.length),
          ) && (
            <ControlSelection
              active={isActive}
              highlighted={isHighlight || isCommenting || isOpenedComment}
              label={label}
              name='shellSelection'
              users={users}
            />
          )}
        </div>
      </div>
    )
  },
)

EditorShell.displayName = 'EditorShell'

export default React.memo(EditorShell)
