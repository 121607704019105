import { SCHEMA } from '@leenda/editor/lib/brand'

import { genField } from 'components/controls/Field'
import { genFormSchema, FONT_SCHEMA_FIELDS } from 'services/Branding/genFormSchema'
import { t } from 'services/Translation'

export const STYLE_FORM_SCHEMA = genFormSchema(SCHEMA.link.style, {
  icon: {
    META: { hidden: true },
    backgroundImage: { label: t('elements.link.schema.linkIcon'), params: { preview: false } },
  },
  preview: {
    META: { label: t('elements.link.tags.preview') },
    backgroundColor: {},
    flexDirection: genField({
      type: 'select',
      label: t('elements.link.schema.imagePosition'),
      layout: ['horizontal', 'solid'],
      params: {
        options: [
          { label: t('input.option.right'), value: 'row' },
          { label: t('input.option.left'), value: 'row-reverse' },
        ],
        fluid: true,
      },
    }),
    borderRadius: { params: { max: 50 } },
    borderStyle: {},
    borderColor: {},
    borderWidth: { params: { min: 1, max: 8 } },
  },
  image: {
    META: { label: t('elements.link.tags.image') },
    borderRadius: { params: { max: 50 } },
    borderStyle: {},
    borderColor: {},
    borderWidth: { params: { min: 1, max: 8 } },
  },
})

export const FONT_FORM_SCHEMA = genFormSchema(SCHEMA.link.font, {
  link: {
    ...FONT_SCHEMA_FIELDS.link,
    ...FONT_SCHEMA_FIELDS.base,
    META: { label: t('elements.link.tags.link') },
  },
  h3: {
    ...FONT_SCHEMA_FIELDS.h3,
    META: { label: t('elements.link.tags.header') },
  },
  base: {
    ...FONT_SCHEMA_FIELDS.base,
    META: { label: t('elements.link.tags.description') },
  },
})
