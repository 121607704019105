import { createDeferred } from 'utils/defered'

import {
  ModalComponent,
  MCWithParams,
  MCWithoutParams,
  useUpdateModalData,
  useModalContext,
  getLastModalIndex,
} from './ModalContext'
import ModalMount from './ModalMount'

export const useOpenModal = <C extends ModalComponent<P, R>, P, R, Keys extends keyof P>(
  ...[component, params]: C extends MCWithoutParams<R>
    ? [MCWithoutParams<R>]
    : C extends MCWithParams<P, R>
    ? [MCWithParams<P, R>, Pick<P, Keys>]
    : never
) => {
  const updateModalData = useUpdateModalData(component)
  const modalContext = useModalContext()

  const open = (
    openParams: C extends MCWithParams<P, R>
      ? Exclude<keyof P, Keys> extends never
        ? Partial<P> | void
        : Partial<P> & Omit<P, Keys>
      : undefined,
  ) => {
    const deferred = createDeferred<R>()
    updateModalData((modalData) => ({
      ...modalData,
      open: true,
      index: getLastModalIndex(modalContext.modals) + 1,
      params: openParams ? ({ ...params, ...openParams } as unknown as P) : (params as P),
      deferred,
    }))
    return deferred.promise
  }
  const mount = <ModalMount component={component} userMount />
  return {
    open,
    mount,
  }
}
