import React from 'react'

import '../EditorElementComponent.scss'
import ErrorBoundary from './ErrorBoundary'

interface IProps {
  children: React.ReactElement
}

const Decorators: React.FC<IProps> = ({ children }) => {
  return <ErrorBoundary>{children}</ErrorBoundary>
}

export default Decorators
