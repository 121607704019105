import cn from 'classnames'
import React, { useMemo } from 'react'

import { ColumnActionUiEnum } from 'components/editor-v3/Editor/types'
import { useEditorCanvasMouse } from 'components/editor-v3/context/EditorContext/actions'
import { hasContent } from 'components/editor-v3/context/EditorContext/selectors/block'
import { NodeType } from 'components/editor-v3/types/date.enums'
import { scrollProps } from 'services/Scroll/ScrollService'
import { AppMode } from 'services/Store/Project/enums'
import { useProjectContext } from 'services/Store/Project/hooks'
import { getIsActiveNode, getIsHighlighted } from 'services/Store/Project/selectors'
import { selectRealtimeUsers } from 'services/Store/Users/selectors'
import { useAppSelector } from 'services/Store/hooks'
import { t } from 'services/Translation'

import { FCNode } from '../../Node/types'
import ColumnControls from '../../controls/ColumnControls'
import ControlSelection from '../../controls/ControlSelection'
import s from './EditorColumn.module.scss'

const EditorColumn: FCNode<NodeType> = React.forwardRef(
  ({ children, node, style, level, block, mode }, ref) => {
    const isPro = mode.editorMode === AppMode.pro
    const isActive = useProjectContext(getIsActiveNode, node.id)
    const isHighlighted = useProjectContext(getIsHighlighted, node.id)

    const users = useAppSelector((state) => selectRealtimeUsers(state, { nodeId: node.id }))
    const isRoot = block.schema.rootId === node.id
    const mouseHandlers = useEditorCanvasMouse({
      mode: mode.editorMode,
      blockId: block.uuid,
      nodeId: node.id,
    })

    const controls = useMemo(() => {
      const arr: ColumnActionUiEnum[] = []
      if (isActive && isPro) {
        arr.push(ColumnActionUiEnum.ADD_ABSOLUTE)
        if (level > 0) {
          arr.push(ColumnActionUiEnum.DELETE)
          arr.push(ColumnActionUiEnum.ADD_COL_NEXT)
          arr.push(ColumnActionUiEnum.ADD_COL_PREV)
          arr.push(ColumnActionUiEnum.ADD_ROW_ABOVE)
          arr.push(ColumnActionUiEnum.ADD_ROW_BELOW)
          arr.push(ColumnActionUiEnum.SPLIT_HORIZONTAL)
          arr.push(ColumnActionUiEnum.SPLIT_VERTICAL)
        }
        if (!isRoot && !hasContent(block, node.id)) {
          arr.push(ColumnActionUiEnum.ADD_SHELL)
          arr.push(ColumnActionUiEnum.SPLIT_HORIZONTAL)
          arr.push(ColumnActionUiEnum.SPLIT_VERTICAL)
        }
      }
      return arr
    }, [isRoot, isActive && isPro, block, node.id])

    return (
      <div
        {...scrollProps(node.id)}
        {...mouseHandlers}
        className={cn(s.column, {
          [s.fillMode]: mode.editorMode === AppMode.fill,
          [s.showGrid]: isPro,
        })}
        ref={ref}
        style={style}
      >
        {children}
        {isPro && Boolean(isActive || isHighlighted || users.length) && (
          <ColumnControls block={block} controls={controls} nodeId={node.id} />
        )}
        {(isActive || isHighlighted || Boolean(users && users.length)) && (
          <ControlSelection
            active={isActive}
            highlighted={isHighlighted}
            label={isRoot ? t('editor.root') : t('editor.column')}
            name='colSelection'
            users={users}
          />
        )}
      </div>
    )
  },
)

EditorColumn.displayName = 'EditorColumn'

export default React.memo(EditorColumn)
