import React from 'react'

import s from './TabPanel.module.scss'

export interface ITabPanelProps {
  name?: string
  title?: React.ReactNode | string
  disabled?: boolean
  children?: React.ReactNode
  active?: boolean
  tabKey: string
}

const TabPanel: React.FC<ITabPanelProps> = ({ children }) => {
  return <div className={s.root}>{children}</div>
}

export default TabPanel
