import { SliderSchemaType } from '@leenda/editor/lib/brand'
import { EditorElement, ProgressType, SliderElementValue } from '@leenda/editor/lib/elements'

import { genDataField } from 'components/controls/Field'
import RealtimeField from 'components/controls/RealtimeField'
import Collapse from 'components/uiKit/Collapse'
import { Typography } from 'components/uiKit/Typography'
import { DEFAULT_BOTTOM_INDENT, FIELD_BOTTOM_INDENT } from 'components/uiKit/Typography/constants'
import { FULL_COLLAPSE_PADDING, TITLE_COLLAPSE_PADDING } from 'constants/styles'
import ScrollService from 'services/Scroll/ScrollService'
import { ScrollContainerEnum } from 'services/Scroll/enums'
import { Block } from 'services/Store/Project/types'
import { t } from 'services/Translation'
import { chainName, getParent } from 'utils/form'

import { throughElementItemsArray } from '../../utils/formEffects'
import { Form } from './Slider.types'

type SliderElement = EditorElement<SliderElementValue, SliderSchemaType>

const genField = genDataField<Block>()

const slides = (
  onActive: (id: string) => void,
  onFocusItem: (index: string | null) => void,
  active?: string | null,
) =>
  genField({
    name: 'items',
    type: 'array',
    fields: [],
    effect: throughElementItemsArray,
    params: {
      onActive,
      onFocusItem,
      active,
      itemLabel: t('elements.slider.form.slide'),
      ordering: true,
      styleType: 'padding',
      min: 1,
      max: 184,
    },
  })

const hasLoop = genField({
  name: 'hasLoop',
  type: 'segmented',
  label: t('elements.slider.form.hasLoop'),
  defaultValue: true,
  layout: 'horizontal',
})

const hasProgress = genField({
  name: 'hasProgress',
  type: 'segmented',
  label: t('input.label.progress'),
  defaultValue: true,
  layout: 'horizontal',
})

const type = genField({
  name: 'type',
  type: 'select',
  layout: 'horizontal',
  label: t('input.label.type'),
  defaultValue: 'dot',
  params: (block, { name }) => {
    const { parent } = getParent<SliderElementValue>(name, block)
    return {
      hidden: !parent?.hasProgress,
      options: [
        { value: ProgressType.dot, label: t('input.option.points') },
        { value: ProgressType.number, label: t('input.option.number') },
      ],
      fluid: true,
    }
  },
})

const hasFirst = genField({
  name: 'includeSlide.first',
  type: 'segmented',
  label: t('elements.slider.form.first'),
  defaultValue: true,
  layout: 'horizontal',
  params: (block, { name }) => {
    const { parent: element } = getParent<SliderElement>(name, block, 3)
    return {
      hidden: element?.value.type === ProgressType.dot || !element?.value.hasProgress,
    }
  },
})

const hasLast = genField({
  name: 'includeSlide.last',
  type: 'segmented',
  label: t('elements.slider.form.last'),
  defaultValue: true,
  layout: 'horizontal',
  params: (block, { name }) => {
    const { parent: element } = getParent<SliderElement>(name, block, 3)
    return {
      hidden: element?.value.type === ProgressType.dot || !element?.value.hasProgress,
    }
  },
})

const SliderForm: Form = ({ name, element, state, setState }) => {
  const onActive = (id: string) =>
    ScrollService.scroll({ container: ScrollContainerEnum.toolbar, id }, null)
  const onFocus = (value: string | null) => setState({ ...state, active: value })

  return (
    <>
      <Collapse
        header={t('elements.slider.form.settings')}
        iconPosition='right'
        name='settings'
        paddings={FULL_COLLAPSE_PADDING}
        initialOpen
      >
        <Typography indents={DEFAULT_BOTTOM_INDENT} styleType='hint'>
          {t('elements.slider.form.loopHint')}
        </Typography>
        <RealtimeField config={chainName(name, hasLoop)} />
        <RealtimeField config={chainName(name, hasProgress)} />
        <RealtimeField config={chainName(name, type)} />
        {element.value.type === ProgressType.number && element.value.hasProgress && (
          <Typography indents={DEFAULT_BOTTOM_INDENT} styleType='hint'>
            {t('elements.slider.form.includeSlidesHint')}
          </Typography>
        )}
        <RealtimeField config={chainName(name, hasFirst)} />
        <RealtimeField config={chainName(name, hasLast)} />
      </Collapse>
      <Collapse
        header={t('elements.slider.form.slides')}
        iconPosition='right'
        name='image'
        paddings={TITLE_COLLAPSE_PADDING}
        initialOpen
      >
        <Typography indents={FIELD_BOTTOM_INDENT} styleType='hint'>
          {t('elements.slider.form.moveSlideHint')}
        </Typography>
        <RealtimeField config={chainName(name, slides(onActive, onFocus, state?.active))} />
      </Collapse>
    </>
  )
}

export default SliderForm
