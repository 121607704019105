import { ColorResult, Color as RColor, RGBColor } from 'react-color'

import { Color, IColorOption } from './ColorPicker.types'

const HEX_SYMBOLS = /[0-9A-F]+/

export const toColor = (value?: string | RGBColor, options?: IColorOption[]): Color => {
  if (value === 'transparent' || value === undefined) {
    return {
      hexa: '#00000000',
      rgba: { r: 0, g: 0, b: 0 },
    }
  }
  const color = options?.find((item) => item.value === value)?.color
  if (typeof value === 'string' && color) {
    value = color
  }

  if (typeof value === 'string') {
    if (value.startsWith('#')) {
      return {
        hexa: value.length <= 7 ? `${value}FF`.toUpperCase() : value.toUpperCase(),
        rgba: hex2rgb(value),
      }
    }
    if (value.startsWith('rgba')) {
      return {
        hexa: rgbS2hex(value),
        rgba: rgbS2rgb(value),
      }
    }
  } else if (isRGB(value)) {
    return {
      hexa: rgb2hex(value),
      rgba: value,
    }
  }
  return {
    hexa: '#FFFFFF',
    rgba: { r: 255, g: 255, b: 255, a: 1 },
  }
}

export const validateHEX = (value: string) => {
  if (value.startsWith('#')) {
    value = value.slice(1)
  }
  if (value.length > 6) {
    value = value.slice(0, 6)
  }
  const newValue = value.match(HEX_SYMBOLS)?.join('')
  return newValue
}

export const isRGB = (value?: RColor | ColorResult): value is RGBColor => {
  if (typeof value === 'object' && 'r' in value && 'g' in value && 'b' in value) {
    return true
  }
  return false
}

export const isColorResult = (value: RColor | ColorResult): value is ColorResult => {
  if (typeof value === 'object' && 'rgb' in value) {
    return true
  }
  return false
}

export const hex2rgb = (hex: string, a?: number): RGBColor => {
  const r = parseInt(hex.slice(1, 3), 16)
  const g = parseInt(hex.slice(3, 5), 16)
  const b = parseInt(hex.slice(5, 7), 16)
  const alpha = parseInt(hex.slice(7, 9) || 'FF', 16)

  return { r, g, b, a: a ?? alpha / 255 }
}

const rgb2hex = ({ r, g, b, a = 1 }: RGBColor) => {
  const RR = r.toString(16).padStart(2, '0')
  const GG = g.toString(16).padStart(2, '0')
  const BB = b.toString(16).padStart(2, '0')
  const AA = Math.round(a * 255)
    .toString(16)
    .padStart(2, '0')

  return `#${RR}${GG}${BB}${AA}`.toUpperCase()
}

const rgbS2hex = (value: string) => rgb2hex(rgbS2rgb(value))

const rgbS2rgb = (value: string): RGBColor => {
  const rgb = value.replace(/\s/g, '').match(/^rgba?\((\d+),(\d+),(\d+),?([^,\s)]+)?/i)
  const r = Number(rgb?.[1])
  const g = Number(rgb?.[2])
  const b = Number(rgb?.[3])
  const a = Number(rgb?.[4])
  return { r, g, b, a }
}
