import { useSubscription } from '@apollo/client'

import { FileMetaGroupsSearch } from 'gql/__generated__/graphql'

import { fileMetaAndGroupCreate, fileMetaAndGroupRemove, fileMetaCreate } from './files.cache'
import { fileMetaGroupSubscription, filesSubscription, projectFilesSubscription } from './files.gql'

// import { profileUpdateCacheAvatar } from './profile.cache'

export type FilesSubscriptionsTypes = {
  companyId: string
  projectId?: string | null
  parentId: string | null
  search?: FileMetaGroupsSearch
}

export const useFilesSubscription = (params: FilesSubscriptionsTypes) =>
  useSubscription(filesSubscription, {
    onData: (options) => {
      const data = options.data.data?.data
      switch (data?.type) {
        case 'create':
          fileMetaCreate(data, params)
          // profileUpdateCacheAvatar(data.files) //Useless
          break
      }
    },
    variables: {
      companyId: params.companyId,
      projectId: params.projectId,
      parentId: params.parentId || 'root',
    },
  })

export const useFileMetaGroupSubscription = (params: FilesSubscriptionsTypes) =>
  useSubscription(fileMetaGroupSubscription, {
    onData: (options) => {
      const data = options.data.data?.data
      switch (data?.type) {
        case 'create':
          fileMetaAndGroupCreate(data, params)
          break
        case 'delete':
          fileMetaAndGroupRemove(data, params)
          break
      }
    },
    variables: {
      companyId: params.companyId,
      projectId: params.projectId,
      parentId: params.parentId || 'root',
    },
  })

export const useProjectFilesSubscription = (projectId: string) =>
  useSubscription(projectFilesSubscription, {
    variables: {
      projectId,
    },
  })
