import { useCallback, useEffect } from 'react'
import { NodeEntry, Range, Editor, Transforms } from 'slate'

import { SlateInternal } from './RichTextConstants'
import { CustomEditor } from './RichTextTypes'

export const useSelectionDecorate = (editor: CustomEditor, active: boolean) => {
  useEffect(() => {
    active && !editor.selection && Transforms.select(editor, Editor.start(editor, []))
  }, [active, editor])

  return useCallback(
    ([_node, path]: NodeEntry): Range[] => {
      if (active && editor.selection && !Range.isCollapsed(editor.selection)) {
        const intersection = Range.intersection(editor.selection, Editor.range(editor, path))

        if (intersection == null) {
          return []
        }

        const range = {
          [SlateInternal.pseudoSelection]: true,
          ...intersection,
        }

        return [range]
      }
      return []
    },
    [active, editor],
  )
}
