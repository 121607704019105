import { PLATFORM_CONFIG } from 'platform/platformComfig'

import i18n from './i18n'

const DEFAULT_PARAMS = {
  PlatformName: PLATFORM_CONFIG.PlatformName,
  emailDomain: PLATFORM_CONFIG.emailDomain,
}

const t = (key: string, options?: Record<string, string | number | undefined | null>): string =>
  i18n.t(key, { ...options, ...DEFAULT_PARAMS })

export default t
