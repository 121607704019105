import { SidebarFontSchemaType } from '@leenda/editor/lib/brand'
import cn from 'classnames'
import { useCallback } from 'react'

import { ElementFontCss } from 'services/Branding/types'
import { useProjectContext } from 'services/Store/Project/hooks'
import { getSection, isDisabledNavigation } from 'services/Store/Project/selectors'
import { useNavigationAction } from 'services/Store/Project/triggers'
import { testProps } from 'utils/test/qaData'

import s from './CourseTitle.module.scss'

interface ICourseTitleProps {
  titleStyles: ElementFontCss<SidebarFontSchemaType>['title']
  projectName?: string
  hidden?: boolean
}

const CourseTitle: React.FC<ICourseTitleProps> = ({ projectName, titleStyles, hidden }) => {
  const cover = useProjectContext(getSection, { cover: true })
  const onNavigate = useNavigationAction()
  const isDisabledSidebarAndHome = useProjectContext(isDisabledNavigation)
  const disabled = isDisabledSidebarAndHome || !cover
  const goFirstSection = useCallback(
    () => !disabled && onNavigate({ sectionId: cover?.id }),
    [disabled, onNavigate, cover],
  )

  if (!projectName || hidden) {
    return null
  }

  return (
    <div
      className={cn(s.root, disabled && s.active)}
      onClick={goFirstSection}
      {...testProps({ el: 'headerTitle', name: projectName })}
    >
      <span className={s.headerTitle} style={titleStyles}>
        {projectName}
      </span>
    </div>
  )
}
export default CourseTitle
