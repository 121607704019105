import { FileTypeEnum } from 'utils'

export const ACCEPT_MAP_FILES_EXTENSIONS: { [key in FileTypeEnum]: string[] } = {
  [FileTypeEnum.TEXT]: [
    'zip',
    'rar',
    'z7',
    'tgz',
    'docx',
    'doc',
    'docm',
    'xlsx',
    'xls',
    'xlsm',
    'pptx',
    'ppt',
    'pptm',
    'txt',
    'rtf',
    'pdf',
    'csv',
    'xml',
  ],
  [FileTypeEnum.IMAGE]: ['jpg', 'jpeg', 'png', 'svg', 'webp', 'gif', 'bmp', 'heic', 'heif'],
  [FileTypeEnum.AUDIO]: ['wav', 'mp3', 'ogg', 'aac', 'wma', 'm4a', 'flac', 'mid'],
  [FileTypeEnum.VIDEO]: ['avi', 'mp4', 'mov', 'mkv', 'mpeg', 'm4v'],
  [FileTypeEnum.FONT]: ['woff', 'woff2', 'ttf', 'otf'],
  [FileTypeEnum.TRANSLATE]: ['xliff', 'xlf', 'csv'],
  [FileTypeEnum.APPLICATION]: [],
}
