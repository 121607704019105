import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts'

import { useGetSizes } from '../ChartElement.hooks'
import { ChartElementType } from '../ChartTypes'
import { customTooltip, getXConfig, getYConfig } from '../helper'

const MARGINS = { left: 16, top: 24 }

export const BarChartRender: ChartElementType = ({ element, styles, mode, font }) => {
  const items = element.value.items
  const { width, height, onMeasure, rootWidth } = useGetSizes({ element, font, styles, mode })

  return (
    <ResponsiveContainer minHeight={Number(styles.shell?.minHeight) || 300} minWidth={rootWidth}>
      <BarChart barSize={48} data={element.value.items} margin={MARGINS}>
        <CartesianGrid stroke={styles.chart.color} vertical={false} />
        <XAxis
          {...getXConfig({
            styles,
            label: element.value.x,
            onMeasure,
            font,
            tickCount: element.value.items.length,
            height,
            showLabel: element.value.description,
            mode,
          })}
        />
        <YAxis
          {...getYConfig({
            font,
            styles,
            label: element.value.y,
            onMeasure,
            width,
            showLabel: element.value.description,
          })}
        />
        <Tooltip content={customTooltip} contentStyle={font.base} cursor={false} label='label' />
        <Bar dataKey='val'>
          {items.map((entry) => (
            <Cell fill={styles.chart.backgroundColor || element.value?.color} key={entry.value} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  )
}
