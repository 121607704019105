import React from 'react'

import { useProjectContext } from 'services/Store/Project/hooks'
import { getIsActiveNode, getIsHighlighted } from 'services/Store/Project/selectors'
import { Block } from 'services/Store/Project/types'
import { selectRealtimeUsers } from 'services/Store/Users/selectors'
import { useAppSelector } from 'services/Store/hooks'
import { t } from 'services/Translation'

import ControlSelection from '../ControlSelection'

interface IRowControlsProps {
  nodeId: string
  isActive: boolean
  block: Block
}

const RowControls: React.FC<IRowControlsProps> = ({ nodeId }) => {
  const active = useProjectContext(getIsActiveNode, nodeId)
  const highlighted = useProjectContext(getIsHighlighted, nodeId)
  const users = useAppSelector((state) => selectRealtimeUsers(state, { nodeId }))
  if (active || highlighted || users.length) {
    return (
      <ControlSelection
        active={active}
        highlighted={highlighted}
        label={t('editor.row')}
        name='rowSelection'
        users={users}
      />
    )
  }
  return null
}

export default RowControls
