import React, { ImgHTMLAttributes, useMemo, useState } from 'react'

import { EMPTY_OBJECT, preventDefault } from 'constants/commonConstans'
import { FileSchemaFragment } from 'gql/__generated__/graphql'

import s from './ResponsiveImage.module.scss'

interface IResponsiveImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  file?: FileSchemaFragment
  blackout?: string
}

const ResponsiveImage = React.forwardRef<HTMLImageElement, IResponsiveImageProps>(
  ({ file, ...rest }, ref) => {
    const [loading, setLoading] = useState(true)
    const imgWidth = file?.width || 1
    const imgHeight = file?.height || 1
    const canvas = useMemo(() => (loading ? { width: '100%' } : { display: 'none' }), [loading])
    const img = useMemo(
      () =>
        loading ? { ...rest.style, position: 'absolute', inset: 0 } : rest.style || EMPTY_OBJECT,
      [loading, rest.style],
    )
    const borderWidth = parseInt(rest.style?.borderWidth as string) || 1
    const handleLoad = () => setLoading(false)

    return (
      <>
        <canvas height={imgHeight} style={canvas} width={imgWidth} />
        <img
          {...rest}
          className={s.img}
          onDragStart={preventDefault}
          onLoad={handleLoad}
          ref={ref}
          style={img}
        />
        <div
          className={s.mask}
          style={{
            backgroundColor: rest.blackout,
            borderRadius: img.borderRadius,
            width: `calc(100% - ${borderWidth * 2}px)`,
            height: `calc(100% - ${borderWidth * 2}px)`,
            top: rest.style?.borderWidth,
            left: rest.style?.borderWidth,
          }}
        />
      </>
    )
  },
)

ResponsiveImage.displayName = 'ResponsiveImage'

export default ResponsiveImage
