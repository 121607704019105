import { DeepPartial } from '@leenda/editor/lib/utils/types'
import cn from 'classnames'
import lodash from 'lodash'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useHistory, matchPath, useLocation } from 'react-router'

import { KitSize } from 'components/uiKit/KitTypes'
import { useOpenModal } from 'components/uiKit/Modal'
import ConfirmationModal from 'components/uiKit/Modal/ConfirmationModal'
import { EMPTY_OBJECT } from 'constants/commonConstans'
import { t } from 'services/Translation'
import { testProps } from 'utils/test/qaData'

import { IFieldMeta, IOnChangeData } from '../Field/Field.types'
import s from './Form.module.scss'
import FormContext from './FormContext'
import { useFormValues, useFormMeta, useSubmitForm } from './hooks'
import { mergeCustomize } from './mergeCustomize'
import { AsyncFieldError, IFormMeta, inputErrorValuesTypes } from './types'

type FormProps<
  T,
  R extends { data?: { errors?: (AsyncFieldError | null)[] | null; data?: any } },
> = {
  children: React.ReactNode
  name: string
  onSubmit?: (
    fullValues: T,
    values: DeepPartial<T>,
    meta: IFormMeta,
  ) => Promise<R | void | null> | void
  initialValues?: DeepPartial<T>
  defaultValues?: T
  layout?: 'horizontal' | 'vertical'
  size?: KitSize
  onChangeValues?: (fullValues: T, values: DeepPartial<T>, meta: IFormMeta) => void
  inputErrorValues?: inputErrorValuesTypes
  onReset?: () => void
  fluid?: boolean
  autoComplete?: boolean
  disabled?: boolean
  blockOnExit?: boolean
}

const Form = <T, R extends { data?: { errors?: (AsyncFieldError | null)[] | null; data?: any } }>({
  children,
  name,
  layout = 'vertical',
  size = KitSize.M,
  initialValues = EMPTY_OBJECT as DeepPartial<T>,
  defaultValues,
  inputErrorValues,
  fluid,
  onSubmit,
  onChangeValues,
  onReset,
  autoComplete = true,
  disabled = false,
  blockOnExit,
}: FormProps<T, R>) => {
  const [values, setValues] = useFormValues(initialValues)
  const [metas, setMetas] = useFormMeta(EMPTY_OBJECT)
  const showConfirmModal = useOpenModal(ConfirmationModal)
  const history = useHistory()
  const path = useLocation().pathname

  const resetForm = useCallback(() => setMetas({ meta: EMPTY_OBJECT }), [setMetas])
  const fullValues = useMemo(() => values as T, [values]) // TODO: delete fullValues

  const inheritValues = useMemo(
    () => lodash.mergeWith({}, defaultValues, fullValues, mergeCustomize),
    [defaultValues, fullValues],
  )

  const formMeta = useMemo(
    () =>
      Object.values(metas).reduce<IFormMeta>(
        (acc, cur) => ({
          dirty: Boolean(acc.dirty || cur.dirty),
          touched: Boolean(acc.touched || cur.touched),
          valid: !(!acc.valid || !(cur.valid ?? true) || cur.commonError),
          errors: cur.error ? [...acc.errors, cur.error] : acc.errors,
        }),
        { dirty: false, touched: false, valid: true, errors: [] },
      ),
    [metas],
  )

  const onChangeMeta = useCallback((name?: string, meta?: IFieldMeta) => {
    if (name && meta) {
      setMetas({ name, meta })
    }
  }, [])

  const [submit, submitting] = useSubmitForm({
    onSubmit,
    formMeta,
    fullValues,
    values: values as DeepPartial<T>,
    metas,
    resetForm,
    onChangeMeta,
    inputErrorValues,
    setMetas,
  })

  const onChange = useCallback(
    ({ name, value, meta }: IOnChangeData) => {
      setValues({ name, value })
      if (meta) {
        setMetas({ name, meta })
      }
    },
    [metas],
  )

  const onSubmitHandle = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    return submit()
  }

  const resetFields = useCallback(() => {
    onReset && onReset()
    setValues({ value: initialValues })
  }, [onReset, initialValues])

  const handleExit = async (path: string, unblock?: any) => {
    const result = await showConfirmModal.open()
    if (result) {
      unblock()
      history.push(path)
    }
  }

  useEffect(() => {
    if (onChangeValues) {
      onChangeValues(fullValues, values as DeepPartial<T>, formMeta)
    }
  }, [fullValues, values, formMeta])

  useEffect(() => {
    if (initialValues) {
      setValues({ value: initialValues })
    }
  }, [initialValues, setValues])

  useEffect(() => {
    if (formMeta.dirty && blockOnExit) {
      const onBeforeUnload = (e: BeforeUnloadEvent) => {
        e.preventDefault()
        e.returnValue = t('modal.confirmation.title')
      }
      window.addEventListener('beforeunload', onBeforeUnload)
      let unblock: any = null

      unblock = history.block((location) => {
        const isCurrentPage = matchPath(location.pathname, { path })
        if (isCurrentPage) {
          return
        } else {
          handleExit(location.pathname, unblock)
        }
        return false
      })
      return () => {
        unblock()
        window.removeEventListener('beforeunload', onBeforeUnload)
      }
    } else {
      return () => {}
    }
  }, [history, metas, values, formMeta.dirty])

  const context = useMemo(
    () => ({
      initialValues,
      values,
      fullValues,
      metas,
      formMeta,
      resetFields,
      submit,
      onChange,
      onChangeMeta,
      defaultValues,
      inheritValues,
      layout,
      size,
      submitting,
      disabled,
    }),
    [
      initialValues,
      values,
      defaultValues,
      fullValues,
      inheritValues,
      metas,
      formMeta,
      resetFields,
      submit,
      onChange,
      onChangeMeta,
      layout,
      size,
      submitting,
      disabled,
    ],
  )

  return (
    <FormContext.Provider value={context}>
      <form
        {...testProps({ el: 'form', name })}
        autoComplete={!autoComplete ? 'off' : undefined}
        className={cn(s[layout], { [s.fluid]: fluid })}
        onSubmit={onSubmitHandle}
      >
        {children}
      </form>
    </FormContext.Provider>
  )
}

export default Form
