import { createSelector } from '@reduxjs/toolkit'
import * as R from 'ramda'

import { CommentMock } from 'components/comments/types'
import { CommentStatus } from 'gql/__generated__/graphql'
import { IProjectCommentsFilterValues, IProjectContext } from 'services/Store/Project/types'

import { AppMode } from '../../enums'

export const getActiveComment = ({ data, state }: IProjectContext) =>
  data.comments.find((comment) => comment.id === state.comments.opened?.commentId) || null

export const getActiveThread = ({ data, state }: IProjectContext) =>
  data.comments.find((comment) => comment.id === state.comments.opened?.threadId) || null

export const getComments = createSelector(
  (state: IProjectContext) => state.data.comments,
  (state: IProjectContext) => state.state.comments.filter,
  (_: IProjectContext, p?: { sectionId?: string; blockId?: string; elementId?: string | null }) =>
    p,
  (comments, filter, params) => R.filter(filterComment({ ...filter, ...params }), comments),
)

export const getNewCommentsCount = ({ data }: IProjectContext) =>
  R.filter(filterComment({ read: false, resolved: CommentStatus.pending }), data.comments).length

export const getIsCommentedBlock = (
  { data, state }: IProjectContext,
  blockId?: string,
  elementId?: null,
) => {
  if (!blockId) {
    return false
  }
  const comments = R.filter(
    filterComment({ ...state.comments.filter, blockId, elementId }),
    data.comments,
  )
  return comments.length > 0
}

export const getIsCommentedElement = ({ data, state }: IProjectContext, elementId?: string) => {
  if (!elementId) {
    return false
  }
  const comments = R.filter(filterComment({ ...state.comments.filter, elementId }))(data.comments)
  return comments.length > 0
}

export const getIsOpenComment = createSelector(
  (state: IProjectContext) => state.data.comments,
  (state: IProjectContext) => state.state.comments.opened,
  (_: IProjectContext, p: { blockId: string; elementId?: string; nodeId?: string }) => p,
  (comments, opened, params) => {
    const comment = comments.find((comment) => comment.id === opened?.threadId)
    return (
      opened?.blockId === params.blockId &&
      (comment?.elementId === params.elementId || params.nodeId === opened?.nodeId)
    )
  },
)

export const getCommentsFilter = ({ state }: IProjectContext) => state.comments.filter

export const getCommentForm = ({ state }: IProjectContext) => state.comments.form

export const getIsCommenting = (
  { state }: IProjectContext,
  blockId: string | null,
  elementId: 'any' | string | null | undefined,
) =>
  state.mode === AppMode.comment &&
  state.comments.form.blockId === blockId &&
  (elementId === 'any' || state.comments.form.elementId === elementId)
export const getPinnedComments = createSelector(
  (state: IProjectContext) => state.data.comments,
  (state: IProjectContext) => state.state.comments.pinned,
  (state: IProjectContext) => state.state.comments.opened,
  (comments, pinned, opened) => {
    const map = R.indexBy(
      R.prop('id'),
      comments.filter((comment) => comment.id !== opened?.threadId),
    )
    return pinned.map((id) => map[id]).filter(Boolean)
  },
)

export const getIsPinnedComment = ({ state }: IProjectContext, id: string) =>
  state.comments.pinned.includes(id)

export const getThreadId = ({ state }: IProjectContext) => state.comments.opened?.threadId
export const getCommentId = ({ state }: IProjectContext) => state.comments.opened?.commentId

const filterComment = (values: Partial<IProjectCommentsFilterValues>) => (comment: CommentMock) => {
  const { sectionId, blockId, elementId, parentId } = values
  const { createdById, resolved, read, answered, mentioned } = values
  const createdBy = comment.createdBy?.id || ''

  if (sectionId !== undefined && sectionId !== comment.sectionId) {
    return false
  }
  if (blockId !== undefined && blockId !== comment.blockUuid) {
    return false
  }
  if (elementId !== undefined && elementId !== comment.elementId) {
    return false
  }
  if (parentId !== undefined && parentId !== comment.parentId) {
    return false
  }
  if (R.isNotNil(read) && read !== Boolean(comment.isRead)) {
    return false
  }
  if (resolved && resolved !== comment.status) {
    return false
  }

  if (R.isNotNil(answered) && answered !== Boolean(comment.children?.length)) {
    return false
  }
  if (createdById?.length && !createdById.includes(createdBy || '')) {
    return false
  }
  if (mentioned?.length && !isMentioned(comment, mentioned)) {
    return false
  }

  return true
}

const isMentioned = (comment: CommentMock, ids: string[]): boolean | undefined =>
  comment.mentioned?.some(({ id }) => ids.includes(id)) ||
  comment.children?.some((c) => isMentioned(c, ids))
