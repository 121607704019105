import React from 'react'

export default class PortalTarget extends React.Component<{ id: string } | { [key: string]: any }> {
  shouldComponentUpdate() {
    return false
  }

  render() {
    return <div {...this.props} />
  }
}
