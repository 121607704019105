import { gql } from 'gql/__generated__'

export const pdfBuildFragment = gql(`
  fragment PdfBuildSchema on PdfBuild {
    id
    name
    pinned
    projectId
    employeeId
    size
    status
    type
    progress {
      progressStatus
      progress
      secondaryProgressCurrent
      secondaryProgressTotal
    }
    createdAt
    updatedAt
    lmsTarget {
      ...LmsTargetSchema
    }
  }
`)

export const exportSettingsFragment = gql(`
  fragment ExportSettingsSchema on ScormSettings {
    id
    courseThresholdStart
    courseThresholdFinish
    testThreshold
    debug
    sectionId
    videoResolution
    imageResolution
    version
    data {
      suspendDataType
      suspendDataInterval
      scoreType
      usedStatues
    }
    identifier
    manifestOrgId
    manifestItemId
    isCustomId
  }
`)

export const scormBuildFragment = gql(`
  fragment ScormBuildSchema on ScormBuild {
    id
    pinned
    name
    projectId
    version
    status
    createdAt
    size
    progress {
      progressStatus
      progress
      secondaryProgressCurrent
      secondaryProgressTotal
    }
    createdAt
    updatedAt
    settings {
      ...ExportSettingsSchema
    }
    employeeId
    lmsTarget {
      ...LmsTargetSchema
    }
  }
`)

export const scormBuildInitFragment = gql(`
  fragment ScormPackageSchema on ScormBuild {
    ...ScormBuildSchema
  }
`)
