import * as R from 'ramda'

import { IProjectContext, ISectionState } from 'services/Store/Project/types'

import { getSectionId } from '..'
import { sectionLens, sectionsLens } from '../../lenses'

export const getSectionsState = R.view(sectionsLens)

export const getSectionState = (
  state: IProjectContext,
  params?: { id?: string },
): ISectionState | null =>
  params ? R.view(sectionLens(params.id))(state) : R.view(sectionLens(getSectionId(state)))(state)
