"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MenuPositionType = exports.SidebarMenuType = void 0;
var SidebarMenuType;
(function (SidebarMenuType) {
    SidebarMenuType["fixed"] = "fixed";
    SidebarMenuType["drawer"] = "drawer";
})(SidebarMenuType || (exports.SidebarMenuType = SidebarMenuType = {}));
var MenuPositionType;
(function (MenuPositionType) {
    MenuPositionType["left"] = "left";
    MenuPositionType["right"] = "right";
})(MenuPositionType || (exports.MenuPositionType = MenuPositionType = {}));
