import {
  ButtonActionType,
  ButtonBlockAction,
  ButtonElementValue,
  ButtonContinueAction,
  ButtonNavigationAction,
} from '@leenda/editor/lib/elements'
import * as R from 'ramda'

import { BlockMode, SectionTypeEnum } from 'services/Store/Project/enums'
import { ProjectActionsTypes } from 'services/Store/Project/reducer'
import { MapType, Section } from 'services/Store/Project/types'
import { IBlockState } from 'services/Store/Project/types'
import { t } from 'services/Translation'

import { isTypeGuardAction } from './typeGuard'

const ALL_ACTIONS_OPTIONS: { value: ButtonBlockAction; label: string }[] = [
  { value: ButtonBlockAction.testStart, label: t('input.option.testStart') },
  { value: ButtonBlockAction.validate, label: t('input.option.validate') },
  { value: ButtonBlockAction.testEnd, label: t('input.option.testEnd') },
  { value: ButtonBlockAction.testRestart, label: t('input.option.testRestart') },
  { value: ButtonBlockAction.courseStart, label: t('input.option.courseStart') },
  { value: ButtonBlockAction.courseEnd, label: t('input.option.courseEnd') },
  { value: ButtonBlockAction.reset, label: t('input.option.reset') },
]

export const navigationActionOptions: { value: ButtonNavigationAction; label: string }[] = [
  { value: ButtonNavigationAction.nextSection, label: t('input.option.nextSection') },
  { value: ButtonNavigationAction.prevSection, label: t('input.option.prevSection') },
  { value: ButtonNavigationAction.custom, label: t('input.option.custom') },
]

const actionOptions: Record<ButtonActionType, { value: ButtonActionType; label: string }> = {
  [ButtonActionType.blockAction]: {
    value: ButtonActionType.blockAction,
    label: t('input.option.blockAction'),
  },
  [ButtonActionType.navigation]: {
    value: ButtonActionType.navigation,
    label: t('input.option.courseNavigation'),
  },
  [ButtonActionType.link]: { value: ButtonActionType.link, label: t('input.option.link') },
  [ButtonActionType.file]: { value: ButtonActionType.file, label: t('input.option.downloadFile') },
  [ButtonActionType.continue]: {
    value: ButtonActionType.continue,
    label: t('input.option.continue'),
  },
}

export const valueByType = {
  [ButtonActionType.navigation]: {
    actionType: ButtonActionType.navigation,
    action: ButtonNavigationAction.nextSection,
    label: t('elements.button.value.nextSection'),
  },
  [ButtonActionType.link]: {
    actionType: ButtonActionType.link,
    label: t('elements.button.value.link'),
  },
  [ButtonActionType.file]: {
    actionType: ButtonActionType.file,
    label: t('elements.button.value.file'),
  },
  [ButtonActionType.continue]: {
    actionType: ButtonActionType.continue,
    action: ButtonContinueAction.none,
    label: t('elements.button.value.continue'),
  },
}

export const valueByAction = {
  [ButtonBlockAction.courseStart]: {
    actionType: ButtonActionType.blockAction,
    action: ButtonBlockAction.courseStart,
    label: t('elements.button.value.courseStart.label'),
    secondLabel: t('elements.button.value.courseStart.second'),
  },
  [ButtonBlockAction.validate]: {
    actionType: ButtonActionType.blockAction,
    action: ButtonBlockAction.validate,
    label: t('elements.button.value.validate.label'),
    secondLabel: t('elements.button.value.validate.second'),
    thirdLabel: t('elements.button.value.validate.third'),
    fourthLabel: t('elements.button.value.validate.fourth'),
  },
  [ButtonBlockAction.testStart]: {
    actionType: ButtonActionType.blockAction,
    action: ButtonBlockAction.testStart,
    label: t('elements.button.value.testStart.label'),
  },
  [ButtonBlockAction.testEnd]: {
    actionType: ButtonActionType.blockAction,
    action: ButtonBlockAction.testEnd,
    label: t('elements.button.value.testEnd.label'),
    secondLabel: t('elements.button.value.testEnd.second'),
    thirdLabel: t('elements.button.value.testEnd.third'),
  },
  [ButtonBlockAction.testRestart]: {
    actionType: ButtonActionType.blockAction,
    action: ButtonBlockAction.testRestart,
    label: t('elements.button.value.testRestart.label'),
  },
  [ButtonBlockAction.courseEnd]: {
    actionType: ButtonActionType.blockAction,
    action: ButtonBlockAction.courseEnd,
    label: t('elements.button.value.courseEnd.label'),
  },
  [ButtonBlockAction.reset]: {
    actionType: ButtonActionType.blockAction,
    action: ButtonBlockAction.reset,
    label: t('elements.button.value.reset.label'),
  },
  [ButtonNavigationAction.nextSection]: {
    actionType: ButtonActionType.navigation,
    action: ButtonNavigationAction.nextSection,
    label: t('elements.button.value.nextSection.label'),
  },
  [ButtonNavigationAction.prevSection]: {
    actionType: ButtonActionType.navigation,
    action: ButtonNavigationAction.prevSection,
    label: t('elements.button.value.prevSection.label'),
  },
  [ButtonNavigationAction.custom]: {
    actionType: ButtonActionType.navigation,
    action: ButtonNavigationAction.custom,
    label: t('elements.button.value.custom.label'),
  },
}

export const getLastViewedSection = (section: Section[], viewedBlocks: MapType<IBlockState>) => {
  return [...section]
    .reverse()
    .find((section) => section.blocksOrder.some((bO) => viewedBlocks[bO]?.viewed))
}

interface IFilterByBlockAction {
  mode?: BlockMode
  sectionType?: SectionTypeEnum
}

const ACTIONS_IN_MODE: {
  [key in SectionTypeEnum]?: Partial<Record<BlockMode, ButtonBlockAction[]>>
} = {
  [SectionTypeEnum.test]: {
    [BlockMode.start]: [ButtonBlockAction.testStart],
    [BlockMode.end]: [ButtonBlockAction.testEnd, ButtonBlockAction.testRestart],
    [BlockMode.questions]: [
      ButtonBlockAction.validate,
      ButtonBlockAction.testEnd,
      ButtonBlockAction.reset,
    ],
  },
  [SectionTypeEnum.cover]: {
    [BlockMode.cover]: [ButtonBlockAction.courseStart],
  },
  [SectionTypeEnum.landing]: {
    [BlockMode.questions]: [ButtonBlockAction.validate],
    [BlockMode.view]: [ButtonBlockAction.courseEnd],
  },
  [SectionTypeEnum.chapter]: {},
}

export const ALLOWED_ACTIONS_IN_MODE: Record<BlockMode, ButtonActionType[]> = {
  [BlockMode.start]: [
    ButtonActionType.blockAction,
    ButtonActionType.navigation,
    ButtonActionType.link,
    ButtonActionType.file,
  ],
  [BlockMode.end]: [
    ButtonActionType.blockAction,
    ButtonActionType.navigation,
    ButtonActionType.link,
    ButtonActionType.file,
  ],
  [BlockMode.questions]: [
    ButtonActionType.blockAction,
    ButtonActionType.navigation,
    ButtonActionType.link,
    ButtonActionType.file,
  ],
  [BlockMode.cover]: [
    ButtonActionType.blockAction,
    ButtonActionType.navigation,
    ButtonActionType.link,
    ButtonActionType.file,
  ],
  [BlockMode.view]: [
    ButtonActionType.navigation,
    ButtonActionType.link,
    ButtonActionType.file,
    ButtonActionType.continue,
    ButtonActionType.blockAction,
  ],
}

export const getButtonActionTypesByMode = (mode?: BlockMode) => {
  return mode ? ALLOWED_ACTIONS_IN_MODE[mode].map((actionType) => actionOptions[actionType]) : []
}

export const filterByBlockAction = ({ mode, sectionType }: IFilterByBlockAction) => {
  const availableActions = sectionType && mode ? ACTIONS_IN_MODE[sectionType]?.[mode] : null

  if (!availableActions) {
    return []
  }

  return ALL_ACTIONS_OPTIONS.filter((option) => availableActions.includes(option.value))
}

interface IFIlterByNavigation {
  mode?: BlockMode
}

export const filterByNavigation = ({ mode }: IFIlterByNavigation) => {
  const options = navigationActionOptions

  return mode === 'cover' ? options.filter((elem) => elem.value !== 'prevSection') : options
}

export const hasLabel = (action: ButtonElementValue['action'], label: string) =>
  isTypeGuardAction(action) && R.has(label, valueByAction[action])

export const getButtonLabel = (
  map: Partial<Record<ProjectActionsTypes[keyof ProjectActionsTypes] | 'noop', string>>,
  action: ProjectActionsTypes[keyof ProjectActionsTypes] | 'noop',
) => map[action]
