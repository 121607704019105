import React, { useCallback } from 'react'

import { IControlProps } from 'components/controls/Field/Field.types'
import Select, { ISelectProps, SelectValueType } from 'components/uiKit/Select'

const FieldSelect = <V extends SelectValueType>({
  name,
  defaultValue,
  onChange,
  value,
  ...rest
}: IControlProps<ISelectProps<V>>) => {
  const onChangeWrapper = useCallback((value: V) => onChange({ name, value }), [name, onChange])
  return (
    <Select
      {...rest}
      name={name}
      onChange={onChangeWrapper}
      value={value !== undefined ? value : defaultValue}
    />
  )
}

export default FieldSelect
