import { RectangleSchemaType } from '@leenda/editor/lib/brand'

import { t } from 'services/Translation'

import DemoWrapper from '../../decorators/DemoWrapper'
import { IElementDemoProps } from '../../elements.types'
import EmptyDemo from '../EmptyDemo'

const RectangleDemo: React.FC<IElementDemoProps<RectangleSchemaType>> = () => (
  <DemoWrapper center>
    <EmptyDemo name={t('elements.rectangle.label')} />
  </DemoWrapper>
)

export default RectangleDemo
