import React from 'react'

import { KeyIconsType } from 'components/uiKit/Icon'

import { IPlacement } from './Dropdown/types'

export type ValueType = string | number | boolean | null

export interface IKitControl<V> extends KitAccessState {
  /**
   * String for QA auto tests.
   */
  name: string

  /**
   * The current value of the control.
   */
  value?: V

  /**
   * The default value of the control.
   */
  defaultValue?: V

  /**
   * A callback function that is called when the value of the control changes.
   * @param value The new value of the control.
   */
  onChange?: (value: V) => void

  /**
   * The size of the control.
   */
  size?: KitSize

  /**
   * A callback function that is called when the control loses focus.
   */
  onBlur?: () => void

  /**
   * A callback function that is called when the control gains focus.
   */
  onFocus?: () => void
}

export type KitAccessState = {
  /**
   * Specifies whether the control is disabled.
   */
  disabled?: boolean

  /**
   * Specifies whether the control is read-only.
   */
  readOnly?: boolean

  /**
   * Specifies whether the control is hidden.
   */
  hidden?: boolean
}

export interface IOption<V, LP extends ILabelProps = ILabelProps> {
  value: V
  label?: React.ReactNode | React.FC<LP>
  textLabel?: string
  disabled?: boolean
  hidden?: boolean
  testData?: string
  icon?: KeyIconsType
  tooltip?: string
  tooltipPlacement?: IPlacement
}

export interface ILabelProps {
  size?: KitSize
  disabled?: boolean
  error?: boolean
}

export type KitDimensions = Pick<
  React.CSSProperties,
  | 'width'
  | 'maxWidth'
  | 'minWidth'
  | 'height'
  | 'maxHeight'
  | 'minHeight'
  | 'flexBasis'
  | 'flexGrow'
  | 'flexShrink'
>

export type KitIndents = Pick<
  React.CSSProperties,
  | 'margin'
  | 'marginLeft'
  | 'marginTop'
  | 'marginRight'
  | 'marginBottom'
  | 'padding'
  | 'paddingLeft'
  | 'paddingTop'
  | 'paddingRight'
  | 'paddingBottom'
>

export enum KitSize {
  XS = 'xmini',
  S = 'mini',
  M = 'medium',
  L = 'large',
}

// can be extended by array, string etc.
export type RenderFunction<P> = (params: P) => React.ReactElement
