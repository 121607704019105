import { LinkProps } from 'react-router-dom'

export const isExternalLink = (to: LinkProps['to']) => {
  try {
    const path = typeof to === 'object' ? to.pathname || '' : typeof to === 'string' ? to || '' : ''
    const url = new URL(path, window.location.origin)
    const externalLink =
      url.hostname !== window.location.hostname || url.host !== window.location.host
    return { externalLink, path }
  } catch (err) {
    return { externalLink: false, path: '' }
  }
}
