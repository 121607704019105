import React, { Dispatch, SetStateAction, useEffect } from 'react'

import { ScrollRef } from 'components/LayoutPage/LayoutScroll/LayoutScroll'
import Empty, { IEmptyProps } from 'components/uiKit/Empty'
import InfiniteLoadGrid from 'components/uiKit/InfiniteLoadGrid'
import { t } from 'services/Translation'
import { FinderItem } from 'store/models'

import { Paths } from '../types'
import GridItem from './GridItem'
import GridItemModal from './GridItemModal'

interface IFinderCardProps {
  data: FinderItem[]
  onChangeSelection: Dispatch<SetStateAction<FinderItem[]>>
  onClickItem: (item: FinderItem) => void
  total: number
  selectedItems: FinderItem[]
  loading: boolean
  empty?: IEmptyProps
  clearQuery: () => void
  paths: Paths
  fetchMore?: () => Promise<unknown>
  isModal?: boolean
}

const FinderCard = React.forwardRef<ScrollRef, IFinderCardProps>(
  (
    {
      data,
      onChangeSelection,
      onClickItem,
      total,
      selectedItems,
      loading,
      clearQuery,
      paths,
      fetchMore,
      isModal,
    },
    ref,
  ) => {
    useEffect(() => {
      onChangeSelection([])
    }, [data, onChangeSelection])

    if (!loading && data.length === 0) {
      return <Empty icon='folder' text={t('common.nothingFound')} grow />
    }

    return (
      <InfiniteLoadGrid
        chunkLoading={loading}
        fetchMore={fetchMore}
        grid={{ width: '308px' }}
        hasMore={total > data.length}
        loading={loading}
        name='filePicker'
        padding='5px 0 20px'
        ref={ref}
      >
        {data.map((item) =>
          isModal ? (
            <GridItemModal
              clearQuery={clearQuery}
              item={item}
              key={item.id}
              onChangeSelection={onChangeSelection}
              onClick={onClickItem}
              selectedItems={selectedItems}
            />
          ) : (
            <GridItem
              clearQuery={clearQuery}
              item={item}
              key={item.id}
              onChangeSelection={onChangeSelection}
              paths={paths}
              selectedItems={selectedItems}
            />
          ),
        )}
      </InfiniteLoadGrid>
    )
  },
)

FinderCard.displayName = 'FinderCard'

export default FinderCard
