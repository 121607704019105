import React from 'react'

import RichText from 'components/form/RichText'
import { PreviewMode, AppMode } from 'services/Store/Project/enums'
import { useProjectContext } from 'services/Store/Project/hooks'
import { getIsActiveElement } from 'services/Store/Project/selectors'

import './RichTextElement.scss'
import { RichTextElementType } from './RichTextElement.types'

const RichTextElement: RichTextElementType = ({ element, mode, onChange, waiting, font }) => {
  const { id, value } = element
  const isFill = mode.previewMode === PreviewMode.editor && mode.editorMode === AppMode.fill
  const isActive = useProjectContext(getIsActiveElement, id)

  return (
    <RichText
      active={isActive}
      disabled={!isFill}
      name='richTextElement'
      onChange={onChange}
      styles={font}
      value={value}
      waiting={waiting}
    />
  )
}

export default React.memo(RichTextElement)
