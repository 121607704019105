import { SidebarFontSchemaType, SidebarSchemaType } from '@leenda/editor/lib/brand'
import { MenuPositionType, SidebarMenuType } from '@leenda/editor/lib/brand/types'
import cn from 'classnames'
import React, { useCallback } from 'react'

import { Description } from 'components/editor-v3/cource/controls/CourseSidebar/components/Description'
import { getIconData } from 'components/editor-v3/cource/controls/CourseSidebar/utils'
import Highlighter from 'components/uiKit/Highlighter'
import { ElementFontCss, ElementStyleCss } from 'services/Branding/types'
import { scrollProps } from 'services/Scroll/ScrollService'
import { useProjectContext } from 'services/Store/Project/hooks'
import { isSectionDisabled } from 'services/Store/Project/selectors'
import { Section } from 'services/Store/Project/types'
import { testPropsEl } from 'utils/test/qaData'

import s from './SectionCourseItem.module.scss'

export interface IDragCollectedProps {
  isDragging: boolean
}

interface ISectionCourseItemProps {
  item: Section
  onSelect?: (section: Section) => void
  searchValue?: string
  childrenList?: Section[]
  styles: ElementStyleCss<SidebarSchemaType>
  font: ElementFontCss<SidebarFontSchemaType>
  sectionId?: string
}

const SectionCourseItem: React.FC<ISectionCourseItemProps> = ({
  item,
  onSelect,
  searchValue,
  childrenList = [],
  styles,
  font,
  sectionId,
}) => {
  const { isChapter, id } = item
  const active = id === sectionId

  const handleSectionClick = useCallback((section: Section) => onSelect?.(section), [onSelect])
  const menuPosition = styles.value.position as MenuPositionType

  const fixedMenuRight =
    styles.menu.type === SidebarMenuType.fixed && menuPosition === MenuPositionType.right
  const disabled = useProjectContext(isSectionDisabled, item.id)
  const rootCn = cn(s.root, {
    [s.disabled]: disabled,
    [s.activeItem]: active,
    [s.chapter]: isChapter,
    [s.right]: fixedMenuRight,
  })
  const itemIconCn = cn(s.itemIcon, isChapter && s.itemIconChapter)
  const itemContentCn = cn(s.itemContent, active)

  const spaceBetweenSections = styles.margins.offsetYFromSection
  const offsetFromIcon = styles.margins.offsetFromIcon

  const { showIcon, IconImage, styleFontSection, icon } = getIconData(item.type, styles, font)

  const rootStyles = {
    ...styles.root,
    ...(active && { backgroundColor: styles.item__var.backgroundColor }),
    paddingTop: spaceBetweenSections,
    paddingBottom: spaceBetweenSections,
  }

  return (
    <div
      className={rootCn}
      onClick={() => handleSectionClick(item)}
      {...scrollProps(item.id)}
      style={rootStyles}
    >
      <div {...testPropsEl('sectionItem', { name: item.name }, { id: item.id })} className={s.item}>
        <div className={itemContentCn}>
          {showIcon && (
            <div className={itemIconCn} style={{ marginRight: offsetFromIcon }}>
              {IconImage ? (
                <div className={s.iconImage} style={{ backgroundImage: IconImage }} />
              ) : (
                icon
              )}
            </div>
          )}
          <div className={s.text} style={styleFontSection}>
            <Highlighter search={searchValue} text={item.name || ''} breakWord />
          </div>
        </div>
        <div className={s.description}>
          {showIcon && <div className={itemIconCn} style={{ marginRight: offsetFromIcon }} />}
          {styles.value.showDescription && (
            <Description childrenChapter={childrenList.length} font={font} item={item} />
          )}
        </div>
      </div>
    </div>
  )
}

export default SectionCourseItem
