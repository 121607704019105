import { DisplayError } from 'components/DataFallback'
import { BackUrlType, DataFallbackTypeEnum } from 'components/DataFallback/types'
import { DocumentTitle } from 'components/LayoutPage'
import { t } from 'services/Translation'

interface IProps {
  text?: string
  backUrl?: BackUrlType
}

const NotFound: React.FC<IProps> = ({
  text = t('page.protected.notFound.title', { entity: t('page.protected.notFound.page') }),
  backUrl,
}) => {
  return (
    <DocumentTitle title={text}>
      <DisplayError backUrl={backUrl} type={DataFallbackTypeEnum.commonError} />
    </DocumentTitle>
  )
}

export default NotFound
