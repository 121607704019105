import React from 'react'
import { useContext } from 'use-context-selector'

import { PreviewMode } from 'services/Store/Project/enums'

import TestMatchingContext from '../TestMatchingContext'
import DefaultElementEditor from './DefaultElementEditor'
import DefaultElementPreview from './DefaultElementPreview'

const COMPONENTS_MAP: Partial<Record<PreviewMode, React.FC>> = {
  editor: DefaultElementEditor,
}

const DefaultElement: React.FC = () => {
  const { mode } = useContext(TestMatchingContext)

  const Component = COMPONENTS_MAP[mode.previewMode] || DefaultElementPreview
  return <Component />
}

export default DefaultElement
