import { ShellNode } from 'components/editor-v3/types/data.types'
import { DeviceMode } from 'services/Store/Project/enums'

import { nodeWithDeviceMode } from './block'

const offsetsToSting = (offsets: Array<number | 'auto'> = []) =>
  Array(4)
    .fill(0)
    .map((_init, index) => (offsets[index] || 0) + 'px')
    .join(' ')

export const getShellStyle = (shell: ShellNode, deviceMode: DeviceMode) => {
  const deviceData = nodeWithDeviceMode(shell, deviceMode)
  let width = {}
  let height = {}
  if (deviceData.widthType === 'auto') {
    width = { width: 'auto' }
  } else if (deviceData.widthType === '100%') {
    width = { width: '100%' }
  } else if (deviceData.widthType === 'px') {
    width = { maxWidth: deviceData.maxWidth, minWidth: deviceData.minWidth }
  }
  if (deviceData.heightType === 'auto') {
    height = { height: 'auto' }
  } else if (deviceData.heightType === '100%') {
    height = height = { height: '100%' }
  } else if (deviceData.heightType === 'px') {
    height = { maxHeight: deviceData.maxHeight, minHeight: deviceData.minHeight }
  }
  return {
    style: {
      flexGrow: deviceData.flexGrow,
      ...width,
      ...height,
    },
    wrapperStyle: {
      margin: offsetsToSting(deviceData.margin),
      padding: offsetsToSting(deviceData.padding),
      justifyContent: deviceData.justifyContent,
      alignItems: deviceData.alignItems,
    },
  }
}
