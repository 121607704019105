import React, { ReactElement } from 'react'
import { Helmet } from 'react-helmet-async'

import Loader from '../../Loader'
import ModalBody from './ModalBody'
import ModalContainer from './ModalContainer'
import ModalFooter from './ModalFooter'
import ModalHeader from './ModalHeader'

interface IModalComponent {
  name: string
  title?: string
  styleType?: 'dialog' | 'free' | 'tabs' | 'crop' | 'fullScreen'
  width?: number
  maxWidth?: number
  minHeight?: number | '100%'
  loading?: boolean
  children?: React.ReactNode
}

const Modal: React.FC<IModalComponent> = ({
  children,
  minHeight,
  title,
  styleType = 'dialog',
  maxWidth,
  width,
  name,
  loading,
}) => {
  const childrenArray = React.Children.toArray(children) as ReactElement[]
  const headerComponent = childrenArray.find((element) => element.type === ModalHeader)
  const headerElement =
    headerComponent || (title && styleType === 'dialog' && <ModalHeader title={title} />)
  const footer = childrenArray.find((element) => element.type === ModalFooter)
  let content: ReactElement | boolean | undefined = childrenArray.find(
    (element) => element.type === ModalBody,
  )
  if (!content) {
    const body = childrenArray.filter(
      (element) => element.type !== ModalHeader && element.type !== ModalFooter,
    )
    content = body.length > 0 && <ModalBody>{body}</ModalBody>
  }

  const containerStyle = {
    ...(width && { width }),
    ...(maxWidth && { maxWidth: `min(calc(100vw - 48px), ${maxWidth}px)` }),
    minHeight,
  }

  return (
    <ModalContainer name={name} style={containerStyle} styleType={styleType}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {loading && <Loader name={name} />}
      {!loading && (
        <>
          {headerElement}
          {content}
          {footer}
        </>
      )}
    </ModalContainer>
  )
}

export default Modal
