import { TestHotspotSchemaType } from '@leenda/editor/lib/brand'

import DemoWrapper from '../../decorators/DemoWrapper'
import { IElementDemoProps } from '../../elements.types'
import Element from './components/TestHotspotDemo'

const TestHotspotDemo: React.FC<IElementDemoProps<TestHotspotSchemaType>> = ({ styles }) => (
  <DemoWrapper center>
    <Element styles={styles} />
  </DemoWrapper>
)

export default TestHotspotDemo
