import * as R from 'ramda'

import { ISectionTreeNode, listToTree } from 'components/sections/ProjectStructure/utils'
import { PassOrderEnum } from 'gql/__generated__/graphql'
import { IProjectContext, Block, Section } from 'services/Store/Project/types'
import { notEmpty } from 'utils/notEmpty'

import { getBlocks, getProject, getSection, getSections } from '..'
import { BlockMode, SectionTypeEnum } from '../../enums'
import { getBlocksState } from './block'
import { getSectionState } from './section'

const findSection = (
  nodes: ISectionTreeNode<Section>[],
  id: string,
): ISectionTreeNode<Section> | undefined => {
  for (const node of nodes) {
    if (node.item.id === id) {
      return node
    }
    if (node.children) {
      const result = findSection(node.children, id)
      if (result) {
        return result
      }
    }
  }
  return
}

const flattenChapter = (nodes: ISectionTreeNode<Section>[]): ISectionTreeNode<Section>[] => {
  return nodes.flatMap((node) => {
    if (node.item.type === SectionTypeEnum.chapter && node.children) {
      return flattenChapter(node.children)
    }
    if (node.item.type === SectionTypeEnum.cover) {
      return []
    } else {
      return node
    }
  })
}

type SectionViewState = {
  count: number
  viewed: number
}

export const getProgress = (state: IProjectContext, sectionId?: string) => {
  const sections = getSections(state)
  const tree = listToTree(sections)

  const sectionsSubTree = (sectionId ? [findSection(tree, sectionId)] : tree).filter(notEmpty)

  const sectionsFlat = flattenChapter(sectionsSubTree)
  const project = getProject(state)
  const passOrder = project?.passOrder

  const blockStates = getBlocksState(state)

  const getTestViewState = (section: Section) => {
    const viewableBlocks = getViewableBlocksIds(state, section.id)
    const sectionState = getSectionState(state, { id: section.id })
    const count = viewableBlocks.length
    let viewed = 0
    if (sectionState?.status === 'inProgress') {
      viewed = R.sum(viewableBlocks.map((id) => (blockStates[id]?.result ? 1 : 0)))
    } else if (sectionState?.status === 'finished') {
      viewed = viewableBlocks.length
    }
    return { count, viewed }
  }
  const getLandingsViewState = (section: Section) => {
    const viewedBlocks = getViewableBlocksIds(state, section.id)

    return {
      count: viewedBlocks.length,
      viewed: R.sum(
        viewedBlocks.map((id) => {
          if (passOrder === PassOrderEnum.linear) {
            return blockStates[id]?.viewed && !blockStates[id]?.incomplete ? 1 : 0
          } else {
            return blockStates[id]?.viewed ? 1 : 0
          }
        }),
      ),
    }
  }
  const viewStates = sectionsFlat.map((section: ISectionTreeNode<Section>) => {
    if (section.item.type === SectionTypeEnum.test) {
      return getTestViewState(section.item)
    } else if (section.item.type === SectionTypeEnum.landing) {
      return getLandingsViewState(section.item)
    }
    throw new Error('Unknown section type')
  })
  const { viewed, count } = viewStates.reduce(
    (acc: SectionViewState, item: SectionViewState) => ({
      count: acc.count + item.count,
      viewed: acc.viewed + item.viewed,
    }),
    { count: 0, viewed: 0 },
  )

  return (viewed / (count || 1)) * 100
}

export const getContinueBlockId = (state: IProjectContext, sectionId?: string) => {
  const blockState = getBlocksState(state, { sectionId })
  const order = getSection(state, { id: sectionId })?.blocksOrder || []
  return R.findLast<string>((id) => Boolean(blockState[id]?.viewed), order) || R.head(order)
}

export const getContinueTestQuestionId = (state: IProjectContext, sectionId?: string) => {
  const blockState = getBlocksState(state, { sectionId })
  const order = getSection(state, { id: sectionId })?.blocksOrder || []
  return R.findLast<string>((id) => Boolean(blockState[id]?.isReady), order) || R.last(order)
}

const getViewableBlocksIds = (state: IProjectContext, sectionId?: string) => {
  const blocks = getBlocks(state, sectionId)
  return R.compose(
    R.map<Block, string>(R.prop('uuid')),
    R.filter(R.propSatisfies((mode) => VIEWABLE_BLOCK_MODES.includes(mode), 'mode')),
  )(blocks)
}

const VIEWABLE_BLOCK_MODES = [BlockMode.questions, BlockMode.view]
