import React from 'react'

import { NodeType } from 'components/editor-v3/types/date.enums'

import { FCNode } from '../../Node/types'
import s from './PreviewColumn.module.scss'

const PreviewColumn: FCNode<NodeType> = React.forwardRef(({ children, style }, ref) => {
  return (
    <div className={s.column} ref={ref} style={style}>
      {children}
    </div>
  )
})

PreviewColumn.displayName = 'PreviewColumn'

export default PreviewColumn
