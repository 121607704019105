/* eslint-disable no-console */
import { Middleware } from '@reduxjs/toolkit'

import { getDiff } from 'utils/object'

import type { ProjectActionsTypes } from '../reducer'

const EXCLUDED_ACTIONS: ProjectActionsTypes[keyof ProjectActionsTypes][] = ['project/setHighlight']

export const logger: Middleware = (store) => (next) => (actionPlain) => {
  const action = actionPlain as { type: ProjectActionsTypes[keyof ProjectActionsTypes] }
  if (EXCLUDED_ACTIONS.includes(action.type)) {
    return next(action)
  }
  const prevState = store.getState()
  console.groupCollapsed('ACTION:', action.type)
  console.log('PAYLOAD:', 'payload' in action ? action.payload : null)
  const result = next(action)
  const nextState = store.getState()
  console.log('Diff STATE:', getDiff(prevState, nextState))
  console.log('NEXT STATE:', nextState)
  console.log('PREV STATE === NEXT STATE', prevState === nextState)
  console.groupCollapsed('trace')
  console.trace()
  console.groupEnd()
  console.groupEnd()
  return result
}
