import React from 'react'

import { KeyIconsType } from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'
import { Typography } from 'components/uiKit/Typography'

import ColorCircle from '../ColorCircle'
import { IColorOption } from '../ColorPicker.types'
import s from './ColorRoot.module.scss'

interface IColorRootProps {
  color: string
  icon?: KeyIconsType
  size?: KitSize
  name?: string
  labeled?: boolean
  option?: IColorOption
  onClick?: () => void
  onBlur?: () => void
  onFocus?: () => void
}

const ColorRoot: React.FC<IColorRootProps> = ({
  color,
  icon,
  size,
  name,
  labeled,
  option,
  onBlur,
  onFocus,
}) => {
  return (
    <div className={s.root}>
      {labeled && (
        <span className={s.label}>
          <Typography rows={1} size={KitSize.XS} wordBreak='break-all'>
            {option?.rootLabel || color.slice(0, 7)}
          </Typography>
        </span>
      )}
      <ColorCircle
        color={color}
        icon={icon}
        name={name}
        onBlur={onBlur}
        onFocus={onFocus}
        size={size}
      />
    </div>
  )
}

export default ColorRoot
