import { useInViewport } from 'ahooks'
import cn from 'classnames'
import { produce } from 'immer'
import * as jsondiffpatch from 'jsondiffpatch'
import groupBy from 'lodash/groupBy'
import moment from 'moment/moment'
import React, { useEffect, useState } from 'react'

import { LayoutScroll } from 'components/LayoutPage'
import BlockRenderContainer from 'components/editor-v3/cource/layout/Block/BlockContainer'
import Button from 'components/uiKit/Button'
import Employee from 'components/uiKit/Employee'
import Icon from 'components/uiKit/Icon'
import Loader from 'components/uiKit/Loader'
import Modal, { MCWithoutParams, ModalBody } from 'components/uiKit/Modal'
import Scalable from 'components/uiKit/Scalable'
import { NOOP } from 'constants/commonConstans'
import { useBlockApplyVersion, useBlockPatches } from 'gql/blocks/apollo'
import { SKELETON_MODE } from 'services/Store/Project/constants'
import { useProjectContext } from 'services/Store/Project/hooks'
import { getBlock, getUrlParams } from 'services/Store/Project/selectors'
import { Block } from 'services/Store/Project/types'
import { selectEmployees } from 'services/Store/Users/selectors'
import { useAppSelector } from 'services/Store/hooks'
import { t } from 'services/Translation'
import { VERSION_DATE_TIME } from 'utils/contentTypes'
import { testProps } from 'utils/test/qaData'

import s from './BlockHistory.module.scss'

const reversePatch = (patches: any, block: Block, version: number) => {
  try {
    return produce(block || ({} as Block), (draft: any) => {
      patches.reduce((acc: any, update: any) => {
        const res = jsondiffpatch.unpatch(acc, update.patch)
        return res
      }, draft)
      draft.version = version
    })
  } catch (_e: any) {
    return null
  }
}

type VersionsListItem = {
  version: number
  date: string
  label: string | JSX.Element | null
  employeeId: string
  release: string
}

const BlockHistory: MCWithoutParams = ({ onClose }) => {
  const block = useProjectContext(getBlock)
  const { sectionId } = useProjectContext(getUrlParams)
  const { data, fetchMore } = useBlockPatches(block?.uuid || '')
  const [stateBlock, setStateBlock] = useState(block)
  const employees = useAppSelector(selectEmployees)
  const [selectedVersion, setSelectedVersion] = useState(block?.version || 0)
  const [activeVersionRef, setActiveVersionRef] = useState<HTMLDivElement | null>(null)
  const loaderRef = React.useRef<HTMLDivElement>(null)
  const [inViewPort] = useInViewport(loaderRef)
  const [applyVersion] = useBlockApplyVersion()
  useEffect(() => {
    setStateBlock(block)
  }, [block])

  const patches = data?.data.patches || []
  const pagination = data?.data.pagination

  useEffect(() => {
    if (activeVersionRef) {
      activeVersionRef.scrollIntoView({ block: 'nearest' })
    }
  }, [activeVersionRef])

  const onSelectVersion = (version: number) => {
    if (!block) {
      return
    }
    const revertPatches = patches.filter((patch) => patch.version > version)
    const versionBlock = reversePatch(revertPatches, block, version)
    setSelectedVersion(version)
    setStateBlock(versionBlock)
  }
  const onApplyVersion = () => {
    applyVersion({
      variables: {
        payload: {
          sectionId,
          uuid: block?.uuid || '',
          versionId: patches.find((patch) => patch.version === selectedVersion)?.id || null,
        },
      },
    })
    onClose()
  }

  const hasMore = pagination && pagination?.total > pagination?.offset + pagination?.limit
  useEffect(() => {
    if (inViewPort && pagination && hasMore) {
      fetchMore({
        variables: {
          search: {
            pagination: {
              offset: pagination.offset + pagination.limit,
              limit: pagination.limit,
            },
          },
        },
      })
    }
  }, [inViewPort, pagination, hasMore])

  const versionsList: VersionsListItem[] = patches.map((patch, index) => ({
    label:
      index === 0 ? (
        <span className={s.icon}>
          <Icon name='alertCheck' />
        </span>
      ) : null,
    version: patch.version,
    date: patch.createdAt,
    employeeId: patch.employeeId,
    release: patch.release,
  }))
  if (patches.length && pagination && pagination?.offset + pagination?.limit >= patches.length) {
    versionsList.push({
      label: t('modal.blockHistory.created'),
      version: patches?.[patches.length - 1]?.version - 1,
      date: block?.createdAt,
      employeeId: patches?.[patches.length - 1]?.employeeId || '',
      release: patches?.[patches.length - 1]?.release,
    })
  }

  const aggregatedPatches = groupBy(versionsList, 'release')
  return (
    <Modal name='blockHistory' styleType='free'>
      <ModalBody sizeAutoCapable>
        <div className={s.root}>
          <div className={s.blockPreview}>
            <LayoutScroll autoHide='leave' sizeAutoCapable>
              {stateBlock ? (
                <Scalable>
                  <BlockRenderContainer
                    block={stateBlock}
                    key={stateBlock.version}
                    mode={SKELETON_MODE}
                  />
                </Scalable>
              ) : (
                t('modal.blockHistory.brokenVersion')
              )}
            </LayoutScroll>
          </div>
          <div className={s.panel}>
            <div className={s.title}>{t('modal.blockHistory.history')}</div>
            <div className={s.history}>
              <LayoutScroll sizeAutoCapable>
                {Object.entries(aggregatedPatches).map(([release, items]) => {
                  return (
                    <div className={s.historyList} key={release}>
                      <div className={s.release}>
                        {t('modal.blockHistory.version')} {release}
                      </div>
                      {items.map((item, index) => {
                        const employee =
                          item.employeeId && employees.find((e) => e.id === item.employeeId)
                        const active = selectedVersion === item.version
                        return (
                          <div
                            className={cn(s.version, { [s.active]: active })}
                            key={item.version}
                            onClick={() => onSelectVersion(item.version)}
                            ref={active ? setActiveVersionRef : NOOP}
                            {...testProps({
                              el: 'blockHistory',
                              date: moment(+item.date).format(VERSION_DATE_TIME),
                              employeeName: (employee || {})?.kUser?.name,
                              index,
                            })}
                          >
                            <div className={s.date}>
                              {moment(+item.date).format(VERSION_DATE_TIME)} {item.label}
                            </div>
                            {employee && (
                              <div className={s.avatar}>
                                <Employee employee={employee} styleType='name' />
                              </div>
                            )}
                          </div>
                        )
                      })}
                      {hasMore && (
                        <div ref={loaderRef}>
                          <Loader name='versionsListFetchMore' />
                        </div>
                      )}
                    </div>
                  )
                })}
              </LayoutScroll>
            </div>
            <div className={s.button}>
              <Button
                disabled={block?.version === selectedVersion}
                name='applyVersion'
                onClick={onApplyVersion}
                fluid
              >
                {t('uiKit.button.apply')}
              </Button>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default BlockHistory
