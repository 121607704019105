import Icon from 'components/uiKit/Icon'
import { TemplatesListSchemaFragment } from 'gql/__generated__/graphql'
import { notEmpty } from 'utils/notEmpty'
import { testPropsEl } from 'utils/test/qaData'

import s from './Numeration.module.scss'

const MAX_VIEWED_BLOCKS = 7

interface INumerationProps {
  selected: TemplatesListSchemaFragment[]
  removeByIndex: (value: number, e: React.MouseEvent) => void
  uuid: string
}

const Numeration = ({ removeByIndex, selected, uuid }: INumerationProps) => {
  const numbers = selected.map((item, i) => (item.uuid === uuid ? i + 1 : null)).filter(notEmpty)

  return (
    <div className={s.selectedIndexBlock}>
      {numbers.map((num, i) => {
        if (i < MAX_VIEWED_BLOCKS) {
          return (
            <div
              {...testPropsEl('blockCardNumber', { value: uuid }, { index: num })}
              className={s.numberWrapper}
              key={num}
              onClick={(e) => {
                e.stopPropagation()
                removeByIndex(num, e)
              }}
            >
              <div className={s.closeIcon}>
                <Icon name='otherClose' />
              </div>
              <div className={s.valueText}>{num}</div>
            </div>
          )
        }

        if (i === MAX_VIEWED_BLOCKS) {
          return (
            <div className={s.numberWrapper} key={num}>
              <div className={s.valueText}>...</div>
            </div>
          )
        }

        return null
      })}
    </div>
  )
}

export default Numeration
