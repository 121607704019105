import { useCallback } from 'react'

import { useProjectContext, useProjectDispatch } from 'services/Store/Project/hooks'
import { getTestStartTrigger } from 'services/Store/Project/triggers'

import BaseButtonElement from '../BaseButtonElement'
import { ButtonElementType } from '../ButtonElement.types'

const StartButtonElement: ButtonElementType = ({ element, styles, mode, font }) => {
  const dispatch = useProjectDispatch()
  const trigger = useProjectContext(getTestStartTrigger)
  const label = element?.value?.label
  const onClick = useCallback(
    () => !trigger.disabled && dispatch(trigger.actionCreator()),
    [dispatch, trigger],
  )

  return (
    <BaseButtonElement
      action={element.value.action}
      disabled={trigger.disabled}
      font={font}
      label={label}
      mode={mode}
      onClick={onClick}
      styles={styles}
    />
  )
}

export default StartButtonElement
