import {
  TestInfoEndFontSchemaType,
  TestInfoEndSchemaType,
  TestInfoStartFontSchemaType,
  TestInfoStartSchemaType,
} from '@leenda/editor/lib/brand'
import { CONFIG_ELEMENTS } from '@leenda/editor/lib/elements'

import { DEMO_MODE } from 'services/Store/Project/constants'

import DemoWrapper from '../../decorators/DemoWrapper'
import { IElementDemoProps } from '../../elements.types'
import Element from './TestInfoElement'

const Start: React.FC<IElementDemoProps<TestInfoStartSchemaType, TestInfoStartFontSchemaType>> = ({
  styles,
  fonts,
}) => (
  <DemoWrapper center>
    <Element
      element={{
        id: `${CONFIG_ELEMENTS.testInfoStart.type}-demo`,
        type: CONFIG_ELEMENTS.testInfoStart.type,
        value: CONFIG_ELEMENTS.testInfoStart.defaultValue,
        font: {},
        style: {},
      }}
      font={fonts}
      mode={DEMO_MODE}
      styles={styles}
    />
  </DemoWrapper>
)

const End: React.FC<IElementDemoProps<TestInfoEndSchemaType, TestInfoEndFontSchemaType>> = ({
  styles,
  fonts,
}) => (
  <DemoWrapper center>
    <Element
      element={{
        id: `${CONFIG_ELEMENTS.testInfoEnd.type}-demo`,
        type: CONFIG_ELEMENTS.testInfoEnd.type,
        value: CONFIG_ELEMENTS.testInfoEnd.defaultValue,
        font: {},
        style: {},
      }}
      font={fonts}
      mode={DEMO_MODE}
      styles={styles}
    />
  </DemoWrapper>
)

export { Start, End }
