"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_VALUE = exports.createItem = exports.TYPE = void 0;
const id_1 = require("../../../utils/id");
exports.TYPE = 'layoutList';
const createItem = () => ({
    value: (0, id_1.generateId)(),
    label: '',
});
exports.createItem = createItem;
exports.DEFAULT_VALUE = {
    items: [(0, exports.createItem)()],
};
