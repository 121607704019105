import { IOption } from '../KitTypes'
import { ArrayElement, ICheckboxOption, Value } from './Checkbox'

export const isChecked = <T extends Value | Value[] = Value>(
  value?: T,
  item?: IOption<ArrayElement<T>>,
  option?: ICheckboxOption<T>,
): boolean => {
  if (Array.isArray(value)) {
    return value.includes(item?.value as any)
  }
  if (option) {
    return value === option.valueTrue
  }

  return Boolean(value)
}
