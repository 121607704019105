import { ElementsTypes, EditorElement } from '@leenda/editor/lib/elements'
import { generateId } from '@leenda/editor/lib/utils/id'
import lodash from 'lodash'

import { FCElementType } from './elements.types'
import AccordionElement from './elements/AccordionElement'
import ButtonElement from './elements/ButtonElement'
import CardElement from './elements/CardElement'
import ChartElement from './elements/ChartElement'
import CodeElement from './elements/CodeElement'
import CourseProgressElement from './elements/CourseProgressElement'
import CourseStructureElement from './elements/CourseStructureElement'
import DividerElement from './elements/DividerElement'
import DownloaderElement from './elements/DownloaderElement'
import EmbeddedElement from './elements/EmbeddedElement'
import GroupingElement from './elements/GroupingElement'
import ImageElement from './elements/ImageElement'
import LabeledGraphicElement from './elements/LabeledGraphicElement'
import LayoutListElement from './elements/LayoutListElement'
import LinkElement from './elements/LinkElement'
import ListElement from './elements/ListElement'
import AudioElement from './elements/Media/AudioElement'
import VideoElement from './elements/Media/VideoElement'
import RectangleElement from './elements/RectangleElement'
import RichTextElement from './elements/RichTextElement'
import SliderElement from './elements/SliderElement'
import SplitterElement from './elements/SplitterElement'
import TableElement from './elements/TableElement'
import TabsElement from './elements/TabsElement'
import TestFeedbackElement from './elements/TestFeedbackElement'
import HotspotElement from './elements/TestHotspotElement'
import { StartTestInfoElement, EndTestInfoElement } from './elements/TestInfoElement'
import KeyboardInputElement from './elements/TestKeyboardInputElement'
import TestMatchingElement from './elements/TestMatchingElement'
import TestMultipleChoiceElement from './elements/TestMultipleChoiceElement'
import TestRankingElementV2 from './elements/TestRankingElement'
import TestResultElement from './elements/TestResultElement'
import TestSingleChoiceElement from './elements/TestSingleChoiceElement'

export const ELEMENTS_CONFIG = {
  [RichTextElement.type]: RichTextElement,
  [ImageElement.type]: ImageElement,
  [AudioElement.type]: AudioElement,
  [VideoElement.type]: VideoElement,
  [DownloaderElement.type]: DownloaderElement,
  [LinkElement.type]: LinkElement,
  [DividerElement.type]: DividerElement,
  [ListElement.type]: ListElement,
  [ChartElement.type]: ChartElement,
  [LabeledGraphicElement.type]: LabeledGraphicElement,
  [CardElement.type]: CardElement,
  [TableElement.type]: TableElement,

  // SIMPLE FIGURES
  [RectangleElement.type]: RectangleElement,
  [CodeElement.type]: CodeElement,
  [EmbeddedElement.type]: EmbeddedElement,

  // COVER
  [CourseStructureElement.type]: CourseStructureElement,
  [CourseProgressElement.type]: CourseProgressElement,
  [LayoutListElement.type]: LayoutListElement,

  // TEST
  [TestResultElement.type]: TestResultElement,
  [StartTestInfoElement.type]: StartTestInfoElement,
  [EndTestInfoElement.type]: EndTestInfoElement,
  [TestFeedbackElement.type]: TestFeedbackElement,

  // QUESTIONS
  [TestSingleChoiceElement.type]: TestSingleChoiceElement,
  [TestMultipleChoiceElement.type]: TestMultipleChoiceElement,
  [TestRankingElementV2.type]: TestRankingElementV2,
  [TestMatchingElement.type]: TestMatchingElement,
  [KeyboardInputElement.type]: KeyboardInputElement,
  [HotspotElement.type]: HotspotElement,

  // BUTTONS
  [ButtonElement.type]: ButtonElement,

  // RECURSIVE ELEMENTS
  [TabsElement.type]: TabsElement,
  [AccordionElement.type]: AccordionElement,
  [SliderElement.type]: SliderElement,

  [GroupingElement.type]: GroupingElement,
  [SplitterElement.type]: SplitterElement,
}

// eslint-disable-next-line react/display-name
export const ElementNotFound = (type: string) => () => <div>Element not Found: {type}</div>

export const getEditorElementComponent = (type: ElementsTypes): FCElementType => {
  const res = ELEMENTS_CONFIG[type]
  if (res && res.Element) {
    return res.Element as unknown as FCElementType
  }
  return ElementNotFound(type)
}

export const getElementDefaultValue = (type: ElementsTypes) => ELEMENTS_CONFIG[type].defaultValue

export const generateElement = (type: ElementsTypes) => {
  const { defaultValue } = ELEMENTS_CONFIG[type]

  return lodash.cloneDeep({
    id: generateId(),
    type: type,
    style: {},
    font: {},
    value: defaultValue,

    containerOptions: {}, // deprecated (need on backend)
    options: {}, // deprecated (need on backend)
  }) as unknown as EditorElement
}

export const NO_PANEL_ELEMENTS: Array<keyof typeof ELEMENTS_CONFIG> = [
  ELEMENTS_CONFIG.richText.type,
  ELEMENTS_CONFIG.table.type,
]
