export const SYSTEM_COLORS = [
  '#DD7AFF',
  '#B06DC4',
  '#8F35AA',
  '#5F2792',
  '#401F81',
  '#8B8BF3',
  '#736EEF',
  '#472FC5',
  '#3E15AD',
  '#75C387',
  '#36AA55',
  '#1A7836',
  '#075923',
  '#4DA499',
  '#419488',
  '#37776B',
  '#32675D',
  '#6AC6FF',
  '#3D8BF4',
  '#3668CE',
  '#3049AE',
  '#E17F77',
  '#EF5B3D',
  '#E0513B',
  '#C1422F',
  '#F7A34E',
  '#EA8A45',
  '#E27D42',
  '#F3D63C',
  '#F1BF33',
  '#EFA72A',
  '#B7444B',
  '#8E3844',
]
