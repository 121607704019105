import { AccordionSchemaType } from '@leenda/editor/lib/brand'
import { useSize } from 'ahooks'
import { useRef } from 'react'

import NodeContainer from 'components/editor-v3/cource/layout/Node/NodeContainer'
import { ElementStyleCss } from 'services/Branding/types'
import { Block, IBlockMode } from 'services/Store/Project/types'

import { transformArrayToString } from '../AccordionElement'
import s from './Content.module.scss'

interface IContentProps {
  value: string
  isOpen: (value: string) => boolean
  styles: ElementStyleCss<AccordionSchemaType>
  block?: Block | null
  mode: IBlockMode
}

const Content = ({ value, isOpen, styles, block, mode }: IContentProps) => {
  const contentRef = useRef<HTMLDivElement>(null)
  const size = useSize(contentRef)
  const paddings = styles.indents?.content || []

  return (
    <div
      className={s.content}
      style={{
        maxHeight: isOpen(value) ? Number(size?.height) + paddings[2] : 0,
      }}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        ref={contentRef}
        style={{ padding: transformArrayToString(styles?.indents.content as any) }}
      >
        <NodeContainer block={block!} id={value} level={0} mode={mode} />
      </div>
    </div>
  )
}

export default Content
