export enum BrandTableActionUiEnum {
  DEFAULT = 'DEFAULT',
  CLONE = 'CLONE',
  EXPORT = 'EXPORT',
  RENAME = 'RENAME',
  DELETE = 'DELETE',
}

export enum BrandPageActionUiEnum {
  ADD = 'ADD',
  IMPORT = 'IMPORT',
}
