import { onError } from '@apollo/client/link/error'
import { gqlClient } from 'gql'
import { GraphQLError } from 'graphql'
import queryString from 'query-string'
import { matchPath } from 'react-router'

import { notify } from 'components/uiKit/Notification'
import { NotificationType } from 'components/uiKit/Notification/types'
import { APP_PATHS, AUTH_PATHS } from 'constants/paths'
import generateLink from 'routes/generateLink'
import history from 'routes/history'
import { t } from 'services/Translation'
import existKeyLocal from 'services/Translation/existKeyLocal'

import { ErrorMessage } from './__generated__/graphql'
import { companySlug } from './companies.gql'

const errorMessages = {
  TOKEN_NOT_FOUND: 'TOKEN_NOT_FOUND',
  EMPLOYEE_NOT_FOUND: 'EMPLOYEE_NOT_FOUND',
  LOW_COMPANY_ACCESS: 'LOW_COMPANY_ACCESS',
  USER_DOESNT_EXIST: t('be.error.USER_DOESNT_EXIST'),
  TOKEN_DOESNT_EXIST: t('be.error.TOKEN_DOESNT_EXIST'),
  EMAIL_DOESNT_MATCH: t('be.error.EMAIL_DOESNT_MATCH'),
  USER_NOT_FOUND: t('be.error.USER_NOT_FOUND'),
  TOKEN_DOESNT_SAVE: t('be.error.TOKEN_DOESNT_SAVE'),
  MESSAGE_DOESNT_SENT: t('be.error.MESSAGE_DOESNT_SENT'),
  CANT_CREATE_USER: t('be.error.CANT_CREATE_USER'),
  TOKEN_INVALID: t('be.error.TOKEN_INVALID'),
  PASSWORD_INVALID: t('be.error.PASSWORD_INVALID'),
  CANT_CHANGE_PASSWORD: t('be.error.CANT_CHANGE_PASSWORD'),
  CANT_BLOCK_USER: t('be.error.CANT_BLOCK_USER'),
  CANT_RECOVER_PASSWORD: t('be.error.CANT_RECOVER_PASSWORD'),
  PASSWORD_OR_EMAIL_INVALID: t('be.error.PASSWORD_OR_EMAIL_INVALID'),
  PASSWORD_OR_EMAIL_EXIST: t('be.error.PASSWORD_OR_EMAIL_EXIST'),
  PASSWORD_USED: t('be.error.PASSWORD_USED'),
  DOESNT_AUTH: t('be.error.DOESNT_AUTH'),
  LOGIN_OR_PASSWORD_DOESNT_EXIST: t('be.error.LOGIN_OR_PASSWORD_DOESNT_EXIST'),
  EMAIL_EXIST: t('be.error.EMAIL_EXIST'),
  COMPANY_ALREADY_EXIST: t('be.error.COMPANY_ALREADY_EXIST'),
  COMPANY_DOESNT_EXIST: t('be.error.COMPANY_DOESNT_EXIST'),
  EMAIL_INVALID: t('be.error.EMAIL_INVALID'),
  COMPANY_LEGAL_ID_ALREADY_EXIST: t('be.error.COMPANY_LEGAL_ID_ALREADY_EXIST'),
  CANT_CREATE_TAG: t('be.error.CANT_CREATE_TAG'),
  CANT_CREATE_ROLE: t('be.error.CANT_CREATE_ROLE'),
  CANT_CREATE_EMPLOYEE: t('be.error.CANT_CREATE_EMPLOYEE'),
  PASSWORDS_DOESNT_EQUAL: t('be.error.PASSWORDS_DOESNT_EQUAL'),
  EMPLOYEE_DOESNT_EXIST: t('be.error.USER_DOESNT_EXIST'),
  EMAIL_ALREADY_EXIST: t('be.error.EMAIL_ALREADY_EXIST'),
  ROLE_ALREADY_EXIST: t('be.error.ROLE_ALREADY_EXIST'),
  ROLE_DOESNT_EXIST: t('be.error.ROLE_DOESNT_EXIST'),
  ROLE_CANNOT_MODIFY: t('be.error.ROLE_CANNOT_MODIFY'),
  COMPANY_DOESNT_FOUND: t('be.error.COMPANY_DOESNT_FOUND'),
  COMPANY_NAME_ALREADY_EXIST: t('be.error.COMPANY_NAME_ALREADY_EXIST'),
  PROJECT_DOESNT_EXIST: t('be.error.PROJECT_DOESNT_EXIST'),
  PROJECT_DOESNT_FOUND: t('be.error.PROJECT_DOESNT_FOUND'),
  PROJECT_NAME_ALREADY_EXIST: t('be.error.PROJECT_NAME_ALREADY_EXIST'),
  PROJECT_GROUP_DOESNT_EXIST_IN_COMPANY: t('be.error.PROJECT_GROUP_DOESNT_EXIST_IN_COMPANY'),
  PROJECT_GROUP_HAS_NESTED_PROJECT_GROUP: t('be.error.PROJECT_GROUP_HAS_NESTED_PROJECT_GROUP'),
  PROJECT_GROUP_HAS_NESTED_PROJECTS: t('be.error.PROJECT_GROUP_HAS_NESTED_PROJECTS'),
  PROJECT_GROUP_WITH_NAME_ALREADY_EXIST: t('be.error.PROJECT_GROUP_WITH_NAME_ALREADY_EXIST'),
  SECTION_DOESNT_EXIST: t('be.error.SECTION_DOESNT_EXIST'),
  RECOVER_PASSWORD: t('be.error.RECOVER_PASSWORD'),
  CHANGE_PASSWORD: t('be.error.CHANGE_PASSWORD'),
  AT_LEAST_ONE_OWNER: t('be.error.AT_LEAST_ONE_OWNER'),
  CANT_TAKE_USER: t('be.error.CANT_TAKE_USER'),
  CANT_REGISTRATE_USER: t('be.error.CANT_REGISTRATE_USER'),
  CANT_CONFIRM_USER: t('be.error.CANT_CONFIRM_USER'),
  CANT_LOGIN_USER: t('be.error.CANT_LOGIN_USER'),
  CANT_SEND_INVITE_FOR_USER: t('be.error.CANT_SEND_INVITE_FOR_USER'),
  CANT_ACCEPT_INVITE: t('be.error.CANT_ACCEPT_INVITE'),
  CANT_CHANGE_EMAIL: t('be.error.CANT_CHANGE_EMAIL'),
  CANT_SEND_LINK_FOR_RECOVER_PASSWORD: t('be.error.CANT_SEND_LINK_FOR_RECOVER_PASSWORD'),
  CANT_SEND_LINK_FOR_CHANGE_PASSWORD: t('be.error.CANT_SEND_LINK_FOR_CHANGE_PASSWORD'),
  CANT_CREATE_COMPANY: t('be.error.CANT_CREATE_COMPANY'),
  CANT_UPDATE_COMPANY: t('be.error.CANT_UPDATE_COMPANY'),
  CANT_DELETE_COMPANY: t('be.error.CANT_DELETE_COMPANY'),
  CANT_FORCE_DELETE_COMPANY: t('be.error.CANT_FORCE_DELETE_COMPANY'),
  CANT_CREATE_BLOCK: t('be.error.CANT_CREATE_BLOCK'),
  CANT_UPDATE_BLOCK: t('be.error.CANT_UPDATE_BLOCK'),
  CANT_CREATE_SECTION: t('be.error.CANT_CREATE_SECTION'),
  CANT_UPDATE_SECTION: t('be.error.CANT_UPDATE_SECTION'),
  CANT_UPDATE_EMPLOYEE: t('be.error.CANT_UPDATE_EMPLOYEE'),
  CANT_DELETE_EMPLOYEE: t('be.error.CANT_DELETE_EMPLOYEE'),
  CANT_FORCE_DELETE_EMPLOYEE: t('be.error.CANT_FORCE_DELETE_EMPLOYEE'),
  CANT_CREATE_PROJECT: t('be.error.CANT_CREATE_PROJECT'),
  CANT_UPDATE_PROJECT: t('be.error.CANT_UPDATE_PROJECT'),
  CANT_FORCE_DELETE_PROJECT: t('be.error.CANT_FORCE_DELETE_PROJECT'),
  CANT_DELETE_PROJECT: t('be.error.CANT_DELETE_PROJECT'),
  CANT_CREATE_PROJECT_GROUP: t('be.error.CANT_CREATE_PROJECT_GROUP'),
  CANT_DELETE_PROJECT_GROUP: t('be.error.CANT_DELETE_PROJECT_GROUP'),
  CANT_DELETE_ROLE: t('be.error.CANT_DELETE_ROLE'),
  CANT_FORCE_DELETE_PROJECT_GROUP: t('notify.CANT_FORCE_DELETE_PROJECT_GROUP'),
  CANT_FORCE_DELETE_ROLE: t('be.error.CANT_FORCE_DELETE_ROLE'),
  CANT_SET_ROLE: t('be.error.CANT_SET_ROLE'),
  CANT_UPDATE_ROLE: t('be.error.CANT_UPDATE_ROLE'),
  TAG_WITH_NAME_ALREADY_EXIST: t('be.error.TAG_WITH_NAME_ALREADY_EXIST'),
  TAG_DOESNT_EXIST: t('be.error.TAG_DOESNT_EXIST'),
  CANT_UPDATE_TAG: t('be.error.CANT_UPDATE_TAG'),
  CANT_DELETE_TAG: t('be.error.CANT_DELETE_TAG'),
  CANT_GET_ACCESS_TO_COMPANY: t('be.error.CANT_GET_ACCESS_TO_COMPANY'),
  CANT_GRANT_PRIVILEGE: t('be.error.CANT_GRANT_PRIVILEGE'),
  CANT_GRANT_ACCESS_TO_PROJECT: t('be.error.CANT_GRANT_ACCESS_TO_PROJECT'),
  CANT_DELETE_SECTION: t('be.error.CANT_DELETE_SECTION'),
  DOESNT_HAVE_PERMISSION: t('be.error.DOESNT_HAVE_PERMISSION'),
  CANT_CREATE_MULTY_BLOCKS: t('be.error.CANT_CREATE_MULTY_BLOCKS'),
  TOO_MANY_CHARS: t('be.error.TOO_MANY_CHARS'),
  NEED_DIFFERENT_ROLES: t('be.error.NEED_DIFFERENT_ROLES'),
  CANT_RETURN_COMPANIES: t('be.error.CANT_RETURN_COMPANIES'),
  GROUP_WITH_NAME_ALREADY_EXIST: t('be.error.GROUP_WITH_NAME_ALREADY_EXIST'),
  FILE_META_GROUP_COLLISION: t('be.error.FILE_META_GROUP_COLLISION'),
  CANT_MOVED_FILE_META_GROUP: t('be.error.CANT_MOVED_FILE_META_GROUP'),
  CANT_DELETE_FILE_META_GROUP: t('be.error.CANT_DELETE_FILE_META_GROUP'),
  CANT_CREATE_FILE_META_GROUP: t('be.error.CANT_CREATE_FILE_META_GROUP'),
  CANT_CREATE_FILE: t('be.error.CANT_CREATE_FILE'),
  CANT_UPDATE_FILE: t('be.error.CANT_UPDATE_FILE'),
  CANT_DELETE_FILE: t('be.error.CANT_DELETE_FILE'),
  PARTICIPANTS_LIMIT_REACHED: t('be.error.PARTICIPANTS_LIMIT_REACHED'),
  FILE_META_GROUP_DOESNT_EXIST_IN_COMPANY: t('be.error.FILE_META_GROUP_DOESNT_EXIST_IN_COMPANY'),
  PROJECT_NAME_EXISTS: t('be.error.PROJECT_NAME_EXISTS'),
  INVALID_OLD_PASSWORD: t('be.error.INVALID_OLD_PASSWORD'),
  COMMENT_NO_PERMISSION: t('be.error.COMMENT_NO_PERMISSION'),
  COMPANY_NOT_FOUND: t('be.error.COMPANY_NOT_FOUND'),
  TRIAL_ALREADY_EXISTS: t('be.error.TRIAL_ALREADY_EXISTS'),
  [ErrorMessage.SSO_PROVIDER_UNAUTHORIZED]: t('be.error.SSO_PROVIDER_UNAUTHORIZED'),
  [ErrorMessage.SSO_CONFIG_NOT_FOUND]: t('be.error.SSO_CONFIG_NOT_FOUND'),
  [ErrorMessage.COMPANY_SLUG_NOT_FOUND]: t('be.error.COMPANY_SLUG_NOT_FOUND'),
  [ErrorMessage.INVALID_XML]: t('be.error.INVALID_XML'),
  [ErrorMessage.AI_LIMIT_REACHED]: t('be.error.AI_LIMIT_REACHED'),
} as { [key: string]: string }

const NOT_FOUND_PAGE_ERRORS = [
  'EMPLOYEES_LIMIT_REACHED',
  'STORAGE_LIMIT_REACHED',
  'PROJECT_NAME_EXISTS',
  // errors returned by BE on pages NotFound
  'SECTION_NOT_FOUND',
  'PARENT_FILE_META_GROUP_NOT_FOUND',
  'PARENT_PROJECT_GROUP_NOT_FOUND',
  'FILE_META_GROUP_NOT_FOUND',
  'PROJECT_GROUP_NOT_FOUND',
  'PROJECT_NOT_FOUND',
  'TASK_NOT_FOUND',
  'TAG_NOT_FOUND',
  'CANT_GET_BRAND',
  'EDITOR_SECTION_NOT_FOUND',
  'project with projectId',
  'Variable "$projectId" of required type "String!" was not provided',
  'Internal Server Error',
  'Int cannot represent non-integer value:',
  'BAD_SECTION',
]

// check if there is an error on page NotFound
const errorNotFound = (graphQLErrors: ReadonlyArray<GraphQLError> | undefined) =>
  graphQLErrors?.find(({ message }) =>
    NOT_FOUND_PAGE_ERRORS.some((errorInPlug) => message.includes(errorInPlug)),
  )

const errorRedirect = [
  errorMessages.PROJECT_GROUP_DOESNT_EXIST_IN_COMPANY,
  errorMessages.SECTION_DOESNT_EXIST,
  errorMessages.DOESNT_HAVE_PERMISSION,
]

const logout = () => {
  const { redirectTo = '' } = queryString.parse(history.location.search || '')
  const redirectLink = generateLink(AUTH_PATHS.login, {
    search: `?redirectTo=${encodeURIComponent(String(redirectTo) || history.location.pathname)}`,
  })
  history.replace(redirectLink)
}

const ssoLogin = async () => {
  const match = matchPath(history.location.pathname, APP_PATHS.company)
  const companyId = match ? (match.params as { companyId: string }).companyId : undefined
  const { data } = await gqlClient.core.query({
    query: companySlug,
    variables: {
      companyId: '',
    },
  })

  const link = generateLink(AUTH_PATHS.sso, {
    ssoId: data.data || companyId,
  })
  history.replace(link)
}

const dedupeErrors = (message: string, cb: (id: string) => void) => {
  const id = (message || '').replace(/\W/g, '_').toLowerCase()

  let isVisible = id === ''
  if (!isVisible) {
    const el = document.querySelector(`.${id}`)
    isVisible = el === null
  }
  if (isVisible) {
    cb(id)
  }
}

export const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  const { response } = operation.getContext()

  if (response?.status === 401) {
    logout()
    return
  }

  if (errorNotFound(graphQLErrors)) {
    return
  }

  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path, extensions }) => {
      // redirect subscribtions
      if (errorRedirect.includes(errorMessages[message])) {
        window.location.hash = APP_PATHS.home
      }

      if (message === ErrorMessage.SSO_PROVIDER_UNAUTHORIZED) {
        ssoLogin()
        return
      }
      if (message === ErrorMessage.SSO_DEFAULT_PROVIDER_UNAUTHORIZED) {
        logout()
        return
      }
      // known errors localization errors
      if (existKeyLocal(`be.error.${message}`)) {
        dedupeErrors(message, (id) => {
          notify({
            type: NotificationType.error,
            message: t(`be.error.${message}`),
            className: id,
          })
        })
        return
      }
      // known not localization errors
      if (errorMessages[message]) {
        notify({
          type: NotificationType.error,
          message,
        })
        console.error('not localization errors')
        return
      }

      // TODO: this condition is questionable, possibly irrelevant
      if (message === 'Cannot return null for non-nullable field Query.getKUser.') {
        logout()
      } else {
        const { code, stacktrace } = extensions || {}
        // unknown errors
        console.error({ code, stacktrace, message, path, locations })
        dedupeErrors(message, (id) => {
          notify({
            type: NotificationType.error,
            message: `[GraphQL error]`,
            // description: `Message: ${message}, Location: ${JSON.stringify(
            //   locations,
            // )}, Path: ${path}`,
            className: id,
          })
        })
      }
    })
  }

  if (networkError && networkError.message === 'Failed to fetch') {
    dedupeErrors('NETWORK_ERROR', (id) => {
      notify({
        type: NotificationType.error,
        message: errorMessages['NETWORK_ERROR'],
        className: id,
      })
    })
  }

  return
})
