import { SectionTypeEnum } from '@leenda/editor/lib/elements'
import React from 'react'

import { ISectionTreeNode } from 'components/sections/ProjectStructure/utils'
import Collapse from 'components/uiKit/Collapse'
import { Section } from 'services/Store/Project/types'

import s from './SectionsTreeEditor.module.scss'
import LineVerticalComponent from './components/LineVerticalComponent'
import SectionTreeNodeEditor from './components/SectionTreeNodeEditor'

interface ISectionsTreeEditor<S extends Section> {
  nodes: ISectionTreeNode<S>[]
  initialOpenCollapse: boolean
  onSectionPick?: (item: Section) => void
  isEditSectionId?: string
  setIsEditSectionId: (active: string) => void
  hideActions?: boolean
  sectionId?: string
  disabledByTypes?: SectionTypeEnum[]
}

const SectionsTreeEditor = <S extends Section>({
  nodes,
  initialOpenCollapse,
  onSectionPick,
  isEditSectionId,
  setIsEditSectionId,
  hideActions,
  sectionId,
  disabledByTypes,
}: ISectionsTreeEditor<S>) => (
  <>
    {nodes.map((node) => {
      return node.children?.length ? (
        <div className={s.collapse} key={node.item.id}>
          <Collapse
            header={(props) => {
              return (
                <LineVerticalComponent lvl={node.item.lvl} open={props.open}>
                  <SectionTreeNodeEditor
                    {...props}
                    disabledByTypes={disabledByTypes}
                    hideActions={hideActions}
                    isEditSectionId={isEditSectionId}
                    node={node}
                    onClick={onSectionPick}
                    sectionId={sectionId}
                    setIsEditSectionId={setIsEditSectionId}
                  />
                </LineVerticalComponent>
              )
            }}
            initialOpen={initialOpenCollapse}
            name={node.item.name || node.item.id}
            noBorder
          >
            <SectionsTreeEditor
              disabledByTypes={disabledByTypes}
              hideActions={hideActions}
              initialOpenCollapse={initialOpenCollapse}
              isEditSectionId={isEditSectionId}
              nodes={node.children}
              onSectionPick={onSectionPick}
              sectionId={sectionId}
              setIsEditSectionId={setIsEditSectionId}
            />
          </Collapse>
        </div>
      ) : (
        <SectionTreeNodeEditor
          disabledByTypes={disabledByTypes}
          hideActions={hideActions}
          isEditSectionId={isEditSectionId}
          key={node.item.id}
          node={node}
          onClick={onSectionPick}
          sectionId={sectionId}
          setIsEditSectionId={setIsEditSectionId}
          open
        />
      )
    })}
  </>
)

export default React.memo(SectionsTreeEditor)
