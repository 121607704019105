import { useContext, useEffect } from 'react'

import { TableContext } from '../TableContext'

export const useRedirectAccessPage = (total: number) => {
  const {
    pagination: { page, setPage, pageSize },
  } = useContext(TableContext)

  useEffect(() => {
    const pages = Math.ceil(total / pageSize)
    if (pages < page && total > 0) {
      setPage(pages)
    }
  }, [page, pageSize, setPage, total])
}
