import { CourseStructureItem } from '@leenda/editor/lib/elements'

import { ISectionTreeNode } from 'components/sections/ProjectStructure/utils'
import Collapse from 'components/uiKit/Collapse'
import { useProjectContext } from 'services/Store/Project/hooks'
import { getSection } from 'services/Store/Project/selectors'

import { StructureElementDisplayConfig } from '../../CourseStructureTree'
import { StructureNodeElement } from '../StructureNodeElement'

interface IStructureCollapse {
  nodes: ISectionTreeNode<CourseStructureItem>[]
  displayConfig: StructureElementDisplayConfig
  onSectionPick: (sectionId: string) => void
}

const CourseCollapseStructure: React.FC<IStructureCollapse> = ({
  nodes,
  displayConfig,
  onSectionPick,
}) => {
  const sectionId = displayConfig.activeId
  const section = useProjectContext(getSection)
  const isActive = (id: string) => id === (sectionId || section?.id)

  return (
    <>
      {nodes.map((node) =>
        node.children?.length ? (
          <Collapse
            collapsible='icon'
            header={(props) => (
              <StructureNodeElement
                {...props}
                active={isActive(node.item.id)}
                displayConfig={displayConfig}
                node={node}
              />
            )}
            initialOpen={displayConfig.initialOpen}
            key={node.item.id}
            name={node.item.name || node.item.id}
            styleType='ghost'
          >
            <CourseCollapseStructure
              displayConfig={displayConfig}
              nodes={node.children}
              onSectionPick={onSectionPick}
            />
          </Collapse>
        ) : (
          <StructureNodeElement
            active={isActive(node.item.id)}
            displayConfig={displayConfig}
            key={node.item.id}
            node={node}
            onClick={() => onSectionPick(node.item.id)}
            open
          />
        ),
      )}
    </>
  )
}

export default CourseCollapseStructure
