"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmployeeStatusEnum = exports.TaskRole = exports.CommentRole = exports.SystemRole = exports.ProjectRole = exports.PermissionGroup = exports.CompanyRole = exports.PermissionsKey = exports.PermissionService = exports.getCommentRole = exports.getTaskRole = exports.getPermissions = exports.getPermissionsForNotActive = exports.getAllPermissionGroups = exports.getDefaultPermissionGroups = void 0;
const permissions_1 = require("./permissions");
Object.defineProperty(exports, "PermissionsKey", { enumerable: true, get: function () { return permissions_1.PermissionsKey; } });
const enums_1 = require("./enums");
Object.defineProperty(exports, "CommentRole", { enumerable: true, get: function () { return enums_1.CommentRole; } });
Object.defineProperty(exports, "CompanyRole", { enumerable: true, get: function () { return enums_1.CompanyRole; } });
Object.defineProperty(exports, "EmployeeStatusEnum", { enumerable: true, get: function () { return enums_1.EmployeeStatusEnum; } });
Object.defineProperty(exports, "PermissionGroup", { enumerable: true, get: function () { return enums_1.PermissionGroup; } });
Object.defineProperty(exports, "ProjectRole", { enumerable: true, get: function () { return enums_1.ProjectRole; } });
Object.defineProperty(exports, "SystemRole", { enumerable: true, get: function () { return enums_1.SystemRole; } });
Object.defineProperty(exports, "TaskRole", { enumerable: true, get: function () { return enums_1.TaskRole; } });
const mapping_1 = require("./mapping");
const uniq = (arr) => Array.from(new Set(arr));
const getDefaultPermissionGroups = ({ employee, participant, }) => {
    const res = [];
    if (employee) {
        res.push(...(mapping_1.COMPANY_ROLE_PERMISSIONS_GROUPS[employee.role] || []));
    }
    if (participant) {
        res.push(...mapping_1.PROJECT_ROLE_PERMISSIONS_GROUPS[participant.role]);
    }
    return uniq(res);
};
exports.getDefaultPermissionGroups = getDefaultPermissionGroups;
const getAllPermissionGroups = ({ employee, participant, }) => {
    const res = (0, exports.getDefaultPermissionGroups)({ employee, participant });
    if (employee) {
        res.push(...(employee.permissionGroups || []));
    }
    if (participant) {
        res.push(...(participant.permissionGroups || []));
    }
    return uniq(res);
};
exports.getAllPermissionGroups = getAllPermissionGroups;
const getPermissionsForNotActive = ({ user, employee, participant }, keysForActive) => {
    if (!user ||
        (user === null || user === void 0 ? void 0 : user.role) === enums_1.SystemRole.visitor ||
        !employee ||
        (employee === null || employee === void 0 ? void 0 : employee.role) === enums_1.CompanyRole.visitor ||
        (employee === null || employee === void 0 ? void 0 : employee.status) === enums_1.EmployeeStatusEnum.ARCHIVED ||
        (participant === null || participant === void 0 ? void 0 : participant.role) === enums_1.ProjectRole.visitor) {
        return [];
    }
    const res = [];
    if (employee.role !== enums_1.CompanyRole.commentator) {
        res.push(permissions_1.PermissionsKey.project_employeesFilter_team_add);
    }
    if (keysForActive.includes(permissions_1.PermissionsKey.project_tasks_r) && participant) {
        res.push(permissions_1.PermissionsKey.project_employeesFilter_tasks_assignee);
    }
    if (keysForActive.includes(permissions_1.PermissionsKey.project_tasks_r)) {
        res.push(permissions_1.PermissionsKey.project_employeesFilter_tasks_mention);
    }
    if (keysForActive.includes(permissions_1.PermissionsKey.project_tasks_r) &&
        employee.status === enums_1.EmployeeStatusEnum.ACTIVE) {
        res.push(permissions_1.PermissionsKey.project_employeesFilter_tasks_assignOnDelete);
    }
    if (keysForActive.includes(permissions_1.PermissionsKey.project_comments_r) && participant) {
        res.push(permissions_1.PermissionsKey.project_employeesFilter_comments_mention);
    }
    return res;
};
exports.getPermissionsForNotActive = getPermissionsForNotActive;
const getPermissions = ({ user, employee, participant, commentRole, taskRole, }) => {
    const permissionsForActive = {
        user: [],
        employee: [],
        participant: [],
    };
    const res = [];
    if (user) {
        permissionsForActive.user.push(...mapping_1.SYSTEM_ROLE_PERMISSIONS[user.role]);
    }
    if (employee) {
        permissionsForActive.employee.push(...mapping_1.COMPANY_ROLE_PERMISSIONS[employee.role]);
        permissionsForActive.employee.push(...mapping_1.COMPANY_ROLE_PERMISSIONS_GROUPS[employee.role].flatMap((group) => mapping_1.PERMISSIONS_BY_GROUP[group]));
        permissionsForActive.employee.push(...employee.permissionGroups.flatMap((group) => mapping_1.PERMISSIONS_BY_GROUP[group]));
        commentRole && permissionsForActive.employee.push(...mapping_1.COMMENT_ROLE_PERMISSIONS[commentRole]);
        if (participant) {
            permissionsForActive.participant.push(...mapping_1.PROJECT_ROLE_PERMISSIONS[participant.role]);
            permissionsForActive.participant.push(...mapping_1.PROJECT_ROLE_PERMISSIONS_GROUPS[participant.role].flatMap((group) => mapping_1.PERMISSIONS_BY_GROUP[group]));
            permissionsForActive.participant.push(...participant.permissionGroups.flatMap((group) => mapping_1.PERMISSIONS_BY_GROUP[group]));
        }
        commentRole && permissionsForActive.participant.push(...mapping_1.COMMENT_ROLE_PERMISSIONS[commentRole]);
        taskRole && permissionsForActive.participant.push(...mapping_1.TASK_ROLE_PERMISSIONS[taskRole]);
    }
    if (user && user.isActive) {
        res.push(...permissionsForActive.user);
    }
    if (employee && employee.status === enums_1.EmployeeStatusEnum.ACTIVE) {
        res.push(...permissionsForActive.employee);
    }
    if (participant) {
        res.push(...permissionsForActive.participant);
    }
    const keysForActive = uniq([
        ...permissionsForActive.user,
        ...permissionsForActive.employee,
        ...permissionsForActive.participant,
    ]);
    res.push(...(0, exports.getPermissionsForNotActive)({ user, employee, participant }, keysForActive));
    const set = new Set(res);
    const list = Array.from(set);
    return { list, set };
};
exports.getPermissions = getPermissions;
const getTaskRole = ({ createdBy, assignedIds }, employeeId) => {
    if (createdBy === employeeId) {
        return enums_1.TaskRole.creator;
    }
    if (assignedIds.includes(employeeId)) {
        return enums_1.TaskRole.assignee;
    }
    return undefined;
};
exports.getTaskRole = getTaskRole;
const getCommentRole = ({ createdBy, mentionedIds }, employeeId) => {
    if (createdBy === employeeId) {
        return enums_1.CommentRole.creator;
    }
    if (mentionedIds.includes(employeeId)) {
        return enums_1.CommentRole.mentioned;
    }
    return undefined;
};
exports.getCommentRole = getCommentRole;
class PermissionService {
    constructor(data) {
        this.data = data;
    }
    static hasPermissions(permissions, data, mode = 'and') {
        const keys = (0, exports.getPermissions)(data);
        if (mode === 'or') {
            return permissions.some((permission) => keys.set.has(permission));
        }
        else {
            return permissions.every((permission) => keys.set.has(permission));
        }
    }
    has(permissions, mode) {
        if (!Array.isArray(permissions)) {
            permissions = [permissions];
        }
        return PermissionService.hasPermissions(permissions, this.data, mode);
    }
    assert(permissions, mode) {
        if (!this.has(permissions, mode)) {
            throw new Error('PermissionError');
        }
    }
}
exports.PermissionService = PermissionService;
