import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  Tooltip,
  YAxis,
} from 'recharts'

import { useGetSizes } from '../ChartElement.hooks'
import { ChartElementType } from '../ChartTypes'
import { customTooltip, getXConfig, getYConfig } from '../helper'

const MARGINS = { left: 16, top: 24 }

export const LineChartRender: ChartElementType = ({ element, styles, mode, font }) => {
  const { width, height, onMeasure, rootWidth } = useGetSizes({ element, font, styles, mode })

  const normalizedItems = element.value.items.map((item) => ({
    ...item,
    val: item.val || 0,
  }))

  return (
    <ResponsiveContainer minHeight={Number(styles?.shell?.minHeight) || 300} minWidth={rootWidth}>
      <LineChart data={normalizedItems} margin={MARGINS}>
        <XAxis
          {...getXConfig({
            styles,
            label: element.value.x,
            onMeasure,
            tickCount: element.value.items.length,
            font,
            height,
            showLabel: element.value.description,
            mode,
          })}
        />
        <YAxis
          {...getYConfig({
            styles,
            label: element.value.y,
            onMeasure,
            width,
            font,
            showLabel: element.value.description,
          })}
        />
        <CartesianGrid stroke={styles.chart.color} vertical={false} />
        <Line
          dataKey='val'
          dot={{ strokeWidth: 2 }}
          isAnimationActive={false}
          stroke={styles.chart.backgroundColor}
          strokeWidth={2}
        />
        <Tooltip content={customTooltip} contentStyle={font.base} cursor={false} label='label' />
      </LineChart>
    </ResponsiveContainer>
  )
}
