"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_VALUE = exports.TYPE = void 0;
const rich_text_1 = require("@leenda/rich-text");
exports.TYPE = 'splitter';
exports.DEFAULT_VALUE = {
    leftBlock: {
        image: null,
        showImage: false,
        text: (0, rich_text_1.textToRtValue)('', rich_text_1.SlateElementType.heading3),
        background: 'rgba(255, 255, 255, 0.1)',
    },
    rightBlock: {
        image: null,
        showImage: false,
        text: (0, rich_text_1.textToRtValue)('', rich_text_1.SlateElementType.heading3),
        background: 'rgba(255, 255, 255, 0.1)',
    },
    position: 50,
};
