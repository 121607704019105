import React, { FC } from 'react'
import { Helmet } from 'react-helmet-async'

interface IDocumentTitleProps {
  children?: React.ReactNode
  title?: string
}

export const DocumentTitle: FC<IDocumentTitleProps> = ({ children, title }) => (
  <>
    <Helmet>
      <title>{title}</title>
    </Helmet>
    {children}
  </>
)

export default DocumentTitle
