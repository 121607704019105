const EMPTY_SYMBOLS = ['-', ' ', '_']

export const searchTrim = (str: string) => {
  if (!str) {
    return str
  }
  let result = str
  while (EMPTY_SYMBOLS.includes(result.charAt(0))) {
    result = result.substring(1)
  }
  return result
}
