import { GroupingItem } from '@leenda/editor/lib/elements'
import cn from 'classnames'
import { useContext } from 'use-context-selector'

import GroupingContext from 'components/editor-v2/EditorElements/elements/GroupingElement/GroupingContext'
import { useProjectT } from 'services/ProjectLabels'
import { useImageWithCrop } from 'utils/files'

import s from './Answer.module.scss'

interface IAnswerProps {
  item: GroupingItem
}
const Answer = ({ item }: IAnswerProps) => {
  const pt = useProjectT()
  const { styles, hasAnswerImage, font } = useContext(GroupingContext)
  const img = useImageWithCrop(item.image)
  const labelClasses = cn(s.answer, { [s.placeholder]: !item.label })
  const label =
    item.label || (item?.image?.id && hasAnswerImage)
      ? item.label || ''
      : pt('elements.grouping.answer.placeholder')
  const textStyles = item.label && { ...font?.base }

  return (
    <div
      className={labelClasses}
      style={{
        ...styles.answer,
        ...textStyles,
        padding: styles.indents.padding,
        backgroundImage: img?.path && hasAnswerImage ? `url(${img.path})` : undefined,
      }}
    >
      {label}
    </div>
  )
}
export default Answer
