import lodash from 'lodash'

import { IItemProps } from '../../ListElement.types'
import image from '../../assets/single_mini.svg'
import s from './ListImage.module.scss'

const ListImage: React.FC<IItemProps> = ({ styles, path }) => {
  const bgColor = !styles.backgroundImage && !path ? 'backgroundColor' : ''
  return (
    <div
      className={s.root}
      style={{
        ...lodash.omit(styles, ['backgroundImage', bgColor]),
      }}
    >
      {!styles.backgroundImage && !path ? (
        <img src={image} />
      ) : (
        <div
          className={s.image}
          style={{
            backgroundImage: path ? `url(${path})` : styles.backgroundImage,
          }}
        />
      )}
    </div>
  )
}

export default ListImage
