import * as R from 'ramda'

import { isAbsoluteNode, isCommonNode, isShellNode } from 'components/editor-v3/types/data.guards'
import { LayoutNode } from 'components/editor-v3/types/data.types'
import { InsertPlace, NodeType } from 'components/editor-v3/types/date.enums'
import { Block } from 'services/Store/Project/types'
import { notEmpty } from 'utils/notEmpty'

import {
  getDeepCloneNode,
  getNode,
  getNodeWithFullChildren,
  getPathNodes,
} from '../selectors/block'
import { createAbsoluteNode, createColNode, createRowNode, createShell } from '../struct/block'

export type UpdateBlockResult = {
  block: Block
  nodeId?: string
}

const insertId = (relatedId: string, insertId: string, place: InsertPlace, array: string[]) => {
  const index = array.indexOf(relatedId)
  return R.insert(index + (place === InsertPlace.above ? 0 : 1), insertId, array)
}

const deleteFromParentMutator = (block: Block, nodeId: string) => {
  const [_node, parent] = R.reverse(getPathNodes(block, nodeId))
  return R.assocPath(['schema', 'nodes', parent.id], {
    ...parent,
    children: parent.children.filter((id) => id !== nodeId),
  })
}

export const deleteChildNodesDeepMutator = (block: Block, nodeId: string) => {
  const removeNodes = getNodeWithFullChildren(block, nodeId)
  return R.compose(
    R.identity,
    //@ts-ignore
    ...removeNodes.map((node) => R.dissocPath(['schema', 'nodes', node.id])),
  )
}

export const deleteChildElementDeepMutator = (block: Block, nodeId: string) => {
  const childNodes = getNodeWithFullChildren(block, nodeId).filter(
    (node) => isShellNode(node) && node.elementId,
  )
  const elementIds: string[] = childNodes
    .map((node) => (isShellNode(node) ? node.elementId : null))
    .filter(notEmpty)
  const elements = R.omit(elementIds, block.elements)
  return (data: Block) => ({ ...data, elements })
}

const simplifyPathMutator =
  (rowId: string) =>
  (block: Block): Block => {
    const pathNodes = R.reverse(getPathNodes(block, rowId))
    const [node, parent] = pathNodes
    if (node.type === NodeType.row && node?.children.length === 0) {
      console.info('simplifyPathMutator: case 1 - remove empty row')
      //@ts-ignore
      return R.compose(
        simplifyPathMutator(parent.id),
        deleteFromParentMutator(block, node.id),
        deleteChildNodesDeepMutator(block, node.id),
      )(block)
    }

    if (node?.children?.length === 1 && parent?.children?.length === 1 && pathNodes.length > 2) {
      const child = getNode(block, node.children[0])
      if (
        !isCommonNode(child) ||
        (parent.type === NodeType.column && node.type === NodeType.column)
      ) {
        return block
      }
      console.info('simplifyPathMutator: case 2 - move Up column if only', pathNodes)

      return R.compose(
        simplifyPathMutator(parent.id),
        R.dissocPath(['schema', 'nodes', node.id]),
        R.dissocPath(['schema', 'nodes', child.id]),
        R.assocPath(['schema', 'nodes', parent.id], {
          ...parent,
          children: child.children,
        }),
      )(block)
    }
    return block
  }

export const addRow = (block: Block, columnId: string, place: InsertPlace): UpdateBlockResult => {
  const [_column, parentRow, parentColumn] = R.reverse(getPathNodes(block, columnId))
  const newColumn = createColNode()
  const newRow = createRowNode([newColumn.id])

  // TODO: update parent minHeight

  return {
    //@ts-ignore
    block: R.compose(
      R.assocPath(['schema', 'nodes', newRow.id], newRow),
      R.assocPath(['schema', 'nodes', newColumn.id], newColumn),
      R.assocPath(['schema', 'nodes', parentColumn.id], {
        ...parentColumn,
        children: insertId(parentRow.id, newRow.id, place, parentColumn.children),
      }),
    )(block),
    nodeId: newColumn.id,
  }
}

export const addColumn = (
  block: Block,
  columnId: string,
  place: InsertPlace,
): UpdateBlockResult => {
  const [_column, parentRow] = R.reverse(getPathNodes(block, columnId))
  const newColumn = createColNode()

  // TODO: update parent minHeight

  return {
    //@ts-ignore
    block: R.compose(
      R.assocPath(['schema', 'nodes', newColumn.id], newColumn),
      R.assocPath(['schema', 'nodes', parentRow.id], {
        ...parentRow,
        children: insertId(columnId, newColumn.id, place, parentRow.children),
      }),
    )(block),
    nodeId: newColumn.id,
  }
}

export const splitVertical = (block: Block, columnId: string): UpdateBlockResult => {
  const [column, parentRow] = R.reverse(getPathNodes(block, columnId))
  const children = column.children.map((id) => getNode(block, id))
  const [absolute, content] = R.partition(isAbsoluteNode, children)
  const newColumn1 = createColNode(content.map((node) => node.id))
  const newColumn2 = createColNode()
  const newRow = createRowNode([newColumn1.id, newColumn2.id])

  return {
    block: R.compose(
      simplifyPathMutator(parentRow.id),
      R.assocPath(['schema', 'nodes', column.id], {
        ...column,
        children: [...absolute.map((node) => node.id), newRow.id],
      }),
      R.assocPath(['schema', 'nodes', newRow.id], newRow),
      R.assocPath(['schema', 'nodes', newColumn1.id], newColumn1),
      R.assocPath(['schema', 'nodes', newColumn2.id], newColumn2),
    )(block),
    nodeId: newColumn1.id,
  }
}

export const splitHorizontal = (block: Block, columnId: string): UpdateBlockResult => {
  const column = getNode(block, columnId)
  const children = column.children.map((id) => getNode(block, id))
  const [absolute, content] = R.partition(isAbsoluteNode, children)
  const newColumn1 = createColNode(content.map((node) => node.id))
  const newColumn2 = createColNode()
  const newRow1 = createRowNode([newColumn1.id])
  const newRow2 = createRowNode([newColumn2.id])

  return {
    //@ts-ignore
    block: R.compose(
      R.assocPath(['schema', 'nodes', column.id], {
        ...column,
        children: [...absolute.map((node) => node.id), newRow1.id, newRow2.id],
      }),
      R.assocPath(['schema', 'nodes', newRow1.id], newRow1),
      R.assocPath(['schema', 'nodes', newRow2.id], newRow2),
      R.assocPath(['schema', 'nodes', newColumn1.id], newColumn1),
      R.assocPath(['schema', 'nodes', newColumn2.id], newColumn2),
    )(block),
    nodeId: newColumn1.id,
  }
}

export const addAbsolute = (block: Block, columnId: string): UpdateBlockResult => {
  const column = getNode(block, columnId)
  const absoluteNode = createAbsoluteNode()

  return {
    // @ts-expect-error temporary
    block: R.compose(
      R.assocPath(['schema', 'nodes', column.id], {
        ...column,
        children: [...column.children, absoluteNode.id],
      }),
      R.assocPath(['schema', 'nodes', absoluteNode.id], absoluteNode),
    )(block),
    nodeId: absoluteNode.id,
  }
}

export const removeNode = (block: Block, nodeId: string): UpdateBlockResult => {
  const pathNodes = R.reverse(getPathNodes(block, nodeId))
  const [node] = pathNodes
  if (node.type === NodeType.column) {
    const [_column, parentRow, parentColumn] = pathNodes
    const parentColumnRowsCount = (parentColumn?.children || [])
      .map((id) => getNode(block, id)?.type)
      .filter((type) => type === NodeType.row).length
    if (
      !parentRow ||
      (pathNodes.length === 3 && parentRow.children.length <= 1 && parentColumnRowsCount <= 1)
    ) {
      return { block }
    }

    return {
      //@ts-ignore
      block: R.compose(
        simplifyPathMutator(parentRow.id),
        deleteFromParentMutator(block, nodeId),
        deleteChildNodesDeepMutator(block, nodeId),
      )(block),
    }
  } else if (node.type === NodeType.row) {
    const [_row, parentColumn, parentRow] = pathNodes
    const parentColumnRowsCount = (parentColumn?.children || [])
      .map((id) => getNode(block, id)?.type)
      .filter((type) => type === NodeType.row).length
    if (!parentRow && parentColumnRowsCount <= 1) {
      return { block }
    }
    return {
      block: R.compose(
        deleteFromParentMutator(block, nodeId),
        deleteChildNodesDeepMutator(block, nodeId),
      )(block) as Block,
    }
  } else if (node.type === NodeType.absolute || node.type === NodeType.shell) {
    return {
      block: R.compose(
        deleteFromParentMutator(block, nodeId),
        deleteChildNodesDeepMutator(block, nodeId),
      )(block) as Block,
    }
  }
  return { block }
}

export const addShell = (block: Block, columnId: string): UpdateBlockResult => {
  const node = getNode(block, columnId)
  const newShell = createShell()

  return {
    //@ts-ignore
    block: R.compose(
      R.assocPath(['schema', 'nodes', node.id], {
        ...node,
        children: [...node.children, newShell.id],
      }),
      R.assocPath(['schema', 'nodes', newShell.id], newShell),
    )(block),
    nodeId: newShell.id,
  }
}

export const deleteShell = (block: Block, shellId: string): UpdateBlockResult => {
  return {
    block: R.compose(
      deleteFromParentMutator(block, shellId),
      deleteChildNodesDeepMutator(block, shellId),
    )(block) as Block,
  }
}

const COLUMN_LIKE_NODE_TYPES = [NodeType.column, NodeType.absolute]
const isColumnLike = (node: LayoutNode) => COLUMN_LIKE_NODE_TYPES.includes(node?.type)

export const pasteNode = (
  fromBlock: Block,
  toBlock: Block,
  nodeId: string,
  insertNodeId: string,
  isCut: boolean,
) => {
  const copyData = getDeepCloneNode(fromBlock, nodeId)
  const copyNode = getNode(fromBlock, nodeId)
  const newId = isCut ? nodeId : copyData.id
  const insertNode = getNode(toBlock, insertNodeId)
  let updatedNode: any = null
  if (
    (insertNode?.type === 'row' && copyNode?.type === 'row') ||
    (isColumnLike(insertNode) && isColumnLike(copyNode))
  ) {
    const [_node, parentNode] = R.reverse(getPathNodes(toBlock, insertNodeId))
    updatedNode = {
      ...parentNode,
      children: insertId(insertNodeId, newId, InsertPlace.below, parentNode.children),
    }
  } else if (
    (insertNode?.type === 'row' && isColumnLike(copyNode)) ||
    (isColumnLike(insertNode) && copyNode?.type === 'row')
  ) {
    updatedNode = {
      ...insertNode,
      children: [...insertNode.children, newId],
    }
  } else if (isColumnLike(insertNode) && isShellNode(copyNode)) {
    const hasSameShell = insertNode.children.some((id) => {
      const node = getNode(toBlock, id)
      return isShellNode(node) && node.type === copyNode.type
    })
    if (!hasSameShell) {
      updatedNode = {
        ...insertNode,
        children: [...insertNode.children, newId],
      }
    }
  }

  if (updatedNode) {
    if (isCut && fromBlock.uuid === toBlock.uuid) {
      const [_node, fromParent] = R.reverse(getPathNodes(toBlock, nodeId))
      if (fromParent.id === updatedNode.id) {
        return undefined
      }
      const block = R.compose(
        R.assocPath(['schema', 'nodes', updatedNode.id], updatedNode),
        R.assocPath(['schema', 'nodes', fromParent.id], {
          ...fromParent,
          children: R.without([nodeId], fromParent.children),
        }),
      )(toBlock)
      return { block, id: copyData.id }
    } else {
      const block = R.compose(
        R.assocPath(['schema', 'nodes', updatedNode.id], updatedNode),
        //@ts-ignore
        ...copyData.nodes.map((node) => R.assocPath(['schema', 'nodes', node.id], node)),
        ...copyData.elements.map((element) => R.assocPath(['elements', element.id], element)),
      )(toBlock)
      return {
        block,
        id: copyData.id,
      }
    }
  }
  return undefined
}
