import { BlockSchemaFragment } from 'gql/__generated__/graphql'

import { gqlClient } from '..'
import { sectionEditorFragment } from '../sections.gql'

/* TODO: move to sections? */
export const updateBlocksOrderCache = (sectionId: string, blocksOrder: string[]) => {
  const cache = gqlClient.core.cache

  try {
    cache.modify({
      id: cache.identify({
        __typename: 'EditorSection',
        id: sectionId,
      }),
      fields: {
        blocksOrder: () => {
          return blocksOrder
        },
      },
    })
  } catch (err) {
    console.error('"updateBlocksOrderCache" fn is crashed on operation: ".modify"', err)
  }

  try {
    const val = cache.readFragment<BlockSchemaFragment>({
      id: cache.identify({
        __typename: 'EditorSection',
        id: sectionId,
      }),
      fragment: sectionEditorFragment,
      fragmentName: 'SectionEditorSchema',
    })
    cache.writeFragment({
      fragment: sectionEditorFragment,
      id: cache.identify({
        __typename: 'EditorSection',
        id: sectionId,
      }),
      fragmentName: 'SectionEditorSchema',
      //@ts-ignore TODO: 1373
      data: { ...val, blocksOrder },
    })
  } catch (err) {
    console.error('"blockUpdateCache" fn is crashed on operation: ".writeFragment"', err)
  }
}
