"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const constants_1 = require("../../../elements/course/TestAnswerFeedback/constants");
const genStyle_1 = require("../genStyle");
const styleSchema = {
    common__var: {
        width: (0, genStyle_1.genStyle)({ value: 16 }),
        marginRight: (0, genStyle_1.genStyle)({ value: 14 }),
    },
    correct: {
        backgroundImage: (0, genStyle_1.genStyle)({ value: null }),
    },
    incorrect: {
        backgroundImage: (0, genStyle_1.genStyle)({ value: null }),
    },
};
exports.default = { [constants_1.TYPE]: { style: styleSchema, font: {} } };
