import lodash from 'lodash'
import moment from 'moment'
import * as R from 'ramda'

import { useProjectT } from 'services/ProjectLabels'
import { useProjectContext } from 'services/Store/Project/hooks'
import {
  getSection,
  getCurrentTry,
  getStartTime,
  getEndTime,
  getTotalQuestions,
  getSuccessQuestions,
} from 'services/Store/Project/selectors'
import { Block } from 'services/Store/Project/types'

import { OPTIONS } from './TestInfoElement.constants'
import { OptionsTemplateProps, PageItem } from './TestInfoElement.types'

const SECONDS_PER_HOUR = 3600
const getSeconds = (time?: number | null) => lodash.round(time || Date.now(), -3)
const calcSpendTime = (startTime: number, endTime: number, time: number) => {
  const result = getSeconds(endTime) - getSeconds(startTime)
  const clampResult = R.clamp(0, time || Number.MAX_SAFE_INTEGER, result)
  return moment(time ? clampResult : result)
    .utc()
    .format('HH:mm:ss')
}

const useGetTestInfoItemVariable = (block?: Block | null) => {
  const pt = useProjectT()

  const section = useProjectContext(getSection)
  const totalQuestionsSuccess = useProjectContext(getSuccessQuestions)
  const totalQuestions = useProjectContext(getTotalQuestions, block?.sectionId)
  const currentTry = useProjectContext(getCurrentTry)
  const time = (section?.test?.time || 0) * 1000
  const startTime = useProjectContext(getStartTime)
  const endTime = useProjectContext(getEndTime)
  const spendTime = calcSpendTime(startTime, endTime, time)

  const questionslist =
    section?.blocks?.filter((block: any) => block.mode === 'questions').length || 0

  const getTestInfoItemVariable = (pageItem: PageItem) => {
    let value: OptionsTemplateProps = {}
    const template = OPTIONS[pageItem].template

    switch (pageItem) {
      case 'PERCENTAGE':
        value = {
          percentage: section?.test?.testThreshold || 0,
        }
        break
      case 'ATTEMPTS_LEFT':
        const totalAttempts = section?.test?.testRetryCount || 0
        const usedAttempts = currentTry || 0
        const attemptsLeft = totalAttempts - usedAttempts
        value = { attemptsLeft, totalAttempts, limit: section?.test?.testLimit }
        break
      case 'CURRENT_ATTEMPT':
        value = {
          currentAttempt: currentTry ? currentTry + 1 : 1,
          totalAttempts: section?.test?.testRetryCount || 0,
          limit: section?.test?.testLimit,
        }
        break
      case 'TEST_TIME':
        const time = section?.test?.time
        const haveHours = time && time / SECONDS_PER_HOUR >= 1
        const momentTime = time ? moment.utc(time * 1000) : undefined

        let timeValue = ''
        if (momentTime) {
          timeValue = momentTime.format(haveHours ? 'HH:mm:ss' : 'mm:ss')
        }

        value = {
          testTime: timeValue,
        }
        break
      case 'TEST_TIME_SPENT':
        value = {
          testTimeSpent: spendTime,
        }
        break
      case 'QUESTIONS':
        value = {
          questions: totalQuestions || questionslist,
        }
        break
      case 'QUESTIONS_SUCCESS':
        value = {
          successQuestions: totalQuestionsSuccess,
          totalQuestions: totalQuestions || questionslist,
        }
        break
      default:
        break
    }

    return template({ ...value, pt })
  }

  return getTestInfoItemVariable
}

export { useGetTestInfoItemVariable }
