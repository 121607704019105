import cn from 'classnames'

import Icon from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'
import { testProps, testPropsEl } from 'utils/test/qaData'

import s from './CheckableTag.module.scss'

export type TagsProps<T> = {
  options: { value: T; label: string }[]
  onChange: (val: T[]) => void
  value?: T[]
  name: string
  disabled?: boolean
  mode?: 'single' | 'multiple'
  hideClose?: boolean
}

const CheckableTag = <T extends string>({
  options,
  onChange,
  value = [],
  name,
  disabled,
  mode = 'multiple',
  hideClose = false,
}: TagsProps<T>) => {
  const onTagClick = (tag: T) => {
    if (disabled) {
      return
    }
    const alreadyChecked = value && value.includes(tag)
    if (mode === 'single') {
      hideClose ? onChange([tag]) : onChange(alreadyChecked ? [] : [tag])
    } else if ((mode = 'multiple')) {
      onChange(alreadyChecked ? value.filter((t) => t !== tag) : [...value, tag])
    }
  }

  return (
    <div
      className={s.root}
      {...testProps({ el: 'checkableTags', name, value: value ? value.join(',') : null })}
    >
      {options.map(({ value: tagValue, label }) => {
        const checked = value && value.includes(tagValue)
        const tagCn = cn(s.tag, {
          [s.checked]: checked,
          [s.disabled]: disabled,
        })
        return (
          <div
            className={tagCn}
            key={tagValue}
            onClick={() => onTagClick(tagValue)}
            {...testPropsEl('checkableTag', { name, value: tagValue }, { label, disabled })}
          >
            {label}
          </div>
        )
      })}
      {value && !!value.length && !disabled && !hideClose && (
        <div
          className={s.clearButton}
          onClick={() => onChange([])}
          {...testProps({ el: 'checkableTag', name: 'clearTags' })}
        >
          <Icon name='otherClose' size={KitSize.M} />
        </div>
      )}
    </div>
  )
}

export default CheckableTag
