import { gql } from 'gql/__generated__'

export const generateText = gql(`
  mutation GenerateText($data: TextGen!) {
    data: generateText(data: $data) {
      id
      content
    }
  }
`)

export const aiUsageAccept = gql(`
  mutation AiUsageAccept($id: String!) {
    data: aiUsageAccept(id: $id)
  }
`)

export const createAiConfig = gql(`
  mutation CreateAiConfig($data: AiConfigCreateInput!) {
    data: createAiConfig(data: $data) {
      id
      companyId
      provider
    }
  }
`)

export const updateAiConfig = gql(`
  mutation UpdateAiConfig($data: AiConfigUpdateInput!) {
    data: updateAiConfig(data: $data) {
      id
      active
      totalLimit
    }
  }
`)

export const deleteAiConfig = gql(`
  mutation DeleteAiConfig($id: String!) {
    data: deleteAiConfig(id: $id)
  }
`)

export const testAiConfig = gql(`
  mutation TestAiConfig($data: AiConfigCreateInput!) {
    data: testAiConfig(data: $data)
  }
`)
