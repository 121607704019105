import { SCHEMA } from '@leenda/editor/lib/brand'

import { genFormSchema, FONT_SCHEMA_FIELDS } from 'services/Branding/genFormSchema'
import { t } from 'services/Translation'

export const STYLE_FORM_SCHEMA = genFormSchema(SCHEMA.downloader.style, {
  downloader: {
    META: { label: t('elements.downloader.tags.downloader') },
    backgroundColor: {},
  },
  icon: {
    META: { hidden: true },
    color: { label: t('input.label.iconColor') },
  },
  label: {
    META: { hidden: true },
    backgroundColor: { label: t('elements.downloader.schema.labelBackground') },
    color: { label: t('elements.downloader.schema.labelText') },
  },
  border: {
    META: { label: t('elements.downloader.tags.border') },
    borderRadius: {},
    borderStyle: {},
    borderColor: {},
    borderWidth: {},
  },
})

export const FONT_FORM_SCHEMA = genFormSchema(SCHEMA.downloader.font, {
  base: {
    ...FONT_SCHEMA_FIELDS.base,
    META: { label: t('elements.downloader.tags.downloader') },
  },
})
