import React from 'react'
import { colorsTypes } from 'utils'

import Icon from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'
import { FinderFolderItem } from 'store/models'
import { testProps } from 'utils/test/qaData'

import s from './FolderComponent.module.scss'

interface IFolderComponentProps {
  folder: FinderFolderItem
  onClick?: any
}

const FolderComponent: React.FC<IFolderComponentProps> = ({ folder, onClick }) => {
  const onClickHandler = () => {
    onClick && onClick(folder)
  }

  return (
    <div
      className={s.root}
      {...testProps({ el: 'link', name: folder.name })}
      onClick={onClickHandler}
    >
      <Icon
        className={s.icon}
        name='iconFillFolder'
        size={KitSize.S}
        style={{ color: folder.color || colorsTypes.purpure }}
      />
      <div className={s.content} style={folder.isArchived ? { filter: 'grayscale(100%)' } : {}}>
        <div className={s.title} style={folder.isArchived ? { filter: 'grayscale(100%)' } : {}}>
          {folder.nodeName || folder.name}
        </div>
      </div>
    </div>
  )
}

export default FolderComponent
