import cn from 'classnames'
import React, { useContext } from 'react'
import { sortTypes } from 'utils'

import Icon from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'
import { testProps } from 'utils/test/qaData'

import { TableContext } from '../TableContext'
import { ColumnType } from '../types'
import s from './ColumnTitle.module.scss'

interface IColumnTitleProps<T extends { id: string }> {
  name: string
  config: ColumnType<T>
}

const ColumnTitle = <T extends { id: string }>({ name, config }: IColumnTitleProps<T>) => {
  const { sortKey, title, align } = config
  const {
    sort: { setSort, sortKey: sort, sortOrder },
  } = useContext(TableContext)
  const onClick = () => {
    sortKey && setSort(sortKey)
  }
  const asc = sort === sortKey && sortOrder === sortTypes.ascending

  return (
    <div
      className={cn(s.root, { [s.active]: sort === sortKey, [s.right]: align === 'right', [s.sortable]: !!sortKey })}
      onClick={onClick}
      {...testProps({ el: 'column', title, name })}
      style={{ whiteSpace: 'nowrap' }}
    >
      <span>{title}</span>
      {sortKey && (
        <div className={cn(s.icon, { [s.asc]: asc })}>
          <Icon name='otherSort' size={KitSize.S} />
        </div>
      )}
    </div>
  )
}

export default ColumnTitle
