import { CONFIG_ELEMENTS } from '@leenda/editor/lib/elements'

import { t } from 'services/Translation'

import { ElementGroup } from '../../elements.types'
import { genElementConfig } from '../../genElementConfig'
import { STYLE_FORM_SCHEMA, FONT_FORM_SCHEMA } from './DividerBrandFormSchema'
import { DemoDashed, DemoDotted, DemoSolid, DemoNumbered } from './DividerDemo'
import Element from './DividerElement'
import Form from './DividerForm'
import dividerIcon from './assets/divider.svg'

export default genElementConfig(CONFIG_ELEMENTS.divider, {
  label: t('elements.divider.label'),
  icon: dividerIcon,
  group: ElementGroup.view,
  Element,
  ValueForm: Form,
  STYLE_FORM_SCHEMA,
  FONT_FORM_SCHEMA,
  Demo: [DemoSolid, DemoDashed, DemoDotted, DemoNumbered],
})
