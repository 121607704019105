import lodash from 'lodash'
import * as R from 'ramda'

import { ScormSuspendData } from 'scormWrapper/vendors/SCORM_API_types'

import { IProjectContext, MapType, Section, Block } from './types'

export const stateToSuspendData = (state: IProjectContext): ScormSuspendData => {
  const mapping = getMapping(state.data)
  return {
    course: state.state.course,
    sections: lodash.mapKeys(state.state.sections, (_section, id) => mapping[id]),
    blocks: lodash.mapKeys(state.state.blocks, (_block, id) => mapping[id]),
    elements: lodash.mapKeys(state.state.elements, (_element, id) => mapping[id]),
  }
}

export const suspendDataToState = (
  data: { sections: MapType<Section>; blocks: MapType<Block> },
  suspendData: ScormSuspendData,
) => {
  const mapping = getMapping(data)
  const revertedIdToIndex = revertMapping(mapping)
  return {
    course: suspendData.course,
    sections: lodash.mapKeys(suspendData.sections, (_section, id) => revertedIdToIndex[id]),
    blocks: lodash.mapKeys(suspendData.blocks, (_block, id) => revertedIdToIndex[id]),
    elements: lodash.mapKeys(suspendData.elements, (_element, id) => revertedIdToIndex[id]),
  }
}

type Mapping = { [id: string]: string }

const getMapping = lodash.memoize(
  (data: { sections: MapType<Section>; blocks: MapType<Block> }) => {
    const { sections, blocks } = data
    const mapping: Mapping = {}
    const ids = [
      ...R.keys(sections),
      ...R.keys(blocks),
      ...(R.values(blocks).flatMap(({ elements }) => R.keys(elements)) as string[]),
    ]

    ids.sort()
    ids.forEach((id, index) => (mapping[id] = index.toString()))
    return mapping
  },
)

const revertMapping = (mapping: Mapping): Mapping => {
  const reverted: Mapping = {}
  for (const key of Object.keys(mapping)) {
    reverted[mapping[key]] = key
  }
  return reverted
}
