import React, { ReactNode, useCallback, useState } from 'react'
import { createContext } from 'use-context-selector'

interface IMediaContext {
  setActiveMediaPlayer: (mediaInstance: HTMLDivElement | null, pauseFn?: () => void) => void
  activeMedia: { instance: HTMLDivElement | null; pause: (() => void) | null } | null
  clearActiveMediaPlayer: (mediaInstance: HTMLDivElement | null) => void
}

interface IMediaProviderProps {
  children: ReactNode
}

export const MediaContext = createContext<IMediaContext | null>(null)

const MediaProvider: React.FC<IMediaProviderProps> = ({ children }) => {
  const [activeMedia, setActiveMedia] = useState<{
    instance: HTMLDivElement | null
    pause: (() => void) | null
  } | null>(null)

  const setActiveMediaPlayer = useCallback(
    (mediaInstance: HTMLDivElement | null, pauseFn?: () => void) => {
      if (activeMedia && activeMedia.instance !== mediaInstance && activeMedia.pause) {
        activeMedia.pause()
      }
      setActiveMedia({ instance: mediaInstance, pause: pauseFn || null })
    },
    [activeMedia],
  )

  const clearActiveMediaPlayer = useCallback((mediaInstance: HTMLDivElement | null) => {
    setActiveMedia((current) => (current?.instance === mediaInstance ? null : current))
  }, [])

  return (
    <MediaContext.Provider value={{ activeMedia, setActiveMediaPlayer, clearActiveMediaPlayer }}>
      {children}
    </MediaContext.Provider>
  )
}

export default MediaProvider
