import cn from 'classnames'
import trialFooter from 'platform/assets/logo.svg'
import { PLATFORM_CONFIG } from 'platform/platformComfig'
import React from 'react'

import Link from 'components/uiKit/Link'
import { DeviceMode } from 'services/Store/Project/enums'
import { useProjectContext } from 'services/Store/Project/hooks'
import { getDeviceMode } from 'services/Store/Project/selectors'
import { t } from 'services/Translation'

import s from './TrialLogo.module.scss'

const TrialLogo: React.FC = () => {
  const deviceMode = useProjectContext(getDeviceMode)
  const isMobile = deviceMode === DeviceMode.mobile

  return (
    <div className={cn(s.root, s[deviceMode])}>
      <div className={cn(s.title, s[deviceMode])}>
        {isMobile ? (
          <div className={s.mobile}>
            <img alt={t('common.platformName')} className={s.logo} src={trialFooter} />
            <div>
              Created @&nbsp;
              <Link
                className={s.link}
                name='contactSales'
                styleType='free'
                target='_blank'
                to={PLATFORM_CONFIG.trialLink}
              >
                {PLATFORM_CONFIG.PlatformName}
              </Link>
              <div className={cn(s.description, s[deviceMode])}>
                This message is displayed during the trial period only.
              </div>
            </div>
          </div>
        ) : (
          <>
            <img alt={t('common.platformName')} className={s.logo} src={trialFooter} />
            Created @&nbsp;
            <Link
              className={s.link}
              name='contactSales'
              styleType='free'
              target='_blank'
              to={PLATFORM_CONFIG.trialLink}
            >
              {PLATFORM_CONFIG.PlatformName}
            </Link>
            <div className={cn(s.description, s[deviceMode])}>
              &nbsp;This message is displayed during the trial period only.
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default TrialLogo
