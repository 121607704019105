import { useEffect } from 'react'
import { Editor, Transforms } from 'slate'
import { ReactEditor } from 'slate-react'

import { CustomEditor } from './slate'

export const useAutofocus = (editor: CustomEditor, autofocus?: boolean) => {
  useEffect(() => {
    if (autofocus) {
      // hotfix scrolling when focusing element outside viewport
      setTimeout(() => {
        try {
          const node = ReactEditor.toDOMNode(editor, editor)
          if (node) {
            node.focus({ preventScroll: true })
            Transforms.select(editor, Editor.end(editor, []))
          }
        } catch (error) {
          console.warn(error)
        }
      }, 0)
    }
  }, [autofocus, editor])
}
