import { Middleware } from '@reduxjs/toolkit'
import qs from 'query-string'
import * as R from 'ramda'

import generateLink from 'routes/generateLink'
import history from 'routes/history'

import { isAction } from '../actionTypeGuard'
import { IProjectContext, ProjectPaths, ProjectUrlType } from '../types'

const cleanUndefined = R.pickBy((v) => v !== undefined)

const cleanParams = <T extends ProjectUrlType>(
  old: T,
  params: { [key in keyof T]?: T[keyof T] | null },
): T => R.pickBy((v) => !R.isNil(v), { ...old, ...params })

const cleanSearch = <T extends Record<string, unknown>>(
  old: T,
  params: { [key in keyof T]?: T[keyof T] | null },
) =>
  `?${qs.stringify(
    R.pickBy((v) => !R.isNil(v), {
      ...R.pick(['commentId', 'threadId', 'taskId'], old),
      ...params,
    }),
  )}`

const makeUpdateHistory =
  <T extends ProjectUrlType>(path: ProjectPaths, oldParams: T) =>
  (
    params: { [key in keyof T]?: T[keyof T] | null },
    search?: { [key in keyof T]?: T[keyof T] | null },
  ) => {
    const link = generateLink(path, cleanParams(oldParams, cleanUndefined(params)), {
      search: search ? cleanSearch(oldParams, search) : '',
    })
    return history.replace(link)
  }

export const navigation: Middleware = (store) => (next) => (action) => {
  const result = next(action)
  const nextState: IProjectContext = store.getState().project
  const { path, urlParams } = nextState
  const updateHistory = makeUpdateHistory(path, urlParams)

  if (
    // INIT
    isAction(action, 'init') ||
    // COMMENTS
    isAction(action, 'setOpenedComment') ||
    // TASKS
    isAction(action, 'setOpenedTask') ||
    // Project navigation
    isAction(action, 'setProjectNavigation') ||
    isAction(action, 'updateBlock') ||
    isAction(action, 'undoBlock') ||
    isAction(action, 'redoBlock') ||
    // Course navigation
    isAction(action, 'setCourseNavigation') ||
    isAction(action, 'courseStart') ||
    isAction(action, 'courseContinue') ||
    isAction(action, 'testStart') ||
    isAction(action, 'testRestart') ||
    isAction(action, 'testEnd')
  ) {
    const { sectionId, blockId, nodeId } = nextState.urlParams
    updateHistory({ sectionId, blockId, nodeId })
  }

  return result
}
