import cn from 'classnames'
import { isToday, isSameDay, isAfter, isBefore } from 'date-fns'
import React from 'react'

import s from './DatePickerDay.module.scss'

interface IDatePickerDayProps {
  day: number
  date?: Date
  selected?: Date | null
  minDate?: Date | number
  maxDate?: Date | number
}

const DatePickerDay: React.FC<IDatePickerDayProps> = ({
  day,
  date,
  selected,
  maxDate,
  minDate,
}) => {
  const disabledByMin =
    (minDate && date && isBefore(date, minDate) && !isSameDay(date, minDate)) || false
  const disabledByMax =
    (maxDate && date && isAfter(date, maxDate) && !isSameDay(date, maxDate)) || false

  const cnRoot = cn(s.root, {
    [s.today]: date && isToday(date),
    [s.disabled]: disabledByMin || disabledByMax,
    [s.selected]: date && selected && isSameDay(date, selected),
  })
  return (
    <div className={cnRoot} title={date?.toString()}>
      {day}
    </div>
  )
}

export default DatePickerDay
