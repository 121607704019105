import React from 'react'

import { IEmptyProps } from 'components/uiKit/Empty'
import Table, { useColumns } from 'components/uiKit/Table'
import { FinderItem } from 'store/models'

import { Paths } from '../types'
import { columnsConfig, columnsConfigModal } from './config'

export const FINDER_PAGE_NAME = 'finderPage'

interface IFinderTableProps {
  data: FinderItem[]
  onChangeSelection?: (radio: FinderItem[]) => void
  total: number
  selectedItems: FinderItem[]
  loading: boolean
  empty?: IEmptyProps
  clearQuery: () => void
  paths: Paths
  fetchMore?: () => Promise<unknown>
  onClickItem: (item: FinderItem) => void
  isModal?: boolean
}

const FinderTable: React.FC<IFinderTableProps> = ({
  data,
  onChangeSelection,
  onClickItem,
  total,
  selectedItems,
  loading,
  clearQuery,
  empty,
  paths,
  isModal,
}) => {
  const columnConfig = isModal ? columnsConfigModal(onClickItem) : columnsConfig(paths, clearQuery)
  const { columns } = useColumns(FINDER_PAGE_NAME, columnConfig)
  return (
    <Table
      columns={columns}
      dataSource={data}
      empty={empty}
      loading={loading}
      name={FINDER_PAGE_NAME}
      onChangeSelection={onChangeSelection}
      selectedItems={selectedItems}
      total={total}
      checked
    />
  )
}

export default FinderTable
