import { useCallback } from 'react'

import { IControlProps } from 'components/controls/Field/Field.types'
import Switcher, { ISwitcherProps } from 'components/uiKit/Switcher/Switcher'

const FieldSwitcher: React.FC<IControlProps<ISwitcherProps>> = ({
  name,
  onChange,
  defaultValue,
  value,
  ...rest
}) => {
  const handleChange = useCallback((value: boolean) => onChange({ value, name }), [name, onChange])
  return (
    <Switcher
      name={name}
      onChange={handleChange}
      value={value ?? (defaultValue as boolean)}
      {...rest}
    />
  )
}

export default FieldSwitcher
