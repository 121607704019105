import React, { Suspense } from 'react'

import Loader from 'components/uiKit/Loader'

const WithSuspense = (
  Component: React.LazyExoticComponent<React.ComponentType<any>>,
  Fallback?: React.ComponentType<any>,
) => {
  const WithSuspense = (props: any) => (
    <Suspense
      fallback={
        Fallback ? <Fallback /> : <Loader name='withSuspenseLoader' styleType='fixed' resources />
      }
    >
      {Component ? <Component {...props} /> : <div />}
    </Suspense>
  )
  return WithSuspense
}

export { WithSuspense }
