import { SidebarMenuType } from '@leenda/editor/lib/brand/types'
import { CONFIG_COURSE } from '@leenda/editor/lib/elements'

import { useElementStyleCss } from 'services/Branding/hooks'
import { IBlockMode } from 'services/Store/Project/types'

import { DrawerSidebarContainer } from './DrawerSidebar'
import FixedSidebarContainer from './FixedSidebar/FixedSidebarContainer'

interface ICourseSidebarProps {
  mode: IBlockMode
}

const CourseSidebar: React.FC<ICourseSidebarProps> = ({ mode }) => {
  const styles = useElementStyleCss(CONFIG_COURSE.sidebar.type, mode.deviceMode)
  const drawer = styles.menu.type === SidebarMenuType.drawer
  const fixed = styles.menu.type === SidebarMenuType.fixed

  return (
    <>
      {drawer && <DrawerSidebarContainer mode={mode} />}
      {fixed && <FixedSidebarContainer mode={mode} />}
    </>
  )
}

export default CourseSidebar
