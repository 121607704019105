import { t } from 'services/Translation'

export const DEFAULT_FORM_ERRORS = {
  NAME_NOT_UNIQUE: t('input.error.nameExist'),
}

export const EXCLUDE_FORM_HANDLERS = {
  onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault()
    }
  },
}
