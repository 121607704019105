import { Highlighted } from 'components/uiKit/Table/types'
import { FileMetaSchemaFragment, FilesGroupsFragment } from 'gql/__generated__/graphql'

export type FinderItem = FinderFileItem | FinderFolderItem

export type FinderFileItem = Highlighted<FileMetaSchemaFragment> & FinderItemAddition
export type FinderFolderItem = Highlighted<FilesGroupsFragment> & FinderItemAddition

type FinderItemAddition = {
  img?: string | null
  iconLink?: React.FC<React.SVGProps<SVGSVGElement>> | null
  path?: string
  type?: string
}

export enum EntryTypeName {
  projectGroup = 'ProjectGroup',
  project = 'Project',
  fileMetaGroup = 'FileMetaGroup',
  fileMeta = 'FileMeta',
}
