import { SYMBOLS_CODES, SlateFormats } from 'components/AppText/constants'
import {
  getFormat,
  getOnUpdateFormat,
  useAppTextSelector,
} from 'components/AppText/useAppTextSelector'
import Button from 'components/uiKit/Button'
import { Tooltip } from 'components/uiKit/Dropdown'
import { t } from 'services/Translation'

import s from './SymbolItem.module.scss'

const SymbolItem = ({ name }: { name: SlateFormats }) => {
  const onUpdateFormat = useAppTextSelector(getOnUpdateFormat)
  const format = useAppTextSelector(getFormat)

  return (
    <Tooltip overlay={t(`uiKit.tooltip.${name}` as const)} trigger={['hoverWithoutContent']}>
      <div className={s.root}>
        <Button name={name} onClick={() => onUpdateFormat(name, format)} styleType='ghost'>
          {String.fromCodePoint(SYMBOLS_CODES[name])}
        </Button>
      </div>
    </Tooltip>
  )
}

export default SymbolItem
