import { SCHEMA } from '@leenda/editor/lib/brand'

import { genFormSchema, FONT_SCHEMA_FIELDS } from 'services/Branding/genFormSchema'
import { t } from 'services/Translation'

export const STYLE_FORM_SCHEMA = genFormSchema(SCHEMA.testInfoEnd.style, {
  circle: {
    META: { label: t('elements.test-info.tags.icon') },
    backgroundColor: {},
    color: { label: t('input.label.iconColor') },
    borderStyle: {},
    borderColor: {},
    borderWidth: {},
  },
})

export const FONT_START_FORM_SCHEMA = genFormSchema(SCHEMA.testInfoEnd.font, {
  base: {
    ...FONT_SCHEMA_FIELDS.base,
    META: { label: t('elements.test-info.tags.startTest') },
  },
})

export const FONT_END_FORM_SCHEMA = genFormSchema(SCHEMA.testInfoEnd.font, {
  base: {
    ...FONT_SCHEMA_FIELDS.base,
    META: { label: t('elements.test-info.tags.testResult') },
  },
})
