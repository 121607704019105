import { AppMode, PreviewMode } from '../../enums'
import { IProjectContext } from '../../types'

export const getScrollReady = (context: IProjectContext) =>
  Object.values(context.state.scrollReady).every(Boolean)

const getScrollBlockMargin = (context: IProjectContext) => {
  if (context.mode === PreviewMode.editor) {
    if (context.state.mode === AppMode.pro) {
      return 74
    }
    return 56
  }
  return 16
}

export const getScrollContext = (context: IProjectContext) => ({
  blockMargin: getScrollBlockMargin(context),
})
