import { GroupingFontSchemaType, GroupingSchemaType } from '@leenda/editor/lib/brand'
import { GroupingItem, GroupingGroup } from '@leenda/editor/lib/elements'
import { createContext } from 'use-context-selector'

import { NOOP } from 'constants/commonConstans'
import { ElementFontCss, ElementStyleCss } from 'services/Branding/types'
import { PREVIEW_MODE } from 'services/Store/Project/constants'
import { IBlockMode } from 'services/Store/Project/types'

import { ValidationMap } from './components/DefaultView/PortalGroupingItem/PortalGroupingItem'

interface IGroupingContextProps {
  destination: GroupingGroup[]
  hasGroupImage?: boolean
  hasAnswerImage?: boolean
  styles: ElementStyleCss<GroupingSchemaType>
  font?: ElementFontCss<GroupingFontSchemaType>
  mode: IBlockMode
  shuffledAnswers: GroupingItem[]
  testResult: { [key: string]: boolean }
  validationMap: ValidationMap
  setRetry: React.Dispatch<React.SetStateAction<number>>
  setDestination: React.Dispatch<React.SetStateAction<GroupingGroup[]>>
  setShuffledAnswers: React.Dispatch<React.SetStateAction<GroupingItem[]>>
  setTestResult: React.Dispatch<
    React.SetStateAction<{
      [key: string]: boolean
    }>
  >
}

const GroupingContext = createContext<IGroupingContextProps>({
  destination: [],
  hasGroupImage: false,
  hasAnswerImage: false,
  styles: {} as ElementStyleCss<GroupingSchemaType>,
  mode: PREVIEW_MODE,
  shuffledAnswers: [],
  testResult: {},
  validationMap: {},
  setDestination: NOOP,
  setRetry: NOOP,
  setShuffledAnswers: NOOP,
  setTestResult: NOOP,
})

export default GroupingContext
