import { DragDropContext, Droppable, Draggable, DropResult } from '@hello-pangea/dnd'
import { produce } from 'immer'
import lodash from 'lodash'
import { useState } from 'react'
import { useContext } from 'use-context-selector'

import GroupingContext from '../../GroupingContext'
import Result from '../Result'
import s from './DefaultElement.module.scss'
import DestinationCard from './DestinationCard'
import PortalGroupingItem from './PortalGroupingItem'

const DefaultElement = () => {
  const {
    styles,
    shuffledAnswers,
    validationMap,
    destination,
    testResult,
    setDestination,
    setShuffledAnswers,
  } = useContext(GroupingContext)
  const [draggedId, setDraggedId] = useState<string | undefined>()

  const onDragEnd = (result: DropResult) => {
    const el = shuffledAnswers[result.source.index]
    if (!result.destination || validationMap[el.value] !== result.destination?.droppableId) {
      testResult[el.value] = false
    }
    if (testResult[el.value] === undefined) {
      testResult[el.value] = true
    }
    if (!result.destination) {
      return
    }

    const newDest = produce(destination, (draft) => {
      const dest = draft.find((e) => e.value === result.destination?.droppableId)
      dest?.items.push(el)
    })

    setDestination(newDest)
    const shuffled = shuffledAnswers.filter((e) => e.value !== result.draggableId)
    setShuffledAnswers(shuffled)
    setDraggedId(shuffled[shuffled.length - 1]?.value)
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div>
        {shuffledAnswers.length === 0 && <Result />}
        {shuffledAnswers.length > 0 && (
          <Droppable
            direction='vertical'
            droppableId='source'
            type={draggedId && validationMap[draggedId]}
            isDropDisabled
          >
            {(provided) => {
              return (
                <div
                  {...provided.droppableProps}
                  className={s.answerWrapper}
                  style={{ borderRadius: styles.answer.borderRadius }}
                >
                  <div
                    ref={provided.innerRef}
                    style={{
                      ...lodash.omit(styles.answer, 'borderStyle'),
                      backgroundColor: styles.answer.backgroundColor || undefined,
                    }}
                  >
                    {shuffledAnswers.map((i, index) => (
                      <Draggable draggableId={i.value} index={index} key={`${i.value}${index}`}>
                        {(provided, snapshot) => (
                          <PortalGroupingItem item={i} provided={provided} snapshot={snapshot} />
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                </div>
              )
            }}
          </Droppable>
        )}
      </div>
      <div className={s.destContainer} style={{ gap: styles.indents.gap }}>
        {destination.map((e) => {
          return (
            <Droppable
              direction='vertical'
              droppableId={e.value}
              key={e.value}
              type={draggedId && validationMap[draggedId]}
            >
              {(provided) => <DestinationCard group={e} provided={provided} />}
            </Droppable>
          )
        })}
      </div>
    </DragDropContext>
  )
}

export default DefaultElement
