import { FileUsage } from '@leenda/editor/lib/files'

import { IControlProps } from 'components/controls/Field/Field.types'
import FileLoader from 'components/uiKit/FileLoader'

export type FileTypes = 'image' | 'audio' | 'video' | 'document'

interface IFieldFile {
  value: FileUsage
  fileType: FileTypes
  placeholder?: string
  nullable?: boolean
  preview?: boolean
}

const FieldFile: React.FC<IControlProps<IFieldFile>> = ({
  value,
  onChange,
  name,
  fileType,
  defaultValue,
  onFocus,
  onBlur,
  ...rest
}) => {
  return (
    <FileLoader
      fileType={fileType}
      name={name}
      onBlur={onBlur}
      // @ts-ignore
      onChange={onChange}
      onFocus={onFocus}
      source='file'
      value={value ?? defaultValue}
      {...rest}
    />
  )
}

export default FieldFile
