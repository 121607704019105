"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const constants_1 = require("../../../elements/course/Sidebar/constants");
const font_1 = require("../../font");
const theme_1 = require("../../theme");
const types_1 = require("../../types");
const genStyle_1 = require("../genStyle");
const styleSchema = {
    menu: { type: (0, genStyle_1.genStyle)({ value: types_1.SidebarMenuType.drawer }) },
    value: {
        position: (0, genStyle_1.genStyle)({ value: types_1.MenuPositionType.left }),
        showLogo: (0, genStyle_1.genStyle)({ value: false }),
        showOverlay: (0, genStyle_1.genStyle)({ value: true }),
        showDescription: (0, genStyle_1.genStyle)({ value: true }),
        initialOpen: (0, genStyle_1.genStyle)({ value: true }),
        collapsePosition: (0, genStyle_1.genStyle)({ value: 'none' }),
        progressPosition: (0, genStyle_1.genStyle)({ value: 'left' }),
        fixedWidth: (0, genStyle_1.genStyle)({ value: 560 }),
    },
    item__var: {
        backgroundColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.hoverColor }),
    },
    itemHover__var: {
        backgroundColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.hoverColor }),
    },
    delimiter__var: {
        backgroundColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.borderColor }),
    },
    progress: {
        backgroundColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.lightBackgroundColor }),
        color: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.primaryColor }),
        borderColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.borderColor }),
        borderStyle: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.borderStyle }),
        borderWidth: (0, genStyle_1.genStyle)({ value: 1 }),
        showLine: (0, genStyle_1.genStyle)({ value: true }),
        showDescriptionProgress: (0, genStyle_1.genStyle)({ value: true }),
    },
    margins: {
        offsetFromIcon: (0, genStyle_1.genStyle)({ value: 8 }),
        offsetYFromSection: (0, genStyle_1.genStyle)({ value: 12 }),
        offsetFromProgress: (0, genStyle_1.genStyle)({ value: 16 }),
        offsetFromCollapse: (0, genStyle_1.genStyle)({ value: 8 }),
    },
    chapterIcons: {
        showIcons: (0, genStyle_1.genStyle)({ value: false }),
        backgroundImage: (0, genStyle_1.genStyle)({ value: null }),
    },
    sectionIcons: {
        showIcons: (0, genStyle_1.genStyle)({ value: false }),
        backgroundImage: (0, genStyle_1.genStyle)({ value: null }),
    },
    testIcons: {
        showIcons: (0, genStyle_1.genStyle)({ value: false }),
        backgroundImage: (0, genStyle_1.genStyle)({ value: null }),
    },
};
const fontSchema = {
    title: Object.assign({}, font_1.FONT_SCHEMA_EMPTY.h4),
    chapter: Object.assign(Object.assign({}, font_1.FONT_SCHEMA_EMPTY.base), { color: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.secondaryTextColor }) }),
    section: Object.assign({}, font_1.FONT_SCHEMA_EMPTY.base),
    description: Object.assign(Object.assign({}, font_1.FONT_SCHEMA_EMPTY.base), { fontSize: (0, genStyle_1.genStyle)({ value: 11 }) }),
};
exports.default = { [constants_1.TYPE]: { style: styleSchema, font: fontSchema } };
