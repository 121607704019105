import { useInterval } from 'ahooks'
import lodash from 'lodash'
import moment from 'moment'
import { useEffect, useState } from 'react'

const SECOND = 1000
const MINUTE = 60 * SECOND
const HOUR = 60 * MINUTE

const FIRE_TIME = 2 * MINUTE

const UPDATE_INTERVAL = 100
const getSeconds = (time?: number | null) => lodash.round(time || Date.now(), -3)

interface ITimerOptions {
  onFinish: () => void
  time: number
  startTime?: number | null
  stop?: boolean
}

export const useTestTimer = ({ onFinish, startTime, time, stop }: ITimerOptions) => {
  const [now, setNow] = useState(getSeconds())

  const deadline = getSeconds(startTime) + time * SECOND
  const left = stop ? time * SECOND : deadline - getSeconds()
  const format = left <= HOUR ? 'mm:ss' : 'HH:mm:ss'

  useInterval(() => !stop && now !== getSeconds() && setNow(getSeconds()), UPDATE_INTERVAL)

  useEffect(() => {
    if (now >= deadline) {
      onFinish()
    }
  }, [now, deadline, onFinish])

  return {
    left: moment.utc(left).format(format),
    isFire: left <= FIRE_TIME,
  }
}
