import { ElementsTypes, TimePoint } from '@leenda/editor/lib/elements'
import React from 'react'
import { videoFormat } from 'utils'

import { genField } from 'components/controls/Field'
import Form from 'components/controls/Form'
import FormControls from 'components/controls/FormControls'
import FormField from 'components/controls/FormField'
import { t } from 'services/Translation'

import s from './PointForm.module.scss'

export const time = (
  duration: number | null,
  timePoints: TimePoint[],
  currentPoint: number | null,
  type: ElementsTypes,
) =>
  genField({
    name: 'time',
    type: 'time',
    params: { format: duration && duration > 3600 ? 'HH:mm:ss' : 'mm:ss', autoFocus: true },
    rules: [
      {
        required: true,
        message: t('input.error.required', { field: t('input.label.time') }),
      },
      {
        validator: (_, value) => {
          if (typeof value === 'number' && typeof duration === 'number') {
            if (value > duration) {
              return type === 'audio'
                ? t('input.error.audioDuration', { duration: videoFormat(duration) })
                : t('input.error.videoDuration', { duration: videoFormat(duration) })
            }
            if (timePoints.filter(({ time }, i) => time === value && i !== currentPoint).length) {
              return t('input.error.pointExist')
            }
          }
          return false
        },
      },
    ],
  })

export const title = genField({
  name: 'title',
  type: 'textarea',
  rules: [
    {
      required: true,
      message: t('input.error.requiredField', { field: t('input.label.description') }),
    },
  ],
})

interface IPointFormProps {
  duration: number | null
  currentPoint: number | null
  timePoints: TimePoint[]
  initialValues?: TimePoint
  onSubmit: (pointValue: TimePoint) => void
  onReset: () => void
  type: ElementsTypes
}

const PointForm: React.FC<IPointFormProps> = ({
  onSubmit,
  onReset,
  initialValues,
  duration,
  timePoints,
  currentPoint,
  type,
}) => {
  const onSave = (values: { time: number; title: string }) => {
    onSubmit(values)
  }

  return (
    <div className={s.root}>
      <Form
        initialValues={initialValues}
        name='addNewTimePoint'
        onReset={onReset}
        onSubmit={onSave}
      >
        <FormField config={time(duration, timePoints, currentPoint, type)} />
        <FormField config={title} />
        <div className={s.controls}>
          <FormControls />
        </div>
      </Form>
    </div>
  )
}

export default PointForm
