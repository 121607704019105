import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AccountMock } from 'components/uiKit/Employee'
import { EMPTY_ARRAY } from 'constants/commonConstans'
import { EmployeeListSchemaFragment } from 'gql/__generated__/graphql'

import { AccountPosition, Position } from './UsersPosition.types'

export interface IUsersState {
  myPosition: Position | null
  pinnedEmployee: AccountMock | null
  users: AccountPosition[]
  employees: (EmployeeListSchemaFragment & { color: string })[]
  userPreferences: Record<string, unknown>
  meId: string
  total: number
}

const initialState: IUsersState = {
  myPosition: null,
  pinnedEmployee: null,
  users: [],
  employees: [],
  meId: '',
  total: 0,
  userPreferences: {},
}

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setMyPosition: (state, action: PayloadAction<Position | null>) => {
      state.myPosition = action.payload
    },
    setPinnedEmployee: (state, action: PayloadAction<AccountMock | null>) => {
      state.pinnedEmployee = action.payload
    },
    setUsers: (state, action: PayloadAction<AccountPosition[]>) => {
      state.users = action.payload.length ? action.payload : EMPTY_ARRAY
    },
    setEmployees: (
      state,
      action: PayloadAction<{
        employees: (EmployeeListSchemaFragment & { color: string })[]
        total: number
      }>,
    ) => {
      state.employees = action.payload.employees
      state.total = action.payload.total
    },
    setMeId: (state, action: PayloadAction<string>) => {
      state.meId = action.payload
    },
    setUserPreferences: (state, action: PayloadAction<Record<string, unknown>>) => {
      state.userPreferences = action.payload
    },
    setUserPreferencesItems: (state, action: PayloadAction<Record<string, unknown>>) => {
      state.userPreferences = { ...state.userPreferences, ...action.payload }
    },
  },
})

export const {
  setMyPosition,
  setPinnedEmployee,
  setEmployees,
  setUsers,
  setUserPreferences,
  setUserPreferencesItems,
  setMeId,
} = usersSlice.actions

export default usersSlice.reducer
