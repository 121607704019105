import React from 'react'
import { chainName } from 'utils'

import { genDataField } from 'components/controls/Field'
import RealtimeField from 'components/controls/RealtimeField'
import Padding from 'components/editor-v3/Editor/Toolbar/Padding'
import { Block } from 'services/Store/Project/types'
import { t } from 'services/Translation'

import { TestKeyboardInputValueFormType } from './TestKeyboardInputTypes'

const genField = genDataField<Block>()

const label = genField({
  name: 'label',
  type: 'text',
  params: {
    placeholder: t('input.placeholder.writeAnAnswer'),
  },
})

const items = genField({
  name: 'items',
  type: 'array',
  layout: 'horizontal',
  defaultValue: { label: '', points: 1 },
  fields: [label],
  params: {
    itemLabel: t('input.label.addComment'),
    max: 15,
    min: 1,
  },
})

const TestKeyboardInputForm: TestKeyboardInputValueFormType = ({ name }) => (
  <Padding sides>
    <RealtimeField config={chainName(name, items)} />
  </Padding>
)

export default TestKeyboardInputForm
