// import platformLogo from 'platform/assets/platform.svg'
// import { PLATFORM_CONFIG } from 'platform/platformComfig'
import React, { useMemo } from 'react'
import { useHistory, useParams } from 'react-router'

import Link from 'components/uiKit/Link'
import { notify } from 'components/uiKit/Notification'
import { NotificationType } from 'components/uiKit/Notification/types'
import { HOME_PATHS } from 'constants/paths'
import generateLink from 'routes/generateLink'

import { DataFallbackConfigType, DataFallbackTypeEnum } from '../types'
import s from './DisplayError.module.scss'
import { LINK_TEXT_MAP, DESCRIPTION_TEXT_MAP, SVG_MAP, getTextTitleMap } from './constants'

type IDisplayErrorProps = Omit<DataFallbackConfigType, 'position | view'> & { onClose?: () => void }

const DisplayError: React.FC<IDisplayErrorProps> = ({
  title,
  type = DataFallbackTypeEnum.commonError,
  backUrl,
  backCb,
  linkLabel,
  textNotification = DESCRIPTION_TEXT_MAP[DataFallbackTypeEnum.notFound],
  onClose,
}) => {
  const history = useHistory()
  const oldParams = useParams<Record<string, string>>()
  const { path = HOME_PATHS.home, params, search } = backUrl ?? {}
  const url = useMemo(
    () => generateLink(path, { ...oldParams, ...params }),
    [path, oldParams, params],
  )

  url.search = search

  if (type === DataFallbackTypeEnum.notification) {
    notify({ type: NotificationType.error, message: textNotification })
    if (backUrl !== null) {
      history.replace(url)
    }
    if (backCb) {
      backCb()
    }
    return null
  }

  const titleText = getTextTitleMap(title)[type]
  const linkText = linkLabel || LINK_TEXT_MAP[type]
  const descriptionText = DESCRIPTION_TEXT_MAP[type]

  const IconComponent = SVG_MAP[type]

  return (
    <div className={s.root}>
      {IconComponent && <IconComponent />}
      <div className={s.title}>{titleText}</div>
      <div className={s.description}>{descriptionText}</div>
      <span className={s.goToPlatform} onClick={() => onClose?.()}>
        <Link name='goToPlatform' to={url} replace>
          {linkText}
        </Link>
      </span>
    </div>
  )
}

export default DisplayError
