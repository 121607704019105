import { BlockMode, SectionTypeEnum } from 'services/Store/Project/enums'

export enum UiTemplateTags {
  all = 'all',
  text = 'text',
  image = 'image',
  list = 'list',
  multimedia = 'multimedia',
  interactive = 'interactive',
  quote = 'quote',
  separators = 'separators',
  affirmation = 'affirmation',
  questions = 'questions',
  start = 'start',
  end = 'end',
  cover = 'cover',
}

export const UiTemplateTagsBySection: { [key in SectionTypeEnum]: UiTemplateTags[] } = {
  [SectionTypeEnum.landing]: [
    UiTemplateTags.text,
    UiTemplateTags.image,
    UiTemplateTags.list,
    UiTemplateTags.multimedia,
    UiTemplateTags.interactive,
    UiTemplateTags.quote,
    UiTemplateTags.separators,
    UiTemplateTags.affirmation,
    UiTemplateTags.questions,
  ],
  [SectionTypeEnum.chapter]: [],
  [SectionTypeEnum.test]: [UiTemplateTags.questions, UiTemplateTags.start, UiTemplateTags.end],
  [SectionTypeEnum.cover]: [UiTemplateTags.cover],
}

export enum TemplatePickerMode {
  multi = 'multi',
  single = 'single',
  change = 'change',
}

export enum TemplatePickerModalType {
  change = 'change',
  add = 'add',
}

// TODO: not used - remove
export const templateTagToBlockMode = (tag: UiTemplateTags) => {
  if (tag === UiTemplateTags.start) {
    return BlockMode.start
  }
  if (tag === UiTemplateTags.questions) {
    return BlockMode.questions
  }
  if (tag === UiTemplateTags.end) {
    return BlockMode.end
  }
  if (tag === UiTemplateTags.cover) {
    return BlockMode.cover
  }
  return null
}

export const blockModeToTemplateTag = (mode: BlockMode) => {
  if (mode === BlockMode.start) {
    return UiTemplateTags.start
  }
  if (mode === BlockMode.questions) {
    return UiTemplateTags.questions
  }
  if (mode === BlockMode.end) {
    return UiTemplateTags.end
  }
  if (mode === BlockMode.cover) {
    return UiTemplateTags.cover
  }
  return null
}

export enum TemplateStepperState {
  completed = 'completed',
  disabled = 'disabled',
  alert = 'alert',
  active = 'active',
  notSelected = 'notSelected',
}
