import { ThemeKeys } from '@leenda/editor/lib/brand'

import { t } from 'services/Translation'

export const BRAND_THEME_LABELS: Record<ThemeKeys, string> = {
  primaryColor: t('entity.brand.theme.primaryColor'),
  secondaryColor: t('entity.brand.theme.secondaryColor'),
  focusedColor: t('entity.brand.theme.focusedColor'),
  inactiveColor: t('entity.brand.theme.inactiveColor'),
  neutralColor: t('entity.brand.theme.neutralColor'),
  borderColor: t('entity.brand.theme.borderColor'),
  primaryTextColor: t('entity.brand.theme.primaryTextColor'),
  secondaryTextColor: t('entity.brand.theme.secondaryTextColor'),
  lightTextColor: t('entity.brand.theme.lightTextColor'),
  accentTextColor: t('entity.brand.theme.accentTextColor'),
  primaryBackgroundColor: t('entity.brand.theme.primaryBackgroundColor'),
  darkBackgroundColor: t('entity.brand.theme.darkBackgroundColor'),
  lightBackgroundColor: t('entity.brand.theme.lightBackgroundColor'),
  accentBackgroundColor: t('entity.brand.theme.accentBackgroundColor'),
  infoColor: t('entity.brand.theme.infoColor'),
  successColor: t('entity.brand.theme.successColor'),
  errorColor: t('entity.brand.theme.errorColor'),
  hoverColor: t('entity.brand.theme.hoverColor'),
  warningColor: t('entity.brand.theme.warningColor'),

  borderRadius: t('entity.brand.theme.borderRadius'),
  borderStyle: t('entity.brand.theme.borderStyle'),
  logoHeader: t('entity.brand.theme.logoHeader'),
}

export const FONTS_FULL_SET_LABELS = {
  base: t('entity.font.base'),
  h1: t('entity.font.h1'),
  h2: t('entity.font.h2'),
  h3: t('entity.font.h3'),
  h4: t('entity.font.h4'),
  caption: t('entity.font.caption'),
  list: t('entity.font.list'),
  quote: t('entity.font.quote'),
  link: t('entity.font.link'),
}
