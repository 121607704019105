import { ElementsTypes, TimePoint } from '@leenda/editor/lib/elements'
import { useBoolean } from 'ahooks'
import { useMemo, useState } from 'react'

import Button from 'components/uiKit/Button'
import { t } from 'services/Translation'

import PointForm from '../PointForm'
import PointItem from '../PointItem/PointItem'
import s from './TimeLine.module.scss'

const timePointComparator = (a: TimePoint, b: TimePoint) => a.time - b.time

interface IProps {
  duration: number | null
  timePoints?: TimePoint[]
  onChange: (timePoints: TimePoint[]) => void
  type: ElementsTypes
}

const TimeLine: React.FC<IProps> = ({ timePoints, onChange, duration, type }) => {
  const [isForm, { setTrue: showForm, setFalse: hideForm }] = useBoolean(false)
  const [editingPoint, setEditingPoint] = useState<number | null>(null)
  const sortedPoints = useMemo(
    () => timePoints?.slice().sort(timePointComparator) || [],
    [timePoints],
  )

  const initialValues = useMemo(
    () => (typeof editingPoint === 'number' ? timePoints?.[editingPoint] : undefined),
    [timePoints, editingPoint],
  )

  const updatePoints = ({ point, index }: { point?: TimePoint; index?: number | null }) => {
    if (point && index !== null) {
      const value = [...sortedPoints.filter((_, i) => i !== index), point].sort(timePointComparator)
      onChange(value)
    } else if (point) {
      const value = [...sortedPoints, point].sort(timePointComparator)
      onChange(value)
    }
  }

  const handleOnSubmit = (point: TimePoint) => {
    updatePoints({ point, index: editingPoint })
    setEditingPoint(null)
    hideForm()
  }

  const handleOnReset = () => {
    setEditingPoint(null)
    hideForm()
  }

  const handleEditPoint = (index: number) => {
    setEditingPoint(index)
    showForm()
  }

  const handleDeletePoint = (index: number) => {
    const value = sortedPoints.filter((_, i) => i !== index)
    onChange(value)
  }

  return (
    <div className={s.root}>
      {isForm ? (
        <PointForm
          currentPoint={editingPoint}
          duration={duration}
          initialValues={initialValues}
          onReset={handleOnReset}
          onSubmit={handleOnSubmit}
          timePoints={sortedPoints}
          type={type}
        />
      ) : (
        <div className={s.timeLine}>
          {sortedPoints?.map(({ time, title }, i) => (
            <PointItem
              index={i}
              key={time}
              onDelete={handleDeletePoint}
              onEdit={handleEditPoint}
              time={time}
              title={title}
            />
          ))}
        </div>
      )}
      {!isForm && (
        <Button name='addPoint' onClick={showForm} styleType='secondary' fluid>
          {t('uiKit.button.add')}
        </Button>
      )}
    </div>
  )
}

export default TimeLine
