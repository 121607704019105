export const getPercents = (value: number, total?: number | string | null): number => {
  if (!total) {
    return 0
  }

  const percents = (value / Number(total)) * 100
  return percents > 100 ? 100 : percents
}

export const getPercentsLabel = (current: number, total: number) =>
  `${current || '0'}/${total || '-'}`
