"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const constants_1 = require("../../../elements/elements/Divider/constants");
const font_1 = require("../../font");
const theme_1 = require("../../theme");
const genStyle_1 = require("../genStyle");
const styleSchema = {
    line: {
        borderColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.borderColor }),
        borderWidth: (0, genStyle_1.genStyle)({ value: 1 }),
    },
    double: {
        marginTop: (0, genStyle_1.genStyle)({ value: 4 }),
    },
    numbered: {
        backgroundColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.primaryBackgroundColor }),
        borderStyle: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.borderStyle }),
        borderColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.borderColor }),
        borderWidth: (0, genStyle_1.genStyle)({ value: 1 }),
        borderRadius: (0, genStyle_1.genStyle)({ value: 50 }),
        justifyContent: (0, genStyle_1.genStyle)({ value: 'center' }),
        height: (0, genStyle_1.genStyle)({ value: 50 }),
        width: (0, genStyle_1.genStyle)({ value: 50 }),
    },
};
const fontSchema = {
    base: Object.assign({}, font_1.FONT_SCHEMA_EMPTY.base),
};
exports.default = { [constants_1.TYPE]: { style: styleSchema, font: fontSchema } };
