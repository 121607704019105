"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_VALUE = exports.createItem = exports.TYPE = void 0;
const translation_1 = require("../../../translation");
const id_1 = require("../../../utils/id");
const rich_text_1 = require("@leenda/rich-text");
exports.TYPE = 'accordion';
const createItem = () => ({
    value: (0, id_1.generateId)(),
    label: (0, rich_text_1.textToRtValue)((0, translation_1.t)('elements.accordion.item'), rich_text_1.SlateElementType.heading4),
});
exports.createItem = createItem;
exports.DEFAULT_VALUE = {
    items: [(0, exports.createItem)()],
    showFirstTab: false,
    multipleExpand: true,
};
