import { BasePlugin } from '@uppy/core'
import heic2any from 'heic2any'
import { TOptions } from 'i18next'

import { t } from 'services/Translation'
import { UppyType } from 'store/models'

type ToGifOptions = TOptions & { toType?: string; gifInterval?: number }

const EXTENSIONS_FILE_TO_PREPARED = ['heic', 'heif']

class UppyHeicFix extends BasePlugin {
  opts: ToGifOptions

  constructor(uppy: UppyType, opts?: ToGifOptions) {
    super(uppy, opts)
    this.id = 'UppyHeicFix'
    this.type = 'modifier'

    const defaultOptions = {
      toType: 'image/jpeg',
    }

    this.opts = Object.assign({}, defaultOptions, opts)
    this.prepareUpload = this.prepareUpload.bind(this)
  }

  setOptions(newOpts: ToGifOptions) {
    super.setOptions(newOpts)
  }

  prepareUpload(fileIDs: string[]) {
    const promises = fileIDs.map((fileID) => {
      const file = this.uppy.getFile(fileID)
      const foundedExt = EXTENSIONS_FILE_TO_PREPARED.find(
        (ext) => file.extension.toLowerCase() === ext,
      )

      if (foundedExt) {
        const extUpperCase = foundedExt.toUpperCase()
        this.uppy.emit('preprocess-progress', file, {
          mode: 'indeterminate',
          message: t('notify.convertHeic', { ext: extUpperCase }),
        })

        return heic2any({
          blob: file.data,
          toType: this.opts.toType,
          gifInterval: this.opts.gifInterval,
        })
          .then((blob) => {
            this.uppy.log(`[Image Compressor] Image ${file.id}`)
            this.uppy.setFileState(fileID, {
              data: blob,
              meta: {
                ...file.meta,
                name: file.meta.name.replace(`.${extUpperCase}`, '.png'),
                type: 'image/jpeg',
              },
            })
          })
          .catch((err) => {
            console.error(err, 'prepareUploadFile')
            this.uppy.log(`[Image Compressor] Failed to compress ${file.id}:`, 'warning')
            this.uppy.log(err, 'warning')
          })
      }

      return undefined
    })

    const emitPreprocessCompleteForAll = () => {
      fileIDs.forEach((fileID) => {
        const file = this.uppy.getFile(fileID)
        this.uppy.emit('preprocess-complete', file)
      })
    }

    // Why emit `preprocess-complete` for all files at once, instead of
    // above when each is processed?
    // Because it leads to StatusBar showing a weird “upload 6 files” button,
    // while waiting for all the files to complete pre-processing.
    return Promise.all(promises).then(emitPreprocessCompleteForAll)
  }

  install() {
    this.uppy.addPreProcessor(this.prepareUpload)
  }

  uninstall() {
    this.uppy.removePreProcessor(this.prepareUpload)
  }
}

export { UppyHeicFix }
