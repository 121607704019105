import { videoFormat } from 'utils'

import { seekTo } from 'components/MediaPlayer/store/actions'
import { Actions } from 'components/MediaPlayer/store/types'
import { testProps } from 'utils/test/qaData'

import s from '../TimePoints.module.scss'

interface IPlaybackRateProps {
  dispatch: React.Dispatch<Actions>
  time: number
  title: string
}

const Item: React.FC<IPlaybackRateProps> = ({ dispatch, time, title }) => {
  const handleTimePoint = () => dispatch(seekTo(time))

  return (
    <div className={s.item} onClick={handleTimePoint}>
      <div className={s.time} {...testProps({ el: 'timeItem', value: videoFormat(time) })}>
        {videoFormat(time)}
      </div>
      <div className={s.title} {...testProps({ el: 'titleItem', value: title })}>
        {title}
      </div>
    </div>
  )
}

export default Item
