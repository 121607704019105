import React from 'react'

import { IconButton } from 'components/uiKit/Button'
import { SKELETON_MODE } from 'services/Store/Project/constants'

import CourseModal from '../../components/CourseModal'
import ScormDebug from './ScormDebug'

const ScormDebugModal = () => {
  const [open, setOpen] = React.useState(false)

  return (
    <>
      <IconButton
        icon='mainMenuSettings'
        name='debug'
        onClick={() => setOpen(true)}
        styleType='ghost'
      />
      <CourseModal
        component={ScormDebug}
        mode={SKELETON_MODE}
        onClose={() => setOpen(false)}
        visible={open}
      />
    </>
  )
}

export default ScormDebugModal
