import { SlateElementType } from '@leenda/rich-text'
import React, { useCallback, useRef, useState } from 'react'

import {
  getSelectedFormat,
  getSetToolbarForm,
  getUpdateFormat,
  useRichTextContext,
} from 'components/form/RichText/useRichTextContext'
import { IconButton } from 'components/uiKit/Button'
import { KitSize } from 'components/uiKit/KitTypes'
import Select from 'components/uiKit/Select/Select'
import { SectionTypeEnum } from 'services/Store/Project/enums'
import { useProjectContext } from 'services/Store/Project/hooks'
import { getBlock, getSections } from 'services/Store/Project/selectors'
import { Section } from 'services/Store/Project/types'
import { t } from 'services/Translation'

import { useToolbarPosition } from '../../useToolbarPosition'
import s from './CrossLinkForm.module.scss'

interface IValue {
  section?: string
  block?: string
}

const sectionsToOptions = (sections: Section[]) => {
  return sections
    .filter((section: Section) => section.type !== SectionTypeEnum.chapter)
    .map((section: Section) => ({
      label: section.name,
      value: section.id,
    }))
}

const CrossLinkForm: React.FC = () => {
  const ref = useRef<HTMLDivElement>(null)
  const selectedFormat = useRichTextContext(getSelectedFormat)
  const [value, setValue] = useState<IValue | undefined>(selectedFormat?.inline?.value)
  const onUpdateFormat = useRichTextContext(getUpdateFormat)
  const setToolbarForm = useRichTextContext(getSetToolbarForm)

  const sections = useProjectContext(getSections)
  const currentBlock = useProjectContext(getBlock)
  const section = sections.find((section) => section.id === value?.section)
  const isLanding = section?.type === SectionTypeEnum.landing

  const isEditing = Boolean(selectedFormat?.inline?.value)

  const blockOptions = section?.blocksOrder.map((value, i) => ({
    value,
    label: `${i + 1} ${t('elements.button.form.blockCount')}`,
    disabled: value === currentBlock?.uuid,
  }))

  const handleClose = useCallback(() => setToolbarForm('default'), [setToolbarForm])

  const onSave = () => {
    onUpdateFormat(SlateElementType.crossLink, value)
    handleClose()
  }

  const onDelete = () => {
    onUpdateFormat(SlateElementType.crossLink, null)
    handleClose()
  }

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Escape') {
      e.preventDefault()
      handleClose()
    }
  }

  const onSectionChange = (section: string) => {
    setValue({ section })
  }

  const onBlockChange = (block: string) => {
    setValue((prev) => ({ ...prev, block }))
  }

  useToolbarPosition(ref)

  return (
    <div className={s.root} onKeyDown={onKeyDown} ref={ref}>
      <Select
        minWidth={360}
        name='rtCrossLinkSection'
        onChange={onSectionChange}
        options={sectionsToOptions(sections)}
        placeholder={t('uiKit.tooltip.sectionName')}
        size={KitSize.S}
        styleType='ghost'
        value={value?.section}
        isSearch
        resettable
      />
      <Select
        disabled={!!(value?.section && !isLanding)}
        minWidth={88}
        name='rtCrossLinkBlock'
        onChange={onBlockChange}
        options={isLanding ? blockOptions : undefined}
        placeholder={t('uiKit.tooltip.block')}
        size={KitSize.S}
        styleType='ghost'
        value={value?.block}
        isSearch
        resettable
      />
      {isEditing && (
        <IconButton
          icon='otherBrokenLink'
          name='delete'
          onClick={onDelete}
          size={KitSize.S}
          styleType='ghost'
        />
      )}
      {value?.section && (
        <IconButton
          icon='checkmark1'
          name='create'
          onClick={onSave}
          size={KitSize.S}
          styleType='ghost'
        />
      )}
      {!value?.section && (
        <IconButton
          icon='otherClose'
          name='cancel'
          onClick={handleClose}
          size={KitSize.S}
          styleType='ghost'
        />
      )}
    </div>
  )
}

export default CrossLinkForm
