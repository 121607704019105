import { gql } from './__generated__'

export const testFragment = gql(`
  fragment TestSchema on EditorSectionTest {
    time
    testLimit
    testRetryCount
    testThreshold
    isNextOnSubmit
    isValidationVisible
    isResultValuePercent
    questionOrder
    isShuffleQuestions
    isProgressShown
    randomCount
    isUnableToExit
  }
`)

export const sectionFragment = gql(`
  fragment SectionSchema on EditorSection {
    id
    name
    description
    type
    blocksOrder
    deadline
    blocks {
      uuid
      mode
      isHide
      name
      mode
    }
    test {
      ...TestSchema
    }
    lvl
    isChapter
    updatedAt
    publicLink {
      ...PublicLinkSchema
    }
    isDone
    isHide
    tags
  }
`)

export const sectionWithBlocksDeepFragment = gql(`
  fragment SectionWithBlocksSchema on EditorSection {
    ...SectionSchema
    blocks {
      ...BlockDeepSchema
    }
  }
`)

export const sectionOrderFragment = gql(`
  fragment SectionOrderSchema on EditorSectionOrderOptionalType {
    id
    lvl
  }
`)

export const sectionEditorFragment = gql(`
  fragment SectionEditorSchema on EditorSection {
    ...SectionSchema
    isDone
    isHide
    projectId
    deadline
    totalCost
    formats {
      id
      cost
      unitType
      count
      formatId
    }
    tags
    isArchived
    createdAt
    publicLink {
      ...PublicLinkSchema
    }
  }
`)

/**
 * We cannot export GQL from .apollo files
 * not to litter the scope with global queries in gqlActions
 */
export const sectionsAllQuery = gql(`
  query SectionsAll($projectId: String!) {
    data: editorSectionsAll(projectId: $projectId) {
      ...SectionEditorSchema
    }
  }
`)

export const sectionsGetById = gql(`
  query SectionsGetById($id: String!, $projectId: String!) {
    data: editorSectionsGetById(id: $id, projectId: $projectId) {
      ...SectionEditorSchema
    }
  }
`)

export const sectionCreate = gql(`
  mutation SectionsCreate($projectId: String!, $data: EditorSectionInput!) {
    data: editorSectionsCreate(projectId: $projectId, data: $data) {
      sectionsOrder {
        ...SectionOrderSchema
      }
      section {
        ...SectionEditorSchema
      }
    }
  }
`)

export const blockOrderUpdate = gql(`
  mutation BlockOrderUpdate($id: String!, $data: EditorSectionUpdateInput!, $projectId: String!) {
    data: editorSectionsUpdateById(id: $id, data: $data, projectId: $projectId) {
      section {
          id
          blocksOrder
      }
    }
  }
`)

export const sectionUpdate = gql(`
  mutation SectionsUpdateById(
    $id: String!
    $data: EditorSectionUpdateInput!
    $projectId: String!
    $dragId: String
  ) {
    data: editorSectionsUpdateById(id: $id, data: $data, projectId: $projectId, dragId: $dragId) {
      sectionsOrder {
        ...SectionOrderSchema
      }
      section {
        ...SectionEditorSchema
      }
    }
  }
`)

export const sectionsUpdateByIds = gql(`
  mutation SectionsUpdateByIds(
    $ids: [ID!]!
    $data: EditorSectionUpdateManyInput!
    $projectId: String!
  ) {
    data: editorSectionsUpdateByIds(ids: $ids, data: $data, projectId: $projectId) {
      ...SectionEditorSchema
    }
  }
`)

export const sectionUpdateHide = gql(`
  mutation SectionsUpdateHideById($id: String!, $isHide: Boolean!) {
    editorSectionUpdateHide(id: $id, isHide: $isHide) {
      data {
        ...SectionEditorSchema
      }
    }
  }
`)

export const sectionsDeleteByIds = gql(`
  mutation SectionsDeleteByIds($ids: [String!]!, $projectId: String!) {
    data: editorSectionsDeleteByIds(ids: $ids, projectId: $projectId) {
      sectionIdsDeleted
      sectionsOrder {
        ...SectionOrderSchema
      }
    }
  }
`)

export const sectionDuplicate = gql(`
  mutation SectionsDuplicate($companyId: ID!, $ids: [ID!]!) {
    data: editorSectionsDuplicate(companyId: $companyId, ids: $ids) {
      data {
        section {
          ...SectionEditorSchema
        }
        sectionsOrder {
          ...SectionOrderSchema
        }
      }
    }
  }
`)

export const sectionsTransfer = gql(`
  mutation SectionsTransfer($projectId: ID!, $payload: SectionsTransferPayload!) {
    data: editorSectionsTransfer(projectId: $projectId, payload: $payload) {
      data {
        sectionIds
      }
      errors {
        ...ErrorSchema
      }
    }
  }
`)
