import { LabeledGraphicSchemaType, LabeledGraphicFontSchemaType } from '@leenda/editor/lib/brand'
import { EditorElement, LabeledGraphicElementValue } from '@leenda/editor/lib/elements'
import { createContext, useContextSelector } from 'use-context-selector'

import { NOOP } from 'constants/commonConstans'
import { ElementStyleCss } from 'services/Branding/types'
import { PREVIEW_MODE } from 'services/Store/Project/constants'
import { Block, IBlockMode } from 'services/Store/Project/types'

import { LabeledGraphicState } from './LabeledGraphic.types'

interface ILabeledGraphicProps {
  block?: Block | null
  element: EditorElement<
    LabeledGraphicElementValue,
    LabeledGraphicSchemaType,
    LabeledGraphicFontSchemaType
  >
  imgRef: React.RefObject<HTMLImageElement> | null
  mode: IBlockMode
  onChange?: (value: LabeledGraphicElementValue) => void
  setState: ((value: LabeledGraphicState) => void) | undefined
  styles: ElementStyleCss<LabeledGraphicSchemaType>
  waiting?: boolean
  activePoint?: string | null
  setActivePoint: (i: string | null) => void
}

const LabeledGraphicContext = createContext<ILabeledGraphicProps>({
  block: null,
  element: {} as EditorElement<
    LabeledGraphicElementValue,
    LabeledGraphicSchemaType,
    LabeledGraphicFontSchemaType
  >,
  imgRef: null,
  mode: PREVIEW_MODE,
  onChange: NOOP,
  setState: NOOP,
  styles: {} as ElementStyleCss<LabeledGraphicSchemaType>,
  waiting: false,
  activePoint: null,
  setActivePoint: NOOP,
})

export const useLabeledGraphicImgRef = () =>
  useContextSelector(LabeledGraphicContext, (c) => c.imgRef)
export const useLabeledGraphicContext = <R>(selector: (c: ILabeledGraphicProps) => R) =>
  useContextSelector(LabeledGraphicContext, selector)

export default LabeledGraphicContext
