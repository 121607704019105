import { Draft } from 'immer'
import * as jsondiffpatch from 'jsondiffpatch'
import lodash from 'lodash'
import * as R from 'ramda'

import { isShellNode } from 'components/editor-v3/types/data.guards'
import { EMPTY_ARRAY } from 'constants/commonConstans'
import { EditorBlockPatchSubscriptionSubscription } from 'gql/__generated__/graphql'
import { DeviceMode, AppMode, SectionTypeEnum } from 'services/Store/Project/enums'
import {
  Block,
  IEditorSettings,
  INavigationState,
  IProjectContext,
  IProjectTasksFilterValues,
  IToolbarState,
  Patch,
} from 'services/Store/Project/types'
import { IProjectCommentsFilterValues } from 'services/Store/Project/types'
import { notEmpty } from 'utils/notEmpty'

import { navigationLens, settingsLens, stateLens, toolbarLens } from '../lenses'
import {
  IProjectNavigationPayload,
  IResetBlockPayload,
  IUpdateBlockPayload,
  IUpdateBlockVersionPayload,
  IWaitBlockPayload,
} from '../reducer'
import { getBlock, getBlockId, getSection } from '../selectors'

export const setDeviceMode = (mode: DeviceMode) => (state: IProjectContext) =>
  R.assocPath(['state', 'deviceMode'], mode, state)

export const setCommentsFilter =
  (filter: IProjectCommentsFilterValues) => (state: IProjectContext) =>
    R.assocPath(['state', 'comments', 'filter'], filter, state)

export const setTasksFilter = (filter: IProjectTasksFilterValues) => (state: IProjectContext) =>
  R.assocPath(['state', 'tasksFilter'], filter, state)

export const setAppMode = (mode: AppMode) =>
  R.over(stateLens, (old) => R.mergeDeepRight(old, { mode }))

export const setNavigation = (navigation: Partial<INavigationState & { init?: boolean }>) =>
  R.over(navigationLens, (old) => {
    const { init, ...data } = navigation
    return init ? { ...data, ...R.pickBy(R.isNotNil, old) } : { ...old, ...data }
  })

export const setToolbar = (toolbar: Partial<IToolbarState>) =>
  R.over(toolbarLens, (old) => ({ ...old, ...toolbar }))

export const setSettings = (settings: Partial<IEditorSettings>) =>
  R.over(settingsLens, (old) => ({ ...old, ...settings }))

export const setHighlight = (highlight: string | null) => (state: IProjectContext) =>
  R.assocPath(['state', 'editor', 'highlight'], highlight, state)

export const setProjectNavigation =
  (payload: IProjectNavigationPayload) => (state: IProjectContext) => {
    const isCommenting = state.state.mode === AppMode.comment
    const section = getSection(state, { id: payload.sectionId }) || getSection(state)
    const isLanding = section?.type === SectionTypeEnum.landing
    const nodeId = calcNodeId(state, payload)
    const updateNavigation = (selectedBlocks: string[], newParams: IProjectNavigationPayload) =>
      R.compose<[IProjectContext], IProjectContext, IProjectContext>(
        R.assocPath(
          ['state', 'editor', 'selectedBlocks'],
          isCommenting && isLanding ? [] : selectedBlocks,
        ),
        R.assocPath(
          ['urlParams'],
          isCommenting && isLanding
            ? R.pickBy((v, key) => v !== undefined && key !== 'blockId' && key !== 'nodeId', {
                ...state.urlParams,
                ...newParams,
                nodeId,
              })
            : R.pickBy((v) => v !== undefined, { ...state.urlParams, ...newParams, nodeId }),
        ),
      )(state)

    if (payload.ctrl) {
      const selectedBlocks = state.state.editor.selectedBlocks
      const isSelected = payload.blockId && selectedBlocks.includes(payload.blockId)
      const newSelectedBlocks =
        isSelected && selectedBlocks.length > 1
          ? selectedBlocks.filter((blockId) => blockId !== payload.blockId)
          : payload.blockId
            ? R.uniq([...selectedBlocks, payload.blockId])
            : selectedBlocks
      const blockId = isSelected ? R.last(state.state.editor.selectedBlocks) : payload.blockId
      return updateNavigation(newSelectedBlocks, { ...payload, blockId })
    }

    if (payload.shift) {
      const selectedBlocks = state.state.editor.selectedBlocks
      if (payload.blockId && !selectedBlocks.length) {
        return updateNavigation([payload.blockId], payload)
      }
      const section = getSection(state)
      const openedBlockId = getBlockId(state)
      const blocksMap = state.data.blocks
      const blocks = (section?.blocksOrder || []).map((id) => blocksMap[id]).filter(notEmpty)

      const firstIndex = blocks.findIndex((block) => openedBlockId === block.uuid)
      const lastIndex = blocks.findIndex((block) => payload.blockId === block.uuid)

      const [from, to] = [firstIndex, lastIndex].sort((a, b) => a - b)

      const blockIds = blocks
        .slice(from, to + 1)
        .filter((block) => !block.isDone)
        .map((block) => block.uuid)

      return updateNavigation(blockIds, payload)
    }

    if (payload.blockIds?.length) {
      return updateNavigation(payload.blockIds, payload)
    }

    if (payload.blockId) {
      return updateNavigation([payload.blockId], payload)
    }

    if (payload.blockId === null) {
      return updateNavigation(EMPTY_ARRAY, payload)
    }

    return updateNavigation(state.state.editor.selectedBlocks, payload)
  }

export const updateBlock = (state: IProjectContext, payload: IUpdateBlockPayload) => {
  if (state.state.blocks[payload.id]?.waiting) {
    return state
  }

  const { id, name, value } = payload
  const nodeId = state.urlParams.nodeId
  const block = state.data.blocks[id]
  const nameArr = lodash.toPath(name).map((item) => (/^\d+$/.test(item) ? parseInt(item) : item))
  const newBlock = name ? R.assocPath(nameArr, value, block) : (value as Block)
  const existNodeId = newBlock?.schema.nodes[nodeId || ''] ? nodeId : null

  return R.compose<[IProjectContext], IProjectContext, IProjectContext, IProjectContext>(
    R.assocPath(['state', 'editor', 'redoPatches'], EMPTY_ARRAY),
    R.assocPath(['data', 'blocks', id], newBlock),
    R.assocPath(['urlParams', 'nodeId'], existNodeId),
  )(state)
}

export const waitBlock = (state: IProjectContext, payload: IWaitBlockPayload) => {
  state.state.blocks[payload.id] = {
    ...state.state.blocks[payload.id],
    waiting: payload.wait,
  }
  if (!payload.wait) {
    state.state.editor.remotePatches
      .sort((a, b) => a.version - b.version)
      .forEach((patch) => {
        remoteBlockPatch(state, patch)
      })
    state.state.editor.remotePatches = []
  }
}

export const updateBlockVersion = (
  state: Draft<IProjectContext>,
  payload: IUpdateBlockVersionPayload,
) => {
  state.data.blocks[payload.blockId].version = payload.version
}

export const resetBlock = (state: Draft<IProjectContext>, payload: IResetBlockPayload) => {
  state.state.editor.redoPatches = []
  state.state.editor.undoPatches = []
  state.data.blocks[payload.id] = payload.block
}

export const remoteBlockPatch = (
  state: Draft<IProjectContext>,
  payload: EditorBlockPatchSubscriptionSubscription['data'],
): void => {
  if (state.state.blocks[payload.uuid]?.waiting) {
    state.state.editor.remotePatches.push(payload)
  } else {
    const { uuid, patch, version } = payload
    if (state.data.blocks[uuid].version! < version) {
      jsondiffpatch.patch(state.data.blocks[uuid], JSON.parse(patch))
      state.data.blocks[uuid].version = version
    }
  }
}

export const addUndoPatch = (state: Draft<IProjectContext>, payload: Patch): void => {
  state.state.editor.undoPatches.push(payload)
}

export const undoBlock = (state: Draft<IProjectContext>): void => {
  const lastPatch = R.last(state.state.editor.undoPatches)
  if (lastPatch && !state.state.blocks[lastPatch?.blockId]?.waiting) {
    state.state.editor.undoPatches.pop()
    const undoPatch = lastPatch && jsondiffpatch.reverse(lastPatch.patch)
    if (undoPatch) {
      jsondiffpatch.patch(state.data.blocks[lastPatch.blockId], undoPatch)
      state.state.editor.redoPatches.push(lastPatch)
    }
  }
}

export const redoBlock = (state: Draft<IProjectContext>): void => {
  const lastPatch = R.last(state.state.editor.redoPatches)
  if (lastPatch && !state.state.blocks[lastPatch?.blockId]?.waiting) {
    state.state.editor.redoPatches.pop()
    const redoPatch = lastPatch && lastPatch.patch
    if (redoPatch) {
      jsondiffpatch.patch(state.data.blocks[lastPatch.blockId], redoPatch)
      state.state.editor.undoPatches.push(lastPatch)
    }
  }
}

const calcNodeId = (state: IProjectContext, payload: IProjectNavigationPayload) => {
  const blockId = payload.blockId
  const nodeId = payload.nodeId !== undefined ? payload.nodeId : state.urlParams.nodeId
  const block = getBlock(state, { id: blockId }) || getBlock(state)
  const isFill = state.state.mode !== AppMode.pro
  const isPro = state.state.mode === AppMode.pro
  const isNode = nodeId && block?.schema.nodes[nodeId]
  const isShell = nodeId && block?.schema.nodes[nodeId] && isShellNode(block.schema.nodes[nodeId])
  return isNode && (isPro || (isFill && isShell)) ? nodeId : null
}
