import { TableElementValue } from '@leenda/editor/lib/elements'
import cn from 'classnames'
import React, { CSSProperties, memo } from 'react'
import { useDragLayer } from 'react-dnd'

import { useTableContext } from '../../TableContext'
import { DndControlsState } from '../../TableElement.types'
import Row from '../Row'
import s from '../Table/Table.module.scss'

const layerStyles: CSSProperties = {
  position: 'absolute',
  pointerEvents: 'none',
  zIndex: 100,
  left: 0,
  top: 0,
}

const getItemStyles = (
  currentOffset: { x: number; y: number } | null,
  axis: 'row' | 'column',
  width: number,
  height: number,
) => {
  if (!currentOffset) {
    return {
      display: 'none',
    }
  }
  const x = currentOffset.x
  const y = currentOffset.y

  const styles: CSSProperties = {
    position: 'fixed',
  }

  if (axis === 'row') {
    styles.top = y - height / 2 + 8
    styles.left = x
  } else {
    styles.top = y
    styles.left = x - width / 2 + 8
  }

  return styles
}

const RenderPreview = ({
  dragState,
  tableValue,
}: {
  dragState: DndControlsState
  tableValue: TableElementValue
}) => {
  if (dragState.dragType === 'row' && dragState.dragIndex !== null) {
    return <Row index={dragState.dragIndex} isDragging />
  }

  if (dragState.dragType === 'column' && dragState.dragIndex !== null) {
    return (
      <>
        {tableValue.cells.map((_, i) => (
          <Row colIndex={dragState.dragIndex} index={i} key={i} isDragging />
        ))}
      </>
    )
  }

  return null
}
const TableDragLayer = () => {
  const { dragState, tableValue } = useTableContext()

  const { isDragging, currentOffset, axis, width, height } = useDragLayer((monitor) => ({
    isDragging: monitor.isDragging(),
    currentOffset: monitor.getSourceClientOffset(),
    axis: monitor.getItem()?.axis,
    width: monitor.getItem()?.width,
    height: monitor.getItem()?.height,
    id: monitor.getItem()?.id,
  }))

  if (!isDragging) {
    return null
  }

  return (
    <div style={layerStyles}>
      <div style={getItemStyles(currentOffset, axis, width, height)}>
        <table className={cn(s.table, s.preview)}>
          <tbody>
            <RenderPreview dragState={dragState} tableValue={tableValue} />
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default memo(TableDragLayer)
