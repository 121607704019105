import { PreviewMode } from 'services/Store/Project/enums'
import { prepareLinkUrl } from 'utils/websiteValidation'

import BaseButtonElement from '../BaseButtonElement'
import { ButtonElementType } from '../ButtonElement.types'

const LinkButtonElement: ButtonElementType = ({ element, styles, mode, font }) => {
  const label = element?.value?.label
  const actionData = element?.value?.actionData
  const url = actionData && 'url' in actionData ? actionData?.url : undefined
  const isEditorMode = mode.previewMode === PreviewMode.editor
  const correctUrl = prepareLinkUrl(url || '')

  return (
    <a href={!isEditorMode && url ? correctUrl : undefined} rel='noreferrer' target='_blank'>
      <BaseButtonElement
        action={element.value.action}
        font={font}
        label={label}
        mode={mode}
        styles={styles}
      />
    </a>
  )
}

export default LinkButtonElement
