import React from 'react'

const HideControl = <T extends string, N extends T>({
  children,
  enabled,
  name,
}: {
  children: React.ReactElement | React.ReactElement[]
  enabled: T[]
  name: N | N[]
}) => {
  const names = Array.isArray(name) ? name : [name]
  const show = names.some((n) => enabled.includes(n))
  if (show && children) {
    return children as React.ReactElement
  } else {
    return null
  }
}
export default HideControl
