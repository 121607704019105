import { gql } from 'gql/__generated__'

export const kUserIdentificationFragment = gql(`
  fragment kUserIdentificationSchema on KUser {
    id
    role
    name
    email
    locale
    isActive
    avatar {
      ...AvatarSchema
    }
  }
`)

export const kUserInfoFragment = gql(`
  fragment kUserInfoSchema on KUser {
    id
    name
    isActive
    isArchived
    activeFrom
    createdAt
    updatedAt
    phone
    email
    locale
    role
    preferences
    avatar {
      ...AvatarSchema
    }
  }
`)

export const kUserCompaniesFragment = gql(`
  fragment KUserCompaniesSchema on Company {
    id
    name
    contractType
    employees (filter: { kuserIds: [$userId], isArchived: true }) {
      id
      kuserId
      role
      status
      createdAt
      invite {
        id
      }
    }
  }
`)

export const kUserCoreFragment = gql(`
  fragment kUserCoreSchema on KUser {
    ...kUserInfoSchema
    companiesCount
    canCreateCompany
  }
`)
