import React from 'react'
import { createContext } from 'use-context-selector'

import { NOOP } from 'constants/commonConstans'

import { BoxType } from './types'

interface IAbsolutePortalContextContext {
  viewport?: React.RefObject<HTMLDivElement>
  portalsRef: { current: HTMLDivElement | null }
  portals: { current: Record<string, BoxType | null> }
  setPortals: React.Dispatch<React.SetStateAction<Record<string, BoxType | null>>>
}

const AbsolutePortalContext = createContext<IAbsolutePortalContextContext>({
  portalsRef: { current: null },
  portals: { current: {} },
  setPortals: NOOP,
})

AbsolutePortalContext.Provider.displayName = 'AbsolutePortalContext.Provider'

export default AbsolutePortalContext
