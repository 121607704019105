import { PanelItem } from '@leenda/editor/lib/elements'
import { SetStateAction } from 'react'
import { createContext } from 'use-context-selector'

import { NOOP } from 'constants/commonConstans'
import { PREVIEW_MODE } from 'services/Store/Project/constants'
import { IBlockMode } from 'services/Store/Project/types'

interface ISliderContextProps {
  goToSlide: (slide: number) => void
  nextSlide: () => void
  prevSlide: () => void
  slideIndex: number
  items: PanelItem[]
  mode: IBlockMode
  leftArrowNoLoop: boolean
  rightArrowNoLoop: boolean
  includeSlide?: {
    first: boolean
    last: boolean
  }
  translation: number
  setTranslation: React.Dispatch<SetStateAction<number>>
  hidden: number
  setHidden: React.Dispatch<SetStateAction<number>>
}

const SliderContext = createContext<ISliderContextProps>({
  goToSlide: NOOP,
  nextSlide: NOOP,
  prevSlide: NOOP,
  slideIndex: 0,
  items: [],
  mode: PREVIEW_MODE,
  leftArrowNoLoop: true,
  rightArrowNoLoop: true,
  includeSlide: {
    first: true,
    last: true,
  },
  translation: 0,
  setTranslation: NOOP,
  hidden: 0,
  setHidden: NOOP,
})

export default SliderContext
