import { MenuPositionType } from '@leenda/editor/lib/brand/types'
import { CONFIG_COURSE } from '@leenda/editor/lib/elements'
import React, { useCallback, useEffect } from 'react'

import { Drawer } from 'components/uiKit/Drawer'
import { useElementFontCss, useElementStyleCss } from 'services/Branding/hooks'
import { ScrollContainerEnum } from 'services/Scroll/enums'
import { scroll } from 'services/Store/Project/actions'
import { setSidebar } from 'services/Store/Project/actions'
import { DeviceMode, SectionTypeEnum } from 'services/Store/Project/enums'
import { useProjectContext, useProjectDispatch } from 'services/Store/Project/hooks'
import { getSections, getProject, getSidebar, getSectionId } from 'services/Store/Project/selectors'
import { IBlockMode } from 'services/Store/Project/types'

import DrawerSidebar from './DrawerSidebar'

interface IDrawerSidebarProps {
  mode: IBlockMode
}

const getSidebarWidthMap = (deviceMode: DeviceMode, brandWidth: string | number): string | number =>
  ({
    [DeviceMode.desktop]: brandWidth,
    [DeviceMode.tablet]: brandWidth,
    [DeviceMode.mobile]: '100%',
  })[deviceMode]

const DrawerSidebarContainer: React.FC<IDrawerSidebarProps> = ({ mode }) => {
  const dispatch = useProjectDispatch()
  const sections = useProjectContext(getSections, [
    SectionTypeEnum.chapter,
    SectionTypeEnum.test,
    SectionTypeEnum.landing,
  ])
  const sectionId = useProjectContext(getSectionId)
  const project = useProjectContext(getProject)
  const { open } = useProjectContext(getSidebar)
  const onClose = useCallback(() => dispatch(setSidebar({ open: false })), [dispatch])
  const styles = useElementStyleCss(CONFIG_COURSE.sidebar.type, mode.deviceMode)
  const font = useElementFontCss(CONFIG_COURSE.sidebar.type, mode.deviceMode)
  const sideBarWidth = getSidebarWidthMap(mode.deviceMode, styles.value.fixedWidth)

  useEffect(() => {
    if (open) {
      dispatch(
        scroll({
          container: ScrollContainerEnum.sectionNav,
          id: sectionId,
          intersection: true,
        }),
      )
    }
  }, [sectionId, dispatch, open])

  return (
    <Drawer
      onClose={onClose}
      open={open}
      placement={styles.value.position as MenuPositionType}
      position='absolute'
      showBg={styles.value.showOverlay}
      width={sideBarWidth}
      destroyOnClose
    >
      <DrawerSidebar
        font={font}
        onClose={onClose}
        projectName={project?.name}
        sectionId={sectionId}
        sections={sections}
        styles={styles}
      />
    </Drawer>
  )
}

export default DrawerSidebarContainer
