import { createContext } from 'react'

import { NOOP } from 'constants/commonConstans'

import { PaginationConfig, SortConfig } from './types'

interface ITableContext {
  pagination: PaginationConfig
  sort: SortConfig
  onLoadMore?: () => void
  hasNextPage?: boolean
}

export const TableContext = createContext<ITableContext>({
  pagination: {
    page: 1,
    pageSize: 10,
    setPage: NOOP,
  },
  sort: {
    sortKey: '',
    sortOrder: '',
    setSort: NOOP,
  },
})
