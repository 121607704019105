import { FORMAT_FIELDS, SlateFormats } from 'components/form/RichText/RichTextConstants'
import {
  getUpdateFormat,
  getSelectedFormat,
  useRichTextContext,
} from 'components/form/RichText/useRichTextContext'
import Checkbox from 'components/uiKit/Checkbox'
import { Tooltip } from 'components/uiKit/Dropdown'
import Icon from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'
import { t } from 'services/Translation'

const CheckboxTool: React.FC<{ name: SlateFormats }> = ({ name }) => {
  const onUpdateFormat = useRichTextContext(getUpdateFormat)
  const selectedFormat = useRichTextContext(getSelectedFormat)
  const iconName = FORMAT_FIELDS[name]?.icon
  return (
    <Tooltip overlay={t(`uiKit.tooltip.${name}`)}>
      <Checkbox
        name={name}
        onChange={(value) => onUpdateFormat(name, value)}
        value={selectedFormat[name]}
        hideCheckbox
      >
        {iconName && <Icon name={iconName} size={KitSize.S} />}
      </Checkbox>
    </Tooltip>
  )
}
export default CheckboxTool
