import queryString from 'query-string'
import { generatePath, matchPath } from 'react-router'

import { GetStrictLinkToType, RouteParams } from './routes.types'
import { isOptions, isRouteParams } from './typeGuards'

export const unSafeGenerateLink = <T extends string>(
  current: string,
  to: T,
  params: RouteParams<T>,
  query: Record<string, string>,
) => {
  const currentParams = matchPath(window.location.hash.slice(1), { path: current })
  const pathname = generatePath(to, { ...currentParams?.params, ...params } as any)
  return `${pathname}?${queryString.stringify(query)}`
}

const generateLink: GetStrictLinkToType = (...[route, firstArg, secondArg]) => {
  const params = isRouteParams(firstArg) ? firstArg : {}
  const options = isOptions(firstArg) ? firstArg : secondArg

  try {
    const pathname = generatePath(route, params as any)

    return {
      pathname,
      search: options?.search,
      state: options?.state,
    }
  } catch (error) {
    console.warn(error)
    return {
      pathname: '',
    }
  }
}

export default generateLink
