import { LmsIntegrationType } from 'gql/__generated__/graphql'

export interface ILmsIntegrationCreateValues {
  type: LmsIntegrationType
  name: string
  params: {
    apiKey?: string
  }
}

export enum LmsIntegrationActionUiEnum {
  DELETE = 'DELETE',
  EDIT = 'EDIT',
}
