import { ButtonCustomNavigationData, ButtonNavigationAction } from '@leenda/editor/lib/elements'
import { useCallback } from 'react'

import { useProjectT } from 'services/ProjectLabels'
import { useProjectDispatch, useProjectContext } from 'services/Store/Project/hooks'
import { getNavigationLinkTrigger } from 'services/Store/Project/triggers'

import BaseButtonElement from '../BaseButtonElement'
import { ButtonElementType } from '../ButtonElement.types'

const NavigationButtonElement: ButtonElementType = ({ element, styles, mode, font }) => {
  const pt = useProjectT()
  const label = element?.value?.label || pt('elements.button.common.label')

  const { action, actionData } = element.value as {
    action: ButtonNavigationAction
    actionData: ButtonCustomNavigationData
  }
  const dispatch = useProjectDispatch()
  const trigger = useProjectContext(getNavigationLinkTrigger, {
    type: action,
    sectionId: actionData?.sectionId || '',
    blockId: actionData?.blockId,
  })
  const onClick = useCallback(() => {
    if (trigger.disabled) {
      return
    }
    dispatch(trigger.actionCreator())
  }, [dispatch, trigger])
  return (
    <BaseButtonElement
      action={element.value.action}
      disabled={trigger.disabled}
      font={font}
      label={label}
      mode={mode}
      onClick={onClick}
      styles={styles}
    />
  )
}

export default NavigationButtonElement
