import ReactPlayer from 'react-player'
import screenfull from 'screenfull'

import { MediaReducerMiddleware, Action } from './types'

type ToggleFullscreenMiddleware = (ref: React.RefObject<HTMLDivElement>) => MediaReducerMiddleware

export const fullscreenMiddleware: ToggleFullscreenMiddleware =
  (ref) => () => (next) => (action) => {
    if (action.type === Action.TOGGLE_FULLSCREEN) {
      if (screenfull.isEnabled && ref.current) {
        screenfull.toggle(ref.current)
      }
    }
    return next(action)
  }

type ReactPlayerMiddleware = (player: React.RefObject<ReactPlayer>) => MediaReducerMiddleware

export const reactPlayerMiddleware: ReactPlayerMiddleware =
  (player) => (store) => (next) => (action) => {
    if (action.type === Action.SEEK_TO) {
      const state = store.getState()
      player.current?.seekTo(action.payload + state.start, 'seconds')
    }

    if (action.type === Action.SET_PROGRESS) {
      const state = store.getState()
      const { start, duration } = state
      const { playedSeconds } = action.payload

      if (playedSeconds < start) {
        player.current?.seekTo(start, 'seconds')
      }

      if (playedSeconds >= duration + start) {
        player.current?.seekTo(start, 'seconds')
      }
    }
    return next(action)
  }
