import { useState } from 'react'

import { DeviceMode } from 'services/Store/Project/enums'

import { Properties } from '../../elements.types'
import { ChartElementType } from './ChartTypes'
import { IMeasureProps } from './helper'

const DEFAULT_MARGIN = 8

export const useGetSizes = <T extends ChartElementType>({
  styles,
  font,
  element,
  mode,
}: Pick<Properties<T>, 'styles' | 'element' | 'mode' | 'font'>) => {
  const [yWidths, setYWidth] = useState(Array(8).fill(20))
  const [xHeights, setXHeight] = useState(Array(12).fill(30))
  const onMeasure = ({ index, width, height }: IMeasureProps) => {
    ;(width ? setYWidth : setXHeight)((prev) => {
      const newParams = [...prev]
      newParams[index] = width ? width : height
      return newParams
    })
  }

  const titleFontSize = parseInt(String(font?.caption.fontSize || '0'))
  const axisFontSize = parseInt(String(font?.base.fontSize || '0'))

  const width =
    Math.max(...yWidths) + (element.value.y && element.value.description ? titleFontSize * 2 : 0)
  const height =
    Math.max(...xHeights) +
    (element.value.x && element.value.description
      ? titleFontSize * 1.5 + axisFontSize
      : DEFAULT_MARGIN)

  const isMobile = mode.deviceMode === DeviceMode.mobile
  const rootWidth = isMobile
    ? element.value.items.length * 80
    : Number(styles?.shell?.minWidth) || 400

  return { onMeasure, width, height, rootWidth }
}
