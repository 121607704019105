import * as R from 'ramda'

import { notEmpty } from 'utils/notEmpty'

import { ISelectOption, SelectValueType } from './SelectTypes'

const DATA_SELECT_SCROLL_OPTION = 'data-menu-item-scroll'
export const selectScrollId = (id: string) => (id ? { [DATA_SELECT_SCROLL_OPTION]: id } : {})

const DATA_FOCUS_VISIBLE = 'data-focus-visible'
export const setFocusVisible = (ref: HTMLDivElement | null, value: 'on' | 'off') => {
  ref?.setAttribute(DATA_FOCUS_VISIBLE, value)
}

export const recursiveFilter = <V extends SelectValueType>(
  options: ISelectOption<V>[] = [],
  filter: (option: ISelectOption<V>) => boolean,
): ISelectOption<V>[] =>
  options.reduce<ISelectOption<V>[]>((acc, option) => {
    if (option.options?.length) {
      const filteredOptions = recursiveFilter(option.options, filter)
      if (filteredOptions.length) {
        acc.push({ ...option, options: filteredOptions })
      }
    } else if (filter(option)) {
      acc.push(option)
    }
    return acc
  }, [])

export const filterOption =
  (search: string) =>
  <V extends SelectValueType>(item: ISelectOption<V>) => {
    if (item.searchable) {
      return item.searchable?.toLocaleLowerCase().includes(search?.trim().toLocaleLowerCase())
    }
    if (typeof item.label === 'string') {
      return item.label.toLocaleLowerCase().includes(search?.trim().toLocaleLowerCase())
    }
    return false
  }

export const mapValue = <V extends SelectValueType>(
  map: Record<string, ISelectOption<V>>,
  value?: V,
) =>
  (Array.isArray(value)
    ? value.map((v) => map[String(v)] || { value: v })
    : [map[String(value)]]
  ).filter(notEmpty)

export const isEmptyValue = <V>(v: V) => (Array.isArray(v) ? v.length === 0 : R.isNil(v))

export const splitBy = (value: string, separators: string[]) =>
  value
    .split(new RegExp(`[${separators.join('')}]`))
    .map((item) => item.trim())
    .filter((item) => item !== '')
