import Padding from 'components/editor-v3/Editor/Toolbar/Padding'
import { Typography } from 'components/uiKit/Typography'
import { t } from 'services/Translation'

import { TestMatchingValueFormType } from './TestMatchingElement.types'

const TestMatchingForm: TestMatchingValueFormType = () => (
  <Padding sides>
    <Typography styleType='hint'>{t('elements.testMatching.form.randomHint')}</Typography>
  </Padding>
)
export default TestMatchingForm
