import { PdfBuildSchemaFragment, ScormBuildSchemaFragment } from 'gql/__generated__/graphql'

export enum ExportTypeEnumUi {
  SCORM = 'SCORM',
  PDF = 'PDF',
  HTML = 'HTML',
}

export enum ManifestIdsTypeEnumUi {
  NEW = 'NEW',
  PREVIOUS = 'PREVIOUS',
  CUSTOM = 'CUSTOM',
}

export enum ExportTargetTypeUiEnum {
  DOWNLOAD = 'DOWNLOAD',
  LMS = 'LMS',
}

export type LastBuild = ScormBuildSchemaFragment | PdfBuildSchemaFragment
