import { IMenuOption } from '../../uiKit/Menu'

export enum BlockActionUiEnum {
  CHANGE = 'CHANGE',
  CLONE = 'CLONE',
  DELETE = 'DELETE',
  SAVE_TEMPLATE = 'SAVE_TEMPLATE',
  BLOCK_HISTORY = 'BLOCK_HISTORY',
  COPY_TO_ANOTHER_SECTION = 'COPY_TO_ANOTHER_SECTION',
  ADD_BEFORE = 'ADD_BEFORE',
  ADD_AFTER = 'ADD_AFTER',
  MOVE_UP = 'MOVE_UP',
  MOVE_DOWN = 'MOVE_DOWN',
  GO_NEXT = 'GO_NEXT',
  GO_PREV = 'GO_PREV',
  SETTINGS = 'SETTINGS',
}

export enum PreviewBlockActionUiEnum {
  ADD_COMMENT = 'ADD_COMMENT',
  EDIT = 'EDIT',
}

export enum EditorActionUiEnum {
  TASKS_PANEL = 'TASKS_PANEL',
  COMMENTS_PANEL = 'COMMENTS_PANEL',
  ELEMENT_PANEL = 'ELEMENT_PANEL',
  ELEMENT_FILL_MODE = 'ELEMENT_FILL_MODE',
}

export enum ColumnActionUiEnum {
  ADD_ROW_ABOVE = 'ADD_ROW_ABOVE',
  ADD_ROW_BELOW = 'ADD_ROW_BELOW',
  ADD_COL_PREV = 'ADD_COL_PREV',
  ADD_COL_NEXT = 'ADD_COL_NEXT',
  SPLIT_VERTICAL = 'SPLIT_VERTICAL',
  SPLIT_HORIZONTAL = 'SPLIT_HORIZONTAL',
  ADD_ABSOLUTE = 'ADD_ABSOLUTE',
  ADD_SHELL = 'ADD_SHELL',
  DELETE = 'DELETE',
}

export enum LayoutActionUiEnum {
  DELETE_CELL = 'DELETE_CELL',
}

export type ActionsConfig<T extends string> = {
  availableActions: T[]
  options: { [key in T]: IMenuOption<T> }
  handleAction(data: { value: T }): void
  hasAction(...actions: T[]): boolean
}
