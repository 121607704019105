import { useCallback, useRef, useState } from 'react'
import { ReactEditor } from 'slate-react'

import { removePseudoSelection } from './formatOperations/internal'
import { CustomEditor } from './slate'

export const useFocus = (
  editor: CustomEditor,
  toolbarRef: React.RefObject<HTMLDivElement>,
  onBlur?: (e: React.FocusEvent<HTMLDivElement>) => void,
  onFocus?: (e: React.FocusEvent<HTMLDivElement>) => void,
) => {
  const [focused, setFocused] = useState(false)
  const timeoutRef = useRef<NodeJS.Timeout | null>()
  const handleBlur = useCallback(
    (e: React.FocusEvent<HTMLDivElement>) => {
      const ref = ReactEditor.toDOMNode(editor, editor)
      if (!ref?.contains(e.relatedTarget) && !toolbarRef.current?.contains(e.relatedTarget)) {
        timeoutRef.current = setTimeout(() => {
          removePseudoSelection(editor)
          setFocused(false)
          onBlur?.(e)
        })
      }
    },
    [editor, toolbarRef, onBlur],
  )

  const handleFocus = useCallback(
    (e: React.FocusEvent<HTMLDivElement>) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
        timeoutRef.current = null
      }
      setFocused(true)
      onFocus?.(e)
    },
    [onFocus],
  )

  return [focused, { onBlur: handleBlur, onFocus: handleFocus }] as const
}
