import cn from 'classnames'
import * as R from 'ramda'
import { Dispatch, SetStateAction, useCallback } from 'react'

import Card from 'components/uiKit/Card'
import Checkbox from 'components/uiKit/Checkbox'
import { KitSize } from 'components/uiKit/KitTypes'
import Link from 'components/uiKit/Link'
import { Typography } from 'components/uiKit/Typography'
import generateLink from 'routes/generateLink'
import { usePathParams } from 'routes/hooks'
import { FinderItem } from 'store/models/entry.model'
import { extendTestData, testProps } from 'utils/test/qaData'
import { getTypeFile, isFinderFile, isFinderFolder } from 'utils/useFinder'

import { FinderFileIcons } from '../../FinderFileIcons'
import { Paths } from '../../types'
import { Description } from '../Description'
import s from './GridItem.module.scss'

interface IFinderItem {
  item: FinderItem
  onClick?: (item: FinderItem) => void
  onChangeSelection: Dispatch<SetStateAction<FinderItem[]>>
  selectedItems: FinderItem[]
  clearQuery: () => void
  paths: Paths
}

const GridItem: React.FC<IFinderItem> = ({
  item,
  onClick,
  onChangeSelection,
  selectedItems,
  paths,
  clearQuery,
}) => {
  const typeFile = getTypeFile(item.type)

  const params = usePathParams(paths.main)
  const linkToFolder = generateLink(paths.main, { ...params, fileMetaGroupId: item.id })
  const linkToFile = generateLink(paths.preview, { ...params, fileId: item.id })

  const onSelectItem = useCallback(
    (val: boolean) => {
      onChangeSelection((prev: FinderItem[]) => {
        if (!val) {
          return R.filter<FinderItem, FinderItem[]>(({ id }) => id !== item.id, prev)
        } else {
          return [...prev, item]
        }
      })
    },
    [item, onChangeSelection],
  )

  const selected = !!selectedItems.find(({ id }) => id === item.id)

  return (
    <div
      className={s.item}
      key={item.id}
      onClick={() => onClick?.(item)}
      {...testProps({ el: 'fileItem', label: item.name, id: item.id })}
    >
      <Link
        name={item.id}
        onClick={clearQuery}
        testData={extendTestData({
          id: item.id,
          createdBy: item.createdBy?.id,
        })}
        to={isFinderFile(item) ? linkToFile : linkToFolder}
      >
        <Card name='fileModalCard' selected={selected} size={KitSize.XS} fluid>
          <div className={s.block}>
            <div className={cn(s.blockWrapper, { [s.folder]: isFinderFolder(item) })}>
              <FinderFileIcons item={item} typeFile={typeFile} />
            </div>
          </div>
        </Card>
      </Link>

      <div className={s.title}>
        <div className={s.name}>
          <Checkbox name='resource' onChange={onSelectItem} value={selected} />
          <Link
            name={item.id}
            onClick={clearQuery}
            testData={extendTestData({
              id: item.id,
              createdBy: item.createdBy?.id,
            })}
            to={isFinderFile(item) ? linkToFile : linkToFolder}
          >
            <Typography
              rows={1}
              styleType={isFinderFolder(item) ? 'link' : 'default'}
              tooltip={item.name}
            >
              {item.name}
            </Typography>
          </Link>
        </div>
        <Description item={item} />
      </div>
    </div>
  )
}

export default GridItem
