import { ElementGroup } from 'components/editor-v2/EditorElements/elements.types'
import { IPickerGroup } from 'components/editor-v2/EditorElements/pickerConfig/elementsPicker.types'
import { IElementPickerConfig } from 'components/editor-v2/EditorElements/pickerConfig/elementsPickerConfig'
import { getEnumOption } from 'utils/enum'

export const sortedGroups = ['notUsed', 'cover', 'view', 'test', 'interactive']

export const tagOptions = [
  getEnumOption('ElementGroup', ElementGroup.view),
  getEnumOption('ElementGroup', ElementGroup.interactive),
  getEnumOption('ElementGroup', ElementGroup.test),
]

export const filterElems = (
  groups: IPickerGroup[],
  defItems: IElementPickerConfig[],
  searchWords: string,
  tags?: string[],
): IPickerGroup[] => {
  const allItems: IPickerGroup[] = [
    ...groups,
    ...defItems.map((group) => ({
      ...group,
      items: group.items.map((el) => ({
        ...el,
        icon: <img alt='image' src={String(el.icon)} />,
      })),
    })),
  ]

  const tagElements = allItems.filter((item) => tags?.includes(item.type))
  const elements = tagElements.length ? tagElements : allItems

  const searchElements = elements.map((e) => {
    const items = e.items.filter((el) =>
      searchWords
        .toLowerCase()
        .split(' ')
        .find((k) => el.label.toLowerCase().includes(k)),
    )

    return Object.assign({}, e, { items })
  })

  return searchWords ? searchElements : tags?.length ? tagElements : allItems
}
