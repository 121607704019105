import lodash from 'lodash'

import { getEnumLabel } from 'utils/enum'

import { ELEMENTS_CONFIG } from '../elements.config'
import { IElementConfig, ElementGroup } from '../elements.types'
import { IPickerElement } from './elementsPicker.types'

export interface IElementPickerConfig {
  type: ElementGroup
  name: string
  items: IPickerElement[]
}

export const getElementsPickerConfig = () =>
  // TODO can be const but circular dependency
  Object.entries(lodash.groupBy(ELEMENTS_CONFIG, 'group')).map(([type, items]) => ({
    type: type as ElementGroup,
    items: items as IElementConfig[],
    name: getEnumLabel('ElementGroup', type as ElementGroup),
  }))
