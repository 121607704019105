import { CONFIG_ELEMENTS } from '@leenda/editor/lib/elements'

import EditorActions from 'components/editor-v3/cource/layout/controls/ElementControls/EditableProMode'
import { t } from 'services/Translation'

import { ElementGroup } from '../../elements.types'
import { genElementConfig } from '../../genElementConfig'
import { STYLE_FORM_SCHEMA, FONT_FORM_SCHEMA } from './LabeledGraphicBrandFormSchema'
import Demo from './LabeledGraphicDemo'
import Element from './LabeledGraphicElement'
import ValueForm from './LabeledGraphicForm'
import icon from './assets/labeledGraphic.svg'

export default genElementConfig(CONFIG_ELEMENTS.labeledGraphic, {
  label: t('elements.labeledGraphic.label'),
  icon,
  Element,
  ValueForm,
  EditorActions,
  Demo,
  STYLE_FORM_SCHEMA,
  FONT_FORM_SCHEMA,
  group: ElementGroup.interactive,
})
