import { PreviewMode } from 'services/Store/Project/enums'
import { IProjectContext } from 'services/Store/Project/types'

import { getSection } from '../data/section'
import { getPreviewMode } from './editor'
import { getSectionState } from './section'

export const getSidebar = ({ state }: IProjectContext) => state.sidebar

export const getEndCourseDisabled = ({ state }: IProjectContext) =>
  state.course.status !== 'completed'

export const getCourseState = (state: IProjectContext) => state.state.course

export const isDisabledNavigation = (state: IProjectContext) => {
  const isEditor = getPreviewMode(state) === PreviewMode.editor
  const section = getSection(state)
  const sectionState = getSectionState(state)
  return isEditor || (section?.test?.isUnableToExit && sectionState?.status === 'inProgress')
}
