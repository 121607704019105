import { useDebounce } from 'ahooks'
import * as R from 'ramda'
import { useMemo, useState } from 'react'

import { EMPTY_ARRAY } from 'constants/commonConstans'
import { UnsplashExternalImagesQueryVariables } from 'gql/__generated__/graphql'
import { useUnsplashExternalImages } from 'gql/files.apollo'

const DEFAULT_LIMIT = 30 //TODO: hardcode back
const DEFAULT_OFFSET = 0

export const useUnsplashFiles = () => {
  const [query, setQuery] = useState<string>('')
  const debounceQuery = useDebounce(query, { wait: 500 })
  const [chunkLoading, setChunkLoading] = useState(false)

  const variables: UnsplashExternalImagesQueryVariables = useMemo(
    () => ({
      payload: {
        offset: DEFAULT_OFFSET,
        limit: DEFAULT_LIMIT,
        query: debounceQuery,
      },
    }),
    [debounceQuery],
  )
  const {
    data: unsplashData,
    loading: unsplashDataLoading,
    fetchMore,
    error,
  } = useUnsplashExternalImages(variables)
  const externalImages = unsplashData?.data?.externalImages || EMPTY_ARRAY
  const total = unsplashData?.data.total || 0

  const handleFetchMore = async () => {
    setChunkLoading(true)
    const res = await fetchMore({
      variables: R.mergeDeepRight(variables, {
        payload: { offset: externalImages.length },
      }),
    })
    setChunkLoading(false)
    return res
  }

  return {
    externalImages,
    total,
    loading: unsplashDataLoading,
    chunkLoading,
    fetchMore: handleFetchMore,
    query,
    setQuery,
    error,
  }
}
