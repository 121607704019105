import { CourseStructureItem } from '@leenda/editor/lib/elements'
import cn from 'classnames'
import lodash from 'lodash'

import { ISectionTreeNode } from 'components/sections/ProjectStructure/utils'
import Icon from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'
import Progress, { ProgressType } from 'components/uiKit/Progress'
import { useProjectContext } from 'services/Store/Project/hooks'
import { getProgress } from 'services/Store/Project/selectors'
import { testPropsEl } from 'utils/test/qaData'

import { StructureElementDisplayConfig } from '../../CourseStructureTree'
import s from './SectionStatus.module.scss'

interface ISectionStatusProps {
  node: ISectionTreeNode<CourseStructureItem>
  displayConfig: StructureElementDisplayConfig
  open?: boolean
  disabled: boolean
}

export const SectionStatus: React.FC<ISectionStatusProps> = ({
  node,
  displayConfig,
  open,
  disabled,
}) => {
  const { item, isLast, isFirst } = node
  const progress = useProjectContext(getProgress, item.id)

  const rootCn = cn(s.root, s[displayConfig.progressPosition], {
    [s.isLast]: item.isChapter ? !open && isLast : isLast,
    [s.isFirst]: isFirst,
  })

  const progressStyle = lodash.pick(displayConfig.styles?.progress, [
    'borderStyle',
    'borderColor',
    'borderWidth',
    'backgroundColor',
  ])

  return (
    <div
      {...testPropsEl('sectionStatus', { value: progress, name: item.name }, { id: item.id })}
      className={rootCn}
    >
      {!disabled ? (
        <>
          <div className={s.status} style={progressStyle}>
            <div className={s.progress}>
              {progress >= 100 ? (
                <div
                  className={s.statusDone}
                  style={{ color: displayConfig.styles?.progress.color }}
                >
                  <Icon name='alertCheck' />
                </div>
              ) : progress > 0 ? (
                <Progress
                  customStyles={{ stroke: displayConfig.styles?.icon.color }}
                  name='sectionStatus'
                  percent={progress}
                  size='mini'
                  styleType={ProgressType.circle}
                />
              ) : null}
            </div>
          </div>
          <div
            className={s.line}
            style={{ backgroundColor: displayConfig.styles?.progress.borderColor }}
          />
        </>
      ) : (
        <>
          <Icon className={s.lockIcon} name='builderLock' size={KitSize.S} />
          <div
            className={s.line}
            style={{ backgroundColor: displayConfig.styles?.progress.borderColor }}
          />
        </>
      )}
    </div>
  )
}
