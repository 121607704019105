import cn from 'classnames'
import React, { useCallback, useId } from 'react'

import { testPropsEl } from 'utils/test/qaData'

import s from './Radio.module.scss'

interface IRadioProps {
  disabled?: boolean
  value: string
  checked: boolean
  onChange?: (value: string) => void
  setIsValid?: (value: boolean) => void
  brandStyle?: React.CSSProperties
  testData: string
  isEditor?: boolean
  children: React.ReactNode
}

const Radio: React.FC<IRadioProps> = ({
  disabled,
  value,
  checked,
  onChange,
  setIsValid,
  children,
  brandStyle,
  testData,
  isEditor,
}) => {
  const id = useId()
  const handleOnChange = useCallback(() => onChange?.(value), [value, onChange])
  const handleSetIsValid = useCallback(() => setIsValid?.(!checked), [checked, setIsValid])

  return (
    <label
      className={cn(s.root, { [s.disabled]: disabled, [s.checked]: checked })}
      htmlFor={id}
      style={brandStyle}
      {...testPropsEl('courseRadio', { value }, { checked, testData })}
    >
      <input
        checked={checked}
        disabled={disabled}
        id={isEditor ? undefined : id}
        onChange={handleOnChange}
        type='checkbox'
      />
      <div
        className={s.checkmark}
        onClick={isEditor ? handleSetIsValid : undefined}
        {...testPropsEl('checkmarkRadio', { value }, { checked, testData })}
      />
      <div className={s.label}>{children}</div>
    </label>
  )
}

export default Radio
