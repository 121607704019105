import React from 'react'

import Empty from 'components/uiKit/Empty'
import { KitSize } from 'components/uiKit/KitTypes'
import { AppMode } from 'services/Store/Project/enums'
import { t } from 'services/Translation'

import { TableElementValueFormType } from './TableElement.types'
import s from './TableElementForm.module.scss'

const TableElementForm: TableElementValueFormType = ({ mode }) => {
  const isPro = mode.editorMode === AppMode.pro
  return (
    <div className={s.root}>
      <Empty
        icon='settings'
        size={KitSize.XS}
        text={
          isPro
            ? t('elements.table.form.proMode.emptyDescription')
            : t('elements.table.form.contentMode.emptyDescription')
        }
        grow
      />
    </div>
  )
}

export default React.memo(TableElementForm)
