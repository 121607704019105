import { SidebarFontSchemaType, SidebarSchemaType } from '@leenda/editor/lib/brand'
import { CourseStructureItem } from '@leenda/editor/lib/elements'
import React, { useCallback } from 'react'

import { LayoutScroll } from 'components/LayoutPage'
import CourseStructure from 'components/editor-v3/cource/controls/CourseSidebar/DrawerSidebar/CourseStructure/CourseStructure'
import { StructureSearchResult } from 'components/editor-v3/cource/controls/CourseSidebar/DrawerSidebar/CourseStructure/components/StructureSearch'
import { ElementFontCss, ElementStyleCss } from 'services/Branding/types'
import ScrollService from 'services/Scroll/ScrollService'
import { ScrollContainerEnum } from 'services/Scroll/enums'
import { setSidebarSearch } from 'services/Store/Project/actions'
import { useProjectContext, useProjectDispatch } from 'services/Store/Project/hooks'
import { getSidebar } from 'services/Store/Project/selectors'
import { useNavigationAction } from 'services/Store/Project/triggers'

import { CourseSearch } from '../components/CourseSearch'
import { CourseTitle } from '../components/CourseTitle'
import s from './DrawerSidebar.module.scss'

interface IDrawerSidebarProps {
  onClose: () => void
  sections: CourseStructureItem[]
  projectName?: string
  sectionId: string
  styles: ElementStyleCss<SidebarSchemaType>
  font: ElementFontCss<SidebarFontSchemaType>
}

const DrawerSidebar: React.FC<IDrawerSidebarProps> = ({
  onClose,
  projectName,
  sections,
  sectionId,
  styles,
  font,
}) => {
  const { search: searchValue } = useProjectContext(getSidebar)
  const dispatch = useProjectDispatch()

  const onNavigate = useNavigationAction()

  const onSelectSection = useCallback(
    (sectionId: string) => {
      onNavigate({ sectionId })
      onClose()
    },
    [onClose, onNavigate],
  )

  const onSearchResultSectionPick = useCallback(
    (sectionId: string) => {
      dispatch(setSidebarSearch({ search: '' }))
      onSelectSection(sectionId)
    },
    [dispatch, onSelectSection],
  )

  return (
    <div className={s.root}>
      <CourseTitle projectName={projectName} titleStyles={font.title} />
      <div className={s.searchWrapper}>
        <CourseSearch name='structureSearchInput' fluid />
      </div>
      <LayoutScroll ref={ScrollService.createContainerSetter(ScrollContainerEnum.sectionNav)}>
        <div className={s.content}>
          {searchValue ? (
            <StructureSearchResult
              font={font}
              onSelect={onSearchResultSectionPick}
              searchValue={searchValue}
              sections={sections}
              styles={styles}
            />
          ) : (
            <div className={s.defaultStructure} style={styles.root}>
              <CourseStructure
                font={font}
                onSectionPick={onSelectSection}
                onSelect={onSelectSection}
                sectionId={sectionId}
                sections={sections}
                styles={styles}
                initiallyExpanded
              />
            </div>
          )}
        </div>
      </LayoutScroll>
    </div>
  )
}

export default DrawerSidebar
