"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const constants_1 = require("../../../elements/elements/TestResult/constants");
const font_1 = require("../../font");
const theme_1 = require("../../theme");
const genStyle_1 = require("../genStyle");
const styleSchema = {
    chartCorrectAnswerFill: {
        color: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.primaryColor }),
    },
    chartCorrectAnswerEmpty: {
        color: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.neutralColor }),
    },
    chartFailAnswerFill: {
        color: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.primaryColor }),
    },
    chartFailAnswerEmpty: {
        color: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.neutralColor }),
    },
};
const fontSchema = {
    h2: Object.assign({}, font_1.FONT_SCHEMA_EMPTY.h2),
    h3: Object.assign({}, font_1.FONT_SCHEMA_EMPTY.h3),
    h4: Object.assign({}, font_1.FONT_SCHEMA_EMPTY.h4),
};
exports.default = { [constants_1.TYPE]: { style: styleSchema, font: fontSchema } };
