import { LabeledGraphicFontSchemaType } from '@leenda/editor/lib/brand'
import { LabeledGraphicElementValue, LabeledGraphicPoint } from '@leenda/editor/lib/elements'
import { RichTextValue, rtValueToText } from '@leenda/rich-text'
import cn from 'classnames'
import * as R from 'ramda'

import RichText from 'components/form/RichText/RichText'
import { useGetRichTextProps } from 'components/form/RichText/useGetRichTextProps'
import { ElementFontCss } from 'services/Branding/types'
import { PreviewMode } from 'services/Store/Project/enums'

import { useLabeledGraphicContext } from '../../LabeledGraphicContext'
import s from './PointTooltip.module.scss'

interface IPointContentProps {
  path?: string
  point: LabeledGraphicPoint
  id: string
  value?: LabeledGraphicElementValue
  index: number
  font: ElementFontCss<LabeledGraphicFontSchemaType>
}

const PointContent = ({ path, point, id, value, index, font }: IPointContentProps) => {
  const styles = useLabeledGraphicContext((c) => c.styles)
  const mode = useLabeledGraphicContext((c) => c.mode)
  const onChange = useLabeledGraphicContext((c) => c.onChange)
  const waiting = useLabeledGraphicContext((c) => c.waiting)

  const { isActiveElement, activeIndex, onLabelSelect, isFill } = useGetRichTextProps({
    elementId: id,
    mode,
  })

  const isEditorMode = mode.previewMode === PreviewMode.editor

  const onChangeLabel = (labelValue: RichTextValue, index: number, field: string) => {
    value && onChange?.(R.assocPath(['items', index, field], labelValue, value))
  }

  const descriptionStr = rtValueToText(point.description)
  const descriptionName = `labeledGraphic.${index}.description`

  return (
    <div className={s.inner}>
      <div className={cn(s.content, s[mode.deviceMode])}>
        {path && (
          <img
            alt={point.image?.accessibility}
            aria-label={point.image?.accessibility}
            className={cn(s.image, s[mode.deviceMode])}
            src={path}
            style={{ borderRadius: styles.image.borderRadius }}
          />
        )}
        {(descriptionStr || isEditorMode) && (
          <span onMouseDown={() => onLabelSelect(descriptionName)}>
            <RichText
              active={isActiveElement && activeIndex === descriptionName}
              disabled={!isFill}
              name={descriptionName}
              onChange={(val) => onChangeLabel(val, index, 'description')}
              styles={font}
              value={point.description}
              waiting={waiting}
            />
          </span>
        )}
      </div>
    </div>
  )
}

export default PointContent
