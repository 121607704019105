import { useUnmount } from 'ahooks'
import { leftAiTokens } from 'entities/AiConfig/helpers'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Editor } from 'slate'
import { ReactEditor, useSlate } from 'slate-react'

import { LayoutScroll } from 'components/LayoutPage'
import { RichTextViewer } from 'components/form/RichText/RichTextViewer'
import {
  getPrompt,
  getSetPrompt,
  getSetToolbarForm,
  getStyles,
  useRichTextContext,
} from 'components/form/RichText/useRichTextContext'
import { collapseSelectionToEnd } from 'components/form/RichText/utils/selection'
import Button, { IconButton } from 'components/uiKit/Button'
import { Tooltip } from 'components/uiKit/Dropdown'
import Icon from 'components/uiKit/Icon'
import Input from 'components/uiKit/Input'
import { KitSize } from 'components/uiKit/KitTypes'
import Loader from 'components/uiKit/Loader'
import { PROJECT_PATHS } from 'constants/paths'
import { AiTextPromptTogglesEnum } from 'gql/__generated__/graphql'
import { useMyInfo } from 'gql/actions.helpers'
import { usePathParams } from 'routes/hooks'
import { t } from 'services/Translation'
import { getEnumLabel } from 'utils/enum'

import { useAi } from '../../useAi'
import { useToolbarPosition } from '../../useToolbarPosition'
import s from './AiForm.module.scss'

type Command = {
  key: AiTextPromptTogglesEnum
  instruction: string
}

const AiForm: React.FC = () => {
  const prompt = useRichTextContext(getPrompt)
  const setPrompt = useRichTextContext(getSetPrompt)
  const ref = useRef<HTMLDivElement>(null)
  const styles = useRichTextContext(getStyles)
  const { companyId } = usePathParams(PROJECT_PATHS.editor)
  const [value, setValue] = useState(
    prompt !== AiTextPromptTogglesEnum.CUSTOM
      ? getEnumLabel('AiTextPromptTogglesEnum', prompt)
      : '',
  )
  const {
    sendPrompt,
    acceptAiUsage,
    slate,
    response: { data, loading, error, called },
  } = useAi()
  const { profile } = useMyInfo(companyId)

  const setToolbarForm = useRichTextContext(getSetToolbarForm)
  const company = profile?.company
  const disableAi = leftAiTokens(company?.aiConfigs) <= 0

  const editor = useSlate()
  const onRun = useCallback(
    async (command: Command) => {
      try {
        const prompt = editor.selection && Editor.string(editor, editor.selection)

        if (!prompt) {
          return
        }
        sendPrompt(command.key, command.instruction)
      } catch (error) {
        console.error(error)
      }
    },
    [editor, sendPrompt],
  )

  const onCustomEdit = useCallback(() => {
    prompt &&
      onRun({
        key: prompt,
        instruction: prompt === AiTextPromptTogglesEnum.CUSTOM ? value : '',
      })
  }, [onRun, prompt, value])

  const handleClose = useCallback(() => setToolbarForm('default'), [setToolbarForm])

  const handleConfirm = useCallback(() => {
    if (data && slate) {
      acceptAiUsage({ variables: { id: data.id } })
      ReactEditor.focus(editor)
      Editor.insertFragment(editor, slate)
      handleClose()
    }
  }, [acceptAiUsage, data, editor, handleClose, slate])

  const handleDecline = useCallback(() => {
    collapseSelectionToEnd(editor)
    handleClose()
  }, [editor, handleClose])

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !e.ctrlKey) {
      onCustomEdit()
    }
  }

  const handleRewrite = useCallback(() => {
    if (data) {
      acceptAiUsage({ variables: { id: data.id } })
      onCustomEdit()
    }
  }, [data, acceptAiUsage, onCustomEdit])

  useEffect(() => {
    if (prompt && prompt !== AiTextPromptTogglesEnum.CUSTOM && !called) {
      sendPrompt(prompt)
    }
  }, [prompt, called, sendPrompt])

  if (error) {
    handleClose()
  }

  useUnmount(() => {
    setPrompt(null)
  })

  useToolbarPosition(ref)

  if (data) {
    return (
      <div className={s.root} ref={ref}>
        <div className={s.resultTitle}>
          <Icon name='builderAi' size={KitSize.S} /> {t('elements.richText.ai.result')}
        </div>
        <div className={s.result}>
          <LayoutScroll sizeAutoCapable>
            <div className={s.resultText}>
              <RichTextViewer styles={styles} value={slate} />
            </div>
          </LayoutScroll>
          <div className={s.shadow} />
        </div>
        <div className={s.bottomControls}>
          <Tooltip disabled={!disableAi} overlay={t('elements.richText.ai.tooltip')}>
            <Button
              disabled={disableAi}
              name='rewrite'
              onClick={handleRewrite}
              size={KitSize.S}
              styleType='secondary'
            >
              {t('uiKit.button.retry')}
            </Button>
          </Tooltip>
          <div className={s.buttonsRight}>
            <Button name='decline' onClick={handleDecline} size={KitSize.S} styleType='ghost'>
              {t('uiKit.button.decline')}
            </Button>
            <Button name='confirm' onClick={handleConfirm} size={KitSize.S} styleType='primary'>
              {t('uiKit.button.confirm')}
            </Button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={s.root} ref={ref}>
      <div className={s.prompt}>
        <div className={s.input}>
          <Input
            name='richTextLink'
            onChange={setValue}
            onKeyDown={handleKeyDown}
            placeholder={t('elements.richText.ai.placeholder')}
            prefix={<Icon name='builderAi' size={KitSize.S} />}
            styleType='ghost'
            value={value}
            autoFocus
          />
        </div>
        <div className={s.controls}>
          {loading ? (
            <Loader name='aiLoading' />
          ) : (
            <IconButton
              disabled={!value}
              icon='send'
              name='runAi'
              onClick={onCustomEdit}
              styleType='ghost'
            />
          )}
          <IconButton
            icon='otherClose'
            name='closeCustomPanel'
            onClick={handleClose}
            styleType='ghost'
          />
        </div>
      </div>
    </div>
  )
}

export default AiForm
