import { CONFIG_ELEMENTS } from '@leenda/editor/lib/elements'

import { t } from 'services/Translation'

import { ElementGroup } from '../../elements.types'
import { genElementConfig } from '../../genElementConfig'
import {
  STYLE_FORM_SCHEMA,
  FONT_START_FORM_SCHEMA,
  FONT_END_FORM_SCHEMA,
} from './TestInfoBrandFormSchema'
import { Start, End } from './TestInfoDemo'
import Element from './TestInfoElement'
import Form from './TestInfoForm'
import endTestInfoIcon from './assets/testInfoEnd.svg'
import startTestInfoIcon from './assets/testInfoStart.svg'

export const StartTestInfoElement = genElementConfig(CONFIG_ELEMENTS.testInfoStart, {
  label: t('elements.test-info.start.label'),
  icon: startTestInfoIcon,
  group: ElementGroup.test,
  Element,
  ValueForm: Form,
  STYLE_FORM_SCHEMA,
  FONT_FORM_SCHEMA: FONT_START_FORM_SCHEMA,
  Demo: [Start],
})

export const EndTestInfoElement = genElementConfig(CONFIG_ELEMENTS.testInfoEnd, {
  label: t('elements.test-info.end.label'),
  icon: endTestInfoIcon,
  group: ElementGroup.test,
  Element,
  ValueForm: Form,
  STYLE_FORM_SCHEMA,
  FONT_FORM_SCHEMA: FONT_END_FORM_SCHEMA,
  Demo: [End],
})
