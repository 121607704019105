import { CONFIG_ELEMENTS } from '@leenda/editor/lib/elements'

import { t } from 'services/Translation'

import { ElementGroup } from '../../elements.types'
import { genElementConfig } from '../../genElementConfig'
import './CourseStructure.types'
import { STYLE_FORM_SCHEMA, FONT_FORM_SCHEMA } from './CourseStructureBrandFormSchema'
import CourseStructureDemo from './CourseStructureDemo'
import Element from './CourseStructureElement'
import Form from './CourseStructureForm'
import icon from './assets/courseStructure.svg'

export default genElementConfig(CONFIG_ELEMENTS.courseStructure, {
  icon,
  group: ElementGroup.cover,
  label: t('elements.courseStructure.label'),
  Element,
  ValueForm: Form,
  STYLE_FORM_SCHEMA,
  FONT_FORM_SCHEMA,
  Demo: CourseStructureDemo,
})
