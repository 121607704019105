import { Action, IState, Actions } from './types'

const STOP = { played: 0, playedSeconds: 0, loaded: 0, loadedSeconds: 0 }

function reducer(state: IState, action: Actions): IState {
  switch (action.type) {
    case Action.PLAY:
      return { ...state, playing: true, started: true }
    case Action.PAUSE:
      return { ...state, playing: false }
    case Action.UPDATE:
      return { ...state, ...action.payload }
    case Action.SET_DURATION:
      return { ...state, duration: action.payload }
    case Action.TOGGLE_PLAY:
      return { ...state, playing: !state.playing, started: true }
    case Action.START_SEEKING:
      return { ...state, seeking: true }
    case Action.SET_VOLUME:
      return { ...state, volume: action.payload }
    case Action.SET_PLAYED: {
      const played = action.payload / state.duration
      return { ...state, played, playedSeconds: action.payload }
    }
    case Action.TOGGLE_FULLSCREEN: {
      return { ...state, fullscreen: !state.fullscreen }
    }
    case Action.SET_PLAYBACK_RATE: {
      return { ...state, playbackRate: action.payload }
    }
    case Action.SEEK_TO: {
      return { ...state, seeking: false }
    }
    case Action.SET_PROGRESS:
      const { seeking, start, duration, loop } = state
      const { loaded, loadedSeconds, played, playedSeconds } = action.payload

      if (playedSeconds < start) {
        return state
      }

      if (playedSeconds >= duration + start) {
        return { ...state, ...STOP, playing: loop }
      }

      if (seeking) {
        return state
      }

      const p = played > 0 ? (playedSeconds - start) / duration : 0
      const pS = playedSeconds > 0 ? playedSeconds - start : 0
      const l = loaded ? (loadedSeconds - start) / duration : loaded
      const lS = loadedSeconds ? loadedSeconds - start : loadedSeconds

      return { ...state, played: p, playedSeconds: pS, loaded: l, loadedSeconds: lS }

    default: {
      console.error(action)
      return state
    }
  }
}

export default reducer
