import React, { useCallback } from 'react'

import { IControlProps } from 'components/controls/Field/Field.types'
import Segmented, { ISegmentedProps } from 'components/uiKit/Segmented'

const FieldSegmented: React.FC<IControlProps<ISegmentedProps>> = ({
  name,
  onChange,
  defaultValue,
  value,
  fluid,
  ...rest
}) => {
  const handleChange = useCallback(
    (value: ISegmentedProps['value']) => onChange({ value, name }),
    [name, onChange],
  )

  return (
    <Segmented
      fluid={fluid}
      name={name}
      onChange={handleChange}
      {...rest}
      value={value ?? (defaultValue as string)}
    />
  )
}

export default FieldSegmented
