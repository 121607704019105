import { motion } from 'framer-motion'

import { useGetRichTextProps } from 'components/form/RichText/useGetRichTextProps'
import { PreviewMode } from 'services/Store/Project/enums'
import { useAnimatedNumberState } from 'utils/animationHooks'

import { useElementRootStyles } from '../../hooks/useElementRootStyles'
import s from './ListElement.module.scss'
import { ListElementType } from './ListElement.types'
import ListItemComponent from './ListItemComponent'

const ANIMATION_VARIANTS = {
  hidden: { opacity: 0, x: 50 },
  visible: { opacity: 1, x: 0 },
}

const ListElement: ListElementType = (props) => {
  const { element, styles, mode } = props
  const { value } = element
  const rootStyles = useElementRootStyles(styles.root)
  const isEditor = mode.previewMode === PreviewMode.editor
  const isPdf = mode.previewMode === PreviewMode.pdf
  const [renderIndex, setRenderIndex] = useAnimatedNumberState(500)
  const rtProps = useGetRichTextProps({ elementId: element.id, mode })

  return (
    <ul className={s.list} style={rootStyles}>
      {value?.items?.map((item, i) => {
        return (
          <div key={i}>
            {styles.animation.fade && !isEditor && !isPdf ? (
              <motion.div
                animate={i <= renderIndex ? 'visible' : 'hidden'}
                initial='hidden'
                transition={{ duration: 1 }}
                variants={ANIMATION_VARIANTS}
              >
                <ListItemComponent
                  i={i}
                  item={item}
                  {...props}
                  onViewIndex={setRenderIndex}
                  rtProps={rtProps}
                />
              </motion.div>
            ) : (
              <ListItemComponent
                i={i}
                item={item}
                {...props}
                onViewIndex={setRenderIndex}
                rtProps={rtProps}
              />
            )}
          </div>
        )
      })}
    </ul>
  )
}

export default ListElement
