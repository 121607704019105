import { LabeledGraphicFontSchemaType } from '@leenda/editor/lib/brand'
import { useContext } from 'use-context-selector'

import { ElementFontCss } from 'services/Branding/types'

import LabeledGraphicContext from '../LabeledGraphicContext'
import Point from '../Point'
import PointTooltip from '../Point/PointTooltip'
import mock from '../assets/imageDemo.svg'
import s from './PdfComponent.module.scss'

interface IPdfComponentProps {
  styles: {
    allStyles: any
    rootStyles: Partial<React.CSSProperties> | undefined
    imageStyles: Partial<React.CSSProperties> | undefined
  }
  font: ElementFontCss<LabeledGraphicFontSchemaType>
  filePath?: string
}

const PdfComponent = ({ styles, filePath, font }: IPdfComponentProps) => {
  const { rootStyles, imageStyles } = styles
  const { element, imgRef } = useContext(LabeledGraphicContext)

  return (
    <div className={s.pdf}>
      <div className={s.root} style={rootStyles}>
        <img
          ref={imgRef}
          src={filePath || mock}
          style={{
            ...imageStyles,
            borderRadius: filePath ? imageStyles?.borderRadius : 'none',
          }}
        />
        {element.value.items?.map((item, index) => (
          <Point
            font={font}
            id={element.id}
            index={index}
            isActive={false}
            key={item.value}
            point={item}
            value={element.value}
          />
        ))}
      </div>
      <div className={s.pdfItems}>
        {element.value.items?.map((item, index) => (
          <div className={s.pdfItem} key={item.value}>
            <PointTooltip
              count={element.value.items.length}
              font={font}
              id={element.id}
              index={index}
              point={item}
              value={element.value}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default PdfComponent
