import { useFragment } from '@apollo/client'
import {
  FileImageUsage,
  FileUsage,
  FileUsageTypeEnum,
  getFileUsage,
} from '@leenda/editor/lib/files'
import companyLogo480x480 from 'platform/assets/companyLogo-480x480.svg'

import { FileMetaSchemaFragment, FileSchemaFragment } from 'gql/__generated__/graphql'
import { getFileMetaByIdFromCache } from 'gql/files.apollo'
import { fileFragment } from 'gql/files.gql'

export type FilesWithPath = {
  path?: string
  file?: FileSchemaFragment

  originalFile?: FileSchemaFragment
  name?: string
  processing?: boolean
}

export const COMPANY_DEFAULT_LOGO = companyLogo480x480

export const getCompanyLogoOrDefault = (logo?: FileMetaSchemaFragment | null): string => {
  const file = logo && getFileInFileMeta(logo, 'original')
  return file?.path ? file.path : COMPANY_DEFAULT_LOGO
}

export const getFileInFileMeta = (
  fileMeta: FileMetaSchemaFragment,
  key = 'original',
): FilesWithPath => {
  const intFiles = fileMeta.files
  const file = intFiles.find((e) => e.key === key)
  const originalFile = intFiles.find((e) => e.key === 'original')

  return {
    processing: !fileMeta.isProcessed,
    path: file?.path,
    file: file,
    originalFile: originalFile,
    name: fileMeta.name,
  }
}
export const getFileByUsage = (
  file: Omit<FileUsage, 'type'> | null,
  type: FileUsageTypeEnum,
): FilesWithPath | null => {
  const fileMeta = getFileMetaByIdFromCache(file?.id)

  if (!file || !fileMeta) {
    return null
  }
  const fileUsage = getFileUsage(type)(file)

  return getFileInFileMeta(fileMeta, fileUsage?.key)
}

export const getFileAsCommonById = (imageId: string | null | undefined): FilesWithPath | null => {
  if (!imageId) {
    return null
  }
  return getFileByUsage({ id: imageId }, FileUsageTypeEnum.common)
}

export const getImageByUsage = (image: Omit<FileImageUsage, 'type'> | null): FilesWithPath | null =>
  getFileByUsage(image, FileUsageTypeEnum.image)

export const useImageWithCrop = (
  image: Omit<FileImageUsage, 'type'> | null,
): FilesWithPath | null => {
  useFragment({
    fragment: fileFragment,
    fragmentName: 'FileMetaSchema',
    from: {
      __typename: 'FileMeta',
      id: image?.id || '',
    },
  })
  return getImageByUsage(image)
}

export const getOriginalImageById = (imageId: string | null | undefined): FilesWithPath | null => {
  if (!imageId) {
    return null
  }
  return getImageByUsage({ id: imageId })
}
