import { HTMLMotionProps, motion, useAnimation, Variants } from 'framer-motion'
import React, { useRef, useState } from 'react'

import s from './CourseSlider.module.scss'
import { swipePower } from './utils'

const variants: Variants = {
  toLeft: {
    x: '-100%',
    pointerEvents: 'none',
  },
  toRight: {
    x: '100%',
    pointerEvents: 'none',
  },
  center: {
    x: 0,
    pointerEvents: 'initial',
  },
}

interface ICourseSliderProps {
  items: JSX.Element[]
  activeIndex: number
  goPrev: () => void
  goNext: () => void
}

const CourseSlider = ({ items, activeIndex, goPrev, goNext }: ICourseSliderProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const [rect, setRect] = useState<DOMRect | null>()
  const [dragEnabled, setDragEnabled] = useState(true)

  React.useEffect(() => {
    if (ref.current) {
      setRect(ref.current.getBoundingClientRect())
    }
  }, [])

  const [page, setPage] = React.useState(activeIndex)

  const prev = activeIndex !== 0 ? activeIndex - 1 : undefined
  const cur = activeIndex
  const next = activeIndex !== items.length - 1 ? activeIndex + 1 : undefined

  const animation = useAnimation()

  const handleDragEnd = async (
    _evt: MouseEvent,
    { offset }: { offset: { x: number; y: number } },
  ) => {
    if (!dragEnabled) {
      return
    }
    const power = swipePower(offset.x, rect?.width)
    if (power > 60 && page > 0) {
      await animation.start('toRight')
      paginate(-1)
      goPrev()
    } else if (power < -60 && page < items.length - 1) {
      await animation.start('toLeft')
      paginate(1)
      goNext()
    }
  }

  const paginate = (dir: number) => {
    setPage(page + dir)
  }

  const disableDrag = page < 0 || page > items.length - 1

  const handleDragStart = (_evt: MouseEvent, { offset }: { offset: { x: number; y: number } }) => {
    const power = swipePower(offset.x, rect?.width)
    if ((power > 0 && page === 0) || (power < 0 && page === items.length - 1)) {
      setDragEnabled(false)
    } else {
      setDragEnabled(true)
    }
  }

  return (
    <div className={s.slider}>
      <motion.div
        animate={animation}
        className={s.track}
        drag={disableDrag ? false : 'x'}
        dragConstraints={{ left: 0, right: 0 }}
        dragMomentum={false}
        key={page}
        onDragEnd={handleDragEnd}
        onDragStart={handleDragStart}
        transition={{
          x: { type: 'spring', mass: 0.5, stiffness: 500, damping: 50 },
        }}
        variants={variants}
        dragDirectionLock
      >
        <SliderItem data={prev ? items[prev] : undefined} key={prev} />
        <SliderItem data={items[cur]} key={cur} ref={ref} />
        <SliderItem data={next ? items[next] : undefined} key={next} />
      </motion.div>
    </div>
  )
}

interface IItemProps extends HTMLMotionProps<'div'> {
  data?: JSX.Element
}

const SliderItem = React.forwardRef<HTMLDivElement, IItemProps>(({ data }, ref) => {
  const variants: Variants = {
    top: {
      y: 0,
    },
    bottom: {
      y: 0,
    },
  }
  const currentVariant = React.useRef('bottom')
  const inMotion = React.useRef(false)
  const animation = useAnimation()

  /**
   * handleDragStart handles a drag start event and sets the inMotion ref
   * to true. We enable click events only if `inMotion != true`.
   * @returns {void}
   */
  const handleDragStart = async () => {
    inMotion.current = true
  }

  /**
   * handleDragEnd handles the drag end event and decides, if we need to
   * transition into a new animation variant.
   * @param {{}} info - Drag informations
   * @returns {void}
   */

  const handleOnClick = async () => {
    if (inMotion.current === false) {
      currentVariant.current = currentVariant.current === 'top' ? 'bottom' : 'top'
      await animation.start(currentVariant.current)
    }
  }

  return (
    <motion.div
      animate={animation}
      className={s.item}
      drag={false}
      dragConstraints={{ bottom: 0, top: 0 }}
      dragMomentum={false}
      onDragStart={handleDragStart}
      ref={ref}
      transition={{
        y: { type: 'spring', stiffness: 500, damping: 50 },
      }}
      variants={variants}
      dragDirectionLock
    >
      <div className={s.itemInner} onClick={handleOnClick}>
        {data}
      </div>
    </motion.div>
  )
})

SliderItem.displayName = 'Item'

export default CourseSlider
