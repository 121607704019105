import { FileUsageTypeEnum } from '@leenda/editor/lib/files'
import { FileTypeEnum } from 'utils'

import MediaPlayer from 'components/MediaPlayer'
import { useProjectT } from 'services/ProjectLabels'
import { getFileByUsage } from 'utils/files'

import s from './AudioPreview.module.scss'

const AudioPreview = ({ value }: any) => {
  const mediaFile = getFileByUsage(value, FileUsageTypeEnum.audio)
  const pt = useProjectT()

  return (
    <div className={s.root}>
      <MediaPlayer
        type={FileTypeEnum.AUDIO}
        url={mediaFile?.path || value || null}
        volumeLabel={pt('elements.media.volume')}
      />
    </div>
  )
}

export default AudioPreview
