import { NavButtonFontSchemaType, NavButtonSchemaType } from '@leenda/editor/lib/brand'
import { ButtonNavigationAction } from '@leenda/editor/lib/elements'
import cn from 'classnames'
import React, { useCallback } from 'react'

import { getButtonLabel } from 'components/editor-v2/EditorElements/elements/ButtonElement/helper'
import { ElementFontCss, ElementStyleCss } from 'services/Branding/types'
import { useProjectT } from 'services/ProjectLabels'
import { PreviewMode, DeviceMode } from 'services/Store/Project/enums'
import { useProjectContext, useProjectDispatch } from 'services/Store/Project/hooks'
import { getNextSectionTrigger, getPrevSectionTrigger } from 'services/Store/Project/triggers'
import { IBlockMode } from 'services/Store/Project/types'
import { t } from 'services/Translation'

import s from './LandingFooterNavigation.module.scss'
import NavigationButtonsDemo from './NavigationButtonsDemo'
import BaseNavigationButton from './components/BaseNavigationButton'
import ButtonIcon from './components/ButtonIcon'
import { getIconUrl } from './helpers'

interface ILandingFooterNavigationProps {
  mode: IBlockMode
  styles: ElementStyleCss<NavButtonSchemaType>
  fonts?: ElementFontCss<NavButtonFontSchemaType>
}

const LandingFooterNavigation: React.FC<ILandingFooterNavigationProps> = ({
  mode,
  styles,
  fonts,
}) => {
  const pt = useProjectT()
  const dispatch = useProjectDispatch()
  const prevTrigger = useProjectContext(getPrevSectionTrigger)
  const nextTrigger = useProjectContext(getNextSectionTrigger)
  const nextLabel = getButtonLabel(
    {
      'project/setCourseNavigation': pt('navigationButton.continue'),
      'project/courseEnd': pt('navigationButton.end'),
      noop: pt('navigationButton.continue'),
    },
    nextTrigger?.action,
  )
  const isDemoMode = mode.previewMode === PreviewMode.demo
  const rootCn = cn(s.root, { [s.mobile]: mode.deviceMode === DeviceMode.mobile })

  const handlePrevTrigger = useCallback(
    () => !prevTrigger.disabled && dispatch(prevTrigger.actionCreator()),
    [dispatch, prevTrigger],
  )

  const handleNextTrigger = useCallback(
    () => !nextTrigger.disabled && dispatch(nextTrigger.actionCreator()),
    [dispatch, nextTrigger],
  )

  const disabledNext = { ...styles?.nextButton__var, ...styles?.nextButtonDisabled__var }
  const disabledPrev = { ...styles?.prevButton__var, ...styles?.prevButtonDisabled__var }

  const showButtons = styles.show.show

  const nextIcon = getIconUrl(styles?.nextButton__var)
  const prevIcon = getIconUrl(styles?.prevButton__var)

  return isDemoMode ? (
    <NavigationButtonsDemo fonts={fonts} mode={mode} styles={styles} />
  ) : (
    <div className={rootCn} style={{ gap: styles.padding__var.gap }}>
      {showButtons && (
        <>
          {(!prevTrigger.disabled || isDemoMode) && (
            <BaseNavigationButton
              action={ButtonNavigationAction.prevSection}
              disabled={prevTrigger.disabled}
              fonts={fonts}
              icon={<ButtonIcon defaultName='courseArrowBack' url={prevIcon} />}
              iconPosition='left'
              label={pt('navigationButton.return')}
              mode={mode}
              onClick={handlePrevTrigger}
              styles={{
                root: styles?.root,
                button: prevTrigger.disabled ? disabledPrev : styles.prevButton__var,
              }}
            />
          )}
          <BaseNavigationButton
            action={ButtonNavigationAction.nextSection}
            disabled={nextTrigger.disabled}
            fonts={fonts}
            icon={
              nextTrigger.action === 'project/setCourseNavigation' ? (
                <ButtonIcon defaultName='courseArrowForward' url={nextIcon} />
              ) : null
            }
            iconPosition='right'
            label={isDemoMode ? t('elements.button.demo.value.continue') : nextLabel}
            mode={mode}
            onClick={handleNextTrigger}
            styles={{
              root: styles.root,
              button: nextTrigger.disabled ? disabledNext : styles.nextButton__var,
            }}
          />
        </>
      )}
    </div>
  )
}

export default LandingFooterNavigation
