import { SidebarFontSchemaType, SidebarSchemaType } from '@leenda/editor/lib/brand'
import { CourseStructureItem } from '@leenda/editor/lib/elements'
import React from 'react'

import { getIconData } from 'components/editor-v3/cource/controls/CourseSidebar/utils'
import Highlighter from 'components/uiKit/Highlighter'
import { ElementFontCss, ElementStyleCss } from 'services/Branding/types'
import { useProjectT } from 'services/ProjectLabels'
import { scrollProps } from 'services/Scroll/ScrollService'
import { testPropsEl } from 'utils/test/qaData'

import s from './StructureItemCourse.module.scss'

interface IItemsProps {
  item: CourseStructureItem
  onSelect?: (sectionId: string) => void
  searchValue?: string
  styles: ElementStyleCss<SidebarSchemaType>
  font: ElementFontCss<SidebarFontSchemaType>
  nestedOffset?: number
}

const StructureItemCourse: React.FC<IItemsProps> = ({
  item,
  onSelect,
  searchValue,
  styles,
  font,
  nestedOffset,
}) => {
  const pt = useProjectT()
  const { showIcon, IconImage, styleFontSection, icon } = getIconData(item.type, styles, font)

  const offsetFromIcon = styles?.margins.offsetFromIcon
  const sectionNameStyles = { ...styles?.root, ...(!showIcon && { marginLeft: nestedOffset }) }
  const iconStyles = {
    ...(IconImage && { backgroundImage: IconImage }),
    marginRight: offsetFromIcon,
    marginLeft: nestedOffset,
  }

  return (
    <>
      {showIcon && (
        <div className={IconImage ? s.iconImage : s.itemIcon} style={iconStyles}>
          {!IconImage && icon}
        </div>
      )}
      <div
        {...testPropsEl('sectionItem', { name: item.name }, { id: item.id })}
        {...scrollProps(item.id)}
        className={s.item}
        onClick={() => onSelect?.(item.id)}
        style={sectionNameStyles}
      >
        <div className={s.itemContent}>
          <div style={styleFontSection}>
            {item.name ? (
              <Highlighter search={searchValue} text={item.name} breakWord />
            ) : (
              pt('courseStructure.chapterName')
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default StructureItemCourse
