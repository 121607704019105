import React, { useState } from 'react'

import { IconButton } from 'components/uiKit/Button'
import Dropdown from 'components/uiKit/Dropdown'
import { KitSize } from 'components/uiKit/KitTypes'

import { ColumnType, ITablesExtendsData } from '../types'
import TableOptionsForm, { ITableOptionsFormProps } from './TableOptionsForm'

interface ITableOptionsProps<Data, Args extends Record<string, unknown>> {
  name: string
  defaultColumns?: ColumnType<Data>[]
  customForm?: React.FC<ITableOptionsFormProps<Data, Args>>
  formArgs?: Args
  hidden?: boolean
}

const TableOptions = <T extends ITablesExtendsData, Args extends Record<string, unknown>>({
  name,
  defaultColumns,
  customForm: Form = TableOptionsForm,
  formArgs,
  hidden,
}: ITableOptionsProps<T, Args>) => {
  const [isVisible, setIsVisible] = useState(false)

  const handleSubmit = () => {
    setIsVisible(false)
  }

  return (
    <Dropdown
      onVisibleChange={setIsVisible}
      overlay={
        <Form
          args={formArgs}
          defaultColumns={defaultColumns}
          onSubmit={handleSubmit}
          tableName={name}
        />
      }
      placement='bottomRight'
      visible={isVisible}
    >
      {({ open }) => (
        <IconButton
          active={open}
          hidden={hidden}
          icon='iconsOtherMore'
          name='paginationSize'
          size={KitSize.S}
          styleType='ghost'
        />
      )}
    </Dropdown>
  )
}

export default TableOptions
