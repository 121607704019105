import cn from 'classnames'
import React, { useCallback } from 'react'

import { IconButton } from 'components/uiKit/Button'
import { KitSize } from 'components/uiKit/KitTypes'
import { testProps } from 'utils/test/qaData'

import {
  useSelectActions,
  useSelectContext,
  useSelectHandlers,
  useSelectSize,
} from '../SelectContext'
import { isEmptyValue } from '../SelectUtils'
import s from './SelectIcon.module.scss'

interface ISelectIconProps {
  name: string
}
const SelectIcon: React.FC<ISelectIconProps> = ({ name }) => {
  const { handleChange, handleSeparate } = useSelectHandlers()
  const { setForceSearch, setInputValue, setIsOpen } = useSelectActions()
  const size = useSelectSize()
  const isOpen = useSelectContext((c) => c.isOpen)
  const loading = useSelectContext((c) => c.loading)
  const disabled = useSelectContext((c) => c.disabled)
  const readOnly = useSelectContext((c) => c.readOnly)
  const hideAction = disabled || readOnly
  const showEnter = useSelectContext(
    (c) => !hideAction && c.isMultiple && c.inputable && c.hideList,
  )
  const showReset = useSelectContext((c) => !hideAction && !isEmptyValue(c.value) && c.resettable)
  const showSearch = useSelectContext(
    (c) => !hideAction && !showReset && c.isSearch && c.value && !c.isMultiple && !c.inputable,
  )
  const showArrow = useSelectContext((c) => !showReset && !readOnly && !c.hideList)

  const onResetClick = useCallback(
    (e: React.SyntheticEvent) => {
      handleChange?.(null)
      setInputValue?.('')
      e.stopPropagation()
    },
    [handleChange, setInputValue],
  )

  const onSearchClick = useCallback(() => {
    setIsOpen(true)
    setForceSearch(true)
  }, [setForceSearch, setIsOpen])

  const cnRoot = cn(s.root, s[size], { [s.open]: isOpen })

  return (
    <div className={cnRoot} {...testProps({ el: 'selectToggle', name, open: isOpen })}>
      {showEnter && (
        <IconButton
          disabled={disabled}
          icon='otherEnter'
          loading={loading}
          name='enter'
          onClick={handleSeparate}
          size={KitSize.S}
          styleType='ghost'
          tabIndex={-1}
        />
      )}
      {showReset && (
        <IconButton
          icon='otherReset'
          name='remove'
          onClick={onResetClick}
          size={KitSize.S}
          styleType='ghost'
          tabIndex={-1}
        />
      )}
      {showSearch && (
        <IconButton
          icon='searchField'
          name='open'
          onClick={onSearchClick}
          size={KitSize.S}
          styleType='ghost'
          tabIndex={-1}
        />
      )}
      {showArrow && (
        <IconButton
          disabled={disabled}
          icon={isOpen ? 'otherUp' : 'otherDown'}
          loading={loading}
          name='open'
          size={KitSize.S}
          styleType='ghost'
          tabIndex={-1}
        />
      )}
    </div>
  )
}

export default React.memo(SelectIcon)
