import { useMutation, useQuery } from '@apollo/client'
import { getOperationName } from '@apollo/client/utilities'

import {
  BrandTableItemsByCompanyQueryVariables,
  BrandSelectItemsByCompanyQueryVariables,
} from 'gql/__generated__/graphql'

import {
  brandTableItemsByCompany,
  branGetById,
  createBrand,
  updateCompanyBrand,
  setCompanyBrand,
  deleteBrandsByIds,
  cloneCompanyBrand,
  brandsSelectItemsByCompany,
} from './brands.gql'

export const useGetAllBrandTableItems = (
  variables: BrandTableItemsByCompanyQueryVariables,
  skip = false,
) =>
  useQuery(brandTableItemsByCompany, {
    variables,
    fetchPolicy: 'cache-and-network',
    skip,
    onError: (e) => console.error('"useGetAllBrands" fn is crashed on operation: "useQuery"', e),
  })

export const useGetAllBrandSelectItems = (
  variables: BrandSelectItemsByCompanyQueryVariables,
  skip = false,
) =>
  useQuery(brandsSelectItemsByCompany, {
    variables: {
      ...variables,
      search: { pagination: { limit: 500, offset: 0 }, order: [['updatedAt', 'ASC']] },
    },
    fetchPolicy: 'cache-and-network',
    skip,
    onError: (e) => console.error('"useGetAllBrands" fn is crashed on operation: "useQuery"', e),
  })

export const useGetBrand = (id: string, skip?: boolean) =>
  useQuery(branGetById, {
    variables: { id },
    skip,
    onError: (err) => console.error('"useGetBrand" fn is crashed on operation: "useQuery"', err),
  })

export const useCreateBrand = () =>
  useMutation(createBrand, {
    onError: (e) => console.error('"useCreateBrand" fn is crashed on operation: "useMutation"', e),
    refetchQueries: [String(getOperationName(brandTableItemsByCompany))],
  })

export const useDeleteBrandById = () =>
  useMutation(deleteBrandsByIds, {
    refetchQueries: [String(getOperationName(brandTableItemsByCompany))],
    onError: (e) =>
      console.error('"useDeleteBrandById" fn is crashed on operation: "useMutation"', e),
  })

export const useUpdateBrand = () =>
  useMutation(updateCompanyBrand, {
    onError: (e) => console.error('"useUpdateBrand" fn is crashed on operation: "useMutation"', e),
  })

export const useDefaultBrand = () =>
  useMutation(setCompanyBrand, {
    refetchQueries: [String(getOperationName(brandTableItemsByCompany))],
    onError: (e) => console.error('"useDefaultBrand" fn is crashed on operation: "useMutation"', e),
  })

export const useCloneBrands = () =>
  useMutation(cloneCompanyBrand, {
    refetchQueries: [String(getOperationName(brandTableItemsByCompany))],
    onError: (e) => console.error('"useDefaultBrand" fn is crashed on operation: "useMutation"', e),
  })
