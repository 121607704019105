import { useMutation, useQuery, useSubscription } from '@apollo/client'
import { getOperationName } from '@apollo/client/utilities'
import { gqlClient } from 'gql'

import { gql } from 'gql/__generated__'
import {
  GetKUsersQueryVariables,
  GetKuserCompaniesQueryVariables,
  KUserInfoSchemaFragment,
} from 'gql/__generated__/graphql'

import { kUserInfoFragment } from './gql/fragments'
import { updateUserPreferences } from './gql/mutations'
import {
  getKUsers,
  getKUsersAll,
  getKuserCompanies,
  getUser,
  kUserById,
  kUserByIdAdmin,
  kUsersSubscribtion,
  getToken,
} from './gql/queries'

const updateUserInCore = (data: Pick<KUserInfoSchemaFragment, 'id' | '__typename'>) => {
  gqlClient.core.cache.writeFragment({
    id: `KUser:${data.id}`,
    fragment: kUserInfoFragment,
    fragmentName: 'kUserInfoSchema',
    data: data,
  })
}

export const useUpdateUser = () =>
  useMutation(
    gql(`
      mutation kUsersUpdateById($id: String!, $data: kUserUpdateInput!) {
        data: kUsersUpdateById(id: $id, data: $data) {
          ...kUserInfoSchema
        }
      }
    `),
    {
      onError: (err) =>
        console.error('"useUpdateUser" fn is crashed on operation: "useMutation"', err),
      onCompleted: (data) => {
        const userUpdate = data?.data
        // console.log(userUpdate)
        if (userUpdate) {
          updateUserInCore(userUpdate)
        }
      },
      client: gqlClient.auth,
    },
  )

export const useGetUserInfo = (skip = false) =>
  useQuery(getUser, {
    onError: (err) => console.error('"useGetUserInfo" fn is crashed on operation: "useQuery"', err),
    client: gqlClient.core,
    fetchPolicy: 'cache-and-network',
    skip,
  })

export const useUpdateUserPreferences = () =>
  useMutation(updateUserPreferences, {
    onError: (err) =>
      console.error('"useUpdateUserPreferences" fn is crashed on operation: "useMutation"', err),
    client: gqlClient.core,
  })

export const useGetKUsersAll = () =>
  useQuery(getKUsersAll, {
    onError: (err) =>
      console.error('"useGetKUsersAll" fn is crashed on operation: "useQuery"', err),
    client: gqlClient.auth,
  })

export const useGetKUserById = (id: string) =>
  useQuery(kUserById, {
    onError: (err) =>
      console.error('"useGetKUserById" fn is crashed on operation: "useQuery"', err),
    client: gqlClient.auth,
    variables: { id },
  })

export const useGetKUsersByIdAdmin = (id: string) =>
  useQuery(kUserByIdAdmin, {
    onError: (err) =>
      console.error('"useGetKUserById" fn is crashed on operation: "useQuery"', err),
    client: gqlClient.auth,
    variables: { id },
  })

export const useGetKUsers = (variables: GetKUsersQueryVariables) =>
  useQuery(getKUsers, {
    onError: (err) => console.error('"getKUsers" fn is crashed on operation: "useQuery"', err),
    variables,
    fetchPolicy: 'cache-and-network',
  })

export const useGetUserCompanies = (variables: GetKuserCompaniesQueryVariables) =>
  useQuery(getKuserCompanies, {
    onError: (err) =>
      console.error('"useGetUserCompanies" fn is crashed on operation: "useQuery"', err),
    variables,
    fetchPolicy: 'cache-and-network',
  })

export const useKUsersSubscription = () =>
  useSubscription(kUsersSubscribtion, {
    onError: (err) =>
      console.error('"useKUsersSubscription" fn is crashed on operation: "useSubscription"', err),
    onData: ({ data, client }) => {
      if (data.data?.data.type === 'create' || data.data?.data.type === 'delete') {
        client.refetchQueries({
          include: [String(getOperationName(getKUsers))],
        })
      }
    },
  })

export const useGetToken = () =>
  useQuery(getToken, {
    fetchPolicy: 'no-cache',
    onError: (err) => console.error('"useGetToken" fn is crashed on operation: "useQuery"', err),
    client: gqlClient.auth,
  })
