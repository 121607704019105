import { AiModelRoleEnum, AiProvider } from 'gql/__generated__/graphql'

export interface IAiConfigCreateValues {
  provider: AiProvider
  role: AiModelRoleEnum
  params: {
    model: string
    baseUrl?: string
    apiKey?: string
    resourceName?: string
    organization?: string
    project?: string
    region?: string
    accessKeyId?: string
    secretAccessKey?: string
    headers?: Record<string, string>
    compatibility?: 'strict' | 'compatible'
  }
}

export enum AiConfigActionUiEnum {
  DELETE = 'DELETE',
  EDIT = 'EDIT',
}
