import { sendEvent } from 'analytics'
import cn from 'classnames'
import moment from 'moment'
import React from 'react'
import { DATE_NOTIFICATION } from 'utils'

import Banner from 'components/uiKit/Banner'
import { Tooltip } from 'components/uiKit/Dropdown'
import Link from 'components/uiKit/Link'
import { RoutineMaintenanceSchemaFragment } from 'gql/__generated__/graphql'
import { t } from 'services/Translation'

import s from './Release.module.scss'

interface IReleaseProps {
  onClose: () => void
  notificationData: RoutineMaintenanceSchemaFragment
}

const Release: React.FC<IReleaseProps> = ({ onClose, notificationData }) => {
  const onClickLink = () => {
    sendEvent('click', { el: 'link', name: 'announcerLink' })
  }

  return (
    <Banner onClose={onClose} type='info'>
      <div className={cn({ [s.messageBlock]: notificationData.linkUrl })}>
        <div className={s.textWrapper}>
          <Tooltip
            mouseEnterDelay={1}
            overlay={notificationData.message}
            placement='bottom'
            theme='purple'
          >
            <div className={s.textInfo}>{notificationData.message}</div>
          </Tooltip>
        </div>
      </div>
      {notificationData.date && (
        <div className={s.dateBlock}>
          <div>{moment(notificationData.date).format(DATE_NOTIFICATION)}</div>
        </div>
      )}
      {notificationData.linkUrl && (
        <div className={s.linkUrlBlock}>
          <div className={s.textWrapper}>
            <Tooltip
              mouseEnterDelay={1}
              overlay={
                notificationData.linkLabel ? notificationData.linkLabel : t('input.label.link')
              }
              placement='bottom'
              theme='purple'
            >
              <div className={cn(s.linkUrl)}>
                <div className={s.textInfo}>
                  <Link
                    name='maintainInfoLink'
                    onClickCapture={onClickLink}
                    rel='noreferrer'
                    styleType='free'
                    target='_blank'
                    to={notificationData.linkUrl}
                  >
                    {notificationData.linkLabel
                      ? notificationData.linkLabel
                      : t('input.label.link')}
                  </Link>
                </div>
              </div>
            </Tooltip>
          </div>
        </div>
      )}
    </Banner>
  )
}

export default Release
