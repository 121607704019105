"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_VALUE = exports.TYPE = void 0;
const translation_1 = require("../../../translation");
const rich_text_1 = require("@leenda/rich-text");
const types_1 = require("./types");
exports.TYPE = 'testFeedback';
exports.DEFAULT_VALUE = {
    show: true,
    type: types_1.TestFeedbackType.validation,
    fail: [
        ...(0, rich_text_1.textToRtValue)((0, translation_1.t)('elements.testFeedback.fail'), rich_text_1.SlateElementType.heading3),
        ...(0, rich_text_1.textToRtValue)(''),
    ],
    success: [
        ...(0, rich_text_1.textToRtValue)((0, translation_1.t)('elements.testFeedback.success'), rich_text_1.SlateElementType.heading3),
        ...(0, rich_text_1.textToRtValue)(''),
    ],
    info: [
        ...(0, rich_text_1.textToRtValue)((0, translation_1.t)('elements.testFeedback.info'), rich_text_1.SlateElementType.heading3),
        ...(0, rich_text_1.textToRtValue)(''),
    ],
};
