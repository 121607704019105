import { PermissionsKey } from '@leenda/crud'
import React from 'react'

import ContactSalesButton from 'components/ContactSalesButton'
import Alert from 'components/uiKit/Alert'
import { KitSize } from 'components/uiKit/KitTypes'
import { CompanySchemaFragment } from 'gql/__generated__/graphql'
import { usePermissions } from 'services/Permission/PermissionContext'
import { t } from 'services/Translation'
import { getResourcesLabel } from 'utils/resources'

import s from './StorageLimitAlert.module.scss'

interface IStorageLimitAlertProps {
  companyData: CompanySchemaFragment
}
const StorageLimitAlert: React.FC<IStorageLimitAlertProps> = ({ companyData }) => {
  const canContact = usePermissions(PermissionsKey.company_settings_base_u)
  const resourcesLabel = getResourcesLabel(
    companyData.overallResources.total,
    companyData.availableStorage,
  )

  return (
    <Alert type='info'>
      <div className={s.alertMessage}>
        <span>{t('notify.storageLimit', { resources: resourcesLabel })}</span>
        {canContact && (
          <ContactSalesButton
            email={companyData.email || ''}
            name={companyData.owner.kUser.name || ''}
            size={KitSize.XS}
          />
        )}
      </div>
    </Alert>
  )
}

export default StorageLimitAlert
