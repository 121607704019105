import { PermissionIdentification, PermissionService } from '@leenda/crud'
import React, { useCallback, useMemo } from 'react'

import {
  deleteMenuItem,
  subscribeMenuItem,
  unsubscribeMenuItem,
  editMenuItem,
  resolveMenuItem,
  copyLinkMenuItem,
  pendingMenuItem,
  createCopyLinkAction,
} from 'components/comments/actions'
import { CommentActionUiEnum, CommentMock } from 'components/comments/types'
import Employee, { AccountMock } from 'components/uiKit/Employee'
import { useHover } from 'components/uiKit/KitUtils'
import { IMenuOption } from 'components/uiKit/Menu'
import { COMPANY_PATHS } from 'constants/paths'
import { EmployeeListSchemaFragment } from 'gql/__generated__/graphql'
import { useCommentPermissions } from 'services/Permission/PermissionContext'
import { selectCurrentEmployee } from 'services/Store/Users/selectors'
import { useAppSelector } from 'services/Store/hooks'

import { NameRow, CommentText, Actions, ICommentTextRef } from '../components'
import s from './FeedThread.module.scss'

const getActions = (
  comment: CommentMock,
  permissions: PermissionService<PermissionIdentification>,
  me: EmployeeListSchemaFragment | null,
) => [
  subscribeMenuItem({ comment, permissions, me }),
  unsubscribeMenuItem({ comment, permissions, me }),
  editMenuItem({ comment, permissions, me }),
  deleteMenuItem({ comment, permissions, me }),
  copyLinkMenuItem({ comment, permissions, me }),
  pendingMenuItem({ comment, permissions, me, inline: true }),
  resolveMenuItem({ comment, permissions, me, inline: true }),
]

interface IFeedThreadProps {
  comment: CommentMock
  mentions: AccountMock[]
}

const FeedThread: React.FC<IFeedThreadProps> = ({ comment, mentions }) => {
  const permissions = useCommentPermissions(comment)
  const textRef = React.useRef<ICommentTextRef>(null)
  const [showActions, handlers] = useHover()
  const me = useAppSelector(selectCurrentEmployee) || null

  const options = useMemo(() => getActions(comment, permissions, me), [comment, permissions, me])

  const handleClick = useCallback(
    async (item: IMenuOption<CommentActionUiEnum>) => {
      if (item.value === CommentActionUiEnum.EDIT) {
        textRef.current?.edit?.()
      } else if (item.value === CommentActionUiEnum.LINK) {
        await createCopyLinkAction(COMPANY_PATHS.dashboard, {
          projectId: comment.project?.id,
          sectionId: comment.sectionId,
          blockId: comment.blockUuid,
          threadId: comment.id,
        })
      }
    },
    [comment],
  )

  return (
    <div {...handlers} className={s.root}>
      <div className={s.title}>
        <Employee employee={comment.createdBy} styleType='avatar' />
        <NameRow comment={comment} />
        <Actions comment={comment} onClick={handleClick} options={options} show={showActions} />
      </div>
      <CommentText comment={comment} mentions={mentions} ref={textRef} />
    </div>
  )
}

export default React.memo(FeedThread)
