import React, { useState } from 'react'
import { useContextSelector } from 'use-context-selector'

import { IconButton } from 'components/uiKit/Button'
import Dropdown from 'components/uiKit/Dropdown'
import { KitSize } from 'components/uiKit/KitTypes'
import Radio from 'components/uiKit/Radio'
import { Typography } from 'components/uiKit/Typography'
import { t } from 'services/Translation'

import { TemplatePickerContext } from '../hooks'
import s from './DisplayOptions.module.scss'

const DISPLAY_OPTIONS = [
  { value: 2, label: t('entity.template.displayRow.two') },
  { value: 3, label: t('entity.template.displayRow.three') },
  { value: 0, label: t('entity.template.displayRow.max') },
]

interface IDisplayOptionsProps {}

const DisplayOptions: React.FC<IDisplayOptionsProps> = () => {
  const { columns, setColumns } = useContextSelector(TemplatePickerContext, (value) => value)
  const [isVisible, setIsVisible] = useState(false)

  return (
    <div className={s.root}>
      <Dropdown
        onVisibleChange={setIsVisible}
        overlay={
          <div className={s.content}>
            <Typography styleType='hint'>{t('entity.template.displayRowTitle')}</Typography>
            <Radio
              name='displayOptions'
              onChange={setColumns}
              options={DISPLAY_OPTIONS}
              value={columns}
            />
          </div>
        }
        placement='bottomRight'
        visible={isVisible}
      >
        {({ open }) => (
          <IconButton
            active={open}
            icon='iconsOtherMore'
            name='paginationSize'
            size={KitSize.S}
            styleType='ghost'
          />
        )}
      </Dropdown>
    </div>
  )
}

export default DisplayOptions
