import { RichTextFontSchemaType } from '@leenda/editor/lib/brand'
import { EditorElementError } from '@leenda/editor/lib/elements'
import { RichTextValue } from '@leenda/rich-text'
import React from 'react'

import { ElementFontCss } from 'services/Branding/types'

import RichTextCore from './RichTextCore'
import { RichTextRef } from './RichTextTypes'
import { RichTextViewer } from './RichTextViewer'

export type IRichTextProps = {
  name: string
  waiting?: boolean
  value: RichTextValue
  active: boolean
  disabled?: boolean
  styles: ElementFontCss<RichTextFontSchemaType>
  cursorPosition?: 'start' | 'end'
} & (
  | {
      onChange?: (value: RichTextValue | null, error?: EditorElementError[]) => void
      iterable: true
      onUp?: () => void
      onDown?: () => void
    }
  | {
      onChange?: (value: RichTextValue, error?: EditorElementError[]) => void
      iterable?: never
      onUp?: never
      onDown?: never
    }
)

const RichText = React.forwardRef<RichTextRef, IRichTextProps>(
  ({ value, disabled, styles, ...rest }, ref) =>
    disabled ? (
      <RichTextViewer styles={styles} value={value} />
    ) : (
      <RichTextCore {...rest} ref={ref} styles={styles} value={value} />
    ),
)

RichText.displayName = 'RichText'

export default React.memo(RichText)
