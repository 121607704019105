import { config } from '../config'
import { initAmplitude } from './amplitude'
import { initSentry } from './sentry'
import { initUserGuiding } from './userguiding'

export const initAnalytics = () => {
  try {
    if (['production', 'uat'].includes(config.default.environment)) {
      initAmplitude()
    }
    if (!['local', 'test'].includes(config.default.environment)) {
      initSentry()
    }
    initUserGuiding()

    // initAmplitude()
    // initSentry()
  } catch (e) {
    console.error('Error initAnalytics', e)
  }
}
