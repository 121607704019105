import { CONFIG_ELEMENTS } from '@leenda/editor/lib/elements'

import { t } from 'services/Translation'

import { ElementGroup } from '../../elements.types'
import { genElementConfig } from '../../genElementConfig'
import Demo from './EmbeddedDemo'
import Element from './EmbeddedElement'
import Form from './EmbeddedForm'
import codeIcon from './assets/embedded.svg'

export default genElementConfig(CONFIG_ELEMENTS.embedded, {
  label: t('elements.embedded.label'),
  icon: codeIcon,
  group: ElementGroup.view,
  STYLE_FORM_SCHEMA: {},
  FONT_FORM_SCHEMA: {},
  Element,
  ValueForm: Form,
  Demo,
})
