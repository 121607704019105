import cn from 'classnames'

import s from './Input.module.scss'

const AdditionWrapper: React.FC<{ children?: React.ReactNode; hideIcon?: boolean }> = ({
  children,
  hideIcon, // TODO: think is needed?
}) => {
  return <div className={cn(s.addition, { [s.hideIcon]: hideIcon })}>{children}</div>
}

export default AdditionWrapper
