import { SlateElementType } from '@leenda/rich-text'
import { Editor, Element, Node, Transforms } from 'slate'

import { LIST_ELEMENTS } from '../RichTextConstants'

export const withLists = (editor: Editor) => {
  const { normalizeNode } = editor

  editor.normalizeNode = (entry) => {
    const [node, path] = entry

    // If the element is a paragraph, ensure its children are valid.
    if (Element.isElement(node) && LIST_ELEMENTS.includes(node.type)) {
      for (const [child, childPath] of Node.children(editor, path)) {
        if (Element.isElement(child) && child.type !== SlateElementType.listItem) {
          Transforms.setNodes(editor, { type: SlateElementType.listItem }, { at: childPath })
          return
        }
      }
    }
    if (Element.isElement(node) && node.type === SlateElementType.listItem) {
      for (const [child, childPath] of Node.children(editor, path)) {
        if (
          Element.isElement(child) &&
          Element.isElement(child) &&
          !Editor.isInline(editor, child)
        ) {
          Transforms.unwrapNodes(editor, {
            match: (n) =>
              !Editor.isEditor(n) && Element.isElement(n) && SlateElementType.listItem === n.type,
            mode: 'lowest',
            at: childPath,
          })
          return
        }
      }
    }

    // Fall back to the original `normalizeNode` to enforce other constraints.
    normalizeNode(entry)
  }

  return editor
}
