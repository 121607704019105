import { nanoid } from 'nanoid'
import React, { useContext, useEffect, useMemo } from 'react'

import { ModalComponent, ModalContext, useUpdateModalData } from './ModalContext'
import ModalRender from './ModalRender'

interface IModalMountProps<P, R> {
  component: ModalComponent<P, R>
  userMount?: boolean
  stopPropagation?: boolean
}

const ModalMount = <P, R>({
  component,
  userMount = true,
  stopPropagation,
}: IModalMountProps<P, R>) => {
  const id = useMemo(() => nanoid(), [])
  const updateModal = useUpdateModalData(component)
  const { modals, modalPortal } = useContext(ModalContext)
  const modalData = modals.get(component as ModalComponent<unknown, unknown>)
  const onClose = (result?: R) => {
    updateModal((data) => {
      if (data.deferred) {
        data.deferred.resolve(result)
      }
      return { ...data, open: false }
    })
  }
  useEffect(() => {
    if (userMount) {
      updateModal((data) => {
        if (data.mounted && data.mounted !== id) {
          throw new Error('Modal already mounted')
        }
        if (!data.mounted) {
          return { ...data, mounted: id }
        }
        return data
      })
    }
    return () => {
      if (userMount) {
        updateModal((data) => {
          if (data.mounted === id) {
            return { ...data, mounted: undefined }
          }
          return data
        })
      }
    }
  }, [component])

  const currentModalIndex = [...modals.values()]
    .filter((modal) => modal.open)
    .map((modal) => modal.index)
    .sort((a, b) => b - a)[0]

  const getContainer = () => modalPortal.current

  return (
    <ModalRender
      component={component}
      currentModalIndex={currentModalIndex}
      getContainer={getContainer}
      index={modalData?.index}
      onClose={onClose}
      open={modalData?.open || false}
      params={modalData?.params as P}
      stopPropagation={stopPropagation}
    />
  )
}

export default React.memo(ModalMount) as typeof ModalMount
