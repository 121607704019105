import { chainName } from 'utils'

import { genDataField } from 'components/controls/Field'
import RealtimeField from 'components/controls/RealtimeField'
import Padding from 'components/editor-v3/Editor/Toolbar/Padding'
import { Block } from 'services/Store/Project/types'
import { t } from 'services/Translation'

import { CourseProgressValueFormType } from './CourseProgress.types'

const percentPosition = genDataField<Block>()({
  name: 'percentPosition',
  type: 'select',
  label: t('input.label.value'),
  layout: 'horizontal',
  defaultValue: 'right',
  params: {
    options: [
      { value: 'left', label: t('input.option.left') },
      { value: 'right', label: t('input.option.right') },
      { value: 'hide', label: t('input.option.hide') },
    ],
    fluid: true,
  },
})

const CourseProgressForm: CourseProgressValueFormType = ({ name }) => {
  return (
    <Padding sides>
      <RealtimeField config={chainName(name, percentPosition)} />
    </Padding>
  )
}

export default CourseProgressForm
