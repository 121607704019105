import { MenuPositionType } from '@leenda/editor/lib/brand/types'
import { CONFIG_COURSE, SectionTypeEnum } from '@leenda/editor/lib/elements'
import { useMemo, useCallback, useEffect } from 'react'

import { listToTree, getNodePathById } from 'components/sections/ProjectStructure/utils'
import { Drawer } from 'components/uiKit/Drawer'
import { useElementFontCss, useElementStyleCss } from 'services/Branding/hooks'
import { setSidebar, setSidebarChapter, setSidebarSearch } from 'services/Store/Project/actions'
import { DeviceMode } from 'services/Store/Project/enums'
import { useProjectContext, useProjectDispatch } from 'services/Store/Project/hooks'
import {
  getProgress,
  getProject,
  getSectionId,
  getSections,
  getSidebar,
} from 'services/Store/Project/selectors'
import { useNavigationAction } from 'services/Store/Project/triggers'
import { IBlockMode, Section } from 'services/Store/Project/types'

import { getSectionHasFirstBlock } from '../utils'
import FixedSidebar from './FixedSidebar'

const MIN_WIDTH_SIDEBAR = 360

interface IFixedSidebarContainerProps {
  mode: IBlockMode
}

const FixedSidebarContainer: React.FC<IFixedSidebarContainerProps> = ({ mode }) => {
  const dispatch = useProjectDispatch()
  const { open, search: searchValue = '' } = useProjectContext(getSidebar)
  const sections = useProjectContext(getSections, [
    SectionTypeEnum.chapter,
    SectionTypeEnum.landing,
    SectionTypeEnum.test,
  ])
  const sectionId = useProjectContext(getSectionId)
  const project = useProjectContext(getProject)
  const percent = useProjectContext(getProgress)
  const styles = useElementStyleCss(CONFIG_COURSE.sidebar.type, mode.deviceMode)
  const font = useElementFontCss(CONFIG_COURSE.sidebar.type, mode.deviceMode)
  const onNavigate = useNavigationAction()
  const { chapter } = useProjectContext(getSidebar)
  const isMobile = mode.deviceMode === DeviceMode.mobile
  const sectionNodes = listToTree(sections)
  const { courseNodes, parent, firstSectionIdInParent } = useMemo(() => {
    const [node, parent] = getNodePathById(sectionNodes, chapter?.id || '')
    const children = node ? node.children || [] : sectionNodes
    const firstSectionIdInParent = parent?.children?.find(({ item }) => !item.isChapter)?.item?.id
    return { courseNodes: children, parent, firstSectionIdInParent }
  }, [sectionNodes, chapter])
  const resultSections = sections.filter(
    (e) => !e.isChapter && e.name?.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()),
  )

  const onClose = useCallback(() => dispatch(setSidebar({ open: false })), [dispatch])

  const onSectionSelect = useCallback(
    (paramsSection?: Section) => {
      dispatch(setSidebarSearch({ search: '' }))
      const { id = '', isChapter } = paramsSection || {}

      if (isChapter) {
        dispatch(setSidebarChapter({ chapter: paramsSection || null }))
        const [node] = getNodePathById(sectionNodes, id)
        const foundFirstSection = getSectionHasFirstBlock(node.children || [])
        foundFirstSection && onNavigate({ sectionId: foundFirstSection.id })
      } else {
        onNavigate({ sectionId: id })
      }
    },
    [sectionNodes, dispatch, onNavigate],
  )

  const onSectionBack = useCallback(() => {
    dispatch(setSidebarChapter({ chapter: parent?.item || null }))
    firstSectionIdInParent && onNavigate({ sectionId: firstSectionIdInParent })
  }, [dispatch, firstSectionIdInParent, onNavigate, parent?.item])

  useEffect(() => {
    if (sectionId) {
      const [_restNodes, paren] = getNodePathById(sectionNodes, sectionId)
      dispatch(setSidebarChapter({ chapter: paren?.item || null }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionId])

  if (mode.deviceMode !== DeviceMode.desktop) {
    const sidebarWidth = isMobile ? '100%' : MIN_WIDTH_SIDEBAR

    return (
      <Drawer
        onClose={onClose}
        open={open}
        placement={styles.value.position as MenuPositionType}
        position='absolute'
        showBg={false}
        width={sidebarWidth}
        destroyOnClose
      >
        <FixedSidebar
          courseNodes={courseNodes}
          font={font}
          isMobile={isMobile}
          onSectionBack={onSectionBack}
          onSectionSelect={onSectionSelect}
          percent={percent}
          projectLogoId={project?.company?.logo?.id}
          projectName={project?.name}
          resultSections={resultSections}
          sectionId={sectionId}
          styles={styles}
        />
      </Drawer>
    )
  }

  return (
    <FixedSidebar
      courseNodes={courseNodes}
      font={font}
      isMobile={isMobile}
      onSectionBack={onSectionBack}
      onSectionSelect={onSectionSelect}
      percent={percent}
      projectLogoId={project?.company?.logo?.id}
      projectName={project?.name}
      resultSections={resultSections}
      sectionId={sectionId}
      styles={styles}
    />
  )
}

export default FixedSidebarContainer
