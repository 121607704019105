const envVars = process.env

const isSecured = location.protocol === 'https:'

const rootLocation = `http${isSecured ? 's' : ''}://${location.host}${location.pathname}`

export type AppEnvironment = 'local' | 'dev' | 'static' | 'uat' | 'production' | 'test' | 'release'
const getEnvironment = (): AppEnvironment => {
  if (location.hostname === 'localhost') {
    return 'local'
  } else if (location.hostname.startsWith('dev-release')) {
    return 'release'
  } else if (location.hostname.startsWith('dev')) {
    return 'dev'
  } else if (location.hostname.startsWith('staticdev')) {
    return 'static'
  } else if (location.hostname.startsWith('stage-uat')) {
    return 'uat'
  } else if (location.hostname.startsWith('app')) {
    return 'production'
  } else if (location.hostname.match(/^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/)) {
    return 'test'
  }
  return 'local'
}

const config = {
  io: {
    authEndpoint: envVars.REACT_APP_IO_API_APOLLO_ENDPOINT__AUTH || `${rootLocation}auth/graphql`,
    coreEndpoint: envVars.REACT_APP_IO_API_APOLLO_ENDPOINT__CORE || `${rootLocation}core/graphql`,
    coreEndpointWs:
      envVars.REACT_APP_IO_API_APOLLO_ENDPOINT__CORE___WS ||
      `ws${isSecured ? 's' : ''}://${location.host}${location.pathname}core/graphql`,
    fileEndpoint: envVars.REACT_APP_IO_API_APOLLO_ENDPOINT__FILE || `${rootLocation}files`,
    serverStorageEndpoint: envVars.REACT_APP_IO_API_APOLLO_ENDPOINT__SERVER_STORAGE,
    exportEndpoint: envVars.REACT_APP_EXPORT || `${rootLocation}export`,
    lmsEndpoint: envVars.REACT_APP_IO_API_APOLLO_ENDPOINT__LMS || `${rootLocation}lms`,
  },
  default: {
    locale: envVars.REACT_APP_DEFAULT_LOCALE,
    env: envVars.REACT_APP_ENV,
    isDevelopmentOnly: envVars.REACT_APP_ENV === 'local',
    versionBackend: envVars.REACT_APP_VERSION_BACKEND_BUILD,
    versionFrontend: envVars.CI_COMMIT_TAG || envVars.CI_COMMIT_REF_NAME,
    environment: getEnvironment(),
  },
}

export { config }
