import { useCallback } from 'react'

import { IconButton } from 'components/uiKit/Button'
import { Tooltip } from 'components/uiKit/Dropdown'
import { KitSize } from 'components/uiKit/KitTypes'
import { t } from 'services/Translation'

import { ViewResourceEnum } from './types'

interface ITableView {
  view: ViewResourceEnum
  setView: (newView: ViewResourceEnum) => void
}

const TableView: React.FC<ITableView> = ({ view, setView }) => {
  const handleViewChange = useCallback(
    () => setView(view === ViewResourceEnum.card ? ViewResourceEnum.list : ViewResourceEnum.card),
    [setView, view],
  )
  return (
    <Tooltip
      overlay={
        view === ViewResourceEnum.card ? t('input.option.tableView') : t('input.option.blockView')
      }
    >
      <IconButton
        icon={view === ViewResourceEnum.card ? 'menuBurgerCircle2' : 'imagePicture'}
        name='pageView'
        onClick={handleViewChange}
        size={KitSize.S}
        styleType='ghost'
        value={view}
      />
    </Tooltip>
  )
}

export default TableView
