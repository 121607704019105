type ColorsTypes = {
  yellow: '#F1BF33'
  orange: '#EF5B3D'
  coral: '#D2664A'
  blue: '#3D8BF4'
  purpure: '#736EEF'
  primary: '#36AA55'
  black: '#000000'

  [key: string]: string
}

export const colorsTypes: ColorsTypes = {
  yellow: '#F1BF33',
  orange: '#EF5B3D',
  coral: '#D2664A',
  blue: '#3D8BF4',
  purpure: '#736EEF',
  primary: '#36AA55',
  black: '#000000',
}

export const colorsTypesAccents = {
  black: '#666666',
  primary: 'var(--color-background-secondary)',
  purpure: '#ccd4fd',
  coral: '#bda3ab',
  orange: '#fbad9b',
  yellow: '#f3e6cb',
  green: '#bfe8d5',
} as {
  [key: string]: string
}

export const colors = Object.keys(colorsTypes).map((e) => ({
  accent: colorsTypes[e],
  accentWr: colorsTypesAccents[e],
}))

export const colorsMap = Object.keys(colorsTypes).reduce((result, e) => {
  result[colorsTypes[e]] = {
    accent: colorsTypes[e],
    accentWr: colorsTypesAccents[e],
  }
  return result
}, {} as { [key: string]: { accent: string; accentWr: string } })

export const getEntityStyles = (entity: string | undefined) => ({
  color: (entity && colorsMap[entity] && colorsMap[entity].accent) || entity,
})
