import { MIN_CELL_WIDTH } from '@leenda/editor/lib/elements/elements/Table/constants'
import classNames from 'classnames'
import { useState } from 'react'

import { useTableContext } from '../../TableContext'
import s from './Resizer.module.scss'

type ResizerProps = {
  onResize: (width: number) => void
  width: number
  height: number
  minWidth?: number
}

const Resizer = ({ onResize, width, height, minWidth = MIN_CELL_WIDTH }: ResizerProps) => {
  const { setSelected } = useTableContext()
  const [isResizing, setIsResizing] = useState(false)

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    e.preventDefault()

    setSelected(null)

    const onMouseMove = (moveEvent: MouseEvent) => {
      const newWidth = width + moveEvent.pageX - e.pageX
      document.body.style.cursor = 'col-resize'
      if (newWidth < minWidth) {
        return
      }
      onResize(newWidth)
    }
    const onMouseUp = () => {
      document.removeEventListener('mousemove', onMouseMove)
      document.removeEventListener('mouseup', onMouseUp)
      document.body.style.cursor = 'auto'
      setIsResizing(false)
    }
    document.addEventListener('mousemove', onMouseMove)
    document.addEventListener('mouseup', onMouseUp)
    setIsResizing(true)
  }

  return (
    <div
      className={classNames(s.resizer, { [s.resizing]: isResizing })}
      onClick={(e) => e.stopPropagation()}
      onMouseDown={handleMouseDown}
      style={{ height, left: width - 2 }}
    />
  )
}

export default Resizer
