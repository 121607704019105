import { CustomElement, SlateElementType, htmlToSlate } from '@leenda/rich-text'

type DescendantData = any

export const htmlToSlatePaste = (html: string, type?: SlateElementType): CustomElement[] => {
  const res: DescendantData[] = htmlToSlate(html)
  if (type && type !== SlateElementType.elementDefault) {
    return res
      .flatMap((element) => {
        if ([SlateElementType.numberedList, SlateElementType.bulletedList].includes(element.type)) {
          return element.children as CustomElement[]
        }
        return element
      })
      .map((element) => ({
        ...element,
        type,
      }))
  }
  if (res.length > 1 && res[0].type !== SlateElementType.elementDefault) {
    res.unshift({
      type: SlateElementType.elementDefault,
      children: [
        {
          text: '',
        },
      ],
    })
  }
  if (res[res.length - 1].type !== SlateElementType.elementDefault) {
    res.push({
      type: SlateElementType.elementDefault,
      children: [
        {
          text: '',
        },
      ],
    })
  }
  return res
}
