export enum InsertPlace {
  above,
  below,
}

export enum NodeType {
  shell = 'shell',
  absolute = 'absolute',
  column = 'column',
  row = 'row',
}

export enum FlexBehaviorProps {
  shrink = 'shrink',
  grow = 'grow',
}

export enum ShellsAlignmentDirections {
  start = 'start',
  center = 'center',
  end = 'end',
  normal = 'normal',
}

export enum EditorPanel {
  Navigation = 'Navigation',
  Canvas = 'Canvas',
  Header = 'Header',
  Toolbar = 'Toolbar',
}
