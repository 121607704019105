import { DragDropContext, Droppable, Draggable, DropResult } from '@hello-pangea/dnd'
import { RankingItem } from '@leenda/editor/lib/elements'
import { textToRtValue } from '@leenda/rich-text'
import { useEffect, useMemo, useRef } from 'react'
import { reorder } from 'utils'

import { TestAnswerFeedback } from 'components/editor-v2/EditorElements/decorators/TestAnswerFeedback/TestAnswerFeedback'
import { useElementRootStyles } from 'components/editor-v2/EditorElements/hooks/useElementRootStyles'
import { RichTextViewer } from 'components/form/RichText/RichTextViewer'
// import { RTStyles } from 'components/form/RichText/richText.types'
// import { useElementStyle } from 'services/Branding/hooks'
import { setQuestion } from 'services/Store/Project/actions'
import { PreviewMode, SectionTypeEnum } from 'services/Store/Project/enums'
import { useProjectContext, useProjectDispatch } from 'services/Store/Project/hooks'
import { getBlockState, getSection } from 'services/Store/Project/selectors'
import { notEmpty } from 'utils/notEmpty'
import { shuffle } from 'utils/shuffle'

import { TestRankingElementType } from '../TestRankingElement.types'
import s from './TestRankingElement.module.scss'
import TestRankingItem from './TestRankingItem'

const TestRankingElementPreview: TestRankingElementType = ({
  element,
  mode,
  styles,
  block,
  state,
  font,
}) => {
  const isPdf = mode.previewMode === PreviewMode.pdf
  const { id: elementId, value } = element
  const dispatch = useProjectDispatch()
  const section = useProjectContext(getSection)
  const blockResult = useProjectContext(getBlockState, block?.uuid)?.result
  const wrapperRef = useRef<HTMLDivElement | null>(null)

  // const rtStyles: RTStyles = useElementStyle(DeviceMode.desktop, 'richText', {
  //   [DeviceMode.desktop]: { body__var: styles.text },
  // })

  const isViewMode = mode.previewMode !== PreviewMode.editor
  const isDisabled = Boolean(blockResult || !isViewMode)
  const isVisible = Boolean(
    blockResult &&
      (section?.test?.isValidationVisible ||
        (section?.type === SectionTypeEnum.landing && block?.test?.isResult)),
  )
  // remember first values as right order (maybe better use for it block.test.successRules)
  const validOrderMap = useMemo(
    () =>
      (value?.items || []).reduce(
        (result, item, index) => {
          result[item.value || ''] = index
          return result
        },
        {} as { [id: string]: number },
      ),
    [value?.items],
  )

  // shuffle elements only on first mount(we haven`t state yet) and only in view(preview)
  const valueItems = useMemo<RankingItem[]>(() => {
    if (isViewMode && !isPdf) {
      return (
        state?.value?.map((id) => value.items.find((item) => item.value == id)) ||
        (state?.initialItems as string[])?.map((id) =>
          value.items.find((item) => item.value == id),
        ) ||
        []
      ).filter(notEmpty)
    }
    return value.items
  }, [state, value])

  const onDragEnd = ({ source, destination }: DropResult) => {
    if (!destination || !valueItems.length) {
      return
    }

    const reorderedItems = reorder(valueItems, source.index, destination.index)

    dispatch(
      setQuestion({
        elementId,
        value: {
          value: reorderedItems.map((item) => item.value || ''),
          initialItems: state?.initialItems,
        },
        isReady: true,
        blockId: block?.uuid || '',
      }),
    )
  }

  const rootStyles = useElementRootStyles(styles.root)

  useEffect(() => {
    if (!state?.initialItems) {
      dispatch(
        setQuestion({
          elementId,
          value: {
            initialItems: shuffle(value.items).map((item) => item.value || ''),
          },
          isReady: false,
          blockId: block?.uuid || '',
        }),
      )
    }
  }, [state?.initialItems])

  return (
    <div ref={wrapperRef} style={rootStyles}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='droppable' isDropDisabled={isDisabled}>
          {(provided) => (
            <div className={s.items} ref={provided.innerRef} {...provided.droppableProps}>
              {valueItems.map((rankingItem, index) => (
                <Draggable
                  draggableId={index.toString()}
                  index={index}
                  isDragDisabled={isDisabled}
                  key={index}
                >
                  {(provided, snapshot) => (
                    <div
                      className={s.wrapItem}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <TestAnswerFeedback
                        deviceMode={mode.deviceMode}
                        isTrueAnswer={
                          isDisabled && validOrderMap[rankingItem.value || ''] === index
                        }
                        isVisible={isVisible}
                        showAnswerForCurrentElement={isVisible}
                      >
                        <TestRankingItem index={index} snapshot={snapshot} styles={styles}>
                          <div className={s.text}>
                            <RichTextViewer
                              styles={font}
                              value={rankingItem.label || textToRtValue('')}
                            />
                          </div>
                        </TestRankingItem>
                      </TestAnswerFeedback>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  )
}

export default TestRankingElementPreview
