import { getPercents } from './common'

const GB = Math.pow(1024, 3)
const MB = Math.pow(1024, 2)

const toFixedWithoutRounding = (value: number, accuracy: number): string => {
  const multiplier = Math.pow(10, accuracy)
  return (Math.floor(value * multiplier) / multiplier).toFixed(accuracy)
}

export const getResourcesLabel = (
  value: number,
  availableStorage?: number | string | null,
): string => {
  return `${toFixedWithoutRounding(value / GB, 2)}/${
    availableStorage ? toFixedWithoutRounding(Number(availableStorage) / GB, 2) : '-'
  } GB`
}

export const convertStorage = (storage: number): string => {
  return (storage * GB).toString()
}

export const convertStorageToUi = (storage: string | null) => {
  if (!storage) {
    return
  }

  return +(Number(storage) / GB).toFixed(1)
}

export const showResourcesLimitAlert = (
  current: number | string | null = 0,
  available: number | string | null = 0,
): boolean => {
  if (!available) {
    return false
  }

  const allowedGap = 10 * MB
  const gap = Number(available) - Number(current)

  return gap < allowedGap
}

export const disableUpload = (
  current: number | string | null = 0,
  available: number | string | null = 0,
  throwLimits: boolean | null,
): boolean => {
  if (!available || !throwLimits) {
    return false
  }

  return Number(available) - Number(current) <= 0
}

export const getResourcesMeta = (
  current: number | string | null = 0,
  available: number | string | null = 0,
  trowLimits: boolean | null = false,
) => {
  return {
    resourcesLabel: getResourcesLabel(Number(current), available),
    showResourcesLimitAlert: showResourcesLimitAlert(current, available),
    resourcesPercents: getPercents(Number(current), available),
    disableUpload: disableUpload(current, available, trowLimits),
  }
}
