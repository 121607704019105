import { gql } from 'gql/__generated__'

export const inviteFragment = gql(`
  fragment InviteSchema on Invite {
    id
    expired
    employeeId
    createdAt
  }
`)

export const employeeSmallFragment = gql(`
  fragment EmployeeSmallSchema on Employee {
    id
    role
    permissionGroups
    isArchived
    updatedAt
    status
    kuserId
    companyId
    kUser {
      ...kUserIdentificationSchema
    }
  }
`)

export const employeeFragment = gql(`
  fragment EmployeeSchema on Employee {
    ...EmployeeSmallSchema
  }
`)

export const employeeInfoFragment = gql(`
  fragment EmployeeInfoSchema on Employee {
    description
    status
    registrated
    edited
    phone
    adminInfo
  }
`)

export const employeeListFragment = gql(`
  fragment EmployeeListSchema on Employee {
    ...EmployeeSchema
    role
    companyId
    ownedProjects {
      id
      name
      owner {
        id
        kUser {
          name
        }
      }
    }
    isArchived
    status
    kuserId
    participant {
      id
      employee {
        id
      }
      role
    }
    kUser {
      ...kUserInfoSchema
    }
    projects {
      id
    }
    tags
    updatedAt
    createdAt
    invite {
      ...InviteSchema
    }
  }
`)

const _EmployeeGetTaskInfoFragment = gql(`
  fragment EmployeeGetTaskInfo on Employee {
    id
    projects {
      id
      name
    }
    employeeTasks {
      id
    }
  }
`)

export const employeesGetAllQuery = gql(`
  query EmployeesAll($companyId: String!, $search: EmployeeSearch) {
    data: employeesAll(companyId: $companyId, search: $search) {
      employees {
        ...EmployeeListSchema
      }
      limit
      offset
      total
    }
  }
`)

export const employeesGetByIdQuery = gql(`
  query EmployeesGetById($id: String!) {
    data: employeesGetById(id: $id) {
      ...EmployeeListSchema
      ...EmployeeInfoSchema
    }
  }
`)

export const updateEmployeeNotification = gql(`
  mutation UpdateNotificationSettings($data: NotificationSettingsInput!) {
    data: updateNotificationSettings(data: $data) {
      id
      notificationSettings {
        notificationGroup
        skipEmail
        skipSystem
      }
    }
  }
`)

export const employeeNotificationSettings = gql(`
  query EmployeeNotificationSettings($id: String!) {
    data: employeesGetById(id: $id) {
      id
      notificationSettings {
        notificationGroup
        skipEmail
        skipSystem
      }
    }
  }
`)

export const setEmployeesPrivileges = gql(`
  mutation SetEmployeesPrivileges($data: EmployeePrivilegesInput!) {
    data: setEmployeesPrivileges(data: $data) {
      id
      role
      permissionGroups
    }
  }
`)

export const employeesForceDeleteById = gql(`
  mutation EmployeesForceDeleteById($payload: EmployeesForceDeleteByIdInput!) {
    data: employeesForceDeleteById(payload: $payload) {
      data {
        ids
      }
      errors {
        ...ErrorSchema
      }
    }
  }
`)

export const employeeGetTaskInfo = gql(`
  query EmployeeGetTaskInfo($employeeIds: [String!]!) {
    data: employeeGetTaskInfo(employeeIds: $employeeIds) {
      ...EmployeeGetTaskInfo
    }
  }
`)

export const inviteEmployee = gql(`
  mutation EmployeesSendInvite($companyId: String!, $payload: InviteInput!) {
    data: inviteEmployee(payload: $payload, companyId: $companyId) {
      data {
        employeeId
        status
      }
      errors {
        ...ErrorSchema
      }
    }
  }
`)

export const restoreEmployees = gql(`
  mutation RestoreEmployees($companyId: String!, $payload: RestoreInput!) {
    data: restoreEmployee(companyId: $companyId, payload: $payload) {
      data {
        employeeId
      }
      errors {
        ...ErrorSchema
      }
    }
  }
`)

export const employeesUpdateById = gql(`
  mutation EmployeesUpdateById($id: String!, $data: EmployeeUpdateInput!) {
    data: employeesUpdateById(id: $id, data: $data) {
      data {
        employee {
          ...EmployeeListSchema
          ...EmployeeInfoSchema
        }
      }
      errors {
        ...ErrorSchema
      }
    }
  }
`)

export const employeesGetKByKUserId = gql(`
  query EmployeesGetKByKUserId($id: String!, $companyId: String!) {
    data: employeesGetKByKUserId(id: $id, companyId: $companyId) {
      ...EmployeeListSchema
      ...EmployeeInfoSchema
    }
  }
`)

export const employeesSubscribe = gql(`
  subscription EmployeesSubscribe($companyId: String!) {
    data: employee(companyId: $companyId) {
      type
      employee {
        ...EmployeeInfoSchema
        ...EmployeeListSchema
      }
    }
  }
`)

export const resendInviteMutation = gql(`
  mutation ResendInvite($companyId: String!, $inviteIds: [String!]!) {
    data: resendInvite(companyId: $companyId, inviteIds: $inviteIds) {
      id
      expired
      employeeId
      createdAt
    }
  }
`)
