import React from 'react'

import ControlItem from 'components/editor-v3/cource/layout/controls/ControlItem'
import EditableProMode from 'components/editor-v3/cource/layout/controls/ElementControls/EditableProMode'
import Separator from 'components/editor-v3/cource/layout/controls/Separator/Separator'
import { AppMode } from 'services/Store/Project/enums'
import { useProjectContext } from 'services/Store/Project/hooks'
import { getEditorMode } from 'services/Store/Project/selectors'
import { t } from 'services/Translation'

import { TestFeedbackEditorActionsType } from './TestFeedbackTypes'

const ACTIONS = {
  CHANGE_STATE: {
    icon: 'otherSwitch',
    label: t('elements.testAnswerFeedback.tooltip.switch.label'),
    value: 'CHANGE_STATE',
  },
  SHOW: {
    icon: 'eyeOpenEye',
    label: t('elements.testAnswerFeedback.tooltip.eyeOpenEye.label'),
    value: 'SHOW',
  },
  HIDE: {
    icon: 'eyeCloseEye',
    label: t('elements.testAnswerFeedback.tooltip.eyeCloseEye.label'),
    value: 'HIDE',
  },
} as const

const TestFeedbackEditorActions: TestFeedbackEditorActionsType = ({
  element,
  state,
  mode,
  customMode,
  setState,
  setAppMode,
}) => {
  const editorMode = useProjectContext(getEditorMode)
  const isValidation = element.value.type === 'validation'
  const { hidden, isValid } = state || {}

  const change = () => setState({ ...state, isValid: !isValid })
  const toggle = () => setState({ ...state, hidden: !hidden })

  return (
    <>
      {editorMode === AppMode.pro && (
        <>
          <EditableProMode customMode={customMode} mode={mode} setAppMode={setAppMode} />
          <Separator />
        </>
      )}
      <ControlItem handler={toggle} option={hidden ? ACTIONS.HIDE : ACTIONS.SHOW} />
      {isValidation && !hidden && <ControlItem handler={change} option={ACTIONS.CHANGE_STATE} />}
    </>
  )
}

export default TestFeedbackEditorActions
