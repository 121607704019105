import { SCHEMA } from '@leenda/editor/lib/brand'

import { genFormSchema, FONT_SCHEMA_FIELDS } from 'services/Branding/genFormSchema'
import { t } from 'services/Translation'

export const STYLE_FORM_SCHEMA = genFormSchema(SCHEMA.testFeedback.style, {
  common: {
    META: { hidden: true },
    borderRadius: {},
  },
  infoIcon: {
    META: { label: t('elements.testFeedback.tags.infoPanel') },
    backgroundImage: { label: t('elements.testFeedback.schema.infoIcon') },
  },
  infoPanel: {
    META: { hidden: true },
    backgroundColor: {},
    borderStyle: {},
    borderColor: {},
    borderWidth: {},
  },
  successIcon: {
    META: { label: t('elements.testFeedback.tags.successPanel') },
    backgroundImage: { label: t('elements.testFeedback.schema.successIcon') },
  },
  successPanel: {
    META: { hidden: true },
    backgroundColor: {},
    borderStyle: {},
    borderColor: {},
    borderWidth: {},
  },
  failIcon: {
    META: { label: t('elements.testFeedback.tags.failPanel') },
    backgroundImage: { label: t('elements.testFeedback.schema.failIcon') },
  },
  failPanel: {
    META: { hidden: true },
    backgroundColor: {},
    borderStyle: {},
    borderColor: {},
    borderWidth: {},
  },
})

export const FONT_FORM_SCHEMA = genFormSchema(SCHEMA.testFeedback.font, FONT_SCHEMA_FIELDS)
