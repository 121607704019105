import { SidebarFontSchemaType, SidebarSchemaType } from '@leenda/editor/lib/brand'
import { CourseStructureItem } from '@leenda/editor/lib/elements'
import React from 'react'

import { ElementFontCss, ElementStyleCss } from 'services/Branding/types'
import { t } from 'services/Translation'

import { StructureItemCourse } from '../StructureItemCourse'
import s from './StructureSearch.module.scss'

interface ISearchResultProps {
  sections: CourseStructureItem[]
  searchValue: string
  onSelect: (sectionId: string) => void
  styles: ElementStyleCss<SidebarSchemaType>
  font: ElementFontCss<SidebarFontSchemaType>
}

const StructureSearchResult = ({
  sections,
  searchValue,
  onSelect,
  styles,
  font,
}: ISearchResultProps) => {
  const resultSections = sections.filter(
    (e) => !e.isChapter && e.name?.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()),
  )

  return (
    <div>
      <div className={s.title}>
        {t('input.label.result')}
        <span className={s.searchNum}>{resultSections.length}</span>
      </div>
      <div className={s.resultsContent}>
        {resultSections.map((item) => {
          return (
            <div className={s.resultItem} key={item.id}>
              <StructureItemCourse
                font={font}
                item={item}
                onSelect={onSelect}
                searchValue={searchValue}
                styles={styles}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default StructureSearchResult
