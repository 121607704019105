import cn from 'classnames'
import querystring from 'query-string'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet-async'

import { PLATFORM_CONFIG } from '../../platform/platformComfig'
import s from './Banner/LicenseExpiration/LicenseExpiration.module.scss'

export const useContactSales = ({ email, name }: { email: string; name: string }) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [firstName, ...lastNameParts] = name.split(' ')
  const open = () => setIsModalVisible(true)

  const query = querystring.stringify({
    email,
    firstName,
    lastName: lastNameParts.join(' '),
  })

  const mount = isModalVisible ? (
    <div className={s.contactSales}>
      <div className={s.overlay} onClick={() => setIsModalVisible(false)} />
      <div
        className={cn('meetings-iframe-container', s.content)}
        data-src={`${PLATFORM_CONFIG.hubSpotContactSalesPath}&${query}`}
      />
      <Helmet>
        <script
          src='https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js'
          type='text/javascript'
        />
      </Helmet>
    </div>
  ) : null
  return {
    mount,
    open,
  }
}
