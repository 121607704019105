import { TableFontSchemaType, TableSchemaType } from '@leenda/editor/lib/brand'
import { Cell } from '@leenda/editor/lib/elements'
import { CONFIG_ELEMENTS } from '@leenda/editor/lib/elements'
import { textToRtValue } from '@leenda/rich-text'

import { DEMO_MODE } from 'services/Store/Project/constants'
import { t } from 'services/Translation'

import DemoWrapper from '../../decorators/DemoWrapper'
import { IElementDemoProps } from '../../elements.types'
import Element from './TableElement'

const demoCells: Cell[][] = [
  [
    { value: textToRtValue(t('elements.table.value.cell', { number: 1 })) },
    { value: textToRtValue(t('elements.table.value.cell', { number: 2 })) },
  ],
  [
    { value: textToRtValue(t('elements.table.value.cell', { number: 3 })) },
    { value: textToRtValue(t('elements.table.value.cell', { number: 4 })) },
  ],
]

const demoCellsWithHeader: Cell[][] = [
  [
    { value: textToRtValue(t('elements.table.value.header', { number: 1 })) },
    { value: textToRtValue(t('elements.table.value.header', { number: 2 })) },
  ],
  [
    { value: textToRtValue(t('elements.table.value.header', { number: 3 })) },
    { value: textToRtValue(t('elements.table.value.cell', { number: 1 })) },
  ],
]

const HeadersDemo: React.FC<IElementDemoProps<TableSchemaType, TableFontSchemaType>> = ({
  styles,
  fonts,
}) => (
  <DemoWrapper center>
    <Element
      element={{
        id: `${CONFIG_ELEMENTS.table.type}-demo`,
        type: CONFIG_ELEMENTS.table.type,
        value: {
          ...CONFIG_ELEMENTS.table.defaultValue,
          headerRow: true,
          headerColumn: true,
          cells: demoCellsWithHeader,
        },
        font: {},
        style: {},
      }}
      font={fonts}
      mode={DEMO_MODE}
      styles={styles}
    />
  </DemoWrapper>
)

const DefaultDemo: React.FC<IElementDemoProps<TableSchemaType, TableFontSchemaType>> = ({
  styles,
  fonts,
}) => (
  <DemoWrapper center>
    <Element
      element={{
        id: `${CONFIG_ELEMENTS.table.type}-demo`,
        type: CONFIG_ELEMENTS.table.type,
        value: { ...CONFIG_ELEMENTS.table.defaultValue, cells: demoCells },
        font: {},
        style: {},
      }}
      font={fonts}
      mode={DEMO_MODE}
      styles={styles}
    />
  </DemoWrapper>
)

export default [DefaultDemo, HeadersDemo]
