import { SlateElementType, SlateMark } from '@leenda/rich-text'
import * as R from 'ramda'
import React, { useCallback, useEffect, useId, useMemo } from 'react'

import {
  getFormat,
  getOnUpdateFormat,
  getSetForm,
  useAppTextSelector,
} from 'components/AppText/useAppTextSelector'
import { IconButton } from 'components/uiKit/Button'
import Checkbox from 'components/uiKit/Checkbox'
import Dropdown, { Tooltip } from 'components/uiKit/Dropdown'
import { useDropDownContext } from 'components/uiKit/Dropdown/context'
import Icon from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'
import Select from 'components/uiKit/Select'
import { t } from 'services/Translation'

import { useSelectionRect } from '../../useSelectionRect'
import CheckboxTool from '../components/CheckboxTool'
import OtherSettings from '../components/OtherSettings/OtherSettings'
import SymbolItem from '../components/SymbolItem'
import { TEXT_TYPE_OPTIONS } from '../toolbarOptions'
import { MARKS, symbols } from '../toolbarOptions'
import s from './DefaultForm.module.scss'

const calcTop = (formRef: { width: number; height: number }, _anchor: DOMRect, focus: DOMRect) => {
  return focus.top - 8 - formRef?.height || 0
}

const calcLeft = (formRef: { width: number; height: number }, anchor: DOMRect, focus: DOMRect) => {
  const focusLeft = focus.left - 0
  const focusTop = focus.top - 0
  const anchorLeft = anchor.left - 0
  const anchorTop = anchor.top - 0
  const toolbarWidth = formRef?.width || 0
  const left =
    focusTop < anchorTop
      ? focusLeft
      : focusTop > anchorTop
        ? focusLeft - toolbarWidth
        : focusLeft < anchorLeft || focusTop < anchorTop
          ? focusLeft
          : focusLeft - toolbarWidth

  return R.clamp(0, window.innerWidth, left)
}

interface IDefaultFormProps {
  full?: boolean
}

const DefaultForm = React.forwardRef<HTMLDivElement, IDefaultFormProps>(({ full }, outerRef) => {
  const id = useId()
  const ref = outerRef as React.MutableRefObject<HTMLDivElement>
  const { parentContext } = useDropDownContext()
  const { anchor, focus } = useSelectionRect()

  const format = useAppTextSelector(getFormat)
  const value = TEXT_TYPE_OPTIONS.find((item) => format[item.value])?.value
  const marks = useMemo(() => (full ? MARKS : [SlateMark.bold, SlateMark.italic]), [full])

  const setForm = useAppTextSelector(getSetForm)
  const onUpdateFormat = useAppTextSelector(getOnUpdateFormat)
  const onLinkClick = useCallback(() => setForm('link'), [setForm])

  const update = useCallback(() => {
    if (ref.current && anchor && focus) {
      ref.current.style.left = `${calcLeft(ref.current.getBoundingClientRect(), anchor, focus)}px`
      ref.current.style.top = `${calcTop(ref.current.getBoundingClientRect(), anchor, focus)}px`
    }
  }, [anchor, focus, ref])

  useEffect(() => update(), [update])

  useEffect(() => {
    ref.current && parentContext?.registerSubPopup(id, ref.current)
  })

  if (!anchor || !focus) {
    return null
  }

  return (
    <div className={s.root} ref={ref} tabIndex={-1}>
      {full && (
        <>
          <Select
            name='textType'
            onChange={onUpdateFormat}
            options={TEXT_TYPE_OPTIONS}
            size={KitSize.S}
            styleType='ghost'
            value={value || SlateElementType.elementDefault}
            fluid
          />
          <div className={s.delimiter} />
        </>
      )}
      <div className={s.group}>
        {marks.map((mark) => (
          <CheckboxTool key={mark} name={mark} />
        ))}
      </div>
      <div className={s.delimiter} />
      <Checkbox name='link' onChange={onLinkClick} value={!!format.inline} hideCheckbox>
        <Icon name='link2' size={KitSize.S} />
      </Checkbox>
      {full && (
        <>
          <div className={s.delimiter} />
          <Dropdown
            overlay={
              <div className={s.symbols}>
                {symbols.map((symbol, index) => (
                  <SymbolItem key={index} name={symbol} />
                ))}
              </div>
            }
            placement='bottomLeft'
            trigger={['click']}
          >
            <Tooltip
              overlay={t('uiKit.tooltip.symbols')}
              placement='top'
              trigger={['hoverWithoutContent']}
            >
              <IconButton icon='builderSymbol' name='symbol' size={KitSize.S} styleType='ghost' />
            </Tooltip>
          </Dropdown>
          <div className={s.delimiter} />

          <Dropdown overlay={<OtherSettings />} placement='bottomLeft'>
            <IconButton icon='iconsOtherMore' name='otherMore' size={KitSize.S} styleType='ghost' />
          </Dropdown>
        </>
      )}
    </div>
  )
})

DefaultForm.displayName = 'DefaultForm'

export default DefaultForm
