import React from 'react'

import { nodeWithDeviceMode } from 'components/editor-v3/context/EditorContext/selectors/block'
import { isAbsoluteNode } from 'components/editor-v3/types/data.guards'
import { NodeType } from 'components/editor-v3/types/date.enums'
import { PreviewMode } from 'services/Store/Project/enums'

import { FCNode } from '../Node/types'
import EditorAbsolute from './EditorAbsolute'
import PreviewAbsolute from './PreviewAbsolute'

const BLOCK_COMPONENTS: Partial<Record<PreviewMode, FCNode<NodeType.absolute>>> = {
  editor: EditorAbsolute,
}

const AbsoluteContainer: FCNode = React.forwardRef((props, ref) => {
  const { node, mode } = props
  const { style } = nodeWithDeviceMode(node, mode.deviceMode)

  const Component = BLOCK_COMPONENTS[mode.previewMode] || PreviewAbsolute
  if (isAbsoluteNode(node)) {
    return <Component {...props} node={node} ref={ref} style={{ ...style }} />
  }
  return null
})

AbsoluteContainer.displayName = 'AbsoluteContainer'

export default React.memo(AbsoluteContainer)
