import { useLocalStorageState, useUpdateEffect } from 'ahooks'
import * as R from 'ramda'
import { useMemo } from 'react'

import {
  EDITOR_ACCENT_COLOR,
  EDITOR_BACKGROUND_COLOR,
  EDITOR_DIVIDER_FILL_MODE,
  EDITOR_NAV_WIDTH_PRO_MODE,
  EDITOR_COMMENTS_SHOW,
  EDITOR_TASKS_SHOW,
} from 'constants/localStorageKeys'

import { DEFAULT_SETTINGS } from './constants'
import { useProjectContext } from './hooks'
import { getSettings } from './selectors'
import { IEditorSettings } from './types'

export const useEditorSettings = () => {
  const [backgroundColor, setBackgroundColor] = useLocalStorageState(EDITOR_BACKGROUND_COLOR)
  const [accentColor, setAccentColor] = useLocalStorageState(EDITOR_ACCENT_COLOR)
  const [divider, setDivider] = useLocalStorageState(EDITOR_DIVIDER_FILL_MODE)
  const [navWidth, setNavWidth] = useLocalStorageState(EDITOR_NAV_WIDTH_PRO_MODE)
  const [comments, setComments] = useLocalStorageState(EDITOR_COMMENTS_SHOW)
  const [tasks, setTasks] = useLocalStorageState(EDITOR_TASKS_SHOW)
  const stateSettings = useProjectContext(getSettings)

  const settings = useMemo(
    () =>
      R.mergeRight(
        DEFAULT_SETTINGS,
        R.filter((i) => !R.isNil(i), {
          backgroundColor,
          accentColor,
          divider,
          navWidth,
          comments,
          tasks,
        }),
      ) as IEditorSettings,
    [backgroundColor, accentColor, divider, navWidth, comments, tasks],
  )

  useUpdateEffect(() => {
    const { accentColor, backgroundColor, divider, navWidth, comments, tasks } = stateSettings
    setBackgroundColor(backgroundColor)
    setAccentColor(accentColor)
    setDivider(divider)
    setNavWidth(navWidth)
    setComments(comments)
    setTasks(tasks)
  }, [
    stateSettings.accentColor,
    stateSettings.backgroundColor,
    stateSettings.divider,
    stateSettings.navWidth,
    stateSettings.comments,
    stateSettings.tasks,
  ])
  return settings
}
