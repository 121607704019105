import { useCallback, useMemo, useRef } from 'react'
import React from 'react'

import { deleteMenuItem, editMenuItem } from 'components/comments/actions'
import {
  Actions,
  ICommentTextRef,
  NameRow,
  CommentText,
} from 'components/comments/comment/components'
import { CommentActionUiEnum, CommentMock } from 'components/comments/types'
import Employee, { AccountMock } from 'components/uiKit/Employee'
import { useHover } from 'components/uiKit/KitUtils'
import { IMenuOption } from 'components/uiKit/Menu'
import { useCommentPermissions } from 'services/Permission/PermissionContext'
import { scrollProps } from 'services/Scroll/ScrollService'
import { selectCurrentEmployee } from 'services/Store/Users/selectors'
import { useAppSelector } from 'services/Store/hooks'

import s from './EditorComment.module.scss'

interface IEditorCommentProps {
  comment: CommentMock
  mentions: AccountMock[]
}

const EditorComment: React.FC<IEditorCommentProps> = ({ comment, mentions }) => {
  const permissions = useCommentPermissions(comment)
  const me = useAppSelector(selectCurrentEmployee) || null
  const refText = useRef<ICommentTextRef>(null)
  const [showActions, handlers] = useHover()

  const options = useMemo(
    () => [
      editMenuItem({ comment, permissions, me }),
      ...(comment.parentId ? [deleteMenuItem({ comment, permissions, me })] : []),
    ],
    [comment, permissions, me],
  )

  const handleClick = useCallback((item: IMenuOption<CommentActionUiEnum>) => {
    if (item.value === CommentActionUiEnum.EDIT) {
      refText.current?.edit?.()
    }
  }, [])

  return (
    <div {...scrollProps(comment.id)} {...handlers} className={s.root}>
      <div className={s.title}>
        <Employee employee={comment.createdBy} styleType='avatar' />
        <NameRow comment={comment} />
        <Actions comment={comment} onClick={handleClick} options={options} show={showActions} />
      </div>
      <div className={s.content}>
        <CommentText comment={comment} mentions={mentions} ref={refText} />
      </div>
    </div>
  )
}

export default React.memo(EditorComment)
