import { CONFIG_ELEMENTS, ImageElementValue } from '@leenda/editor/lib/elements'
import { chainName, getParent, urlValidation } from 'utils'

import { genDataField } from 'components/controls/Field'
import RealtimeField from 'components/controls/RealtimeField'
import Padding from 'components/editor-v3/Editor/Toolbar/Padding'
import { KitSize } from 'components/uiKit/KitTypes'
import { Block } from 'services/Store/Project/types'
import { t } from 'services/Translation'

import { ImageValueFormType } from './ImageEl.types'

const genField = genDataField<Block>()

export const sourceType = genField({
  label: t('input.label.source'),
  name: 'sourceType',
  type: 'select',
  layout: 'horizontal',
  params: {
    options: [
      { value: 'file', label: t('input.option.file') },
      { value: 'url', label: t('input.option.link') },
    ],
    size: KitSize.S,
    fluid: true,
  },
  effect: (_, changeData) => {
    const { value: sourceType, name } = changeData
    const { parentName } = getParent(name)

    return {
      ...changeData,
      name: parentName,
      value: { ...CONFIG_ELEMENTS.image.defaultValue, sourceType },
    }
  },
})

export const url = genField({
  name: 'url',
  type: 'file',
  rules: [
    { required: true, message: t('input.placeholder.pasteLink') },
    {
      validator: (rule, value) =>
        typeof value === 'string' ? urlValidation(rule, value) : undefined,
    },
  ],
  params: (block, { name }) => {
    const { parent } = getParent<ImageElementValue>(name, block)

    return {
      hidden: parent?.sourceType !== 'url',
      placeholder: t('input.placeholder.pasteLink'),
      size: KitSize.S,
      fileType: 'image',
      source: 'url',
      preview: true,
    }
  },
  effect: (block, changeData) => {
    const { value: url, name } = changeData
    const { parentName } = getParent(name)
    const { parent } = getParent<ImageElementValue>(name, block)

    return {
      ...changeData,
      name: parentName,
      value: {
        ...CONFIG_ELEMENTS.image.defaultValue,
        sourceType: 'url',
        url,
        fileType: 'image',
        blackout: parent?.blackout,
      },
    }
  },
})

const image = genField({
  name: 'image',
  type: 'file',
  layout: 'horizontal',
  params: (block, { name }) => {
    const { parent } = getParent<ImageElementValue>(name, block)
    return {
      hidden: parent?.sourceType !== 'file',
      fileType: 'image',
      nullable: true,
      preview: true,
    }
  },
})

const zoomable = genField({
  name: 'zoomable',
  label: t('elements.image.form.zoomable'),
  type: 'segmented',
  layout: 'horizontal',
  defaultValue: false,
})

export const accessibility = genField({
  name: 'image.accessibility',
  type: 'text',
  label: t('input.label.accessibility'),
  layout: 'horizontal',
  params: (block, { name }) => {
    const { parent } = getParent<ImageElementValue>(name, block, 2)
    return {
      placeholder: t('input.placeholder.altText'),
      hidden: !parent?.image?.id && !parent?.url,
    }
  },
})

export const blackout = genField({
  name: 'blackout',
  type: 'color',
  label: t('input.label.blackout'),
  layout: 'horizontal',
})

const ImageElForm: ImageValueFormType = ({ name }) => {
  return (
    <Padding sides>
      <RealtimeField config={chainName(name, sourceType)} />
      <RealtimeField config={chainName(name, url)} />
      <RealtimeField config={chainName(name, image)} />
      <RealtimeField config={chainName(name, zoomable)} />
      <RealtimeField config={chainName(name, blackout)} />
      <RealtimeField config={chainName(name, accessibility)} />
    </Padding>
  )
}

export default ImageElForm
