import { gql } from 'gql/__generated__'

export const pfdBuildSubscription = gql(`
  subscription SubscribePdf($projectId: ID!) {
    data: projectExportPdf(projectId: $projectId) {
      ...PdfBuildSchema
    }
  }
`)

export const scormBuildSubscription = gql(`
  subscription Scorm($projectId: ID!) {
    data: projectExportScorm(projectId: $projectId) {
      ...ScormBuildSchema
    }
  }
`)
