import { CardFontSchemaType, CardSchemaType } from '@leenda/editor/lib/brand'
import { CONFIG_ELEMENTS, CardElementValue } from '@leenda/editor/lib/elements'
import { textToRtValue } from '@leenda/rich-text'

import { DEMO_MODE } from 'services/Store/Project/constants'
import { t } from 'services/Translation'

import DemoWrapper from '../../decorators/DemoWrapper'
import { IElementDemoProps } from '../../elements.types'
import Element from './CardElement'

const DEMO_VALUE: CardElementValue = {
  front: {
    label: textToRtValue(t('elements.card.value.frontCard')),
    image: null,
  },
  back: {
    label: textToRtValue(t('elements.card.value.backCard')),
    image: null,
  },
}

const CardDemo: React.FC<IElementDemoProps<CardSchemaType, CardFontSchemaType>> = ({
  styles,
  fonts,
}) => (
  <DemoWrapper center>
    <Element
      element={{
        id: `${CONFIG_ELEMENTS.card.type}-demo`,
        type: CONFIG_ELEMENTS.card.type,
        value: DEMO_VALUE,
        style: {},
        font: {},
      }}
      font={fonts}
      mode={DEMO_MODE}
      styles={styles}
    />
  </DemoWrapper>
)

export default CardDemo
