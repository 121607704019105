export enum DataFallbackTypeEnum {
  notFound = 'notFound',
  accessDenied = 'accessDenied',
  commonError = 'commonError',
  notification = 'notification',
}

export type BackUrlType = { path: string; params?: Record<string, string>; search?: string }

export type DataFallbackConfigType = {
  backUrl?: BackUrlType | null
  backCb?: () => void
  type?: DataFallbackTypeEnum
  title?: string
  position?: 'static' | 'fixed'
  linkLabel?: string
  textNotification?: string
  view?: 'modal' | 'page'
}
