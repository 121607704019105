import moment from 'moment'

export const dateFormat = 'DD.MM.YYYY'
export const dateFormatTable = 'DD MMM YYYY'
export const dateFormatTime = 'DD.MM.YYYY HH:mm:ss'
export const dateFormatShort = 'MMM Do'
export const dateMathFormat = 'LL'
export const timeFormat = 'HH:mm:ss'
export const MAX_TABLE_SIZE = 250
export const DATE_NOTIFICATION = 'DD MMMM Y, HH:mm'
export const datePickerFormat = 'ddd MMM DD YYYY HH:mm:ss [GMT]Z (z)'

export const VERSION_DATE_TIME = 'D MMM Y, HH:mm'

export function disabledDate(current: moment.Moment) {
  // Can not select days before today and today
  return (current && current < moment().startOf('day')) || false
}

export const getTimeDiff = (timeStart: Date, timeEnd: Date) => {
  return moment
    .utc(moment(timeEnd, timeFormat).diff(moment(timeStart, timeFormat)))
    .format(timeFormat)
}
