import { VideoElementValue } from '@leenda/editor/lib/elements'
import { FileUsageTypeEnum } from '@leenda/editor/lib/files'
import { useMount } from 'ahooks'
import React, { useCallback, useMemo, useState } from 'react'
import { FileTypeEnum } from 'utils'

import MediaPlayer from 'components/MediaPlayer'
import { useElementRootStyles } from 'components/editor-v2/EditorElements/hooks/useElementRootStyles'
import { useProjectT } from 'services/ProjectLabels'
import { completeElement, setScrollWait } from 'services/Store/Project/actions'
import { PreviewMode } from 'services/Store/Project/enums'
import { useProjectContext, useProjectDispatch } from 'services/Store/Project/hooks'
import { getIsInteractiveMode } from 'services/Store/Project/selectors'
import { getFileByUsage, getImageByUsage } from 'utils/files'
import { prepareUrl } from 'utils/websiteValidation'

import s from './Media.module.scss'
import { MediaElementType } from './types'

const Element: MediaElementType = ({
  element: { value, type, id },
  mode,
  styles,
  block,
  onChange,
  font,
}) => {
  const pt = useProjectT()
  const {
    sourceType,
    autoPlay,
    controls,
    repeat,
    volume,
    duration,
    timePoints,
    blackout,
    playbackRate,
    file,
    coverImage,
    percent = 0,
  } = value
  const dispatch = useProjectDispatch()
  const isInteractiveMode = useProjectContext(getIsInteractiveMode)
  const { url, cover } = useMemo(() => {
    const { url } = value
    const mediaFile = getFileByUsage(
      file,
      type === 'audio' ? FileUsageTypeEnum.audio : FileUsageTypeEnum.video,
    )
    const coverFile = getImageByUsage(coverImage)

    return {
      url: sourceType === 'url' ? prepareUrl(url || '') : mediaFile?.path || null,
      cover: coverFile?.path,
    }
  }, [file?.id, value.url, coverImage?.id])

  const breakpoints = file?.params?.breakpoints
  const [isViewed, setIsViewed] = useState(false)

  const rootStyles = useElementRootStyles(styles.root)

  const onDuration = useCallback(
    (duration: number) => {
      if (value.duration === null) {
        onChange &&
          onChange({
            ...value,
            duration,
            //@ts-ignore
            file: { ...file, params: { ...file?.params, breakpoints: [0, duration] } },
            timePoints: [],
          })
      }
    },
    [value, onChange, file],
  )

  const onPlay = useCallback(() => {
    if (percent === 0) {
      const payload = { elementId: id, blockId: block?.uuid || '', incomplete: false }
      isInteractiveMode && dispatch(completeElement(payload))
    }
  }, [dispatch, id, block?.uuid, isInteractiveMode])

  const handleOnReady = useCallback(() => {
    dispatch(setScrollWait({ id, ready: true }))
  }, [dispatch, id])

  const handleProgress = useCallback(
    (progressPercent: number) => {
      if (!isViewed && progressPercent >= percent && percent > 0) {
        setIsViewed(true)
        const payload = { elementId: id, blockId: block?.uuid || '', incomplete: false }
        if (isInteractiveMode) {
          dispatch(completeElement(payload))
        }
      }
    },
    [isViewed, percent, id, block?.uuid, isInteractiveMode, dispatch],
  )

  useMount(() => {
    url && url !== '//' && dispatch(setScrollWait({ id, ready: false }))
  })

  return (
    <div className={s.root} style={rootStyles}>
      <MediaPlayer
        accessibility={
          type === 'video' ? (value as VideoElementValue)?.coverImage?.accessibility : undefined
        }
        autoPlay={autoPlay && [PreviewMode.preview, PreviewMode.scorm].includes(mode.previewMode)}
        blackout={blackout}
        controls={controls}
        cover={cover}
        duration={(breakpoints ? breakpoints[1] - breakpoints[0] : duration) || 0}
        fonts={font}
        isEditor={mode.previewMode === PreviewMode.editor}
        loop={repeat}
        onDuration={onDuration}
        onPlay={onPlay}
        onProgress={handleProgress}
        onReady={handleOnReady}
        playbackRate={playbackRate}
        start={breakpoints?.[0] || 0}
        styles={styles}
        timePoints={timePoints}
        type={type === FileTypeEnum.AUDIO ? FileTypeEnum.AUDIO : FileTypeEnum.VIDEO}
        url={url}
        volume={volume / 100}
        volumeLabel={pt('elements.media.volume')}
        full
      />
    </div>
  )
}

export default Element
