import { CustomElement, CustomText, RichTextValue } from '@leenda/rich-text'
import lodash from 'lodash'
import { Editor } from 'slate'

import { SlateInternal } from '../constants'
import { CustomEditor } from '../slate'

export const setPseudoSelection = (editor: CustomEditor) => {
  const selection = editor.selection
  const hasSelection = !lodash.isEqual(selection?.anchor?.offset, selection?.focus?.offset)
  if (hasSelection) {
    Editor.addMark(editor, SlateInternal.pseudoSelection, true)
    editor.pseudoSelection = selection
  }
}

export const removePseudoSelection = (editor: CustomEditor) => {
  const pseudoSelection = editor.pseudoSelection
  if (pseudoSelection) {
    editor.pseudoSelection = undefined
    Editor.removeMark(editor, SlateInternal.pseudoSelection)
  }
}

const isCustomElement = (node: CustomElement | CustomText): node is CustomElement =>
  'children' in node

export const deserializePseudoSelection = (value: RichTextValue): RichTextValue =>
  value.map(
    (node) =>
      ({
        ...lodash.omit(node, [SlateInternal.pseudoSelection]),
        ...(isCustomElement(node) && { children: deserializePseudoSelection(node.children) }),
      }) as CustomElement | CustomText,
  )
