import { CONFIG_ELEMENTS } from '@leenda/editor/lib/elements'

import { t } from 'services/Translation'

import { ElementGroup } from '../../elements.types'
import { genElementConfig } from '../../genElementConfig'
import { STYLE_FORM_SCHEMA, FONT_FORM_SCHEMA } from './SplitterBrandFormSchema'
import Demo from './SplitterDemo'
import SplitterEditorActions from './SplitterEditorActions'
import Element from './SplitterElement'
import Form from './SplitterForm'
import splitterIcon from './assets/splitter.svg'

export default genElementConfig(CONFIG_ELEMENTS.splitter, {
  label: t('elements.splitter.label'),
  icon: splitterIcon,
  group: ElementGroup.interactive,
  Element,
  ValueForm: Form,
  STYLE_FORM_SCHEMA,
  FONT_FORM_SCHEMA,
  Demo,
  EditorActions: SplitterEditorActions,
})
