import { produce } from 'immer'
import { useEffect, useState } from 'react'

import { AppMode } from 'services/Store/Project/enums'
import { IBlockMode } from 'services/Store/Project/types'

export const useCustomMode = (mode: IBlockMode, isActive: boolean) => {
  const [editorMode, setEditorMode] = useState<AppMode | null>(null)
  const nextMode = produce(mode, (draft) => {
    if (editorMode) {
      draft.editorMode = editorMode
    }
  })

  useEffect(() => {
    setEditorMode(null)
  }, [isActive, mode])

  return [nextMode, setEditorMode] as const
}
