import { HeaderFontSchemaType, HeaderSchemaType } from '@leenda/editor/lib/brand'
import { MenuPositionType, SidebarMenuType } from '@leenda/editor/lib/brand/types'
import { CONFIG_COURSE } from '@leenda/editor/lib/elements'
import cn from 'classnames'
import React from 'react'

import { useElementRootStyles } from 'components/editor-v2/EditorElements/hooks/useElementRootStyles'
import { useElementStyleCss } from 'services/Branding/hooks'
import { ElementFontCss, ElementStyleCss } from 'services/Branding/types'
import { DeviceMode } from 'services/Store/Project/enums'
import { useProjectContext } from 'services/Store/Project/hooks'
import { getSidebar } from 'services/Store/Project/selectors'
import { Project, ScormSettings } from 'services/Store/Project/types'
import { testProps } from 'utils/test/qaData'

import ScormDebugModal from '../ScormDebug/ScormDebugModal'
import s from './CourseHeader.module.scss'
import { CourseHeaderLogo } from './components/CourseHeaderLogo'
import MenuOpenIcon from './components/MenuOpenIcon/MenuOpenIcon'

interface ICourseHeaderProps {
  deviceMode?: DeviceMode
  toggleSidebar: () => void
  isDisabledSidebarAndHome?: boolean
  title?: string | null
  project?: Project | null
  scormSettings?: ScormSettings
  styles: ElementStyleCss<HeaderSchemaType>
  fonts: ElementFontCss<HeaderFontSchemaType>
}

const CourseHeader: React.FC<ICourseHeaderProps> = ({
  toggleSidebar,
  deviceMode = DeviceMode.desktop,
  title,
  isDisabledSidebarAndHome,
  project,
  scormSettings,
  styles,
  fonts,
}) => {
  const mobile = deviceMode === DeviceMode.mobile
  const desktop = deviceMode === DeviceMode.desktop
  const { open } = useProjectContext(getSidebar)

  const stylesSidebar = useElementStyleCss(CONFIG_COURSE.sidebar.type, deviceMode)
  const rootStyles = useElementRootStyles(styles.root, styles.panel)
  const menuPosition = stylesSidebar.value.position as MenuPositionType
  const isFixedSidebar = stylesSidebar.menu.type === SidebarMenuType.fixed
  const positionRight = menuPosition === MenuPositionType.right
  const positionLeft = menuPosition === MenuPositionType.left
  const showHeader = styles.panel.showHeader
  const showMenu = styles.menu.showMenu

  const isFloatingBurger = !showHeader && ((isFixedSidebar && !desktop) || !isFixedSidebar)
  const hideBurger =
    (!showMenu && !isFixedSidebar) ||
    (!showMenu && isFixedSidebar && desktop) ||
    (!showHeader && isFixedSidebar && desktop) ||
    (isFixedSidebar && desktop)

  if (!showHeader) {
    return hideBurger ? null : (
      <MenuOpenIcon
        isDisabledSidebarAndHome={isDisabledSidebarAndHome}
        isFloatingBurger={isFloatingBurger}
        isOpen={open}
        positionRight={positionRight}
        styles={styles}
        toggleSidebar={toggleSidebar}
      />
    )
  }

  return (
    <div className={cn(s.root, positionRight && s.reverse)} style={rootStyles}>
      <div className={s.left}>
        {(!hideBurger || isFloatingBurger) && (
          <MenuOpenIcon
            isDisabledSidebarAndHome={isDisabledSidebarAndHome}
            isFloatingBurger={isFloatingBurger}
            isOpen={open}
            positionRight={positionRight}
            styles={styles}
            toggleSidebar={toggleSidebar}
          />
        )}
        {positionLeft && styles.panel.showLogo && (
          <CourseHeaderLogo
            disabled={isDisabledSidebarAndHome}
            projectLogoId={project?.company?.logo?.id}
            title={title}
          />
        )}
      </div>
      {!mobile && (
        <div className={s.center}>
          <div
            className={s.title}
            style={fonts.base}
            {...testProps({ el: 'courseTitle', label: title, id: project?.id })}
          >
            {title}
          </div>
        </div>
      )}
      <div className={s.headerRightFix}>
        {positionRight && styles.panel.showLogo && (
          <CourseHeaderLogo
            disabled={isDisabledSidebarAndHome}
            projectLogoId={project?.company?.logo?.id}
            title={title}
          />
        )}
        {scormSettings?.debug && <ScormDebugModal />}
      </div>
    </div>
  )
}

export default CourseHeader
