import { IItemProps } from '../../ListElement.types'
import s from './ListNumber.module.scss'

const ListNumber: React.FC<IItemProps> = ({ children, styles }) => (
  <div className={s.numberText} style={styles}>
    {children}
  </div>
)

export default ListNumber
