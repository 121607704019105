import React from 'react'

type ErrBoundProps = {
  children: React.ReactElement
  onError?: () => void
}

type ErrBoundState = {
  hasError: boolean
}

class RichTextErrorBoundary extends React.Component<ErrBoundProps, ErrBoundState> {
  constructor(props: ErrBoundProps) {
    super(props)
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    console.error({ error, errorInfo })
    const { onError } = this.props
    onError?.()
  }

  render(): React.ReactElement {
    const { children } = this.props
    return children
  }
}

export { RichTextErrorBoundary }
