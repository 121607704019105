import React from 'react'
import { useHistory } from 'react-router'

import { AccountMock, EmployeesGroup } from 'components/uiKit/Employee'
import { EmployeeType } from 'components/uiKit/Employee/Employee'
import { IEmployeesGroupProps } from 'components/uiKit/Employee/EmployeesGroup/EmployeesGroup'
import { KitSize } from 'components/uiKit/KitTypes'
import { PROJECT_PATHS } from 'constants/paths'
import { useMatchPathParams } from 'routes/hooks'
import { Position } from 'services/Store/Users/UsersPosition.types'
import { setPinnedEmployee } from 'services/Store/Users/reducer'
import { selectPinnedEmployee, selectRealtimeUsers } from 'services/Store/Users/selectors'
import { useAppDispatch, useAppSelector } from 'services/Store/hooks'
import { notEmpty } from 'utils/notEmpty'

interface IProjectPositionUsersListProps {
  filter: Partial<Position>
  maxCount?: number
  size?: KitSize
  disabled?: boolean
  styleType?: IEmployeesGroupProps['styleType']
  type?: EmployeeType
}

const PATHS = [PROJECT_PATHS.editor, PROJECT_PATHS.preview]
const ProjectPositionUsersList: React.FC<IProjectPositionUsersListProps> = ({
  size,
  filter,
  maxCount,
  disabled = false,
  styleType = 'editorHeader',
  type = 'interactive',
}) => {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const users = useAppSelector((state) => selectRealtimeUsers(state, filter))
  const activeEmployee = useAppSelector(selectPinnedEmployee)
  const employees = users.map((user) => user?.employee).filter(notEmpty)

  const handleSelectEmployee = (employee: AccountMock, editor: boolean) => {
    const pinnedUserPath = users.find((user) => user.employee.kuserId === employee.kuserId)?.path
    if (editor) {
      dispatch(setPinnedEmployee(employee))
    } else if (pinnedUserPath) {
      history.push(pinnedUserPath)
    }
  }
  const editor = useMatchPathParams(PATHS)
  const onSelect = (employee: AccountMock) => handleSelectEmployee(employee, editor)

  return (
    <EmployeesGroup
      activeEmployeeId={activeEmployee?.id}
      disabled={disabled}
      employees={employees}
      maxCount={maxCount}
      onSelect={onSelect}
      size={size}
      styleType={styleType}
      type={type}
    />
  )
}

export default React.memo(ProjectPositionUsersList)
