"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ButtonContinueAction = exports.ButtonNavigationAction = exports.ButtonBlockAction = exports.ButtonActionType = void 0;
var ButtonActionType;
(function (ButtonActionType) {
    ButtonActionType["blockAction"] = "blockAction";
    ButtonActionType["navigation"] = "navigation";
    ButtonActionType["continue"] = "continue";
    ButtonActionType["link"] = "link";
    ButtonActionType["file"] = "file";
})(ButtonActionType || (exports.ButtonActionType = ButtonActionType = {}));
var ButtonBlockAction;
(function (ButtonBlockAction) {
    ButtonBlockAction["courseStart"] = "courseStart";
    ButtonBlockAction["testStart"] = "testStart";
    ButtonBlockAction["validate"] = "validate";
    ButtonBlockAction["testEnd"] = "testEnd";
    ButtonBlockAction["testRestart"] = "testRestart";
    ButtonBlockAction["courseEnd"] = "courseEnd";
    ButtonBlockAction["reset"] = "reset";
})(ButtonBlockAction || (exports.ButtonBlockAction = ButtonBlockAction = {}));
var ButtonNavigationAction;
(function (ButtonNavigationAction) {
    ButtonNavigationAction["nextSection"] = "nextSection";
    ButtonNavigationAction["prevSection"] = "prevSection";
    ButtonNavigationAction["custom"] = "custom";
})(ButtonNavigationAction || (exports.ButtonNavigationAction = ButtonNavigationAction = {}));
var ButtonContinueAction;
(function (ButtonContinueAction) {
    ButtonContinueAction["none"] = "none";
    ButtonContinueAction["above"] = "above";
    ButtonContinueAction["allAbove"] = "allAbove";
})(ButtonContinueAction || (exports.ButtonContinueAction = ButtonContinueAction = {}));
