import lodash from 'lodash'

export const chainName = <T extends { name?: string }>(...args: (string | T | undefined)[]): T => {
  const config = args.find((item) => typeof item === 'object') as T

  const name = args.reduce<string>((acc, cur) => {
    const curName = typeof cur === 'string' ? cur : cur?.name
    return [acc, curName].filter(Boolean).join('.')
  }, '')

  return { ...config, name }
}

export const getParent = <T>(name?: string, data?: unknown, level = 1) => {
  const pathParts = name?.split('.') || []
  const parentName = pathParts.slice(0, pathParts.length - level).join('.')

  return {
    parent: (parentName ? lodash.get(data, parentName) : data) as T | undefined,
    parentName,
  }
}

export const disabledHours = (
  duration?: number | null,
  cb: (left: number, index: number) => boolean = (left, index) => left < index,
) => {
  if (duration) {
    const hours: number[] = []
    const hour = Math.floor(duration / 3600)
    for (let index = 0; index < 24; index++) {
      if (cb(hour, index)) {
        hours.push(index)
      }
    }
    return hours
  }
  return []
}

export const disabledMinutes = (
  currHour: number,
  duration?: number | null,
  cb: (left: number, index: number) => boolean = (left, index) => left < index,
) => {
  if (duration) {
    const hour = Math.floor(duration / 3600)
    if (currHour < hour) {
      return []
    }
    const leftSeconds = duration % 3600
    const leftMinutes = Math.floor(leftSeconds / 60)
    const minutes: number[] = []
    for (let index = 0; index < 60; index++) {
      if (cb(leftMinutes, index)) {
        minutes.push(index)
      }
    }
    return minutes
  }
  return []
}

export const disabledSeconds = (
  currHour: number,
  currMinute: number,
  duration?: number | null,
  cb: (left: number, index: number) => boolean = (left, index) => left < index,
) => {
  if (duration) {
    const hour = Math.floor(duration / 3600)
    const minute = Math.floor((duration % 3600) / 60)
    if (currHour < hour) {
      return []
    }
    if (currMinute < minute) {
      return []
    }
    const leftSeconds = duration % 60
    const seconds: number[] = []
    for (let index = 0; index < 60; index++) {
      if (cb(leftSeconds, index)) {
        seconds.push(index)
      }
    }
    return seconds
  }
  return []
}

export const getMultipleValue = <D extends Record<string, unknown>, K extends keyof D>(
  items: D[],
  key: K,
  defaultValue: any,
) => {
  const values = items.map((section) => section[key] ?? defaultValue)
  const uniqValues = lodash.uniq(values)
  if (!uniqValues.length) {
    return defaultValue
  }
  return uniqValues.length === 1 ? uniqValues[0] : ('different' as const)
}
