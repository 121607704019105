import { CONFIG_ELEMENTS } from '@leenda/editor/lib/elements'

import EditorActions from 'components/editor-v3/cource/layout/controls/ElementControls/EditableProMode'
import { t } from 'services/Translation'

import { ElementGroup } from '../../elements.types'
import { genElementConfig } from '../../genElementConfig'
import { STYLE_FORM_SCHEMA, FONT_FORM_SCHEMA } from './ListBrandFormSchema'
import { DemoNumber, DemoPoint, DemoPointIcon } from './ListDemo'
import Element from './ListElement'
import ValueForm from './ListElementForm'
import icon from './assets/list.svg'

export default genElementConfig(CONFIG_ELEMENTS.list, {
  label: t('elements.list.label'),
  icon,
  group: ElementGroup.view,
  Element,
  ValueForm,
  STYLE_FORM_SCHEMA,
  FONT_FORM_SCHEMA,
  EditorActions,
  Demo: [DemoNumber, DemoPoint, DemoPointIcon],
})
