import { ButtonNavigationAction } from '@leenda/editor/lib/elements'
import {
  CrossLinkElement,
  CustomElement,
  LinkElement,
  AnnotationElement,
  SlateElementType,
  SlateMark,
  rtValueToText,
} from '@leenda/rich-text'
import cn from 'classnames'
import lodash from 'lodash'
import React, { CSSProperties, useCallback, useEffect, useMemo, useState } from 'react'
import { useSlate } from 'slate-react'
import { RenderElementProps } from 'slate-react/dist/components/editable'

import CourseTooltip from 'components/editor-v3/cource/components/CourseTooltip'
import { IconButton } from 'components/uiKit/Button'
import { preventDefault, stopPropagation } from 'constants/commonConstans'
import { DeviceMode } from 'services/Store/Project/enums'
import { useProjectContext, useProjectDispatch } from 'services/Store/Project/hooks'
import { getDeviceMode, getEditorMode, getPreviewMode } from 'services/Store/Project/selectors'
import { getNavigationLinkTrigger } from 'services/Store/Project/triggers'
import { prepareLinkUrl } from 'utils/websiteValidation'

import { SlateElementConfig } from '../RichTextConstants'
import { getCrossLinkElement, getLinkElement } from '../inline/withInline'
import s from './Element.module.scss'
import { getNodeFormatting } from './Text'

interface ISlateBlockRenderProps {
  attributes?: RenderElementProps['attributes']
  style?: { [key: string]: any }
  element: CustomElement
  isEdit?: boolean
  className?: string
  children?: React.ReactNode
}

type SlateBlockRender = React.FC<ISlateBlockRenderProps>

const BlockquoteElement: SlateBlockRender = ({ children, attributes, isEdit, ...restProps }) => (
  <blockquote {...attributes} {...restProps}>
    {children}
  </blockquote>
)
const NumberedList: SlateBlockRender = ({ children, attributes, isEdit, ...restProps }) => (
  <ol {...attributes} {...restProps}>
    {children}
  </ol>
)
const BulletedList: SlateBlockRender = ({ children, attributes, isEdit, ...restProps }) => (
  <ul {...attributes} {...restProps}>
    {children}
  </ul>
)
const DefaultElement: SlateBlockRender = ({ children, attributes, isEdit, ...restProps }) => (
  <div {...attributes} {...restProps}>
    {children}
  </div>
)
const Caption: SlateBlockRender = ({ children, attributes, isEdit, ...restProps }) => (
  <div {...attributes} {...restProps}>
    {children}
  </div>
)
const ListItem: SlateBlockRender = ({ children, attributes, isEdit, ...restProps }) => (
  <li {...attributes} {...restProps}>
    {children}
  </li>
)
const Heading1: SlateBlockRender = ({ children, attributes, isEdit, ...restProps }) => (
  <h1 {...attributes} {...restProps}>
    {children}
  </h1>
)
const Heading2: SlateBlockRender = ({ children, attributes, isEdit, ...restProps }) => (
  <h2 {...attributes} {...restProps}>
    {children}
  </h2>
)
const Heading3: SlateBlockRender = ({ children, attributes, isEdit, ...restProps }) => (
  <h3 {...attributes} {...restProps}>
    {children}
  </h3>
)
const Heading4: SlateBlockRender = ({ children, attributes, isEdit, ...restProps }) => (
  <h4 {...attributes} {...restProps}>
    {children}
  </h4>
)

const InlineChromiumBugfix = () => (
  <span
    contentEditable={false}
    style={{
      fontSize: 0,
    }}
  >
    ${String.fromCodePoint(160) /* Non-breaking space */}
  </span>
)

const Link: SlateBlockRender = ({ isEdit, attributes, children, element, className }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const editor = isEdit && useSlate()
  const selected = editor && getLinkElement(editor) === element
  const elementTyped = element as LinkElement
  const style: CSSProperties = {}
  if (selected) {
    style.boxShadow = '0 0 0 2px var(--color-delimiter-secondary)'
  }
  if (isEdit) {
    style.cursor = 'text'
  }
  return (
    <a
      {...attributes}
      className={className}
      href={prepareLinkUrl(elementTyped.url)}
      rel='noopener noreferrer'
      style={style}
      target='_blank'
    >
      <InlineChromiumBugfix />
      {children}
      <InlineChromiumBugfix />
    </a>
  )
}

const CrossLink: SlateBlockRender = ({ isEdit, attributes, children, element, className }) => {
  const elementTyped = element as CrossLinkElement
  const dispatch = useProjectDispatch()
  const trigger = useProjectContext(getNavigationLinkTrigger, {
    type: ButtonNavigationAction.custom,
    sectionId: elementTyped.value?.section || '',
    blockId: elementTyped.value?.block,
  })
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const editor = isEdit && useSlate()
  const selected = editor && getCrossLinkElement(editor) === element
  const style: CSSProperties = {}
  if (selected) {
    style.boxShadow = '0 0 0 2px var(--color-delimiter-secondary)'
  }
  if (isEdit) {
    style.cursor = 'text'
  } else {
    style.cursor = 'pointer'
  }

  if (!isEdit && trigger.disabled) {
    style.cursor = 'not-allowed'
  }
  const onClick = useCallback(() => {
    if (trigger.disabled) {
      return
    }
    dispatch(trigger.actionCreator())
  }, [dispatch, trigger])

  return (
    <span
      {...attributes}
      className={className}
      onClick={!isEdit ? onClick : undefined}
      style={style}
    >
      <InlineChromiumBugfix />
      {children}
      <InlineChromiumBugfix />
    </span>
  )
}

const Code: SlateBlockRender = ({ children, attributes, isEdit, element, ...restProps }) => {
  // @ts-ignore
  const leftColor = element.children[0]?.[SlateMark.backgroundColor]
  // @ts-ignore
  const rightColor = element.children[element.children.length - 1]?.[SlateMark.backgroundColor]
  const mainColor = leftColor === rightColor && leftColor ? leftColor : 'var(--brand-borderColor)'
  return (
    <code {...attributes} {...restProps}>
      <span style={{ borderRadius: 6, backgroundColor: mainColor }}>
        <span
          contentEditable={false}
          style={{
            borderTopLeftRadius: 6,
            borderBottomLeftRadius: 6,
            backgroundColor: leftColor || mainColor,
            width: 8,
          }}
        >
          &nbsp;
        </span>
        <span style={{ backgroundColor: mainColor }}>{children}</span>
        <span
          contentEditable={false}
          style={{
            borderTopRightRadius: 6,
            borderBottomRightRadius: 6,
            backgroundColor: rightColor || mainColor,
            width: 8,
          }}
        >
          &nbsp;
        </span>
      </span>
    </code>
  )
}
const Annotation: SlateBlockRender = ({
  isEdit,
  attributes,
  children,
  element,
  className,
  style,
}) => {
  const elementTyped = element as AnnotationElement
  const [visible, setVisible] = useState(false)
  const editorMode = useProjectContext(getEditorMode)
  const previewMode = useProjectContext(getPreviewMode)
  const deviceMode = useProjectContext(getDeviceMode)

  const mode = useMemo(
    () => ({ editorMode, previewMode, deviceMode }),
    [editorMode, previewMode, deviceMode],
  )

  const isMobile = mode.deviceMode === DeviceMode.mobile

  const openTooltip = () => setVisible(true)
  const closeTooltip = (e: any) => {
    preventDefault(e)
    stopPropagation(e)
    setVisible(false)
  }

  useEffect(() => {
    setVisible(false)
  }, [deviceMode])

  return (
    <span
      {...attributes}
      className={className}
      onClick={!isEdit && isMobile ? openTooltip : undefined}
      onMouseEnter={!isEdit && !isMobile ? openTooltip : undefined}
      onMouseLeave={!isEdit && !isMobile ? closeTooltip : undefined}
    >
      <CourseTooltip
        mode={mode}
        name='annotation'
        open={visible}
        position='bottom'
        title={
          <div className={cn(s.annotationTitle, { [s.mobile]: isMobile })} style={style}>
            {isMobile && (
              <>
                <div className={s.close}>
                  <IconButton
                    icon='otherClose'
                    name='closeTooltip'
                    onClick={closeTooltip}
                    styleType='ghost'
                  />
                </div>
                <div className={s.active}>
                  {lodash.capitalize(rtValueToText(elementTyped.children))}
                </div>
              </>
            )}
            {elementTyped.value}
          </div>
        }
        destroyTooltipOnHide
        isAnimated
      >
        <InlineChromiumBugfix />
        {children}
        <InlineChromiumBugfix />
      </CourseTooltip>
    </span>
  )
}

const ELEMENTS_COMPONENTS: Record<SlateElementType, SlateBlockRender> = {
  [SlateElementType.blockquote]: BlockquoteElement,
  [SlateElementType.numberedList]: NumberedList,
  [SlateElementType.bulletedList]: BulletedList,
  [SlateElementType.listItem]: ListItem,
  [SlateElementType.elementDefault]: DefaultElement,
  [SlateElementType.caption]: Caption,
  [SlateElementType.heading1]: Heading1,
  [SlateElementType.heading2]: Heading2,
  [SlateElementType.heading3]: Heading3,
  [SlateElementType.heading4]: Heading4,
  [SlateElementType.link]: Link,
  [SlateElementType.crossLink]: CrossLink,
  // https://docs.slatejs.org/concepts/12-typescript#multiple-document-models
  [SlateElementType.mention]: DefaultElement,
  [SlateElementType.code]: Code,
  [SlateElementType.annotation]: Annotation,
}

const Elements: React.FC<Partial<RenderElementProps & { isEdit: boolean }>> = (props) => {
  const { element, attributes } = props
  if (!element) {
    return null
  }
  const config = SlateElementConfig[element.type] || {}
  const { style, classes } = getNodeFormatting(element)
  const Component = ELEMENTS_COMPONENTS[element.type] || DefaultElement

  return (
    <Component
      attributes={attributes}
      className={cn(config.className, classes)}
      element={element}
      isEdit={props.isEdit}
      style={style}
    >
      {props.children}
    </Component>
  )
}

export default Elements
