"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const constants_1 = require("../../../elements/elements/Card/constants");
const font_1 = require("../../font");
const theme_1 = require("../../theme");
const genStyle_1 = require("../genStyle");
const styleSchema = {
    card: {
        height: (0, genStyle_1.genStyle)({ value: 380 }),
        width: (0, genStyle_1.genStyle)({ value: 290 }),
        borderRadius: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.borderRadius }),
    },
    border: {
        borderStyle: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.borderStyle }),
        borderWidth: (0, genStyle_1.genStyle)({ value: 1 }),
        borderColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.borderColor }),
    },
    sideA: {
        alignItems: (0, genStyle_1.genStyle)({ value: 'center' }),
        backgroundColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.primaryBackgroundColor }),
    },
    sideB: {
        alignItems: (0, genStyle_1.genStyle)({ value: 'center' }),
        backgroundColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.neutralColor }),
    },
    icon: {
        color: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.lightTextColor }),
        backgroundColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.primaryColor }),
        inversion: (0, genStyle_1.genStyle)({ value: true }),
        borderRadius: (0, genStyle_1.genStyle)({ value: 100 }),
    },
};
const fontSchema = Object.assign({}, font_1.FONT_SCHEMA_EMPTY);
exports.default = { [constants_1.TYPE]: { style: styleSchema, font: fontSchema } };
