import cn from 'classnames'
import { format as fnsFormat, toDate } from 'date-fns'
import React, { useMemo, useState } from 'react'
import ReactDatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import Dropdown from 'components/uiKit/Dropdown/DropDown'
import { t } from 'services/Translation'
import { DATE_LOCALE } from 'services/Translation/i18n'

import { KitSize } from '../KitTypes'
import s from './DatePicker.module.scss'
import DatePickerDay from './DatePickerDay'
import DatePickerHeader from './DatePickerHeader'
import DatePickerInput from './DatePickerInput'

export interface IDefaultDatePicker {
  defaultValue?: number | null
  value?: number | null
  placeholder?: string
  styleType?: 'default' | 'ghost'
  disabled?: boolean
  error?: boolean
  onChange: (value?: number | null) => void
  size?: KitSize
  fluid?: boolean
  format?: string
  minDate?: Date | number
  maxDate?: Date | number
  name: string
}

const DatePicker = ({
  defaultValue,
  value,
  styleType,
  onChange,
  size,
  placeholder = t('input.placeholder.date'),
  format = 'dd.MM.yyyy',
  minDate,
  maxDate,
  disabled,
  error,
  fluid,
}: IDefaultDatePicker) => {
  const selected = useMemo(
    () => (value && toDate(value)) || (defaultValue && toDate(defaultValue)) || null,
    [value, defaultValue],
  )

  const [open, setOpen] = useState(false)

  const handleOnChange = (value: Date) => {
    setOpen(false)
    onChange(value.getTime())
  }

  return (
    <div className={cn(s.root, { [s.fluid]: fluid })}>
      <Dropdown
        closeOnRootClick={false}
        disabled={disabled}
        onVisibleChange={setOpen}
        overlay={
          <ReactDatePicker
            calendarClassName={s.calendar}
            dayClassName={(_date) => s.day}
            disabled={disabled}
            locale={DATE_LOCALE}
            maxDate={maxDate ? toDate(maxDate) : null}
            minDate={minDate ? toDate(minDate) : null}
            onChange={handleOnChange}
            renderCustomHeader={(args) => <DatePickerHeader {...args} selected={selected} />}
            renderDayContents={(day, date) => (
              <DatePickerDay
                date={date}
                day={day}
                maxDate={maxDate}
                minDate={minDate}
                selected={selected}
              />
            )}
            selected={selected}
            inline
          />
        }
        overlayStyle={{ minWidth: 'unset' }}
        styleType='clear'
        visible={open}
      >
        <DatePickerInput
          disabled={disabled}
          error={error}
          onChange={onChange}
          placeholder={placeholder}
          size={size}
          styleType={styleType}
          value={(selected && fnsFormat(selected, format)) || ''}
        />
      </Dropdown>
    </div>
  )
}

export default DatePicker
