"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_VALUE = exports.TYPE = void 0;
const types_1 = require("./types");
exports.TYPE = 'divider';
exports.DEFAULT_VALUE = {
    dividerType: 'solid',
    dividerCount: null,
    dividerView: types_1.DividerViews.single,
    dividerNumber: '',
};
