export const removeFilesUppyFromLocalStorage = (id?: string) => {
  const nameUppyKey = 'tus::tus-uppy-'
  const uppyKeys: string[] = []

  for (let i = 0; i < localStorage.length; i++) {
    const currentKey = localStorage.key(i)
    if (currentKey && currentKey.match(id || nameUppyKey)) {
      uppyKeys.push(currentKey)
    }
  }

  uppyKeys.forEach((key) => localStorage.removeItem(key))
}
