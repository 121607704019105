import lodash from 'lodash'
import React, { useMemo } from 'react'

import { nodeWithDeviceMode } from 'components/editor-v3/context/EditorContext/selectors/block'
import { NodeType } from 'components/editor-v3/types/date.enums'
import { PreviewMode } from 'services/Store/Project/enums'

import { FCNode } from '../Node/types'
import EditorColumn from './EditorColumn'
import PreviewColumn from './PreviewColumn'

const BLOCK_COMPONENTS: Partial<Record<PreviewMode, FCNode<NodeType>>> = {
  editor: EditorColumn,
}

const ColumnContainer: FCNode = React.forwardRef((props, ref) => {
  const { node, mode } = props
  const { style } = nodeWithDeviceMode(node, mode.deviceMode)
  const padding = style.padding && style.padding.map((v: number) => `${v || 0}px`).join(' ')
  const colStyles = useMemo(() => ({ ...lodash.omit(style, ['scale']), padding }), [style, padding])

  const Component = BLOCK_COMPONENTS[mode.previewMode] || PreviewColumn
  return <Component {...props} ref={ref} style={colStyles} />
})

ColumnContainer.displayName = 'ColumnContainer'

export default React.memo(ColumnContainer)
