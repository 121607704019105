import { useProjectT } from 'services/ProjectLabels'

import BaseButtonElement from '../BaseButtonElement'
import { ButtonElementType } from '../ButtonElement.types'

const DefaultButtonElement: ButtonElementType = ({ element, styles, mode, font }) => {
  const pt = useProjectT()
  const label = element?.value?.label || pt('elements.button.common.label')

  return (
    <BaseButtonElement
      action={element.value.action}
      font={font}
      label={label}
      mode={mode}
      styles={styles}
    />
  )
}

export default DefaultButtonElement
