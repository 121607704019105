import { PermissionIdentification, PermissionService } from '@leenda/crud'
import { rtValueToText } from '@leenda/rich-text'
import cn from 'classnames'
import React, { useCallback, useMemo } from 'react'

import {
  copyLinkMenuItem,
  createCopyLinkAction,
  deleteMenuItem,
  pendingMenuItem,
  resolveMenuItem,
  subscribeMenuItem,
  unsubscribeMenuItem,
} from 'components/comments/actions'
import { CommentActionUiEnum, CommentMock } from 'components/comments/types'
import { AccountMock } from 'components/uiKit/Employee'
import { IMenuOption } from 'components/uiKit/Menu'
import { EmployeeListSchemaFragment } from 'gql/__generated__/graphql'
import { useCommentPermissions } from 'services/Permission/PermissionContext'
import { scrollProps } from 'services/Scroll/ScrollService'
import { useProjectContext } from 'services/Store/Project/hooks'
import { getBlock } from 'services/Store/Project/selectors'
import { getPath } from 'services/Store/Project/selectors/context'
import { selectCurrentEmployee } from 'services/Store/Users/selectors'
import { useAppSelector } from 'services/Store/hooks'
import { testPropsEl } from 'utils/test/qaData'

import { Actions, Authors, NameRow, Reply, CommentText, Path } from '../components'
import s from './ToolbarThread.module.scss'

const getActions = (
  comment: CommentMock,
  permissions: PermissionService<PermissionIdentification>,
  me: EmployeeListSchemaFragment | null,
) => [
  subscribeMenuItem({ comment, permissions, me }),
  unsubscribeMenuItem({ comment, permissions, me }),
  deleteMenuItem({ comment, permissions, me }),
  copyLinkMenuItem({ comment, permissions, me }),
  resolveMenuItem({ comment, permissions, me, inline: true }),
  pendingMenuItem({ comment, permissions, me, inline: true }),
]

interface IToolbarThreadProps {
  active: boolean
  comment: CommentMock
  mentions: AccountMock[]
  setThread: (thread: CommentMock) => void
}

const ToolbarThread: React.FC<IToolbarThreadProps> = ({ comment, mentions, active, setThread }) => {
  const path = useProjectContext(getPath)
  const block = useProjectContext(getBlock, { id: comment.blockUuid })
  const permissions = useCommentPermissions(comment)
  const me = useAppSelector(selectCurrentEmployee) || null

  const options = useMemo(() => getActions(comment, permissions, me), [comment, permissions, me])

  const handleOpenThread = useCallback(() => setThread(comment), [setThread, comment])

  const handleActionsClick = useCallback(
    async (option: IMenuOption<CommentActionUiEnum>) => {
      if (option.value === CommentActionUiEnum.LINK) {
        await createCopyLinkAction(path, {
          sectionId: comment.sectionId,
          blockId: comment.blockUuid,
          threadId: comment.id,
        })
      }
    },
    [comment, path],
  )

  return (
    <div
      {...scrollProps(comment.id)}
      {...testPropsEl('thread', { text: rtValueToText(comment.text) })}
      className={cn(s.root, { [s.active]: active })}
      onClick={handleOpenThread}
    >
      <div className={s.title}>
        <Authors comment={comment} grey={comment.isRead} styleType='default' />
        <Actions comment={comment} onClick={handleActionsClick} options={options} />
      </div>
      <Path block={block} comment={comment} />
      <NameRow comment={comment} />
      <CommentText comment={comment} mentions={mentions} />
      <Reply comment={comment} />
    </div>
  )
}

export default React.memo(ToolbarThread)
