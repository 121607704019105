import { TestResultSchemaType } from '@leenda/editor/lib/brand'
import { EditorElement, TestResultElementValue } from '@leenda/editor/lib/elements'
import { chainName, getParent } from 'utils'

import { genDataField } from 'components/controls/Field'
import RealtimeField from 'components/controls/RealtimeField'
import Padding from 'components/editor-v3/Editor/Toolbar/Padding'
import { Block } from 'services/Store/Project/types'
import { t } from 'services/Translation'

import { TestResultValueFormType } from './TestResultElement.types'

type TestResultElementType = EditorElement<TestResultElementValue, TestResultSchemaType>

const genField = genDataField<Block>()

const successTitle = genField({
  name: 'resultSuccessTitle',
  type: 'text',
  label: t('elements.testResult.form.successTitle'),
  params: {
    placeholder: t('input.placeholder.testSuccess'),
  },
  layout: 'vertical',
})

const failTitle = genField({
  name: 'resultFailTitle',
  type: 'text',
  label: t('elements.testResult.form.failTitle'),
  params: {
    placeholder: t('input.placeholder.testFail'),
  },
  layout: 'vertical',
})

const showDiagram = genField({
  name: 'showDiagram',
  type: 'segmented',
  label: t('elements.testResult.form.isDiagram'),
  layout: 'horizontal',
})

const position = genField({
  name: 'position',
  type: 'select',
  label: t('elements.testResult.form.position'),
  layout: 'horizontal',

  params: {
    options: [
      { value: 'top', label: t('input.option.top') },
      { value: 'bottom', label: t('input.option.bottom') },
      { value: 'left', label: t('input.option.left') },
      { value: 'right', label: t('input.option.right') },
    ],
    fluid: true,
  },
})

const showFeedback = genField({
  name: 'showFeedback',
  type: 'segmented',
  label: t('elements.testResult.form.isFeedback'),
  layout: 'horizontal',
})

const feedbackType = genField({
  name: 'feedbackType',
  type: 'radio',
  label: t('elements.testResult.form.feedback'),
  layout: 'horizontal',
  params: {
    options: [
      { value: 'info', label: t('input.option.commonFeedback') },
      { value: 'validation', label: t('input.option.dependentFeedback') },
    ],
  },
})

const feedbackSuccess = genField({
  name: 'resultFeedbackSuccess',
  type: 'text',
  label: t('elements.testResult.form.feedbackSuccess'),
  layout: 'vertical',
  params: (block, { name }) => {
    const { parent } = getParent<TestResultElementType>(name, block, 2)

    return {
      placeholder: t('input.placeholder.feedbackText'),
      hidden: parent && parent.value.feedbackType === 'info',
    }
  },
})

const feedbackFail = genField({
  name: 'resultFeedbackFail',
  type: 'text',
  label: t('elements.testResult.form.feedbackFail'),
  layout: 'vertical',
  params: (block, { name }) => {
    const { parent } = getParent<TestResultElementType>(name, block, 2)
    return {
      placeholder: t('input.placeholder.feedbackText'),
      hidden: parent && parent.value.feedbackType === 'info',
    }
  },
})

const feedbackCommon = genField({
  name: 'resultFeedbackCommon',
  type: 'text',
  label: t('elements.testResult.form.feedbackCommon'),
  layout: 'vertical',
  params: (block, { name }) => {
    const { parent } = getParent<TestResultElementType>(name, block, 2)
    return {
      placeholder: t('input.placeholder.feedbackText'),
      hidden: parent && parent.value.feedbackType !== 'info',
    }
  },
})

const TestResultForm: TestResultValueFormType = ({ name, element }) => (
  <Padding sides>
    <RealtimeField config={chainName(name, successTitle)} />
    <RealtimeField config={chainName(name, failTitle)} />
    <RealtimeField config={chainName(name, showDiagram)} />
    {element.value.showDiagram && <RealtimeField config={chainName(name, position)} />}
    <RealtimeField config={chainName(name, showFeedback)} />
    {element.value.showFeedback && (
      <>
        <RealtimeField config={chainName(name, feedbackType)} />
        <RealtimeField config={chainName(name, feedbackSuccess)} />
        <RealtimeField config={chainName(name, feedbackFail)} />
        <RealtimeField config={chainName(name, feedbackCommon)} />
      </>
    )}
  </Padding>
)

export default TestResultForm
