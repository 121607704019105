import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import Highlighter from 'components/uiKit/Highlighter'
import { ICON_MAP } from 'components/uiKit/Icon/generate/iconMap'
import { getFinderRecordName } from 'components/uiKit/Table/utils'
import {
  FileMetaGroupsSearch,
  FileMetaTypeFilterEnum,
  UpsertType,
  FileMetaFor,
  FileMetaGroupAllQuery,
  FileMetaGroupAllQueryVariables,
  FileMetaSchemaFragment,
  FilesGroupsFragment,
} from 'gql/__generated__/graphql'
import { useFileMetaGroupSubscription, useFilesSubscription } from 'gql/files.apollo'
import { fileGroupAllQuery, fileMetaGroupAllQuery } from 'gql/files.gql'
import { t } from 'services/Translation'
import { FinderFileItem, FinderFolderItem, FinderItem } from 'store/models/entry.model'

import { FileTypeEnum } from './fileTypes'
import { getFileAsCommonById } from './files'

export interface IParams {
  companyId: string
  fileMetaGroupId: string | undefined
  projectId?: string
  contentType?: FileTypeEnum[]
}

const QUERY_MAP = {
  modalMove: fileGroupAllQuery,
  default: fileMetaGroupAllQuery,
}

export const getCreatedForByFilter = (filter: FileMetaTypeFilterEnum) => {
  return {
    [FileMetaTypeFilterEnum.companies]: FileMetaFor.companies,
    [FileMetaTypeFilterEnum.projects]: FileMetaFor.projects,
    [FileMetaTypeFilterEnum.owner]: FileMetaFor.owner,
    [FileMetaTypeFilterEnum.all]: undefined,
  }[filter]
}
export const getFilterByCreatedFor = (createdFor?: FileMetaFor) => {
  if (!createdFor) {
    return FileMetaTypeFilterEnum.all
  }
  return {
    [FileMetaFor.companies]: FileMetaTypeFilterEnum.companies,
    [FileMetaFor.projects]: FileMetaTypeFilterEnum.projects,
    [FileMetaFor.owner]: FileMetaTypeFilterEnum.owner,
  }[createdFor]
}

// If isInfiniteLoad === true, define a merge function by adding incoming data to existing ones (src/gql/index.ts).
// If isInfiniteLoad === false data is displayed page by page without writing in one array.
// https://www.apollographql.com/docs/react/caching/cache-field-behavior/

export const prepareFinderItem = (
  item: FileMetaSchemaFragment | FilesGroupsFragment,
  query: string,
) => {
  const file = 'files' in item ? getFileAsCommonById(item.id) : EMPTY_CONTENT

  const isImage = file?.file?.type ? isFileImage(file?.file.type) : true

  return {
    ...item,
    img: isImage ? file?.path : null,
    iconLink: !isImage ? getFileIcon(file?.file?.type || '') : null,
    path: file?.path,
    nodeName: <Highlighter search={query} text={getFinderRecordName(item)} />,
  }
}

export const useFinder = (
  params: IParams,
  search: Partial<FileMetaGroupsSearch>,
  query: 'default' | 'modalMove' = 'default',
) => {
  const { companyId, projectId, fileMetaGroupId } = params
  const searchVars: FileMetaGroupsSearch = useMemo(
    () => ({
      id: getIdFileMeta(params, search.fileMetaType as unknown as FileMetaFor) || params.companyId,
      fileMetaType: search.fileMetaType || FileMetaTypeFilterEnum.companies,
      order: [['updatedAt', 'DESC']],
      ...search,
    }),
    [params, search],
  )

  const { data, loading, fetchMore, networkStatus, error } = useQuery(QUERY_MAP[query], {
    variables: {
      companyId,
      search: searchVars,
      parentId: fileMetaGroupId,
      isInfiniteLoad: false,
    } as FileMetaGroupAllQueryVariables,
    fetchPolicy: 'cache-and-network',
    onError: (err) =>
      console.error('"useFileMetaGroupAll" fn is crashed on operation: "useLazyQuery"', err),
  })

  useFilesSubscription({
    companyId,
    parentId: fileMetaGroupId || null,
    projectId: projectId || null,
    search: searchVars,
  })

  useFileMetaGroupSubscription({
    companyId,
    parentId: fileMetaGroupId || null,
    projectId: projectId || null,
    search: searchVars,
  })

  const finderData: FinderItem[] = useMemo(
    () =>
      [
        ...(data?.data.groups || []),
        ...((data as FileMetaGroupAllQuery)?.data.fileMetas || []),
      ].map((item) => prepareFinderItem(item, search.query || '')),
    [data, search.query],
  )

  const breadcrumbs = data?.breadcrumb?.breadcrumbs || []

  const total = data?.data?.total || 0
  const limit = data?.data.limit
  const totalGroup = data?.data?.totalGroup || 0

  return {
    data: finderData,
    loading,
    breadcrumbs,
    total,
    fetchMore,
    searchVars,
    limit,
    error,
    networkStatus,
    totalGroup,
  }
}

export const isFinderFile = (item?: FinderItem): item is FinderFileItem =>
  item?.__typename === 'FileMeta'

export const isFinderFolder = (item?: FinderItem): item is FinderFolderItem =>
  item?.__typename === 'FileMetaGroup'

export const getTypeFile = (type?: string) => {
  if (type && type.indexOf('/') !== -1) {
    return type?.slice(0, type.indexOf('/')) as FileTypeEnum | undefined
  }
  return type as FileTypeEnum | undefined
}

export const getIdFileMeta = (
  params: { companyId: string; projectId?: string; ownerId?: string; taskId?: string },
  fileMetaType?: FileMetaFor | UpsertType,
) => {
  switch (fileMetaType) {
    case 'projects':
      return params.projectId
    case 'companies':
      return params.companyId
    case 'owner':
      return params.companyId
    case 'task':
      return params.taskId
  }
  return params.companyId
}

export const EMPTY_CONTENT = { path: undefined, file: { type: undefined } }

export const getFileIcon = (type: string) => {
  const [fileType] = type.split('/')
  switch (fileType) {
    case 'image':
      return ICON_MAP.otherImage || null
    case 'audio':
      return ICON_MAP.iconsOtherAudio || null
    case 'video':
      return ICON_MAP.iconsOtherVideo || null
    default:
      return ICON_MAP.otherFile || null
  }
}

export const isFileImage = (type: string) => {
  const [fileType] = type.split('/')

  return fileType === 'image'
}

// TODO: use getEnumOption from utils
export const CONTENT_TYPES_OPTIONS = [
  { label: t('page.finder.tag.image'), value: FileTypeEnum.IMAGE },
  { label: t('page.finder.tag.audio'), value: FileTypeEnum.AUDIO },
  { label: t('page.finder.tag.video'), value: FileTypeEnum.VIDEO },
  { label: t('page.finder.tag.docs'), value: FileTypeEnum.TEXT },
  { label: t('page.finder.tag.other'), value: FileTypeEnum.APPLICATION },
]
