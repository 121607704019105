import cn from 'classnames'
import React from 'react'

import { Tooltip } from 'components/uiKit/Dropdown'
import Icon from 'components/uiKit/Icon'
import { IMenuOption } from 'components/uiKit/Menu'
import { testProps } from 'utils/test/qaData'

import s from './ControlItem.module.scss'

type ControlItemProps<V extends string> = {
  handler: ({ value }: { value: V }) => void
  option: IMenuOption<V>
  active?: boolean
}

const ControlItem = <V extends string>({ handler, option, active }: ControlItemProps<V>) => {
  if (!option.icon) {
    throw new Error('ControlItem: icon is required')
  }
  if (typeof option.label === 'function') {
    throw new Error('ControlItem: label must be a string')
  }
  return (
    <Tooltip overlay={option.label}>
      <div
        {...testProps({ el: 'layoutAction', name: option.value })}
        className={cn(s.root, { [s.active]: active, [s.disabled]: option.disabled })}
        onClick={option.disabled ? undefined : () => handler({ value: option.value })}
        onMouseDown={(e) => e.stopPropagation()}
      >
        <Icon name={option.icon} />
      </div>
    </Tooltip>
  )
}

export default ControlItem
