import { DeepPartial } from '@apollo/client/utilities'
import { ElementSchemaType } from '@leenda/editor/lib/brand'
import * as R from 'ramda'

const pick = <V extends { value?: unknown; form?: unknown }>({ value, form }: V) => ({
  ...(value !== undefined && { value }),
  ...(form !== undefined && { form }),
})

const omit = <V extends { META?: unknown } | null>(data: V) => data && R.omit(['META'], data)
const groupPick = R.mapObjIndexed(pick)
const deepPick = R.mapObjIndexed(
  R.mapObjIndexed(R.mapObjIndexed(R.compose(R.mapObjIndexed(pick), omit))),
)

export const groupComparator = <T extends Record<string, { value?: unknown; form?: unknown }>>(
  a: T,
  b: T,
) => !R.equals(groupPick(a), groupPick(b))

export const styleComparator = <T extends { value?: unknown; form?: unknown }>(a: T, b: T) =>
  !R.equals(pick(a), pick(b))

export const schemaComparator = <
  T extends { style?: { desktop?: DeepPartial<ElementSchemaType> } },
>(
  a?: T,
  b?: T,
) => a && b && !R.equals(deepPick(a), deepPick(b))

export const fontsComparator = <T>(a?: T, b?: T) => a && b && !R.equals(deepPick(a), deepPick(b))

const deepPick2 = R.mapObjIndexed(R.mapObjIndexed((a) => a))

export const fontsDeviceComparator = <T>(a?: T, b?: T) =>
  a && b && !R.equals(deepPick2(a), deepPick2(b))
