import NodeContainer from 'components/editor-v3/cource/layout/Node/NodeContainer'

import { useElementRootStyles } from '../../hooks/useElementRootStyles'
import { Props } from './LayoutList.types'
import s from './LayoutListElement.module.scss'

const LayoutListElement: Props = ({ block, element, styles, mode }) => {
  const { items } = element.value

  const rootStyles = useElementRootStyles(styles.root)
  const alignStyles: React.CSSProperties =
    styles.settings.flexDirection === 'row'
      ? {
          justifyContent: styles.settings.justifyContent,
          flexWrap: 'wrap',
        }
      : {
          alignItems: styles.settings.justifyContent,
        }

  return (
    <div
      className={s.root}
      style={{
        ...styles.indents,
        ...rootStyles,
        ...alignStyles,
        flexDirection: styles.settings.flexDirection,
      }}
    >
      {items.map(({ value }) => (
        <div className={s.item} key={value}>
          <NodeContainer block={block!} id={value} level={0} mode={mode} />
        </div>
      ))}
    </div>
  )
}

export default LayoutListElement
