import { t } from 'services/Translation'

export const DEFAULT_STYLE_TYPES = {
  cancel: 'ghost',
  save: 'primary',
} as const

export const FILTER_LABELS = {
  cancel: t('uiKit.button.reset'),
  save: t('uiKit.button.apply'),
}

export const DELETE_LABELS = {
  cancel: t('uiKit.button.cancel'),
  save: t('uiKit.button.delete'),
}

export const SAVE_LABELS = {
  cancel: t('uiKit.button.cancel'),
  save: t('uiKit.button.save'),
}
