"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const constants_1 = require("../../../elements/elements/TestFeedback/constants");
const font_1 = require("../../font");
const theme_1 = require("../../theme");
const genStyle_1 = require("../genStyle");
const styleSchema = {
    common: {
        borderRadius: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.borderRadius }),
    },
    infoPanel: {
        backgroundColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.infoColor }),
        borderStyle: (0, genStyle_1.genStyle)({ value: 'none' }),
        borderColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.borderColor }),
        borderWidth: (0, genStyle_1.genStyle)({ value: 1 }),
    },
    infoIcon: {
        backgroundImage: (0, genStyle_1.genStyle)({ value: null }),
    },
    successPanel: {
        backgroundColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.successColor }),
        borderStyle: (0, genStyle_1.genStyle)({ value: 'none' }),
        borderColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.borderColor }),
        borderWidth: (0, genStyle_1.genStyle)({ value: 1 }),
    },
    successIcon: {
        backgroundImage: (0, genStyle_1.genStyle)({ value: null }),
    },
    failPanel: {
        backgroundColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.errorColor }),
        borderStyle: (0, genStyle_1.genStyle)({ value: 'none' }),
        borderColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.borderColor }),
        borderWidth: (0, genStyle_1.genStyle)({ value: 1 }),
    },
    failIcon: {
        backgroundImage: (0, genStyle_1.genStyle)({ value: null }),
    },
};
const fontSchema = Object.assign({}, font_1.FONT_SCHEMA_EMPTY);
exports.default = { [constants_1.TYPE]: { style: styleSchema, font: fontSchema } };
