import { gql } from 'gql/__generated__'

export const BrandSelectItem = gql(`
  fragment BrandSelectItem on Brand {
    id
    isDefault
    name
  }
`)

export const BrandTableItem = gql(`
  fragment BrandTableItem on Brand {
    id
    isDefault
    name
    projectsCount
    createdBy {
      id
    }
    updatedAt
  }
`)

export const BrandEditable = gql(`
  fragment BrandEditable on Brand {
    id
    name
    theme
    schema
    palette
    isArchived
    font
    labels
    files {
      ...FileMetaSchema
    }
  }
`)

export const brandsSelectItemsByCompany = gql(`
  query BrandSelectItemsByCompany(
    $companyId: String!
    $search: BrandSearch
    $excludedIds: [String]
  ) {
    data: brandsByCompany(companyId: $companyId, search: $search, excludedIds: $excludedIds) {
      brands {
        ...BrandSelectItem
      }
    }
  }
`)

export const brandTableItemsByCompany = gql(`
  query BrandTableItemsByCompany(
    $companyId: String!
    $search: BrandSearch
    $excludedIds: [String]
  ) {
    data: brandsByCompany(companyId: $companyId, search: $search, excludedIds: $excludedIds) {
      brands {
        ...BrandTableItem
      }
      total
      limit
      offset
    }
  }
`)

export const branGetById = gql(`
  query BrandGetById($id: String!) {
    data: brandGetById(id: $id) {
      ...BrandEditable
    }
  }
`)

export const createBrand = gql(`
  mutation CreateBrand($companyId: ID!, $data: BrandInput!) {
    data: createBrand(companyId: $companyId, data: $data) {
      ...BrandTableItem
    }
  }
`)

export const cloneCompanyBrand = gql(`
  mutation CloneCompanyBrands($brandIds: [ID!]!) {
    data: cloneCompanyBrand(brandIds: $brandIds) {
      ...BrandTableItem
    }
  }
`)

export const setCompanyBrand = gql(`
  mutation SetCompanyBrand($companyId: ID!, $brandId: String!) {
    data: setCompanyBrand(companyId: $companyId, brandId: $brandId) {
      ...BrandTableItem
    }
  }
`)

export const deleteBrandsByIds = gql(`
  mutation DeleteBrandsByIds($payload: DeleteBrandByIds!) {
    data: deleteBrandByIds(payload: $payload)
  }
`)

export const updateCompanyBrand = gql(`
  mutation UpdateCompanyBrand($brandId: ID!, $data: BrandInput!) {
    data: updateCompanyBrand(brandId: $brandId, data: $data) {
      ...BrandEditable
    }
  }
`)
