import { createSelector } from '@reduxjs/toolkit'
import * as R from 'ramda'

import { IProjectContext } from 'services/Store/Project/types'
import { notEmpty } from 'utils/notEmpty'

import { BlockMode, SectionTypeEnum } from '../../enums'
import { getSection } from './section'

export const getBlocks = createSelector(
  (context: IProjectContext, sectionId?: string) => getSection(context, { id: sectionId }),
  (context: IProjectContext) => context.data.blocks,
  (section, blocks) =>
    section
      ? section?.blocksOrder.map((item) => blocks[item]).filter(notEmpty) || []
      : R.values(blocks),
)

export const getBlocksMap = (context: IProjectContext) => context.data.blocks

type GetBlockParams = {
  id?: string | null
  first?: true
  next?: true
  prev?: true
}

export const getBlock = (context: IProjectContext, params?: GetBlockParams) => {
  const { data, urlParams } = context
  if (params) {
    const { id, first, next, prev } = params
    if (id) {
      return data.blocks[id]
    }
    const section = getSection(context)
    const blocks = section?.blocksOrder.map((id) => data.blocks[id]).filter(notEmpty) || []
    if (first) {
      return R.head(blocks)
    }

    const index = urlParams.blockId
      ? R.findIndex(({ uuid }) => uuid === urlParams.blockId, blocks)
      : 0

    if (next && index >= 0) {
      return blocks[R.inc(index)] || null
    }
    if (prev && index >= 0) {
      return blocks[R.dec(index)] || null
    }
    return null
  }
  return urlParams.blockId ? data.blocks[urlParams.blockId] || null : null
}

export const getBlockIndex = createSelector(
  (c: IProjectContext) => c,
  (c: IProjectContext) => getSection(c),
  (context: IProjectContext, id?: string) => getBlock(context, { id }),
  (context, section, block) => {
    const blocks = getBlocks(context, section?.id)
    const isTest = section?.type === SectionTypeEnum.test
    if (isTest) {
      const showCountBlock = block?.mode !== BlockMode.start && block?.mode !== BlockMode.end
      return showCountBlock
        ? blocks
            .filter((b) => b.mode !== BlockMode.start && b.mode !== BlockMode.end)
            .findIndex((b) => b.uuid === block?.uuid)
        : -1
    }
    return blocks?.findIndex((b) => b.uuid === block?.uuid)
  },
)

export const getIsLastBlock = (context: IProjectContext, id: string) => {
  const section = getSection(context, { id: context.urlParams.sectionId })
  if (section && R.last(section.blocksOrder) === id) {
    return true
  }
  return false
}

export const getIsFirstBlock = (context: IProjectContext, id: string) => {
  const section = getSection(context, { id: context.urlParams.sectionId })
  if (section && R.head(section.blocksOrder) === id) {
    return true
  }
  return false
}
