"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateSlate = void 0;
const types_1 = require("../types");
const guards_1 = require("../types/guards");
const validateInlineChildren = (value) => {
    var _a;
    return (_a = value === null || value === void 0 ? void 0 : value.children) === null || _a === void 0 ? void 0 : _a.every((child) => (0, guards_1.isInlineElement)(child) || (0, guards_1.isTextNode)(child));
};
const validateSlate = (value) => {
    if (!value || !Array.isArray(value)) {
        console.warn('value is not array', value);
        return false;
    }
    for (const element of value) {
        if (!element || !element.type || Array.isArray(element)) {
            console.warn('element is not object or has no type', element);
            return false;
        }
        if ([types_1.SlateElementType.numberedList, types_1.SlateElementType.bulletedList].includes(element.type)) {
            for (const item of element.children) {
                if (!item || item.type !== types_1.SlateElementType.listItem) {
                    console.warn('list item is not object or has no type', item);
                    return false;
                }
                const isInline = validateInlineChildren(item);
                if (!isInline) {
                    console.warn('list item has invalid children', element);
                    return false;
                }
            }
        }
        else if ((0, guards_1.isBlockElement)(element)) {
            const isInline = validateInlineChildren(element);
            if (!isInline) {
                console.warn('block element has invalid children', element);
                return false;
            }
        }
    }
    return true;
};
exports.validateSlate = validateSlate;
