import React from 'react'
import { useContext } from 'react'

import TableContext, { TableContextValue } from '../../TableContext'
import Cell, { SimpleCell } from '../Cell'

interface IRowProps {
  index: number
  isDragging?: boolean
  colIndex?: number | null
}

const getCells = (context: TableContextValue, i: number) => context.tableValue.cells[i]

const Row: React.FC<IRowProps> = ({ index: indexRow, isDragging, colIndex }) => {
  const ctx = useContext(TableContext)
  const cells = getCells(ctx, indexRow)

  return (
    <tr>
      {cells.map(
        (cell, index) =>
          cell &&
          (colIndex === undefined || colIndex === null || colIndex === index) &&
          (isDragging ? (
            <SimpleCell cell={cell} index={index} indexRow={indexRow} key={index} />
          ) : (
            <Cell cell={cell} index={index} indexRow={indexRow} key={index} />
          )),
      )}
    </tr>
  )
}

export default Row
