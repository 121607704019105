import cn from 'classnames'
import React, { useCallback } from 'react'

import { testProps } from 'utils/test/qaData'

import { IOption, KitSize } from '../KitTypes'
import { OptionLabel } from '../KitUtils'
import s from './Segmented.module.scss'

interface IProps<T> {
  item: IOption<T>
  name: string
  value?: T
  groupName: string
  onChange: (value?: T) => void
  size: KitSize
  disabled?: boolean
  onFocus?: () => void
  onBlur?: () => void
}
const SegmentedElement = ({
  item,
  value,
  onChange,
  onFocus,
  onBlur,
  size,
  groupName,
  name,
  disabled,
}: IProps<number | string | boolean>) => {
  const isActive = value === item.value

  const handleChange = useCallback(() => {
    if (!isActive) {
      onChange(item.value as string | boolean)
    }
  }, [isActive, item.value, onChange])

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLLabelElement>) => {
      if (event.key === 'Enter') {
        event.preventDefault()
        handleChange()
      }
    },
    [handleChange],
  )

  return (
    <label
      className={cn(s.label)}
      key={String(item.value)}
      onKeyDown={handleKeyDown}
      tabIndex={0}
      {...testProps({ el: 'switcherItem', name, value: item.value, checked: isActive })}
    >
      <input
        checked={isActive}
        className={s.input}
        disabled={disabled}
        name={groupName}
        onBlur={onBlur}
        onClick={handleChange}
        onFocus={onFocus}
        type='radio'
        value={String(item.value)}
        readOnly
      />
      <span
        className={cn(s.item, s[size], {
          [s.active]: isActive,
          [s.disabled]: disabled,
        })}
      >
        <OptionLabel option={item} size={KitSize.M} />
      </span>
    </label>
  )
}
export default SegmentedElement
