import { PdfBuildListSchemaFragment, ScormBuildListSchemaFragment } from 'gql/__generated__/graphql'

export enum ProjectBuildActionUiEnum {
  PIN = 'PIN',
  DOWNLOAD = 'DOWNLOAD',
  DELETE = 'DELETE',
  UNPIN = 'UNPIN',
  EDIT = 'EDIT',
}

export type IItemTypes = PdfBuildListSchemaFragment | ScormBuildListSchemaFragment
