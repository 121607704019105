import React from 'react'

import { nodeWithDeviceMode } from 'components/editor-v3/context/EditorContext/selectors/block'
import { NodeType } from 'components/editor-v3/types/date.enums'
import { PreviewMode } from 'services/Store/Project/enums'

import { FCNode } from '../Node/types'
import EditorRowSpace from './EditorRow'
import PreviewRowSpace from './PreviewRow'

const BLOCK_COMPONENTS: Partial<Record<PreviewMode, FCNode<NodeType>>> = {
  editor: EditorRowSpace,
}

const RowContainer: FCNode = React.forwardRef((props, ref) => {
  const { node, mode } = props
  const { style } = nodeWithDeviceMode(node, mode.deviceMode)
  const Component = BLOCK_COMPONENTS[mode.previewMode] || PreviewRowSpace

  return <Component {...props} ref={ref} style={style} />
})

RowContainer.displayName = 'RowContainer'

export default React.memo(RowContainer)
