import { CourseStructureFontSchemaType, CourseStructureSchemaType } from '@leenda/editor/lib/brand'

import { t } from 'services/Translation'

import DemoWrapper from '../../decorators/DemoWrapper'
import { IElementDemoProps } from '../../elements.types'
import EmptyDemo from '../EmptyDemo'

const CourseStructureDemo: React.FC<
  IElementDemoProps<CourseStructureSchemaType, CourseStructureFontSchemaType>
> = () => {
  return (
    <DemoWrapper center>
      <EmptyDemo name={t('elements.courseStructure.label')} />
    </DemoWrapper>
  )
}

export default CourseStructureDemo
