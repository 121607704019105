import { FileTypeEnum } from 'utils'

import { genDataField } from 'components/controls/Field'
import { t } from 'services/Translation'
import { EmployeePickerParamsType } from 'utils/employee/employee'
import { CONTENT_TYPES_OPTIONS } from 'utils/useFinder'

export type FilterValues = {
  createFrom?: number
  createTo?: number
  ownersIds?: string[]
  author?: string
  contentType?: FileTypeEnum[]
}

const genField = genDataField<FilterValues>()

export const contentTypeField = (hidden = false) =>
  genField({
    name: 'contentType',
    type: 'select',
    label: t('input.label.fileType'),
    params: {
      options: CONTENT_TYPES_OPTIONS,
      fluid: true,
      isMultiple: true,
      placeholder: t('input.placeholder.select'),
      hidden,
    },
  })

export const authorField = (params: EmployeePickerParamsType) =>
  genField({
    name: 'ownersIds',
    label: t('common.createdBy'),
    type: 'select',
    layout: 'vertical',
    params: {
      ...params,
      isMultiple: true,
      placeholder: t('input.placeholder.select'),
      resettable: true,
      fluid: true,
      dropdownType: 'dropdown',
    },
  })

export const CREATED_FROM_FIELD = genField({
  label: t('input.label.createdDate'),
  name: 'createFrom',
  type: 'date',
  layout: ['vertical', 'free'],
  params: {
    placeholder: t('input.label.from'),
    fluid: true,
  },
})

export const CREATED_TO_FIELD = genField({
  name: 'createTo',
  type: 'date',
  layout: ['vertical', 'free'],
  params: {
    placeholder: t('input.label.to'),
    fluid: true,
  },
})
