import { IArea } from '@leenda/editor/lib/elements'
import { generateId } from '@leenda/editor/lib/utils/id'
import lodash from 'lodash'

import { ResizeDirection } from './TestHotspotElement.types'

export const getPercentageCoordinate = (clientX: number, clientY: number, imgRect: DOMRect) => {
  return {
    currentX: ((clientX - imgRect.left) / imgRect.width) * 100,
    currentY: ((clientY - imgRect.top) / imgRect.height) * 100,
  }
}

export const clampCoordinates = (
  x: number,
  y: number,
  width: number,
  height: number,
  min: number,
  max: number,
) => {
  return {
    x: lodash.clamp(x, min, max - width),
    y: lodash.clamp(y, min, max - height),
  }
}

export const createArea = (x: number, y: number): IArea => ({
  id: generateId(),
  x,
  y,
  width: 0,
  height: 0,
})

export const updateAreaSize = (
  startPoint: { x: number; y: number },
  endPoint: { x: number; y: number },
) => {
  const width = Math.abs(endPoint.x - startPoint.x)
  const height = Math.abs(endPoint.y - startPoint.y)

  return {
    width,
    height,
    x: Math.min(startPoint.x, endPoint.x),
    y: Math.min(startPoint.y, endPoint.y),
  }
}

export const getOppositePoint = (area: IArea, direction: ResizeDirection) => {
  switch (direction) {
    case 'top-left':
      return { x: area.x + area.width, y: area.y + area.height }
    case 'top-right':
      return { x: area.x, y: area.y + area.height }
    case 'bottom-left':
      return { x: area.x + area.width, y: area.y }
    case 'bottom-right':
      return { x: area.x, y: area.y }
  }
}

const isSpotInArea = (x: number, y: number, area: IArea): boolean => {
  const { x: hx, y: hy, width, height } = area
  return x >= hx && x <= hx + width && y >= hy && y <= hy + height
}

export const getAreasContainingSpot = (x: number, y: number, areas: IArea[]): string[] => {
  return areas.filter((area) => isSpotInArea(x, y, area)).map((area) => area.id)
}
