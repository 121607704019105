import { useCallback } from 'react'

import { useProjectT } from 'services/ProjectLabels'
import { useProjectContext, useProjectDispatch } from 'services/Store/Project/hooks'
import { getTestSubmitTrigger } from 'services/Store/Project/triggers'

import BaseButtonElement from '../BaseButtonElement'
import { ButtonElementType } from '../ButtonElement.types'
import { getButtonLabel } from '../helper'

const SubmitButtonElement: ButtonElementType = ({ element, mode, styles, block, font }) => {
  const dispatch = useProjectDispatch()
  //TODO: fix on 'useProjectT' after merging Project Settings MR
  const pt = useProjectT()

  const trigger = useProjectContext(getTestSubmitTrigger, { blockId: block?.uuid, pt })

  const onClick = useCallback(
    () => !trigger.disabled && dispatch(trigger.actionCreator()),
    [dispatch, trigger],
  )
  const label = getButtonLabel(
    {
      'project/questionValidate': element.value.label,
      'project/setCourseNavigation': element.value.secondLabel,
      'project/questionReset': element.value.thirdLabel,
      'project/testEnd': element.value.fourthLabel,
      'project/courseEnd': element.value.fourthLabel,
      noop: element.value.label,
    },
    trigger.action,
  )

  if (trigger.hide) {
    return null
  }

  return (
    <BaseButtonElement
      action={element.value.action}
      disabled={trigger?.disabled}
      font={font}
      label={label}
      mode={mode}
      onClick={onClick}
      styles={styles}
      tooltip={trigger?.tooltip}
    />
  )
}

export default SubmitButtonElement
