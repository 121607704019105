import { gql } from 'gql/__generated__'

export const commentsAll = gql(`
  query CommentsAll($filter: CommentsFilter!, $search: CommentsSearch!) {
    data: commentsAll(filter: $filter, search: $search,) {
      comments {
        ...CommentThreadSchema
      }
    }
  }
`)

export const dashboardComments = gql(`
  query DashboardComments(
    $companyId: String!
    $search: DashboardCommentsSearch!
    $filter: DashboardCommentsFilter
  ) {
    data: dashboardComments(companyId: $companyId, search: $search, filter: $filter) {
      comments {
        ...FeedThreadFragment
      }
      pagination {
        total
      }
    }
  }
`)

export const taskComments = gql(`
  query TaskComments($filter: CommentsFilter!, $search: CommentsSearch!) {
    data: commentsAll(filter: $filter, search: $search,) {
      comments {
        ...CommentThreadSchema
      }
    }
  }
`)
