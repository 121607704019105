export const getMargins = (indents: number[] | string) => {
  const marginArray =
    typeof indents !== 'string'
      ? indents
      : indents.split(' ').map((el) => Number(el.replace('px', '')))

  const margins = {
    right: marginArray[0],
    left: marginArray[1],
    bottom: marginArray[2],
  }
  return margins
}
