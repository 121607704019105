import React from 'react'

interface IDropDownContextProps {
  registerSubPopup: (id: string, node: HTMLElement) => void
}

export const DropDownContext = React.createContext<IDropDownContextProps | null>(null)

export const useDropDownContext = () => {
  const subPopupElements = React.useRef<Record<string, HTMLElement>>({})

  const parentContext = React.useContext(DropDownContext)

  const contextValue = React.useMemo<IDropDownContextProps>(() => {
    return {
      registerSubPopup: (id, subPopupEle) => {
        subPopupElements.current[id] = subPopupEle

        parentContext?.registerSubPopup(id, subPopupEle)
      },
    }
  }, [parentContext])

  return { contextValue, parentContext, subPopupElements }
}
