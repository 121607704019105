import cn from 'classnames'
import React, { useCallback } from 'react'

import { testPropsEl } from 'utils/test/qaData'

import { IOption } from '../KitTypes'
import s from './Radio.module.scss'
import RadioItem from './RadioItem/RadioItem'

type Value = number | string | string[] | boolean

export interface IRadioProps<T extends Value = Value> {
  name: string
  onChange: (value: T) => void
  value?: T
  options?: IOption<T>[]
  hideRadio?: boolean
  children?: React.ReactNode | string | null
  disabled?: boolean
  direction?: 'vertical' | 'horizontal'
  resettable?: boolean
  fluid?: boolean
  /** DON`T USE IT!!! JUST DESIgN SYNC */
  gap?: number
}

const Radio = <T extends Value = Value>({
  name,
  onChange,
  value,
  options = [],
  direction = 'vertical',
  hideRadio,
  disabled,
  resettable,
  gap,
  fluid = true,
}: IRadioProps<T>) => {
  const handleOnChange = useCallback(
    (val: any) => onChange(resettable && val === value ? undefined : val),
    [onChange, value],
  )
  return (
    <div
      className={cn(s.root, fluid && s.fluid, s[direction])}
      {...testPropsEl('radio', { name })}
      style={{ gap }}
    >
      {options.map((item) => (
        <RadioItem
          direction={direction}
          disabled={disabled}
          hideRadio={hideRadio}
          item={item}
          key={String(item.value)}
          name={name}
          onChange={handleOnChange}
          value={value}
        />
      ))}
    </div>
  )
}

export default React.memo(Radio) as typeof Radio
