import React, { useEffect, useState } from 'react'
import { ColorWrapChangeHandler } from 'react-color/lib/components/common/ColorWrap'

import Input, { AdditionWrapper } from 'components/uiKit/Input'
import InputNumber from 'components/uiKit/InputNumber'
import { KitSize } from 'components/uiKit/KitTypes'

import { validateHEX, hex2rgb } from '../../helpers'
import s from './HexFields.module.scss'

interface IUpperFloorProps {
  hex?: string
  onChange: ColorWrapChangeHandler
  alpha?: number
}

const getStringHEX = (hex?: string) => hex?.toUpperCase()?.slice(1, 7)

const HexFields = ({ hex: hexValue, alpha, onChange }: IUpperFloorProps): JSX.Element => {
  const [hex, setHex] = useState(getStringHEX(hexValue))

  const onChangeHEX = (value: string) => {
    const newValue = validateHEX(value.toUpperCase()) || ''
    setHex(newValue)

    if (newValue.length === 6) {
      onChange(hex2rgb(`#${newValue}`, alpha))
    }
  }

  const onChangeAlpha = (value = 0) => {
    if (value <= 100) {
      onChange(hex2rgb(hexValue || '', value / 100))
    }
  }

  useEffect(() => {
    setHex(getStringHEX(hexValue))
  }, [hexValue])

  return (
    <div className={s.root}>
      <div className={s.label}>Hex</div>
      <div className={s.inputHex}>
        <Input
          name='hex'
          onChange={onChangeHEX}
          prefix={<AdditionWrapper>#</AdditionWrapper>}
          size={KitSize.S}
          value={hex}
        />
      </div>
      <div className={s.inputAlpha}>
        <InputNumber
          name='alpha'
          onChange={onChangeAlpha}
          postfix={<AdditionWrapper>%</AdditionWrapper>}
          size={KitSize.S}
          value={Math.round((alpha ?? 1) * 100)}
        />
      </div>
    </div>
  )
}

export default HexFields
