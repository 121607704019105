import { FinishModalFontSchemaType, FinishModalSchemaType } from '@leenda/editor/lib/brand'
import { CONFIG_COURSE, CONFIG_ELEMENTS } from '@leenda/editor/lib/elements'
import React, { useCallback, useMemo } from 'react'

import BaseButtonElement from 'components/editor-v2/EditorElements/elements/ButtonElement/BaseButtonElement'
import { useElementStyleCss, useElementFontCss } from 'services/Branding/hooks'
import { ElementFontCss, ElementStyleCss } from 'services/Branding/types'
import { useProjectT } from 'services/ProjectLabels'
import { IBlockMode } from 'services/Store/Project/types'

import CourseModal from '../../components/CourseModal'
import s from './FinishModal.module.scss'

interface ICourseFinishedModalProps {
  mode: IBlockMode
}

export const CourseFinishedModalContent: React.FC<{
  onClose: () => void
  mode: IBlockMode
  styles: ElementStyleCss<FinishModalSchemaType>
  fonts: ElementFontCss<FinishModalFontSchemaType>
}> = ({ onClose, mode, styles, fonts }) => {
  const pt = useProjectT()
  const buttonStyles = useElementStyleCss(CONFIG_ELEMENTS.button.type, mode.deviceMode)
  const buttonFonts = useElementFontCss(CONFIG_ELEMENTS.button.type, mode.deviceMode)
  return (
    <div className={s.root} style={styles.background}>
      <div className={s.title} style={fonts?.h3}>
        {pt('finishModal.title')}
      </div>
      <div className={s.text} style={fonts?.base}>
        {pt('finishModal.text')}
      </div>
      <div className={s.button}>
        <BaseButtonElement
          action='continueViewing'
          font={buttonFonts}
          label={pt('finishModal.button')}
          mode={mode}
          onClick={onClose}
          styles={buttonStyles}
        />
      </div>
    </div>
  )
}

const FinishModal: React.FC<ICourseFinishedModalProps> = ({ mode }) => {
  const [open, setOpen] = React.useState(true)
  const styles = useElementStyleCss(CONFIG_COURSE.finishModal.type, mode.deviceMode)
  const fonts = useElementFontCss(CONFIG_COURSE.finishModal.type, mode.deviceMode)
  const props = useMemo(() => ({ mode, styles, fonts }), [mode, styles, fonts])
  const onClose = useCallback(() => setOpen(false), [])

  if (!styles.value.show) {
    return null
  }
  return (
    <CourseModal
      component={CourseFinishedModalContent}
      maxWidth={640}
      mode={mode}
      onClose={onClose}
      props={props}
      visible={open}
    />
  )
}

export default FinishModal
