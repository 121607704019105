import { useKeyPress } from 'ahooks'

import Form from 'components/controls/Form'
import SubmitButton from 'components/controls/SubmitButton/SubmitButton'
import Button from 'components/uiKit/Button'
import Modal, { MCWithParams, ModalHeader, ModalFooter, ModalBody } from 'components/uiKit/Modal'
import { t } from 'services/Translation'

import './ConfirmModal.scss'

interface IConfirmModalParams {
  title?: string | JSX.Element
  info?: string | JSX.Element
  okButtonTitle?: string | JSX.Element
  cancelButtonTitle?: string | JSX.Element
}

const CONFIRM_MODAL_PARAMS = {
  title: t('modal.delete.title'),
  info: t('modal.delete.description'),
  okButtonTitle: t('uiKit.button.delete'),
  cancelButtonTitle: t('uiKit.button.cancel'),
  stopPropagation: true,
}

const ConfirmModal: MCWithParams<IConfirmModalParams, boolean> = ({ onClose, params }) => {
  useKeyPress(['enter'], () => onClose(true))
  const { title, info, okButtonTitle, cancelButtonTitle } = CONFIRM_MODAL_PARAMS
  return (
    <Form layout='vertical' name='confirm' onSubmit={() => onClose(true)}>
      <Modal name='confirm'>
        <ModalHeader title={params.title || title} />
        <ModalBody sizeAutoCapable>
          <div>{params.info || info}</div>
        </ModalBody>
        <ModalFooter>
          <Button name='cancel-confirm-remove' onClick={() => onClose(false)} styleType='ghost'>
            {params.cancelButtonTitle || cancelButtonTitle}
          </Button>
          <SubmitButton label={params.okButtonTitle || okButtonTitle} name='confirm-remove' />
        </ModalFooter>
      </Modal>
    </Form>
  )
}

export { ConfirmModal }
