"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_VALUE = exports.TYPE = void 0;
const translation_1 = require("../../../translation");
const id_1 = require("../../../utils/id");
const rich_text_1 = require("@leenda/rich-text");
exports.TYPE = 'list';
exports.DEFAULT_VALUE = {
    items: [
        {
            label: (0, rich_text_1.textToRtValue)((0, translation_1.t)('elements.list.label')),
            value: (0, id_1.generateId)(),
            number: 1,
            icon: null,
        },
    ],
    mode: 'number',
};
