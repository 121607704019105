import { EditorElement } from '@leenda/editor/lib/elements'
import lodash from 'lodash'
import React, { useMemo, useState } from 'react'

import { generateElement } from 'components/editor-v2/EditorElements/elements.config'
import { ElementGroup } from 'components/editor-v2/EditorElements/elements.types'
import {
  IPickerElement,
  IPickerGroup,
} from 'components/editor-v2/EditorElements/pickerConfig/elementsPicker.types'
import {
  filterElementsByBlockMode,
  getExcludedTypes,
} from 'components/editor-v2/EditorElements/pickerConfig/elementsPickerFilter'
import { getNodeUsages } from 'components/editor-v3/context/EditorContext/selectors/block'
import { getElementName } from 'components/editor-v3/context/EditorContext/selectors/blockLabels'
import ScaledElementSkeleton from 'components/editor-v3/cource/renderEntry/ScaledElementSkeleton'
import Card from 'components/uiKit/Card'
import CheckableTag from 'components/uiKit/CheckableTag'
import Empty from 'components/uiKit/Empty'
import Highlighter from 'components/uiKit/Highlighter'
import InfiniteLoadGrid from 'components/uiKit/InfiniteLoadGrid'
import { KitSize } from 'components/uiKit/KitTypes'
import Modal, { MCWithoutParams } from 'components/uiKit/Modal'
import Search from 'components/uiKit/Search'
import { BlockMode, SectionTypeEnum } from 'services/Store/Project/enums'
import { useProjectContext } from 'services/Store/Project/hooks'
import { getBlock, getElement, getSection } from 'services/Store/Project/selectors'
import { t } from 'services/Translation'
import { getEnumLabel } from 'utils/enum'
import { testPropsEl } from 'utils/test/qaData'

import s from './ElementPickerModal.module.scss'
import { tagOptions, filterElems, sortedGroups } from './helpers'

type ElementPickerFilterParams = {
  sectionType?: SectionTypeEnum
  mode?: BlockMode
  elements: EditorElement[]
  element: EditorElement | null
}

const ElementPickerModal: MCWithoutParams<EditorElement> = ({ onClose }) => {
  const block = useProjectContext(getBlock)
  const section = useProjectContext(getSection)
  const element = useProjectContext(getElement)
  const [searchWords, setSearchWords] = useState<string>()
  const [tags, setTags] = useState<string[]>()

  const { notUsedElements, usedElements } = getNodeUsages(block!)

  const params: ElementPickerFilterParams = {
    sectionType: section?.type,
    mode: block?.mode,
    elements: usedElements,
    element,
  }
  const defItems = filterElementsByBlockMode(params)

  const filtered = useMemo(() => {
    const groups: IPickerGroup[] = []
    const excludedTypes = getExcludedTypes(usedElements, element)

    if (notUsedElements.length) {
      const notUsedGroup: IPickerGroup = {
        type: ElementGroup.notUsed,
        items: notUsedElements
          .filter((element) => !excludedTypes.includes(element.type))
          .map((element) => {
            return {
              type: 'exist',
              icon: <ScaledElementSkeleton element={element} height={114} width={180} />,
              label: getElementName(element),
              element,
            }
          }),
        name: getEnumLabel('ElementGroup', ElementGroup.notUsed),
      }
      groups.push(notUsedGroup)
    }

    return filterElems(groups, defItems, searchWords || '', tags)
  }, [searchWords, params, tags?.length])

  const sortedFilteredElements = lodash.sortBy(filtered, (elem) => sortedGroups.indexOf(elem.type))

  const noElements = filtered.every((group) => !group.items.length)

  const onItemSelect = (data: Omit<IPickerElement, 'icon'>) => () => {
    setSearchWords('')
    if (data.type === 'exist') {
      onClose(data.element)
    } else {
      onClose(generateElement(data.type))
    }
  }

  return (
    <Modal minHeight='100%' name='elementPick' styleType='tabs'>
      <div className={s.root}>
        <div className={s.searchContainer}>
          <div className={s.searchForm}>
            <Search
              name='element-picker-search'
              onChange={setSearchWords}
              value={searchWords}
              fluid
            />
          </div>
        </div>
        <div className={s.tags}>
          <CheckableTag
            name='elementsTags'
            onChange={setTags}
            options={tagOptions}
            value={tags || []}
          />
        </div>
        <div className={s.content}>
          {noElements ? (
            <Empty text={t('common.nothingFound')} />
          ) : (
            <div>
              {sortedFilteredElements.map((group) => {
                return (
                  !!group.items.length && (
                    <div key={group.type}>
                      <div className={s.groupTitle}>{group.name}</div>
                      <InfiniteLoadGrid
                        grid={{ width: '235px' }}
                        loading={false}
                        name='templateCard'
                        padding='16px 0px 20px'
                      >
                        {group.items.map((element, i: number) => (
                          <Card
                            key={element.type + i}
                            name='elementPicker'
                            onClick={onItemSelect(element)}
                            size={KitSize.S}
                          >
                            <div
                              className={s.itemName}
                              {...testPropsEl(
                                'elementCardCTitle',
                                { value: element.type },
                                { index: i },
                              )}
                            >
                              <Highlighter search={searchWords} text={element.label} />
                            </div>
                            <div
                              className={s.itemContainer}
                              {...testPropsEl(
                                'elementCardContent',
                                { value: element.type, label: element.label },
                                { index: i },
                              )}
                            >
                              <div className={s.itemIcon}>{element.icon}</div>
                            </div>
                          </Card>
                        ))}
                      </InfiniteLoadGrid>
                    </div>
                  )
                )
              })}
            </div>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default ElementPickerModal
