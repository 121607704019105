"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProgressIconPosition = exports.CollapseIconPosition = exports.SectionTypeEnum = void 0;
var SectionTypeEnum;
(function (SectionTypeEnum) {
    SectionTypeEnum["landing"] = "landing";
    SectionTypeEnum["test"] = "test";
    SectionTypeEnum["chapter"] = "chapter";
    SectionTypeEnum["cover"] = "cover";
})(SectionTypeEnum || (exports.SectionTypeEnum = SectionTypeEnum = {}));
var CollapseIconPosition;
(function (CollapseIconPosition) {
    CollapseIconPosition["none"] = "none";
    CollapseIconPosition["left"] = "left";
    CollapseIconPosition["right"] = "right";
    CollapseIconPosition["leftOutside"] = "leftOutside";
    CollapseIconPosition["rightOutside"] = "rightOutside";
})(CollapseIconPosition || (exports.CollapseIconPosition = CollapseIconPosition = {}));
var ProgressIconPosition;
(function (ProgressIconPosition) {
    ProgressIconPosition["none"] = "none";
    ProgressIconPosition["left"] = "left";
    ProgressIconPosition["right"] = "right";
})(ProgressIconPosition || (exports.ProgressIconPosition = ProgressIconPosition = {}));
