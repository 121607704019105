import cn from 'classnames'
import React, { useCallback } from 'react'

import { IconButton } from 'components/uiKit/Button'
import { KitSize } from 'components/uiKit/KitTypes'
import { OptionLabel } from 'components/uiKit/KitUtils'
import { testPropsEl } from 'utils/test/qaData'

import {
  useSelectHandlers,
  useSelectSize,
  useSelectContext,
  useSelectStyleType,
} from '../SelectContext'
import { ISelectOption, SelectValueType } from '../SelectTypes'
import s from './SelectRootItem.module.scss'

interface IItemProps<V extends SelectValueType> {
  item: ISelectOption<V>
}

const SelectValue = <V extends SelectValueType>({ item }: IItemProps<V>) => {
  const { handleChooseOption } = useSelectHandlers()

  const size = useSelectSize()
  const styleType = useSelectStyleType()
  const name = useSelectContext((c) => c.name)
  const isMultiple = useSelectContext((c) => c.isMultiple)
  const inputable = useSelectContext((c) => c.inputable)
  const disabled = useSelectContext((c) => c.disabled)
  const forceSearch = useSelectContext((c) => c.forceSearch)
  const readOnly = useSelectContext((c) => c.readOnly)
  const optionValidator = useSelectContext((c) => c.optionValidator)
  const hidden = forceSearch || (inputable && !isMultiple)

  const onRemoveClick = useCallback(
    (e: React.SyntheticEvent) => {
      e.stopPropagation()
      handleChooseOption?.(item)
    },
    [handleChooseOption, item],
  )

  const cnRoot = cn(s[size], s[styleType], {
    [s.tag]: isMultiple,
    [s.single]: !isMultiple,
    [s.disabled]: disabled,
    [s.error]: optionValidator?.(item),
  })

  if (hidden) {
    return null
  }

  return (
    <div
      {...testPropsEl(`selectItemRoot`, { ...item, name, label: item.textLabel || item.label })}
      className={cnRoot}
    >
      <span className={s.label}>
        <OptionLabel error={optionValidator?.(item)} option={item} size={size} type='root' />
      </span>
      {isMultiple && !readOnly && (
        <IconButton
          disabled={disabled}
          icon='otherClose'
          name='remove'
          onClick={onRemoveClick}
          size={KitSize.XS}
          styleType='ghost'
          tabIndex={-1}
        />
      )}
    </div>
  )
}

export default React.memo(SelectValue)
