import { generateId } from '@leenda/editor/lib/utils/id'
import { useCallback, useMemo } from 'react'

import { IControlProps } from 'components/controls/Field/Field.types'
import { t } from 'services/Translation'
import { testPropsEl } from 'utils/test/qaData'

import s from './ExternalFile.module.scss'

const ExternalFile: React.FC<IControlProps<{ value?: File | null }>> = ({
  name,
  onChange,
  ...rest
}) => {
  const id = useMemo(() => generateId(), [])
  const onChangeWrapper = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0]
      return onChange({ name, value: file })
    },
    [name, onChange],
  )

  return (
    <div className={s.root}>
      <input
        className={s.input}
        id={id}
        name={name}
        onChange={onChangeWrapper}
        type='file'
        {...testPropsEl('externalFile', { value: rest.value, name })}
      />
      <label className={s.label} htmlFor={id}>
        {rest?.value?.name ? (
          <span>{rest.value.name}</span>
        ) : (
          <span>{t('input.label.upload')}</span>
        )}
      </label>
    </div>
  )
}

export default ExternalFile
