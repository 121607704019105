import { TestInfoStartFontSchemaType, TestInfoStartSchemaType } from '@leenda/editor/lib/brand'
import { FileImageUsage } from '@leenda/editor/lib/files'

import { ElementFontCss, ElementStyleCss } from 'services/Branding/types'
import { useImageWithCrop } from 'utils/files'
import { testProps } from 'utils/test/qaData'

import './TestInfoItem.scss'

type TestInfoItemProps = {
  item?: string
  text?: string
  icon?: React.FC<React.SVGProps<SVGSVGElement>> | null
  show: boolean
  testInfoItemVariable?: string
  image: Omit<FileImageUsage, 'type'> | null
  styles: ElementStyleCss<TestInfoStartSchemaType>
  font?: ElementFontCss<TestInfoStartFontSchemaType>
}

const TestInfoItem = ({
  item,
  text,
  icon,
  show,
  testInfoItemVariable,
  image: imageProps,
  styles,
  font,
}: TestInfoItemProps) => {
  const Icon = icon || null

  const image = useImageWithCrop(imageProps)

  return (
    <li key={item}>
      <div
        className={`test-info-item test-info-item--isVisible--${show}`}
        {...testProps({ el: 'testInfoItem', name: item, val: testInfoItemVariable })}
      >
        {image?.path && (
          <img
            alt='test status image'
            className='test-info-item__img'
            src={image?.path}
            style={styles.circle}
          />
        )}
        {!image?.path && Icon && (
          <div className='test-info-item__icon' style={styles.circle}>
            <Icon />
          </div>
        )}
        <span className='test-info-item__text' style={font?.base}>
          {text} - {testInfoItemVariable}
        </span>
      </div>
    </li>
  )
}

export { TestInfoItem }
