import { CONFIG_ELEMENTS } from '@leenda/editor/lib/elements'

import { ElementGroup } from 'components/editor-v2/EditorElements/elements.types'
import { genElementConfig } from 'components/editor-v2/EditorElements/genElementConfig'
import { t } from 'services/Translation'

import Demo from '../Demo'
import Element from '../Element'
import ValueForm from '../Form'
import icon from '../assets/audio.svg'
import { STYLE_FORM_SCHEMA, FONT_FORM_SCHEMA } from './AudioBrandFormSchema'

export default genElementConfig(CONFIG_ELEMENTS.audio, {
  label: t('elements.audio.label'),
  group: ElementGroup.view,
  STYLE_FORM_SCHEMA,
  FONT_FORM_SCHEMA,
  icon,
  Element,
  ValueForm,
  Demo: Demo('audio'),
})
