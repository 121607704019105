import React from 'react'

import { CommentMock } from 'components/comments/types'
import { Typography } from 'components/uiKit/Typography'
import { t } from 'services/Translation'
import { testProps } from 'utils/test/qaData'

interface IReplyProps {
  comment: CommentMock
  onClick?: (comment: CommentMock) => void
}

const Reply: React.FC<IReplyProps> = ({ comment, onClick }) => {
  const { children } = comment

  const handleClick = () => onClick?.(comment)

  return (
    <Typography
      {...testProps({ el: 'responses', count: children?.length })}
      onClick={handleClick}
      styleType='link'
    >
      {t('entity.comment.answers', { count: children?.length })}
    </Typography>
  )
}

export default Reply
