import React, { useEffect } from 'react'

import DataFallback from 'components/DataFallback'
import { DataFallbackConfigType, DataFallbackTypeEnum } from 'components/DataFallback/types'
import { PROJECT_HOME_PATHS } from 'constants/paths'
import { courseStart } from 'services/Store/Project/actions'
import { SectionTypeEnum } from 'services/Store/Project/enums'
import { useProjectContext, useProjectDispatch } from 'services/Store/Project/hooks'
import { getSection, getSectionId } from 'services/Store/Project/selectors'
import { IBlockMode } from 'services/Store/Project/types'
import { t } from 'services/Translation'

import CourseCover from './CourseCover'
import CourseLanding from './CourseLanding'
import CourseTestSection from './CourseTestSection'

const CONFIG: DataFallbackConfigType = {
  position: 'static',
  backUrl: { path: PROJECT_HOME_PATHS.sections },
  title: t('page.notFound.sectionNotFound'),
  linkLabel: t('page.notFound.textLink.sections'),
  type: DataFallbackTypeEnum.notFound,
}

const CourseSectionMap = {
  [SectionTypeEnum.cover]: CourseCover,
  [SectionTypeEnum.landing]: CourseLanding,
  [SectionTypeEnum.test]: CourseTestSection,
  [SectionTypeEnum.chapter]: null,
}

const CourseSection: React.FC<{ mode: IBlockMode }> = ({ mode }) => {
  const dispatch = useProjectDispatch()
  const section = useProjectContext(getSection)
  const sectionId = useProjectContext(getSectionId)
  const cover = useProjectContext(getSection, { cover: true })
  const firstSection = useProjectContext(getSection, { first: true })

  const currentSection = sectionId ? section : cover
  const Section = (currentSection?.type && CourseSectionMap[currentSection?.type]) || CourseCover

  const startWithoutCover = Boolean(!sectionId && !cover && firstSection)

  useEffect(() => {
    if (startWithoutCover) {
      dispatch(courseStart())
    }
  }, [dispatch, startWithoutCover])

  return (
    <DataFallback
      config={CONFIG}
      data={currentSection}
      error={!currentSection}
      loading={startWithoutCover}
    >
      {(currentSection) => <Section mode={mode} section={currentSection} />}
    </DataFallback>
  )
}

export default CourseSection
