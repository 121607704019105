import * as R from 'ramda'

import { getNodeUsages } from 'components/editor-v3/context/EditorContext/selectors/block'
import { ElementState, IProjectContext, MapType } from 'services/Store/Project/types'

import { elementLens, elementsLens } from '../lenses'
import { ICompleteElementPayload } from '../reducer'
import { getBlock, getBlockElementsState, getDeviceMode } from '../selectors'
import { updateBlockState } from './common'

export const updateElement = (id: string, state: Partial<ElementState>) =>
  R.over(elementLens(id), (old) => R.mergeRight(old, state))

export const clearElements = (ids: string[]) => R.over(elementsLens, R.omit(ids))

export const clearElementValues = (ids: string[]) =>
  R.over(elementsLens, (elements) => ({
    ...elements,
    ...R.map<MapType<ElementState>, MapType<ElementState>>(
      //@ts-ignore
      R.pick(['initialItems']),
      //@ts-ignore
      R.pick(ids, elements),
    ),
  }))

export const completeElementInBlock = (
  state: IProjectContext,
  payload: ICompleteElementPayload,
) => {
  const { blockId, incomplete, elementId } = payload
  const elements = getBlockElementsState(state, blockId)
  const block = getBlock(state, { id: blockId })!
  if (!block) {
    return state
  }
  const deviceMode = getDeviceMode(state)
  const { usedElements } = getNodeUsages(block, { deviceMode })
  const fullState = { ...elements, [elementId]: { incomplete } }

  const hasIncompleteElement = usedElements.some((el) => fullState[el.id]?.incomplete)

  return R.compose(
    updateBlockState(blockId, { incomplete: hasIncompleteElement }),
    updateElement(elementId, { incomplete }),
  )(state)
}
