import { useMemo } from 'react'

import { TableContext } from './TableContext'
import Table from './TableCore'
import { ITablesExtendsData, PaginationConfig, SortConfig, TableProps } from './types'

interface IModalTableProps<T extends ITablesExtendsData> extends TableProps<T> {
  paginationConfig: PaginationConfig
  sortConfig: SortConfig
}

const ModalTable = <T extends ITablesExtendsData>(props: IModalTableProps<T>) => {
  const { paginationConfig, sortConfig } = props

  const value = useMemo(
    () => ({
      pagination: paginationConfig,
      sort: sortConfig,
    }),
    [paginationConfig, sortConfig],
  )

  return (
    <TableContext.Provider value={value}>
      <Table {...props} />
    </TableContext.Provider>
  )
}

export default ModalTable
