import { t } from 'i18next'
import React, { useMemo } from 'react'
import { useContextSelector } from 'use-context-selector'

import Empty from 'components/uiKit/Empty/Empty'
import Loader from 'components/uiKit/Loader'
import { EmployeePreferencesTab, TemplatesListSchemaFragment } from 'gql/__generated__/graphql'
import { useBlocksGetDeleted } from 'gql/blocks/apollo'
import { SectionTypeEnum } from 'services/Store/Project/enums'

import MasonryTemplate from '../MasonryTemplate'
import { TemplatePickerContext } from '../hooks'
import { TemplatePickerMode } from '../types'
import s from './ArchivedBlocksList.module.scss'

interface IArchivedBlocksListProps {}
const ArchivedBlocksList: React.FC<IArchivedBlocksListProps> = () => {
  const {
    companyId,
    search,
    sectionId,
    columns,
    type,
    step,
    allSelected,
    onClick,
    removeByIndex,
    projectId,
  } = useContextSelector(TemplatePickerContext, (value) => value)
  if (!sectionId) {
    throw new Error('Section is undefined')
  }

  const { data, loading } = useBlocksGetDeleted({ companyId, sectionId })

  const viewedBlocks = data?.data.slice(0, 20)

  const blocks = useMemo(() => {
    if (search) {
      return (viewedBlocks || []).filter((e) =>
        search
          .toLowerCase()
          .split(' ')
          .find((k) => e.name.toLowerCase().includes(k)),
      )
    }

    return viewedBlocks || []
  }, [search, viewedBlocks])

  const testBlocks = step === 1 ? blocks.filter((item) => item.mode === 'questions') : []
  const filteredBlocks = type === SectionTypeEnum.test ? testBlocks : blocks
  const isEmpty = !loading && !filteredBlocks.length

  const itemProps = useMemo(
    () => ({
      selected: allSelected,
      onClick,
      removeByIndex,
      search,
      companyId,
      projectId,
      tab: EmployeePreferencesTab.archived,
      type,
    }),
    [allSelected, search, removeByIndex, onClick, step, companyId, projectId, type],
  )

  return (
    <div className={s.root}>
      {isEmpty ? (
        <Empty search={Boolean(search)} text={t('modal.pickerTemplate.nothingFound')} grow />
      ) : loading ? (
        <Loader name='dataTemplatesLoader' resources />
      ) : (
        <MasonryTemplate
          columns={columns}
          itemProps={itemProps}
          items={(filteredBlocks as unknown as TemplatesListSchemaFragment[]) || []}
          mode={TemplatePickerMode.multi}
        />
      )}
    </div>
  )
}

export default ArchivedBlocksList
