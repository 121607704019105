import { EmbeddedSchemaType } from '@leenda/editor/lib/brand'
import { CONFIG_ELEMENTS } from '@leenda/editor/lib/elements'

import { DEMO_MODE } from 'services/Store/Project/constants'

import DemoWrapper from '../../decorators/DemoWrapper'
import { IElementDemoProps } from '../../elements.types'
import Element from './EmbeddedElement'

const EmbeddedDemo: React.FC<IElementDemoProps<EmbeddedSchemaType>> = ({ styles, fonts }) => (
  <DemoWrapper>
    <Element
      element={{
        id: `${CONFIG_ELEMENTS.embedded.type}-demo`,
        type: CONFIG_ELEMENTS.embedded.type,
        value: CONFIG_ELEMENTS.embedded.defaultValue,
        style: {},
        font: {},
      }}
      font={fonts}
      mode={DEMO_MODE}
      styles={styles}
    />
  </DemoWrapper>
)

export default EmbeddedDemo
