"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_VALUE = exports.TYPE = void 0;
const translation_1 = require("../../../translation");
const id_1 = require("../../../utils/id");
exports.TYPE = 'grouping';
exports.DEFAULT_VALUE = {
    items: [
        {
            value: (0, id_1.generateId)(),
            target: {
                label: (0, translation_1.t)('elements.grouping.group', { number: 1 }),
                image: null,
            },
            items: [
                {
                    value: (0, id_1.generateId)(),
                    label: (0, translation_1.t)('elements.grouping.answer', { number: 1 }),
                    image: null,
                },
            ],
        },
        {
            value: (0, id_1.generateId)(),
            target: {
                label: (0, translation_1.t)('elements.grouping.group', { number: 2 }),
                image: null,
            },
            items: [
                {
                    value: (0, id_1.generateId)(),
                    label: (0, translation_1.t)('elements.grouping.answer', { number: 1 }),
                    image: null,
                },
            ],
        },
    ],
};
