import React from 'react'

import AbsolutePortal from 'components/editor-v3/components/AbsolutePortal'
import { EMPTY_ARRAY } from 'constants/commonConstans'

import cs from '../Control.module.scss'
import s from './ControlSelection.module.scss'

interface IControlSelectionProps {
  active: boolean
  highlighted: boolean
  users?: Array<{
    employee: { id: string; color: string; kUser?: { name?: string | null } }
  }>
  label: string
  name: string
}

const ControlSelection: React.FC<IControlSelectionProps> = ({
  name,
  label,
  active,
  highlighted,
  users = EMPTY_ARRAY,
}) => {
  const me = active || highlighted
  const userColor = users.length ? users[0]?.employee.color : null
  const borderedBoxShadowStyle = me
    ? 'inset 0 0 0 1px var(--color-secondary-default)'
    : userColor
      ? `inset 0 0 0 1px ${userColor}`
      : ''
  return (
    <AbsolutePortal name={name}>
      <div className={cs.border} style={{ boxShadow: borderedBoxShadowStyle }}>
        <div className={s.labels}>
          {me && <div className={s.nodeLabel}>{label}</div>}
          {users.map((user) => (
            <div
              className={s.user}
              key={user?.employee.id}
              style={{ background: user?.employee.color }}
            >
              {user?.employee.kUser?.name}
            </div>
          ))}
        </div>
      </div>
    </AbsolutePortal>
  )
}

export default ControlSelection
