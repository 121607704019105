import { TimePoint } from '@leenda/editor/lib/elements'
import cn from 'classnames'
import React, { useCallback, useMemo } from 'react'

import { MediaStyles } from '../../types'
import s from './SeekSlider.module.scss'

interface ISeekSliderProps {
  onStartSeeking: () => void
  onSetPlayed: (seconds: number) => void
  onSeekTo: (seconds: number) => void
  played?: number
  loaded?: number
  duration: number
  styles?: Partial<Record<MediaStyles, React.CSSProperties>>
  points: TimePoint[]
  preview?: boolean
}

const SeekSlider: React.FC<ISeekSliderProps> = ({
  played = 0,
  loaded = 0,
  duration,
  styles,
  points,
  onSetPlayed,
  onStartSeeking,
  onSeekTo,
  preview,
}) => {
  const loadedStyles: React.CSSProperties = useMemo(
    () => ({
      ...styles?.sliderFilled,
      width: `${loaded * 100}%`,
    }),
    [styles?.sliderFilled, loaded],
  )

  const playedStyles: React.CSSProperties = useMemo(
    () => ({
      ...styles?.sliderFilled,
      width: `${played * 100}%`,
    }),
    [styles?.sliderFilled, played],
  )

  const pointStyles = useCallback(
    (time: number): React.CSSProperties => ({
      left: `${(time / duration) * 100}%`,
      backgroundColor: styles?.player?.backgroundColor,
    }),
    [styles?.player?.backgroundColor],
  )

  const handleStartSeeking = (
    event: React.MouseEvent<HTMLInputElement, MouseEvent> | React.TouchEvent<HTMLInputElement>,
  ) => {
    event.stopPropagation()
    onStartSeeking()
  }

  const handleSeekTo = (
    event: React.MouseEvent<HTMLInputElement, MouseEvent> | React.TouchEvent<HTMLInputElement>,
  ) => {
    event.stopPropagation()
    onSeekTo(parseFloat(event.currentTarget.value) * duration)
  }

  const handleSetPlayed = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation()
    onSetPlayed(parseFloat(event.currentTarget.value) * duration)
  }
  return (
    <div className={s.root}>
      <div className={cn(s.track, { [s.preview]: preview })} style={styles?.slider}>
        <div className={s.loaded} style={loadedStyles} />
        <div className={s.played} style={playedStyles} />
        <div className={s.points}>
          {points.map(({ time }) => (
            <div className={s.point} key={time} style={pointStyles(time)} />
          ))}
        </div>
      </div>
      <input
        className={s.input}
        max={1}
        min={0}
        onChange={handleSetPlayed}
        onMouseDown={handleStartSeeking}
        onMouseUp={handleSeekTo}
        onTouchEnd={handleSeekTo}
        onTouchEndCapture={handleSeekTo}
        onTouchStart={handleStartSeeking}
        onTouchStartCapture={handleStartSeeking}
        step={0.001}
        type='range'
        value={played}
      />
    </div>
  )
}

export default SeekSlider
