"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_VALUE = exports.TYPE = void 0;
const types_1 = require("./types");
const translation_1 = require("../../../translation");
exports.TYPE = 'embedded';
exports.DEFAULT_VALUE = {
    type: types_1.EmbeddedSourceEnum.html,
    html: `<p>${(0, translation_1.t)('elements.embedded.label')}</p>`,
    zipFile: null,
};
