import { useEffect, useRef, useState } from 'react'

export const useDelayClose = (open: boolean, delay: number) => {
  const [render, setRender] = useState(false)
  const timeout = useRef<ReturnType<typeof setTimeout>>()

  useEffect(() => {
    if (timeout.current) {
      clearTimeout(timeout.current)
    }
    if (open) {
      setRender(true)
    } else {
      timeout.current = setTimeout(() => {
        setRender(false)
        timeout.current = undefined
      }, delay)
    }
    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current)
      }
    }
  }, [open])

  return render
}

type AnimatedNumberState = {
  renderIndex: number
  viewedIndex: number
  timeout: ReturnType<typeof setTimeout> | undefined
}

export const useAnimatedNumberState = (interval = 500, initialValue = -1) => {
  const [renderIndex, setRenderIndex] = useState(initialValue)
  const ref = useRef<AnimatedNumberState>({
    renderIndex: initialValue,
    viewedIndex: initialValue,
    timeout: undefined,
  })
  const check = () => {
    if (ref.current.viewedIndex > ref.current.renderIndex) {
      ref.current.renderIndex += 1
      setRenderIndex(ref.current.renderIndex)
      ref.current.timeout = setTimeout(check, interval)
    } else {
      ref.current.timeout = undefined
    }
  }

  useEffect(() => {
    check()
    return () => {
      clearTimeout(ref.current.timeout)
    }
  }, [])

  const onViewIndex = (i: number) => {
    ref.current.viewedIndex = i
    if (!ref.current.timeout) {
      check()
    }
  }

  return [renderIndex, onViewIndex] as const
}
