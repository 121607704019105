import React from 'react'

import ContactSalesButton from 'components/ContactSalesButton'
import Banner from 'components/uiKit/Banner'
import { KitSize } from 'components/uiKit/KitTypes'
import { ContractType } from 'gql/__generated__/graphql'
import { getEnumLabel } from 'utils/enum'

import s from './LicenseExpiration.module.scss'

interface ILicenseExpirationProps {
  daysLeft: number
  onClose: () => void
  contractType: ContractType
  hasContactSales: boolean
}
const LicenseExpiration: React.FC<ILicenseExpirationProps> = ({
  daysLeft,
  onClose,
  contractType,
  hasContactSales,
}) => {
  const config = { email: 'nik@nik.nik', name: 'nik nikit' }
  const textWarning = getEnumLabel('ContractExpirationWarning', contractType, {
    count: daysLeft,
  })
  const textExpired = getEnumLabel('ContractExpired', contractType, {
    count: daysLeft,
  })

  const bannerText = daysLeft < 0 ? textExpired : textWarning

  return (
    <Banner onClose={onClose} type={daysLeft < 0 ? 'error' : 'warning'}>
      <div className={s.root}>
        <div className={s.textInfo}>{bannerText}</div>
        {hasContactSales && <ContactSalesButton {...config} size={KitSize.XS} />}
      </div>
    </Banner>
  )
}

export default LicenseExpiration
