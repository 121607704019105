import React from 'react'

import Padding from 'components/editor-v3/Editor/Toolbar/Padding'
import { Typography } from 'components/uiKit/Typography'
import { t } from 'services/Translation'

import { TestRankingValueFormType } from './TestRankingElement.types'

const TestRankingForm: TestRankingValueFormType = () => (
  <Padding sides>
    <Typography styleType='hint'>{t('elements.testRanking.form.randomHint')}</Typography>
  </Padding>
)
export default TestRankingForm
