import { ElementSchemaType } from '@leenda/editor/lib/brand'
import * as R from 'ramda'

import { IFieldConfig } from 'components/controls/Field/Field.types'
import { genField, mergeFields, mergeParams } from 'components/controls/Field/genField'
import { DeviceMode } from 'services/Store/Project/enums'
import { t } from 'services/Translation'
import { getParent } from 'utils/form'

import { DEFAULT_BRAND_FIELDS } from './constants/fields'
import { FONTS_FULL_SET_LABELS } from './constants/labels'

export type BrandFormSchemaType<_ extends ElementSchemaType = ElementSchemaType> = Record<
  string,
  Record<string, IFieldConfig | BrandFormSchemaMeta> | BrandFormSchemaMeta
>

type DefaultFieldsType = typeof DEFAULT_BRAND_FIELDS
export type BrandFormSchemaMeta = {
  __type: 'META'
  order?: number
  edited?: boolean
  hidden?: boolean
  hiddenDivider?: boolean
  label?: string
}

export const hiddenByDevice = (data: unknown, config: IFieldConfig) => ({
  hidden: !getParent(config.name, data).parentName.split('.').includes(DeviceMode.desktop),
})

export const FONT_SCHEMA_FIELDS = {
  base: {
    META: { label: FONTS_FULL_SET_LABELS.base },
    fontFamily: { params: hiddenByDevice },
    fontWeight: { params: hiddenByDevice },
    fontStyle: { params: hiddenByDevice },
    fontSize: {},
    lineHeight: {},
    color: { params: hiddenByDevice },
  },
  h1: {
    META: { label: FONTS_FULL_SET_LABELS.h1 },
    fontFamily: { params: hiddenByDevice },
    fontWeight: { params: hiddenByDevice },
    fontStyle: { params: hiddenByDevice },
    fontSize: {},
    lineHeight: {},
    color: { params: hiddenByDevice },
  },
  h2: {
    META: { label: FONTS_FULL_SET_LABELS.h2 },
    fontFamily: { params: hiddenByDevice },
    fontWeight: { params: hiddenByDevice },
    fontStyle: { params: hiddenByDevice },
    fontSize: {},
    lineHeight: {},
    color: { params: hiddenByDevice },
  },
  h3: {
    META: { label: FONTS_FULL_SET_LABELS.h3 },
    fontFamily: { params: hiddenByDevice },
    fontWeight: { params: hiddenByDevice },
    fontStyle: { params: hiddenByDevice },
    fontSize: {},
    lineHeight: {},
    color: { params: hiddenByDevice },
  },
  h4: {
    META: { label: FONTS_FULL_SET_LABELS.h4 },
    fontFamily: { params: hiddenByDevice },
    fontWeight: { params: hiddenByDevice },
    fontStyle: { params: hiddenByDevice },
    fontSize: {},
    lineHeight: {},
    color: { params: hiddenByDevice },
  },
  caption: {
    META: { label: FONTS_FULL_SET_LABELS.caption },
    fontFamily: { params: hiddenByDevice },
    fontWeight: { params: hiddenByDevice },
    fontStyle: { params: hiddenByDevice },
    fontSize: {},
    lineHeight: {},
    color: { params: hiddenByDevice },
  },
  list: {
    META: { label: FONTS_FULL_SET_LABELS.list },
    fontFamily: { params: mergeParams({ hidden: true }, hiddenByDevice) },
    fontWeight: { params: hiddenByDevice },
    fontStyle: { params: hiddenByDevice },
    fontSize: { params: { hidden: true } },
    lineHeight: { params: { hidden: true } },
    spacing: {},
    gap: {},
    color: { params: hiddenByDevice },
  },
  quote: {
    META: { label: FONTS_FULL_SET_LABELS.quote },
    fontFamily: { params: mergeParams({ hidden: true }, hiddenByDevice) },
    fontWeight: { params: hiddenByDevice },
    fontStyle: { params: hiddenByDevice },
    fontSize: { params: { hidden: true } },
    lineHeight: {},
    color: { params: hiddenByDevice },
    borderLeftColor: { label: t('input.label.delimiter'), params: hiddenByDevice },
  },
  link: {
    META: { label: FONTS_FULL_SET_LABELS.link },
    fontFamily: { params: mergeParams({ hidden: true }, hiddenByDevice) },
    fontWeight: { params: mergeParams({ hidden: true }, hiddenByDevice) },
    fontStyle: { params: mergeParams({ disabled: true }, hiddenByDevice) },
    fontSize: { params: { hidden: true } },
    lineHeight: { params: { hidden: true } },
    color: { params: hiddenByDevice },
    textDecoration: { params: hiddenByDevice },
  },
}

type CustomType<Schema = ElementSchemaType> = {
  [T in keyof Schema]: {
    [S in keyof Schema[T]]: S extends keyof DefaultFieldsType
      ? Partial<DefaultFieldsType[S]> | IFieldConfig | never
      : IFieldConfig
  } & { META?: Partial<BrandFormSchemaMeta> }
} & { META?: Partial<BrandFormSchemaMeta> }

const genMeta = (meta?: Partial<BrandFormSchemaMeta>): BrandFormSchemaMeta => ({
  __type: 'META',
  ...meta,
})
export const isMeta = (
  value?: Record<string, IFieldConfig | BrandFormSchemaMeta> | IFieldConfig | BrandFormSchemaMeta,
): value is BrandFormSchemaMeta => value?.__type === 'META'

export const genFormSchema = <S extends ElementSchemaType, F extends CustomType<S>>(
  _: S,
  { META, ...custom }: F,
): BrandFormSchemaType<S> => {
  let groupOrder = 1
  return {
    META: genMeta(META),
    ...R.mapObjIndexed(({ META, ...group }, groupKey) => {
      let fieldOrder = 1
      return {
        META: genMeta({ order: groupOrder++, ...META }),
        ...R.mapObjIndexed((field, fieldKey) => {
          return genField(
            mergeFields(DEFAULT_BRAND_FIELDS[fieldKey], {
              order: fieldOrder++,
              name: [groupKey, fieldKey].join('.'),
              ...field,
            }),
          )
        }, group),
      }
    }, custom),
  }
}
