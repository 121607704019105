import cn from 'classnames'
import React, { useState, useEffect, ReactNode } from 'react'

import { KitSize } from 'components/uiKit/KitTypes'
import { testPropsEl } from 'utils/test/qaData'

import TabMenu from '../TabMenu'
import { ITabItemProps } from '../TabMenu/TabMenu'
import TabContent from './TabContent/TabContent'
import { ITabPanelProps } from './TabPanel/TabPanel'
import s from './Tabs.module.scss'
import { ITabsMain } from './types'

export interface ITabsProps<V> extends Partial<ITabsMain> {
  children: ReactNode
  name: string
  defaultActiveKey?: V
  onChange?: (tabKey: V) => void
  tabsStyle?: React.CSSProperties
  tabMenuStyle?: React.CSSProperties
  tabContentStyle?: React.CSSProperties
  activeKey?: V
  tabBarExtraContent?: { right?: ReactNode | string; left?: ReactNode | string; grow?: boolean }
  tabsGrow?: boolean
  destroyInactiveTabPane?: boolean
}

const Tabs = <V extends string>({
  children,
  name,
  styleType = 'default',
  direction = 'top',
  onChange: onChangeProps,
  tabsStyle,
  grow,
  defaultActiveKey,
  tabPosition = 'tabLeft',
  activeKey: activeKeyProps,
  tabBarExtraContent,
  size = KitSize.M,
  tabsGrow,
  destroyInactiveTabPane = true,
  tabContentStyle,
  tabMenuStyle,
}: ITabsProps<V>) => {
  const tabList: ITabItemProps<V>[] = []
  React.Children.forEach(children, (child: React.ReactNode) => {
    if (!React.isValidElement<ITabPanelProps<V>>(child)) {
      return
    }
    const element: React.ReactElement<ITabPanelProps<V>> = child

    tabList.push({
      value: element.props.tabKey,
      label: element.props.title,
      disabled: element.props.disabled,
      hidden: element.props.hidden,
    })
  })

  const [activeKeyState, setActiveKeyState] = useState(
    activeKeyProps || defaultActiveKey || tabList[0]?.value || '',
  )

  useEffect(() => {
    if (activeKeyProps !== undefined) {
      setActiveKeyState(activeKeyProps)
    }
  }, [activeKeyProps])

  const activeKey = tabList.findIndex((tab) => tab.value === activeKeyState)
    ? activeKeyState
    : defaultActiveKey && tabList.findIndex((tab) => tab.value === defaultActiveKey)
      ? defaultActiveKey
      : tabList[0].value

  const cnRoot = cn(s.root, s[direction])

  const onChange = (tabKey: V) => {
    setActiveKeyState(tabKey)
    onChangeProps && onChangeProps(tabKey)
  }

  return (
    <div className={cnRoot} {...testPropsEl('tabs', { name })} style={tabsStyle}>
      <TabMenu
        activeKey={activeKey as V}
        direction={direction}
        grow={grow}
        onChange={onChange}
        size={size}
        styleType={styleType}
        tabBarExtraContent={tabBarExtraContent}
        tabList={tabList}
        tabMenuStyle={tabMenuStyle}
        tabPosition={tabPosition}
        tabsGrow={tabsGrow}
      />
      <div className={s.content} style={tabContentStyle}>
        {React.Children.map(children, (child: React.ReactNode) => {
          if (!React.isValidElement<ITabPanelProps<V>>(child)) {
            return null
          }
          const element: React.ReactElement<ITabPanelProps<V>> = child
          const { children, tabKey, textError, hidden } = element.props
          if ((tabKey !== activeKey && destroyInactiveTabPane) || hidden) {
            return null
          }
          return (
            <TabContent hidden={tabKey !== activeKey} key={tabKey} textError={textError}>
              {children}
            </TabContent>
          )
        })}
      </div>
    </div>
  )
}

export default Tabs
