import React from 'react'

import { IControlProps } from 'components/controls/Field/Field.types'
import Radio, { IRadioProps } from 'components/uiKit/Radio'

const FieldRadio: React.FC<IControlProps<IRadioProps>> = ({
  value,
  onChange,
  name,
  defaultValue,
  ...rest
}) => {
  const handleOnChange = (value: IRadioProps['value']) => {
    onChange({ value, name })
  }

  return (
    <Radio
      {...rest}
      name={String(name)}
      onChange={handleOnChange}
      value={value ?? (defaultValue as string)}
    />
  )
}

export default FieldRadio
