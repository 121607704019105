import { SCHEMA } from '@leenda/editor/lib/brand'

import { genFormSchema } from 'services/Branding/genFormSchema'

export const STYLE_FORM_SCHEMA = genFormSchema(SCHEMA.image.style, {
  image: {
    objectFit: {},
    META: { hidden: true },
    borderRadius: {},
    borderStyle: {},
    borderColor: {},
    borderWidth: {},
  },
})
