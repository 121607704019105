import moment from 'moment'
import * as R from 'ramda'

import { TaskMock } from 'components/uiKit/Task'
import { TaskStatus } from 'gql/__generated__/graphql'
import { IProjectTasksFilterValues, IProjectContext } from 'services/Store/Project/types'

export const getActiveTask = ({ data, state }: IProjectContext) =>
  data.tasks.find((task) => task.id === state.tasks.opened?.taskId) || null

export const getProgressTasks = ({ data, state }: IProjectContext) =>
  R.filter(filterTasks({ ...state.tasks.filter }), data.tasks)

export const getDoneTasks = ({ data, state }: IProjectContext) =>
  R.compose(
    R.filter(
      filterTasks({
        ...state.tasks.filter,
        status: [TaskStatus.done],
        deadlineFrom: null,
        deadlineTo: null,
      }),
    ),
  )(data.tasks)

export const getIsTaskedBlock = ({ data, state }: IProjectContext, blockUuid?: string) => {
  const tasks = R.filter(filterTasks({ ...state.tasks.filter, blockUuid }), data.tasks)
  return tasks[0] || null
}

export const getTasksFilter = ({ state }: IProjectContext) => state.tasks.filter

export const getTaskId = ({ state }: IProjectContext) => state.tasks.opened?.taskId || null

const filterTasks = (values: Partial<IProjectTasksFilterValues>) => (task: TaskMock) => {
  const { blockUuid, status, assignedIds, deadlineFrom, deadlineTo } = values
  if (!R.isNil(blockUuid) && blockUuid !== task.blockUuid) {
    return false
  }
  if (!R.isNil(status) && !status.includes(task.status)) {
    return false
  }
  if (
    assignedIds?.length &&
    !R.intersection(assignedIds, R.map(R.prop('id'), task.assigned || [])).length
  ) {
    return false
  }
  if (!R.isNil(deadlineFrom) && moment(task.deadline).isBefore(deadlineFrom)) {
    return false
  }
  if (!R.isNil(deadlineTo) && moment(task.deadline).isAfter(deadlineTo)) {
    return false
  }
  return true
}
