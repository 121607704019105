"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isBlockElement = exports.isListElement = exports.isInlineElement = exports.isTextNode = void 0;
const types_1 = require("../types");
const constants_1 = require("../constants");
const isTextNode = (e) => {
    return !e.type && typeof e.text === 'string';
};
exports.isTextNode = isTextNode;
const isInlineElement = (e) => {
    var _a;
    return constants_1.INLINE_ELEMENTS.includes(e === null || e === void 0 ? void 0 : e.type) && ((_a = e === null || e === void 0 ? void 0 : e.children) === null || _a === void 0 ? void 0 : _a.every(exports.isTextNode));
};
exports.isInlineElement = isInlineElement;
const isListElement = (e) => {
    return constants_1.LIST_ELEMENTS.includes(e === null || e === void 0 ? void 0 : e.type);
};
exports.isListElement = isListElement;
const isBlockElement = (e) => {
    return (Object.values(types_1.SlateElementType).includes(e === null || e === void 0 ? void 0 : e.type) && !(0, exports.isInlineElement)(e) && !(0, exports.isListElement)(e));
};
exports.isBlockElement = isBlockElement;
