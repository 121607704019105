import { TestInfoEndElementValue, TestInfoStartElementValue } from '@leenda/editor/lib/elements'

import { OPTIONS } from './TestInfoElement.constants'
import { PageItem } from './TestInfoElement.types'

const getTextInfoAppearance = (
  item: PageItem,
  value: TestInfoStartElementValue | TestInfoEndElementValue,
) => {
  const options = OPTIONS[item]
  const valueConfig = value[options.name]

  return {
    ...options,
    ...valueConfig,
  }
}

export { getTextInfoAppearance }
