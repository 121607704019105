import { useCallback, useContext, useEffect, useState } from 'react'
import { useHistory, RouteComponentProps } from 'react-router'

import { usePathActive, usePathParamsSafe } from 'routes/hooks'
import { LinkType, RouteState } from 'routes/routes.types'

import { MCWithParams, ModalContext, useModalContainer } from './ModalContext'
import ModalRender from './ModalRender'
import { EXIT_ANIMATION_DURATION } from './contants'

interface IRouterModalProps<P, R> {
  path: string
  backPath?: LinkType<RouteState> | string
  component: MCWithParams<P, R>
}

const CHANGE_ROUTE_DELAY = EXIT_ANIMATION_DURATION * 1000 + 50

const RouterModal = <P extends RouteComponentProps, R>({
  path,
  component,
  backPath,
}: IRouterModalProps<P, R>) => {
  const activeRoute = usePathActive(path)
  const params = usePathParamsSafe(path) as any
  const [open, setOpen] = useState(false)
  const { modals } = useContext(ModalContext)

  const { push } = useHistory()
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const getContainer = useModalContainer()
  const onClose = useCallback(() => {
    setOpen(false)
    setTimeout(() => {
      backPath ? push(backPath) : history.back()
    }, CHANGE_ROUTE_DELAY)
  }, [backPath])

  useEffect(() => {
    if (activeRoute) {
      setOpen(true)
    }
  }, [activeRoute])
  const hasOpen = [...modals.values()].some((modal) => modal.open)
  return (
    <ModalRender
      component={component}
      currentModalIndex={hasOpen ? 0 : -1}
      getContainer={getContainer}
      index={-1}
      onClose={onClose}
      open={open}
      params={params}
    />
  )
}

export default RouterModal
