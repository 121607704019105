import { SCHEMA } from '@leenda/editor/lib/brand'
import React from 'react'

import { genField } from 'components/controls/Field'
import Divider from 'components/uiKit/Divider'
import Icon, { KeyIconsType } from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'
import { ISelectOption } from 'components/uiKit/Select/SelectTypes'
import { genFormSchema } from 'services/Branding/genFormSchema'
import { t } from 'services/Translation'

import s from './RectangleForm.module.scss'

const createBorderLabel = (iconName: KeyIconsType, text: string) => {
  const Label: React.FC = () => (
    <div className={s.label}>
      <Icon name={iconName} size={KitSize.S} /> {text}
    </div>
  )
  return Label
}

const BORDER_OPTIONS: ISelectOption<string>[] = [
  {
    value: 'none',
    label: '',
    options: [{ label: t('input.option.withoutBorder'), value: 'none' }],
  },
  {
    value: 'valuable',
    label: <Divider free />,
    options: [
      { value: 'solid', label: createBorderLabel('solid', t('input.option.solid')) },
      { value: 'dashed', label: createBorderLabel('dash', t('input.option.dashed')) },
      { value: 'dotted', label: createBorderLabel('dots', t('input.option.dotted')) },
    ],
  },
]

export const STYLE_FORM_SCHEMA = genFormSchema(SCHEMA.rectangle.style, {
  rectangle: {
    META: { hidden: true },
    backgroundColor: {},
    borderRadius: {},
  },
  border: {
    META: { hidden: true },
    borderStyle: genField({
      type: 'select',
      label: t('input.label.borderType'),
      params: {
        options: BORDER_OPTIONS,
        fluid: true,
      },
    }),
    borderColor: {},
    borderWidth: {},
  },
})
