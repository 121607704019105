import { FC } from 'react'

import Button from 'components/uiKit/Button'
import Icon, { KeyIconsType } from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'

import s from './NoBlock.module.scss'

interface INoBlockProps {
  config?: {
    description: string | JSX.Element
    buttonLabel: string
    icon?: KeyIconsType
  }
  onClick: () => void
}

const NoBlock: FC<INoBlockProps> = ({ config, onClick }) => {
  const { description, buttonLabel, icon } = config || {}

  return (
    <div className={s.root}>
      <p className={s.info}>{description}</p>
      <div className={s.buttonWrapper}>
        <Button name='createFirstBlock' onClick={() => onClick()}>
          {icon && <Icon name={icon} size={KitSize.M} />}
          {buttonLabel}
        </Button>
      </div>
    </div>
  )
}

export default NoBlock
