import { testProps } from 'utils/test/qaData'

import s from './LayoutTitle.module.scss'

const LayoutTitle: React.FC<{ name?: string; children: React.ReactNode }> = ({
  children,
  name,
}) => (
  <h2 className={s.root} {...testProps({ el: 'title', name })}>
    {children}
  </h2>
)

export default LayoutTitle
