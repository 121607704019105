import { SidebarFontSchemaType, SidebarSchemaType } from '@leenda/editor/lib/brand'
import {
  CollapseIconPosition,
  CourseStructureItem,
  ProgressIconPosition,
} from '@leenda/editor/lib/elements'
import cn from 'classnames'

import { Description } from 'components/editor-v3/cource/controls/CourseSidebar/components/Description'
import { getIconData } from 'components/editor-v3/cource/controls/CourseSidebar/utils'
import { ISectionTreeNode } from 'components/sections/ProjectStructure/utils'
import CollapseIcon from 'components/uiKit/Collapse/CollapseIcon'
import { ElementFontCss, ElementStyleCss } from 'services/Branding/types'
import { useProjectContext } from 'services/Store/Project/hooks'
import { isSectionDisabled } from 'services/Store/Project/selectors'

import SectionStatus from '../SectionStatus/SectionStatus'
import { StructureItemCourse } from '../StructureItemCourse'
import s from './StructureNodeCourse.module.scss'

export const LEVEL_INDENT = 30
interface ITreeNodeProps {
  toggle?(active?: boolean): void
  open: boolean
  node: ISectionTreeNode<CourseStructureItem>
  onClick?: () => void
  active: boolean
  styles: ElementStyleCss<SidebarSchemaType>
  font: ElementFontCss<SidebarFontSchemaType>
}

const getGridTemplateStyle = (styles: ElementStyleCss<SidebarSchemaType>, showIcon?: boolean) => {
  let gridTemplateColumnsValue = ''
  const topRow: string[] = []
  const bottomRow: string[] = []
  const progressValue = 'minmax(min-content, max-content) '
  const iconValue = 'minmax(min-content, max-content) '
  const sectionNameValue = 'minmax(min-content, auto) '

  if (styles.value.collapsePosition === CollapseIconPosition.leftOutside) {
    topRow.push('collapse')
    bottomRow.push('.')
    gridTemplateColumnsValue += iconValue
  }
  if (styles.value.progressPosition === ProgressIconPosition.left) {
    topRow.push('progress')
    bottomRow.push('line')
    gridTemplateColumnsValue += progressValue
  }
  if (styles.value.collapsePosition === CollapseIconPosition.left) {
    topRow.push('collapse')
    bottomRow.push('.')
    gridTemplateColumnsValue += iconValue
  }

  if (showIcon) {
    topRow.push('icon')
    bottomRow.push('.')
    gridTemplateColumnsValue += iconValue
  }

  topRow.push('section')
  bottomRow.push('description')
  gridTemplateColumnsValue += sectionNameValue

  if (styles.value.collapsePosition === CollapseIconPosition.right) {
    topRow.push('collapse')
    bottomRow.push('.')
    gridTemplateColumnsValue += iconValue
  }
  if (styles.value.progressPosition === ProgressIconPosition.right) {
    topRow.push('progress')
    bottomRow.push('line')
    gridTemplateColumnsValue += progressValue
  }
  if (styles.value.collapsePosition === CollapseIconPosition.rightOutside) {
    topRow.push('collapse')
    bottomRow.push('.')
    gridTemplateColumnsValue += iconValue
  }

  const preparedTemplateAreas = [topRow, bottomRow]

  const getGridTemplateAreas = (gridLayout: string[][]) =>
    gridLayout.map((row) => `"${row.join(' ')}"`).join(' ')

  const gridTemplateAreasValue = getGridTemplateAreas(preparedTemplateAreas)

  return {
    gridTemplateAreas: gridTemplateAreasValue,
    gridTemplateColumns: gridTemplateColumnsValue.trim(),
  }
}

const StructureNodeCourse: React.FC<ITreeNodeProps> = ({
  open,
  node,
  onClick,
  toggle,
  active,
  styles,
  font,
}) => {
  const { item } = node
  const nestedOffset = item.lvl * LEVEL_INDENT
  const disabled = useProjectContext(isSectionDisabled, item.id)

  styles.value.collapsePosition

  const iconPosition = [CollapseIconPosition.right, CollapseIconPosition.rightOutside].includes(
    styles.value.collapsePosition as CollapseIconPosition,
  )
    ? 'right'
    : 'left'

  const collapseIcon = (
    <div className={s.collapseIcon} onClick={() => toggle?.()}>
      {item.isChapter && toggle && styles.value.collapsePosition !== CollapseIconPosition.none && (
        <CollapseIcon iconPosition={iconPosition} open={open} />
      )}
    </div>
  )

  const onClickHandler = () => {
    if (disabled) {
      return
    }
    if (item.isChapter) {
      toggle?.()
    } else {
      onClick?.()
    }
  }

  const rootCn = cn(s.root, {
    [s.cursorOpen]: item.isChapter && open,
    [s.cursorClose]: item.isChapter && !open,
    [s.cursorPointer]: !item.isChapter,
    [s.active]: active,
    [s.disabled]: disabled,
  })

  const spaceBetweenFromSections = styles?.margins.offsetYFromSection
  const collapseOffset = styles?.margins.offsetFromCollapse
  const { showIcon } = getIconData(item.type, styles, font)

  const rootStyles = {
    paddingTop: spaceBetweenFromSections,
    paddingBottom: spaceBetweenFromSections,
    ...getGridTemplateStyle(styles, showIcon),
  }

  return (
    <div className={rootCn} onClick={onClickHandler} style={rootStyles}>
      {/* row1 */}
      {styles.value.collapsePosition === CollapseIconPosition.leftOutside && (
        <div className={s.collapse} style={{ marginRight: collapseOffset }}>
          {collapseIcon}
        </div>
      )}
      {styles.value.progressPosition === ProgressIconPosition.left && (
        <div className={s.sectionStatus}>
          <SectionStatus disabled={disabled} node={node} open={open} styles={styles} />
        </div>
      )}
      {styles.value.collapsePosition === CollapseIconPosition.left && (
        <div className={s.nodeIndent} style={{ marginRight: collapseOffset }}>
          {collapseIcon}
        </div>
      )}
      <StructureItemCourse
        font={font}
        item={node.item}
        nestedOffset={nestedOffset}
        styles={styles}
      />
      {styles.value.collapsePosition === CollapseIconPosition.right && (
        <div className={cn(s.nodeIndent, s.collapse)} style={{ marginRight: collapseOffset }}>
          {collapseIcon}
        </div>
      )}
      {styles.value.progressPosition === ProgressIconPosition.right && (
        <div className={s.sectionStatus}>
          <SectionStatus disabled={disabled} node={node} open={open} styles={styles} />
        </div>
      )}
      {styles.value.collapsePosition === CollapseIconPosition.rightOutside && collapseIcon}
      {/* row2 */}
      {styles.value.progressPosition === ProgressIconPosition.left && (
        <div className={s.line}>
          <SectionStatus
            disabled={disabled}
            node={node}
            open={open}
            styles={styles}
            hiddenProgress
          />
        </div>
      )}
      {styles?.value.showDescription && (
        <div className={s.description} style={{ marginLeft: nestedOffset }}>
          <Description childrenChapter={node.children?.length || 0} font={font} item={item} />
        </div>
      )}
      {styles.value.progressPosition === ProgressIconPosition.right && (
        <div className={s.line}>
          <SectionStatus
            disabled={disabled}
            node={node}
            open={open}
            styles={styles}
            hiddenProgress
          />
        </div>
      )}
    </div>
  )
}

export default StructureNodeCourse
