import React from 'react'

import { genDataField } from 'components/controls/Field'
import RealtimeField from 'components/controls/RealtimeField'
import Padding from 'components/editor-v3/Editor/Toolbar/Padding'
import Divider from 'components/uiKit/Divider'
import { Block } from 'services/Store/Project/types'
import { t } from 'services/Translation'
import { chainName } from 'utils/form'

import { OPTIONS, PAGE_ITEMS } from './TestInfoElement.constants'
import { TestInfoValueFormType, PageItem } from './TestInfoElement.types'
import './TestInfoForm.scss'

const genField = genDataField<Block>()

const show = genField({
  type: 'segmented',
  layout: 'horizontal',
  label: t('input.label.show'),
  name: 'show',
  defaultValue: false,
})

const label = genField({
  type: 'text',
  name: 'text',
  layout: 'horizontal',
})

const icon = genField({
  type: 'file',
  name: 'icon',
  params: { fileType: 'image', nullable: true },
  layout: 'horizontal',
})

const TestInfoForm: TestInfoValueFormType = ({ name, element }) => {
  const ITEMS = PAGE_ITEMS[element.value.type]

  return (
    <>
      {ITEMS.map((item) => (
        <TestInfoItem item={item} key={item} name={name} />
      ))}
    </>
  )
}

const TestInfoItem: React.FC<{ name: string; item: PageItem }> = ({ name, item }) => {
  const { title, name: itemName } = OPTIONS[item]

  return (
    <Padding sides>
      <p>{title}</p>
      <RealtimeField config={chainName(name, itemName, show)} />
      <RealtimeField config={chainName(name, itemName, label)} />
      <RealtimeField config={chainName(name, itemName, icon)} />
      <Divider />
    </Padding>
  )
}

export default TestInfoForm
