import lodash from 'lodash'
import { useRef, useState, useMemo, useCallback } from 'react'
import React from 'react'

import { SHELL_SIZE_KEYS } from 'components/editor-v3/types/data.constants'
import { DeviceMode, PreviewMode } from 'services/Store/Project/enums'
import { useImageWithCrop } from 'utils/files'

import ResponsiveImage from '../../ResponsiveImage'
import { useElementCompleted } from '../../hooks/useElementCompleted'
import { useElementRootStyles } from '../../hooks/useElementRootStyles'
import { LabeledGraphicElementType } from './LabeledGraphic.types'
import LabeledGraphicContext from './LabeledGraphicContext'
import s from './LabeledGraphicElement.module.scss'
import MobileView from './MobilePointsView'
import PdfComponent from './PfdComponent'
import Point from './Point'
import mock from './assets/imageDemo.svg'

const LabeledGraphicElement: LabeledGraphicElementType = ({
  mode,
  element,
  state,
  setState,
  styles,
  block,
  waiting,
  onChange,
  font,
}) => {
  const [viewedPoints, setViewedPoints] = useState<Record<string, boolean>>(
    element.value.items.reduce((acc, item) => ({ ...acc, [item.value]: false }), {}),
  )

  const isMobile = mode.deviceMode === DeviceMode.mobile
  const allViewed = lodash.every(viewedPoints)

  const setActivePoint = useCallback(
    (i: string | null) => {
      setState?.({ activePoint: i, incomplete: !allViewed })
      if (i !== null && !viewedPoints[i]) {
        setViewedPoints((prev) => ({ ...prev, [i]: true }))
      }
    },
    [allViewed, setState, viewedPoints],
  )

  const imgRef = useRef<HTMLImageElement>(null)
  const file = useImageWithCrop(element.value.image)

  const shellStyles = {
    maxWidth: '100%',
    maxHeight: '100%',
    ...lodash.pick(styles.shell, SHELL_SIZE_KEYS),
    ...(element.value.image?.params?.round && { borderRadius: '50%' }),
  }

  const rootStyles = useElementRootStyles(styles.root, shellStyles)
  const imageStyles = useElementRootStyles(styles.image, shellStyles)

  useElementCompleted(block?.uuid || '', element.id, allViewed)

  const isPdf = mode.previewMode === PreviewMode.pdf

  const contextValue = useMemo(
    () => ({
      block,
      element,
      imgRef,
      mode,
      onChange,
      setState,
      styles,
      waiting,
      activePoint: state?.activePoint,
      setActivePoint,
    }),
    [block, element, onChange, imgRef, mode, setState, styles, waiting, state, setActivePoint],
  )

  if (isPdf) {
    return (
      <LabeledGraphicContext.Provider value={contextValue}>
        <PdfComponent
          filePath={file?.path}
          font={font}
          styles={{ allStyles: styles, rootStyles, imageStyles }}
        />
      </LabeledGraphicContext.Provider>
    )
  }

  return (
    <LabeledGraphicContext.Provider value={contextValue}>
      <div className={s.root} style={rootStyles}>
        <ResponsiveImage
          alt={element.value.image?.accessibility}
          aria-label={element.value.image?.accessibility}
          blackout={element.value.blackout}
          file={file?.file}
          ref={imgRef}
          src={file?.path || mock}
          style={{
            ...imageStyles,
            borderRadius: file?.path ? imageStyles?.borderRadius : 'none',
          }}
        />

        {isMobile && element.value.items.length ? (
          <MobileView font={font} viewedPoints={viewedPoints} />
        ) : (
          element.value.items?.map((item, index) => (
            <Point
              font={font}
              id={element.id}
              index={index}
              isActive={state?.activePoint === item.value}
              isAnimated={styles.animation.pulse && !viewedPoints[item.value]}
              key={item.value}
              point={item}
              value={element.value}
            />
          ))
        )}
      </div>
    </LabeledGraphicContext.Provider>
  )
}

export default React.memo(LabeledGraphicElement)
