import cn from 'classnames'
import React from 'react'
import { useContextSelector } from 'use-context-selector'

import { FormContext } from 'components/controls/Form'
import Button, { IButtonPropsType } from 'components/uiKit/Button'
import { KitSize } from 'components/uiKit/KitTypes'

import SubmitButton from '../SubmitButton/SubmitButton'
import s from './FormControls.module.scss'
import { DEFAULT_STYLE_TYPES, SAVE_LABELS } from './constants'

interface IFormControlsProps {
  disabled?: boolean
  size?: KitSize
  justify?: 'right' | 'between' | 'center' | 'left'
  label?: { cancel?: string; save?: string }
  styleType?: { cancel?: IButtonPropsType['styleType']; save?: IButtonPropsType['styleType'] }
  loading?: boolean
  onCancel?: () => void
  onSave?: () => void
  submitName?: string
}

const FormControls: React.FC<IFormControlsProps> = ({
  disabled,
  size = KitSize.M,
  justify = 'center',
  label = SAVE_LABELS,
  styleType = DEFAULT_STYLE_TYPES,
  loading,
  submitName = 'save',
  onCancel,
}) => {
  const { cancel, save } = label
  const onReset = useContextSelector(FormContext, (state) => state.resetFields)

  return (
    <div className={cn(s.root, s[justify])}>
      {cancel && (
        <Button
          name='cancel'
          onClick={onCancel || onReset}
          size={size}
          styleType={styleType.cancel}
          type='reset'
        >
          {cancel}
        </Button>
      )}
      {save && (
        <SubmitButton
          disabled={disabled}
          label={save}
          loading={loading}
          name={submitName}
          size={size}
          styleType={styleType.save}
        />
      )}
    </div>
  )
}

export default FormControls
