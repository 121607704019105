import cn from 'classnames'
import React from 'react'

import Icon from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'

import s from './CollapseIcon.module.scss'

interface ICollapseIconProps {
  open?: boolean
  iconPosition: 'left' | 'right' | 'none'
}

const CollapseIcon: React.FC<ICollapseIconProps> = ({ open, iconPosition }) => {
  const wrapperCn = cn(s.icon, s[iconPosition], { [s.isOpen]: open })
  return <Icon className={wrapperCn} name='iconsExpandRight' size={KitSize.S} />
}

export default CollapseIcon
