import * as R from 'ramda'

import { EmployeePreferencesSchemaFragment } from 'gql/__generated__/graphql'
import { Project } from 'services/Store/Project/types'

export const getProject = R.path<Project | null>(['data', 'project'])

export const selectEmployeePreferences = R.path<EmployeePreferencesSchemaFragment | null>([
  'data',
  'project',
  'employeePreferences',
])
