import * as R from 'ramda'
import { useCallback } from 'react'

import { useOpenModal } from 'components/uiKit/Modal'
import { setProjectNavigation, updateBlock } from 'services/Store/Project/actions'
import { useProjectDispatch } from 'services/Store/Project/hooks'
import { Block } from 'services/Store/Project/types'

import { getNode } from '../../context/EditorContext/selectors/block'
import { createColNode, createShell } from '../../context/EditorContext/struct/block'
import { ELEMENTS_WITH_CHILDREN } from '../../types/data.constants'
import { NodeType } from '../../types/date.enums'
import ElementPickerModal from './ElementPickerModal'

export const usePickElement = (block: Block, nodeId: string) => {
  const dispatch = useProjectDispatch()
  const onChange = useCallback(
    (block: Block) => dispatch(updateBlock({ id: block?.uuid, value: block })),
    [dispatch],
  )
  const elementModal = useOpenModal(ElementPickerModal)

  return useCallback(async () => {
    const element = await elementModal.open()
    const nodes = []
    let shellId = null
    const selectedNode = block && nodeId ? getNode(block, nodeId) : null
    if (selectedNode?.type === NodeType.shell) {
      shellId = nodeId
    } else if (selectedNode?.type === NodeType.column || selectedNode?.type === NodeType.absolute) {
      const newShell = createShell()
      const updateColumn = {
        ...selectedNode,
        children: [...selectedNode.children, newShell.id],
      }
      nodes.push(newShell, updateColumn)
      shellId = newShell.id
    }
    if (!element || !shellId) {
      return
    }
    const newElement = element
    if (ELEMENTS_WITH_CHILDREN.includes(element.type)) {
      for (const item of element.value.items) {
        const column = createColNode()
        item.value = column.id
        nodes.push(column)
      }
    }
    const path = ['schema', 'nodes', shellId, 'elementId']
    const newBlock = R.compose(
      R.assocPath(path, element.id),
      //@ts-ignore
      R.assocPath(['elements', element.id], newElement),
      ...nodes.map((node) => R.assocPath(['schema', 'nodes', node.id], node)),
    )(block)
    onChange(newBlock as Block)
    dispatch(setProjectNavigation({ nodeId: shellId }))
  }, [block, nodeId, dispatch, onChange])
}
