export interface INotificationProps {
  type: NotificationType
  message: string
  className?: string
  duration?: number
}

export enum NotificationType {
  success = 'success',
  warning = 'warning',
  error = 'error',
  info = 'info',
}
