import React from 'react'

import { PreviewMode } from 'services/Store/Project/enums'

import { TestRankingElementType } from '../TestRankingElement.types'
import TestRankingElementEditor from './TestRankingElementEditor'
import TestRankingElementPreview from './TestRankingElementPreview'

const COMPONENTS_MAP: Partial<Record<PreviewMode, TestRankingElementType>> = {
  editor: TestRankingElementEditor,
  demo: TestRankingElementEditor,
}

const TestRankingElement: TestRankingElementType = ({ mode, ...rest }) => {
  const Component = COMPONENTS_MAP[mode.previewMode] || TestRankingElementPreview
  return <Component mode={mode} {...rest} />
}

export default TestRankingElement
