import { LabeledGraphicFontSchemaType } from '@leenda/editor/lib/brand'
import cn from 'classnames'
import React from 'react'

import CourseSlider from 'components/editor-v3/cource/components/CourseSlider'
import CourseTooltip from 'components/editor-v3/cource/components/CourseTooltip'
import { IconButton } from 'components/uiKit/Button'
import Icon from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'
import { ElementFontCss } from 'services/Branding/types'
import { PreviewMode } from 'services/Store/Project/enums'
import { getImageByUsage } from 'utils/files'
import { extendTestData } from 'utils/test/qaData'

import { useLabeledGraphicContext } from '../LabeledGraphicContext'
import Point from '../Point'
import PointContent from '../Point/PointTooltip/PointContent'
import s from './MobilePointsView.module.scss'

interface IMobileViewProps {
  viewedPoints: Record<string, boolean>
  font: ElementFontCss<LabeledGraphicFontSchemaType>
}

const MobilePointsView: React.FC<IMobileViewProps> = ({ viewedPoints, font }) => {
  const styles = useLabeledGraphicContext((c) => c.styles)
  const mode = useLabeledGraphicContext((c) => c.mode)
  const element = useLabeledGraphicContext((c) => c.element)
  const setActivePoint = useLabeledGraphicContext((c) => c.setActivePoint)
  const activePoint = useLabeledGraphicContext((c) => c.activePoint)
  const activeIndex = element.value.items.findIndex((item) => item.value === activePoint)
  const isEditor = mode.previewMode === PreviewMode.editor
  const isFirst = activeIndex === 0
  const isLast = activeIndex === element.value.items.length - 1

  const goPrev = () => {
    const prevItem = element.value.items[activeIndex - 1]
    setActivePoint(prevItem?.value)
  }
  const goNext = () => {
    const nextItem = element.value.items[activeIndex + 1]
    setActivePoint(nextItem?.value)
  }

  const itemsContent = element.value.items?.map((item, index) => (
    <PointContent
      font={font}
      id={item.value}
      index={index}
      key={index}
      path={getImageByUsage(item.image)?.path}
      point={item}
    />
  ))

  return (
    <CourseTooltip
      isAnimated={styles.animation.fade && !isEditor}
      mode={mode}
      name='tooltipLabeledGraphic'
      open={activePoint !== null}
      title={
        <div className={s.tooltip} style={{ ...styles.tooltip }}>
          <div className={s.close}>
            <IconButton
              icon='otherClose'
              name='closeTooltip'
              onClick={() => setActivePoint(null)}
              styleType='ghost'
            />
          </div>
          <div className={s.slider}>
            <CourseSlider
              activeIndex={activeIndex}
              goNext={goNext}
              goPrev={goPrev}
              items={itemsContent}
            />
          </div>
          {styles.navigation.switch && (
            <div className={s.navigation}>
              <Icon
                className={cn(s.arrow, { [s.disabled]: isFirst })}
                name='iconsForElementsLeft'
                onClick={() => !isFirst && goPrev()}
                size={KitSize.L}
                testData={extendTestData({
                  index: activeIndex,
                  disabled: isFirst,
                })}
              />
              <div className={s.footer}>
                {activeIndex + 1}/{element.value.items.length}
              </div>
              <Icon
                className={cn(s.arrow, { [s.disabled]: isLast })}
                name='iconsForElementsRight'
                onClick={() => !isLast && goNext()}
                size={KitSize.L}
                testData={extendTestData({
                  index: activeIndex,
                  disabled: isLast,
                })}
              />
            </div>
          )}
        </div>
      }
      destroyTooltipOnHide
    >
      {element.value.items?.map((item, index) => (
        <div key={item.value} onClick={() => setActivePoint(item.value)}>
          <Point
            font={font}
            id={element.id}
            index={index}
            isActive={activePoint === item.value}
            isAnimated={styles.animation.pulse && !viewedPoints[item.value]}
            key={item.value}
            point={item}
            value={element.value}
          />
        </div>
      ))}
    </CourseTooltip>
  )
}

export default MobilePointsView
