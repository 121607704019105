import { CONFIG_ELEMENTS } from '@leenda/editor/lib/elements'

import { t } from 'services/Translation'

import { ElementGroup } from '../../elements.types'
import { genElementConfig } from '../../genElementConfig'
import { STYLE_FORM_SCHEMA } from './RectangleBrandFormSchema'
import Demo from './RectangleDemo'
import Element from './RectangleElement'
import Form from './RectangleForm'
import rectangleIcon from './assets/rectangle.svg'

export default genElementConfig(CONFIG_ELEMENTS.rectangle, {
  label: t('elements.rectangle.label'),
  icon: rectangleIcon,
  group: ElementGroup.view,
  Element,
  ValueForm: Form,
  STYLE_FORM_SCHEMA,
  FONT_FORM_SCHEMA: {},
  Demo,
})
