import { useCallback } from 'react'

import { useProjectContext, useProjectDispatch } from 'services/Store/Project/hooks'
import { getAnswerResetTrigger } from 'services/Store/Project/triggers'

import BaseButtonElement from '../BaseButtonElement'
import { ButtonElementType } from '../ButtonElement.types'

const ResetButtonElement: ButtonElementType = ({ element, styles, mode, block, font }) => {
  const trigger = useProjectContext(getAnswerResetTrigger, { blockId: block?.uuid })
  const dispatch = useProjectDispatch()
  const label = element?.value?.label

  const onClick = useCallback(
    () => !trigger.disabled && dispatch(trigger.actionCreator()),
    [dispatch, trigger],
  )

  return (
    <BaseButtonElement
      action={element.value.action}
      disabled={trigger.disabled}
      font={font}
      label={label}
      mode={mode}
      onClick={onClick}
      styles={styles}
    />
  )
}

export default ResetButtonElement
