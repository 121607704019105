import lodash from 'lodash'
import { useCallback } from 'react'

import { useOpenModal } from 'components/uiKit/Modal'
import { EDITOR_PATHS } from 'constants/paths'
import { usePathParams } from 'routes/hooks'
import { updateBlock } from 'services/Store/Project/actions'
import { SectionTypeEnum } from 'services/Store/Project/enums'
import { useProjectContext, useProjectDispatch } from 'services/Store/Project/hooks'
import { getBlock, getProject, getSection } from 'services/Store/Project/selectors'
import { Block } from 'services/Store/Project/types'
import { cloneBlockSchema } from 'utils/editor/clone'

import TemplatePickerModal from './TemplatePickerModal/TemplatePickerModal'
import {
  blockModeToTemplateTag,
  TemplatePickerModalType,
  TemplatePickerMode,
} from './TemplatePickerModal/types'

export const useChangeTemplate = () => {
  const dispatch = useProjectDispatch()
  const block = useProjectContext(getBlock)
  const project = useProjectContext(getProject)
  const { type } = useProjectContext(getSection) || { type: SectionTypeEnum.landing }

  const onChange = useCallback(
    (block: Block) => dispatch(updateBlock({ id: block.uuid, value: block })),
    [dispatch],
  )

  const { companyId, projectId } = usePathParams(EDITOR_PATHS.main)
  const tag = block?.mode && blockModeToTemplateTag(block?.mode)
  const templatePickerModal = useOpenModal(TemplatePickerModal, {
    mode: TemplatePickerMode.change,
    type,
    tag: tag || undefined,
    modalType: TemplatePickerModalType.change,
    employeePreferences: project?.employeePreferences,
  })
  const changeTemplate = async () => {
    const val = await templatePickerModal.open({
      mode: TemplatePickerMode.change,
      companyId,
      projectId,
    })
    const value = val?.selected[0]
    if (value) {
      const newValue = cloneBlockSchema(value as unknown as Block) // TODO fix type
      const payload = {
        ...block,
        ...lodash.pick(newValue, ['schema', 'elements', 'name', 'mode']),
      }
      onChange(payload as Block)
    }
  }
  return changeTemplate
}
