import React from 'react'

import { DataFallbackConfigType } from 'components/DataFallback/types'
import Loader from 'components/uiKit/Loader'

import s from './DataFallback.module.scss'
import { FALLBACK_COMPONENT_MAP } from './DisplayError/constants'

interface IDataFallbackProps<T> {
  loading?: boolean
  error?: boolean
  data?: T
  config: DataFallbackConfigType
  children: (data: Exclude<T, null | undefined>) => React.ReactNode
}

const DataFallback = <T,>(props: IDataFallbackProps<T>) => {
  const { children, config, data, error, loading } = props
  const { position = 'fixed', view = 'page', ...restProps } = config

  const FallBackComponent = FALLBACK_COMPONENT_MAP[view]

  if (loading) {
    return <Loader name='dataFallbackLoader' />
  }

  if (error) {
    return (
      <div className={s[position]}>
        <FallBackComponent {...restProps} />
      </div>
    )
  }

  return children(data as Exclude<T, null | undefined>)
}

export default DataFallback
