import { useSize } from 'ahooks'
import lodash from 'lodash'
import { useState, useEffect, useRef } from 'react'

import { getPath } from 'components/editor-v3/context/EditorContext/selectors/block'
import NodeContainer from 'components/editor-v3/cource/layout/Node/NodeContainer'
import { DeviceMode, PreviewMode } from 'services/Store/Project/enums'
import { useProjectContext } from 'services/Store/Project/hooks'
import { getNodeId } from 'services/Store/Project/selectors'

import { useElementCompleted } from '../../hooks/useElementCompleted'
import { ArrowPosition, Props } from './Slider.types'
import SliderContext from './SliderContext'
import s from './SliderElement.module.scss'
import Arrows from './components/Arrows'
import Progress from './components/Progress'
import SlidesList from './components/SlidesList'

const SliderElement: Props = ({ block, element, styles, mode, state, setState, font }) => {
  const { items, hasLoop, type, hasProgress, includeSlide } = element.value
  const elementContainer = useRef<HTMLDivElement>(null)
  const { width: containerWidth = 0 } = useSize(elementContainer) || {}
  const index = items.findIndex((item) => item.value === state?.active)
  const slideIndex = index === -1 ? 0 : index
  const setSlideIndex = (index: number) => setState?.({ active: items[index]?.value })
  const [translation, setTranslation] = useState<number>(0)
  const [hidden, setHidden] = useState<number>(0)
  const isPreview = mode.previewMode !== PreviewMode.editor

  const [viewedSlides, setViewedSlides] = useState<Record<string, boolean>>(
    items.reduce((acc, item, i) => ({ ...acc, [item.value]: i === 0 ? true : false }), {}),
  )
  const allViewed = isPreview && lodash.every(viewedSlides)

  const isMobile = mode.deviceMode === DeviceMode.mobile

  const isSlidePosition = styles.icons.sliderPosition === ArrowPosition.slide

  const controlledProps: any = {}
  const activeId = useProjectContext(getNodeId)

  if (activeId) {
    const path = getPath(block!, activeId)
    controlledProps.activeKey = items.findIndex((item) => path.some((id) => id === item.value))
  }

  const leftArrowNoLoop = !hasLoop && slideIndex === 0
  const rightArrowNoLoop = !hasLoop && slideIndex + 1 === items.length

  const nextSlide = () => {
    setSlideIndex(slideIndex + 1)
  }

  const prevSlide = () => {
    if (slideIndex - 1 < 0) {
      setSlideIndex(items.length - 1)
    } else {
      setSlideIndex(slideIndex - 1)
    }
  }

  const goToSlide = (number: number) => {
    setSlideIndex(number % items.length)
  }

  const contextValue = {
    goToSlide,
    nextSlide,
    prevSlide,
    slideIndex: slideIndex || 0,
    items,
    mode,
    leftArrowNoLoop,
    rightArrowNoLoop,
    includeSlide,
    translation,
    setTranslation,
    containerWidth,
    hidden,
    setHidden,
  }

  const isPdf = mode.previewMode === PreviewMode.pdf

  useEffect(() => {
    if (controlledProps.activeKey && controlledProps.activeKey !== -1) {
      if (slideIndex !== controlledProps.activeKey) {
        goToSlide(controlledProps.activeKey)
        if (items.length - 1 === controlledProps.activeKey) {
          setTranslation(hidden)
        }
        if (controlledProps.activeKey === 0) {
          setTranslation(0)
        }
        if (
          controlledProps.activeKey &&
          hidden &&
          items.length - hidden >= controlledProps.activeKey
        ) {
          setTranslation(items.length - 1 - controlledProps.activeKey)
        }
      }
    }
  }, [controlledProps.activeKey])

  useEffect(() => {
    if (!slideIndex) {
      setSlideIndex(0)
    }
    !allViewed &&
      items[slideIndex] &&
      setViewedSlides((prev) => ({ ...prev, [items[slideIndex].value]: true }))
  }, [items, slideIndex])

  useEffect(() => {
    if (slideIndex >= items.length) {
      setSlideIndex(items.length - 1)
      hidden && setTranslation((prev) => prev - 1)
    }
  }, [items.length])

  useElementCompleted(block?.uuid || '', element.id, allViewed)

  if (isPdf) {
    return (
      <div className={s.root} ref={elementContainer}>
        {items.map((item, index) => (
          <div className={s.item} key={index}>
            <NodeContainer block={block!} id={item.value} level={0} mode={mode} />
          </div>
        ))}
      </div>
    )
  }

  return (
    <div className={s.root} ref={elementContainer}>
      <SliderContext.Provider value={contextValue}>
        {isSlidePosition && !isMobile && (
          <Arrows styles={styles}>
            <div className={s.slideArrow}>
              <SlidesList block={block!} />
              {hasProgress && (
                <Progress font={font} includeSlide={includeSlide} styles={styles} type={type} />
              )}
            </div>
          </Arrows>
        )}
        {isSlidePosition && isMobile && (
          <>
            <SlidesList block={block!} />
            {hasProgress && (
              <Progress font={font} includeSlide={includeSlide} styles={styles} type={type} />
            )}
          </>
        )}
        {!isSlidePosition && (
          <>
            <SlidesList block={block!} />
            <div>
              <Arrows styles={styles}>
                {hasProgress && (
                  <Progress font={font} includeSlide={includeSlide} styles={styles} type={type} />
                )}
              </Arrows>
            </div>
          </>
        )}
      </SliderContext.Provider>
    </div>
  )
}

export default SliderElement
