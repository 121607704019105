"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const constants_1 = require("../../../elements/elements/LabeledGraphic/constants");
const font_1 = require("../../font");
const theme_1 = require("../../theme");
const genStyle_1 = require("../genStyle");
const styleSchema = {
    image: {
        borderRadius: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.borderRadius }),
        borderStyle: (0, genStyle_1.genStyle)({ value: 'none' }),
        borderColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.borderColor }),
        borderWidth: (0, genStyle_1.genStyle)({ value: 1 }),
    },
    point: {
        width: (0, genStyle_1.genStyle)({ value: 32 }),
        height: (0, genStyle_1.genStyle)({ value: 32 }),
        backgroundColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.primaryColor }),
        backgroundImage: (0, genStyle_1.genStyle)({ value: null }),
    },
    point__var: {
        color: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.primaryColor }),
    },
    tooltip: {
        backgroundColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.primaryBackgroundColor }),
    },
    navigation: {
        switch: (0, genStyle_1.genStyle)({ value: true }),
    },
    animation: {
        fade: (0, genStyle_1.genStyle)({ value: true }),
        pulse: (0, genStyle_1.genStyle)({ value: true }),
    },
};
const fontSchema = Object.assign({ navigation: Object.assign({}, font_1.FONT_SCHEMA_EMPTY.base), numbering: Object.assign(Object.assign({}, font_1.FONT_SCHEMA_EMPTY.base), { color: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.lightTextColor }) }) }, font_1.FONT_SCHEMA_EMPTY);
exports.default = { [constants_1.TYPE]: { style: styleSchema, font: fontSchema } };
