import { gql } from 'gql/__generated__'

export const blocksSubscription = gql(`
  subscription EditorBlockSubscription($sectionId: String!) {
    data: editorBlock(sectionId: $sectionId) {
      block {
        ...BlockSchema
      }
      type
      blocksOrder
    }
  }
`)

export const blockPatchSubscription = gql(`
  subscription EditorBlockPatchSubscription($sectionId: String!) {
    data: editorBlockPatch(sectionId: $sectionId) {
      uuid
      patch
      base
      version
      meta
      files {
        ...FileMetaSchema
      }
    }
  }
`)
