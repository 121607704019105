import { CONFIG_ELEMENTS } from '@leenda/editor/lib/elements'

import { t } from 'services/Translation'

import { ElementGroup } from '../../elements.types'
import { genElementConfig } from '../../genElementConfig'
import { STYLE_FORM_SCHEMA, FONT_FORM_SCHEMA } from './TestFeedbackBrandFormSchema'
import Demo from './TestFeedbackDemo'
import EditorActions from './TestFeedbackEditorActions'
import Element from './TestFeedbackElement'
import Form from './TestFeedbackForm'
import icon from './assets/testFeedback.svg'

export default genElementConfig(CONFIG_ELEMENTS.testFeedback, {
  label: t('elements.testFeedback.label'),
  icon,
  group: ElementGroup.test,
  Element,
  ValueForm: Form,
  STYLE_FORM_SCHEMA,
  FONT_FORM_SCHEMA,
  Demo,
  EditorActions,
})
