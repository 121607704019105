import { DraggableProvided, DraggableStateSnapshot } from '@hello-pangea/dnd'
import { TestMatchingFontSchemaType, TestMatchingSchemaType } from '@leenda/editor/lib/brand'
import cn from 'classnames'
import React from 'react'

import { getStyle } from 'components/editor-v2/EditorElements/elements/TestMatchingElement/helper'
import Icon from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'
import { ElementFontCss, ElementStyleCss } from 'services/Branding/types'
import { testProps } from 'utils/test/qaData'

import s from './MatchingItem.module.scss'

type ItemProps = {
  provided?: DraggableProvided
  snapshot?: DraggableStateSnapshot
  isActive?: boolean
  mode: 'left' | 'right'
  isDragged?: string
  height?: number
  width?: number
  styles: ElementStyleCss<TestMatchingSchemaType>
  font?: ElementFontCss<TestMatchingFontSchemaType>
  children: React.ReactNode
  index: number
}

enum DirectionMode {
  left = 'left',
  rigth = 'rigth',
}

const MatchingItem: React.FC<ItemProps> = ({
  provided,
  snapshot,
  isActive,
  mode,
  isDragged,
  styles,
  height,
  width,
  children,
  index,
  font,
}) => {
  const isLeftItem = mode === DirectionMode.left

  const labelClasses = cn(s.item)
  const rootClasses = cn(s.root, {
    [s.dragged]: isDragged,
    [s.active]: isActive,
    [s.left]: isLeftItem,
  })
  const radiusStyles = isLeftItem
    ? {
        borderBottomLeftRadius: styles.pair.borderRadius,
        borderTopLeftRadius: styles.pair.borderRadius,
      }
    : {
        borderBottomRightRadius: styles.pair.borderRadius,
        borderTopRightRadius: styles.pair.borderRadius,
      }

  const rootStyles = {
    backgroundColor: styles.pair.backgroundColor,
    padding: styles.indents.padding,
    ...radiusStyles,
    ...styles.border,
    ...font?.base,
    ...(provided && snapshot
      ? {
          ...(provided.draggableProps.style || {}),
          ...(getStyle(snapshot, provided.draggableProps.style, width, styles) || {}),
          height,
        }
      : {
          height,
        }),
  }

  return (
    <div
      className={rootClasses}
      id='itemWrapper'
      ref={provided?.innerRef}
      {...((provided && provided.draggableProps) || {})}
      {...((provided && provided.dragHandleProps) || {})}
      style={rootStyles}
    >
      <div className={labelClasses} id='itemContent'>
        {isLeftItem ? (
          <div
            style={{ marginRight: styles.indents.marginRight }}
            {...testProps({ el: 'courseMatchingDrag', index })}
          >
            {styles.icon.backgroundImage ? (
              <div className={s.icon} style={styles.icon} />
            ) : (
              <div className={s.burgerIcon}>
                <Icon name='otherBurger' size={KitSize.S} />
              </div>
            )}
          </div>
        ) : undefined}
        {children}
      </div>
    </div>
  )
}

export default MatchingItem
