import { IArea } from '@leenda/editor/lib/elements'
import cn from 'classnames'
import lodash from 'lodash'
import React, { useState } from 'react'

import { SHELL_SIZE_KEYS } from 'components/editor-v3/types/data.constants'
import { setQuestion } from 'services/Store/Project/actions'
import { PreviewMode } from 'services/Store/Project/enums'
import { useProjectContext, useProjectDispatch } from 'services/Store/Project/hooks'
import { getBlockState } from 'services/Store/Project/selectors'
import { useImageWithCrop } from 'utils/files'

import ResponsiveImage from '../../ResponsiveImage'
import { useElementRootStyles } from '../../hooks/useElementRootStyles'
import s from './TestHotspotElement.module.scss'
import { TestHotspotElementType } from './TestHotspotElement.types'
import mock from './assets/imageDemo.svg'
import HotspotArea from './components/HotspotArea'
import ResizerHandlers from './components/ResizerHandlers'
import Spot from './components/Spot'
import { useHotspotActions } from './hooks/useHotspotActions'

const TestHotspotElement: TestHotspotElementType = ({
  mode,
  element,
  block,
  styles,
  onChange,
  state,
}) => {
  const file = useImageWithCrop(element.value.image)
  const spots = state?.spots || []
  const dispatch = useProjectDispatch()
  const [imgEl, setImgEl] = useState<HTMLImageElement | null>(null)
  const [selectedAreaId, setSelectedAreaId] = useState<string | null>(null)
  const {
    onMouseDown,
    onResizeMouseDown,
    onPreviewMouseDown,
    onExistingAreaMouseDown,
    isActiveElement,
    activeSpot,
    areas,
    setAreas,
    setActiveSpot,
  } = useHotspotActions({
    state,
    element,
    setSelectedAreaId,
    img: imgEl,
    block,
    onChange,
  })

  const isEditor = mode.previewMode === PreviewMode.editor
  const shellStyles = {
    maxWidth: '100%',
    maxHeight: '100%',
    ...lodash.pick(styles.shell, SHELL_SIZE_KEYS),
    ...(element.value.image?.params?.round && { borderRadius: '50%' }),
  }
  const imageStyles = useElementRootStyles(styles.image, shellStyles)
  const result = useProjectContext(getBlockState, block?.uuid)?.result

  const onDeleteSpot = (id: string) => {
    dispatch(
      setQuestion({
        elementId: element.id,
        value: {
          spots: spots.filter((spot) => spot.id !== id),
        },
        isReady: true,
        blockId: block?.uuid || '',
      }),
    )
  }

  return (
    <div
      className={cn(s.root, {
        [s.preview]: !isEditor,
        [s.disabled]: !isActiveElement && isEditor,
      })}
      onMouseDown={!isEditor ? onPreviewMouseDown : onMouseDown}
      ref={(ref) => setImgEl(ref as any)}
    >
      <ResponsiveImage
        alt={element.value.image?.accessibility}
        aria-label={element.value.image?.accessibility}
        blackout={element.value.blackout}
        file={file?.file}
        src={file?.path || mock}
        style={{
          ...imageStyles,
          borderRadius: file?.path ? imageStyles?.borderRadius : 'none',
        }}
      />
      {!isEditor &&
        spots.map((spot) => (
          <Spot
            active={activeSpot === spot.id}
            deviceMode={mode.deviceMode}
            enableDelete={!result && element.value.response === 'all'}
            hasIcon={element.value.type === 'icon'}
            key={spot.id}
            onDeleteSpot={onDeleteSpot}
            result={result}
            setActiveSpot={setActiveSpot}
            spot={spot}
            styles={styles}
          />
        ))}
      <div className={cn({ [s.hidden]: !isEditor && !result, [s.disabled]: !isEditor })}>
        {areas?.map((area: IArea) => (
          <HotspotArea
            animate={!!result && !isEditor}
            area={area}
            enableDelete={areas.length > 1}
            key={area.id}
            onMouseDown={(e) => onExistingAreaMouseDown(e, area)}
            selected={selectedAreaId === area.id}
            setAreas={setAreas}
            styles={styles.area}
          >
            <ResizerHandlers
              area={area}
              onResizeMouseDown={onResizeMouseDown}
              selected={selectedAreaId === area.id}
            />
          </HotspotArea>
        ))}
      </div>
    </div>
  )
}

export default TestHotspotElement
