import lodash from 'lodash'
import React from 'react'

import { IFieldConfig, IControlProps } from 'components/controls/Field/Field.types'
import { chainName } from 'utils/form'

interface IFieldGroupProps {
  value: Record<string, unknown>
  Parent: React.FC<{ config: IFieldConfig }>
  defaultValue: Record<string, unknown>
  fields: IFieldConfig[]
}

const FieldGroup: React.FC<IControlProps<IFieldGroupProps>> = ({
  Parent,
  name,
  fields,
  onChange,
  value,
  defaultValue,
  ...rest
}) => {
  return (
    <>
      {fields.map((field) => (
        <Parent
          config={{
            ...(field.name && { defaultValue: lodash.get(defaultValue, field.name) }),
            ...field,
            name: chainName(name, field).name,
          }}
          key={`${name}.${field.name}`}
          {...lodash.omit(rest, ['options'])}
        />
      ))}
    </>
  )
}

export default FieldGroup
