import { gql } from 'gql/__generated__'

export const commentCreate = gql(`
  mutation CommentCreate($payload: CommentCreateInput!) {
    data: commentCreate(payload: $payload) {
      data {
        ...CommentThreadSchema
      }
    }
  }
`)

export const commentUpdate = gql(`
  mutation CommentUpdate($id: ID!, $payload: CommentUpdateInput!) {
    data: commentUpdate(id: $id, payload: $payload) {
      data {
        ...CommentThreadSchema
      }
    }
  }
`)

export const commentDelete = gql(`
  mutation CommentArchive($id: ID!) {
    data: commentArchive(id: $id) {
      data
    }
  }
`)

export const commentSubscribe = gql(`
  mutation CommentSubscribe($id: ID!) {
    data: commentSubscribe(id: $id) {
      data
    }
  }
`)

export const commentUnsubscribe = gql(`
  mutation CommentUnsubscribe($id: ID!) {
    data: commentUnsubscribe(id: $id) {
      data
    }
  }
`)

export const commentSetRead = gql(`
  mutation CommentSetRead($id: ID!) {
    data: commentSetRead(id: $id) {
      data
    }
  }
`)
