export enum CatalogPageActionUiEnum {
  ADD_FOLDER = 'ADD_FOLDER',
  ADD_PROJECT = 'ADD_PROJECT',
}

export enum CatalogTableActionUiEnum {
  UPDATE = 'UPDATE',
  MOVE = 'MOVE',
  DELETE = 'DELETE',
  DUPLICATE = 'DUPLICATE',
  COPY_IN_OTHER_COMPANY = 'COPY_IN_OTHER_COMPANY',
}
