import cn from '@leenda/editor/lib/brand/labels/cn.json'
import de from '@leenda/editor/lib/brand/labels/de.json'
import en from '@leenda/editor/lib/brand/labels/en.json'
import es from '@leenda/editor/lib/brand/labels/es.json'
import fr from '@leenda/editor/lib/brand/labels/fr.json'
import it from '@leenda/editor/lib/brand/labels/it.json'
import ja from '@leenda/editor/lib/brand/labels/ja.json'
import pl from '@leenda/editor/lib/brand/labels/pl.json'
import pt from '@leenda/editor/lib/brand/labels/pt.json'
import ro from '@leenda/editor/lib/brand/labels/ro.json'
import ru from '@leenda/editor/lib/brand/labels/ru.json'
import sk from '@leenda/editor/lib/brand/labels/sk.json'
import tr from '@leenda/editor/lib/brand/labels/tr.json'
import ua from '@leenda/editor/lib/brand/labels/ua.json'
import { useContext, createContext } from 'react'

import { Locale } from '../../gql/__generated__/graphql'
import { getCurrentLocale } from '../Translation/i18n'

const DEFAULT_LABELS = {
  [Locale.EN]: en,
  [Locale.RU]: ru,
  [Locale.DE]: de,
  [Locale.FR]: fr,
  [Locale.IT]: it,
  [Locale.ES]: es,
  [Locale.PT]: pt,
  [Locale.JA]: ja,
  [Locale.TR]: tr,
  [Locale.PL]: pl,
  [Locale.SK]: sk,
  [Locale.RO]: ro,
  [Locale.CN]: cn,
  [Locale.UA]: ua,
  [Locale.CUSTOM]: {},
}
type ProjectLabels = (typeof DEFAULT_LABELS)[Locale.EN]

const ProjectLabelsContext = createContext(DEFAULT_LABELS[Locale.EN])

export const getDefaultLabels = () => {
  const locale = getCurrentLocale()
  return DEFAULT_LABELS[locale]
}

export const ProjectLabelsProvider = ProjectLabelsContext.Provider

export const useProjectT = () => {
  const labels = useContext(ProjectLabelsContext)
  return (key: keyof ProjectLabels) => labels[key]
}
