import React, { useCallback } from 'react'

import { IControlProps } from 'components/controls/Field/Field.types'
import DatePicker, { IDefaultDatePicker } from 'components/uiKit/DatePicker'

const FieldDatePicker: React.FC<IControlProps<IDefaultDatePicker>> = ({
  name,
  onChange,
  ...props
}) => {
  const handleOnChange = useCallback(
    (value?: number | null) => onChange({ name, value }),
    [name, onChange],
  )

  return <DatePicker {...props} name={name} onChange={handleOnChange} />
}

export default FieldDatePicker
