"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const genStyle_1 = require("../genStyle");
const TYPE = 'landingSectionLayout';
const styleSchema = {
    indents: {
        paddingTop: (0, genStyle_1.genStyle)({ value: 50 }),
        paddingBottom: (0, genStyle_1.genStyle)({ value: 20 }),
    },
    effect: {
        fadeEffect: (0, genStyle_1.genStyle)({ value: true }),
    },
};
exports.default = { [TYPE]: { style: styleSchema, font: {} } };
