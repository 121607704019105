import { Helmet } from 'react-helmet-async'

import { IFontFaceProps, genFontFace } from './genFontFace'

const FontInjector: React.FC<IFontFaceProps> = ({ file, ...rest }) => (
  <Helmet>
    <link
      as='font'
      crossOrigin='anonymous'
      href={file?.path}
      key={file?.id}
      rel='preload'
      type='font/woff2'
    />
    <style>{genFontFace({ file, ...rest })}</style>
  </Helmet>
)

export default FontInjector
