import { COMPANY_FILES_PATHS, PROJECT_FILES_PATHS, PROJECT_PATHS } from 'constants/paths'
import { FileMetaFor } from 'gql/__generated__/graphql'

export type Paths = typeof COMPANY_FILES_PATHS | typeof PROJECT_FILES_PATHS | typeof PROJECT_PATHS

export type IModalTab = FileMetaFor.projects | FileMetaFor.companies

export enum FinderPageActionUiEnum {
  ADD_FILES = 'ADD_FILES',
  ADD_FOLDER = 'ADD_FOLDER',
  ADD_FROM_LIBRARY = 'ADD_FROM_LIBRARY',
}

export enum FinderTableActionUiEnum {
  UPDATE_FILE = 'UPDATE_FILE',
  UPDATE_FOLDER = 'UPDATE_FOLDER',
  MOVE = 'MOVE',
  DOWNLOAD = 'DOWNLOAD',
  DELETE = 'DELETE',
  RESET = 'RESET',
}
