import React, { useEffect } from 'react'
import { createPortal } from 'react-dom'
import { Range } from 'slate'
import { useSlate, useSlateSelection } from 'slate-react'

import { ToolbarForm } from '../RichTextTypes'
import { isAnnotationActive, isCrossLinkActive, isLinkActive } from '../inline/withInline'
import { getSetToolbarForm, getToolbarForm, useRichTextContext } from '../useRichTextContext'
import Toolbar from './Toolbar'
import AiForm from './components/AiForm'
import AnnotationForm from './components/AnnotationForm'
import CrossLinkForm from './components/CrossLinkForm'
import LinkForm from './components/LinkForm'

const FORM_MAP: Record<ToolbarForm, React.FC> = {
  ai: AiForm,
  link: LinkForm,
  annotation: AnnotationForm,
  crossLink: CrossLinkForm,
  default: Toolbar,
}

const BubbleToolbar: React.FC = () => {
  const editor = useSlate()
  const selection = useSlateSelection()
  const toolbarForm = useRichTextContext(getToolbarForm)
  const setToolbarForm = useRichTextContext(getSetToolbarForm)

  useEffect(() => {
    if (selection && !Range.isCollapsed(selection)) {
      setToolbarForm('default')
    } else if (isLinkActive(editor)) {
      setToolbarForm('link')
    } else if (isCrossLinkActive(editor)) {
      setToolbarForm('crossLink')
    } else if (isAnnotationActive(editor)) {
      setToolbarForm('annotation')
    } else {
      setToolbarForm(null)
    }
  }, [editor, selection, setToolbarForm])

  const Component = toolbarForm && FORM_MAP[toolbarForm]

  return Component && createPortal(<Component />, document.body)
}

BubbleToolbar.displayName = 'BubbleToolbar'

export default React.memo(BubbleToolbar)
