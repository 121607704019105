import lodash from 'lodash'
import { useContextSelector } from 'use-context-selector'

import { IFieldMeta } from '../Field/Field.types'
import { FormContext } from '../Form'

export const useFieldData = <Value>(name = '') => {
  const value: Value = useContextSelector(FormContext, (context) =>
    lodash.get(context.values, name),
  )
  const initialValue: Value = useContextSelector(FormContext, (context) =>
    lodash.get(context.initialValues, name),
  )
  const defaultValue: Value = useContextSelector(FormContext, (context) =>
    lodash.get(context.defaultValues, name),
  )
  const inheritValue: Value = useContextSelector(FormContext, (context) =>
    lodash.get(context.inheritValues, name),
  )
  const fullValues: Value = useContextSelector(FormContext, (context) =>
    lodash.get(context.fullValues, name),
  )
  const meta: IFieldMeta = useContextSelector(FormContext, (context) => context.metas[name]) || {}

  return { value, initialValue, meta, defaultValue, inheritValue, fullValues }
}

export const useFormData = () => useContextSelector(FormContext, (context) => context.values)
