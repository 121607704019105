import { ApolloLink } from '@apollo/client'
import { generateId } from '@leenda/editor/lib/utils/id'

const omitDeepArrayWalk = (arr: any[], key: string): any => {
  return arr.map((val) => {
    if (Array.isArray(val)) {
      return omitDeepArrayWalk(val, key)
    }
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    else if (val !== null && typeof val === 'object') {
      return omitDeep(val, key)
    }
    return val
  })
}

const omitDeep = (obj: any, key: string) => {
  const keys = Object.keys(obj)
  const newObj: { [key: string]: any } = {}
  keys.forEach((i) => {
    if (i !== key) {
      const val = obj[i]
      if (val instanceof Date) {
        newObj[i] = val
      } else if (Array.isArray(val)) {
        newObj[i] = omitDeepArrayWalk(val, key)
      } else if (typeof val === 'object' && val !== null) {
        newObj[i] = omitDeep(val, key)
      } else {
        newObj[i] = val
      }
    }
  })
  return newObj
}

const cleanTypenameLink = new ApolloLink((operation, forward) => {
  if (operation.variables && !operation.variables.file) {
    // eslint-disable-next-line
    operation.variables = omitDeep(operation.variables, '__typename')
  }

  return forward(operation)
})

export { cleanTypenameLink }

export const OPTIMISTIC_PREFIX = '__optimistic'
export const getOptimisticId = () => generateId() + OPTIMISTIC_PREFIX
export const isOptimisticItem = <T extends { id: string }>(item?: T) => {
  return item && item.id.includes(OPTIMISTIC_PREFIX)
}
