import React, { createContext, useEffect, useRef } from 'react'

import { DeferredObject } from 'utils/defered'

import s from './ModalContext.module.scss'
import ModalMount from './ModalMount'

type ModalData<P, R> = {
  mounted?: string
  open: boolean
  index: number
  params?: P
  deferred?: DeferredObject<R>
}

type IModalsMap<P = unknown, R = unknown> = Map<ModalComponent<P, R>, ModalData<P, R>>

export interface IModalContextType<P, R> {
  modals: IModalsMap<P, R>
  setModals: React.Dispatch<React.SetStateAction<IModalsMap<P, R>>>
  modalPortal: React.RefObject<HTMLDivElement>
}

export const ModalContext = createContext<IModalContextType<unknown, unknown>>({
  modals: new Map(),
  setModals: () => null,
  modalPortal: React.createRef(),
})
export type ModalParams<P> = P & { disableOverlayClose?: boolean }

export type ModalComponent<P = unknown, R = unknown> = MCWithParams<P, R> | MCWithoutParams<R>
export type MCWithParams<P, R = unknown> = React.FC<{ onClose: (data?: R) => void; params: P }>
export type MCWithoutParams<R = unknown> = React.FC<{ onClose: (data?: R) => void }>

const DEFAULT_MODAL_DATA = { mounted: undefined, open: false, index: 0 }

export const useUpdateModalData = <P, R>(component: ModalComponent<P, R>) => {
  const { setModals } = React.useContext(ModalContext) as IModalContextType<P, R>
  return (update: (data: ModalData<P, R>) => ModalData<P, R>) =>
    setModals((modals) => {
      const modalData = modals.get(component) || DEFAULT_MODAL_DATA
      const newData = update(modalData) as ModalData<P, R>
      return new Map(modals).set(component, newData)
    })
}

export const getLastModalIndex = (modals: IModalsMap) =>
  [...modals.values()]
    .filter((data) => data.open)
    .reduce((acc, data) => Math.max(acc, data.index), 0)

export const useModalContext = () => {
  return React.useContext(ModalContext) as IModalContextType<unknown, unknown>
}

export const useModalContainer = () => {
  const { modalPortal } = useModalContext()
  return () => modalPortal.current || document.body
}

interface IPropsModalProvider {
  children?: React.ReactNode
}

export const ModalProvider: React.FC<IPropsModalProvider> = ({ children }) => {
  const [modals, setModals] = React.useState<IModalsMap>(new Map())

  const modalPortal = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (!modalPortal.current) {
      const container = document.createElement('div')
      container.className = s.portalContainer
      modalPortal.current = container
      document.body.appendChild(container)
    }
  }, [])

  const forMount = Array.from(modals.entries()).filter(([_c, modal]) => !modal.mounted)

  const value = React.useMemo(
    () => ({ modals, setModals, modalPortal }),
    [modals, setModals, modalPortal],
  )

  return (
    <ModalContext.Provider value={value}>
      {forMount.map(([component], index) => (
        <ModalMount component={component} key={index} userMount={false} />
      ))}
      {children}
    </ModalContext.Provider>
  )
}
