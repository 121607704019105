import { FieldTypes, IFieldConfig } from './Field.types'

export const genDataField =
  <D>() =>
  <T extends FieldTypes, N extends string, CP, IF>(
    config: Omit<IFieldConfig<D, T, N, CP, IF>, '__type'>,
  ): IFieldConfig<D, T, N, CP, IF> => ({ ...config, __type: 'UIField' })

export const genField = genDataField()

export const genSingleField = <
  D,
  T extends FieldTypes = FieldTypes,
  N extends string = string,
  CP = unknown,
  IF = unknown,
>(
  config: Omit<IFieldConfig<D, T, N, CP, IF>, '__type'>,
): IFieldConfig<D, T, N, CP, IF> => ({ ...config, __type: 'UIField' })

const getParams = <P extends { params?: unknown }, D>(config: P, data: D) =>
  typeof config.params === 'function' ? config.params(data, config) : config.params || {}

export const mergeFields = <D, T extends FieldTypes, N extends string, CP, IF>(
  ...configs: (Partial<IFieldConfig<D, FieldTypes, string, CP, IF>> | undefined)[]
): IFieldConfig<D, T, N, CP, IF> => {
  const finalConfig = Object.assign({}, ...configs, { __type: 'UIField' })
  if (configs.some((config) => config?.params)) {
    finalConfig.params = (data: unknown, fieldConfig: IFieldConfig<D, T, N, CP, IF>) =>
      configs.reduce(
        (acc, configItem) => {
          const params = getParams({ ...fieldConfig, params: configItem?.params }, data)
          return {
            ...acc,
            ...params,
            disabled: params.disabled || acc.disabled,
            hidden: params.hidden || acc.hidden,
          }
        },
        { disabled: false, hidden: false },
      )
  }
  return finalConfig
}

export const mergeParams =
  <D, T extends FieldTypes, N extends string, CP, IF>(
    ...params: (Partial<IFieldConfig<D, FieldTypes, string, CP, IF>['params']> | undefined)[]
  ) =>
  (data: unknown, fieldConfig: IFieldConfig<D, T, N, CP, IF>) =>
    params.reduce<any>(
      (acc, params) => {
        const cur = getParams({ ...fieldConfig, params }, data)
        return {
          ...acc,
          ...params,
          disabled: cur.disabled || acc?.disabled,
          hidden: cur.hidden || acc?.hidden,
        }
      },
      { disabled: false, hidden: false },
    )
