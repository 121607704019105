import { CONFIG_ELEMENTS } from '@leenda/editor/lib/elements'

import { t } from 'services/Translation'

import { ElementGroup } from '../../elements.types'
import { genElementConfig } from '../../genElementConfig'
import { STYLE_FORM_SCHEMA, FONT_FORM_SCHEMA } from './TestRankingBrandFormSchema'
import Demo from './TestRankingDemo'
import TestRankingEditorActions from './TestRankingEditorActions'
import Element from './TestRankingElement'
import Form from './TestRankingForm'
import rankingIcon from './assets/test-ranking.svg'

export default genElementConfig(CONFIG_ELEMENTS.testRanking, {
  label: t('elements.testRanking.label'),
  icon: rankingIcon,
  group: ElementGroup.test,
  Element,
  ValueForm: Form,
  STYLE_FORM_SCHEMA,
  FONT_FORM_SCHEMA,
  EditorActions: TestRankingEditorActions,
  Demo,
})
