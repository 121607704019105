import { Dispatch, SetStateAction } from 'react'
import { useContextSelector } from 'use-context-selector'

import Button, { IconButton } from 'components/uiKit/Button'
import { KitSize } from 'components/uiKit/KitTypes'
import { TemplatesListSchemaFragment, EmployeePreferencesTab } from 'gql/__generated__/graphql'
import { t } from 'services/Translation'

import { TemplatePickerContext } from '../hooks'
import { TemplatePickerMode } from '../types'
import s from './TemplatePickerFooter.module.scss'

interface ISectionPickerProps {
  onSubmit: (selected: TemplatesListSchemaFragment[]) => void
  clearSelect: () => void
  setSelected: Dispatch<
    SetStateAction<{
      single: TemplatesListSchemaFragment[]
      multi: TemplatesListSchemaFragment[]
    }>
  >
  templates: TemplatesListSchemaFragment[]
  tab: string
  hideAddAll?: boolean
  disabled?: boolean
}

const TemplatePickerFooter: React.FC<ISectionPickerProps> = ({
  onSubmit,
  templates,
  clearSelect,
  setSelected,
  tab,
  hideAddAll,
  disabled,
}) => {
  const { mode, selected, step, setStep, collectionId, allSelected, blocksByMode } =
    useContextSelector(TemplatePickerContext, (value) => value)

  const selectedMode = mode === TemplatePickerMode.single ? 'single' : 'multi'

  const selectAll = () => setSelected((prev) => ({ ...prev, multi: templates }))

  const titleSelected =
    tab === EmployeePreferencesTab.archived
      ? t('modal.pickerTemplate.blocks.selected')
      : t('modal.pickerTemplate.templates.selected')

  const nextStep = () => {
    setStep((prev) => (prev !== null ? prev + 1 : null))
  }
  const tabHasStep =
    (tab === EmployeePreferencesTab.collections && !!collectionId) ||
    tab !== EmployeePreferencesTab.collections

  const next = (step === 0 || step === 1) && mode !== TemplatePickerMode.change && tabHasStep

  return (
    <div className={s.modalFooter}>
      <div className={s.leftSide}>
        {mode === TemplatePickerMode.multi && !hideAddAll && (
          <Button
            disabled={!templates.length || Boolean(selected[selectedMode].length) || disabled}
            name='selectAll'
            onClick={selectAll}
            styleType='ghost'
          >
            {t('uiKit.button.chooseAll')}
          </Button>
        )}
        <div className={s.close}>
          {Boolean(selected[selectedMode].length) && (
            <div className={s.actionsContainer}>
              {mode === TemplatePickerMode.multi && (
                <div className={s.selected}>
                  <IconButton
                    icon='otherClose'
                    name='close'
                    onClick={clearSelect}
                    size={KitSize.S}
                    styleType='ghost'
                  />
                  <span className={s.selectedTitle}>
                    {titleSelected}
                    {selected[selectedMode].length}
                  </span>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <div className={s.buttons}>
        <Button
          disabled={!allSelected.length}
          name={mode === TemplatePickerMode.change ? 'change' : 'add'}
          onClick={() => onSubmit(allSelected)}
          styleType={next && !blocksByMode?.end ? 'secondary' : 'primary'}
        >
          {mode === TemplatePickerMode.change ? t('uiKit.button.replace') : t('uiKit.button.add')}
        </Button>
        {next && !blocksByMode?.end && (
          <Button name='next' onClick={nextStep}>
            {t('uiKit.button.next')}
          </Button>
        )}
      </div>
    </div>
  )
}

export default TemplatePickerFooter
