import { useElementRootStyles } from '../../hooks/useElementRootStyles'
import { getTextInfoAppearance } from './TestInfoElement.appearance'
import { PAGE_ITEMS } from './TestInfoElement.constants'
import { useGetTestInfoItemVariable } from './TestInfoElement.hooks'
import './TestInfoElement.scss'
import { PageItem, TestInfoElementType } from './TestInfoElement.types'
import { TestInfoItem } from './TestInfoItem'

const TestInfoElement: TestInfoElementType = ({ element, styles, block, font }) => {
  const { value } = element
  const ITEMS = PAGE_ITEMS[value.type]
  const getTestInfoItemVariable = useGetTestInfoItemVariable(block)

  const rootStyles = useElementRootStyles(styles.root)
  return (
    <ul className='test-info-list' style={rootStyles}>
      {ITEMS.map((item: PageItem, i) => {
        const { show, text, defaultIcon: Icon, icon } = getTextInfoAppearance(item, value)
        const testInfoItemVariable = getTestInfoItemVariable(item)
        return (
          <TestInfoItem
            font={font}
            icon={Icon}
            image={icon}
            item={item}
            key={`${text?.length}_${i}`}
            show={Boolean(show)}
            styles={styles}
            testInfoItemVariable={testInfoItemVariable}
            text={text}
          />
        )
      })}
    </ul>
  )
}

export default TestInfoElement
