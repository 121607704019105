import { DragDropContext, Droppable, DropResult, Draggable } from '@hello-pangea/dnd'
import { move } from 'ramda'
import { useCallback } from 'react'

import Icon from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'
import Switcher from 'components/uiKit/Switcher'

import { ColumnState, ColumnType, ITablesExtendsData } from '../types'
import { getColumnTitle } from '../utils'
import s from './ColumnsControls.module.scss'

interface IColumnsControls<T = unknown> {
  defaultColumns: ColumnType<T>[]
  columnStates: ColumnState[]
  onChange?: (columns: ColumnState[]) => void
}

interface IColumnControlProps {
  title?: string | JSX.Element
  visible: boolean
  onToggle: (columnKey: string) => void
  columnKey: string
}

const ColumnControl: React.FC<IColumnControlProps> = ({ title, onToggle, columnKey, visible }) => {
  return (
    <div className={s.columnControl}>
      <Icon name='builderDrugAndDrop' size={KitSize.S} />
      <span className={s.title}>{title}</span>
      <Switcher
        name={`${columnKey}-control`}
        onChange={() => onToggle(columnKey)}
        value={visible}
      />
    </div>
  )
}

const ColumnsControls = <T extends ITablesExtendsData>({
  defaultColumns,
  columnStates,
  onChange,
}: IColumnsControls<T>) => {
  const handleToggleColumn = useCallback(
    (columnKey: string) => {
      onChange?.(
        columnStates.map((column) => {
          if (column.key === columnKey) {
            return { ...column, visible: !column.visible }
          }
          return column
        }),
      )
    },
    [columnStates, onChange],
  )

  const reorderColumns = useCallback(
    (sourceIndex: number, destinationIndex: number) => {
      const result = move(sourceIndex, destinationIndex, columnStates)
      onChange?.(result)
    },
    [columnStates, onChange],
  )

  const handleDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination) {
        return
      }
      reorderColumns(result.source.index, result.destination.index)
    },
    [reorderColumns],
  )

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId='columnControls'>
        {(provided) => (
          <div className={s.container} ref={provided.innerRef} {...provided.droppableProps}>
            {columnStates.slice(1).map((column, index) => (
              <Draggable draggableId={column.key} index={index + 1} key={column.key}>
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <ColumnControl
                      columnKey={column.key}
                      key={column.key}
                      onToggle={handleToggleColumn}
                      title={getColumnTitle(column.key, defaultColumns)}
                      visible={column.visible}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default ColumnsControls
