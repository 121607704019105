import { Block, ShellNode } from '@leenda/editor'
import { TestHotspotSchemaType } from '@leenda/editor/lib/brand'
import { EditorElement, IArea, TestHotspotElementValue } from '@leenda/editor/lib/elements'
import { generateId } from '@leenda/editor/lib/utils/id'
import { MouseEvent, useCallback, useEffect, useRef, useState } from 'react'

import { getNode } from 'components/editor-v3/context/EditorContext/selectors/block'
import { setQuestion } from 'services/Store/Project/actions'
import { useProjectContext, useProjectDispatch } from 'services/Store/Project/hooks'
import { getBlockState, getIsActiveNode, getUrlParams } from 'services/Store/Project/selectors'

import { ResizeDirection, TestHotspotStateType } from '../TestHotspotElement.types'
import {
  clampCoordinates,
  createArea,
  getAreasContainingSpot,
  getOppositePoint,
  getPercentageCoordinate,
  updateAreaSize,
} from '../helper'

const MIN_COORDINATE = 0
const MAX_COORDINATE = 100

interface IHotspotActionsProps {
  state?: TestHotspotStateType
  element: EditorElement<TestHotspotElementValue, TestHotspotSchemaType, any>
  setSelectedAreaId: (id: string | null) => void
  img: HTMLImageElement | null
  block: Block | null
  onChange?: (value: TestHotspotElementValue) => void
}

export const useHotspotActions = ({
  state,
  element,
  setSelectedAreaId,
  img,
  block,
  onChange,
}: IHotspotActionsProps) => {
  const [editAreaId, setEditAreaId] = useState<string | null>(null)
  const [draggingAreaId, setDraggingAreaId] = useState<string | null>(null)
  const [areas, setAreasRaw] = useState<IArea[]>(element.value.items)
  const [activeSpot, setActiveSpot] = useState<string | null>(null)
  const startPoint = useRef<{ x: number; y: number }>({ x: 0, y: 0 })

  const spots = state?.spots || []
  const canAddSpots = element.value.response === 'all' || spots?.length < 1
  const { nodeId } = useProjectContext(getUrlParams)
  const node = getNode(block!, nodeId || '')
  const elementNode = (node as ShellNode)?.elementId === element.id ? node : null
  const isActiveElement = useProjectContext(getIsActiveNode, elementNode?.id || '')
  const result = useProjectContext(getBlockState, block?.uuid)?.result
  const dispatch = useProjectDispatch()
  const setAreas = useCallback(
    (fn: (p: IArea[]) => IArea[], skip = false) => {
      setAreasRaw((prev) => {
        const updated = fn(prev)
        if (!skip) {
          onChange && onChange({ ...element.value, items: updated })
        }
        return updated
      })
    },
    [element.value, onChange, areas.length],
  )

  const updateAreaById = (id: string, area: Partial<IArea>) => {
    setAreas((prevAreas) => prevAreas?.map((h) => (h.id === id ? { ...h, ...area } : h)))
  }
  const getAreaById = (id: string) => areas?.find((h) => h.id === id)

  const onMouseDown = (e: MouseEvent<HTMLDivElement>) => {
    const imgRect = img?.getBoundingClientRect()
    if (!isActiveElement) {
      return
    }
    e.stopPropagation()
    e.preventDefault()
    setSelectedAreaId(null)
    if (!imgRect) {
      return
    }

    const { currentX, currentY } = getPercentageCoordinate(e.clientX, e.clientY, imgRect)

    const area = createArea(currentX, currentY)
    startPoint.current = { x: area.x, y: area.y }
    setEditAreaId(area.id)
    setAreas((areas) => [...areas, area])
  }

  const onMouseMove = (e: any) => {
    const imgRect = img?.getBoundingClientRect()
    e.stopPropagation()
    e.preventDefault()

    if (!imgRect) {
      return
    }

    const { currentX, currentY } = getPercentageCoordinate(e.clientX, e.clientY, imgRect)
    const clampedCoords = clampCoordinates(currentX, currentY, 0, 0, MIN_COORDINATE, MAX_COORDINATE)

    if (draggingAreaId) {
      const draggingArea = getAreaById(draggingAreaId)
      if (!draggingArea) {
        return
      }
      const newX = draggingArea.x + (clampedCoords.x - startPoint.current.x)
      const newY = draggingArea.y + (clampedCoords.y - startPoint.current.y)

      const newCoordinates = clampCoordinates(
        newX,
        newY,
        draggingArea.width,
        draggingArea.height,
        MIN_COORDINATE,
        MAX_COORDINATE,
      )

      updateAreaById(draggingAreaId, newCoordinates)
    } else if (editAreaId) {
      const updatedArea = updateAreaSize(startPoint.current, clampedCoords)
      updateAreaById(editAreaId, updatedArea)
    }
  }

  const onMouseUp = () => {
    let selectedAreaId = null
    if (editAreaId) {
      setEditAreaId(null)
      selectedAreaId = editAreaId
    } else if (draggingAreaId) {
      setDraggingAreaId(null)
      selectedAreaId = draggingAreaId
    }
    setSelectedAreaId(selectedAreaId)
  }

  const onExistingAreaMouseDown = (e: MouseEvent<HTMLDivElement>, area: IArea) => {
    e.stopPropagation()
    const imgRect = img?.getBoundingClientRect()

    if (!imgRect) {
      return
    }

    const { currentX, currentY } = getPercentageCoordinate(e.clientX, e.clientY, imgRect)
    startPoint.current = { x: currentX, y: currentY }
    setDraggingAreaId(area.id)
  }

  const onResizeMouseDown = (
    e: MouseEvent<HTMLDivElement>,
    area: IArea,
    direction: ResizeDirection,
  ) => {
    e.stopPropagation()
    startPoint.current = getOppositePoint(area, direction)
    setEditAreaId(area.id)
  }

  const onPreviewMouseDown = (e: MouseEvent<HTMLDivElement>) => {
    const imgRect = img?.getBoundingClientRect()
    if (result) {
      return
    }
    e.stopPropagation()
    if (!imgRect) {
      return
    }
    const { currentX, currentY } = getPercentageCoordinate(e.clientX, e.clientY, imgRect)
    const areasWithSpot = getAreasContainingSpot(currentX, currentY, areas)
    const currentSpot = { x: currentX, y: currentY, id: generateId(), items: areasWithSpot }

    setActiveSpot(currentSpot.id)

    const newSpots = canAddSpots ? [...spots, currentSpot] : [currentSpot]

    dispatch(
      setQuestion({
        elementId: element.id,
        value: {
          spots: newSpots,
        },
        isReady: true,
        blockId: block?.uuid || '',
      }),
    )
  }

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (img && !img.contains(event.target as Node)) {
        setSelectedAreaId(null)
      }
    }
    document.addEventListener('click', handleClickOutside)
    if (draggingAreaId || editAreaId) {
      document.addEventListener('mousemove', onMouseMove)
      document.addEventListener('mouseup', onMouseUp)
    }
    return () => {
      document.removeEventListener('mousemove', onMouseMove)
      document.removeEventListener('mouseup', onMouseUp)
      document.removeEventListener('click', handleClickOutside)
    }
  }, [draggingAreaId, editAreaId, img])

  useEffect(() => {
    const areasChanged = JSON.stringify(element.value.items) !== JSON.stringify(areas)
    if (!editAreaId && !draggingAreaId && areasChanged) {
      setAreas(() => element.value.items, true)
    }
  }, [
    Boolean(!editAreaId && !draggingAreaId),
    areas,
    element.value.items,
    draggingAreaId,
    editAreaId,
    setAreas,
  ])

  useEffect(() => {
    result && setSelectedAreaId(null)
  }, [result, setSelectedAreaId])

  useEffect(() => {
    if (img) {
      const imgWidth = img.offsetWidth
      const imgHeight = img.offsetHeight

      const areaWidth = imgWidth * 0.2
      const areaHeight = imgHeight * 0.3

      const initialAreas = element.value.items.length
        ? element.value.items
        : [
            {
              id: generateId(),
              x: 50,
              y: 50,
              width: (areaWidth / imgWidth) * 100,
              height: (areaHeight / imgHeight) * 100,
            },
          ]

      setAreasRaw(() => initialAreas)
    }
  }, [img])

  return {
    onMouseDown,
    onResizeMouseDown,
    onPreviewMouseDown,
    onExistingAreaMouseDown,
    isActiveElement,
    activeSpot,
    areas,
    setAreas,
    setActiveSpot,
  }
}
