import React, { useCallback } from 'react'

import { IControlProps } from 'components/controls/Field/Field.types'
import Editable, { IEditableProps } from 'components/uiKit/Editable'

const FieldEditable: React.FC<IControlProps<IEditableProps>> = ({
  name,
  onChange,
  defaultValue,
  value,
  ...rest
}) => {
  const onChangeWrapper = useCallback(
    (value?: string | null) => onChange({ name, value }),
    [name, onChange],
  )

  return (
    <Editable
      {...rest}
      name={name}
      onChange={onChangeWrapper}
      value={value ?? (defaultValue as string)}
    />
  )
}

export default FieldEditable
