import { useMutation, useQuery, useSubscription } from '@apollo/client'
import { getOperationName } from '@apollo/client/utilities'

import {
  GetProjectBuildsAllQueryVariables,
  LastBuildFilter,
  PdfLastBuildQueryVariables,
} from 'gql/__generated__/graphql'

import { scormBuildFragment } from './gql/fragments'
import {
  deleteProjectBuilds,
  exportProjectToPdf,
  exportProjectToScorm,
  stopProjectExportToPdf,
  stopProjectExportToScorm,
  updateProjectBuilds,
} from './gql/mutations'
import { getProjectBuildsAll, pdfLastBuild, scormBuild } from './gql/queries'
import { pfdBuildSubscription, scormBuildSubscription } from './gql/subscriptions'

export const useExportPdfMutation = () =>
  useMutation(exportProjectToPdf, {
    onError: (err) =>
      console.error('"useFileMetaGroupCreate" fn is crashed on operation: "useMutation"', err),
    refetchQueries: [
      String(getOperationName(getProjectBuildsAll)),
      String(getOperationName(pdfLastBuild)),
      String(getOperationName(getProjectBuildsAll)),
    ],
  })

export const useExportBuildsAllQuery = (variables: GetProjectBuildsAllQueryVariables) =>
  useQuery(getProjectBuildsAll, {
    variables,
    fetchPolicy: 'cache-and-network',
    onError: (err) =>
      console.error('"useExportBuildsAllQuery" fn is crashed on operation: "useQuery"', err),
  })

export const usePdfLastBuildQuery = (variables: PdfLastBuildQueryVariables) =>
  useQuery(pdfLastBuild, {
    variables,
    fetchPolicy: 'cache-and-network',
    onError: (err) =>
      console.error('"useFileMetaGroupCreate" fn is crashed on operation: "useQuery"', err),
  })

export const useScormBuild = (projectId: string, filter?: LastBuildFilter) => {
  const { data } = useBuildLastGetByProjectIdQuery(projectId, filter)
  useScormBuildSubscription(projectId)
  if (!data?.data) {
    return undefined
  }
  return data.data
}

export const usePdfBuild = (projectId: string) => {
  const { data } = usePdfLastBuildQuery({ projectId })
  useSubscription(pfdBuildSubscription, {
    variables: { projectId },
    onData: ({ client, data }) => {
      if (!data.data) {
        return
      }
      client.cache.writeQuery({
        query: pdfLastBuild,
        variables: { projectId },
        data: { ...data.data, __typename: 'Query' },
      })
    },
  })
  return data?.data || undefined
}

export const useBuildLastGetByProjectIdQuery = (projectId: string, filter?: LastBuildFilter) =>
  useQuery(scormBuild, {
    variables: {
      projectId,
      filter,
    },
    onError: (err) =>
      console.error('"useBuildLastGetByProjectId" fn is crashed on operation: "useLazyQuery"', err),
  })

export const useBuildScormPackageInit = () =>
  useMutation(exportProjectToScorm, {
    onError: (err) =>
      console.error('"useBuildScormPackageInit" fn is crashed on operation: "useMutation"', err),
    refetchQueries: [
      String(getOperationName(getProjectBuildsAll)),
      String(getOperationName(scormBuild)),
    ],
  })

export const useScormBuildSubscription = (projectId: string) =>
  useSubscription(scormBuildSubscription, {
    variables: { projectId },
    onData: ({ client, data }) => {
      if (!data.data) {
        return
      }
      client.cache.writeQuery({
        query: scormBuild,
        variables: { projectId },
        //@ts-ignore TODO: 1373
        data: data.data,
      })
      client.writeFragment({
        id: `ScormBuild:${data.data.data.id}`,
        fragment: scormBuildFragment,
        fragmentName: 'ScormBuildSchema',
        data: { ...data.data.data, __typename: 'ScormBuild' },
      })
    },
  })

export const useStopScormExport = () =>
  useMutation(stopProjectExportToScorm, {
    onError: (err) =>
      console.error('"useStopScormExport" fn is crashed on operation: "useMutation"', err),
  })

export const useStopPdfExport = () =>
  useMutation(stopProjectExportToPdf, {
    onError: (err) =>
      console.error('"useStopPdfExport" fn is crashed on operation: "useMutation"', err),
  })

export const useUpdateProjectBuilds = () =>
  useMutation(updateProjectBuilds, {
    onError: (err) =>
      console.error('"useUpdateProjectBuilds" fn is crashed on operation: "useMutation"', err),
    refetchQueries: [String(getOperationName(getProjectBuildsAll))],
  })

export const useDeleteProjectBuilds = () =>
  useMutation(deleteProjectBuilds, {
    onError: (err) =>
      console.error('"useDeleteProjectBuild" fn is crashed on operation: "useMutation"', err),
    refetchQueries: [String(getOperationName(getProjectBuildsAll))],
  })
