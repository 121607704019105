import { gql } from 'gql/__generated__'

export const projectGroupListFragment = gql(`
  fragment ProjectGroupListSchema on ProjectGroup {
    id
    name
    description
    logo
    color
    parentId
    createdAt
    updatedAt
    analytics {
      projectsTotal
      tasksDone
      tasksTotal
      tasksExpired
    }
    createdBy {
      ...EmployeeSmallSchema
    }
  }
`)

export const projectGroupFragment = gql(`
  fragment ProjectGroupSchema on ProjectGroup {
    id
    name
    color
    parentId
    description
    updatedAt
    breadcrumbs {
      name
      id
      color
    }
  }
`)

export const movedProjectsAndGroupsDataFragment = gql(`
  fragment MovedProjectsAndGroupsData on MovedProjectsAndGroupsData {
    targetGroupId
    currentGroupId
    groupIds
    elementIds
  }
`)

// projects {
//   id
//   name
// }
export const catalogFragment = gql(`
  fragment Catalog on ProjectGroup {
    id
    name
    description
    logo
    color
    parentId
    childrenGroups {
      id
      name
    }
    currentGroups {
      id
      name
    }
    parentGroup {
      id
      name
    }
    lvl
    descendants
    isArchived
    pagesCount
    analytics {
      projectGroupsTotal
      projectsTotal
      projectsDone
      projectsExpired
      tasksTotal
      tasksDone
      tasksExpired
    }
    createdAt
    updatedAt
    breadcrumbs {
      id
      name
    }
  }
`)

export const dashboardProject = gql(`
  fragment DashboardProject on Project {
    id
    name
    accent
    createdAt
    employee {
      id
    }
    updatedAt
    createdBy {
      id
    }
    publicLink {
      ...PublicLinkSchema
    }
  }
`)

export const projectGroupsByIdQuery = gql(`
  query ProjectGroupsGetById($groupId: String!, $companyId: String!) {
    data: projectGroupsGetById(companyId: $companyId, groupId: $groupId) {
      ...ProjectGroupSchema
    }
  }
`)

export const projectGroupAll = gql(`
  query ProjectGroupsAll(
    $companyId: String!
    $parentId: String
    $search: ProjectGroupsSearch
    $deep: Boolean
  ) {
    breadcrumb: projectGroupsGetById(companyId: $companyId, groupId: $parentId) {
      ...ProjectGroupSchema
    }
    data: projectAndGroupAll(
      companyId: $companyId
      search: $search
      parentId: $parentId
      deep: $deep
    ) {
      projects {
        ...ProjectListSchema
      }
      groups {
        ...ProjectGroupListSchema
      }
      total
      totalGroup
      limit
      offset
    }
  }
`)

export const projectGroupsCreate = gql(`
  mutation ProjectGroupsCreate($projectGroup: ProjectGroupInput!, $companyId: String!) {
    data: projectGroupsCreate(data: $projectGroup, companyId: $companyId) {
      data {
        ...ProjectGroupListSchema
      }
      errors {
        ...ErrorSchema
      }
    }
  }
`)

export const projectGroupsUpdate = gql(`
  mutation ProjectGroupsUpdateById($groupId: String!, $data: ProjectGroupInput!) {
    data: projectGroupsUpdateById(groupId: $groupId, data: $data) {
      data {
        ...ProjectGroupListSchema
      }
      errors {
        ...ErrorSchema
      }
    }
  }
`)

export const catalogForceDelete = gql(`
  mutation CatalogForceDelete($data: MultiDeleteForceInput!) {
    data: projectGroupsMultiForceDeleteByIds(data: $data)
  }
`)

export const catalogMove = gql(`
  mutation CatalogMove($data: MoveInput!) {
    projectAndGroupMove(data: $data) {
      data {
        ...MovedProjectsAndGroupsData
      }
      errors {
        ...ErrorSchema
      }
    }
  }
`)

export const catalogDuplicate = gql(`
  mutation CatalogDuplicate($data: DuplicateInput!) {
    data: projectAndGroupDuplicate(data: $data) {
      ...Catalog
    }
  }
`)

export const dashboardProjects = gql(`
  query DashboardProjects($companyId: String!, $search: DashboardProjectsSearch!) {
    data: dashboardProjects(companyId: $companyId, search: $search) {
      projects {
        ...DashboardProject
      }
      pagination {
        total
      }
    }
  }
`)
