import { SCHEMA } from '@leenda/editor/lib/brand'

import { genFormSchema, FONT_SCHEMA_FIELDS } from 'services/Branding/genFormSchema'
import { t } from 'services/Translation'

export const STYLE_FORM_SCHEMA = genFormSchema(SCHEMA.testRanking.style, {
  item__var: {
    META: { label: t('elements.testRanking.tags.item') },
    color: { label: t('input.label.iconColor') },
    borderStyle: {},
    borderColor: {},
    borderWidth: {},
  },
  itemHover__var: {
    META: { label: t('elements.testRanking.tags.itemHover') },
    color: { label: t('input.label.iconColor') },
    borderColor: {},
  },
  offset__var: {
    META: { label: t('elements.testRanking.tags.spacing') },
    marginTop: { label: t('input.label.answerIndents') },
    padding: { label: t('input.label.innerAnswerIndents') },
  },
})

export const FONT_FORM_SCHEMA = genFormSchema(SCHEMA.testRanking.font, FONT_SCHEMA_FIELDS)
