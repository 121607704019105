import { useSize } from 'ahooks'
import cn from 'classnames'
import { AnimatePresence, motion, MotionProps } from 'framer-motion'
import React, { CSSProperties, FC, useCallback, useEffect } from 'react'
import { ReactNode, useRef, useState } from 'react'

import { testProps } from 'utils/test/qaData'

import s from './DropDown.module.scss'
import DropDownContent, { DropdownAnimationProps } from './DropDownContent'
import { DropDownContext, useDropDownContext } from './context'
import { IPlacement, IStyleType, ITheme, IType } from './types'
import { useEvents } from './useEvents'
import { useGetPosition } from './useGetPosition'

type DropdownRootAnimationProps = Pick<MotionProps, 'whileTap' | 'whileHover'>

const TRANSFORM_ORIGIN: Record<IPlacement, React.CSSProperties['transformOrigin']> = {
  bottom: 'bottom center',
  bottomLeft: 'bottom right',
  bottomRight: 'bottom left',
  left: 'center right',
  leftBottom: 'bottom right',
  leftTop: 'top right',
  right: 'center left',
  rightBottom: 'bottom left',
  rightTop: 'top left',
  top: 'top center',
  topLeft: 'top right',
  topRight: 'top left',
}

export interface IDropDownProps extends DropdownAnimationProps, DropdownRootAnimationProps {
  children: ReactNode | FnChild
  overlay?: React.ReactNode
  height?: number
  trigger?: ('click' | 'hover' | 'hoverWithoutContent')[]
  textError?: string
  placement?: IPlacement
  fluid?: boolean
  disabled?: boolean
  onVisibleChange?: (visible: boolean) => void
  visible?: boolean
  styleType?: IStyleType
  type?: IType
  offset?: [number, number]
  theme?: ITheme
  overlayStyle?: CSSProperties
  popupContainer?: HTMLElement
  mouseEnterDelay?: number
  name?: string
  closeOnRootClick?: boolean
  stopPropagation?: boolean
  onBlur?: (e: React.FocusEvent<HTMLDivElement>) => void
  onFocus?: (e: React.FocusEvent<HTMLDivElement>) => void
  onMouseDown?: (e: React.MouseEvent<HTMLDivElement>) => void
  onMouseUp?: (e: React.MouseEvent<HTMLDivElement>) => void
  onMouseLeave?: (e: React.MouseEvent<HTMLDivElement>) => void
  onMouseOver?: (e: React.MouseEvent<HTMLDivElement>) => void
  tabIndex?: number
  rootRef?: React.RefObject<HTMLDivElement>
}

export type FnChild = (props: {
  open: boolean
  setOpen: (open: boolean) => void
}) => React.ReactNode

export const DropDown: FC<IDropDownProps> = ({
  children,
  overlay,
  height = 'auto',
  trigger = ['click'],
  textError,
  placement = 'bottomLeft',
  fluid,
  disabled,
  onVisibleChange,
  visible = false,
  type = 'popover',
  styleType = 'default',
  theme,
  offset = [0, 0],
  overlayStyle,
  popupContainer,
  mouseEnterDelay,
  closeOnRootClick = true,
  name,
  stopPropagation,
  onBlur,
  onFocus,
  onMouseDown,
  onMouseUp,
  onMouseLeave,
  onMouseOver,
  tabIndex,
  rootRef,
  whileHover,
  whileTap,
  ...rest
}) => {
  const [focus, setFocus] = useState(false)
  const localRef = useRef<HTMLDivElement | null>(null)
  const refRoot = rootRef || localRef
  const [open, setOpen] = useState<boolean>(visible)
  const triggerHoverWithoutContent = trigger.includes('hoverWithoutContent')
  const triggerHover = trigger.includes('hover') || type === 'tooltip'
  const triggerClick = trigger.includes('click') && type !== 'tooltip'
  const [popupEle, setPopupEle] = React.useState<HTMLDivElement | null>(null)
  const refPortal = useRef<HTMLDivElement | null>(null)
  const defaultTheme = type === 'tooltip' && !theme ? 'dark' : theme || 'light'
  const hoverOpeningDelay = mouseEnterDelay ? mouseEnterDelay * 1000 : 0
  const size = useSize(popupEle)
  let timeout: NodeJS.Timeout | null = null

  const { contextValue, subPopupElements } = useDropDownContext()

  const positions = useGetPosition({
    refRoot: refRoot.current,
    placement,
    popupEle,
    refPortal,
    type,
    offset,
    size,
  })

  const styleContent = { ...positions, height }

  const openRef = React.useRef(open)
  openRef.current = open

  const triggerOpen = useCallback(
    (nextOpen: boolean) => {
      if (open !== nextOpen) {
        onVisibleChange?.(nextOpen)
        setOpen(nextOpen)
      }
    },
    [open, onVisibleChange],
  )

  const inPopupOrChild = (element: Node) => {
    const childDOM = refRoot.current
    if (triggerHoverWithoutContent && type === 'tooltip') {
      return childDOM?.contains(element) || element === childDOM
    }

    return (
      childDOM?.contains(element) ||
      element === childDOM ||
      popupEle?.contains(element) ||
      element === popupEle ||
      (subPopupElements &&
        Object.values(subPopupElements.current).some(
          (subPopupEle) => subPopupEle?.contains(element) || element === subPopupEle,
        ))
    )
  }

  useEvents({
    inPopupOrChild,
    openRef,
    onVisibleChange,
    popupEle,
    triggerOpen,
  })

  const onClickToggleOpenModal = (event: React.MouseEvent) => {
    if (stopPropagation) {
      event.stopPropagation()
    }
    if (!triggerHoverWithoutContent && !triggerHover && !disabled) {
      triggerOpen(closeOnRootClick ? !open : true)
    }
  }

  const onHoverOpenModal = () => {
    timeout = setTimeout(() => {
      if (!triggerClick && !disabled) {
        triggerOpen(true)
      }
    }, hoverOpeningDelay)
  }

  const handleFocus = (e: React.FocusEvent<HTMLDivElement>) => {
    setFocus(true)
    onFocus?.(e)
  }

  const handleBlur = (e: React.FocusEvent<HTMLDivElement>) => {
    const portal = popupContainer || refPortal.current

    if (
      portal &&
      focus &&
      // e.relatedTarget &&
      !refRoot.current?.contains(e.relatedTarget) &&
      !portal.contains(e.relatedTarget)
    ) {
      setFocus(false)
      onBlur?.(e)
    } else if (
      !portal &&
      focus &&
      // e.relatedTarget &&
      !refRoot.current?.contains(e.relatedTarget)
    ) {
      setFocus(false)
      onBlur?.(e)
    }
  }

  useEffect(() => {
    const onOutsideLeave = (e: MouseEvent) => {
      const target = e.target as Node
      if (triggerHover || triggerHoverWithoutContent) {
        if (openRef?.current && !inPopupOrChild(target)) {
          triggerOpen(false)
          onVisibleChange?.(false)
        }
        if (!openRef?.current && !inPopupOrChild(e.target as Node) && timeout) {
          clearTimeout(timeout)
          timeout = null
        }
      }
    }
    document.addEventListener('mouseover', onOutsideLeave)
    return () => document.removeEventListener('mouseover', onOutsideLeave)
  }, [triggerHover, triggerHoverWithoutContent, timeout, openRef, inPopupOrChild])

  useEffect(() => {
    // if (visible !== open) {
    triggerOpen(visible)
    // }
  }, [visible])

  useEffect(() => {
    return () => {
      if (timeout) {
        clearTimeout(timeout)
        timeout = null
      }
    }
  }, [])

  const childNode = typeof children === 'function' ? children({ open, setOpen }) : children

  return (
    <div
      className={cn(s.root, { [s.fluid]: fluid, [s.disabled]: disabled })}
      onBlur={handleBlur}
      onFocus={handleFocus}
      onMouseDown={onMouseDown}
      onMouseEnter={onHoverOpenModal}
      onMouseLeave={onMouseLeave}
      onMouseOver={onMouseOver}
      onMouseUp={onMouseUp}
      ref={refRoot}
      tabIndex={tabIndex}
      {...testProps({ el: 'dropdown', name, open })}
    >
      <motion.div
        className={s.header}
        onClick={onClickToggleOpenModal}
        whileHover={whileHover}
        whileTap={whileTap}
      >
        {childNode}
      </motion.div>
      <DropDownContext.Provider value={contextValue}>
        <AnimatePresence>
          {open && overlay && (
            <DropDownContent
              {...rest}
              open={open}
              overlayStyle={overlayStyle}
              popupContainer={popupContainer}
              refPortal={refPortal}
              setPopupEle={setPopupEle}
              stopPropagation={stopPropagation}
              styleContent={styleContent}
              styleType={styleType}
              textError={textError}
              theme={defaultTheme}
              transformOrigin={TRANSFORM_ORIGIN[placement]}
              type={type}
            >
              {overlay}
            </DropDownContent>
          )}
        </AnimatePresence>
      </DropDownContext.Provider>
    </div>
  )
}

export default DropDown
