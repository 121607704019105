import React from 'react'

import Empty from 'components/uiKit/Empty'
import { KitSize } from 'components/uiKit/KitTypes'
import { AppMode } from 'services/Store/Project/enums'
import { t } from 'services/Translation'

import { RichTextValueFormType } from './RichTextElement.types'
import s from './RichTextForm.module.scss'

const RichTextForm: RichTextValueFormType = ({ mode }) => {
  const isPro = mode.editorMode === AppMode.pro
  return (
    <div className={s.root}>
      <Empty
        icon='settings'
        size={KitSize.XS}
        text={isPro ? t('uiKit.empty.richTextPro') : t('uiKit.empty.richTextFill')}
        grow
      />
    </div>
  )
}

export default React.memo(RichTextForm)
