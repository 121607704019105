import { TestFooterFontSchemaType, TestFooterSchemaType } from '@leenda/editor/lib/brand'
import React from 'react'

import { useElementRootStyles } from 'components/editor-v2/EditorElements/hooks/useElementRootStyles'
import Progress from 'components/uiKit/Progress'
import { ElementFontCss, ElementStyleCss } from 'services/Branding/types'
import { useProjectT } from 'services/ProjectLabels'

import BlockTestTimer from './BlockTestTimer'
import SectionTestTimer from './SectionTestTimer'
import s from './TestProgressFooter.module.scss'

interface ITestProgressFooterProps {
  fonts: ElementFontCss<TestFooterFontSchemaType>
  styles: ElementStyleCss<TestFooterSchemaType>
  isProgressShown?: boolean | null
  isSectionTime?: number | false | null
  isBlockTime?: boolean | 0 | null
  testLimit?: boolean | null
  testProgress?: number
  totalQuestionsDone?: number
  totalQuestions?: number
  currentTry?: number
  testRetryCount?: number | null
}

const TestProgressFooter: React.FC<ITestProgressFooterProps> = ({
  styles,
  fonts,
  isProgressShown,
  testProgress,
  totalQuestions,
  totalQuestionsDone,
  isSectionTime,
  isBlockTime,
  testLimit,
  currentTry,
  testRetryCount,
}) => {
  const pt = useProjectT()
  const rootStyles = useElementRootStyles(styles.root, styles.panel, fonts?.base)

  return (
    <div className={s.root} style={rootStyles}>
      <div className={s.progress}>
        {isProgressShown && (
          <>
            <Progress
              customStyles={{ stroke: 'var(--brand-focusedColor)' }}
              name='testFooter'
              percent={testProgress || 0}
            />
            <div className={s.progressLabel}>
              {totalQuestionsDone} / {totalQuestions}
            </div>
          </>
        )}
      </div>
      {isSectionTime && <SectionTestTimer />}
      {!isSectionTime && isBlockTime && <BlockTestTimer />}
      {testLimit && (
        <div className={s.tryCount}>
          {pt('footer.try')} : {currentTry ?? 1} {pt('footer.from')} {testRetryCount}
        </div>
      )}
    </div>
  )
}

export default TestProgressFooter
