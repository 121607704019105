import React, { useCallback } from 'react'

import { IControlProps } from 'components/controls/Field/Field.types'
import CheckableTag, { TagsProps } from 'components/uiKit/CheckableTag'

const FieldTag: React.FC<IControlProps<TagsProps<string>>> = ({ name, onChange, ...rest }) => {
  const handleChange = useCallback((value: string[]) => onChange({ value, name }), [name, onChange])

  return <CheckableTag name={name} onChange={handleChange} {...rest} />
}

export default FieldTag
