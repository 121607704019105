import { TimeFormat, TimeUnit, InternalTime } from './types'

export const generateNumbers = (max: number, min: number = 0): string[] => {
  return Array.from({ length: max - min + 1 }, (_, index) => {
    const value = index + min
    return value < 10 ? `0${value}` : `${value}`
  })
}

export const getMinMaxValues = ({
  maxTime,
  minTime,
  selectedTime,
}: {
  maxTime?: number | null
  minTime?: number | null
  selectedTime: InternalTime
}) => {
  const maxTimeSeconds = maxTime || Infinity
  const minTimeSeconds = minTime || 0
  const { ss: secondsMax, mm: minutesMax, HH: hoursMax } = getTimeFromSeconds(maxTimeSeconds)

  const maxValue = {
    HH: hoursMax,
    mm: (selectedTime.HH || 0) === hoursMax ? minutesMax : undefined,
    ss:
      (selectedTime.HH || 0) === hoursMax && selectedTime.mm === minutesMax
        ? secondsMax
        : undefined,
  }

  const {
    ss: secondsMin = 0,
    mm: minutesMin = 0,
    HH: hoursMin = 0,
  } = getTimeFromSeconds(minTimeSeconds)

  const minValue = {
    HH: hoursMin,
    mm: (selectedTime.HH || 0) === hoursMin ? minutesMin : undefined,
    ss:
      (selectedTime.HH || 0) === hoursMin && selectedTime.mm === minutesMin
        ? secondsMin
        : undefined,
  }
  return { maxValue, minValue }
}

export const getSecondsFromTime = (time: InternalTime): number => {
  const { HH, mm, ss } = time
  return (HH || 0) * 3600 + (mm || 0) * 60 + (ss || 0)
}

export const getTimeFromSeconds = (seconds: number): InternalTime => {
  const HH = Math.floor(seconds / 3600)
  const mm = Math.floor((seconds - HH * 3600) / 60)
  const ss = seconds - HH * 3600 - mm * 60
  return { HH, mm, ss }
}
const padStartZero = (num: number) => num.toString().padStart(2, '0')

export const getStringFromTime = (time: InternalTime, format: TimeFormat): string => {
  const newTime = format
    .split(':')
    .map((part: string) => padStartZero(time[part as TimeUnit]))
    .join(':')
  return newTime
}
export const getTimeFromString = (value: string, format: TimeFormat): InternalTime => {
  const keys = format.split(':')
  const values = value.split(':')
  const { HH, mm, ss } = keys.reduce(
    (acc, key, index) => {
      acc[key as TimeUnit] = Number(values[index])
      return acc
    },
    {} as Record<TimeUnit, number>,
  )
  return { HH, mm, ss }
}

export const getSecondsFromString = (value: string, format: TimeFormat): number => {
  const time = getTimeFromString(value, format)
  return getSecondsFromTime(time)
}
