"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const constants_1 = require("../../../elements/course/TestFooter/constants");
const font_1 = require("../../font");
const theme_1 = require("../../theme");
const genStyle_1 = require("../genStyle");
const styleSchema = {
    panel: {
        backgroundColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.primaryBackgroundColor }),
    },
};
const fontSchema = {
    base: Object.assign({}, font_1.FONT_SCHEMA_EMPTY.base),
};
exports.default = { [constants_1.TYPE]: { style: styleSchema, font: fontSchema } };
