import { SliderSchemaType, SliderFontSchemaType } from '@leenda/editor/lib/brand'
import { SliderElementValue } from '@leenda/editor/lib/elements'

import { FCValueFormType, FCElementType } from '../../elements.types'

export type Props = FCElementType<
  SliderElementValue,
  SliderSchemaType,
  SliderFontSchemaType,
  SliderState
>
export type Form = FCValueFormType<
  SliderElementValue,
  SliderSchemaType,
  SliderFontSchemaType,
  SliderState
>

export enum ArrowPosition {
  slide = 'slide',
  progress = 'progress',
}

export type SliderState = {
  active: string | null
}
