import { produce } from 'immer'

import { SectionTreeItemType } from 'components/sections/ProjectStructure/utils'

import { gqlClient } from '.'
import {
  EmployeePreferencesSchemaFragment,
  SectionOrderSchemaFragment,
} from './__generated__/graphql'
import { sectionsAllQuery } from './sections.gql'

export const projectModifySectionsOrder = (
  projectId: string,
  sectionsOrder?: SectionOrderSchemaFragment[] | null,
) => {
  const cache = gqlClient.core.cache
  try {
    cache.modify({
      id: 'Project:' + projectId,
      fields: {
        sectionsOrder: () => {
          return sectionsOrder || null
        },
      },
    })
  } catch (err) {
    console.error('"projectModifySectionsOrder" fn is crashed on operation: ".modify"', err)
  }
}

export const projectModifyEmployeePreference = (
  projectId: string,
  employeePreferences?: Omit<EmployeePreferencesSchemaFragment, '__typename'> | null,
) => {
  const cache = gqlClient.core.cache
  try {
    cache.modify({
      id: 'Project:' + projectId,
      fields: {
        employeePreferences: (data) => {
          return { __typename: 'EmployeePreferences', ...data, ...employeePreferences }
        },
      },
    })
  } catch (err) {
    console.error('"projectModifyEmployeePreference" fn is crashed on operation: ".modify"', err)
  }
}

export const updateSectionsAndSectionOrderInCache = (
  projectId: string,
  sections: SectionTreeItemType[],
  sectionOrder: SectionOrderSchemaFragment[] | null,
) => {
  projectModifySectionsOrder(projectId, sectionOrder)
  const cache = gqlClient.core.cache
  try {
    const data = cache.readQuery({
      query: sectionsAllQuery,
      variables: {
        projectId,
      },
    })

    if (data) {
      cache.writeQuery({
        query: sectionsAllQuery,
        variables: {
          projectId,
        },
        data: produce(data, (draft) => {
          draft.data = [...sections]
        }),
      })
    }
  } catch (err) {
    console.error('"addEmployee" fn is crashed on operation: ".writeQuery"', err)
  }
}
