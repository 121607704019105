import React, { ReactNode } from 'react'

import DropDown, { IDropDownProps } from '../DropDown'

type ITooltip = Omit<IDropDownProps, 'type' | 'children'> & { children: ReactNode }

const Tooltip: React.FC<ITooltip> = ({ children, overlay, ...restProps }) =>
  !overlay ? (
    children
  ) : (
    <DropDown {...restProps} overlay={overlay} type='tooltip'>
      {children}
    </DropDown>
  )

export default Tooltip
