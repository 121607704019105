import { useMemo } from 'react'

import { TableContext } from './TableContext'
import TableCore from './TableCore'
import { usePageSize, useSort } from './table.hooks'
import { ITablesExtendsData, TableProps } from './types'

const Table = <T extends ITablesExtendsData>(props: TableProps<T>) => {
  const { name, dataSource, total, defaultSort = 'updatedAt', onLoadMore, hasNextPage } = props
  const { page, pageSize, setPage } = usePageSize(name)
  const { sort, sortOrder, setSort } = useSort(name, defaultSort as string)

  const value = useMemo(
    () => ({
      pagination: {
        page,
        pageSize,
        setPage,
        total: total || dataSource.length,
      },
      sort: {
        sortKey: sort,
        sortOrder,
        setSort,
      },
      onLoadMore,
      hasNextPage,
    }),
    [
      page,
      pageSize,
      setPage,
      total,
      dataSource.length,
      sort,
      sortOrder,
      setSort,
      onLoadMore,
      hasNextPage,
    ],
  )

  return (
    <TableContext.Provider value={value}>
      <TableCore {...props} />
    </TableContext.Provider>
  )
}

export default Table
