"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const constants_1 = require("../../../elements/elements/Table/constants");
const font_1 = require("../../font");
const theme_1 = require("../../theme");
const genStyle_1 = require("../genStyle");
const styleSchema = {
    divider: {
        borderColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.borderColor }),
        borderWidth: (0, genStyle_1.genStyle)({ value: 1 }),
    },
    content: {
        backgroundColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.primaryBackgroundColor }),
    },
    header: {
        backgroundColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.lightBackgroundColor }),
    },
    indent: {
        padding: (0, genStyle_1.genStyle)({ value: [16, 24, 16, 24] }),
    },
};
const fontSchema = Object.assign({}, font_1.FONT_SCHEMA_EMPTY);
exports.default = { [constants_1.TYPE]: { style: styleSchema, font: fontSchema } };
