import React from 'react'
import { Helmet } from 'react-helmet-async'

import { FontGroupFullSchemaFragment } from 'gql/__generated__/graphql'

import { genFontFace } from './genFontFace'

interface IFontsInjector {
  fontGroups?: FontGroupFullSchemaFragment[]
}

const FontsInjector: React.FC<IFontsInjector> = ({ fontGroups = [] }) =>
  Boolean(fontGroups.length) && (
    <Helmet>
      {fontGroups
        .map(({ id, fonts }: FontGroupFullSchemaFragment) =>
          fonts.map(({ fileMeta: { id: fileMetaId, files }, weight, isItalic }) => [
            <style key={`${fileMetaId}-style`}>
              {genFontFace({ name: id, file: files[0], weight, isItalic })}
            </style>,
            <link
              as='font'
              crossOrigin='anonymous'
              href={files[0]?.path}
              key={`${fileMetaId}-link`}
              rel='preload'
              type='font/woff2'
            />,
          ]),
        )
        .flat()}
    </Helmet>
  )

export default React.memo(FontsInjector)
