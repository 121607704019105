import React, { useCallback } from 'react'

import { IControlProps } from 'components/controls/Field/Field.types'
import InputNumber, { IInputNumberProps } from 'components/uiKit/InputNumber'

import InputNumberArray from './InputNumberArray'

export interface IArrayOption extends Omit<IInputNumberProps, 'value' | 'onChange'> {
  icon?: string | JSX.Element
}

interface IProps extends Omit<IInputNumberProps, 'value' | 'onChange' | 'defaultValue'> {
  value: number | (number | undefined)[] | string
  defaultValue: number | (number | undefined)[] | string
  onChange: (value: number | (number | undefined)[] | string) => void
  array?: IArrayOption[]
}

const FieldNumber: React.FC<IControlProps<IProps>> = ({
  name,
  onChange,
  value,
  array,
  defaultValue,
  ...rest
}) => {
  const onChangeWrapper = useCallback(
    (value?: number | (number | undefined)[] | string) => onChange({ name, value }),
    [name, onChange],
  )
  const newValue = value ?? defaultValue

  if (array) {
    return (
      <InputNumberArray
        {...rest}
        array={array}
        defaultValue={defaultValue as number[]}
        name={name}
        onChange={onChangeWrapper}
        value={newValue as number[]}
      />
    )
  }

  return <InputNumber {...rest} name={name} onChange={onChangeWrapper} value={newValue as number} />
}
export default FieldNumber
