import cn from 'classnames'
import { useEffect } from 'react'
import React from 'react'

import Icon from 'components/uiKit/Icon'
import { preventDefault } from 'constants/commonConstans'
import { FileMetaFor } from 'gql/__generated__/graphql'
import { t } from 'services/Translation'
import { UppyType } from 'store/models'

import { useFileMeta } from '../../finder-v2/useSetFileMetaUppy'
import s from './DragFileContainer.module.scss'

type IDragFileContainerProps = {
  isModal?: boolean
  type?: string
  createdById?: string
  createdFor?: FileMetaFor
  uppy: UppyType
  params: { companyId: string; projectId?: string; fileMetaGroupId?: string }
  children?: React.ReactNode
}

interface IExtendedFile extends File {
  relativePath?: string | null
}

const isFile = (
  entry: FileSystemFileEntry | FileSystemDirectoryEntry | FileSystemEntry,
): entry is FileSystemFileEntry => entry.isFile

function toArray(list?: DataTransferItemList | FileList) {
  return Array.prototype.slice.call(list || [], 0)
}

function getRelativePath(fileEntry: FileSystemFileEntry) {
  if (!fileEntry.fullPath || fileEntry.fullPath === '/' + fileEntry.name) {
    return null
  } else {
    return fileEntry.fullPath
  }
}

function getFilesAndDirectoriesFromDirectory(
  directoryReader: FileSystemDirectoryReader,
  oldEntries: FileSystemEntry[],
  { logDropError }: { logDropError: (error: DOMException) => void },
  { onSuccess }: { onSuccess: (entries: FileSystemEntry[]) => void },
) {
  directoryReader?.readEntries(
    (entries) => {
      const newEntries = [...oldEntries, ...entries]
      // According to the FileSystem API spec, getFilesAndDirectoriesFromDirectory() must be called until it calls the onSuccess with an empty array.
      if (entries.length) {
        setTimeout(() => {
          getFilesAndDirectoriesFromDirectory(
            directoryReader,
            newEntries,
            { logDropError },
            {
              onSuccess,
            },
          )
        }, 0)
        // Done iterating this particular directory
      } else {
        onSuccess(newEntries)
      }
    },
    // Make sure we resolve on error anyway, it's fine if only one directory couldn't be parsed!
    (error: DOMException) => {
      logDropError(error)
      onSuccess(oldEntries)
    },
  )
}

function webkitGetAsEntryApi(
  dataTransfer: DataTransfer | null,
  logDropError: (error: DOMException) => void,
) {
  const files: IExtendedFile[] = []

  const rootPromises: Promise<FileSystemFileEntry[] | undefined>[] = []

  /**
   * Returns a resolved promise, when :files array is enhanced
   *
   * @param {(FileSystemFileEntry|FileSystemDirectoryEntry)} entry
   * @returns {Promise} - empty promise that resolves when :files is enhanced with a file
   */
  const createPromiseToAddFileOrParseDirectory = (
    entry: FileSystemFileEntry | FileSystemDirectoryEntry,
  ): Promise<FileSystemFileEntry[] | undefined> =>
    new Promise((resolve) => {
      // This is a base call
      if (isFile(entry)) {
        // Creates a new File object which can be used to read the file.
        entry.file(
          (file: IExtendedFile) => {
            file.relativePath = getRelativePath(entry)
            files.push(file)
            resolve(undefined)
          },
          // Make sure we resolve on error anyway, it's fine if only one file couldn't be read!
          (error: DOMException) => {
            logDropError(error)
            resolve(undefined)
          },
        )
        // This is a recursive call
      } else if (entry.isDirectory) {
        const directoryReader = entry.createReader()
        getFilesAndDirectoriesFromDirectory(
          directoryReader,
          [],
          { logDropError },
          {
            onSuccess: (entries: FileSystemEntry[]) => {
              const promises = entries
                .map((entry) =>
                  isFile(entry) ? createPromiseToAddFileOrParseDirectory(entry) : undefined,
                )
                .filter((e) => e)
              Promise.all(promises).then(() => resolve(undefined))
            },
          },
        )
      }
    })

  // For each dropped item, - make sure it's a file/directory, and start deepening in!
  toArray(dataTransfer?.items).forEach((item: DataTransferItem) => {
    const entry = item?.webkitGetAsEntry()
    // :entry can be null when we drop the url e.g.
    if (entry && isFile(entry)) {
      rootPromises.push(createPromiseToAddFileOrParseDirectory(entry))
    }
  })

  return Promise.all(rootPromises).then(() => files)
}

function fallbackApi(dataTransfer: DataTransfer | null) {
  const files = toArray(dataTransfer?.files)
  return Promise.resolve(files)
}

function getDroppedFiles(
  dataTransfer: DataTransfer | null,
  logDropError = (_error: DOMException) => {},
) {
  // Get all files from all subdirs. Works (at least) in Chrome, Mozilla, and Safari
  if (dataTransfer?.items && dataTransfer.items[0] && 'webkitGetAsEntry' in dataTransfer.items[0]) {
    return webkitGetAsEntryApi(dataTransfer, logDropError)
    // Otherwise just return all first-order files
  } else {
    return fallbackApi(dataTransfer)
  }
}

const DragFileContainer = React.forwardRef<HTMLDivElement, IDragFileContainerProps>(
  ({ uppy, type, createdById, createdFor, isModal, params, children }, ref) => {
    const addFiles = useFileMeta({ uppy, createdFor, createdById, ...params })
    const [dragOver, setDragOver] = React.useState(false)

    useEffect(() => {
      let timeout: NodeJS.Timeout

      const handleDrop = (event: DragEvent) => {
        event.preventDefault()
        event.stopPropagation()

        getDroppedFiles(event.dataTransfer, (error: DOMException) => uppy.log(error.message)).then(
          (files) => addFiles(files),
        )
        clearTimeout(timeout)
        timeout = setTimeout(() => {
          setDragOver(false)
        }, 100)
      }

      const handleDragOver = (event: DragEvent) => {
        event.preventDefault()
        event.stopPropagation()

        clearTimeout(timeout)
        setDragOver(true)
        // 1. Add a small (+) icon on drop
        // (and prevent browsers from interpreting this as files being _moved_ into the browser, https://github.com/transloadit/uppy/issues/1978)
        if (event.dataTransfer) {
          event.dataTransfer.dropEffect = 'copy'
        }
      }

      const handleDragLeave = (event: DragEvent) => {
        event.preventDefault()
        event.stopPropagation()

        clearTimeout(timeout)
        timeout = setTimeout(() => {
          setDragOver(false)
        }, 100)
      }

      document.body.addEventListener('drop', handleDrop)
      document.body.addEventListener('dragover', handleDragOver)
      document.body.addEventListener('dragleave', handleDragLeave)

      return () => {
        document.body.removeEventListener('drop', handleDrop)
        document.body.removeEventListener('dragover', handleDragOver)
        document.body.removeEventListener('dragleave', handleDragLeave)
      }
    }, [uppy, isModal, type, createdFor, createdById, addFiles, ref])

    return (
      <div
        className={cn(s.root, dragOver && s.filesOnDragModal)}
        onMouseDown={preventDefault}
        ref={ref}
      >
        {children}
        {dragOver && (
          <span className={s.help}>
            <span className={s.helpText}>
              <Icon name='builderArrowDown' /> {t('dragAndDrop.helpText')}
            </span>
          </span>
        )}
      </div>
    )
  },
)

DragFileContainer.displayName = 'DragFileContainer'

export default DragFileContainer
