import { useCallback } from 'react'

import { TaskMock } from 'components/uiKit/Task'
import { setOpenedTask, setToolbar } from 'services/Store/Project/actions'
import { TasksTab } from 'services/Store/Project/enums'
import { useProjectContext, useProjectDispatch } from 'services/Store/Project/hooks'
import { getSettings, getToolbar } from 'services/Store/Project/selectors'

import AbsolutePortal from '../AbsolutePortal'
import { ABSOLUTE_PORTAL_GAP } from '../AbsolutePortal/constants'
import CanvasBubble from '../CanvasBubble'

interface ICanvasTaskProps {
  blockId: string
  task: TaskMock
}

const CanvasTask: React.FC<ICanvasTaskProps> = ({ blockId, task }) => {
  const dispatch = useProjectDispatch()
  const showTasks = useProjectContext(getSettings).tasks
  const toolbar = useProjectContext(getToolbar)

  const handleTaskClick = useCallback(() => {
    dispatch(setToolbar({ taskTab: TasksTab.current, commentTab: null, hidden: false }))
    dispatch(setOpenedTask({ blockId, taskId: task?.id }))
  }, [dispatch, blockId, task])

  if (!(showTasks || toolbar.taskTab)) {
    return null
  }

  return (
    <AbsolutePortal
      name='canvasTask'
      placement='leftTop'
      translateX={`calc(-100% - ${ABSOLUTE_PORTAL_GAP}px)`}
    >
      <CanvasBubble icon='checkmarkCircle1' onClick={handleTaskClick} />
    </AbsolutePortal>
  )
}
export default CanvasTask
