import { LanguagesEnum } from '@leenda/editor/lib/elements/elements/Code/constants'
import CodeMirror from '@uiw/react-codemirror'
import { useDebounceFn } from 'ahooks'
import { useState } from 'react'

import { PreviewMode } from 'services/Store/Project/enums'

import { useElementRootStyles } from '../../hooks/useElementRootStyles'
import { languagesMap } from './CodeConstants'
import s from './CodeElement.module.scss'
import { CodeElementType } from './CodeElement.types'

const CodeElement: CodeElementType = ({ styles, element, mode, onChange }) => {
  const rootStyles = useElementRootStyles(styles.root)
  const preview = mode.previewMode !== PreviewMode.editor
  const { code, language = LanguagesEnum.html, lineNumbers, theme } = element.value
  const [value, setValue] = useState(code)
  const extensions = [languagesMap[language]()]

  const { run: onChangeDebounce } = useDebounceFn(
    (code: string) => {
      onChange && onChange({ ...element.value, code })
      setValue(code)
    },
    { wait: 500 },
  )

  return (
    <div className={s.root} style={rootStyles}>
      <CodeMirror
        basicSetup={{ lineNumbers }}
        // eslint-disable-next-line react/forbid-component-props
        className={s.codeEditor}
        extensions={extensions}
        onChange={onChangeDebounce}
        readOnly={preview}
        theme={theme}
        value={value}
        width='100%'
      />
    </div>
  )
}
export default CodeElement
