import cn from 'classnames'
import React from 'react'

import { KitSize } from 'components/uiKit/KitTypes'

import Button, { IButtonPropsType } from '../Button'
import s from '../Button.module.scss'
import IconButton from '../IconButton/IconButton'

const isButton = (child: unknown): child is React.ReactElement<IButtonPropsType> =>
  React.isValidElement(child) && child.type === Button
const isIconButton = (child: unknown): child is React.ReactElement<IButtonPropsType> =>
  React.isValidElement(child) && child.type === IconButton

/**
 * Props for the ButtonGroup component.
 */
interface IButtonGroupProps {
  /**
   * The style type of the button group.
   */
  styleType?: 'primary' | 'secondary' | 'ghost' | 'accent'
  /**
   * The size of the button group.
   */
  size?: KitSize
  /**
   * Whether the buttons in the group should be round.
   */
  round?: boolean
  /**
   * Whether the button group is currently active.
   */
  active?: boolean
  /**
   * Whether the button group is disabled.
   */
  disabled?: boolean
  /**
   * Whether the button group is currently in a loading state.
   */
  loading?: boolean
  /**
   * The child elements of the button group.
   */
  children: React.ReactNode
}

const ButtonGroup: React.FC<IButtonGroupProps> = ({ children, ...rest }) => {
  const buttons = React.Children.map(children, (child) =>
    isButton(child) || isIconButton(child)
      ? React.cloneElement(child, {
          ...rest,
          ...child.props,
          className: cn(child.props.className, s.button),
        })
      : child,
  )
  if (buttons?.length === 1) {
    return buttons
  }
  return <div className={s.group}>{buttons}</div>
}

export default ButtonGroup
