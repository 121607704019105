import {
  CollapseIconPosition,
  CourseStructureItem,
  ProgressIconPosition,
} from '@leenda/editor/lib/elements'
import cn from 'classnames'
import React from 'react'

import { ISectionTreeNode } from 'components/sections/ProjectStructure/utils'
import CollapseIcon from 'components/uiKit/Collapse/CollapseIcon'
import { useProjectContext } from 'services/Store/Project/hooks'
import { isSectionDisabled } from 'services/Store/Project/selectors'

import { StructureElementDisplayConfig } from '../../CourseStructureTree'
import { SectionStatus } from '../SectionStatus/SectionStatus'
import { StructureItemElement } from '../StructureItemElement'
import s from './StructureNodeElement.module.scss'

interface ITreeNodeProps {
  displayConfig: StructureElementDisplayConfig
  toggle?(active?: boolean): void
  open: boolean
  node: ISectionTreeNode<CourseStructureItem>
  onClick?: () => void
  active: boolean
}

const LEVEL_INDENT = 30

const StructureNodeElement: React.FC<ITreeNodeProps> = ({
  displayConfig,
  open,
  node,
  onClick,
  toggle,
  active,
}) => {
  const { item } = node
  const marginLeft = item.lvl * LEVEL_INDENT

  const disabled = useProjectContext(isSectionDisabled, item.id)

  const iconPosition = [CollapseIconPosition.right, CollapseIconPosition.rightOutside].includes(
    displayConfig.collapsePosition,
  )
    ? 'right'
    : 'left'

  const collapseIcon = (
    <div
      className={cn(s.collapseIcon, s[displayConfig.collapsePosition])}
      onClick={() => toggle && toggle()}
    >
      {item.isChapter && toggle && displayConfig.collapsePosition !== CollapseIconPosition.none && (
        <CollapseIcon iconPosition={iconPosition} open={open} />
      )}
    </div>
  )

  const onClickHandler = () => {
    if (item.isChapter) {
      toggle && toggle()
    } else {
      onClick && onClick()
    }
  }

  const rootCn = cn(s.root, {
    [s.cursorOpen]: item.isChapter && open,
    [s.cursorClose]: item.isChapter && !open,
    [s.cursorPointer]: !item.isChapter,
    [s.active]: active,
    [s.disabled]: disabled,
  })

  return (
    <div className={rootCn} onClick={!disabled ? onClickHandler : undefined}>
      {displayConfig.collapsePosition === CollapseIconPosition.leftOutside && collapseIcon}
      {displayConfig.progressPosition === ProgressIconPosition.left && (
        <SectionStatus disabled={disabled} displayConfig={displayConfig} node={node} open={open} />
      )}
      <div className={s.nodeIndent} style={{ marginLeft }}>
        {displayConfig.collapsePosition === CollapseIconPosition.left && collapseIcon}
        <div className={s.nodeContent}>
          <StructureItemElement displayConfig={displayConfig} item={node.item} />
        </div>
        {displayConfig.collapsePosition === CollapseIconPosition.right && collapseIcon}
      </div>

      {displayConfig.progressPosition === ProgressIconPosition.right && (
        <SectionStatus disabled={disabled} displayConfig={displayConfig} node={node} open={open} />
      )}
      {displayConfig.collapsePosition === CollapseIconPosition.rightOutside && collapseIcon}
    </div>
  )
}

export default StructureNodeElement
