import React, { useCallback } from 'react'
import { videoFormat } from 'utils'

import { IconButton } from 'components/uiKit/Button'
import DropdownMenu from 'components/uiKit/DropdownMenu'
import { IMenuOption } from 'components/uiKit/Menu'
import { getEnumOption } from 'utils/enum'
import { testProps } from 'utils/test/qaData'

import { VideoPointActionUiEnum } from '../../types'
import s from './PointItem.module.scss'

interface IPointItemProps {
  index: number
  time: number
  title: string
  onDelete: (index: number) => void
  onEdit: (index: number) => void
}

const MENU_ACTIONS = [
  getEnumOption('VideoPointActionUiEnum', VideoPointActionUiEnum.EDIT),
  getEnumOption('VideoPointActionUiEnum', VideoPointActionUiEnum.REMOVE),
]

const PointItem: React.FC<IPointItemProps> = ({ index, time, title, onEdit, onDelete }) => {
  const handleEdit = useCallback(() => onEdit(index), [index, onEdit])
  const handleDelete = useCallback(() => onDelete(index), [index, onDelete])
  const onMenuAction = useCallback(
    ({ value }: IMenuOption<VideoPointActionUiEnum>) => {
      if (value === VideoPointActionUiEnum.EDIT) {
        handleEdit()
      }
      if (value === VideoPointActionUiEnum.REMOVE) {
        handleDelete()
      }
    },
    [handleDelete, handleEdit],
  )

  return (
    <div className={s.root}>
      <div className={s.content}>
        <div className={s.time} {...testProps({ el: 'timePoint', value: videoFormat(time) })}>
          {videoFormat(time)}
        </div>
        <div className={s.title} {...testProps({ el: 'titlePoint', value: title })}>
          {title}
        </div>
      </div>
      <DropdownMenu name='pointItemActions' onClick={onMenuAction} options={MENU_ACTIONS}>
        {({ open }) => (
          <IconButton active={open} icon='iconsOtherMore' name='openPointMenu' styleType='ghost' />
        )}
      </DropdownMenu>
    </div>
  )
}

export default PointItem
