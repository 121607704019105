import { LinkFontSchemaType, LinkSchemaType } from '@leenda/editor/lib/brand'
import { CONFIG_ELEMENTS, LinkElementValue } from '@leenda/editor/lib/elements'

import { DEMO_MODE } from 'services/Store/Project/constants'
import { t } from 'services/Translation'

import DemoWrapper from '../../decorators/DemoWrapper'
import { IElementDemoProps } from '../../elements.types'
import Element from './LinkElement'

const DEMO_VALUE: LinkElementValue = {
  linkLabel: t('elements.link.demo.label'),
  linkURL: undefined,
  showPreview: true,
  title: t('elements.link.placeholder.title'),
  description: t('elements.link.placeholder.description'),
  image: null,
}

const LinkDemo: React.FC<IElementDemoProps<LinkSchemaType, LinkFontSchemaType>> = ({
  styles,
  fonts,
}) => {
  return (
    <DemoWrapper>
      <Element
        element={{
          id: `${CONFIG_ELEMENTS.link.type}-demo`,
          type: CONFIG_ELEMENTS.link.type,
          value: DEMO_VALUE,
          style: {},
          font: {},
        }}
        font={fonts}
        mode={DEMO_MODE}
        styles={styles}
      />
    </DemoWrapper>
  )
}

export default LinkDemo
