import { FileImageUsage, FileUsage } from '@leenda/editor/lib/files'
import cn from 'classnames'
import lodash from 'lodash'
import numeral from 'numeral'
import { useCallback } from 'react'
import { FileTypeEnum } from 'utils'

import { IAsyncOnChange } from 'components/controls/Field/Field.types'
import CropModal from 'components/modals/CropModal/CropModal'
import { useFilePickerModal } from 'components/pages/FinderPage'
import { KitSize } from 'components/uiKit/KitTypes'
import { getFileMetaByIdFromCache } from 'gql/files.apollo'
import { t } from 'services/Translation'
import { getImageByUsage } from 'utils/files'

import Button, { IconButton } from '../Button'
import { Tooltip } from '../Dropdown'
import Input from '../Input'
import { useOpenModal } from '../Modal'
import s from './FileLoader.module.scss'
import AudioPreview from './components/AudioPreview'
import VideoPreview from './components/VideoPreview/VideoPreview'
import mock from './image.svg'

export type FileTypes = 'image' | 'audio' | 'video' | 'document'

type FieldFileValue = FileUsage | string | null

const isFileUsage = (value: FieldFileValue): value is FileUsage => {
  return (value as FileUsage)?.id !== undefined
}

const isImageUsage = (value: FieldFileValue, fileType: FileTypes): value is FileImageUsage => {
  return (
    ((value as FileUsage)?.id !== undefined && (value as FileUsage)?.type === 'image') ||
    fileType === 'image'
  )
}

type IFieldFile<V> = {
  value: V
  fileType: FileTypes
  onBlur: ((e?: unknown) => void) | undefined
  onFocus: ((e?: unknown) => void) | undefined
  name: string
  preview?: boolean
  nullable?: boolean
  placeholder?: string
  source: 'file' | 'url'
  onChange: IAsyncOnChange<V>
  disabled?: boolean
}

const FileLoader = <V extends FieldFileValue = FieldFileValue>({
  value,
  fileType,
  onBlur,
  onFocus,
  onChange,
  name,
  source,
  ...rest
}: IFieldFile<V>) => {
  const { nullable, placeholder, preview, disabled } = rest
  const file = getFileMetaByIdFromCache(isFileUsage(value) ? value?.id : undefined)
  const size = numeral(file?.size).format('0.0 b')
  const { name: fileName } = file || {}
  const fileUsage = getImageByUsage(file as any)
  const filePick = useFilePickerModal({
    defaultContentType: (fileType === 'document' ? undefined : fileType) as FileTypeEnum,
  })

  const video = fileType === 'video'
  const image = fileType === 'image'
  const audio = fileType === 'audio'
  const document = fileType === 'document'

  const { open } = useOpenModal(CropModal, { image: value as FileImageUsage })

  const pickFile = async () => {
    onFocus?.()
    const fileId = await filePick.open({})
    fileId && onChange?.({ value: { id: fileId }, name })
    onBlur?.()
  }

  const resetFile = (e: React.MouseEvent) => {
    e.stopPropagation()
    value && onChange?.({ value: null, name })
  }

  const openCropModal = async () => {
    const result = await open({})
    if (result && isImageUsage(value, fileType)) {
      const newValue = {
        id: value?.id,
        params: lodash.cloneDeep(lodash.omitBy(result, lodash.isUndefined)),
        accessibility: value?.accessibility,
      }
      onChange({ name, value: newValue })
    }
  }
  const onChangeUrl = useCallback((value: string) => onChange({ name, value }), [name, onChange])

  return (
    <div className={s.root}>
      {preview && !document && fileType && (
        <div className={cn(s.preview, { [s.full]: image || video })}>
          {image && <img src={(isFileUsage(value) ? fileUsage?.path : value) || mock} />}
          {video && <VideoPreview value={value} />}
          {audio && <AudioPreview value={value} />}
        </div>
      )}
      <div className={s.info}>
        {source === 'file' ? (
          <Tooltip overlay={fileName || undefined}>
            <div className={s.label}>
              {fileName || (image ? t('uiKit.fileLoader.title') : t('uiKit.button.addFile'))}
            </div>
          </Tooltip>
        ) : (
          <Input
            disabled={disabled}
            name={name}
            onChange={onChangeUrl}
            placeholder={placeholder}
            styleType='ghost'
            value={isFileUsage(value) ? undefined : value || undefined}
          />
        )}

        {source === 'file' ? (
          <>
            <div className={s.description}>{file ? size : t('uiKit.fileLoader.placeholder')}</div>
            <div className={s.footer}>
              <div className={s.buttons}>
                <Button
                  disabled={disabled}
                  name='replaceFile'
                  onClick={pickFile}
                  size={KitSize.S}
                  styleType='secondary'
                >
                  {file ? t('uiKit.button.replace') : t('uiKit.button.upload')}
                </Button>
                {file && image && nullable && (
                  <Button
                    disabled={disabled}
                    name='crop'
                    onClick={openCropModal}
                    size={KitSize.S}
                    styleType='secondary'
                  >
                    {t('uiKit.button.crop')}
                  </Button>
                )}
              </div>
              {file && nullable && (
                <IconButton
                  disabled={disabled}
                  icon='otherTrash'
                  name='deleteFile'
                  onClick={resetFile}
                  size={KitSize.S}
                  styleType='ghost'
                />
              )}
            </div>
          </>
        ) : (
          <div className={s.description}>{t('uiKit.fileLoader.urlHint')}</div>
        )}
      </div>
    </div>
  )
}
export default FileLoader
