import { DragDropContext, Draggable, DraggingStyle, Droppable, DropResult } from '@hello-pangea/dnd'
import { generateId } from '@leenda/editor/lib/utils/id'
import cn from 'classnames'
import React from 'react'

import Button from 'components/uiKit/Button'
import Collapse from 'components/uiKit/Collapse/'
import { t } from 'services/Translation'
import { DndPortal } from 'utils/dndPortal'

import { IControlProps, IFieldConfig, IOnChangeData } from '../../Field/Field.types'
import s from './FieldArray.module.scss'
import Item from './Item'
import { move } from './move'

export type ArrayValue = Record<string, unknown> & { value: string }

interface IFieldGroupProps {
  itemLabel?: string
  value: ArrayValue[]
  Parent: React.FC<{ config: IFieldConfig }>
  defaultValue: ArrayValue
  min?: number
  max?: number
  ordering?: boolean
  fields: IFieldConfig[]
  active?: string | null
  styleType?: 'padding' | 'bordered' | 'clear' | 'hovered' | 'collapse' | 'horizontal'
  onActive?: (id: string) => void
  onFocusItem?: (index: string | null) => void
  onClone?: (changeData: IOnChangeData) => ArrayValue[]
  onAdd?: (changeData: IOnChangeData, defaultValue?: ArrayValue) => ArrayValue[]
}

const FieldArray: React.FC<IControlProps<IFieldGroupProps>> = ({
  value = [],
  Parent,
  onChange,
  itemLabel,
  max,
  min,
  styleType = 'bordered',
  onAdd,
  onActive,
  name,
  defaultValue,
  ordering,
  active,
  fields,
  onClone,
  onFocusItem,
}) => {
  const newVal = value || defaultValue

  const handleAdd = () => {
    let newValue
    if (onAdd) {
      newValue = onAdd({ value, name }, defaultValue)
    } else {
      newValue = [
        ...(Array.isArray(newVal) ? newVal : []),
        { ...defaultValue, value: generateId() },
      ]
    }
    onChange({ value: newValue, name, meta: { command: 'add' } })
  }

  const handleMove = ({ destination, source }: DropResult) => {
    if (!destination || !value) {
      return
    }

    const newValue = move(Array.isArray(newVal) ? newVal : [], source.index, destination.index)
    onChange({ value: newValue, name })
  }

  if (ordering) {
    return (
      <div className={cn(s.root, s[styleType])}>
        <DragDropContext onDragEnd={handleMove}>
          <Droppable droppableId='droppable'>
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {(Array.isArray(newVal) ? newVal : []).map((item, index) => (
                  <Draggable draggableId={item.value} index={index} key={item.value}>
                    {(provided, state) => (
                      <DndPortal
                        element={
                          <Item
                            active={active}
                            dnd={provided}
                            fields={fields}
                            id={item.value}
                            index={index}
                            isDragging={state.isDragging}
                            label={itemLabel}
                            max={max}
                            min={min}
                            name={name}
                            onActive={onActive}
                            onChange={onChange}
                            onClone={onClone}
                            onFocus={onFocusItem}
                            Parent={Parent}
                            styleType={styleType}
                            value={newVal}
                          />
                        }
                        position={(provided.draggableProps.style as DraggingStyle)?.position}
                      />
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <div className={cn(s.addButton, s[styleType])}>
          <Button
            disabled={Boolean(max && (Array.isArray(newVal) ? newVal : []).length >= max)}
            name={`${name}.addItem`}
            onClick={handleAdd}
            styleType='secondary'
            fluid
          >
            {t('uiKit.button.add')}
          </Button>
        </div>
      </div>
    )
  }

  return (
    <div className={cn(s.root, s[styleType])}>
      {(Array.isArray(value) && styleType !== 'collapse' ? value : []).map((item, index) => (
        <Item
          active={active}
          fields={fields}
          id={item.value}
          index={index}
          key={item.value}
          label={itemLabel}
          max={max}
          min={min}
          name={name}
          onActive={onActive}
          onChange={onChange}
          onClone={onClone}
          onFocus={onFocusItem}
          Parent={Parent}
          styleType={styleType}
          value={value}
        />
      ))}
      {styleType === 'collapse' ? (
        <Collapse
          header={`${t('uiKit.collapse.answer')} ${(value as []).length}`}
          iconPosition='right'
          name={`${name}.answers`}
          paddings={{ title: '14px 0' }}
        >
          {(value as []).map((item: any, index) => (
            <Item
              active={active}
              fields={fields}
              id={item.value}
              index={index}
              key={item.value}
              label={itemLabel}
              max={max}
              min={min}
              name={name}
              onActive={onActive}
              onChange={onChange}
              onClone={onClone}
              onFocus={onFocusItem}
              Parent={Parent}
              styleType={styleType}
              value={value}
            />
          ))}
          <div className={cn(s.addButton, s[styleType])}>
            <Button
              disabled={Boolean(max && (Array.isArray(value) ? value : []).length >= max)}
              name={`${name}.addItem`}
              onClick={handleAdd}
              styleType='secondary'
              fluid
            >
              {t('uiKit.button.add')}
            </Button>
          </div>
        </Collapse>
      ) : (
        <Button
          name={`${name}.addItem`}
          onClick={handleAdd}
          styleType='secondary'
          type='button'
          fluid
        >
          {t('uiKit.button.add')}
        </Button>
      )}
    </div>
  )
}

export default FieldArray
