import cn from 'classnames'
import React from 'react'

import Employee, { AccountMock } from 'components/uiKit/Employee'
import Icon, { KeyIconsType } from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'

import s from './CanvasBubble.module.scss'

interface ICanvasBubbleProps {
  active?: boolean
  isNew?: boolean
  isResolved?: boolean
  onClick?: () => void
  icon?: KeyIconsType
  count?: number
  employee?: AccountMock | null
}

const CanvasBubble: React.FC<ICanvasBubbleProps> = ({
  count,
  icon,
  employee,
  active,
  isNew,
  isResolved,
  onClick,
}) => {
  return (
    <div className={cn(s.root, { [s.active]: active, [s.resolved]: isResolved })} onClick={onClick}>
      <div className={s.content}>
        {icon && <Icon name={icon} size={KitSize.S} />}
        {employee && <Employee employee={employee} styleType='avatar' />}
        {Boolean(count) && <div className={s.count}>{count}</div>}
        {isNew && <div className={s.newDot} />}
      </div>
    </div>
  )
}

export default CanvasBubble
