import Form from 'components/controls/Form'
import SubmitButton from 'components/controls/SubmitButton/SubmitButton'
import Button from 'components/uiKit/Button'
import Modal, { MCWithoutParams, ModalBody, ModalFooter, ModalHeader } from 'components/uiKit/Modal'
import { t } from 'services/Translation'

const ConfirmationModal: MCWithoutParams<boolean> = ({ onClose }) => {
  return (
    <Form layout='vertical' name='confirmation' onSubmit={() => onClose(true)}>
      <Modal maxWidth={560} name='confirmation' title={t('modal.confirmation.title')}>
        <ModalHeader
          description={t('modal.confirmation.description')}
          title={t('modal.confirmation.title')}
        />
        <ModalBody sizeAutoCapable>
          <div />
        </ModalBody>
        <ModalFooter>
          <Button name='cancel' onClick={() => onClose()} styleType='ghost'>
            {t('uiKit.button.cancel')}
          </Button>
          <SubmitButton label={t('uiKit.button.leave')} name='exit' />
        </ModalFooter>
      </Modal>
    </Form>
  )
}

export default ConfirmationModal
