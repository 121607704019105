import { SidebarSchemaType } from '@leenda/editor/lib/brand'
import { CourseStructureItem, ProgressIconPosition } from '@leenda/editor/lib/elements'
import lodash from 'lodash'
import React from 'react'

import { ISectionTreeNode } from 'components/sections/ProjectStructure/utils'
import Icon from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'
import Progress, { ProgressType } from 'components/uiKit/Progress'
import { ElementStyleCss } from 'services/Branding/types'
import { useProjectContext } from 'services/Store/Project/hooks'
import { getProgress } from 'services/Store/Project/selectors'
import { testPropsEl } from 'utils/test/qaData'

import s from './SectionStatus.module.scss'

const HEIGHT_STATUS_BAR_HEIGHT = 24

const getStyleLine = (
  styles: ElementStyleCss<SidebarSchemaType>,
  isFirst?: boolean,
  isLast?: boolean,
  open?: boolean,
  isChapter?: boolean,
  hasParent?: boolean,
) => {
  return {
    backgroundColor: styles?.progress.borderColor,
    bottom: -((styles?.margins.offsetYFromSection || 0) + HEIGHT_STATUS_BAR_HEIGHT),
    top: -(styles?.margins.offsetYFromSection || 0),
    width: styles?.progress.borderWidth,
    left: `calc(${HEIGHT_STATUS_BAR_HEIGHT / 2}px - (${styles?.progress.borderWidth || 0} / 2))`,
    ...(isFirst && { top: HEIGHT_STATUS_BAR_HEIGHT }),
    ...((isLast || (isLast && !open && isChapter)) && { bottom: HEIGHT_STATUS_BAR_HEIGHT }),
    ...(isLast &&
      open &&
      isChapter && {
        bottom: -((styles?.margins.offsetYFromSection || 0) + HEIGHT_STATUS_BAR_HEIGHT),
      }),
    ...(isFirst &&
      open &&
      hasParent && { top: -(styles?.margins.offsetYFromSection || 0) - HEIGHT_STATUS_BAR_HEIGHT }),
  }
}

interface ISectionStatusProps {
  node: ISectionTreeNode<CourseStructureItem>
  open?: boolean
  disabled: boolean
  styles: ElementStyleCss<SidebarSchemaType>
  hiddenProgress?: boolean
}

const SectionStatus: React.FC<ISectionStatusProps> = ({
  node,
  disabled,
  open,
  styles,
  hiddenProgress = false,
}) => {
  const { item, isLast, isFirst } = node
  const progress = useProjectContext(getProgress, item.id)
  const leftPosition = styles?.value.progressPosition === ProgressIconPosition.left
  const rightPosition = styles?.value.progressPosition === ProgressIconPosition.right
  const spaceFromProgress = styles?.margins.offsetFromProgress

  const progressStyle = lodash.pick(styles?.progress, [
    'borderStyle',
    'borderColor',
    'borderWidth',
    'backgroundColor',
  ])
  const rootStyles = {
    marginRight: leftPosition ? spaceFromProgress : undefined,
    marginLeft: rightPosition ? spaceFromProgress : undefined,
  }
  // TODO: need refactor
  const styleLine = getStyleLine(styles, isFirst, isLast, open, item.isChapter, item.lvl > 0)

  return (
    <div
      {...testPropsEl('sectionStatus', { value: progress, name: item.name }, { id: item.id })}
      className={s.root}
      style={rootStyles}
    >
      {!disabled && (
        <>
          {!hiddenProgress && (
            <div className={s.status} style={progressStyle}>
              <div className={s.progress}>
                {progress >= 100 ? (
                  <div className={s.statusDone} style={{ color: styles?.progress.color }}>
                    <Icon name='alertCheck' />
                  </div>
                ) : progress > 0 ? (
                  <Progress
                    customStyles={{
                      stroke: styles?.progress.color,
                      trail: styles?.progress.backgroundColor,
                    }}
                    name='sectionStatus'
                    percent={progress}
                    size='mini'
                    styleType={ProgressType.circle}
                  />
                ) : null}
              </div>
            </div>
          )}
          <div className={s.line} style={styleLine} />
        </>
      )}
      {disabled && (
        <>
          {!hiddenProgress && <Icon className={s.lockIcon} name='builderLock' size={KitSize.S} />}
          <div className={s.line} style={styleLine} />
        </>
      )}
    </div>
  )
}

export default SectionStatus
