import { useState, useEffect } from 'react'

export const useGetTestResultFeedback = (
  resultFeedbackCommon?: string,
  resultFeedbackSuccess?: string,
  resultFeedbackFail?: string,
  resultSuccessTitle?: string,
  resultFailTitle?: string,
  isFeedbackCommon?: boolean,
) => {
  const [title, setTitle] = useState(
    resultFeedbackCommon || resultFeedbackSuccess || resultFeedbackFail,
  )

  useEffect(() => {
    setTitle(resultFeedbackCommon)
  }, [resultFeedbackCommon])

  useEffect(() => {
    !isFeedbackCommon && setTitle(resultFeedbackSuccess)
    // eslint-disable-next-line
  }, [resultFeedbackSuccess, resultSuccessTitle])

  useEffect(() => {
    !isFeedbackCommon && setTitle(resultFeedbackFail)
    // eslint-disable-next-line
  }, [resultFeedbackFail, resultFailTitle])

  useEffect(() => {
    isFeedbackCommon && setTitle(resultFeedbackCommon)
    !isFeedbackCommon && setTitle(resultFeedbackSuccess)
    // eslint-disable-next-line
  }, [isFeedbackCommon])

  return title
}
