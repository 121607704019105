"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CommentRole = exports.TaskRole = exports.EmployeeStatusEnum = exports.PermissionGroup = exports.SystemRole = exports.CompanyRole = exports.ProjectRole = void 0;
var ProjectRole;
(function (ProjectRole) {
    ProjectRole["stack_holder"] = "stack_holder";
    ProjectRole["participant"] = "participant";
    ProjectRole["visitor"] = "visitor";
})(ProjectRole || (exports.ProjectRole = ProjectRole = {}));
var CompanyRole;
(function (CompanyRole) {
    CompanyRole["owner"] = "owner";
    CompanyRole["admin"] = "admin";
    CompanyRole["employee"] = "employee";
    CompanyRole["guest"] = "guest";
    CompanyRole["visitor"] = "visitor";
    CompanyRole["commentator"] = "commentator";
})(CompanyRole || (exports.CompanyRole = CompanyRole = {}));
var SystemRole;
(function (SystemRole) {
    SystemRole["system_admin"] = "system_admin";
    SystemRole["user"] = "user";
    SystemRole["visitor"] = "visitor";
})(SystemRole || (exports.SystemRole = SystemRole = {}));
var PermissionGroup;
(function (PermissionGroup) {
    PermissionGroup["company_manage"] = "company_manage";
    PermissionGroup["company_create"] = "company_create";
    PermissionGroup["company_style"] = "company_style";
    PermissionGroup["project_pro"] = "project_pro";
    PermissionGroup["project_content"] = "project_content";
    PermissionGroup["project_share"] = "project_share";
    PermissionGroup["project_view"] = "project_view";
    PermissionGroup["project_comment"] = "project_comment";
    PermissionGroup["project_manage"] = "project_manage";
})(PermissionGroup || (exports.PermissionGroup = PermissionGroup = {}));
var EmployeeStatusEnum;
(function (EmployeeStatusEnum) {
    EmployeeStatusEnum["INVITED"] = "INVITED";
    EmployeeStatusEnum["ACTIVE"] = "ACTIVE";
    EmployeeStatusEnum["ARCHIVED"] = "ARCHIVED";
    EmployeeStatusEnum["NOT_ACCEPTED"] = "NOT_ACCEPTED";
})(EmployeeStatusEnum || (exports.EmployeeStatusEnum = EmployeeStatusEnum = {}));
var TaskRole;
(function (TaskRole) {
    TaskRole["creator"] = "creator";
    TaskRole["assignee"] = "assignee";
})(TaskRole || (exports.TaskRole = TaskRole = {}));
var CommentRole;
(function (CommentRole) {
    CommentRole["creator"] = "creator";
    CommentRole["mentioned"] = "mentioned";
})(CommentRole || (exports.CommentRole = CommentRole = {}));
