import { EmbeddedElementValue } from '@leenda/editor/lib/elements'
import { FileUsageTypeEnum } from '@leenda/editor/lib/files'
import lodash from 'lodash'

import { SHELL_SIZE_KEYS } from 'components/editor-v3/types/data.constants'
import { useProjectT } from 'services/ProjectLabels'
import { PreviewMode } from 'services/Store/Project/enums'
import { t } from 'services/Translation'
import { getFileByUsage } from 'utils/files'

import { useElementRootStyles } from '../../hooks/useElementRootStyles'
import s from './EmbeddedElement.module.scss'
import { EmbeddedElementType } from './EmbeddedElement.types'

const prepareHtml = (html?: string) => {
  return `
   <style>
      body, html { margin: 0; padding: 0; height: 100%; }
   </style>
  ${html}`
}

const makeIframeIsolation = (url: string) => {
  const htmlString = `
<html>
  <head>
    <script>
      Object.defineProperty(window, 'parent', {
        get: function() {
            return window;
        }
      });
    </script>
    <style>
      body, html { margin: 0; padding: 0; height: 100%; }
      iframe { width: 100%; height: 100%; border: none; }
    </style>
  </head>
  <body>
    <iframe src="${url}" sandbox='allow-scripts allow-same-origin allow-forms' />
  </body>
</html>
`
  const blob = new Blob([htmlString], { type: 'text/html' })
  return URL.createObjectURL(blob)
}

const getInnerIframeData = (value: EmbeddedElementValue) => {
  const result: {
    props: { src?: string }
    editorMessage?: React.ReactNode
    onlyScorm: boolean
  } = { onlyScorm: false, props: {} }
  if (value.type === 'html') {
    const htmlString = prepareHtml(value.html)
    const blob = new Blob([htmlString], { type: 'text/html' })
    result.props.src = URL.createObjectURL(blob)
  } else if (value.type === 'link') {
    if (value.link) {
      if (!value.link.startsWith('http') && !value.link.startsWith('//')) {
        result.onlyScorm = true
        result.editorMessage = <span>!!!RELATIVE PATH: not supported!!!</span>
      } else {
        result.props.src = value.link
      }
    }
  } else if (value.type === 'zip') {
    if (value.zipFile?.id) {
      const fileData = getFileByUsage(value.zipFile, FileUsageTypeEnum.embeddedArchive)
      const folder = fileData?.path?.split('/').pop()
      const baseUrl =
        window.location.origin +
        window.location.pathname.replace(/index.html$/, '').replace(/\/$/, '')
      const path = `${baseUrl}/templates/content/${folder}/${value.zipPath || 'index.html'}`
      result.props.src = path
      result.editorMessage = (
        <div className={s.help}>
          <p>{t('elements.embedded.help')}</p>
          <p>Embedded work only on SCORM.</p>
        </div>
      )
    }
    result.onlyScorm = true
  }
  return result
}

const EmbeddedElement: EmbeddedElementType = ({ styles, element, mode }) => {
  const pt = useProjectT()

  const rootStyles = useElementRootStyles(styles.root)
  const isEditor = mode.previewMode === PreviewMode.editor
  const sizeStyles = lodash.pick(styles.shell, SHELL_SIZE_KEYS)

  const isScorm = mode.previewMode === PreviewMode.scorm
  const innerIframeData = getInnerIframeData(element.value)
  const showIframe = innerIframeData.onlyScorm ? isScorm : true
  const needIsolation = window.location.protocol !== 'file:'
  const iframeSrc = needIsolation
    ? makeIframeIsolation(innerIframeData.props.src || '')
    : innerIframeData.props.src

  return (
    <div className={s.root} style={{ ...rootStyles, ...sizeStyles }}>
      {!showIframe && <div>{innerIframeData.editorMessage}</div>}
      {showIframe && (
        <iframe
          className={s.iframe}
          sandbox='allow-scripts allow-same-origin allow-forms'
          src={iframeSrc}
          style={sizeStyles}
        >
          <p>{pt('elements.embedded.notSupported')}</p>
        </iframe>
      )}
      {isEditor && <div className={s.overlay} />}
    </div>
  )
}

export default EmbeddedElement
