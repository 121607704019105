import { LanguagesEnum } from '@leenda/editor/lib/elements/elements/Code/constants'
import { chainName } from 'utils'

import { genDataField } from 'components/controls/Field'
import RealtimeField from 'components/controls/RealtimeField'
import Padding from 'components/editor-v3/Editor/Toolbar/Padding'
import { AppMode } from 'services/Store/Project/enums'
import { Block } from 'services/Store/Project/types'
import { t } from 'services/Translation'

import { CodeValueFormType } from './CodeElement.types'

const genField = genDataField<Block>()

const theme = genField({
  name: 'theme',
  type: 'select',
  label: t('input.label.theme'),
  layout: 'horizontal',
  params: {
    options: [
      { label: t('input.option.light'), value: 'light' },
      { label: t('input.option.dark'), value: 'dark' },
    ],
    fluid: true,
  },
})

const lineNumbers = genField({
  name: 'lineNumbers',
  type: 'select',
  layout: 'horizontal',
  label: t('input.label.numbering'),
  params: {
    options: [
      { label: t('input.option.show'), value: true },
      { label: t('input.option.hide'), value: false },
    ],
    fluid: true,
  },
})

const language = genField({
  name: 'language',
  type: 'select',
  layout: 'horizontal',
  label: t('input.label.lang'),
  defaultValue: LanguagesEnum.html,
  params: {
    options: [
      { label: LanguagesEnum.html, value: LanguagesEnum.html },
      { label: LanguagesEnum.javascript, value: LanguagesEnum.javascript },
      { label: LanguagesEnum.css, value: LanguagesEnum.css },
      { label: LanguagesEnum.markdown, value: LanguagesEnum.markdown },
      { label: LanguagesEnum.php, value: LanguagesEnum.php },
      { label: LanguagesEnum.java, value: LanguagesEnum.java },
      { label: LanguagesEnum.python, value: LanguagesEnum.python },
      { label: LanguagesEnum['c/c++'], value: LanguagesEnum['c/c++'] },
      { label: LanguagesEnum.sql, value: LanguagesEnum.sql },
      { label: LanguagesEnum.node, value: LanguagesEnum.node },
    ],
    fluid: true,
  },
})

const CodeForm: CodeValueFormType = ({ name, mode }) => {
  const isPro = mode.editorMode === AppMode.pro
  return (
    <Padding sides>
      {isPro && <RealtimeField config={chainName(name, theme)} />}
      <RealtimeField config={chainName(name, lineNumbers)} />
      <RealtimeField config={chainName(name, language)} />
    </Padding>
  )
}

export default CodeForm
