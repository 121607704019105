type FileTypes = {
  doc: 'doc'
  docx: 'doc'
  csv: 'exl'
  exl: 'exl'
  exlx: 'exl'
  ppt: 'ppt'
  pptx: 'ppt'
  mov: 'mp4'
  mp3: 'mp3'
  mp4: 'mp4'
  '3gp': 'mp4'
  '3gpp': 'mp4'
  ogg: 'mp4'
  webm: 'mp4'
  webp: 'webp'
  pdf: 'pdf'
  txt: 'txt'
  jpg: 'jpg'
  png: 'png'
  jpeg: 'jpg'
  svg: 'svg'
  gif: 'gif'
  [key: string]: string
}

export const fileTypes: FileTypes = {
  doc: 'doc',
  docx: 'doc',
  csv: 'exl',
  exl: 'exl',
  exlx: 'exl',
  ppt: 'ppt',
  pptx: 'ppt',
  mov: 'mp4',
  mp3: 'mp3',
  mp4: 'mp4',
  '3gp': 'mp4',
  '3gpp': 'mp4',
  ogg: 'mp4',
  webm: 'mp4',
  webp: 'webp',
  pdf: 'pdf',
  txt: 'txt',
  jpg: 'jpg',
  png: 'png',
  jpeg: 'jpg',
  svg: 'svg',
  'svg+xml': 'svg',
  gif: 'gif',
}

export enum FileTypeEnum {
  IMAGE = 'image',
  AUDIO = 'audio',
  VIDEO = 'video',
  TEXT = 'text',
  FONT = 'font',
  TRANSLATE = 'translate',
  APPLICATION = 'application',
}
