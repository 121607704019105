import { useCallback, useEffect, useState } from 'react'
import { BaseSelection, Editor } from 'slate'
import { ReactEditor, useSlate, useSlateSelection } from 'slate-react'

export const useSelectionRect = () => {
  const editor = useSlate()
  const selection = useSlateSelection()
  const [focus, setFocus] = useState<DOMRect>()
  const [anchor, setAnchor] = useState<DOMRect>()

  const update = useCallback(() => {
    try {
      const focus =
        selection?.focus ||
        editor.selection?.focus ||
        (editor.pseudoSelection as BaseSelection)?.focus
      const focusRange = focus && Editor.range(editor, focus, focus)
      const focusDomRange = focusRange && ReactEditor.toDOMRange(editor, focusRange)
      const focusRect = focusDomRange?.getBoundingClientRect()

      setFocus(focusRect)
    } catch (error) {
      console.warn(error)
    }

    try {
      const anchor =
        selection?.anchor ||
        editor.selection?.anchor ||
        (editor.pseudoSelection as BaseSelection)?.anchor
      const anchorRange = anchor && Editor.range(editor, anchor, anchor)
      const anchorDomRange = anchorRange && ReactEditor.toDOMRange(editor, anchorRange)
      const anchorRect = anchorDomRange?.getBoundingClientRect()

      setAnchor(anchorRect)
    } catch (error) {
      console.warn(error)
    }
  }, [editor, selection])

  useEffect(() => {
    update()
    document.addEventListener('scroll', update, true)
    return () => {
      document.removeEventListener('scroll', update, true)
    }
  }, [update])

  return { focus, anchor }
}
