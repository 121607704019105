import { FORMAT_FIELDS, SlateFormats } from 'components/AppText/constants'
import {
  getFormat,
  getOnUpdateFormat,
  useAppTextSelector,
} from 'components/AppText/useAppTextSelector'
import Checkbox from 'components/uiKit/Checkbox'
import { Tooltip } from 'components/uiKit/Dropdown'
import Icon from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'
import { t } from 'services/Translation'

const CheckboxTool: React.FC<{ name: SlateFormats }> = ({ name }) => {
  const format = useAppTextSelector(getFormat)
  const onUpdateFormat = useAppTextSelector(getOnUpdateFormat)

  const iconName = FORMAT_FIELDS[name]?.icon
  return (
    <Tooltip overlay={t(`uiKit.tooltip.${name}`)} placement='top'>
      <Checkbox
        name={name}
        onChange={(value) => onUpdateFormat(name, value)}
        value={format[name]}
        hideCheckbox
      >
        {iconName && <Icon name={iconName} size={KitSize.S} />}
      </Checkbox>
    </Tooltip>
  )
}
export default CheckboxTool
