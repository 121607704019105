import { ChartElementValue } from '@leenda/editor/lib/elements'

import { genDataField } from 'components/controls/Field'
import { IOption, KitSize } from 'components/uiKit/KitTypes'
import { Block } from 'services/Store/Project/types'
import { t } from 'services/Translation'
import { getParent } from 'utils/form'

const genField = genDataField<Block>()

const label = genField({
  name: 'label',
  type: 'text',
  rules: [
    {
      max: 30,
    },
  ],
})

const val = genField({
  name: 'val',
  type: 'number',
  defaultValue: 0,
})

const color = (colorOptions: IOption<string>[]) =>
  genField({
    name: 'color',
    type: 'color',
    label: t('elements.chart.form.lineColor'),
    layout: 'horizontal',
    params: (data, config) => {
      const { parent } = getParent<ChartElementValue>(config.name, data, 2)
      return {
        options: colorOptions,
        hidden: parent?.type !== 'pie',
      }
    },
  })

export const items = (colorOptions: IOption<string>[]) =>
  genField({
    name: 'items',
    type: 'array',
    defaultValue: { label: 'Item', val: 0 },
    params: {
      ordering: true,
      itemLabel: t('input.label.point'),
      styleType: 'clear',
      max: 12,
      min: 2,
    },
    fields: [label, val, color(colorOptions)],
  })

export const type = genField({
  name: 'type',
  type: 'select',
  layout: 'horizontal',
  label: t('input.label.type'),
  params: {
    options: [
      { label: t('input.option.lineChart'), value: 'line' },
      { label: t('input.option.barChart'), value: 'bar' },
      { label: t('input.option.pieChart'), value: 'pie' },
    ],
    size: KitSize.S,
    fluid: true,
  },
})

export const description = genField({
  name: 'description',
  type: 'segmented',
  label: t('input.label.description'),
  layout: 'horizontal',
  params: (block, { name }) => {
    const { parent } = getParent<ChartElementValue>(name, block)
    return {
      hidden: parent?.type === 'pie',
    }
  },
})

export const x = genField({
  name: 'x',
  type: 'text',
  layout: 'horizontal',
  label: t('elements.chart.form.x'),
  params: (block, { name }) => {
    const { parent } = getParent<ChartElementValue>(name, block)

    return {
      hidden: !parent?.description || parent.type === 'pie',
      size: KitSize.S,
      placeholder: t('input.placeholder.xAxis'),
    }
  },
  rules: [
    {
      max: 30,
    },
  ],
})

export const y = genField({
  name: 'y',
  type: 'text',
  layout: 'horizontal',
  label: t('elements.chart.form.y'),
  params: (block, { name }) => {
    const { parent } = getParent<ChartElementValue>(name, block)

    return {
      hidden: !parent?.description || parent.type === 'pie',
      size: KitSize.S,
      placeholder: t('input.placeholder.yAxis'),
    }
  },
  rules: [
    {
      max: 30,
    },
  ],
})
