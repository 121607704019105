import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { AnyAction, Dispatch } from '@reduxjs/toolkit'
import { Middleware } from '@reduxjs/toolkit'
import * as patcher from 'jsondiffpatch'
import * as R from 'ramda'

import { INotificationProps, NotificationType } from 'components/uiKit/Notification/types'
import { BlockPatchInput } from 'gql/__generated__/graphql'
import { blockPatchMutation } from 'gql/blocks/gql/mutations'
import { blocksGetById } from 'gql/blocks/gql/queries'
import { ScrollContainerEnum } from 'services/Scroll/enums'

import { isAction } from '../../actionTypeGuard'
import {
  addUndoPatch,
  updateBlockVersion,
  resetBlock,
  scroll,
  setProjectNavigation,
  waitBlock,
} from '../../actions'
import { Block, IProjectContext } from '../../types'

export const DEBOUNCE = 500
export const MAX_WAIT = 5000

const KEYS = ['name', 'isHide', 'isDone', 'mode', 'test', 'elements', 'schema'] as const

const makePatch = (l: Block, r: Block) => patcher.diff(R.pick(KEYS, l), R.pick(KEYS, r))

const refetchBlock = ({ client, dispatch, notify }: IApi, uuid: string, sectionId: string) => {
  dispatch(waitBlock({ id: uuid, wait: true }))
  client
    .query({ query: blocksGetById, variables: { uuid, sectionId }, fetchPolicy: 'network-only' })
    .then(({ data }) => {
      data.data && dispatch(resetBlock({ block: data.data as Block, id: uuid }))
    })
    .catch(() => notify({ type: NotificationType.error, message: 'Error', duration: 2000 }))
    .finally(() => {
      dispatch(waitBlock({ id: uuid, wait: false }))
    })
}

const sendPatch = (api: IApi, { base, sectionId, uuid, delta }: ISendPatchVariables) => {
  const { client, notify } = api
  const patch: BlockPatchInput = { base, sectionId, uuid, patch: JSON.stringify(delta), meta: {} }

  return client
    .mutate({ mutation: blockPatchMutation, variables: { payload: patch } })
    .then(({ data }) => {
      if (data?.data?.message === 'fail') {
        notify({ type: NotificationType.warning, message: 'Fail', duration: 2000 })
        refetchBlock(api, patch.uuid, patch.sectionId)
      }

      if (data?.data?.message === 'collision') {
        notify({ type: NotificationType.info, message: 'Collision', duration: 2000 })
        refetchBlock(api, patch.uuid, patch.sectionId)
      }

      if (data?.data.version !== base + 1) {
        refetchBlock(api, uuid, sectionId)
      }
    })
    .catch(() => {
      notify({ type: NotificationType.error, message: 'Error', duration: 2000 })
      refetchBlock(api, uuid, sectionId)
    })
}

const debouncedPatching = (() => {
  let baseBlock: Block | null = null
  let baseBlockPatched: Block | null = null
  let syncSubscribed = false
  let syncPromise: Promise<unknown> | null = null
  const sync = (api: IApi) => {
    const { dispatch } = api
    if (baseBlock && baseBlockPatched && baseBlock.uuid === baseBlockPatched.uuid) {
      const sectionId = baseBlock.sectionId
      const uuid = baseBlock.uuid
      const base = baseBlock.version || 0
      const delta = makePatch(baseBlock, baseBlockPatched)

      if (delta) {
        if (syncPromise) {
          syncSubscribed = true
          return
        }
        dispatch(scroll({ container: ScrollContainerEnum.canvas, id: baseBlock.uuid }))
        dispatch(addUndoPatch({ blockId: uuid, patch: delta }))
        dispatch(updateBlockVersion({ blockId: uuid, version: base + 1 }))
        syncPromise = sendPatch(api, { sectionId, uuid, base, delta }).then(() => {
          syncPromise = null
          if (syncSubscribed) {
            syncSubscribed = false
            sync(api)
          }
        })
      }
    }
    baseBlock = null
    baseBlockPatched = null
  }

  // const debouncedSync = lodash.debounce(sync, DEBOUNCE, { leading: true, maxWait: MAX_WAIT })

  return (api: IApi, payload: IDebouncedPayload) => {
    const { prevBlock, nextBlock, id } = payload
    if (!baseBlock) {
      baseBlock = prevBlock
      baseBlockPatched = nextBlock
    }
    if (baseBlockPatched && baseBlock.uuid !== id) {
      sync(api)
      baseBlock = prevBlock
      baseBlockPatched = nextBlock
    } else {
      baseBlockPatched = nextBlock
    }
    sync(api)
  }
})()

interface IMiddlewareArguments {
  client: ApolloClient<NormalizedCacheObject>
  notify: (props: INotificationProps) => void
}
export const blockPatch =
  ({ client, notify }: IMiddlewareArguments): Middleware =>
  ({ dispatch, ...store }) =>
  (next) =>
  (action) => {
    const api = { client, notify, dispatch }
    const prevState: IProjectContext = store.getState().project
    const result = next(action)
    const nextState: IProjectContext = store.getState().project

    if (isAction(action, 'updateBlock')) {
      const prevBlock = prevState.data.blocks[action.payload.id]
      const nextBlock = nextState.data.blocks[action.payload.id]
      debouncedPatching(api, { prevBlock, nextBlock, id: action.payload.id })
    }

    if (isAction(action, 'undoBlock')) {
      const lastPatch = R.last(prevState.state.editor.undoPatches)
      if (lastPatch) {
        const sectionId = nextState.urlParams.sectionId
        const uuid = lastPatch.blockId
        const base = nextState.data.blocks[lastPatch.blockId].version || 0
        const delta = patcher.reverse(lastPatch.patch)
        if (delta) {
          dispatch(
            setProjectNavigation({
              blockId: uuid,
              scroll: {
                container: ScrollContainerEnum.canvas,
                id: uuid,
                block: 'center',
                scroll: 'ifneeded',
                intersection: true,
              },
            }),
          )
          dispatch(updateBlockVersion({ blockId: uuid, version: base + 1 }))
          sendPatch(api, { sectionId, uuid, base, delta })
        }
      }
    }

    if (isAction(action, 'redoBlock')) {
      const lastPatch = R.last(prevState.state.editor.redoPatches)
      if (lastPatch) {
        const sectionId = prevState.urlParams.sectionId
        const uuid = lastPatch.blockId
        const base = nextState.data.blocks[lastPatch.blockId].version || 0
        const delta = lastPatch.patch
        dispatch(
          setProjectNavigation({
            blockId: uuid,
            scroll: {
              container: ScrollContainerEnum.canvas,
              id: uuid,
              block: 'center',
              scroll: 'ifneeded',
              intersection: true,
            },
          }),
        )
        dispatch(updateBlockVersion({ blockId: uuid, version: base + 1 }))
        sendPatch(api, { sectionId, uuid, base, delta })
      }
    }
    return result
  }

interface IApi {
  client: ApolloClient<NormalizedCacheObject>
  notify: (props: INotificationProps) => void
  dispatch: Dispatch<AnyAction>
}

interface IDebouncedPayload {
  prevBlock: Block
  nextBlock: Block
  id: string
}

interface ISendPatchVariables {
  sectionId: string
  uuid: string
  base: number
  delta: patcher.Delta
}
