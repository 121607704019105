"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const constants_1 = require("../../../elements/elements/TestKeyboardInput/constants");
const font_1 = require("../../font");
const theme_1 = require("../../theme");
const genStyle_1 = require("../genStyle");
const styleSchema = {
    input: {
        borderColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.borderColor }),
        borderWidth: (0, genStyle_1.genStyle)({ value: 1 }),
        padding: (0, genStyle_1.genStyle)({ value: [16, 24, 16, 24] }),
    },
    placeholder__var: {
        color: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.secondaryTextColor }),
    },
};
const fontSchema = {
    base: Object.assign({}, font_1.FONT_SCHEMA_EMPTY.base),
};
exports.default = { [constants_1.TYPE]: { style: styleSchema, font: fontSchema } };
