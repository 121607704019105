import { TestFeedbackType, TestFeedbackElementValue } from '@leenda/editor/lib/elements'
import { chainName, getParent } from 'utils'

import { genDataField } from 'components/controls/Field'
import RealtimeField from 'components/controls/RealtimeField'
import Padding from 'components/editor-v3/Editor/Toolbar/Padding'
import { Typography } from 'components/uiKit/Typography'
import { DEFAULT_BOTTOM_INDENT } from 'components/uiKit/Typography/constants'
import { Block } from 'services/Store/Project/types'
import { t } from 'services/Translation'
import { getEnumOption } from 'utils/enum'

import { TestFeedbackValueFormType } from './TestFeedbackTypes'

const genField = genDataField<Block>()

const show = genField({
  name: 'show',
  label: t('elements.testFeedback.form.feedback'),
  type: 'segmented',
  layout: 'horizontal',
  defaultValue: false,
})

const type = genField({
  name: 'type',
  label: t('input.label.type'),
  type: 'select',
  layout: 'horizontal',
  params: (block, config) => {
    const { parent } = getParent<TestFeedbackElementValue>(config.name, block)
    return {
      placeholder: t('input.placeholder.chooseType'),
      options: [
        getEnumOption('TestFeedbackType', TestFeedbackType.validation),
        getEnumOption('TestFeedbackType', TestFeedbackType.info),
      ],
      hidden: !parent?.show,
      fluid: true,
    }
  },
})

const TestFeedbackForm: TestFeedbackValueFormType = ({ element, name }) => {
  const hintText =
    element.value.type === 'info'
      ? t('elements.testFeedback.form.infoHint')
      : t('elements.testFeedback.form.validationHint')

  return (
    <Padding sides>
      <RealtimeField config={chainName(name, show)} />
      <RealtimeField config={chainName(name, type)} />
      <Typography indents={DEFAULT_BOTTOM_INDENT} styleType='hint'>
        {hintText}
      </Typography>
    </Padding>
  )
}

export default TestFeedbackForm
