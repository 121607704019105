import { createSelector } from '@reduxjs/toolkit'
import * as R from 'ramda'

import { PassOrderEnum } from 'gql/__generated__/graphql'
import { PreviewMode, SectionTypeEnum } from 'services/Store/Project/enums'
import { IProjectContext, ISectionState, MapType, Section } from 'services/Store/Project/types'
import { notEmpty } from 'utils/notEmpty'

import { getProgress } from '../state'
import { getSectionsState, getSectionState } from '../state/section'
import { getSectionId } from '../url'
import { getProject } from './project'

const getSectionsMap = createSelector(
  (state: IProjectContext) => state.mode,
  (state: IProjectContext) => state.data.sections,
  getSectionsState,
  (mode, sections, sectionState) =>
    mode === PreviewMode.editor
      ? sections
      : R.map(modifySectionByState(sections, sectionState), sections),
)

export const getSections = createSelector(
  getSectionsMap,
  (state: IProjectContext) => state.data.project.sectionsOrder,
  (_: IProjectContext, filter?: SectionTypeEnum[]) => filter,
  (map, order = [], filter) =>
    filter
      ? order.map(({ id }) => map[id]).filter((section) => section && filter.includes(section.type))
      : order.map(({ id }) => map[id]).filter(notEmpty),
)

interface IGetSectionParams {
  id?: string | null
  first?: true
  next?: true
  prev?: true
  cover?: true
}

export const getSection = createSelector(
  (state: IProjectContext) => state,
  getSectionId,
  getSectionsMap,
  (_: IProjectContext, params?: IGetSectionParams) => params,
  (state, sectionId, map, params) => {
    if (!params) {
      return map[sectionId] || null
    }

    const { id, cover, first, next, prev } = params
    if (id) {
      return map[id] || null
    }
    if (cover) {
      return getSections(state, [SectionTypeEnum.cover])[0] || null
    }

    if (first) {
      const sections = getSections(state, [SectionTypeEnum.landing, SectionTypeEnum.test])
      return R.head(sections) || null
    }
    const sections = getSections(state, [
      SectionTypeEnum.cover,
      SectionTypeEnum.landing,
      SectionTypeEnum.test,
    ])
    const index = sectionId ? R.findIndex(({ id }) => id === sectionId, sections) : 0
    if (next && index >= 0) {
      return sections[R.inc(index)] || null
    }
    if (prev && index >= 0) {
      return sections[R.dec(index)] || null
    }
    return null
  },
)

export const getDisabledSections = (state: IProjectContext): Record<string, boolean> => {
  const project = getProject(state)
  const sections = getSections(state)
  const result: Record<string, boolean> = {}
  const isEditor = state.mode === PreviewMode.editor

  if (project?.passOrder !== PassOrderEnum.linear || isEditor) {
    return sections.reduce((acc, { id }) => ({ ...acc, [id]: false }), {})
  }

  const [chapters, notChapters] = R.partition(
    ({ type }) => type === SectionTypeEnum.chapter,
    sections,
  )

  chapters.forEach(({ id }) => {
    result[id] = false
  })
  notChapters.forEach(({ id }) => {
    result[id] = true
  })

  const nonChapterId = (i: number) => notChapters[i]?.id

  for (let i = 0; i < notChapters.length; i++) {
    const section = notChapters[i]

    if (section.type === SectionTypeEnum.cover) {
      result[nonChapterId(i)] = false
      result[nonChapterId(i + 1)] = false
    } else {
      const sectionProgress = getProgress(state, section.id)
      const sectionState = getSectionState(state, { id: section.id })

      if (sectionState?.passed) {
        result[nonChapterId(i)] = false
        result[nonChapterId(i + 1)] = false
      }

      if (
        section.type === SectionTypeEnum.landing &&
        sectionProgress >= (project?.passPercentage || 100)
      ) {
        result[nonChapterId(i + 1)] = false
      }

      if (sectionProgress) {
        result[nonChapterId(i)] = false
      }
    }
    if (i === 0) {
      result[nonChapterId(i)] = false
    }
  }
  return result
}

export const isSectionDisabled = (state: IProjectContext, id: string) => {
  const disabledSections = getDisabledSections(state)
  return disabledSections[id]
}

const modifySectionByState =
  (a: MapType<Section>, b: MapType<ISectionState>) =>
  ({ id }: Section): Section => {
    const blocksOrder = b[id]?.order || a[id]?.blocksOrder || []
    const blocks = R.innerJoin(({ uuid }, id) => uuid === id, a[id].blocks, blocksOrder)
    return R.mergeRight(a[id], { blocks, blocksOrder })
  }
