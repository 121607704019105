import { gql } from './__generated__'

export const TemplateCollectionsFragment = gql(`
  fragment TemplateCollectionsSchema on TemplateCollection {
    id
    name
    brandId
    color
    createdFor
  }
`)

export const TemplateCollectionsCountFragment = gql(`
  fragment TemplateCollectionsSchemaCount on TemplateCollection {
    id
    name
    brandId
    color
    createdFor
    templatesCount(mode: $mode)
  }
`)

export const templatesListFragment = gql(`
  fragment TemplatesListSchema on EditorTemplate {
    employeeId
    projectId
    companyId
    uuid
    name
    test {
      time
      successRules
      onSuccess {
        goToBlockId
      }
      onFail {
        goToBlockId
        retry
      }
      isResult
      repeat
      limit
      attempts
      answerRequired
    }
    elements
    schema
    files {
      ...FileMetaSchema
    }
    mode
    options {
      backgroundColor
      backgroundImage
      indents
      tabs
      tabsIndex
      tabsPosition
      borderRadius
      questionFeedback
    }
    createdAt
    updatedAt
    tags
    isFavorite
    editorTemplateGroupId
  }
`)

export const templatesGetAllQuery = gql(`
  query TemplatesAll($companyId: String!, $data: EditorTemplateAllInput!) {
    data: editorTemplatesAll(companyId: $companyId, data: $data) {
      templates {
        ...TemplatesListSchema
      }
      pagination {
        limit
        offset
        total
      }
    }
  }
`)

export const templatesCreateFromBlock = gql(`
  mutation TemplatesCreateFromBlock($data: EditorTemplateCreateInput!) {
    data: editorTemplateCreateFromBlock(data: $data) {
      data {
        ...TemplatesListSchema
      }
      errors {
        ...ErrorSchema
      }
    }
  }
`)

export const templateCopy = gql(`
  mutation TemplateCopy($payload: EditorTemplateMultiCopyInput!) {
    data: editorTemplatesMultiCopy(payload: $payload) {
      ...TemplatesListSchema
    }
  }
`)

export const templatesSave = gql(`
  mutation TemplatesSave($companyId: String!, $data: EditorTemplateInput!) {
    data: editorTemplatesUpdateById(companyId: $companyId, data: $data) {
      data {
        ...TemplatesListSchema
      }
      errors {
        ...ErrorSchema
      }
    }
  }
`)

export const templatesDeleteByIds = gql(`
  mutation TemplatesDeleteByIds($companyId: String!, $uuid: [String!]!) {
    data: editorTemplatesDeleteByIds(companyId: $companyId, uuid: $uuid)
  }
`)

export const templatesAddToFavs = gql(`
  mutation TemplatesAddToFavs($companyId: String!, $projectId: String, $uuids: [String!]!) {
    data: editorTemplatesFavoritesAdd(companyId: $companyId, uuids: $uuids, projectId: $projectId) {
      uuid
      isFavorite
    }
  }
`)

export const templatesRemoveFromFavs = gql(`
  mutation templatesRemoveFromFavs($companyId: String!, $uuids: [String!]!) {
    data: editorTemplatesFavoritesRemove(companyId: $companyId, uuids: $uuids) {
      uuid
      isFavorite
    }
  }
`)

export const templatesGetGroupAll = gql(`
  query EditorTemplateCollections(
    $companyId: ID!
    $data: TemplateCollectionSearch!
    $mode: [String!]
  ) {
    data: templateCollections(companyId: $companyId, search: $data) {
      templateCollections {
        ...TemplateCollectionsSchemaCount
      }
    }
  }
`)

export const templatesGetTemplatesAndCollections = gql(`
  query EditorTemplatesAndCollections(
    $companyId: ID!
    $data: TemplatesAndCollectionsSearch!
    $mode: [String!]
  ) {
    data: templatesAndCollections(companyId: $companyId, search: $data) {
      templateCollections {
        ...TemplateCollectionsSchemaCount
      }
      templates {
        ...TemplatesListSchema
      }
    }
  }
`)

export const templateGroupCreate = gql(`
  mutation EditorTemplateCollectionCreate(
    $companyId: ID!
    $payload: TemplateCollectionCreateInput!
  ) {
    data: templateCollectionCreate(companyId: $companyId, payload: $payload) {
      data {
        ...TemplateCollectionsSchema
      }
      errors {
        ...ErrorSchema
      }
    }
  }
`)

export const templateGroupDuplicate = gql(`
  mutation EditorTemplateCollectionDuplicate($companyId: ID!, $id: ID!) {
    data: templateCollectionDuplicate(companyId: $companyId, id: $id) {
      id
      name
    }
  }
`)

export const templateGroupUpdate = gql(`
  mutation EditorTemplateCollectionUpdate($id: ID!, $payload: TemplateCollectionUpdateInput!) {
    data: templateCollectionUpdate(id: $id, payload: $payload) {
      data {
        ...TemplateCollectionsSchema
      }
      errors {
        ...ErrorSchema
      }
    }
  }
`)

export const templateGroupDelete = gql(`
  mutation EditorTemplateCollectionArchive($id: ID!) {
    data: templateCollectionArchive(id: $id)
  }
`)

export const editorTemplatesAllTotal = gql(`
  query EditorTemplatesAllTotal($search: EditorTemplatesAllTotalInput!) {
    data: editorTemplatesAllTotal(search: $search) {
      favorites
      archived
    }
  }
`)
