import React, { useCallback } from 'react'

import RichText, { IAppTextProps } from 'components/AppText'
import { IControlProps } from 'components/controls/Field/Field.types'

const FieldRichText: React.FC<IControlProps<IAppTextProps>> = ({ onChange, name, ...rest }) => {
  const handleOnChange = useCallback(
    (value: IAppTextProps['value']) => onChange({ value, name }),
    [onChange, name],
  )

  return <RichText {...rest} name={name} onChange={handleOnChange} />
}

export default FieldRichText
