import { EditorElement } from '@leenda/editor/lib/elements'

import { ELEMENTS_CONFIG } from 'components/editor-v2/EditorElements/elements.config'
import { ShellNode } from 'components/editor-v3/types/data.types'
import { Block } from 'services/Store/Project/types'
import { t } from 'services/Translation'

import { getElement, getNode } from './block'

export const getElementName = (element: EditorElement) =>
  ELEMENTS_CONFIG[element?.type]?.label || t('common.empty')

export const getShellName = (block: Pick<Block, 'schema' | 'elements'>, id: string) => {
  const shell = getNode(block, id) as ShellNode
  return shell?.name || getElementName(getElement(block, shell?.elementId || ''))
}
