import { ImageSchemaType } from '@leenda/editor/lib/brand'
import { CONFIG_ELEMENTS, ImageElementValue } from '@leenda/editor/lib/elements'

import { DEMO_MODE } from 'services/Store/Project/constants'

import DemoWrapper from '../../decorators/DemoWrapper'
import { DEPRECATED_Position, IElementDemoProps } from '../../elements.types'
import Element from './ImageElView'
import s from './ImageElView.module.scss'

const DEMO_VALUE: ImageElementValue = {
  sourceType: 'url',
  image: null,
}

const DEMO_POSITION: DEPRECATED_Position = {
  width: 800,
  height: 600,
}

const ImageDemo: React.FC<IElementDemoProps<ImageSchemaType>> = ({ styles }) => {
  return (
    <DemoWrapper position={DEMO_POSITION} center>
      <div className={s.demo} style={styles.image}>
        <Element
          element={{
            id: `${CONFIG_ELEMENTS.image.type}-demo`,
            type: CONFIG_ELEMENTS.image.type,
            value: DEMO_VALUE,
            style: {},
            font: {},
          }}
          mode={DEMO_MODE}
          //@ts-ignore
          styles={{}}
        />
      </div>
    </DemoWrapper>
  )
}

export default ImageDemo
