import { PermissionsKey } from '@leenda/crud'
import { t } from 'i18next'
import { useCallback, useMemo, useState } from 'react'
import { matchPath, useHistory } from 'react-router-dom'
import { FileTypeEnum } from 'utils'

import { ITabPanelProps } from 'components/uiKit/Tabs/TabPanel/TabPanel'
import { EDITOR_PATHS } from 'constants/paths'
import { EmployeePreferencesFinderTab, FileMetaFor } from 'gql/__generated__/graphql'
import { usePermissions } from 'services/Permission/PermissionContext'
import { setEmployeeToProjectPreferences } from 'services/Store/Project/actions'
import { useProjectContext } from 'services/Store/Project/hooks'
import { selectEmployeePreferences } from 'services/Store/Project/selectors'
import { useAppDispatch } from 'services/Store/hooks'

import { FinderTabsEnum } from './types'

const isEmployeePreferencesFinderTab = (
  tab: EmployeePreferencesFinderTab | FinderTabsEnum,
): tab is EmployeePreferencesFinderTab => tab in EmployeePreferencesFinderTab

interface IUseGetTabsOptions {
  projectId?: string
  contentType?: FileTypeEnum
  showExternalImages?: boolean | null
  tabs?: FinderTabsEnum[]
}

const COMPANY_TABS = [EmployeePreferencesFinderTab.companies, FinderTabsEnum.unsplash]
const PROJECT_TABS = [EmployeePreferencesFinderTab.projects, ...COMPANY_TABS]

const getTabConfig = (finderHidden: boolean, externalImageHidden: boolean) => ({
  [EmployeePreferencesFinderTab.companies]: {
    tabKey: EmployeePreferencesFinderTab.companies,
    title: t('page.finder.tabs.company'),
    value: FileMetaFor.companies,
    hidden: finderHidden,
  },
  [EmployeePreferencesFinderTab.projects]: {
    tabKey: EmployeePreferencesFinderTab.projects,
    title: t('page.finder.tabs.project'),
    value: FileMetaFor.projects,
    hidden: finderHidden,
  },
  [FinderTabsEnum.unsplash]: {
    tabKey: FinderTabsEnum.unsplash,
    title: t('page.finder.tabs.unsplash'),
    value: FinderTabsEnum.unsplash,
    hidden: externalImageHidden,
  },
})

export const useGetTabsOptions = ({
  projectId,
  contentType,
  showExternalImages,
  tabs,
}: IUseGetTabsOptions) => {
  const permissions = usePermissions()

  const dispatch = useAppDispatch()
  const history = useHistory()
  const isEditor = Boolean(matchPath(history.location.pathname, EDITOR_PATHS.main))
  const employeePreferences = useProjectContext(selectEmployeePreferences)
  const defaultTabs = tabs || (projectId ? PROJECT_TABS : COMPANY_TABS)
  const hiddenFinderTabs = !permissions.has(
    projectId ? PermissionsKey.project_resource_c : PermissionsKey.company_resource_c,
  )
  const hiddenImageTabs =
    hiddenFinderTabs || contentType !== FileTypeEnum.IMAGE || !showExternalImages
  const tabsConfig = getTabConfig(hiddenFinderTabs, hiddenImageTabs)
  const tabsOptions: ITabPanelProps<EmployeePreferencesFinderTab | FinderTabsEnum>[] = useMemo(
    () => defaultTabs.map((tab) => tabsConfig[tab]).filter(Boolean),
    [defaultTabs, tabsConfig],
  )
  const activeTab = employeePreferences?.finder?.tab || tabsOptions[0].tabKey
  const [stateFolder, setStateFolder] = useState('root')
  const preferencesFolder = employeePreferences?.finder?.folder || 'root'

  const setActiveTab = useCallback(
    (tab: EmployeePreferencesFinderTab | FinderTabsEnum) => {
      if (isEditor && isEmployeePreferencesFinderTab(tab)) {
        dispatch(setEmployeeToProjectPreferences({ finder: { tab, folder: 'root' } }))
      }
    },
    [dispatch, isEditor],
  )

  const setActiveFolder = useCallback(
    (folder: string) => dispatch(setEmployeeToProjectPreferences({ finder: { folder } })),
    [dispatch],
  )

  return {
    tabsOptions,
    activeTab,
    setActiveTab,
    activeFolder: isEditor ? preferencesFolder : stateFolder,
    setActiveFolder: isEditor ? setActiveFolder : setStateFolder,
  }
}
