import { MotionProps } from 'framer-motion'

export const getCanvasCommentName = (blockId?: string, elementId?: string | null) =>
  ['comment', blockId, elementId].filter(Boolean).join('|')

export const CANVAS_COMMENT_ANIMATION_IN = { opacity: 1, scale: 1 }
export const CANVAS_COMMENT_ANIMATION_OUT = { opacity: 0, scale: 0 }
export const CANVAS_COMMENT_WHILE_HOVER: MotionProps['whileHover'] = {
  scale: 1.1,
}
