import { gql } from 'gql/__generated__'

export const blocksMultiCreate = gql(`
  mutation BlocksMultiCreate($payload: BlockMultiCreateInput!) {
    data: editorBlocksMultiCreate(payload: $payload) {
      blocks {
        ...BlockSchema
      }
      blocksOrder
    }
  }
`)

export const blockPatchMutation = gql(`
  mutation BlocksPatchById($payload: BlockPatchInput!) {
    data: editorBlockPatchById(payload: $payload) {
      message
      version
    }
  }
`)

export const blocksDeleteByIds = gql(`
  mutation BlocksDeleteByIds($uuid: [String!]!) {
    editorBlocksDeleteByIds(uuid: $uuid) {
      uuid
      blocksOrder
    }
  }
`)

export const blocksCopy = gql(`
  mutation BlocksCopy($payload: BlockMultiCreateInput!) {
    data: editorBlocksMultiCopy(payload: $payload) {
      blocks {
        ...BlockSchema
      }
      blocksOrder
    }
  }
`)

export const blocksRevert = gql(`
  mutation EditorBlocksRevert($companyId: ID!, $payload: BlockMultiCreateInput!) {
    data: editorBlocksRevert(companyId: $companyId, payload: $payload) {
      blocks {
        ...BlockSchema
      }
      blocksOrder
    }
  }
`)

export const blockApplyVersion = gql(`
  mutation EditorBlockApplyVersion($payload: BlockVersionApplyInput!) {
    data: editorBlockApplyVersion(payload: $payload) {
      message
      version
    }
  }
`)
