import React, { useCallback, useEffect, useRef } from 'react'

import {
  getSelectedFormat,
  getSetToolbarForm,
  getUpdateFormat,
  useRichTextContext,
} from 'components/form/RichText/useRichTextContext'
import { IconButton } from 'components/uiKit/Button'
import Input from 'components/uiKit/Input'
import { KitSize } from 'components/uiKit/KitTypes'
import { validateLinkUrl } from 'utils/websiteValidation'

import { useToolbarPosition } from '../../useToolbarPosition'
import s from './LinkForm.module.scss'

const LinkForm: React.FC = () => {
  const ref = useRef<HTMLDivElement>(null)
  const selectedFormat = useRichTextContext(getSelectedFormat)
  const [value, setValue] = React.useState<string>(selectedFormat?.inline?.url || '')
  const onUpdateFormat = useRichTextContext(getUpdateFormat)
  const setToolbarForm = useRichTextContext(getSetToolbarForm)
  const handleClose = useCallback(() => setToolbarForm('default'), [setToolbarForm])

  const isValid = validateLinkUrl(value)
  const isEditing = Boolean(selectedFormat?.inline?.url)

  const onSave = () => {
    onUpdateFormat('link', value)
    handleClose()
  }
  const onDelete = () => {
    onUpdateFormat('link', null)
    handleClose()
  }
  useEffect(() => {
    setValue(selectedFormat?.inline?.url || '')
  }, [selectedFormat?.inline?.url])

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.stopPropagation()
      e.preventDefault()
      onSave()
    }
    if (e.key === 'Escape') {
      e.stopPropagation()
      e.preventDefault()
      handleClose()
    }
  }

  useToolbarPosition(ref)

  return (
    <div className={s.root} onKeyDown={onKeyDown} ref={ref}>
      <div className={s.input}>
        <Input
          autoFocus={!value}
          error={!isValid}
          name='richTextLink'
          onChange={setValue}
          styleType='ghost'
          value={value}
        />
      </div>
      <div className={s.controls}>
        {isEditing && (
          <>
            <IconButton
              icon='otherBrokenLink'
              name='delete'
              onClick={onDelete}
              size={KitSize.S}
              styleType='ghost'
            />
            <IconButton
              icon='checkmark1'
              name='update'
              onClick={onSave}
              size={KitSize.S}
              styleType='ghost'
            />
          </>
        )}
        {!isEditing && value && (
          <IconButton
            icon='checkmark1'
            name='create'
            onClick={onSave}
            size={KitSize.S}
            styleType='ghost'
          />
        )}
        {!isEditing && !value && (
          <IconButton
            icon='otherClose'
            name='cancel'
            onClick={handleClose}
            size={KitSize.S}
            styleType='ghost'
          />
        )}
      </div>
    </div>
  )
}

export default LinkForm
