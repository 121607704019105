import { useCallback, useMemo, useRef, useState } from 'react'
import { ColorWrapChangeHandler } from 'react-color/lib/components/common/ColorWrap'

import Button, { IconButton } from 'components/uiKit/Button'
import Divider from 'components/uiKit/Divider'
import Dropdown, { Tooltip } from 'components/uiKit/Dropdown'
import Icon, { KeyIconsType } from 'components/uiKit/Icon'
import { IKitControl, KitDimensions, KitSize } from 'components/uiKit/KitTypes'
import { flatOptions } from 'components/uiKit/KitUtils'
import Menu from 'components/uiKit/Menu'
import { IMenuOption } from 'components/uiKit/Menu/MenuItem'
import Tabs, { TabPanel } from 'components/uiKit/Tabs'
import { t } from 'services/Translation'

import { IPlacement } from '../Dropdown/types'
import { IColorOption } from './ColorPicker.types'
import ColorRoot from './ColorRoot'
import ReactColorPicker from './ReactColorPicker'
import { isColorResult, isRGB, toColor } from './helpers'

const MENU_DIMENSIONS: KitDimensions = { width: 260, height: 282 }

export interface IColorPickerProps extends IKitControl<string> {
  options?: IColorOption[]
  transparent?: boolean
  icon?: KeyIconsType
  offset?: [number, number]
  placement?: IPlacement
  labeled?: boolean
}

const ColorPicker: React.FC<IColorPickerProps> = ({
  name,
  onChange,
  value,
  onBlur,
  onFocus,
  options,
  size,
  icon,
  offset,
  transparent,
  labeled,
  placement = 'topRight',
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const flattenOptions = useMemo(() => flatOptions(options), [options])
  const activeOption = flattenOptions?.find((item) => item.value === value)
  const activeTab = useMemo(
    () =>
      activeOption || (value === 'transparent' && flattenOptions?.length) ? 'options' : 'color',
    [activeOption, flattenOptions.length, value],
  )

  const [visible, setVisible] = useState(false)
  const { hexa, rgba } = useMemo(() => toColor(value, flattenOptions), [flattenOptions, value])

  const handleOnChange: ColorWrapChangeHandler = useCallback(
    (value) => {
      let result = `rgba(255, 255, 255, 1)`
      if (typeof value === 'string') {
        result = value
      } else if (isColorResult(value)) {
        const { r, g, b, a = 1 } = value.rgb || {}
        result = `rgba(${r}, ${g}, ${b}, ${a})`
      } else if (isRGB(value)) {
        const { r, g, b, a = 1 } = value
        result = `rgba(${r}, ${g}, ${b}, ${a})`
      }

      onChange?.(result)
    },
    [onChange],
  )

  const handleMenuClick = useCallback(
    ({ value }: IMenuOption<string>) => {
      onChange?.(value)
      setVisible(false)
    },
    [onChange],
  )

  const handleTransparent = useCallback(() => {
    onChange?.('transparent')
    setVisible(false)
  }, [onChange])

  const tabs = useMemo(() => {
    const arr = []
    if (options) {
      const initScrollId = options.find((item) =>
        item.options?.some((item) => item.value === value),
      )?.value
      arr.push({
        key: 'options',
        title: t('uiKit.colorPicker.brand'),
        component: (
          <Menu
            dimensions={MENU_DIMENSIONS}
            initScrollId={initScrollId}
            name='colorPicker'
            onClick={handleMenuClick}
            options={options}
            target={ref.current}
            value={value}
          />
        ),
      })
    }
    arr.push({
      key: 'color',
      title: (
        <Tooltip overlay={t('uiKit.colorPicker.customHint')}>
          {t('uiKit.colorPicker.custom')}
        </Tooltip>
      ),
      component: <ReactColorPicker color={rgba} onChange={handleOnChange} />,
    })
    return arr
  }, [options, rgba, handleOnChange, value, handleMenuClick])

  const overlay = (
    <>
      <Tabs
        activeKey={activeTab}
        name='colorPickerTabs'
        size={KitSize.S}
        tabBarExtraContent={{
          right: (
            <IconButton
              icon='otherClose'
              name='otherClose'
              onClick={() => setVisible(false)}
              size={KitSize.XS}
              // TODO: refactor Tabs
              // eslint-disable-next-line react/forbid-component-props
              style={{ marginRight: 14, marginTop: 4 }}
              styleType='ghost'
            />
          ),
        }}
      >
        {tabs.map((item) => (
          <TabPanel key={item.key} tabKey={item.key} title={item.title}>
            {item.component}
          </TabPanel>
        ))}
      </Tabs>
      {transparent && (
        <>
          <Divider free />
          <Button name='transparent' onClick={handleTransparent} size={KitSize.L} styleType='ghost'>
            <Icon name='iconsOtherNullBg' size={KitSize.XS} /> {t('uiKit.button.transparent')}
          </Button>
        </>
      )}
    </>
  )

  return (
    <Dropdown
      offset={offset}
      onVisibleChange={setVisible}
      overlay={overlay}
      placement={placement}
      rootRef={ref}
      styleType='clear'
      visible={visible}
    >
      <ColorRoot
        color={hexa}
        icon={icon}
        labeled={labeled}
        name={name}
        onBlur={onBlur}
        onFocus={onFocus}
        option={activeOption}
        size={size}
      />
    </Dropdown>
  )
}

export default ColorPicker
