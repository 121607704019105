import cn from 'classnames'
import React from 'react'

import Icon, { KeyIconsType } from '../Icon'
import { KitSize } from '../KitTypes'
import s from './Alert.module.scss'

interface IAlertProps {
  type: 'info' | 'warning' | 'error' | 'success'
  children: React.ReactNode
}

const ICON: Record<IAlertProps['type'], KeyIconsType> = {
  info: 'alertInfo',
  warning: 'alertDanger',
  error: 'alertFailRed',
  success: 'alertCheck',
}

const Alert: React.FC<IAlertProps> = ({ type, children }) => {
  return (
    <div className={cn(s.root, s[type])}>
      <div className={s.icon}>
        <Icon name={ICON[type]} size={KitSize.XS} />
      </div>
      <div className={s.content}>{children}</div>
    </div>
  )
}

export default Alert
