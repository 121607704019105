import { Highlighted } from 'components/uiKit/Table/types'
import {
  ProjectGroupListSchemaFragment,
  ProjectListSchemaFragment,
} from 'gql/__generated__/graphql'

export const checkIsProject = (
  entity: Highlighted<ProjectListSchemaFragment | ProjectGroupListSchemaFragment>,
): entity is ProjectListSchemaFragment => entity.__typename === 'Project'

export const checkIsFolder = (
  entity: Highlighted<ProjectListSchemaFragment | ProjectGroupListSchemaFragment>,
): entity is ProjectGroupListSchemaFragment => entity.__typename === 'ProjectGroup'
