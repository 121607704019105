import { gql } from 'gql/__generated__'

export const getUser = gql(`
  query getKUser {
    data: getKUser {
      avatar {
        ...AvatarSchema
      }
      ...kUserInfoSchema
      canCreateTrial
    }
  }
`)

export const kUserById = gql(`
  query KUserById($id: String!) {
    data: kUsersGetById(id: $id) {
      avatar {
        ...AvatarSchema
      }
      name
      email
      id
    }
  }
`)

export const kUserByIdAdmin = gql(`
  query KUserByIdAdmin($id: String!) {
    data: kUsersGetById(id: $id) {
      avatar {
        ...AvatarSchema
      }
      name
      email
      id
    }
  }
`)

export const getKUsersAll = gql(`
  query GetKUsersAll($payload: KUserFilter! = { owner: true }) {
    data: kUsersAll(payload: $payload) {
      avatar {
        ...AvatarSchema
      }
      ...kUserInfoSchema
    }
  }
`)

export const getKUsers = gql(`
  query GetKUsers($payload: GetKusersInput) {
    data: getKusers(payload: $payload) {
      users: data {
        ...kUserCoreSchema
        trials {
          id
        }
      }
      limit
      offset
      total
    }
  }
`)

export const getKuserCompanies = gql(`
  query GetKuserCompanies($payload: GetKUserCompaniesInput, $userId: String!) {
    data: getKuserCompanies(payload: $payload) {
      companies: data {
        ...KUserCompaniesSchema
      }
      limit
      offset
      total
    }
  }
`)

export const kUsersSubscribtion = gql(`
  subscription KUsersSubscribtion {
    data: kuser {
      kuser {
        ...kUserCoreSchema
      }
      type
    }
  }
`)

export const getToken = gql(`
  query GetToken {
    token: getToken
  }
`)
