import cn from 'classnames'
import { CSSProperties } from 'react'

import s from './Modal.module.scss'

interface IModalFooterProps {
  gap?: 'mini' | 'medium' | 'large'
  align?: 'start' | 'center' | 'end'
  children?: React.ReactNode
  bordered?: boolean
  padding?: number
}

const ModalFooter: React.FC<IModalFooterProps> = ({
  gap = 'medium',
  align = 'end',
  padding,
  bordered,
  children,
}) => {
  const footerCn = cn(s.footer, s[align], {
    [s.bordered]: bordered,
    [s.gapMini]: gap === 'mini',
    [s.gapLarge]: gap === 'large',
    [s.gapMedium]: gap === 'medium',
  })
  const style = {
    '--modal-footer-gap': `${gap}px`,
    paddingLeft: padding,
    paddingRight: padding,
  } as CSSProperties
  return (
    <div className={footerCn} style={style}>
      {children}
    </div>
  )
}

export default ModalFooter
