import React, { useState } from 'react'

import Field from 'components/controls/Field'
import { IFieldConfig, IFieldRule, IOnChangeData } from 'components/controls/Field/Field.types'

import {
  useRealtimeOnChange,
  useRealtimeData,
  useRealtimeValue,
  useRealtimeMeta,
  useOnBlurField,
  useOnFocusField,
  useFieldParams,
  useRealtimeFieldSize,
} from './hooks'

const isRequired = <D,>(config: IFieldConfig<D>) =>
  config.rules?.some((rule: IFieldRule<unknown>) => rule.required)

const RealtimeField = <D,>({ config }: { config: IFieldConfig<D> }) => {
  const data = useRealtimeData() as D
  const value = useRealtimeValue(config, data)
  const fieldSize = useRealtimeFieldSize()
  const [touched, setTouched] = useState(false)

  const meta = useRealtimeMeta(config, data, value, touched)
  const params = useFieldParams(config, data)

  const onChange = useRealtimeOnChange(config, data)

  const label =
    typeof config.label === 'function'
      ? config.label({
          data,
          config,
          onChange,
          value: value,
          defaultValue: config.defaultValue,
        })
      : config.label

  const onChangeData = async (changes: IOnChangeData) => {
    setTouched(true)
    try {
      await onChange(changes)
    } catch (error) {
      console.error(error)
    }
  }

  const onFocus = useOnFocusField()

  const onBlur = useOnBlurField()

  if (params.hidden) {
    return null
  }

  return (
    <Field
      config={config}
      defaultValue={config.defaultValue}
      label={label}
      meta={meta}
      onBlur={onBlur}
      onChange={onChangeData}
      onFocus={onFocus}
      params={params}
      Parent={RealtimeField}
      required={isRequired(config)}
      size={fieldSize}
      value={value}
    />
  )
}

export default React.memo(RealtimeField) as typeof RealtimeField
