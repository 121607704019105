import { RouteOptions } from './routes.types'

export const isRouteParams = <T extends Record<string, unknown>, S extends Record<string, unknown>>(
  value?: T | RouteOptions<S>,
): value is T => {
  if (!value) {
    return false
  }
  if ('state' in value) {
    return false
  }
  if ('search' in value) {
    return false
  }
  return true
}

export const isOptions = <T extends Record<string, unknown>, S extends Record<string, unknown>>(
  value?: T | RouteOptions<S>,
): value is RouteOptions<S> => {
  if (!value) {
    return false
  }
  if ('state' in value) {
    return true
  }
  if ('search' in value) {
    return true
  }
  return false
}
