import cn from 'classnames'
import React, { useCallback, useId } from 'react'

import { testPropsEl } from 'utils/test/qaData'

import s from './Checkbox.module.scss'

interface ICheckboxProps {
  disabled?: boolean
  checked: boolean
  onChange?: (value: boolean) => void
  setIsValid?: (value: boolean) => void
  brandStyle?: React.CSSProperties
  value: string
  testData: string
  isEditor?: boolean
  children: React.ReactNode
}

const Checkbox: React.FC<ICheckboxProps> = ({
  disabled,
  checked,
  onChange,
  setIsValid,
  children,
  brandStyle,
  value,
  testData,
  isEditor,
}) => {
  const id = useId()
  const handleOnChange = useCallback(() => onChange?.(!checked), [checked, onChange])
  const handleSetIsValid = useCallback(() => setIsValid?.(!checked), [checked, setIsValid])

  return (
    <label
      {...testPropsEl('courseCheckbox', { value }, { checked, testData })}
      className={cn(s.root, { [s.disabled]: disabled, [s.checked]: checked })}
      htmlFor={id}
      style={brandStyle}
    >
      <input
        checked={checked}
        disabled={disabled}
        id={isEditor ? undefined : id}
        onChange={handleOnChange}
        type='checkbox'
      />
      <div
        {...testPropsEl('checkmarkCheckbox', { value }, { checked, testData })}
        className={s.checkmark}
        onClick={isEditor ? handleSetIsValid : undefined}
      />
      <div className={s.label}>{children}</div>
    </label>
  )
}

export default React.memo(Checkbox)
