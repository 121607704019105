"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_VALUE = exports.TYPE = void 0;
exports.TYPE = 'testResult';
exports.DEFAULT_VALUE = {
    showDiagram: true,
    showFeedback: false,
    feedbackType: 'info',
    position: 'left',
};
