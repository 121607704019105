import ControlledRedirectModal from 'services/Permission/modal/ControlledRedirectModal'
import { t } from 'services/Translation'

import DisplayError from '.'
import { DataFallbackTypeEnum } from '../types'
import { ReactComponent as AccessDenied } from './svg/access_denied.svg'
import { ReactComponent as CommonError } from './svg/common_error.svg'
import { ReactComponent as NotFound } from './svg/not_found.svg'

export const SVG_MAP = {
  accessDenied: AccessDenied,
  notFound: NotFound,
  commonError: CommonError,
}

export const FALLBACK_COMPONENT_MAP = {
  modal: ControlledRedirectModal,
  page: DisplayError,
}

export const getTextTitleMap = (entity = t('page.protected.notFound.page')) => ({
  [DataFallbackTypeEnum.accessDenied]: t('page.protected.accessDenied.title'),
  [DataFallbackTypeEnum.commonError]: t('page.protected.commonError.title'),
  [DataFallbackTypeEnum.notFound]: t('page.protected.notFound.title', { entity }),
})

export const DESCRIPTION_TEXT_MAP = {
  [DataFallbackTypeEnum.accessDenied]: t('page.protected.accessDenied.description'),
  [DataFallbackTypeEnum.commonError]: t('page.protected.commonError.pageNotAvailable'),
  [DataFallbackTypeEnum.notFound]: t('page.protected.notFound.objectNotFound'),
}

export const LINK_TEXT_MAP = {
  [DataFallbackTypeEnum.accessDenied]: t('page.protected.accessDenied.textLink'),
  [DataFallbackTypeEnum.commonError]: t('page.protected.commonError.textLink'),
  [DataFallbackTypeEnum.notFound]: t('page.protected.notFound.textLink'),
}
