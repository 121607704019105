import { useContext, useEffect } from 'react'
import { useParams } from 'react-router'

//
// import { useMyInfo } from 'gql/actions.helpers'
import { fileMetaRemoveFakeFile } from 'gql/files.cache'
import { UppyResult, UppySuccessful, UppySucssesfull, UppyType } from 'store/models'
import { removeFilesUppyFromLocalStorage } from 'utils/removeFilesUppyFromLocalStorage'

import { UppyContext } from './UppyContext'

export type OnUppyCompleteType = (
  uppy: UppyType,
  result: UppyResult,
  data: UppySuccessful[],
) => void

type OptionsType = {
  maxFileSize?: null | number
  maxNumberOfFiles?: null | number
  minNumberOfFiles?: null | number
  allowedFileTypes?: null | string[]
}

export const useUppy = (onComplete?: OnUppyCompleteType, options?: OptionsType): UppyType => {
  const { companyId, projectId } = useParams<{
    companyId: string
    projectId: string
  }>()
  // const { profile } = useMyInfo()

  const uppy = useContext(UppyContext)

  useEffect(() => {
    uppy.setOptions({
      restrictions: options,
    })
  }, [uppy, options])

  useEffect(() => {
    const completeCallback = async (result: any) => {
      const upsert = result.successful.map(async (e: { uploadURL: string }) => {
        const fileId = e.uploadURL.split('/').pop()

        if (fileId) {
          return result
        }
      })

      result.successful.forEach((e: any) => {
        uppy.removeFile(e.id)
        removeFilesUppyFromLocalStorage(e.id)
      })

      const data = await Promise.all<{
        successful: UppySucssesfull[]
        id?: string
      }>(upsert)

      setTimeout(() => {
        onComplete && onComplete(uppy, result, data)
      })
    }

    uppy.on('file-removed', (file) => {
      if (!file?.progress?.uploadComplete && file?.meta?.fileMetaId) {
        fileMetaRemoveFakeFile(file.meta.fileMetaId as string)
      }
    })

    uppy.on('complete', completeCallback)

    return () => {
      uppy.off('complete', completeCallback)
    }
  }, [uppy, companyId, projectId, onComplete])

  return uppy
}
