import { DEFAULT_BRAND } from '@leenda/editor/lib/brand'
import React from 'react'

import { CommentMock } from 'components/comments/types'
import { TaskMock } from 'components/uiKit/Task'
import { EMPTY_ARRAY } from 'constants/commonConstans'
import BrandProvider from 'services/Branding/BrandProvider'
import { BrandType } from 'services/Branding/types'
import MediaProvider from 'services/Media/MediaProvider'
import { ProjectLabelsProvider } from 'services/ProjectLabels'
import { FontsInjector } from 'utils/font/injectors'

import { PreviewMode } from './enums'
import { Block, FontsGroups, MapType, Project, ProjectPaths, ScormSettings, Section } from './types'
import { useEditorSettings } from './useEditorSettings'
import { useReduxUpdate } from './useReduxUpdate'

interface IProjectProviderProps {
  path: ProjectPaths
  mode: PreviewMode
  project: Project
  sections: MapType<Section>
  blocks: MapType<Block>
  comments?: CommentMock[]
  tasks?: TaskMock[]
  fonts: FontsGroups
  scormSettings?: ScormSettings
  children: React.ReactNode
}

const ProjectProvider: React.FC<IProjectProviderProps> = ({
  children,
  mode,
  path,
  project,
  blocks,
  sections,
  fonts,
  comments = EMPTY_ARRAY,
  tasks = EMPTY_ARRAY,
  scormSettings,
}) => {
  const settings = useEditorSettings()
  useReduxUpdate(path, mode, project, sections, blocks, comments, tasks, settings, scormSettings)

  return (
    <BrandProvider companyBrand={(project.brand as BrandType) || DEFAULT_BRAND}>
      <ProjectLabelsProvider value={project.localeSettings}>
        <MediaProvider>
          {children}
          <FontsInjector fontGroups={fonts} />
        </MediaProvider>
      </ProjectLabelsProvider>
    </BrandProvider>
  )
}

export default React.memo(ProjectProvider)
