import { useQuery, useMutation } from '@apollo/client'
import { getOperationName } from '@apollo/client/utilities'

import { CompaniesAllQueryVariables } from './__generated__/graphql'
import {
  companyGetAllQuery,
  companyGetByIdQuery,
  getMyUserInfoQuery,
  companyCreate,
  companyUpdate,
  companyForceDelete,
  companyGetAllShort,
  companyGetAllAdmin,
  companyGetByIdAdminQuery,
  companiesDelete,
  createSsoConfig,
  deleteSsoConfig,
  updateSsoConfig,
} from './companies.gql'

export const useCompaniesGetAll = (variables: CompaniesAllQueryVariables, isCache?: boolean) =>
  useQuery(companyGetAllQuery, {
    fetchPolicy: !isCache ? 'cache-only' : 'cache-and-network',
    variables,
    onError: (err) =>
      console.error('"useCompaniesGetAll" fn is crashed on operation: "useQuery"', err),
  })

export const useCompaniesGetAllShort = () =>
  useQuery(companyGetAllShort, {
    fetchPolicy: 'cache-and-network',
    variables: {
      payload: { pagination: { limit: 200, offset: 0 }, isArchived: false },
    },
  })

export const useCompaniesGetAllAdmin = (variables: CompaniesAllQueryVariables) =>
  useQuery(companyGetAllAdmin, {
    variables,
    onError: (err) =>
      console.error('"useCompanyGetAllAdmin" fn is crashed on operation: "useQuery"', err),
  })

export const useCompanyGetById = (id: string) =>
  useQuery(companyGetByIdQuery, {
    variables: {
      companyId: id,
    },
    onError: (err) =>
      console.error('"useCompanyGetById" fn is crashed on operation: "useQuery"', err),
  })

export const useCompanyGetByIdAdmin = (id: string) =>
  useQuery(companyGetByIdAdminQuery, {
    variables: {
      companyId: id,
    },
    onError: (err) =>
      console.error('"useCompanyGetByIdAdmin" fn is crashed on operation: "useQuery"', err),
  })

export const useCompanyMe = (companyId: string, skip?: boolean) => {
  return useQuery(getMyUserInfoQuery, {
    variables: {
      companyId,
    },
    skip,
    onError: (err) =>
      console.error('"useCompanyGetMyInfo" fn is crashed on operation: "useQuery"', err),
  })
}

export const useCompanyCreate = () =>
  useMutation(companyCreate, {
    refetchQueries: [
      String(getOperationName(companyGetAllQuery)),
      String(getOperationName(companyGetAllShort)),
      String(getOperationName(companyGetAllAdmin)),
    ],
    onError: (err) =>
      console.error('"useCompanyCreate" fn is crashed on operation: "useMutation"', err),
  })

export const useCompanyUpdate = () =>
  useMutation(companyUpdate, {
    onError: (err) =>
      console.error('"useCompanyUpdate" fn is crashed on operation: "useMutation"', err),
    refetchQueries: [String(getOperationName(companyGetByIdQuery))],
  })

export const useCompanyForceDelete = () =>
  useMutation(companyForceDelete, {
    onError: (err) =>
      console.error('"useCompanyForceDelete" fn is crashed on operation: "useMutation"', err),
  })

export const useCompaniesDelete = () =>
  useMutation(companiesDelete, {
    refetchQueries: [String(getOperationName(companyGetAllAdmin))],
    onError: (err) =>
      console.error('"useCompaniesDelete" fn is crashed on operation: "useMutation"', err),
  })

export const useCreateSsoConfig = () => useMutation(createSsoConfig)
export const useDeleteSsoConfig = () =>
  useMutation(deleteSsoConfig, { refetchQueries: [String(getOperationName(companyGetByIdQuery))] })

export const useSsoUpdate = () => useMutation(updateSsoConfig)
