import React from 'react'

import s from './ControlsGroup.module.scss'

const ControlsGroup: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <div className={s.root} onMouseDown={(e: React.MouseEvent) => e.stopPropagation()}>
    {children}
  </div>
)

export default ControlsGroup
