import { createContext, useContextSelector } from 'use-context-selector'

import { KitSize, ValueType } from 'components/uiKit/KitTypes'
import { NOOP } from 'constants/commonConstans'

import { IMenuRef } from '../Menu'
import { ISelectProps } from './Select'
import { SelectValueType, ISelectOption } from './SelectTypes'

export interface ISelectContext<V extends SelectValueType> extends ISelectProps<V> {
  map: Record<string, ISelectOption<V>>
  listOptions: ISelectOption<V>[]
  flattenOptions: Omit<ISelectOption<V>, 'options'>[]
  inputRef: React.RefObject<HTMLInputElement>
  ref: React.RefObject<HTMLDivElement>
  listRef: React.RefObject<IMenuRef<ValueType>>
  rootRef: React.RefObject<HTMLInputElement>
  inputValue: string
  forceSearch: boolean
  isOpen: boolean
  focus: boolean
  hideList: boolean
  actions: {
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
    setInputValue: React.Dispatch<React.SetStateAction<string>>
    setForceSearch: React.Dispatch<React.SetStateAction<boolean>>
  }
  handlers: {
    handleChooseOption: (option: ISelectOption<V>) => void
    handleChange: (value: V) => void
    handleSeparate: () => void
  }
}

const SelectContext = createContext<ISelectContext<SelectValueType>>({
  name: 'Select',
  ref: { current: null },
  listRef: { current: null },
  inputRef: { current: null },
  rootRef: { current: null },
  map: {},
  listOptions: [],
  flattenOptions: [],
  forceSearch: false,
  isOpen: false,
  focus: false,
  hideList: false,
  inputValue: '',
  actions: {
    setIsOpen: NOOP,
    setForceSearch: NOOP,
    setInputValue: NOOP,
  },
  handlers: {
    handleChange: NOOP,
    handleChooseOption: NOOP,
    handleSeparate: NOOP,
  },
})

export const useSelectActions = () => useContextSelector(SelectContext, (c) => c.actions)
export const useSelectHandlers = () => useContextSelector(SelectContext, (c) => c.handlers)
export const useSelectContext = <R,>(selector: (c: ISelectContext<SelectValueType>) => R) =>
  useContextSelector(SelectContext, selector)

export const useSelectRef = () => useContextSelector(SelectContext, (c) => c.ref)
export const useSelectRootRef = () => useContextSelector(SelectContext, (c) => c.rootRef)
export const useSelectInputRef = () => useContextSelector(SelectContext, (c) => c.inputRef)
export const useSelectListRef = () => useContextSelector(SelectContext, (c) => c.listRef)

export const useSelectSize = () => useContextSelector(SelectContext, (c) => c.size || KitSize.M)
export const useSelectStyleType = () =>
  useContextSelector(SelectContext, (c) => c.styleType || 'default')

export default SelectContext
