import { ElementSchemaType } from '@leenda/editor/lib/brand'
import { ElementsTypes, ElementValue } from '@leenda/editor/lib/elements'

import { ElementState } from 'services/Store/Project/types'

import { IElementConfig } from './elements.types'

export const genElementConfig = <
  T extends ElementsTypes = ElementsTypes,
  V extends ElementValue = ElementValue,
  S extends ElementSchemaType = ElementSchemaType,
  F extends ElementSchemaType = ElementSchemaType,
  St extends ElementState = ElementState,
>(
  config: Pick<IElementConfig<T, V, S, F, St>, 'type' | 'defaultValue'>,
  customConfig: Omit<IElementConfig<T, V, S, F, St>, 'type' | 'defaultValue'>,
) => ({ ...config, ...customConfig })
