import cn from 'classnames'
import React, { useMemo } from 'react'
import HighlighterLib from 'react-highlight-words'

import s from './Highlighter.module.scss'

export interface IHighlighterProps {
  text: string
  search?: string
  breakWord?: boolean
}

const Highlighter: React.FC<IHighlighterProps> = ({ text, search = '', breakWord }) => {
  const searchWords = useMemo(() => search.split(/[\s-]/g), [search])

  return (
    <HighlighterLib
      // eslint-disable-next-line react/forbid-component-props
      className={cn(s.root, { [s.breakWord]: breakWord })}
      highlightClassName={s.highlight}
      searchWords={searchWords}
      textToHighlight={text}
      autoEscape
    />
  )
}

export default Highlighter
