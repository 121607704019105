import { useCallback, useMemo, useState } from 'react'

import AbsolutePortal from 'components/editor-v3/components/AbsolutePortal'
import { ABSOLUTE_PORTAL_GAP } from 'components/editor-v3/components/AbsolutePortal/constants'
import CanvasBubble from 'components/editor-v3/components/CanvasBubble'
import DropDown from 'components/uiKit/Dropdown/DropDown'
import { CommentStatus } from 'gql/__generated__/graphql'
import { scrollProps } from 'services/Scroll/ScrollService'
import { setOpenedComment } from 'services/Store/Project/actions'
import { AppMode } from 'services/Store/Project/enums'
import { useProjectContext, useProjectDispatch } from 'services/Store/Project/hooks'
import {
  getBlockId,
  getComments,
  getEditorMode,
  getSettings,
  getThreadId,
} from 'services/Store/Project/selectors'

import {
  CANVAS_COMMENT_ANIMATION_IN,
  CANVAS_COMMENT_ANIMATION_OUT,
  CANVAS_COMMENT_WHILE_HOVER,
} from '../constants'
import CanvasThread from './DropdownThread'
import CanvasComments from './DropdownThreads'
import s from './EditorCommentBubble.module.scss'

interface IEditorCommentBubbleProps {
  blockId: string
  nodeId?: string
  elementId?: string
}

const EditorCommentBubble: React.FC<IEditorCommentBubbleProps> = ({
  blockId,
  nodeId,
  elementId = null,
}) => {
  const dispatch = useProjectDispatch()
  const currentBlockId = useProjectContext(getBlockId)
  const threadId = useProjectContext(getThreadId)
  const showComments = useProjectContext(getSettings).comments
  const appMode = useProjectContext(getEditorMode)
  const filter = useMemo(() => ({ blockId, elementId }), [blockId, elementId])
  const comments = useProjectContext(getComments, filter)
  const activeThread = comments.find(({ id }) => id === threadId)
  const [visible, setVisible] = useState(Boolean(activeThread))
  const show =
    comments.length > 0 &&
    (appMode === AppMode.comment || showComments) &&
    (appMode !== AppMode.pro || !currentBlockId || currentBlockId === blockId)
  const isRead = useMemo(() => comments.every(({ isRead }) => isRead), [comments])
  const isResolved = useMemo(
    () => comments.every(({ status }) => status === CommentStatus.resolved),
    [comments],
  )

  const handleCloseThread = useCallback(() => {
    dispatch(setOpenedComment({ blockId, nodeId, threadId: null }))
    comments.length === 1 && setVisible(false)
  }, [dispatch, blockId, nodeId, comments.length])

  const handleVisible = useCallback(
    (visible: boolean) => {
      const threadId = (activeThread || (comments.length === 1 ? comments[0] : null))?.id || null
      setVisible(visible)
      dispatch(setOpenedComment(visible ? { blockId, nodeId, threadId } : null))
    },
    [dispatch, nodeId, blockId, activeThread, comments],
  )

  const overlay =
    activeThread || comments.length === 1 ? (
      <CanvasThread comment={activeThread || comments[0]} onClose={handleCloseThread} />
    ) : (
      <CanvasComments comments={comments} setVisible={handleVisible} />
    )

  if (!show) {
    return null
  }
  return (
    <AbsolutePortal
      animate={CANVAS_COMMENT_ANIMATION_IN}
      initial={CANVAS_COMMENT_ANIMATION_OUT}
      name='commentCanvas'
      placement='leftTop'
      translateX={`calc(-100% - ${ABSOLUTE_PORTAL_GAP}px)`}
    >
      {comments.map((comment) => (
        <div className={s.anchor} {...scrollProps(comment.id)} key={comment.id} />
      ))}
      <DropDown
        animate={CANVAS_COMMENT_ANIMATION_IN}
        exit={CANVAS_COMMENT_ANIMATION_OUT}
        initial={CANVAS_COMMENT_ANIMATION_OUT}
        onVisibleChange={handleVisible}
        overlay={overlay}
        placement='rightTop'
        styleType='clear'
        visible={visible || Boolean(activeThread)}
        whileHover={CANVAS_COMMENT_WHILE_HOVER}
      >
        {({ open }) => (
          <CanvasBubble
            {...(comments.length === 1
              ? { employee: comments[0].createdBy }
              : { count: comments.length })}
            active={open}
            isNew={!isRead}
            isResolved={isResolved}
          />
        )}
      </DropDown>
    </AbsolutePortal>
  )
}

export default EditorCommentBubble
