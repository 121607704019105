export enum SortUiEnum {
  CREATED_AT = 'CREATED_AT',
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  UPDATED_AT = 'UPDATED_AT',
  UPDATED_AT_DESC = 'UPDATED_AT_DESC',
  DEADLINE = 'DEADLINE',
  DEADLINE_ASC = 'DEADLINE_ASC',
  NAME = 'NAME',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  WEIGHT = 'WEIGHT',
  WEIGHT_DESC = 'WEIGHT_DESC',
}
