import { DisplayError } from 'components/DataFallback'
import { DataFallbackConfigType } from 'components/DataFallback/types'
import Modal, { MCWithParams, ModalBody } from 'components/uiKit/Modal'

const ModalRedirect: MCWithParams<DataFallbackConfigType, void> = ({ onClose, params }) => (
  <Modal name='redirect'>
    <ModalBody sizeAutoCapable>
      <DisplayError {...params} onClose={onClose} />
    </ModalBody>
  </Modal>
)

export default ModalRedirect
