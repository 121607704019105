import { useElementRootStyles } from '../../hooks/useElementRootStyles'
import DividerBase from './DividerBase'
import { useDividerNumber } from './DividerElement.hooks'
import { DividerElementType } from './DividerElement.types'

const DividerElement: DividerElementType = ({ element, styles, font }) => {
  const { dividerType, dividerView, isNumbered, dividerNumber } = element.value

  const rootStyles = useElementRootStyles(styles.root)
  const num = useDividerNumber(element.id)

  return (
    <div style={rootStyles}>
      <DividerBase
        font={font}
        isNumbered={isNumbered}
        number={num || dividerNumber}
        styles={styles}
        type={dividerType}
        view={dividerView}
      />
    </div>
  )
}
export default DividerElement
