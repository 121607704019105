import { RichTextFontSchemaType, RichTextSchemaType } from '@leenda/editor/lib/brand'
import { CONFIG_ELEMENTS, RichTextElementValue } from '@leenda/editor/lib/elements'
import { SlateElementType } from '@leenda/rich-text'

import { DEMO_MODE } from 'services/Store/Project/constants'
import { t } from 'services/Translation'

import DemoWrapper from '../../decorators/DemoWrapper'
import { IElementDemoProps } from '../../elements.types'
import Element from './RichTextElement'

const DEMO_VALUE: RichTextElementValue = [
  {
    type: SlateElementType.heading1,
    children: [
      {
        text: t('elements.richText.value.h1'),
      },
    ],
  },
  {
    type: SlateElementType.heading2,
    children: [
      {
        text: t('elements.richText.value.h2'),
      },
    ],
  },
  {
    type: SlateElementType.heading3,
    children: [
      {
        text: t('elements.richText.value.h3'),
      },
    ],
  },
  {
    type: SlateElementType.elementDefault,
    children: [
      {
        text: t('elements.richText.value.default'),
      },
    ],
  },
]

const RichTextDemo: React.FC<IElementDemoProps<RichTextSchemaType, RichTextFontSchemaType>> = ({
  styles,
  fonts,
}) => (
  <DemoWrapper>
    <Element
      element={{
        id: `${CONFIG_ELEMENTS.richText.type}-demo`,
        type: CONFIG_ELEMENTS.richText.type,
        value: DEMO_VALUE,
        style: {},
        font: {},
      }}
      font={fonts}
      mode={DEMO_MODE}
      styles={styles}
    />
  </DemoWrapper>
)

export default RichTextDemo
