import { NO_PANEL_ELEMENTS } from 'components/editor-v2/EditorElements/elements.config'
import { AppMode } from 'services/Store/Project/enums'
import { useProjectContext } from 'services/Store/Project/hooks'
import {
  getBlock,
  getEditorMode,
  getNodeId,
  getToolbar,
  isBlocksMultiSelect,
} from 'services/Store/Project/selectors'

import { getElementByNodeId } from '../../context/EditorContext/selectors/block'
import { BlockActionUiEnum, EditorActionUiEnum } from '../types'

export const useActiveToolbar = () => {
  const editorMode = useProjectContext(getEditorMode)
  const toolbar = useProjectContext(getToolbar)
  const block = useProjectContext(getBlock)
  const nodeId = useProjectContext(getNodeId)
  const isMultiple = useProjectContext(isBlocksMultiSelect)
  if (editorMode === AppMode.comment) {
    return EditorActionUiEnum.COMMENTS_PANEL
  }
  if (toolbar.taskTab) {
    return EditorActionUiEnum.TASKS_PANEL
  }

  if (!block) {
    return null
  }
  const element = nodeId && block && getElementByNodeId(block, nodeId)
  if (editorMode === AppMode.fill) {
    if (toolbar.blockSettings === block.uuid || isMultiple) {
      return BlockActionUiEnum.SETTINGS
    }
    // if (!toolbar.hidden && node && isShellNode(node) && !isMultiple) {
    //   return EditorActionUiEnum.ELEMENT_PANEL
    // }

    const element = nodeId && block && getElementByNodeId(block, nodeId)
    if (element && !NO_PANEL_ELEMENTS.includes(element.type)) {
      return EditorActionUiEnum.ELEMENT_PANEL
    }
  }
  if (editorMode === AppMode.pro) {
    if ((element || nodeId) && !isMultiple) {
      return EditorActionUiEnum.ELEMENT_PANEL
    }
    return BlockActionUiEnum.SETTINGS
  }

  return null
}
