import React from 'react'
import { chainName } from 'utils'

import { genDataField } from 'components/controls/Field'
import RealtimeField from 'components/controls/RealtimeField'
import Padding from 'components/editor-v3/Editor/Toolbar/Padding'
import Icon from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'
import { Block } from 'services/Store/Project/types'
import { t } from 'services/Translation'

import s from './Divider.module.scss'
import { useDividerNumber } from './DividerElement.hooks'
import { DividerValueFormType } from './DividerElement.types'
import { getDividerNumber } from './utils'

const genField = genDataField<Block>()

const dividerType = genField({
  name: 'dividerType',
  type: 'select',
  layout: 'horizontal',
  label: t('input.label.type'),
  params: {
    options: [
      { value: 'solid', label: t('input.option.solid') },
      { value: 'dotted', label: t('input.option.dotted') },
      { value: 'dashed', label: t('input.option.dashed') },
    ],
    fluid: true,
  },
})

const dividerView = genField({
  name: 'dividerView',
  label: t('input.label.view'),
  type: 'segmented',
  layout: 'horizontal',
  params: {
    options: [
      { value: 'single', label: t('input.option.single') },
      { value: 'double', label: t('input.option.double') },
    ],
  },
})

const isNumbered = genField({
  name: 'isNumbered',
  label: t('input.label.numbered'),
  type: 'segmented',
  layout: 'horizontal',
  defaultValue: false,
})

const dividerNumber = (num: string, isNumberedDivider?: boolean) =>
  genField({
    label: t('elements.divider.form.number'),
    name: 'dividerNumber',
    type: 'text',
    layout: 'horizontal',
    params: {
      placeholder: num,
      hidden: !isNumberedDivider,
      parser: getDividerNumber,
      maxLength: 8,
    },
  })

const DividerForm: DividerValueFormType = ({ name, element }) => {
  const num = useDividerNumber(element.id)

  const isNumberedDivider = element.value.isNumbered

  return (
    <Padding sides>
      <RealtimeField config={chainName(name, dividerType)} />
      <RealtimeField config={chainName(name, dividerView)} />
      <RealtimeField config={chainName(name, isNumbered)} />
      {isNumberedDivider && (
        <div className={s.numberExplanationWrapper}>
          <Icon name='builderTooltip' size={KitSize.S} />
          <div className={s.numberExplanation}>{t('elements.divider.form.numberHint')}</div>
        </div>
      )}
      <RealtimeField config={chainName(name, dividerNumber(num, isNumberedDivider))} />
    </Padding>
  )
}

export default DividerForm
