"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PermissionsKey = void 0;
var PermissionsKey;
(function (PermissionsKey) {
    PermissionsKey["system_admin_c"] = "system_admin_c";
    PermissionsKey["system_admin_r"] = "system_admin_r";
    PermissionsKey["system_admin_u"] = "system_admin_u";
    PermissionsKey["system_admin_d"] = "system_admin_d";
    PermissionsKey["company_r"] = "company_r";
    PermissionsKey["company_c"] = "company_c";
    PermissionsKey["company_d"] = "company_d";
    PermissionsKey["company_admin_info_c"] = "company_admin_info_c";
    PermissionsKey["company_admin_info_r"] = "company_admin_info_r";
    PermissionsKey["company_admin_info_u"] = "company_admin_info_u";
    PermissionsKey["company_admin_info_d"] = "company_admin_info_d";
    PermissionsKey["company_pages_dashboard"] = "company_pages_dashboard";
    PermissionsKey["company_pages_catalog"] = "company_pages_catalog";
    PermissionsKey["company_pages_resource"] = "company_pages_resource";
    PermissionsKey["company_pages_tasks"] = "company_pages_tasks";
    PermissionsKey["company_pages_tags"] = "company_pages_tags";
    PermissionsKey["company_pages_style"] = "company_pages_style";
    PermissionsKey["company_pages_fonts"] = "company_pages_fonts";
    PermissionsKey["company_pages_collection"] = "company_pages_collection";
    PermissionsKey["company_pages_employees"] = "company_pages_employees";
    PermissionsKey["company_pages_settings"] = "company_pages_settings";
    PermissionsKey["company_settings_base_r"] = "company_settings_base_r";
    PermissionsKey["company_settings_base_u"] = "company_settings_base_u";
    PermissionsKey["company_settings_owner_r"] = "company_settings_owner_r";
    PermissionsKey["company_settings_owner_u"] = "company_settings_owner_u";
    PermissionsKey["company_settings_system_r"] = "company_settings_system_r";
    PermissionsKey["company_settings_system_u"] = "company_settings_system_u";
    PermissionsKey["company_employees_manage_admin"] = "company_employees_manage_admin";
    PermissionsKey["company_employees_manage_employees"] = "company_employees_manage_employees";
    PermissionsKey["company_employees_self_base_r"] = "company_employees_self_base_r";
    PermissionsKey["company_employees_self_base_u"] = "company_employees_self_base_u";
    PermissionsKey["company_employees_self_profile_r"] = "company_employees_self_profile_r";
    PermissionsKey["company_employees_self_profile_u"] = "company_employees_self_profile_u";
    PermissionsKey["company_employees_self_companyInfo_r"] = "company_employees_self_companyInfo_r";
    PermissionsKey["company_employees_self_companyInfo_u"] = "company_employees_self_companyInfo_u";
    PermissionsKey["company_employees_self_adminInfo_r"] = "company_employees_self_adminInfo_r";
    PermissionsKey["company_employees_self_adminInfo_u"] = "company_employees_self_adminInfo_u";
    PermissionsKey["company_employees_list_base_r"] = "company_employees_list_base_r";
    PermissionsKey["company_employees_list_base_u"] = "company_employees_list_base_u";
    PermissionsKey["company_employees_list_profile_r"] = "company_employees_list_profile_r";
    PermissionsKey["company_employees_list_profile_u"] = "company_employees_list_profile_u";
    PermissionsKey["company_employees_list_companyInfo_r"] = "company_employees_list_companyInfo_r";
    PermissionsKey["company_employees_list_companyInfo_u"] = "company_employees_list_companyInfo_u";
    PermissionsKey["company_employees_list_adminInfo_r"] = "company_employees_list_adminInfo_r";
    PermissionsKey["company_employees_list_adminInfo_u"] = "company_employees_list_adminInfo_u";
    PermissionsKey["company_style_c"] = "company_style_c";
    PermissionsKey["company_style_r"] = "company_style_r";
    PermissionsKey["company_style_u"] = "company_style_u";
    PermissionsKey["company_style_d"] = "company_style_d";
    PermissionsKey["company_catalog_c"] = "company_catalog_c";
    PermissionsKey["company_catalog_r"] = "company_catalog_r";
    PermissionsKey["company_catalog_u"] = "company_catalog_u";
    PermissionsKey["company_catalog_d"] = "company_catalog_d";
    PermissionsKey["company_fonts_c"] = "company_fonts_c";
    PermissionsKey["company_fonts_r"] = "company_fonts_r";
    PermissionsKey["company_fonts_u"] = "company_fonts_u";
    PermissionsKey["company_fonts_d"] = "company_fonts_d";
    PermissionsKey["company_resource_c"] = "company_resource_c";
    PermissionsKey["company_resource_r"] = "company_resource_r";
    PermissionsKey["company_resource_u"] = "company_resource_u";
    PermissionsKey["company_resource_d"] = "company_resource_d";
    PermissionsKey["company_tags_c"] = "company_tags_c";
    PermissionsKey["company_tags_r"] = "company_tags_r";
    PermissionsKey["company_tags_u"] = "company_tags_u";
    PermissionsKey["company_tags_d"] = "company_tags_d";
    PermissionsKey["company_collections_self"] = "company_collections_self";
    PermissionsKey["company_collections_company_c"] = "company_collections_company_c";
    PermissionsKey["company_collections_company_r"] = "company_collections_company_r";
    PermissionsKey["company_collections_company_u"] = "company_collections_company_u";
    PermissionsKey["company_collections_company_d"] = "company_collections_company_d";
    PermissionsKey["project_r"] = "project_r";
    PermissionsKey["project_d"] = "project_d";
    PermissionsKey["project_c"] = "project_c";
    PermissionsKey["project_pages_preview"] = "project_pages_preview";
    PermissionsKey["project_pages_home"] = "project_pages_home";
    PermissionsKey["project_pages_editor"] = "project_pages_editor";
    PermissionsKey["project_pages_pro"] = "project_pages_pro";
    PermissionsKey["project_showInProject"] = "project_showInProject";
    PermissionsKey["project_blocks_c"] = "project_blocks_c";
    PermissionsKey["project_blocks_r"] = "project_blocks_r";
    PermissionsKey["project_blocks_u"] = "project_blocks_u";
    PermissionsKey["project_blocks_d"] = "project_blocks_d";
    PermissionsKey["project_blocks_close"] = "project_blocks_close";
    PermissionsKey["project_blocks_hide"] = "project_blocks_hide";
    PermissionsKey["project_settings_r"] = "project_settings_r";
    PermissionsKey["project_settings_general_u"] = "project_settings_general_u";
    PermissionsKey["project_settings_tags_u"] = "project_settings_tags_u";
    PermissionsKey["project_settings_style_u"] = "project_settings_style_u";
    PermissionsKey["project_settings_collections_u"] = "project_settings_collections_u";
    PermissionsKey["project_settings_localization_u"] = "project_settings_localization_u";
    PermissionsKey["project_sections_c"] = "project_sections_c";
    PermissionsKey["project_sections_r"] = "project_sections_r";
    PermissionsKey["project_sections_u"] = "project_sections_u";
    PermissionsKey["project_sections_d"] = "project_sections_d";
    PermissionsKey["project_sections_close"] = "project_sections_close";
    PermissionsKey["project_sections_hide"] = "project_sections_hide";
    PermissionsKey["project_team_list_c"] = "project_team_list_c";
    PermissionsKey["project_team_list_r"] = "project_team_list_r";
    PermissionsKey["project_team_list_u"] = "project_team_list_u";
    PermissionsKey["project_team_list_d"] = "project_team_list_d";
    PermissionsKey["project_team_manage_owner"] = "project_team_manage_owner";
    PermissionsKey["project_comments_c"] = "project_comments_c";
    PermissionsKey["project_comments_r"] = "project_comments_r";
    PermissionsKey["project_comments_u"] = "project_comments_u";
    PermissionsKey["project_comments_d"] = "project_comments_d";
    PermissionsKey["project_comments_resolve"] = "project_comments_resolve";
    PermissionsKey["project_collection_c"] = "project_collection_c";
    PermissionsKey["project_collection_r"] = "project_collection_r";
    PermissionsKey["project_collection_u"] = "project_collection_u";
    PermissionsKey["project_collection_d"] = "project_collection_d";
    PermissionsKey["project_resource_c"] = "project_resource_c";
    PermissionsKey["project_resource_r"] = "project_resource_r";
    PermissionsKey["project_resource_u"] = "project_resource_u";
    PermissionsKey["project_resource_d"] = "project_resource_d";
    PermissionsKey["project_tasks_c"] = "project_tasks_c";
    PermissionsKey["project_tasks_r"] = "project_tasks_r";
    PermissionsKey["project_tasks_d"] = "project_tasks_d";
    PermissionsKey["project_tasks_common"] = "project_tasks_common";
    PermissionsKey["project_tasks_deadline"] = "project_tasks_deadline";
    PermissionsKey["project_tasks_status"] = "project_tasks_status";
    PermissionsKey["project_tasks_assign"] = "project_tasks_assign";
    PermissionsKey["project_tasks_comment"] = "project_tasks_comment";
    PermissionsKey["project_tasks_attach"] = "project_tasks_attach";
    PermissionsKey["project_style_c"] = "project_style_c";
    PermissionsKey["project_style_r"] = "project_style_r";
    PermissionsKey["project_style_u"] = "project_style_u";
    PermissionsKey["project_style_d"] = "project_style_d";
    PermissionsKey["project_publicLink_c"] = "project_publicLink_c";
    PermissionsKey["project_publicLink_r"] = "project_publicLink_r";
    PermissionsKey["project_publicLink_u"] = "project_publicLink_u";
    PermissionsKey["project_publicLink_d"] = "project_publicLink_d";
    PermissionsKey["project_scormExport_c"] = "project_scormExport_c";
    PermissionsKey["project_scormExport_r"] = "project_scormExport_r";
    PermissionsKey["project_scormExport_u"] = "project_scormExport_u";
    PermissionsKey["project_scormExport_d"] = "project_scormExport_d";
    PermissionsKey["project_employeesFilter_comments_mention"] = "project_employeesFilter_comments_mention";
    PermissionsKey["project_employeesFilter_team_add"] = "project_employeesFilter_team_add";
    PermissionsKey["project_employeesFilter_tasks_assignee"] = "project_employeesFilter_tasks_assignee";
    PermissionsKey["project_employeesFilter_tasks_assignOnDelete"] = "project_employeesFilter_tasks_assignOnDelete";
    PermissionsKey["project_employeesFilter_tasks_mention"] = "project_employeesFilter_tasks_mention";
})(PermissionsKey || (exports.PermissionsKey = PermissionsKey = {}));
