import React, { useCallback } from 'react'

import { IControlProps } from 'components/controls/Field/Field.types'
import TimePicker, { ITimePickerProps } from 'components/uiKit/TimePicker'

const FieldTime: React.FC<IControlProps<ITimePickerProps>> = ({
  onChange,
  name,
  value,
  defaultValue,
  ...rest
}) => {
  const handleChange = useCallback(
    (value: number | null) => onChange({ value, name }),
    [name, onChange],
  )

  return <TimePicker {...rest} name='time' onChange={handleChange} value={value ?? defaultValue} />
}

export default FieldTime
