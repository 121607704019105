type LogEntry = {
  level: 'info' | 'warn' | 'error' | 'debug' | 'action' | 'log'
  message: string
  timestamp: string
  [key: string]: any
}

class Logger {
  private logs: LogEntry[] = []
  private readonly scopeName: string | undefined
  private readonly parent: Logger | undefined
  constructor(parent?: Logger, scopeName?: string) {
    this.parent = parent
    this.scopeName = scopeName
  }

  logWithLevel(level: LogEntry['level'], message: string, data?: any) {
    if (!this.parent) {
      this.logs.push({
        level,
        message: `${this.scopeName ? this.scopeName + ' ' : ''}${message}`,
        timestamp: new Date().toISOString(),
        data,
      })
    } else {
      this.parent.logWithLevel(
        level,
        `${this.scopeName ? this.scopeName + ' ' : ''}${message}`,
        data,
      )
    }
  }
  log(message: string, data?: any) {
    this.logWithLevel('info', message, data)
  }
  info(message: string, data: any) {
    this.logWithLevel('info', message, data)
  }
  action(message: string, data: any) {
    this.logWithLevel('action', message, data)
  }

  getLogs(): LogEntry[] {
    if (this.parent) {
      return this.parent.getLogs()
    }
    return this.logs
  }
  getScopeLogs(scope = this.scopeName): LogEntry[] {
    return this.getLogs().filter((log) => log.message.startsWith(scope || ''))
  }
  scope(scopeName: string) {
    return new Logger(this, `${this.scopeName ? this.scopeName + ':' : ''}${scopeName}`)
  }
  clear() {
    if (this.parent) {
      this.parent.clear()
    } else {
      this.logs = []
    }
  }
}

export const logger = new Logger()
