import cn from 'classnames'
import React, {
  ChangeEvent,
  DetailedHTMLProps,
  MutableRefObject,
  TextareaHTMLAttributes,
  useEffect,
  useRef,
  useState,
} from 'react'

import { testProps } from 'utils/test/qaData'

import s from './TextArea.module.scss'
import { VERTICAL_PADDING_MEDIUM, VERTICAL_PADDING_MINI } from './constants'
import { useAutoHeight } from './useAutoHeight'

const DEFAULT_SIZE = { minRows: 4, maxRows: 4 }
type TextAreaDefaultTypes = DetailedHTMLProps<
  TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
>

export type TextAreaRef = HTMLTextAreaElement

export type AutoHeightProp = boolean | { minRows?: number; maxRows?: number }

export interface ITextArea extends Omit<TextAreaDefaultTypes, 'onChange'> {
  name: string
  onChange: (value: string, e: ChangeEvent<HTMLTextAreaElement>) => void
  placeholder?: string
  value?: string
  disabled?: boolean
  autoSize?: AutoHeightProp
  styleType?: 'default' | 'ghost'
  size?: 'mini' | 'medium'
  fluid?: boolean
  error?: boolean
  ref?:
    | ((instance: HTMLTextAreaElement | null) => void)
    | React.MutableRefObject<HTMLTextAreaElement | null>
    | null
  postfix?: React.ReactNode
  maxWidth?: number
  lineHeight?: number
}

const TextArea = React.forwardRef<HTMLTextAreaElement, ITextArea>(
  (
    {
      value = '',
      postfix,
      onChange,
      autoFocus,
      autoSize = DEFAULT_SIZE,
      styleType = 'default',
      size = 'medium',
      fluid = true,
      error,
      maxWidth,
      onFocus,
      onBlur,
      lineHeight,
      ...rest
    },
    ref,
  ) => {
    const [isFocus, setIsFocus] = useState(false)
    const onFocusHandle = (event: React.FocusEvent<HTMLTextAreaElement>) => {
      onFocus && onFocus(event)
      setIsFocus(true)
    }

    const onBlurHandle = (event: React.FocusEvent<HTMLTextAreaElement>) => {
      onBlur && onBlur(event)
      setIsFocus(false)
    }

    const localRef = useRef<TextAreaRef>(null)
    const textareaElement = (ref as MutableRefObject<HTMLTextAreaElement | null>) || localRef
    const [pos, setPos] = useState(0)

    const verticalPadding = size === 'mini' ? VERTICAL_PADDING_MINI : VERTICAL_PADDING_MEDIUM
    const cssVars = { '--verticalPadding': `${verticalPadding}px`, maxWidth } as React.CSSProperties

    const handleChangeTextArea = (e: ChangeEvent<HTMLTextAreaElement>) => {
      setPos(e.target.selectionStart)
      onChange(e.target.value, e)
    }

    useEffect(() => {
      if (textareaElement.current) {
        textareaElement.current.selectionStart = pos
        textareaElement.current.selectionEnd = pos
      }
    }, [value, pos])

    useAutoHeight(textareaElement, verticalPadding, autoSize, [value, verticalPadding], lineHeight)

    useEffect(() => {
      if (autoFocus) {
        // hotfix scrolling when focusing element outside viewport
        setTimeout(() => {
          if (textareaElement.current) {
            textareaElement.current.focus({ preventScroll: true })
            textareaElement.current.selectionStart = textareaElement.current.value.length
            textareaElement.current.selectionEnd = textareaElement.current.value.length
          }
        }, 0)
      }
    }, [])

    return (
      <div
        className={cn(s.root, { [s.fluid]: fluid, [s.additionRow]: lineHeight && !!value })}
        style={cssVars}
      >
        <textarea
          {...rest}
          {...testProps({
            el: 'textarea',
            value,
            name: rest.name,
            disabled: rest.disabled,
            focus: isFocus,
          })}
          className={cn(s.textarea, s[styleType], s[size], {
            [s.disabled]: rest.disabled,
            [s.error]: error,
            [s.hasPostfix]: postfix,
          })}
          onBlur={onBlurHandle}
          onChange={handleChangeTextArea}
          onFocus={onFocusHandle}
          ref={textareaElement}
          rows={lineHeight ? 1 : undefined}
          value={value}
        />
        {postfix && (
          <div className={cn(s.postfix, { [s.additionRow]: lineHeight && !!value })}>{postfix}</div>
        )}
      </div>
    )
  },
)

TextArea.displayName = 'TextArea'

export default TextArea
