import cn from 'classnames'
import React, { useEffect, useRef } from 'react'

import s from './Scalable.module.scss'

interface IScalableProps {
  minHeight?: number
  children: React.ReactNode
}

const Scalable: React.FC<IScalableProps> = ({ children, minHeight }) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const childRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const container = containerRef.current
    const child = childRef.current

    const resizeObserver = new ResizeObserver(() => {
      if (container && child) {
        const containerWidth = container.offsetWidth
        const childWidth = child.scrollWidth
        if (childWidth > 0) {
          const scale = containerWidth / childWidth
          container.style.height = `${child.scrollHeight * scale}px`
          child.style.transform = `scale(${scale})`
        }
      }
    })

    if (child) {
      resizeObserver.observe(child)
    }

    return () => {
      if (child) {
        resizeObserver.unobserve(child)
      }
    }
  }, [])

  return (
    <div className={cn(s.root)} ref={containerRef} style={{ minHeight }}>
      <div className={s.container} ref={childRef}>
        {children}
      </div>
    </div>
  )
}

export default Scalable
