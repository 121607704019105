import { useKeyPress } from 'ahooks'
import * as R from 'ramda'

import { EMPTY_ARRAY } from 'constants/commonConstans'

export const useKeyboardNavigation = <O extends { value: unknown }>(
  options: O[] = EMPTY_ARRAY,
  target?: HTMLDivElement | null,
  hovered?: O | null,
  onEnter?: () => void,
  onChange?: (item: O | null) => void,
) => {
  const firstItem = R.head(options) || null
  const lastItem = R.last(options) || null

  useKeyPress(
    ['uparrow', 'downarrow', 'enter'],
    (e) => {
      if (e.key === 'Enter') {
        onEnter?.()
      }

      const index = options.findIndex((i) => i.value === hovered?.value) || 0

      if (e.key === 'ArrowUp') {
        e.preventDefault()
        return onChange?.(options[R.dec(index)] || lastItem)
      }
      if (e.key === 'ArrowDown') {
        e.preventDefault()
        return onChange?.(options[R.inc(index)] || firstItem)
      }
    },
    { target },
  )
}
