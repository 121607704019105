import { TestResultFontSchemaType, TestResultSchemaType } from '@leenda/editor/lib/brand'
import { CONFIG_ELEMENTS, TestResultElementValue } from '@leenda/editor/lib/elements'

import { DEMO_MODE } from 'services/Store/Project/constants'
import { t } from 'services/Translation'

import DemoWrapper from '../../decorators/DemoWrapper'
import { IElementDemoProps } from '../../elements.types'
import Element from './TestResultElement'

const DEMO_VALUE: TestResultElementValue = {
  position: 'top',
  feedbackType: 'validation',
  resultFailTitle: t('elements.testResult.value.resultFailTitle'),
  showDiagram: true,
  showFeedback: true,
  resultFeedbackFail: t('elements.testResult.value.resultFeedbackFail'),
  resultSuccessTitle: t('elements.testResult.value.resultSuccessTitle'),
  resultFeedbackSuccess: t('elements.testResult.value.resultFeedbackSuccess'),
}

const TestResultDemo: React.FC<
  IElementDemoProps<TestResultSchemaType, TestResultFontSchemaType>
> = ({ styles, fonts }) => (
  <DemoWrapper>
    <Element
      element={{
        id: `${CONFIG_ELEMENTS.testResult.type}-demo`,
        type: CONFIG_ELEMENTS.testResult.type,
        value: DEMO_VALUE,
        font: {},
        style: {},
      }}
      font={fonts}
      mode={DEMO_MODE}
      styles={styles}
    />
  </DemoWrapper>
)

export default TestResultDemo
