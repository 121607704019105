import React from 'react'

import { CommentMock } from 'components/comments/types'
import { getShellByElementId } from 'components/editor-v3/context/EditorContext/selectors/block'
import { getShellName } from 'components/editor-v3/context/EditorContext/selectors/blockLabels'
import { KitSize } from 'components/uiKit/KitTypes'
import { ITypographyProps, Typography } from 'components/uiKit/Typography'
import { Block } from 'services/Store/Project/types'

interface IPathProps extends Partial<ITypographyProps> {
  comment: CommentMock
  block?: Pick<Block, 'schema' | 'elements'> | null
}

const Path: React.FC<IPathProps> = ({ comment, block, ...rest }) => {
  const { elementId, blockIndex = 0 } = comment

  const shellId = block && elementId && getShellByElementId(block, elementId)
  const elementName = block && shellId && getShellName(block, shellId)

  return (
    <Typography size={KitSize.XS} styleType='hint' weight='medium' {...rest}>
      #{(blockIndex || 0) + 1}
      {elementId && ` / ${elementName || '...'}`}
    </Typography>
  )
}

export default Path
