import React from 'react'

import Empty from 'components/uiKit/Empty'
import { KitDimensions, KitSize, ValueType } from 'components/uiKit/KitTypes'
import Menu, { IMenuOption } from 'components/uiKit/Menu'
import { t } from 'services/Translation'

import {
  useSelectContext,
  useSelectHandlers,
  useSelectListRef,
  useSelectRef,
} from '../SelectContext'

const MENU_DIMENSIONS: KitDimensions = { maxHeight: 350 }

const SelectList = () => {
  const target = useSelectRef()
  const listRef = useSelectListRef()
  const { handleChooseOption } = useSelectHandlers()
  const name = useSelectContext((c) => c.name)
  const options = useSelectContext((c) => c.listOptions)
  const isMultiple = useSelectContext((c) => c.isMultiple)
  const value = useSelectContext((c) => c.value)

  return (
    <Menu
      dimensions={MENU_DIMENSIONS}
      empty={<Empty icon='empty' size={KitSize.XS} text={t('uiKit.select.empty')} />}
      name={`${name}SelectList`}
      onClick={handleChooseOption as (option: IMenuOption<ValueType>) => void}
      options={options}
      ref={listRef}
      resettable={isMultiple}
      target={target.current}
      value={value as ValueType}
    />
  )
}

export default React.memo(SelectList)
