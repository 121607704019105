import { createAction } from 'utils/useReducerWithMiddleware'

import { IProgressChange } from '../types'
import { Action, IUpdateState } from './types'

export const play = createAction(Action.PLAY)<null>()
export const pause = createAction(Action.PAUSE)<null>()
export const togglePlay = createAction(Action.TOGGLE_PLAY)<null>()
export const toggleFullScreen = createAction(Action.TOGGLE_FULLSCREEN)<null>()
export const startSeeking = createAction(Action.START_SEEKING)<null>()
export const setPlaybackRate = createAction(Action.SET_PLAYBACK_RATE)<number>()
export const seekTo = createAction(Action.SEEK_TO)<number>()
export const setPlayed = createAction(Action.SET_PLAYED)<number>()
export const setVolume = createAction(Action.SET_VOLUME)<number>()
export const setDuration = createAction(Action.SET_DURATION)<number>()
export const setProgress = createAction(Action.SET_PROGRESS)<IProgressChange>()
export const update = createAction(Action.UPDATE)<IUpdateState>()
