import InputNumber from 'components/uiKit/InputNumber'
import { KitSize } from 'components/uiKit/KitTypes'

import { IArrayOption } from '../FieldNumber'
import s from './InputNumberArray.module.scss'

interface IFieldMarginProps {
  array: IArrayOption[]
  value?: (number | undefined)[]
  onChange: (value?: (number | undefined)[]) => void
  defaultValue?: (number | undefined)[]
  size?: KitSize
  onBlur?: () => void
  onFocus?: () => void
  name: string
}

const InputNumberArray: React.FC<IFieldMarginProps> = ({
  onChange,
  onBlur,
  onFocus,
  array,
  size,
  value,
  defaultValue,
  name,
}) => {
  const onChangeIndex = (index: number) => (inputValue?: number) => {
    const val = array.map((_, i) =>
      i === index ? inputValue ?? undefined : (value ?? defaultValue)?.[i] ?? undefined,
    )
    onChange(val)
  }

  return (
    <div className={s.inputWrapper}>
      {array.map((val, i) => (
        <div className={s.item} key={i}>
          <InputNumber
            name={`${name}.${i}`}
            onBlur={onBlur}
            onChange={onChangeIndex(i)}
            onFocus={onFocus}
            size={size}
            value={value?.[i]}
            isInteger
          />
          <div className={s.icon}>{val.icon}</div>
        </div>
      ))}
    </div>
  )
}

export default InputNumberArray
