import { useCallback } from 'react'

import { useProjectContext, useProjectDispatch } from 'services/Store/Project/hooks'
import { getEndCourseTrigger } from 'services/Store/Project/triggers'

import BaseButtonElement from '../BaseButtonElement'
import { ButtonElementType } from '../ButtonElement.types'

const EndCourseButtonElement: ButtonElementType = ({ element, styles, mode, font }) => {
  const label = element?.value?.label
  const dispatch = useProjectDispatch()
  const trigger = useProjectContext(getEndCourseTrigger)

  const onClick = useCallback(() => dispatch(trigger.actionCreator()), [dispatch, trigger])

  return (
    <BaseButtonElement
      action={element.value.action}
      disabled={trigger.disabled}
      font={font}
      label={label}
      mode={mode}
      onClick={onClick}
      styles={styles}
    />
  )
}

export default EndCourseButtonElement
