"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FONT = exports.FONT_SCHEMA_EMPTY = void 0;
const types_1 = require("../types");
const genStyle_1 = require("./schema/genStyle");
const theme_1 = require("./theme");
const utils_1 = require("./utils");
const R = __importStar(require("ramda"));
const FONT_DESKTOP_SCHEMA = {
    base: {
        fontFamily: (0, genStyle_1.genStyle)({ value: (0, utils_1.setFontValue)({ name: 'Inter' }) }),
        fontWeight: (0, genStyle_1.genStyle)({ value: 400 }),
        fontStyle: (0, genStyle_1.genStyle)({ value: 'normal' }),
        fontSize: (0, genStyle_1.genStyle)({ value: 16 }),
        lineHeight: (0, genStyle_1.genStyle)({ value: 1.5 }),
        color: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.primaryTextColor }),
    },
    caption: {
        fontFamily: (0, genStyle_1.genStyle)({ value: (0, utils_1.setFontValue)({ name: 'Inter' }) }),
        fontWeight: (0, genStyle_1.genStyle)({ value: 400 }),
        fontStyle: (0, genStyle_1.genStyle)({ value: 'normal' }),
        fontSize: (0, genStyle_1.genStyle)({ value: 14 }),
        lineHeight: (0, genStyle_1.genStyle)({ value: 1.5 }),
        color: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.secondaryTextColor }),
    },
    h1: {
        fontFamily: (0, genStyle_1.genStyle)({ value: (0, utils_1.setFontValue)({ name: 'Inter' }) }),
        fontWeight: (0, genStyle_1.genStyle)({ value: 700 }),
        fontStyle: (0, genStyle_1.genStyle)({ value: 'normal' }),
        fontSize: (0, genStyle_1.genStyle)({ value: 48 }),
        lineHeight: (0, genStyle_1.genStyle)({ value: 1.5 }),
        color: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.primaryTextColor }),
    },
    h2: {
        fontFamily: (0, genStyle_1.genStyle)({ value: (0, utils_1.setFontValue)({ name: 'Inter' }) }),
        fontWeight: (0, genStyle_1.genStyle)({ value: 700 }),
        fontStyle: (0, genStyle_1.genStyle)({ value: 'normal' }),
        fontSize: (0, genStyle_1.genStyle)({ value: 32 }),
        lineHeight: (0, genStyle_1.genStyle)({ value: 1.5 }),
        color: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.primaryTextColor }),
    },
    h3: {
        fontFamily: (0, genStyle_1.genStyle)({ value: (0, utils_1.setFontValue)({ name: 'Inter' }) }),
        fontWeight: (0, genStyle_1.genStyle)({ value: 700 }),
        fontStyle: (0, genStyle_1.genStyle)({ value: 'normal' }),
        fontSize: (0, genStyle_1.genStyle)({ value: 24 }),
        lineHeight: (0, genStyle_1.genStyle)({ value: 1.5 }),
        color: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.primaryTextColor }),
    },
    h4: {
        fontFamily: (0, genStyle_1.genStyle)({ value: (0, utils_1.setFontValue)({ name: 'Inter' }) }),
        fontWeight: (0, genStyle_1.genStyle)({ value: 700 }),
        fontStyle: (0, genStyle_1.genStyle)({ value: 'normal' }),
        fontSize: (0, genStyle_1.genStyle)({ value: 16 }),
        lineHeight: (0, genStyle_1.genStyle)({ value: 1.5 }),
        color: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.primaryTextColor }),
    },
    list: {
        fontWeight: (0, genStyle_1.genStyle)({ value: 400 }),
        fontStyle: (0, genStyle_1.genStyle)({ value: 'normal' }),
        spacing: (0, genStyle_1.genStyle)({ value: 8 }),
        gap: (0, genStyle_1.genStyle)({ value: 3 }),
        color: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.primaryTextColor }),
    },
    quote: {
        fontWeight: (0, genStyle_1.genStyle)({ value: 400 }),
        fontStyle: (0, genStyle_1.genStyle)({ value: 'normal' }),
        lineHeight: (0, genStyle_1.genStyle)({ value: 1.5 }),
        color: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.secondaryTextColor }),
        borderLeftColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.primaryColor }),
    },
    link: {
        color: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.primaryColor }),
        textDecoration: (0, genStyle_1.genStyle)({ value: 'underline' }),
    },
};
const FONT_TABLET_SCHEMA = {
    base: { fontSize: (0, genStyle_1.genStyle)({ value: 16 }), lineHeight: (0, genStyle_1.genStyle)({ value: 1.5 }) },
    caption: { fontSize: (0, genStyle_1.genStyle)({ value: 14 }), lineHeight: (0, genStyle_1.genStyle)({ value: 1.5 }) },
    h1: { fontSize: (0, genStyle_1.genStyle)({ value: 32 }), lineHeight: (0, genStyle_1.genStyle)({ value: 1.5 }) },
    h2: { fontSize: (0, genStyle_1.genStyle)({ value: 24 }), lineHeight: (0, genStyle_1.genStyle)({ value: 1.5 }) },
    h3: { fontSize: (0, genStyle_1.genStyle)({ value: 20 }), lineHeight: (0, genStyle_1.genStyle)({ value: 1.5 }) },
    h4: { fontSize: (0, genStyle_1.genStyle)({ value: 16 }), lineHeight: (0, genStyle_1.genStyle)({ value: 1.5 }) },
    list: { spacing: (0, genStyle_1.genStyle)({ value: 8 }), gap: (0, genStyle_1.genStyle)({ value: 3 }) },
    quote: { lineHeight: (0, genStyle_1.genStyle)({ value: 1.5 }) },
    link: {},
};
const FONT_MOBILE_SCHEMA = {
    base: { fontSize: (0, genStyle_1.genStyle)({ value: 16 }), lineHeight: (0, genStyle_1.genStyle)({ value: 1.5 }) },
    caption: { fontSize: (0, genStyle_1.genStyle)({ value: 14 }), lineHeight: (0, genStyle_1.genStyle)({ value: 1.5 }) },
    h1: { fontSize: (0, genStyle_1.genStyle)({ value: 32 }), lineHeight: (0, genStyle_1.genStyle)({ value: 1.5 }) },
    h2: { fontSize: (0, genStyle_1.genStyle)({ value: 24 }), lineHeight: (0, genStyle_1.genStyle)({ value: 1.5 }) },
    h3: { fontSize: (0, genStyle_1.genStyle)({ value: 20 }), lineHeight: (0, genStyle_1.genStyle)({ value: 1.5 }) },
    h4: { fontSize: (0, genStyle_1.genStyle)({ value: 16 }), lineHeight: (0, genStyle_1.genStyle)({ value: 1.5 }) },
    list: { spacing: (0, genStyle_1.genStyle)({ value: 8 }), gap: (0, genStyle_1.genStyle)({ value: 3 }) },
    quote: { lineHeight: (0, genStyle_1.genStyle)({ value: 1.5 }) },
    link: {},
};
exports.FONT_SCHEMA_EMPTY = R.mapObjIndexed((font) => R.mapObjIndexed(() => ({}), font), FONT_DESKTOP_SCHEMA);
exports.FONT = {
    [types_1.DeviceMode.desktop]: FONT_DESKTOP_SCHEMA,
    [types_1.DeviceMode.tablet]: FONT_TABLET_SCHEMA,
    [types_1.DeviceMode.mobile]: FONT_MOBILE_SCHEMA,
};
