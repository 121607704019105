import { CONFIG_ELEMENTS } from '@leenda/editor/lib/elements'

import EditableProMode from 'components/editor-v3/cource/layout/controls/ElementControls/EditableProMode'
import { t } from 'services/Translation'

import { ElementGroup } from '../../elements.types'
import { genElementConfig } from '../../genElementConfig'
import { STYLE_FORM_SCHEMA, FONT_FORM_SCHEMA } from './TableBrandFormSchema'
import TableDemo from './TableDemo'
import Element from './TableElement'
import Form from './TableElementForm'
import tableIcon from './table.svg'

export default genElementConfig(CONFIG_ELEMENTS.table, {
  icon: tableIcon,
  label: t('elements.table.label'),
  group: ElementGroup.view,
  Element,
  ValueForm: Form,
  Demo: TableDemo,
  STYLE_FORM_SCHEMA,
  FONT_FORM_SCHEMA,
  EditorActions: EditableProMode,
})
