import { CardSide } from '@leenda/editor/lib/elements'
import cn from 'classnames'
import { useMemo } from 'react'

import { genDataField } from 'components/controls/Field'
import RealtimeField from 'components/controls/RealtimeField'
import Padding from 'components/editor-v3/Editor/Toolbar/Padding'
import { IColorOption } from 'components/uiKit/ColorPicker'
import Divider from 'components/uiKit/Divider'
import { genColorOptions } from 'services/Branding/constants/fields'
import { useBrandTheme } from 'services/Branding/hooks'
import { Block } from 'services/Store/Project/types'
import { t } from 'services/Translation'
import { chainName, getParent } from 'utils/form'

import { CardValueFormType } from './CardElement.types'
import s from './CardForm.module.scss'

const genField = genDataField<Block>()

export const frontImage = genField({
  name: 'front.image',
  label: t('elements.card.form.sideA'),
  type: 'file',
  layout: 'vertical',
  params: {
    fileType: 'image',
    nullable: true,
    preview: true,
  },
})

export const frontFill = (colorOptions: IColorOption[]) =>
  genField({
    name: 'front.fillColor',
    type: 'color',
    label: t('input.label.blackout'),
    layout: 'horizontal',
    defaultValue: '#FFFFFF1A',
    params: {
      options: colorOptions,
    },
  })

export const backImage = genField({
  name: 'back.image',
  type: 'file',
  layout: 'vertical',
  label: t('elements.card.form.sideB'),
  params: {
    fileType: 'image',
    nullable: true,
    preview: true,
  },
})

export const backFill = (colorOptions: IColorOption[]) =>
  genField({
    name: 'back.fillColor',
    type: 'color',
    label: t('input.label.blackout'),
    layout: 'horizontal',
    defaultValue: '#FFFFFF1A',
    params: {
      options: colorOptions,
    },
  })

export const frontAccessibility = genField({
  name: 'front.image.accessibility',
  type: 'text',
  label: t('input.label.accessibility'),
  layout: 'horizontal',
  params: (block, { name }) => {
    const { parent } = getParent<CardSide>(name, block, 2)
    return {
      placeholder: t('input.placeholder.altText'),
      hidden: !parent?.image?.id,
    }
  },
})

export const backAccessibility = genField({
  name: 'back.image.accessibility',
  type: 'text',
  label: t('input.label.accessibility'),
  layout: 'horizontal',
  params: (block, { name }) => {
    const { parent } = getParent<CardSide>(name, block, 2)
    return {
      placeholder: t('input.placeholder.altText'),
      hidden: !parent?.image?.id,
    }
  },
})

const CardForm: CardValueFormType = ({ name, state, setState }) => {
  const theme = useBrandTheme()
  const colorOptions = useMemo(() => genColorOptions(theme), [theme])

  const handleAClick = () => setState({ isBack: false })
  const handleBClick = () => setState({ isBack: true })

  return (
    <>
      <div className={cn({ [s.active]: !state?.isBack })} onClick={handleAClick}>
        <Padding bottom sides top>
          <RealtimeField config={chainName(name, frontImage)} />
          <RealtimeField config={chainName(name, frontFill(colorOptions))} />
          <RealtimeField config={chainName(name, frontAccessibility)} />
        </Padding>
      </div>
      <Divider free />
      <div className={cn({ [s.active]: state?.isBack })} onClick={handleBClick}>
        <Padding bottom sides top>
          <RealtimeField config={chainName(name, backImage)} />
          <RealtimeField config={chainName(name, backFill(colorOptions))} />
          <RealtimeField config={chainName(name, backAccessibility)} />
        </Padding>
      </div>
    </>
  )
}

export default CardForm
