import { Route, RouteProps } from 'react-router-dom'

import Lazy from 'components/lazy'
import { AUTH_PATHS } from 'constants/paths'

export const GuestRoute: React.FC<RouteProps> = (props) => {
  // if invite
  // if follow the link from the mail
  // then log out
  const hash = window.location.hash
  const search = window.location.search

  if (
    (search && search.includes('?t=')) ||
    hash.includes(AUTH_PATHS.newPass) ||
    hash.includes(AUTH_PATHS.regConfirm) ||
    hash.includes(AUTH_PATHS.regSuccess) ||
    hash.includes(AUTH_PATHS.regFailure)
  ) {
    localStorage.removeItem('token')
  }

  // const redirectLink = useLink(PATHS.app)

  // const isLogged =
  //   Boolean(localStorage.getItem('token')) || Boolean(sessionStorage.getItem('visitorToken'))

  // if (!isLogged) {
  return <Route {...props} />
  // } else {
  //   return <Redirect to={redirectLink} />
  // }
}

const Auth = Lazy(() => import('./Auth.routes' /* webpackChunkName: "auth-routes" */))

export default Auth
