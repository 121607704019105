import { DraggableStateSnapshot } from '@hello-pangea/dnd'
import { TestRankingFontSchemaType, TestRankingSchemaType } from '@leenda/editor/lib/brand'
import cn from 'classnames'
import React from 'react'

import Icon from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'
import { ElementFontCss, ElementStyleCss } from 'services/Branding/types'
import { testProps } from 'utils/test/qaData'

import s from './TestRankingItem.module.scss'

type TestRankingItemProps = {
  snapshot: DraggableStateSnapshot
  styles: ElementStyleCss<TestRankingSchemaType>
  font?: ElementFontCss<TestRankingFontSchemaType>
  children: React.ReactNode
  index: number
}

const TestRankingItem = ({ snapshot, styles, children, font, index }: TestRankingItemProps) => {
  const childStyles = { ...styles.root, ...font?.base }
  const rootCn = cn(s.root, { [s.dragging]: snapshot.isDragging })

  return (
    <div className={rootCn} style={childStyles}>
      <div className={s.icon} {...testProps({ el: 'courseRankingDrag', index })}>
        <Icon name='otherBurger' size={KitSize.M} />
      </div>
      {children}
    </div>
  )
}

export default TestRankingItem
