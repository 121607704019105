import { FileTypeEnum } from 'utils'

import { ACCEPT_MAP_FILES_EXTENSIONS } from './constants'

export const getExtFile = (file: File) => file.name.split('.').pop()?.toLowerCase() || ''

export const validateFileExtension = (file: File, allowedExtensions: string[]): boolean =>
  allowedExtensions.includes(getExtFile(file))

export const getAllowedExtByFiles = (files: File[], fileTypes: FileTypeEnum[]) => {
  const allowedExtensions = fileTypes.length
    ? fileTypes.reduce<string[]>((acc, item) => {
        if (item in ACCEPT_MAP_FILES_EXTENSIONS) {
          return [...acc, ...ACCEPT_MAP_FILES_EXTENSIONS[item]]
        }
        return acc
      }, [])
    : Object.values(ACCEPT_MAP_FILES_EXTENSIONS).flat()

  const validFiles: File[] = []
  const errorFiles: File[] = []

  files.forEach((file) => {
    if (validateFileExtension(file, allowedExtensions)) {
      validFiles.push(file)
    } else {
      errorFiles.push(file)
    }
  })

  return { validFiles, errorFiles }
}

export const errorFilesExt = (errorFiles: File[]) =>
  errorFiles.reduce<string>((acc, item) => `${acc}, ${getExtFile(item)}`, '')
