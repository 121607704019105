const FORMAT_MAP: Record<string, string> = {
  woff: 'woff',
  woff2: 'woff2',
  ttf: 'truetype',
  otf: 'opentype',
  // eot: 'embedded-opentype',
  // svg: 'svg',
}

export interface IFontFaceProps {
  name: string
  file: { path?: string; ext?: string; id: string } | null | undefined
  weight?: number | null
  isItalic?: boolean | null
}

export const genFontFace = ({ name, file, isItalic, weight }: IFontFaceProps) => `
@font-face {
  font-family: '${name}';
  src: url('${file?.path}') format(${FORMAT_MAP[file?.ext || 'woff']});
  font-style: ${isItalic ? 'italic' : 'normal'};
  ${weight ? `font-weight: ${weight};` : ''}
}`
