import { Cell } from '@leenda/editor/lib/elements'
import { textToRtValue } from '@leenda/rich-text'
import cn from 'classnames'

import RichText from 'components/form/RichText/RichText'

import { useTableContext } from '../../TableContext'
import s from './Cell.module.scss'
import { getCellStyles } from './getCellStyles'

type SimpleCellProps = {
  cell: Cell
  indexRow: number
  index: number
  // styles: CSSProperties
}

const SimpleCell = ({ cell, index, indexRow }: SimpleCellProps) => {
  const { styles, cellNodes, tableValue, waiting, font, onChangeCellText } = useTableContext()
  const width = tableValue.columns[index].width

  const cellStyles = {
    ...getCellStyles({
      cell,
      row: indexRow,
      col: index,
      headerColumn: tableValue.headerColumn,
      headerRow: tableValue.headerRow,
      styles,
    }),
    width,
    minWidth: width,
    height: cellNodes[indexRow][index].clientHeight,
  }

  return (
    <td className={cn(s.td)} style={cellStyles}>
      <pre className={cn(s.root)}>
        <span className={s.span} /* style={{ fontFamily: cellStyles?.fontFamily }} */>
          <RichText
            active={false}
            key={`table.${indexRow}.${index}`}
            name={`table.${indexRow}.${index}`}
            onChange={(val) => onChangeCellText(val, indexRow, index)}
            styles={font}
            value={cell.value || textToRtValue(' ')}
            waiting={waiting}
            disabled
          />
        </span>
      </pre>
    </td>
  )
}

export default SimpleCell
