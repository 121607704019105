"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const constants_1 = require("../../../elements/elements/Grouping/constants");
const font_1 = require("../../font");
const theme_1 = require("../../theme");
const genStyle_1 = require("../genStyle");
const styleSchema = {
    group: {
        backgroundColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.primaryBackgroundColor }),
        borderRadius: (0, genStyle_1.genStyle)({ value: 12 }),
        borderColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.borderColor }),
        borderWidth: (0, genStyle_1.genStyle)({ value: 1 }),
    },
    focusColor__var: {
        borderColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.focusedColor }),
    },
    answer: {
        backgroundColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.primaryBackgroundColor }),
        borderRadius: (0, genStyle_1.genStyle)({ value: 12 }),
        borderStyle: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.borderStyle }),
        borderColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.borderColor }),
        borderWidth: (0, genStyle_1.genStyle)({ value: 1 }),
    },
    icon: {
        backgroundImage: (0, genStyle_1.genStyle)({ value: null }),
    },
    progress: {
        height: (0, genStyle_1.genStyle)({ value: 4 }),
        backgroundColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.neutralColor }),
    },
    progressResult__var: {
        borderRadius: (0, genStyle_1.genStyle)({ value: 6 }),
        backgroundColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.primaryColor }),
    },
    indents: {
        padding: (0, genStyle_1.genStyle)({ value: [2, 0, 2, 8] }),
        gap: (0, genStyle_1.genStyle)({ value: 8 }),
        marginTop: (0, genStyle_1.genStyle)({ value: 32 }),
    },
};
const fontSchema = {
    base: Object.assign(Object.assign({}, font_1.FONT_SCHEMA_EMPTY.base), { fontSize: (0, genStyle_1.genStyle)({ value: 14 }) }),
    h4: Object.assign({}, font_1.FONT_SCHEMA_EMPTY.h4),
};
exports.default = { [constants_1.TYPE]: { style: styleSchema, font: fontSchema } };
