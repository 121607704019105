import lodash from 'lodash'
import queryString from 'query-string'
import { useMemo } from 'react'
import { useLocation } from 'react-router'
import { generatePath, matchPath, useHistory, useParams } from 'react-router'

import { notEmpty } from 'utils/notEmpty'

import { GetLinkToType, ParamsType, MainQueryTypes, StrictRouteParams } from './routes.types'
import { isOptions, isRouteParams } from './typeGuards'

export const usePathParamsSafe = <T extends string>(
  path: T,
): StrictRouteParams<T> | Record<string, undefined> => {
  try {
    return usePathParams(path)
  } catch (e) {
    return {}
  }
}

export const useMatchPathParams = <T extends string>(
  conditionPaths: T[],
  comparedPath?: T,
): boolean => {
  const locationPath = useLocation().pathname
  const pathname = comparedPath || locationPath
  return useMemo(
    () => conditionPaths.some((itemPath) => matchPath(pathname, { path: itemPath })),
    [pathname, ...conditionPaths, matchPath],
  )
}

export const usePathParams = <T extends string>(path: T): StrictRouteParams<T> => {
  const location = useLocation()
  const match = useMemo(
    () =>
      matchPath(location.pathname, {
        path: path,
      }),
    [location.pathname, path],
  )
  if (match) {
    return match.params as StrictRouteParams<T>
  }
  if (!match) {
    throw new Error(`Path ${path} does not match current location ${location.pathname}`)
  }
  return {} as any
}

export const usePathActive = <T extends string>(path: T): boolean => {
  const location = useLocation()
  const match = useMemo(
    () =>
      matchPath(location.pathname, {
        path: path,
      }),
    [location.pathname, path],
  )
  return Boolean(match)
}

export const usePathQuery = <T extends string = MainQueryTypes>() => {
  const history = useHistory()
  const search = useMemo(
    () => queryString.parse(history.location.search),
    [history.location.search],
  )

  return search as Record<T, string | undefined>
}

export const useLink: GetLinkToType = (...[route, firstArg, secondArg]) => {
  const oldParams = useParams<ParamsType>()
  const history = useHistory()
  const params = isRouteParams(firstArg) ? firstArg : {}
  const newParams = lodash.pickBy<string | null | undefined, string>(params, notEmpty)
  const options = isOptions(firstArg) ? firstArg : secondArg

  try {
    const pathname = generatePath(route, { ...oldParams, ...newParams } as any)

    return {
      pathname,
      search: options?.search || history.location.search,
      state: options?.state,
    }
  } catch (error) {
    return {
      pathname: '',
    }
  }
}
