export const getDividerNumber = (value: string) => {
  // allows entering only numbers and dots
  const characterLimits = value.replace(/[^\d.]/g, '')

  // prohibits entering more than one dot and more than one zero in a row
  let step1 = characterLimits.replace(/0{2,}/g, '0').replace(/\.{2,}/g, '.')

  if (step1[0] === '.') {
    step1 = step1.replace('.', '')
  } else if ((step1.match(/\./g) || []).length > 2) {
    step1 = step1.slice(0, -1)
  }

  const step2 = step1.split('.')

  // prohibits entering more than two characters before/after dot
  if (step2.find((item) => item.length > 2)) {
    return step2.map((item) => item.slice(0, 2)).join('.')
  }
  return step1
}
