"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_VALUE = exports.TYPE = void 0;
const translation_1 = require("../../../translation");
const types_1 = require("./types");
exports.TYPE = 'button';
exports.DEFAULT_VALUE = {
    label: (0, translation_1.t)('elements.button.label'),
    secondLabel: (0, translation_1.t)('elements.button.label'),
    thirdLabel: (0, translation_1.t)('elements.button.label'),
    fourthLabel: (0, translation_1.t)('elements.button.label'),
    actionType: types_1.ButtonActionType.link,
};
