export const PLATFORM_CONFIG = {
  passwordMinLength: 10,
  PlatformName: 'Leenda',
  announcerDefaultLink: 'https://help.leenda.com',
  knowledgeBase: 'https://help.leenda.com',
  emailDomain: 'leenda.com',
  hubSpotChatPath: 'https://js-eu1.hs-scripts.com/24969710.js',
  hubSpotContactSalesPath: 'https://meetings-eu1.hubspot.com/leenda-/demo-leenda?embed=true',
  authBG: 'linear-gradient(114deg, #0a8571 0%, #04734b 81.04%)',
  termsOfService:
    'https://storage.yandexcloud.net/terms-service-and-privacy-policy/Leenda/2024_LEENDA_Пользовательское%20соглашение_.pdf',
  privacyPolicy:
    'https://storage.yandexcloud.net/terms-service-and-privacy-policy/Leenda/2024_LEENDA_Политика%20обработки%20персональных%20данных.pdf',
  trialLink: 'mailto:support@leenda.com',
  hasLmsIntegration: false,
}
