import cn from 'classnames'
import React, { useEffect } from 'react'
import { createPortal } from 'react-dom'

import Icon from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'
import { COURSE_TOOLTIP } from 'constants/portals'
import { DeviceMode } from 'services/Store/Project/enums'
import { IBlockMode } from 'services/Store/Project/types'
import { testProps } from 'utils/test/qaData'

import s from './CourseModal.module.scss'

interface ICourseModalProps<Props extends Record<string, unknown>> {
  visible: boolean
  onClose: () => void
  component: React.FC<Props & { onClose: () => void; containerElement: HTMLDivElement | null }>
  props?: Props
  mode: IBlockMode
  maxWidth?: number
}

const CourseModal = <T extends Record<string, unknown>>({
  component,
  visible,
  onClose,
  props,
  mode,
  maxWidth,
}: ICourseModalProps<T>) => {
  const [ref, setRef] = React.useState<HTMLDivElement | null>(null)
  const Component = component as ICourseModalProps<T>['component']
  const getContainer = () => document.getElementById(COURSE_TOOLTIP) || document.body
  const desktopMode = mode.deviceMode === DeviceMode.desktop
  const onCloseClick = () => {
    onClose && onClose()
  }

  const rootCn = cn(s.root, {
    [s.desktop]: desktopMode,
    [s.mobile]: mode.deviceMode === DeviceMode.mobile,
    [s.tablet]: mode.deviceMode === DeviceMode.tablet,
  })
  useEffect(() => {
    const closeOnEsc = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onCloseClick()
      }
    }
    document.addEventListener('keydown', closeOnEsc)
    return () => {
      document.removeEventListener('keydown', closeOnEsc)
    }
  }, [visible])

  if (!visible) {
    return null
  }

  const content = (
    <div className={rootCn}>
      <div className={s.overlay} onClick={onCloseClick}>
        {!desktopMode && (
          <div className={s.closeMobile} onClick={onCloseClick}>
            <Icon name='otherClose' size={KitSize.M} />
          </div>
        )}
      </div>
      <div className={s.contentWrapper} ref={setRef}>
        <div className={s.content} style={{ maxWidth }}>
          {desktopMode && (
            <div
              className={s.closeDesktop}
              onClick={onCloseClick}
              {...testProps({ el: 'modalClose', name: 'courseModal' })}
            >
              <Icon name='otherClose' size={KitSize.M} />
              <div className={s.closeBtnText}>esc</div>
            </div>
          )}
          <Component {...(props as T)} containerElement={ref} onClose={onCloseClick} />
        </div>
        {!desktopMode && <div className={s.closeFix} onClick={onCloseClick} />}
      </div>
    </div>
  )

  return createPortal(content, getContainer())
}

export default CourseModal
