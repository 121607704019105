import React, { useMemo } from 'react'
import { createContext } from 'use-context-selector'

import { IAsyncOnChange } from 'components/controls/Field/Field.types'
import { KitSize } from 'components/uiKit/KitTypes'

interface IPageProps<Data> {
  children: React.ReactNode
  data: Data
  size?: KitSize
  onChange: IAsyncOnChange<Data>
  onFocusField?: (fieldName?: string) => void
  onBlurField?: () => void
}

export interface IRealtimeFormContext<Data = unknown> {
  data: Data
  size?: KitSize
  onChange: IAsyncOnChange<Data>
  onFocusField?: (fieldName?: string) => void
  onBlurField?: () => void
}

export const RealtimeFormContext = createContext<IRealtimeFormContext>({
  data: {},
  onChange: () => Promise.resolve(console.error('not init RealtimeFormContext')),
})

RealtimeFormContext.Provider.displayName = 'RealtimeFormContext.Provider'

const RealtimeForm = <Data,>({
  children,
  onChange,
  data,
  size = KitSize.M,
  onFocusField,
  onBlurField,
}: IPageProps<Data>) => {
  const contextValue = useMemo(
    () => ({ data, size, onChange, onFocusField, onBlurField }),
    [data, size, onChange, onFocusField, onBlurField],
  )
  return (
    <RealtimeFormContext.Provider value={contextValue}>{children}</RealtimeFormContext.Provider>
  )
}

export default React.memo(RealtimeForm) as typeof RealtimeForm
