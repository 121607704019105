import { CustomElement, LinkElement, SlateElementType, MentionElement } from '@leenda/rich-text'
import cn from 'classnames'
import React, { CSSProperties, useCallback } from 'react'
import { Range } from 'slate'
import { useSelected, useSlate } from 'slate-react'
import { RenderElementProps } from 'slate-react/dist/components/editable'

import { IconButton } from 'components/uiKit/Button'
import Divider from 'components/uiKit/Divider'
import Icon from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'
import { notify } from 'components/uiKit/Notification'
import { NotificationType } from 'components/uiKit/Notification/types'
import { NOOP, stopPropagation } from 'constants/commonConstans'
import { t } from 'services/Translation'
import { prepareLinkUrl } from 'utils/websiteValidation'

import s from '../AppText.module.scss'
import { SlateElementConfig } from '../constants'
import { getLinkElement } from '../inline/withInline'
import {
  getDisabled,
  getForm,
  getMentionsMap,
  getReadOnly,
  useAppTextSelector,
} from '../useAppTextSelector'
import { getNodeFormatting } from './Text'

interface ISlateBlockRenderProps {
  attributes?: RenderElementProps['attributes']
  style?: { [key: string]: any }
  element: CustomElement
  isEdit?: boolean
  className?: string
  children?: React.ReactNode
}

type SlateBlockRender = React.FC<ISlateBlockRenderProps>

const BlockquoteElement: SlateBlockRender = ({ children, attributes, isEdit, ...restProps }) => (
  <blockquote {...attributes} {...restProps}>
    {children}
  </blockquote>
)
const NumberedList: SlateBlockRender = ({ children, attributes, isEdit, ...restProps }) => (
  <ol {...attributes} {...restProps}>
    {children}
  </ol>
)
const BulletedList: SlateBlockRender = ({ children, attributes, isEdit, ...restProps }) => (
  <ul {...attributes} {...restProps}>
    {children}
  </ul>
)
const DefaultElement: SlateBlockRender = ({ children, attributes, isEdit, ...restProps }) => (
  <div {...attributes} {...restProps}>
    {children}
  </div>
)
const ListItem: SlateBlockRender = ({ children, attributes, isEdit, ...restProps }) => (
  <li {...attributes} {...restProps}>
    {children}
  </li>
)
const Heading1: SlateBlockRender = ({ children, attributes, isEdit, ...restProps }) => (
  <h1 {...attributes} {...restProps}>
    {children}
  </h1>
)
const Heading2: SlateBlockRender = ({ children, attributes, isEdit, ...restProps }) => (
  <h2 {...attributes} {...restProps}>
    {children}
  </h2>
)
const Heading3: SlateBlockRender = ({ children, attributes, isEdit, ...restProps }) => (
  <h3 {...attributes} {...restProps}>
    {children}
  </h3>
)
const Heading4: SlateBlockRender = ({ children, attributes, isEdit, ...restProps }) => (
  <h3 {...attributes} {...restProps}>
    {children}
  </h3>
)
const InlineChromiumBugfix = () => (
  <span
    contentEditable={false}
    style={{
      fontSize: 0,
    }}
  >
    ${String.fromCodePoint(160) /* Non-breaking space */}
  </span>
)

const Link: SlateBlockRender = ({ attributes, children, element, className }) => {
  const form = useAppTextSelector(getForm)
  const readOnly = useAppTextSelector(getReadOnly)
  const disabled = useAppTextSelector(getDisabled)
  const editor = useSlate()
  const selected = editor && getLinkElement(editor) === element
  const elementTyped = element as LinkElement
  const style: CSSProperties = {}

  if (selected) {
    style.boxShadow = '0 0 0 2px var(--color-delimiter-secondary)'
  }

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (e.ctrlKey || e.metaKey) {
      window.open(prepareLinkUrl(elementTyped.url), '_blank')
    }
  }

  const handleCopy = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(elementTyped.url)
      notify({ type: NotificationType.success, message: t('notify.linkCopied') })
    } catch (err) {
      notify({
        type: NotificationType.error,
        message: t('notify.copiedError'),
        duration: 2000,
      })
    }
  }, [elementTyped.url])

  if (readOnly || disabled) {
    return (
      <a
        {...attributes}
        className={className}
        href={prepareLinkUrl(elementTyped.url)}
        onClick={handleClick}
        rel='noopener noreferrer'
        style={style}
        target='_blank'
      >
        {children}
      </a>
    )
  }

  return (
    <span {...attributes} className={className} onClick={handleClick} style={style}>
      <InlineChromiumBugfix />
      {children}
      {!form &&
        (!editor.selection || (editor.selection && Range.isCollapsed(editor.selection))) && (
          <div className={s.wrLinkHover} contentEditable={false}>
            <div className={s.linkHover} onClick={stopPropagation}>
              <a
                className={s.linkTitle}
                contentEditable={false}
                href={prepareLinkUrl(elementTyped.url)}
                rel='noopener noreferrer'
                target='_blank'
              >
                <Icon name='openLink' size={KitSize.S} />
                {t('uiKit.richText.goToLink')}
              </a>
              <Divider size={KitSize.S} styleType='vertical' />
              <IconButton
                icon='otherCopy'
                name='copyLink'
                onClick={handleCopy}
                size={KitSize.S}
                styleType='ghost'
              />
            </div>
          </div>
        )}
      <InlineChromiumBugfix />
    </span>
  )
}

const Code: SlateBlockRender = ({ children, attributes, isEdit, ...restProps }) => (
  <code {...attributes} {...restProps}>
    {children}
  </code>
)

const Mention: SlateBlockRender = ({ attributes, className, element, children }) => {
  const mentionsMap = useAppTextSelector(getMentionsMap)
  const { employeeId, name } = element as MentionElement
  const selected = useSelected()
  const style: React.CSSProperties = {
    boxShadow: selected ? '0 0 0 2px var(--color-delimiter-secondary)' : 'none',
  }
  return (
    <span {...attributes} className={className} contentEditable={false} style={style}>
      @{mentionsMap[employeeId]?.kUser.name || name || t('page.employee.notFound')}
      {children}
    </span>
  )
}

const ELEMENTS_COMPONENTS: Record<SlateElementType, SlateBlockRender> = {
  [SlateElementType.blockquote]: BlockquoteElement,
  [SlateElementType.numberedList]: NumberedList,
  [SlateElementType.bulletedList]: BulletedList,
  [SlateElementType.listItem]: ListItem,
  [SlateElementType.elementDefault]: DefaultElement,
  [SlateElementType.caption]: DefaultElement,
  [SlateElementType.heading1]: Heading1,
  [SlateElementType.heading2]: Heading2,
  [SlateElementType.heading3]: Heading3,
  [SlateElementType.heading4]: Heading4,
  [SlateElementType.link]: Link,
  [SlateElementType.crossLink]: NOOP,
  [SlateElementType.mention]: Mention,
  [SlateElementType.code]: Code,
  [SlateElementType.annotation]: NOOP,
}

const Elements: React.FC<Partial<RenderElementProps & { isEdit: boolean }>> = (props) => {
  const { element, attributes } = props
  if (!element) {
    return null
  }
  const config = SlateElementConfig[element.type] || {}
  const { style, classes } = getNodeFormatting(element)
  const Component = ELEMENTS_COMPONENTS[element.type] || DefaultElement
  return (
    <Component
      attributes={attributes}
      className={cn(config.className, classes)}
      element={element}
      isEdit={props.isEdit}
      style={style}
    >
      {props.children}
    </Component>
  )
}

export default Elements
