import React from 'react'
import { useState } from 'react'

import Button from 'components/uiKit/Button'
import Input from 'components/uiKit/Input'
import { KitSize } from 'components/uiKit/KitTypes'
import Select from 'components/uiKit/Select'
import { SCORM_API_Wrapper } from 'scormWrapper/vendors/SCORM_API_Wrapper'
import { CMIElement } from 'scormWrapper/vendors/SCORM_API_types'

import s from './ScormDataItem.module.scss'

interface IScormDataItemProps {
  cmiKey: CMIElement
  label: string
  defaultValue?: string
  options?: any[]
}

const ScormDataItem: React.FC<IScormDataItemProps> = ({ label, options, defaultValue, cmiKey }) => {
  const [scormValue, setValue] = useState<string | null>('')
  const [valueForSet, setValueForSet] = useState(defaultValue || '')

  const handleGet = () => setValue(SCORM_API_Wrapper.getValue(cmiKey))

  return (
    <div className={s.root}>
      <div className={s.title}>{label}</div>
      <div className={s.content}>
        <Button
          disabled={!valueForSet}
          name='set'
          onClick={() => SCORM_API_Wrapper.setValue(cmiKey, valueForSet)}
          styleType='secondary'
        >
          set
        </Button>
        {options ? (
          <Select
            name='select'
            onChange={setValueForSet}
            options={options}
            size={KitSize.S}
            value={valueForSet}
          />
        ) : (
          <Input name='input' onChange={setValueForSet} size={KitSize.S} value={valueForSet} />
        )}
      </div>
      <div className={s.content}>
        <Button name='get' onClick={handleGet} styleType='secondary'>
          get
        </Button>
        <pre className={s.pre}>{scormValue}</pre>
      </div>
    </div>
  )
}

export default ScormDataItem
