import queryString from 'query-string'
import { useMemo } from 'react'
import { matchPath, useHistory } from 'react-router'

import { usePageSize, useSort } from 'components/uiKit/Table'
import { EDITOR_PATHS } from 'constants/paths'
import {
  FilesGroupsFragment,
  FileMetaSchemaFragment,
  FileMetaGroupsSearch,
  FileMetaTypeFilterEnum,
} from 'gql/__generated__/graphql'
import { convertToTimestamp } from 'utils/getDate'

type FilesGroupsQueryWeak = {
  page?: number
  pageSize?: number
  sort?: string
  sortOrder?: string
  excludedIds?: string[]
}

type FilesGroupsQueryStrict = {
  page: number
  pageSize: number
  limit: number
  offset: number
  sort: string
  sortOrder: string
  search: string
  sizeFrom: number
  sizeTo: number
  createFrom: number
  createTo: number
  isArchived: boolean
  contentType: string
}

type FilesGroupsQueryParams = {
  sizeFrom?: number
  sizeTo?: number
  createFrom?: number
  createTo?: number
  isArchived?: boolean
  contentType?: string
  page?: number
  ownersIds?: string
  search?: string
  fileMetaType?: FileMetaTypeFilterEnum
  ownerIds?: string
} & FilesGroupsQueryWeak

type IHistory = {
  items: (FilesGroupsFragment | FileMetaSchemaFragment)[]
}

const FILE_SIZE_UNIT = 1000000

export const useFilesGroupsParams = (
  tableName: string,
  defaultSort: string,
  filesLimit?: number | 'all',
  isNotArchived?: boolean,
) => {
  const history = useHistory<IHistory>()
  const { page, pageSize } = usePageSize(tableName)
  const { sort, sortOrder } = useSort(tableName, defaultSort)

  return useMemo(() => {
    const url = queryString.parse(history.location.search)
    const {
      isArchived,
      search,
      sizeFrom,
      sizeTo,
      createFrom,
      createTo,
      ownersIds,
      fileMetaType,
      contentType,
    } = url

    // let { contentType } = url

    let excludedIds
    // if (tableName === MODAL_MOVE_FINDER_TABLE_NAME) {
    //   contentType = FileMetaSearchEnum.categories
    //   const movingItems = history.location.state?.items

    //   excludedIds = movingItems
    //     ?.filter(({ __typename }) => FOLDER_TYPES.some((folderType) => folderType === __typename))
    //     .map(({ id }) => id)
    // }

    const offset = (page - 1) * pageSize

    const archived = getArchived(isArchived, isNotArchived)
    const createFromNum = convertToTimestamp(createFrom)
    const createToNum = convertToTimestamp(createTo)
    const sizeFromNum = typeof sizeFrom === 'string' ? Number(sizeFrom) : undefined
    const sizeToNum = typeof sizeTo === 'string' ? Number(sizeTo) : undefined
    const allEmployee = filesLimit === 'all'
    const limit = allEmployee ? undefined : filesLimit ? filesLimit : pageSize
    const type = contentType === 'all' ? undefined : contentType
    const fileMetaTypeData = fileMetaType
      ? fileMetaType
      : matchPath(history.location.pathname, { path: EDITOR_PATHS.main })
      ? 'projects'
      : 'companies'
    return {
      page,
      pageSize,
      limit,
      offset,
      sort,
      sortOrder,
      createFrom: createFromNum,
      createTo: createToNum,
      search,
      isArchived: archived,
      contentType: type,
      sizeFrom: sizeFromNum && sizeFromNum * FILE_SIZE_UNIT,
      sizeTo: sizeToNum && sizeToNum * FILE_SIZE_UNIT,
      ownersIds,
      fileMetaType: fileMetaTypeData,
      excludedIds,
    } as unknown as FilesGroupsQueryParams & FilesGroupsQueryStrict
  }, [history.location.search, isNotArchived, filesLimit, history.location, tableName])
}

export const useFilesGroupsSearchParams = (
  queryParams: FilesGroupsQueryParams & FilesGroupsQueryStrict,
) =>
  useMemo(
    () =>
      ({
        order: [[queryParams.sort, queryParams.sortOrder]],
        pagination: {
          limit: queryParams.limit,
          offset: queryParams.offset,
        },
        query: queryParams.search,
        isArchived: queryParams.isArchived,
        sizeFrom: queryParams.sizeFrom,
        sizeTo: queryParams.sizeTo,
        createFrom: queryParams.createFrom,
        createTo: queryParams.createTo,
        contentType: [queryParams.contentType],
        fileMetaType: queryParams.fileMetaType,
        ownersIds: queryParams.ownersIds,
        excludedIds: queryParams.excludedIds,
      }) as FileMetaGroupsSearch,
    [queryParams],
  )

const getArchived = (isArchived: string | (string | null)[] | null, isNotArchived?: boolean) => {
  if (isNotArchived) {
    return false
  }

  switch (isArchived) {
    case 'true':
      return true
    case 'false':
      return false
    case 'all':
      return undefined
  }
  return false
}
