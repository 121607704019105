import { SectionTypeEnum } from 'services/Store/Project/enums'
import { useProjectContext } from 'services/Store/Project/hooks'
import { getSectionId, getSections } from 'services/Store/Project/selectors'
import { useNavigationAction } from 'services/Store/Project/triggers'

import { useElementRootStyles } from '../../hooks/useElementRootStyles'
import { CourseStructureElementType } from './CourseStructure.types'
import s from './CourseStructureElement.module.scss'
import CourseStructureTree, {
  StructureElementDisplayConfig,
} from './CourseStructureTree/CourseStructureTree'

const CourseStructureElement: CourseStructureElementType = ({ styles, element, font }) => {
  const {
    value: {
      showDescription,
      showIcon,
      collapsePosition,
      progressPosition,
      initialOpen,
      sectionsDefault,
    },
  } = element
  const sectionId = useProjectContext(getSectionId)
  const sections =
    useProjectContext(getSections, [
      SectionTypeEnum.chapter,
      SectionTypeEnum.landing,
      SectionTypeEnum.test,
    ]) || sectionsDefault

  const onNavigate = useNavigationAction()

  const onSectionPick = (sectionId: string) => onNavigate({ sectionId })

  const rootStyles = useElementRootStyles(styles.root)

  const displayConfig: StructureElementDisplayConfig = {
    showDescription,
    showIcon,
    collapsePosition,
    progressPosition,
    initialOpen,
    activeId: sectionId,
    styles,
    font,
  }

  return (
    <div className={s.root} style={rootStyles}>
      <CourseStructureTree
        displayConfig={displayConfig}
        key={String(initialOpen)}
        onSectionPick={onSectionPick}
        onSelect={onSectionPick}
        //@ts-ignore TODO: 1373
        sections={sections}
        // eslint-disable-next-line react/forbid-component-props
        style={rootStyles}
      />
    </div>
  )
}

export default CourseStructureElement
