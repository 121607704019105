import lodash from 'lodash'

import {
  ProjectSchemaDeepFragment,
  ScormSettings,
  ScormVersionEnum,
  SuspendDataType,
} from 'gql/__generated__/graphql'

type WindowDefaultData = {
  project: ProjectSchemaDeepFragment
  settings: ScormSettings
  projectId: string
  version: ScormVersionEnum
}

export const getWindowData = (): WindowDefaultData => {
  // @ts-ignore
  const { settings, data, projectId, version } = window.defaultData || {}
  const settingsPrepared = lodash.cloneDeep(settings)
  if (!settingsPrepared?.data || !settingsPrepared.data.suspendDataType) {
    lodash.set(settingsPrepared, 'data', {
      suspendDataType: [SuspendDataType.navigation],
    })
  }

  return { project: data?.data, settings, projectId, version }
}
