import { CONFIG_ELEMENTS } from '@leenda/editor/lib/elements'

import EditableProMode from 'components/editor-v3/cource/layout/controls/ElementControls/EditableProMode'
import { t } from 'services/Translation'

import { ElementGroup } from '../../elements.types'
import { genElementConfig } from '../../genElementConfig'
import { STYLE_FORM_SCHEMA } from './TestHotspotBrandFormSchema'
import Demo from './TestHotspotDemo'
import Element from './TestHotspotElement'
import Form from './TestHotspotForm'
import testHotspot from './assets/hotspot.svg'

export default genElementConfig(CONFIG_ELEMENTS.testHotspot, {
  label: t('elements.testHotspot.label'),
  icon: testHotspot,
  group: ElementGroup.test,
  Element,
  ValueForm: Form,
  STYLE_FORM_SCHEMA,
  FONT_FORM_SCHEMA: {},
  EditorActions: EditableProMode,
  Demo,
})
