import { DraggableStateSnapshot, DraggingStyle, NotDraggingStyle } from '@hello-pangea/dnd'
import { TestMatchingSchemaType } from '@leenda/editor/lib/brand'
import { MatchingItem } from '@leenda/editor/lib/elements'
import { useMemo } from 'react'

import { ElementStyleCss } from 'services/Branding/types'
import { shuffle } from 'utils/shuffle'

import { MatchingGroups, TestMatchingStateType } from './TestMatchingElement.types'

export function getStyle(
  snapshot: DraggableStateSnapshot,
  style?: DraggingStyle | NotDraggingStyle,
  offsetW?: number,
  schemaStyles?: ElementStyleCss<TestMatchingSchemaType>,
) {
  let styles = {} as {
    [key: string]: any
  }

  if (snapshot.isDragging && style) {
    const draggingStyle = style as DraggingStyle
    const checkedTransform = draggingStyle.transform || ''
    styles = {
      ...styles,
      width: draggingStyle.width,
      height: draggingStyle.height,
      transform: `${checkedTransform}`,
      transformOrigin: `left top`,
      ...schemaStyles?.root,
    }
  }
  if (!snapshot.isDropAnimating || !snapshot.dropAnimation) {
    return styles
  }
  const { moveTo, curve, duration } = snapshot.dropAnimation
  const translate = `translate(${moveTo.x}px, ${moveTo.y}px)`
  styles = {
    ...styles,
    transform: translate,
    // slowing down the drop because we can
    transition: `all ${curve} ${duration}s`,
  }

  if (snapshot.draggingOver) {
    const translate = `translate(${moveTo.x - (offsetW || 0) / 2 + 20}px, ${moveTo.y}px)`
    styles.transform = translate
  }

  return styles
}

export const mapElements = (
  items: MatchingItem[],
  side: 'left' | 'right' = 'left',
): MatchingGroups[] => {
  return items.map((item) => ({
    value: item.value,
    isActive: false,
    label: side === 'left' ? item.leftLabel : item.rightLabel,
  }))
}

export const useGetLeftRightItems = (
  isViewMode: boolean,
  items: MatchingItem[],
  elementState?: TestMatchingStateType,
) => {
  // shuffle elements only on first mount(we haven`t elementState yet) and only in view(preview)
  const { left, right } = useMemo((): { left: MatchingGroups[]; right: MatchingGroups[] } => {
    if (isViewMode) {
      if (!elementState?.value && elementState?.initialItems) {
        return {
          left: elementState.initialItems,
          right: mapElements(items || [], 'right'),
        }
      }
      if (!elementState?.value) {
        return {
          left: shuffle(mapElements(items || [])),
          right: mapElements(items || [], 'right'),
        }
      }
      return {
        left: elementState.shuffledItems || [],
        right: mapElements(items || [], 'right'),
      }
    }
    return {
      left: mapElements(items || []),
      right: mapElements(items || [], 'right'),
    }
  }, [elementState, items])
  return { left, right }
}

export const useGetSize = (
  state: { width: number; height: number },
  wrapperRef: React.MutableRefObject<HTMLDivElement | null>,
  items: MatchingItem[],
  paddings: number,
) => {
  const width = state?.width || 200
  const height = useMemo(() => {
    if (state.width && wrapperRef.current) {
      const els = wrapperRef.current.querySelectorAll('#itemWrapper')
      if (els) {
        return (
          Array.from(els).reduce((res, e) => {
            const content = e.querySelector('#itemContent')
            if (content) {
              const rec = content.getBoundingClientRect()
              if (rec.height > res) {
                return rec.height
              }
            }
            return res
          }, 0) + paddings
        )
      }
    }
    return
  }, [state.width, wrapperRef, items, paddings])
  return { width, height }
}
