import { gql } from 'gql/__generated__'

export const ssoConfigFragment = gql(`
  fragment SsoConfigSchema on SsoConfig {
    id
    ssoRequired
    emailAttribute
  }
`)

export const companyWithoutAccessFragment = gql(`
  fragment CompanyWithoutAccessSchema on CompanyWithoutAccess {
    id
    name
    logo {
      ...FileMetaSchema
    }
    slug
    isArchived
    createdAt
    updatedAt
  }
`)

export const companySmallFragment = gql(`
  fragment CompanySmallSchema on Company {
    id
    name
    logo {
      ...FileMetaSchema
    }
    slug
    isArchived
    createdAt
    updatedAt
    contractType
    ssoConfig {
      ...SsoConfigSchema
    }
    employees {
      id
      kUser {
        id
        name
        avatar {
          ...AvatarSchema
        }
        email
      }
    }
    employeeCount
  }
`)

export const adminCompanyFragment = gql(`
  fragment CompanyAdminSchema on Company {
    owner {
      ...EmployeeSchema
    }
    availableEmployees
    availableStorage
    availableExports
    contractType
    contractActiveTill
    projectsCount
    exports
    employeeCountNonLeenda
    overallResources {
      company
      project
      pdf
      scorm
      total
    }
    throwLimit
    aiConfigs {
      ...AiConfigSchema
    }
  }
`)

export const companyFragment = gql(`
  fragment CompanySchema on Company {
    id
    name
    email
    logo {
      ...FileMetaSchema
    }
    owner {
      ...EmployeeSchema
    }
    availableEmployees
    contractType
    contractActiveTill
    employees {
      #id
      ...EmployeeSmallSchema
    }
    color
    description
    legalId
    createdAt
    createdBy
    phone
    domainName
    updatedAt
    employeeCount
    showGeneralTemplates
    locale
    employeeCountNonLeenda
    overallResources {
      company
      project
      pdf
      scorm
      total
    }
    availableStorage
    availableExports
    exports
    throwLimit
    ssoConfig {
      ...SsoConfigSchema
    }
    ssoAttributes {
      acsLocation
      entityId
    }
    slug
    aiConfigs {
      ...AiConfigSchema
    }
    lmsIntegrations {
      ...LmsIntegrationSchema
    }
    showExternalImages
  }
`)

export const companyAdminFullFragment = gql(`
  fragment CompanyAdminFullSchema on Company {
    ...CompanySchema
    ...CompanyAdminSchema
    isArchived
  }
`)

export const companyInfoFragment = gql(`
  fragment CompanyInfoSchema on CompanyInfo {
    user {
      id
      name
      email
    }
    company {
      id
      name
      logo {
        ...FileMetaSchema
      }
      contractType
      contractActiveTill
      availableStorage
      overallResources {
        total
      }
      aiConfigs {
        ...AiConfigSchema
      }
    }
    role
    permissionGroups
    employee {
      ...EmployeeSchema
    }
  }
`)

export const getMyUserInfoQuery = gql(`
  query CompanyGetMyInfo($companyId: ID!) {
    data: getMyAndCompanyInfo(companyId: $companyId) {
      ...CompanyInfoSchema
    }
  }
`)

export const companyGetByIdQuery = gql(`
  query CompanyGet($companyId: ID!) {
    data: companiesGetById(companyId: $companyId) {
      ...CompanySchema
    }
  }
`)

export const companyGetByIdAdminQuery = gql(`
  query CompanyGetAdmin($companyId: ID!) {
    data: companiesGetById(companyId: $companyId) {
      ...CompanySchema
      ...CompanyAdminSchema
      isArchived
    }
  }
`)

export const companyListFragment = gql(`
  fragment CompanyListSchema on Company {
    id
    employees {
      #id
      ...EmployeeSmallSchema
    }
    kUsers {
      id
    }
    logo {
      ...FileMetaSchema
    }
    name
    ownerId
    color
    isConstractor
    legalId
    contracts
    description
    isArchived
    createdBy
    phone
    domainName
    email
    createdAt
    updatedAt
    employeeCount
    showGeneralTemplates
    locale
    ...CompanyAdminSchema
    slug
  }
`)

export const companyGetAllQuery = gql(`
  query CompaniesAll($payload: CompaniesAllInput!) {
    data: companiesAll(payload: $payload) {
      companies {
        ...CompanySmallSchema
        ...CompanyWithoutAccessSchema
      }
      total
      limit
      offset
    }
  }
`)

export const companyGetAllAdmin = gql(`
  query CompaniesAllAdmin($payload: CompaniesAllInput!) {
    data: companiesAll(payload: $payload) {
      companies {
        ...CompanyAdminFullSchema
        ...CompanyWithoutAccessSchema
      }
      total
      limit
      offset
    }
  }
`)

export const companyGetAllShort = gql(`
  query CompaniesAllShort($payload: CompaniesAllInput!) {
    data: companiesAll(payload: $payload) {
      companies {
        ...CompanySmallSchema
        ...CompanyWithoutAccessSchema
      }
      total
      limit
      offset
    }
  }
`)

export const companySubscribe = gql(`
  subscription CompanySubscribe($userId: String!) {
    data: company(userId: $userId) {
      company {
        ...CompanyListSchema
      }
      type
    }
  }
`)

export const companyCreate = gql(`
  mutation CompanyCreate($payload: CompanyInput!) {
    data: companiesCreate(data: $payload) {
      data {
        ...CompanyListSchema
      }
      errors {
        ...ErrorSchema
      }
    }
  }
`)

export const companyUpdate = gql(`
  mutation CompanyUpdateById($companyId: ID!, $data: CompanyUpdateInput!) {
    data: companiesUpdateById(companyId: $companyId, data: $data) {
      data {
        ...CompanyListSchema
      }
      errors {
        ...ErrorSchema
      }
    }
  }
`)

export const companyForceDelete = gql(`
  mutation CompanyForceDeleteById($companyId: ID!, $data: CompanyDeleteInput!) {
    data: companiesForceDeleteById(companyId: $companyId, data: $data)
  }
`)

export const companiesDelete = gql(`
  mutation CompaniesDelete($companyIds: [ID!]!) {
    data: companiesDelete(companyIds: $companyIds)
  }
`)

export const createSsoConfig = gql(`
  mutation CreateSsoConfig($companyId: ID!, $data: CreteSsoConfigInput!) {
    data: createSsoConfig(companyId: $companyId, data: $data) {
      id
      ssoConfig {
        ...SsoConfigSchema
      }
    }
  }
`)
export const deleteSsoConfig = gql(`
  mutation DeleteSsoConfig($companyId: ID!) {
    data: deleteSsoConfig(companyId: $companyId) {
      id
      ssoConfig {
        ...SsoConfigSchema
      }
    }
  }
`)

export const updateSsoConfig = gql(`
  mutation UpdateSsoConfig($companyId: ID!, $data: UpdateSsoConfigInput!) {
    data: updateSsoConfig(companyId: $companyId, data: $data) {
      id
      ssoConfig {
        ...SsoConfigSchema
      }
    }
  }
`)

export const companySlug = gql(`
  query GetCompanySlug ($companyId: ID!) {
    data: getSlug(companyId: $companyId)
  }
`)
