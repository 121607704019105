export enum ParticipantsPageActionUiEnum {
  ADD = 'ADD',
  INVITE = 'INVITE',
}

export enum ParticipantsTableActionUiEnum {
  CHANGE = 'CHANGE',
  DELETE = 'DELETE',
  REASSIGN_OWNER = 'REASSIGN_OWNER',
}
