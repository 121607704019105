import cn from 'classnames'
import lodash from 'lodash'
import React, { useCallback, useMemo, useState } from 'react'
import { matchPath, useHistory } from 'react-router'

import { PATHS } from 'constants/paths'

import s from './Banner.module.scss'
import LicenseExpiration from './LicenseExpiration'
import Release from './Release'

type RoutineMaintenanceProps = {
  children: React.ReactNode
}

export enum BannerName {
  release = 'release',
  licenseExpiration = 'licenseExpiration',
}

const CONFIG = {
  [BannerName.release]: {
    component: Release,
    order: 0,
  },
  [BannerName.licenseExpiration]: {
    component: LicenseExpiration,
    order: 1,
  },
}

type BannerData<BN extends BannerName = BannerName> = {
  name: BN
  id: string
  props: Parameters<(typeof CONFIG)[BN]['component']>[0]
}

type BannerContextType = {
  banners: BannerData[]
  add: (banner: BannerData) => void
  remove: (id: string) => void
}

const BannerContext = React.createContext<BannerContextType>({
  banners: [],
  add: () => undefined,
  remove: () => undefined,
})

export const useBannerContext = () => React.useContext(BannerContext)

const Banner = ({ children }: RoutineMaintenanceProps) => {
  const { location } = useHistory()
  const match = matchPath(location.pathname, PATHS.public)
  const isPublic = match !== null

  const [banners, setBanners] = useState<BannerData[]>([])
  const add = useCallback(
    (bannerData: BannerData) => {
      setBanners((prevState) => [...prevState.filter((b) => b.id !== bannerData.id), bannerData])
    },
    [setBanners],
  )
  const remove = useCallback(
    (id: string) => {
      setBanners((prevState) => prevState.filter((b) => b.id !== id))
    },
    [setBanners],
  )
  const value = useMemo<BannerContextType>(
    () => ({
      banners,
      add,
      remove,
    }),
    [banners, setBanners],
  )
  const banner = lodash.sortBy(banners, (banner) => CONFIG[banner.name].order).pop()
  const Banner = banner && CONFIG[banner?.name]?.component

  return (
    <div className={s.root}>
      <BannerContext.Provider value={value}>
        <div className={cn(s.wrapperNotification, { [s.open]: Boolean(Banner) })}>
          {Banner && !isPublic && <Banner {...(banner.props as any)} />}
        </div>
        <div className={cn(s.wrapperChildren)}>{children}</div>
      </BannerContext.Provider>
    </div>
  )
}

export default Banner
