import React, { useCallback, useMemo } from 'react'

import { deleteMenuItem, editMenuItem } from 'components/comments/actions'
import { CommentActionUiEnum, CommentMock } from 'components/comments/types'
import Employee, { AccountMock } from 'components/uiKit/Employee'
import { useHover } from 'components/uiKit/KitUtils'
import { IMenuOption } from 'components/uiKit/Menu'
import { useCommentPermissions } from 'services/Permission/PermissionContext'
import { selectCurrentEmployee } from 'services/Store/Users/selectors'
import { useAppSelector } from 'services/Store/hooks'

import { NameRow, CommentText, Actions, ICommentTextRef } from '../components'
import s from './FeedComment.module.scss'

interface IFeedCommentProps {
  comment: CommentMock
  mentions: AccountMock[]
}

const FeedComment: React.FC<IFeedCommentProps> = ({ comment, mentions }) => {
  const permissions = useCommentPermissions(comment)
  const textRef = React.useRef<ICommentTextRef>(null)
  const [showActions, handlers] = useHover()
  const me = useAppSelector(selectCurrentEmployee) || null

  const options = useMemo(
    () => [
      editMenuItem({ comment, permissions, me }),
      deleteMenuItem({ comment, permissions, me }),
    ],
    [comment, permissions, me],
  )

  const handleClick = useCallback((item: IMenuOption<CommentActionUiEnum>) => {
    if (item.value === CommentActionUiEnum.EDIT) {
      textRef.current?.edit?.()
    }
  }, [])

  return (
    <div {...handlers} className={s.root}>
      <div className={s.line} />
      <div className={s.title}>
        <Employee employee={comment.createdBy} styleType='avatar' />
        <NameRow comment={comment} />
        <Actions comment={comment} onClick={handleClick} options={options} show={showActions} />
      </div>
      <CommentText comment={comment} mentions={mentions} ref={textRef} />
    </div>
  )
}

export default React.memo(FeedComment)
