import { gql } from './__generated__'

export const CommentatorsFragment = gql(`
  fragment CommentatorsSchema on Commentator {
    employee {
      ...EmployeeSmallSchema
    }
    expiredAt
  }
`)

export const employeePreferencesFragment = gql(`
  fragment EmployeePreferencesSchema on EmployeePreferences {
    templates {
      tab
      editorTemplateGroupId
      editorTemplateGroupName
      tag
      order {
        field
        value
      }
    }
    finder {
      tab
      folder
    }
  }
`)

export const projectListFragment = gql(`
  fragment ProjectListSchema on Project {
    id
    name
    tags
    owner {
      ...EmployeeSmallSchema
    }
    isDone
    learningObjectives
    learningGoals
    endDate
    startDate
    accent
    projectGroupId
    updatedAt
    analytics {
      tasksDone
      tasksTotal
      tasksExpired
    }
    createdBy {
      ...EmployeeSmallSchema
    }
    publicLink {
      ...PublicLinkSchema
    }
    isPublic
    passOrder
    passPercentage
    availability
    locale
    localeSettingsName
    localeSettings
    createdAt
  }
`)

export const projectFragment = gql(`
  fragment ProjectSchema on Project {
    id
    companyId
    projectGroupId
    tags
    me {
      id
      employee {
        ...EmployeeSmallSchema
      }
      role
    }
    employee {
      ...EmployeeListSchema
    }
    owner {
      ...EmployeeListSchema
    }
    sectionsOrder {
      ...SectionOrderSchema
    }
    accent
    name
    startDate
    endDate
    updatedAt
    learningObjectives
    learningGoals
    projectGroupId
    employee {
      ...EmployeeSmallSchema
    }
    publicLink {
      ...PublicLinkSchema
    }
    createdAt
    isDone
    brand {
      id
      schema
      theme
      font
      palette
      labels
      files {
        ...FileMetaSchema
      }
    }
    isPublic
    passOrder
    passPercentage
    availability
    locale
    localeSettingsName
    localeSettings
    employeePreferences {
      ...EmployeePreferencesSchema
    }
    commentators {
      ...CommentatorsSchema
    }
  }
`)

export const projectFragmentDeep = gql(`
  fragment ProjectSchemaDeep on Project {
    id
    isPublic
    publicLink {
      ...PublicLinkSchema
    }
    companyId
    projectGroupId
    owner {
      ...EmployeeSmallSchema
    }
    sectionsOrder {
      ...SectionOrderSchema
    }
    sections {
      ...SectionWithBlocksSchema
    }
    hasExcludeItems
    accent
    name
    endDate
    updatedAt
    learningObjectives
    learningGoals
    projectGroupId
    company {
      logo {
        ...FileMetaSchema
      }
      contractType
    }
    brand {
      id
      schema
      theme
      font
      palette
      labels
      files {
        ...FileMetaSchema
      }
    }
    fontsGroups {
      ...FontGroupFullSchema
    }
    passOrder
    passPercentage
    availability
    locale
    localeSettingsName
    localeSettings
    employeePreferences {
      ...EmployeePreferencesSchema
    }
  }
`)

export const projectDeepQuery = gql(`
  query ProjectsDeepGetById(
    $projectId: String!
    $data: ManagePublicLink
    $excludeStash: Boolean = true
    $excludeHide: Boolean = false
  ) {
    data: projectsGetById(
      projectId: $projectId
      data: $data
      excludeStash: $excludeStash
      excludeHide: $excludeHide
    ) {
      ...ProjectSchemaDeep
    }
  }
`)

export const projectsGetByIdQuery = gql(`
  query ProjectsGetById($projectId: String!) {
    data: projectsGetById(projectId: $projectId) {
      ...ProjectSchema
    }
  }
`)

export const meInProjectQuery = gql(`
  query MeInProject($projectId: String!) {
    data: projectsGetById(projectId: $projectId) {
      id
      me {
        id
        employee {
          ...EmployeeSmallSchema
        }
        role
        permissionGroups
      }
    }
  }
`)

export const createProject = gql(`
  mutation ProjectsCreate($companyId: String!, $data: ProjectCreateInput!) {
    data: projectsCreate(companyId: $companyId, data: $data) {
      data {
        ...ProjectListSchema
      }
      errors {
        ...ErrorSchema
      }
    }
  }
`)

export const updateProject = gql(`
  mutation ProjectsUpdateById($projectId: String!, $data: ProjectUpdateInput!) {
    data: projectsUpdateById(projectId: $projectId, data: $data) {
      data {
        ...ProjectSchema
      }
      errors {
        ...ErrorSchema
      }
    }
  }
`)

export const projectsUpdateLocale = gql(`
  mutation ProjectsUpdateLocale($projectId: ID!, $data: ProjectUpdateLocale!) {
    data: projectsUpdateLocaleById(projectId: $projectId, data: $data) {
      ...ProjectSchema
    }
  }
`)

export const projectUpdateOwner = gql(`
  mutation ProjectUpdateOwner($projectId: String!, $ownerId: String!) {
    data: projectUpdateOwner(projectId: $projectId, ownerId: $ownerId) {
      ...ProjectSchema
    }
  }
`)

export const projectsUpdateOwners = gql(`
  mutation ProjectsUpdateOwners($data: [UpdateOwnersData!]!) {
    data: projectsUpdateOwners(data: $data) {
      ...ProjectSchema
    }
  }
`)

export const projectEmployeePreferences = gql(`
  mutation ProjectEmployeePreferences($projectId: ID!, $settings: JSON!) {
    data: projectEmployeePreferencesUpdate(projectId: $projectId, settings: $settings) 
  }
`)
