import { HeaderSchemaType } from '@leenda/editor/lib/brand'
import cn from 'classnames'
import { motion } from 'framer-motion'
import React from 'react'

import { Tooltip } from 'components/uiKit/Dropdown'
import { ElementStyleCss } from 'services/Branding/types'
import { useProjectT } from 'services/ProjectLabels'

import s from './MenuOpenIcon.module.scss'

interface IMenuOpenIconProps {
  toggleSidebar: () => void
  isDisabledSidebarAndHome?: boolean
  styles: ElementStyleCss<HeaderSchemaType>
  isFloatingBurger?: boolean
  positionRight?: boolean
  isOpen?: boolean
}

const ANIMATION_VARIANTS = {
  open: {
    top: {
      top: 13,
      rotate: 45,
      transition: { top: { duration: 0.3 }, rotate: { duration: 0.3, delay: 0.3 } },
    },
    middle: { top: 13, opacity: 0, transition: { opacity: { duration: 0.3 } } },
    bottom: {
      width: 15,
      top: 13,
      rotate: -45,
      transition: { top: { duration: 0.3 }, rotate: { duration: 0.3, delay: 0.3 } },
    },
  },
  closed: {
    top: {
      top: 8,
      rotate: 0,
      transition: { top: { duration: 0.3, delay: 0.3 }, rotate: { duration: 0.3 } },
    },
    middle: { top: 13, opacity: 1, transition: { opacity: { duration: 0.3, delay: 0.3 } } },
    bottom: {
      width: 8,
      top: 18,
      rotate: 0,
      transition: { top: { duration: 0.3, delay: 0.3 }, rotate: { duration: 0.3 } },
    },
  },
}

const MenuOpenIcon: React.FC<IMenuOpenIconProps> = ({
  toggleSidebar,
  isDisabledSidebarAndHome,
  styles,
  isFloatingBurger,
  positionRight,
  isOpen,
}) => {
  const pt = useProjectT()
  const burgerIconBg = styles.burgerIcon.backgroundImage
  const closeIconBg = styles.crossIcon.backgroundImage
  const showCustomIcon = (burgerIconBg && !isOpen) || (closeIconBg && isOpen)

  return (
    <div
      className={cn(
        s.root,
        isFloatingBurger && s.floatingButton,
        positionRight && s.positionRight,
        isDisabledSidebarAndHome && s.disabled,
      )}
      onClick={() => !isDisabledSidebarAndHome && toggleSidebar()}
    >
      <Tooltip disabled={isDisabledSidebarAndHome} overlay={pt('header.courseContent')}>
        {!showCustomIcon && (
          <div className={s.content}>
            <motion.div
              animate={isOpen ? ANIMATION_VARIANTS.open.top : ANIMATION_VARIANTS.closed.top}
              className={cn(s.line, s.positionRight && s.rightLine)}
              initial={false}
            />
            <motion.div
              animate={isOpen ? ANIMATION_VARIANTS.open.middle : ANIMATION_VARIANTS.closed.middle}
              className={s.line}
              initial={false}
            />
            <motion.div
              animate={isOpen ? ANIMATION_VARIANTS.open.bottom : ANIMATION_VARIANTS.closed.bottom}
              className={s.line}
              initial={false}
            />
          </div>
        )}
        {showCustomIcon && (
          <div
            className={s.customMenu}
            style={{ backgroundImage: isOpen ? closeIconBg : burgerIconBg }}
          />
        )}
      </Tooltip>
    </div>
  )
}

export default MenuOpenIcon
