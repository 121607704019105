"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_VALUE = exports.TYPE = void 0;
const id_1 = require("../../../utils/id");
exports.TYPE = 'testKeyboardInput';
exports.DEFAULT_VALUE = {
    items: [
        {
            label: '',
            points: 1,
            value: (0, id_1.generateId)(),
        },
    ],
};
