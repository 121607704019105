import React from 'react'
import { FileTypeEnum } from 'utils'

import Icon, { KeyIconsType } from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'
import { getFinderRecordName } from 'components/uiKit/Table/utils'
import { FileMetaSchemaFragment } from 'gql/__generated__/graphql'
import { FinderFileItem, FinderItem } from 'store/models'
import { testProps } from 'utils/test/qaData'
import { getTypeFile } from 'utils/useFinder'

import s from './FileComponent.module.scss'

interface IFileComponentProps {
  file: FinderFileItem
  onClick?: (item: FinderItem) => void
}

const ICON_MAP: { [key in FileTypeEnum]: KeyIconsType } = {
  [FileTypeEnum.IMAGE]: 'otherImage',
  [FileTypeEnum.AUDIO]: 'iconsOtherAudio',
  [FileTypeEnum.VIDEO]: 'iconsOtherVideo',
  [FileTypeEnum.APPLICATION]: 'otherFile',
  [FileTypeEnum.TEXT]: 'otherFile',
  [FileTypeEnum.FONT]: 'otherFile',
  [FileTypeEnum.TRANSLATE]: 'otherFile',
}

const FileComponent: React.FC<IFileComponentProps> = ({ file, onClick }) => {
  const fileMeta = file as FileMetaSchemaFragment
  const type = getTypeFile(fileMeta.type) || FileTypeEnum.APPLICATION
  const name = getFinderRecordName(file)
  const iconName = ICON_MAP[type]

  return (
    <div className={s.root} onClick={() => onClick?.(file)} {...testProps({ el: 'file', name })}>
      {file.img && type === FileTypeEnum.IMAGE ? (
        <div className={s.previewImg} style={{ backgroundImage: `url(${file.img})` }} />
      ) : (
        <Icon className={s.icon} name={iconName} size={KitSize.S} />
      )}
      <div className={s.content}>
        <div className={s.title}>{file.nodeName || name}</div>
      </div>
    </div>
  )
}

export default FileComponent
