import { createSelector } from '@reduxjs/toolkit'
import * as R from 'ramda'

import { INTERACTIVE_MODES } from 'services/Store/Project/constants'
import { IProjectContext, Block } from 'services/Store/Project/types'
import { notEmpty } from 'utils/notEmpty'

import { getSection, getBlock } from '..'
import { DeviceMode } from '../../enums'

const getBlockWidth = (block: Block) =>
  R.pathOr<number | null>(
    null,
    ['schema', 'nodes', block.schema.rootId, 'style', DeviceMode.desktop, 'maxWidth'],
    block,
  )

export const getDeviceMode = ({ state }: IProjectContext) => state.deviceMode
export const getPreviewMode = ({ mode }: IProjectContext) => mode

export const getEditorMode = ({ state }: IProjectContext) => state.mode
export const getEditorHighlight = ({ state }: IProjectContext) => state.editor.highlight

export const getToolbar = ({ state }: IProjectContext) => state.editor.toolbar

export const getNavBar = ({ state }: IProjectContext) => state.editor.navBar

export const getIsInteractiveMode = ({ mode }: IProjectContext) => INTERACTIVE_MODES.includes(mode)
export const getIsActiveNode = ({ urlParams: url }: IProjectContext, nodeId: string) =>
  url.nodeId === nodeId
export const getIsHighlighted = ({ state }: IProjectContext, nodeId: string | null) =>
  Boolean(nodeId && state.editor.highlight === nodeId)
export const isBlockClosed = (state: IProjectContext) => {
  const section = getSection(state)
  const block = getBlock(state)
  return section?.isDone || block?.isDone
}

export const getCurrentBlockWidth = (state: IProjectContext) => {
  const block = getBlock(state)
  return block ? getBlockWidth(block) : null
}

export const getIsActiveBlock = ({ urlParams }: IProjectContext, id: string) =>
  urlParams.blockId === id
export const getSelectedBlocksCount = ({ state }: IProjectContext) =>
  state.editor.selectedBlocks.length

export const getSelectedBlocksIds = createSelector(
  ({ state }: IProjectContext) => state.editor.selectedBlocks,
  (state: IProjectContext) => getSection(state),
  (_: IProjectContext, saveOrder?: boolean) => saveOrder,
  (selectedBlocks, section, saveOrder) =>
    saveOrder && section
      ? section.blocksOrder?.filter((id) => selectedBlocks.includes(id))
      : selectedBlocks,
)
export const getSelectedBlocks = createSelector(
  getSelectedBlocksIds,
  ({ data }: IProjectContext) => data.blocks,
  (ids, blocks) => ids.map((id) => blocks[id]).filter(notEmpty),
)
export const getIsSelectedBlock = ({ state }: IProjectContext, id: string) =>
  state.editor.selectedBlocks.includes(id)
export const isBlocksMultiSelect = (context: IProjectContext) => {
  return context.state.editor.selectedBlocks.length > 1
}

export const getIsLastSelected = ({ state }: IProjectContext, id: string) =>
  R.last(state.editor.selectedBlocks) === id
export const getSettings = ({ state }: IProjectContext) => state.editor.settings
