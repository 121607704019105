import * as R from 'ramda'

import { AvailabilityEnum, PassOrderEnum, TestSchemaFragment } from 'gql/__generated__/graphql'
import { IProjectContext } from 'services/Store/Project/types'

import { BlockMode } from '../enums'
import {
  getSectionElementsState,
  getSection,
  getProject,
  getBlock,
  getBlockState,
  getTestEndBlock,
  getBlocksMap,
  getTestPercent,
  getSectionState,
} from '../selectors'
import { shuffleQuestions } from '../shuffleQuestions'
import { clearBlocks, updateSection, updateNavigation, updateBlockState } from './common'
import { clearElements } from './element'

export const checkPassedTestSection = (
  state: IProjectContext,
  sectionId: string,
  test?: TestSchemaFragment | null,
) => {
  const project = getProject(state)
  const passPercent = getTestPercent(state, sectionId)
  const sectionState = getSectionState(state, { id: sectionId })

  const isCorrectAvailability = project?.availability === AvailabilityEnum.correct
  const hasPassedThreshold = passPercent && passPercent >= (test?.testThreshold || 0)
  const isPassedAvailability = project?.availability === AvailabilityEnum.passed
  const isFreePassOrder = project?.passOrder === PassOrderEnum.free

  if (isCorrectAvailability && hasPassedThreshold) {
    return true
  }

  if (isPassedAvailability || isFreePassOrder) {
    return true
  }

  return sectionState?.passed ?? false
}

export const testStart = (state: IProjectContext) => {
  const { id: sectionId } = getSection(state) || {}
  const block = getBlock(state, { next: true })

  if (sectionId && block) {
    return R.compose(
      updateNavigation({ sectionId, blockId: block.uuid }),
      updateSection(sectionId, { startTime: Date.now(), incTry: true, status: 'inProgress' }),
    )(state)
  }

  return state
}

export const testEnd = (state: IProjectContext) => {
  const { id: sectionId, blocks = [], test } = getSection(state) || {}
  const fillResultAllBlocks = blocks.map((block) => {
    const { result } = getBlockState(state, block.uuid) || {}
    return updateBlockState(block.uuid, {
      ...(block.mode === BlockMode.questions && {
        result: result || { isValid: false, points: 0 },
      }),
    })
  })
  const endBlock = getTestEndBlock(state, sectionId)
  const passed = checkPassedTestSection(state, sectionId || '', test)

  if (sectionId) {
    return R.compose(
      endBlock ? updateNavigation({ sectionId, blockId: endBlock.uuid }) : R.identity,
      //@ts-ignore
      ...fillResultAllBlocks,
      updateSection(sectionId, {
        endTime: Date.now(),
        status: 'finished',
        passed,
      }),
    )(state) as IProjectContext
  }

  return state
}

export const testRestart = (state: IProjectContext) => {
  const { id: sectionId, test } = getSection(state) || {}
  const { blocksOrder = [] } = state.data.sections[sectionId || ''] || {}
  const { isShuffleQuestions, randomCount } = test || {}
  //@ts-ignore
  const blocks = R.values(R.pick(blocksOrder, getBlocksMap(state)) || {})
  const passed = checkPassedTestSection(state, sectionId || '', test)

  const order = isShuffleQuestions
    ? shuffleQuestions(blocks, randomCount || blocks.length)
    : blocksOrder

  if (sectionId) {
    const elementsIds = R.keys(getSectionElementsState(state, sectionId))
    return R.compose(
      updateNavigation({ sectionId, blockId: R.head(blocksOrder) }),
      updateSection(sectionId, {
        status: 'notStarted',
        order,
        startTime: null,
        endTime: null,
        passed,
      }),
      clearBlocks(blocksOrder),
      clearElements(elementsIds),
    )(state)
  }

  return state
}
