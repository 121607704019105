import { useMemo } from 'react'

let canvas: HTMLCanvasElement | null = null

let ctx: CanvasRenderingContext2D | null = null
export const useTextWidth = (node: HTMLInputElement | null, text: string) => {
  if (!canvas) {
    canvas = document.createElement('canvas')
    canvas.width = 1000
    canvas.height = 50
    ctx = canvas.getContext('2d')
  }
  return useMemo(() => {
    if (ctx) {
      const style = node && window.getComputedStyle(node, null)
      const fontWeight = style?.getPropertyValue('font-weight') || 'normal'
      const fontSize = style?.getPropertyValue('font-size') || '16px'
      const fontFamily = style?.getPropertyValue('font-family') || 'Arial'
      ctx.font = `${fontWeight} ${fontSize} ${fontFamily}`
      return ctx?.measureText(text).width
    }
    return 0
  }, [node, text])
}
