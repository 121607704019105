import { CONFIG_COURSE } from '@leenda/editor/lib/elements'
import cn from 'classnames'
import { ReactNode } from 'react'

import { useElementStyleCss } from 'services/Branding/hooks'
import { DeviceMode } from 'services/Store/Project/enums'

import s from './TestAnswerFeedback.module.scss'
import { ReactComponent as Fail } from './fail.svg'
import { ReactComponent as Success } from './success.svg'

type TestAnswerFeedbackProps = {
  deviceMode: DeviceMode
  children: ReactNode
  isVisible?: boolean | null
  isTrueAnswer?: boolean
  showAnswerForCurrentElement?: boolean
}

export const TestAnswerFeedback = ({
  deviceMode,
  isVisible = false,
  isTrueAnswer = false,
  children,
  showAnswerForCurrentElement,
}: TestAnswerFeedbackProps) => {
  const styles = useElementStyleCss(CONFIG_COURSE.testAnswerFeedback.type, deviceMode)

  let iconComponent = null
  if (isTrueAnswer) {
    if (styles.correct.backgroundImage) {
      iconComponent = <div className={s.image} style={styles.correct} />
    } else {
      iconComponent = <Success />
    }
  } else {
    if (styles.incorrect.backgroundImage) {
      iconComponent = <div className={s.image} style={styles.incorrect} />
    } else {
      iconComponent = <Fail />
    }
  }

  return (
    <div className={s.root} style={styles.root}>
      <div className={cn(s.status, { [s.visible]: isVisible })}>
        {showAnswerForCurrentElement && iconComponent}
      </div>
      <div className={s.content}>{children}</div>
    </div>
  )
}
