import { NavButtonFontSchemaType, NavButtonSchemaType } from '@leenda/editor/lib/brand'
import { ButtonElementValue } from '@leenda/editor/lib/elements'
import cn from 'classnames'
import { useCallback } from 'react'

import { Properties } from 'components/editor-v2/EditorElements/elements.types'
import { ButtonElementType } from 'components/editor-v2/EditorElements/elements/ButtonElement/ButtonElement.types'
import { useElementRootStyles } from 'components/editor-v2/EditorElements/hooks/useElementRootStyles'
import { NavButtonStyles } from 'components/editor-v3/cource/controls/LandingFooterNavigation/helpers'
import { ElementFontCss, ElementStyleCss } from 'services/Branding/types'
import { useProjectContext } from 'services/Store/Project/hooks'
import { getIsInteractiveMode } from 'services/Store/Project/selectors'
import { testProps, testPropsEl } from 'utils/test/qaData'

import s from './BaseNavigationButton.module.scss'

interface IBaseButtonElementProps extends Pick<Properties<ButtonElementType>, 'mode'> {
  label?: string
  disabled?: boolean
  onClick?: () => void
  icon?: React.ReactElement | null
  action?: ButtonElementValue['action']
  fonts?: ElementFontCss<NavButtonFontSchemaType>
  styles: {
    root?: ElementStyleCss<NavButtonSchemaType>['root']
    button: NavButtonStyles
  }
  iconPosition: 'left' | 'right'
}

const BaseNavigationButton: React.FC<IBaseButtonElementProps> = ({
  label,
  disabled: disabledProp,
  styles,
  onClick,
  icon,
  action,
  fonts,
  iconPosition,
}) => {
  const rootStyles = useElementRootStyles(styles.root)
  const isInteractive = useProjectContext(getIsInteractiveMode)
  const disabled = !isInteractive || disabledProp
  const rootCn = cn(s.root, {
    [s.disabled]: disabled,
  })

  const onClickHandler = useCallback(() => {
    if (disabled) {
      return
    }
    onClick && onClick()
  }, [disabled, onClick])
  return (
    <button
      {...testPropsEl('courseButton', { value: action }, { label, disabled })}
      className={rootCn}
      onClick={onClickHandler}
      style={{
        ...rootStyles,
        ...styles.button,
        ...fonts?.base,
      }}
    >
      {styles.button?.showIcons && iconPosition === 'left' && (
        <div
          className={s.iconLeft}
          {...testProps({
            el: 'iconCourseButton',
            position: 'left',
            value: icon?.props?.name,
          })}
        >
          {icon}
        </div>
      )}
      {label}
      {styles.button?.showIcons && iconPosition === 'right' && (
        <div
          className={s.iconRight}
          {...testProps({
            el: 'iconCourseButton',
            position: 'right',
            value: icon?.props?.name,
          })}
        >
          {icon}
        </div>
      )}
    </button>
  )
}

export default BaseNavigationButton
