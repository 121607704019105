import { useHistory } from 'react-router'

import { DataFallbackConfigType } from 'components/DataFallback/types'
import { useModalContainer } from 'components/uiKit/Modal/ModalContext'
import ModalRender from 'components/uiKit/Modal/ModalRender'
import { APP_PATHS } from 'constants/paths'
import generateLink from 'routes/generateLink'

import ModalRedirect from './ModalRedirect'

const ControlledRedirectModal: React.FC<DataFallbackConfigType> = (config) => {
  const history = useHistory()
  const defaultLink = generateLink(APP_PATHS.home)
  const getContainer = useModalContainer()
  const onClickRedirect = () => history.push(config.backUrl || defaultLink)
  const { position, view, ...restProps } = config
  return (
    <ModalRender
      component={ModalRedirect}
      getContainer={getContainer}
      onClose={onClickRedirect}
      params={restProps}
      open
    />
  )
}

export default ControlledRedirectModal
