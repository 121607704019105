import { SCHEMA } from '@leenda/editor/lib/brand'

import { genFormSchema, FONT_SCHEMA_FIELDS } from 'services/Branding/genFormSchema'
import { t } from 'services/Translation'

export const STYLE_FORM_SCHEMA = genFormSchema(SCHEMA.testKeyboardInput.style, {
  placeholder__var: {
    META: { label: t('elements.testKeyboardInput.tags.inputField') },
    color: { label: t('elements.testKeyboardInput.schema.placeholderColor') },
  },
  input: {
    META: { hidden: true },
    borderColor: {},
    borderWidth: {},
    padding: { label: t('elements.testKeyboardInput.schema.padding') },
  },
})

export const FONT_FORM_SCHEMA = genFormSchema(SCHEMA.testKeyboardInput.font, {
  base: {
    ...FONT_SCHEMA_FIELDS.base,
    META: { label: t('elements.testKeyboardInput.tags.baseFont') },
  },
})
