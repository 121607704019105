import React, { useCallback, useRef, useState } from 'react'

import { IControlProps } from 'components/controls/Field/Field.types'
import { IconButton } from 'components/uiKit/Button'
import Dropdown from 'components/uiKit/Dropdown'
import Input, { AdditionWrapper, IInputProps } from 'components/uiKit/Input'
import { KitSize } from 'components/uiKit/KitTypes'

import PasswordTips from './PasswordTips'

interface IPasswordProps extends IInputProps {
  showTips?: boolean
}

const FieldPassword: React.FC<IControlProps<IPasswordProps>> = ({
  name,
  onChange,
  defaultValue,
  value,
  showTips = false,
  ...rest
}) => {
  const ref = useRef<HTMLInputElement>(null)
  const [hidden, setHidden] = useState(true)
  const [showTipsDropdown, setShowTipsDropdown] = useState<boolean>(false)

  const handleFocus = useCallback(() => {
    if (showTips) {
      setShowTipsDropdown(true)
    }
  }, [showTips])

  const handleBlur = useCallback(() => {
    if (showTips) {
      setShowTipsDropdown(false)
    }
  }, [showTips])

  const onChangeWrapper = useCallback(
    (value: string) => onChange({ name, value }),
    [name, onChange],
  )

  const onHiddenClick = useCallback(() => {
    setHidden((v) => !v)
    ref.current?.focus()
  }, [ref])

  return (
    <Dropdown
      closeOnRootClick={false}
      disabled={!showTips}
      onBlur={handleBlur}
      onFocus={handleFocus}
      overlay={<PasswordTips value={value} />}
      placement='right'
      visible={showTipsDropdown}
      fluid
    >
      <Input
        {...rest}
        name={name}
        onChange={onChangeWrapper}
        postfix={
          <AdditionWrapper>
            <IconButton
              icon={hidden ? 'eyeCloseEye' : 'eyeOpenEye'}
              name={`${name}.togglePassword`}
              onClick={onHiddenClick}
              onMouseDown={(e) => e.preventDefault()}
              onMouseUp={(e) => e.preventDefault()}
              size={KitSize.S}
              styleType='ghost'
              type='button'
            />
          </AdditionWrapper>
        }
        ref={ref}
        security=''
        type={hidden ? 'password' : 'text'}
        value={value ?? (defaultValue as string)}
      />
    </Dropdown>
  )
}

export default FieldPassword
