import { CONFIG_ELEMENTS } from '@leenda/editor/lib/elements'

import EditableProMode from 'components/editor-v3/cource/layout/controls/ElementControls/EditableProMode'
import { t } from 'services/Translation'

import { ElementGroup } from '../../elements.types'
import { genElementConfig } from '../../genElementConfig'
import Demo from './CodeDemo'
import Element from './CodeElement'
import Form from './CodeForm'
import codeIcon from './assets/code.svg'

export default genElementConfig(CONFIG_ELEMENTS.code, {
  label: t('elements.code.label'),
  icon: codeIcon,
  group: ElementGroup.view,
  Element,
  ValueForm: Form,
  STYLE_FORM_SCHEMA: {},
  FONT_FORM_SCHEMA: {},
  EditorActions: EditableProMode,
  Demo,
})
