import React, { useEffect, useMemo } from 'react'

import Course from 'components/editor-v3/Course'
import Loader from 'components/uiKit/Loader'
import { SCORM_PATHS } from 'constants/paths'
import { useProjectsDeepGetById } from 'gql/projects.apollo'
import { usePathParams } from 'routes/hooks'
import { getWindowData } from 'services/ScormData/ScormData'
import ProjectProvider from 'services/Store/Project'
import { PreviewMode } from 'services/Store/Project/enums'
import { prepareDeepProject } from 'services/Store/Project/prepareDeepProject'

const ScormPreview: React.FC = () => {
  const { projectId } = usePathParams(SCORM_PATHS.mainV2)
  const { data: projectData, loading } = useProjectsDeepGetById(projectId, true)

  const { project, sections, blocks } = useMemo(
    () => prepareDeepProject(projectData?.data),
    [projectData?.data],
  )

  useEffect(() => {
    document.body.style.minWidth = '0px'
    return () => {
      document.body.style.minWidth = ''
    }
  }, [])

  return (
    <>
      {project && (
        <ProjectProvider
          blocks={blocks}
          //@ts-ignore TODO: 1373
          fonts={projectData?.data?.fontsGroups || []}
          key={projectId}
          mode={PreviewMode.scorm}
          path={SCORM_PATHS.mainV2}
          project={project}
          scormSettings={getWindowData().settings}
          sections={sections}
        >
          <Course />
        </ProjectProvider>
      )}
      {!project && loading && <Loader name='scorm' styleType='fixed' resources />}
    </>
  )
}

export default ScormPreview
