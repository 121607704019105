import { gql } from 'gql/__generated__'

export const commentsSubscription = gql(`
  subscription Comments($companyId: ID!, $projectId: ID, $sectionId: ID) {
    data: comments(companyId: $companyId, projectId: $projectId, sectionId: $sectionId) {
      type
      id
      data {
        ...CommentThreadSchema
      }
      type
    }
  }
`)

export const taskCommentsSubscription = gql(`
  subscription TaskCommentsSubscription($companyId: ID!, $taskId: ID) {
    data: comments(companyId: $companyId, taskId: $taskId) {
      type
      id
      data {
        ...CommentThreadSchema
      }
      type
    }
  }
`)

export const feedThreadsSubscription = gql(`
  subscription FeedThreads(
    $companyId: ID!
    $projectId: ID
    $sectionId: ID
    $filter: CommentsSubscriptionFilter
  ) {
    data: comments(
      companyId: $companyId
      projectId: $projectId
      sectionId: $sectionId
      filter: $filter
    ) {
      type
      id
      data {
        ...FeedThreadFragment
      }
      type
    }
  }
`)
