import React, { useCallback } from 'react'

import { IControlProps } from 'components/controls/Field/Field.types'
import Checkbox, { ICheckboxProps, Value } from 'components/uiKit/Checkbox'

const FieldCheckbox = <V extends Value>({
  name,
  onChange,
  value,
  defaultValue,
  ...rest
}: IControlProps<ICheckboxProps<V>>) => {
  const handleChange = useCallback((value: V) => onChange({ value, name }), [name, onChange])

  return <Checkbox name={name} onChange={handleChange} {...rest} value={value ?? defaultValue} />
}

export default FieldCheckbox
