"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_VALUE = exports.TYPE = void 0;
const types_1 = require("./types");
exports.TYPE = 'courseStructure';
exports.DEFAULT_VALUE = {
    showDescription: true,
    showIcon: true,
    initialOpen: true,
    collapsePosition: types_1.CollapseIconPosition.left,
    progressPosition: types_1.ProgressIconPosition.left,
};
