import React from 'react'

import { IControlProps } from 'components/controls/Field/Field.types'

interface IFieldCustomProps<T> {
  component: React.FC<IControlProps<{ value?: T }>>
  value: T
}

const FieldCustom = <T,>({ component: Component, ...r }: IControlProps<IFieldCustomProps<T>>) => {
  return <Component {...r} />
}

export default FieldCustom
