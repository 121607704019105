import { SlateMark } from '@leenda/rich-text'
import { CSSProperties } from 'react'
import { Editor } from 'slate'

import { CourseFontWeight } from 'constants/course'

import { MARK_CONFIGS } from '../constants'
import { getInlineElement } from '../inline/withInline'
import { CustomEditor } from '../slate'
import { getActiveElements, getElementMarks } from './elements'
import { getTextMark } from './textMarks'

export const getMarkDefaultValues = (style: CSSProperties) => {
  const markFormats = Object.values(MARK_CONFIGS).reduce((acc, config) => {
    const { name, defaultValue } = config
    const value = style[name as keyof CSSProperties] || defaultValue
    return { ...acc, [name]: value }
  }, {})
  const manualMapping = {
    [SlateMark.bold]: Number(style.fontWeight as string) === CourseFontWeight.bold,
    [SlateMark.italic]: (style.fontStyle as string) === 'italic',
  }
  return { ...markFormats, ...manualMapping }
}

export const getCurrentFormat = (editor: CustomEditor) => {
  const elements = getActiveElements(editor)
  const elementMarks = getElementMarks(editor)
  const marks = getTextMark(editor)
  const inline = getInlineElement(editor)

  return { ...elements, ...elementMarks, ...marks, inline }
}

export const isFormatActive = (editor: CustomEditor, format: SlateMark | string): boolean => {
  const marks = Editor.marks(editor)
  if (!marks) {
    return false
  }
  const isActive = marks[format as keyof typeof marks]

  return !!isActive
}
