import { SlateMark } from '@leenda/rich-text'
import { SlateElementType } from '@leenda/rich-text'

import { Tooltip } from 'components/uiKit/Dropdown'
import Icon from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'
import { t } from 'services/Translation'

import { SlateCommand } from '../constants'
import createOptionLabelWithIcon from './components/createOptionLabelWithIcon/createOptionLabelWithIcon'

export const TEXT_TYPE_OPTIONS = [
  {
    label: createOptionLabelWithIcon({ icon: 'builderText', label: t('input.option.text') }),
    value: SlateElementType.elementDefault,
  },
  {
    label: createOptionLabelWithIcon({
      icon: 'builderH1',
      label: t('input.option.header', { header: 'H1' }),
    }),
    value: SlateElementType.heading1,
  },
  {
    label: createOptionLabelWithIcon({
      icon: 'builderH2',
      label: t('input.option.header', { header: 'H2' }),
    }),
    value: SlateElementType.heading2,
  },
  {
    label: createOptionLabelWithIcon({
      icon: 'builderH3',
      label: t('input.option.header', { header: 'H3' }),
    }),
    value: SlateElementType.heading3,
  },
  {
    label: createOptionLabelWithIcon({
      icon: 'builderListNumbers',
      label: t('input.option.numberedList'),
    }),
    value: SlateElementType.numberedList,
  },
  {
    label: createOptionLabelWithIcon({
      icon: 'builderList',
      label: t('input.option.bulletedList'),
    }),
    value: SlateElementType.bulletedList,
  },
  {
    label: createOptionLabelWithIcon({
      icon: 'builderBlockquote',
      label: t('input.option.blockquote'),
    }),
    value: SlateElementType.blockquote,
  },
]

export const ALIGN_OPTIONS = [
  {
    label: (
      <Tooltip overlay={t('uiKit.tooltip.alignLeft')}>
        <Icon name='builderAlignLeft' size={KitSize.S} />
      </Tooltip>
    ),
    value: 'left',
  },
  {
    label: (
      <Tooltip overlay={t('uiKit.tooltip.alignCenter')}>
        <Icon name='builderAlignCenter' size={KitSize.S} />
      </Tooltip>
    ),
    value: 'center',
  },
  {
    label: (
      <Tooltip overlay={t('uiKit.tooltip.alignRight')}>
        <Icon name='builderAlignRight' size={KitSize.S} />
      </Tooltip>
    ),
    value: 'right',
  },
  {
    label: (
      <Tooltip overlay={t('uiKit.tooltip.alignFullWidth')}>
        <Icon name='builderAlignFullWidth' size={KitSize.S} />
      </Tooltip>
    ),
    value: 'justify',
  },
]

export const REGISTER_OPTIONS = [
  {
    label: (
      <Tooltip overlay={t('uiKit.tooltip.superscript')}>
        <Icon name='builderUpper' size={KitSize.S} />
      </Tooltip>
    ),
    value: 'sup',
  },
  {
    label: (
      <Tooltip overlay={t('uiKit.tooltip.subscript')}>
        <Icon name='builderLower' size={KitSize.S} />
      </Tooltip>
    ),
    value: 'sub',
  },
]

export const TRANSFORM_OPTIONS = [
  {
    label: (
      <Tooltip overlay={t('uiKit.tooltip.capitalize')}>
        <Icon name='builderTypeParagraphCharacterTextSize' size={KitSize.S} />
      </Tooltip>
    ),
    value: 'capitalize',
  },
  {
    label: (
      <Tooltip overlay={t('uiKit.tooltip.uppercase')}>
        <Icon name='builderTypeParagraphCharacterAllCaps' size={KitSize.S} />
      </Tooltip>
    ),
    value: 'uppercase',
  },
  {
    label: (
      <Tooltip overlay={t('uiKit.tooltip.lowercase')}>
        <Icon name='builderTypeParagraphCharacterTextSizeSmall' size={KitSize.S} />
      </Tooltip>
    ),
    value: 'lowercase',
  },
]

export const MARKS = [
  SlateMark.bold,
  SlateMark.italic,
  SlateMark.strikethrough,
  SlateMark.underline,
]

export const symbols = [
  SlateCommand.leftDoubleQuotes,
  SlateCommand.rightDoubleQuotes,
  SlateCommand.squareRoot,
  SlateCommand.integral,
  SlateCommand.degree,
  SlateCommand.greaterThan,
  SlateCommand.lessThan,
  SlateCommand.dash,
]
