import { ApolloError } from '@apollo/client'
import numeral from 'numeral'

import { notify } from 'components/uiKit/Notification'
import { NotificationType } from 'components/uiKit/Notification/types'
import { CompanyInfoSchemaFragment, CompanySchemaFragment } from 'gql/__generated__/graphql'
import { t } from 'services/Translation'

import { getResourcesLabel } from './resources'

export const employeeInviteError = (err: ApolloError, company: CompanySchemaFragment) => {
  const limitError = err.graphQLErrors.find((e) => e.message === 'EMPLOYEES_LIMIT_REACHED')

  const diff = Number(company.availableEmployees) - company.employeeCountNonLeenda
  const diffMod = diff < 0 ? 0 : diff

  if (limitError) {
    notify({
      type: NotificationType.error,
      message: t('notify.EMPLOYEES_LIMIT_REACHED', {
        current: company.employeeCountNonLeenda,
        total: company.availableEmployees,
        count: diffMod,
      }),
      duration: 8000,
    })
  }
}

export const storageLimitReached = (
  err: ApolloError,
  company: CompanyInfoSchemaFragment['company'],
) => {
  const storageLimitError = err.graphQLErrors.find((e) => e.message === 'STORAGE_LIMIT_REACHED')
  if (!storageLimitError) {
    return
  }
  const diff = Number(company.availableStorage) - Number(company.overallResources.total)
  const diffMod = diff < 0 ? 0 : diff
  notify({
    type: NotificationType.error,
    message: t('notify.STORAGE_LIMIT_REACHED', {
      resources: getResourcesLabel(company.overallResources.total, company.availableStorage),
      diff: numeral(diffMod).format('0.0 b'),
    }),
    duration: 8000,
  })
}
