import { TaskStatus } from 'gql/__generated__/graphql'

import type { ITasksFilterValues } from './fieldsConfig'

export const TASKS_FILTER_INITIAL_VALUES: ITasksFilterValues = {
  status: [TaskStatus.todo, TaskStatus.new, TaskStatus.doing, TaskStatus.review],
  assignedIds: [],
  deadlineFrom: null,
  deadlineTo: null,
}
