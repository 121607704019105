import { colorsTypes, FileTypeEnum } from 'utils'

import { FilesGroupsFragment } from 'gql/__generated__/graphql'
import { FinderItem } from 'store/models'

import { ReactComponent as archiveSvg } from './Archive.svg'
import { ReactComponent as audioSvg } from './Audio.svg'
import s from './FinderFileIcons.module.scss'
import { ReactComponent as IconFolderComponentSvg } from './Folder.svg'
import { ReactComponent as videoSvg } from './Video.svg'

const EnumIconsByFileType = {
  [FileTypeEnum.AUDIO]: audioSvg,
  [FileTypeEnum.VIDEO]: videoSvg,
  [FileTypeEnum.TEXT]: archiveSvg,
  [FileTypeEnum.APPLICATION]: archiveSvg,
  [FileTypeEnum.TRANSLATE]: archiveSvg,
  [FileTypeEnum.IMAGE]: archiveSvg,
  [FileTypeEnum.FONT]: archiveSvg,
}

const FinderFileIcons = ({ typeFile, item }: { typeFile?: FileTypeEnum; item: FinderItem }) => {
  const IconComponent = EnumIconsByFileType[typeFile || FileTypeEnum.APPLICATION]
  return (
    <>
      {typeFile && (
        <div className={s.fileItem}>
          {typeFile === FileTypeEnum.IMAGE && item.img && (
            <img alt='img' className={s.img} src={item.img} />
          )}
          {typeFile !== FileTypeEnum.IMAGE && <IconComponent />}
        </div>
      )}
      {!typeFile && (
        <div
          className={s.folderItem}
          style={{ color: (item as FilesGroupsFragment)?.color || colorsTypes.purpure }}
        >
          <IconFolderComponentSvg />
        </div>
      )}
    </>
  )
}

export default FinderFileIcons
