import { chainName } from 'utils'

import RealtimeField from 'components/controls/RealtimeField'
import Padding from 'components/editor-v3/Editor/Toolbar/Padding'
import { AppMode, SectionTypeEnum } from 'services/Store/Project/enums'
import { useProjectContext } from 'services/Store/Project/hooks'
import {
  getBlock,
  getBlocks,
  getSection,
  getEditorMode,
  getSections,
} from 'services/Store/Project/selectors'
import { useNextSection } from 'utils/project.hooks'

import { ButtonValueFormType } from './ButtonElement.types'
import {
  action,
  actionType,
  blockNavigation,
  file,
  label,
  sectionNavigation,
  url,
  position,
  condition,
  getLabelConfigs,
} from './buttonConfig'

const ButtonForm: ButtonValueFormType = ({ name, element }) => {
  const sections = useProjectContext(getSections, [
    SectionTypeEnum.cover,
    SectionTypeEnum.landing,
    SectionTypeEnum.test,
  ])
  const section = useProjectContext(getSection)
  const blocks = useProjectContext(getBlocks)
  const block = useProjectContext(getBlock)
  const sectionOptions = sections
    .filter((section) => section.type !== 'chapter')
    .map((section) => ({ value: section.id, label: section.name }))
  const isProMode = useProjectContext(getEditorMode) === AppMode.pro
  const nextSection = useNextSection(section?.id, sections)

  const labels = getLabelConfigs({
    blocks,
    section,
    value: element.value,
    block,
    nextSection,
    isProMode,
  })

  return (
    <Padding sides>
      <RealtimeField config={chainName(name, actionType)} />
      <RealtimeField config={chainName(name, action(section?.type))} />
      <RealtimeField config={chainName(name, sectionNavigation(sectionOptions))} />
      <RealtimeField config={chainName(name, blockNavigation(sections))} />
      <RealtimeField config={chainName(name, url)} />
      <RealtimeField config={chainName(name, file)} />
      <RealtimeField config={chainName(name, label)} />
      <>
        {labels.map((item, index) => (
          <RealtimeField config={chainName(name, item(index + 2))} key={index} />
        ))}
      </>
      <RealtimeField config={chainName(name, position)} />
      <RealtimeField config={chainName(name, condition)} />
    </Padding>
  )
}

export default ButtonForm
