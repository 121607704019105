import React, { FC } from 'react'

import { KitSize } from 'components/uiKit/KitTypes'
import { ModalTable } from 'components/uiKit/Table'
import { PaginationConfig, SortConfig } from 'components/uiKit/Table/types'
import { t } from 'services/Translation'
import { FinderFolderItem } from 'store/models'

import { COLUMNS_CONFIG } from './config'

export const MODAL_MOVE_FINDER_TABLE_NAME = 'modalMoveFinderTable'

interface IFolderTableProps {
  folders: FinderFolderItem[]
  isLoading?: boolean
  totalFolders: number
  setTargetGroupId: (id: string) => void
  isDisabledMove: boolean
  paginationConfig: PaginationConfig
  sortConfig: SortConfig
}

const FolderTable: FC<IFolderTableProps> = ({
  folders,
  isLoading,
  totalFolders,
  setTargetGroupId,
  isDisabledMove,
  paginationConfig,
  sortConfig,
}) => {
  return (
    <ModalTable
      columns={COLUMNS_CONFIG}
      dataSource={folders}
      defaultSort='name'
      empty={{
        text: isDisabledMove ? <div>{t('modal.move.folder')}</div> : t('common.nothingFound'),
        icon: 'folder',
        size: KitSize.XS,
      }}
      loading={isLoading}
      name={MODAL_MOVE_FINDER_TABLE_NAME}
      onClickRow={(_e, group) => setTargetGroupId(group.id)}
      paginationConfig={paginationConfig}
      sortConfig={sortConfig}
      total={totalFolders}
    />
  )
}

export default FolderTable
