import { SCHEMA } from '@leenda/editor/lib/brand'

import { genField } from 'components/controls/Field'
import { FONT_SCHEMA_FIELDS, genFormSchema } from 'services/Branding/genFormSchema'
import { t } from 'services/Translation'

export const STYLE_FORM_SCHEMA = genFormSchema(SCHEMA.labeledGraphic.style, {
  image: {
    META: { label: t('elements.labeledGraphic.tags.image') },
    borderRadius: {},
    borderStyle: {},
    borderColor: {},
    borderWidth: {},
  },
  point: {
    META: { label: t('elements.labeledGraphic.tags.point') },
    backgroundImage: {
      label: t('elements.labeledGraphic.schema.imageBackground'),
      params: { preview: false },
    },
    backgroundColor: {},
    height: {},
    width: {},
  },
  point__var: {
    META: { hidden: true },
    color: { label: t('elements.labeledGraphic.schema.hover') },
  },
  tooltip: {
    META: { label: t('elements.labeledGraphic.tags.tooltip') },
    backgroundColor: {},
  },
  navigation: {
    META: { hidden: true },
    switch: genField({
      type: 'segmented',
      layout: 'horizontal',
      label: t('elements.labeledGraphic.schema.switcher'),
      params: { fluid: false },
    }),
  },
  animation: {
    META: { label: t('elements.labeledGraphic.tags.animation') },
    fade: genField({
      type: 'segmented',
      layout: 'horizontal',
      label: t('elements.labeledGraphic.schema.smooth'),
      params: { fluid: false },
    }),
    pulse: genField({
      type: 'segmented',
      layout: 'horizontal',
      label: t('elements.labeledGraphic.schema.pulse'),
      params: { fluid: false },
    }),
  },
})

export const FONT_FORM_SCHEMA = genFormSchema(SCHEMA.labeledGraphic.font, {
  numbering: {
    ...FONT_SCHEMA_FIELDS.base,
    META: { label: t('elements.labeledGraphic.tags.numbering') },
  },
  navigation: {
    ...FONT_SCHEMA_FIELDS.base,
    META: { label: t('elements.labeledGraphic.tags.switcher') },
  },
  ...FONT_SCHEMA_FIELDS,
})
