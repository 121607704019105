import { gql } from 'gql/__generated__'

export const exportProjectToPdf = gql(`
  mutation PdfExportInit($projectId: ID!, $docType: PdfBuildType!, $integrationId: ID) {
    data: exportProjectToPdf(projectId: $projectId, docType: $docType, integrationId: $integrationId) {
      ...PdfBuildSchema
    }
  }
`)

export const exportProjectToScorm = gql(`
  mutation ExportProjectToScorm($payload: ExportProjectToScormInput!) {
    data: exportProjectToScorm(payload: $payload) {
      ...ScormPackageSchema
    }
  }
`)

export const stopProjectExportToScorm = gql(`
  mutation StopProjectExportToScorm($buildId: ID!) {
    data: stopProjectExportToScorm(buildId: $buildId) {
      ...ScormBuildSchema
    }
  }
`)

export const stopProjectExportToPdf = gql(`
  mutation StopProjectExportToPdf($buildId: ID!) {
    data: stopProjectExportToPdf(buildId: $buildId) {
      ...PdfBuildSchema
    }
  }
`)

export const updateProjectBuilds = gql(`
  mutation UpdateProjectBuilds($data: UpdateBuildInput!) {
    data: updateProjectBuilds( data: $data) {
      ...ScormBuildListSchema
      ...PdfBuildListSchema
    }
  }
`)

export const deleteProjectBuilds = gql(`
  mutation DeleteProjectBuilds($data: DeleteBuildInput!) {
    data: deleteProjectBuilds(data: $data)
  }
`)
