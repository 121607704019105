import React from 'react'

import s from './TabPanel.module.scss'

export interface ITabPanelProps<V extends string> {
  name?: string
  title?: React.ReactNode | string
  disabled?: boolean
  children?: React.ReactNode
  active?: V
  tabKey: V
  textError?: string
  hidden?: boolean
}

const TabPanel = <V extends string>({ children }: ITabPanelProps<V>) => {
  return <div className={s.root}>{children}</div>
}

export default TabPanel
