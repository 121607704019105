import { IControlProps } from 'components/controls/Field/Field.types'
import { Palette, IPaletteProps } from 'components/uiKit/ColorPicker'

const FieldPalette: React.FC<IControlProps<IPaletteProps>> = ({
  name,
  onChange,
  value,
  defaultValue,
  ...rest
}) => {
  const handleOnChange = (value: string) => onChange({ name, value })

  return <Palette {...rest} name={name} onChange={handleOnChange} value={value ?? defaultValue} />
}

export default FieldPalette
