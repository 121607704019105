import { isValid, parse } from 'date-fns'
import React, { useState } from 'react'

import { IconButton } from 'components/uiKit/Button'
import Input, { IInputProps } from 'components/uiKit/Input'
import { KitSize } from 'components/uiKit/KitTypes'

interface ICustomDatePickerInput extends Omit<IInputProps, 'name' | 'onChange'> {
  onChange: (value?: number | null) => void
}

const DatePickerInput: React.FC<ICustomDatePickerInput> = ({
  value,
  error,
  onChange,
  disabled,
  ...rest
}) => {
  const [validError, setValidError] = useState(false)

  const handleOnChange = (value?: string | null) => {
    const date = value?.length === 10 && parse(value, 'dd.MM.yyyy', new Date())
    if (!value) {
      setValidError(false)
      onChange(null)
    } else if (date && isValid(date)) {
      setValidError(false)
      onChange(date.getTime())
    } else {
      setValidError(true)
      onChange(null)
    }
  }

  const clearDate = (e: React.MouseEvent) => {
    e.stopPropagation()
    onChange(null)
  }

  return (
    <Input
      {...rest}
      autoComplete='off'
      disabled={disabled}
      error={validError || error}
      name='dateInput'
      onChange={handleOnChange}
      postfix={
        <IconButton
          disabled={disabled}
          icon={value ? 'otherReset' : 'otherCalendar'}
          name={value ? 'reset' : 'calendar'}
          onClick={value ? clearDate : undefined}
          size={KitSize.S}
          styleType='ghost'
          type='button'
        />
      }
      value={value}
    />
  )
}

export default DatePickerInput
