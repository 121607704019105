import { generateId } from '@leenda/editor/lib/utils/id'
import cn from 'classnames'
import { motion, MotionProps } from 'framer-motion'
import React, { CSSProperties, FC, useEffect } from 'react'
import { createPortal } from 'react-dom'

import s from './DropDownContent.module.scss'
import EmptyContent from './EmptyContent'
import { useDropDownContext } from './context'
import { IStyleType, ITheme, IType } from './types'

export type DropdownAnimationProps = Pick<MotionProps, 'initial' | 'animate' | 'exit'>

interface IDropDownContent extends DropdownAnimationProps {
  textError?: string
  styleType?: IStyleType
  styleContent: React.CSSProperties | undefined
  setPopupEle: React.Dispatch<React.SetStateAction<HTMLDivElement | null>>
  open: boolean
  refPortal: React.MutableRefObject<HTMLDivElement | null>
  type?: IType
  theme?: ITheme
  overlayStyle?: CSSProperties
  popupContainer?: HTMLElement
  stopPropagation?: boolean
  children: React.ReactNode
  transformOrigin: React.CSSProperties['transformOrigin']
}

export const DropDownContent: FC<IDropDownContent> = ({
  textError,
  styleType,
  styleContent,
  setPopupEle,
  open,
  refPortal,
  children,
  type,
  theme,
  overlayStyle,
  popupContainer,
  stopPropagation,
  transformOrigin,
  ...rest
}) => {
  const { parentContext } = useDropDownContext()
  const id = generateId()

  const setPopupRef = React.useCallback((node: HTMLDivElement) => {
    if (node instanceof HTMLElement) {
      setPopupEle(node)
      parentContext?.registerSubPopup(id, node)
    }
  }, [])

  useEffect(() => {
    const container = document.createElement('div')
    container.className = s.portalDropDown
    refPortal.current = container
    document.body.appendChild(container)
    return () => {
      document.body.removeChild(container)
    }
  }, [])

  const cnDefault = cn(s.content, styleType && s[styleType], type && s[type], theme && s[theme])

  const content = (
    <motion.div
      {...rest}
      className={s.root}
      onClick={(e) => stopPropagation && e.stopPropagation()}
      onMouseDown={(e) => stopPropagation && e.stopPropagation()}
      ref={setPopupRef}
      style={{ ...styleContent, ...overlayStyle, transformOrigin }}
      tabIndex={-1}
    >
      {children && <div className={cnDefault}>{children}</div>}
      {!children && type !== 'tooltip' && (
        <div className={cnDefault}>
          <EmptyContent textError={textError} />
        </div>
      )}
    </motion.div>
  )

  return open ? createPortal(content, popupContainer || refPortal.current || document.body) : null
}

export default DropDownContent
