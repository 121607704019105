import { useMemo } from 'react'
import { useLocation } from 'react-router'

import { NavItem } from 'store/models'

const getRouteLeaves = (routes: NavItem[]): NavItem[] => {
  return routes.reduce((acc, route) => {
    if (route.children) {
      return [...acc, ...getRouteLeaves(route.children)]
    }
    return [...acc, route]
  }, [] as NavItem[])
}

export const useNavigationRoutesSelected = (routes: NavItem[]): NavItem | undefined => {
  const { pathname } = useLocation()

  return useMemo(
    () => getRouteLeaves(routes).find((route) => pathname.includes(route.path)),
    [pathname, routes],
  )
}
