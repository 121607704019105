import { genField } from 'components/controls/Field'
import { SectionTypeEnum } from 'services/Store/Project/enums'
import { t } from 'services/Translation'

import { BASE_TAGS } from '../../editor-v3/Editor/Modals/TemplatePickerModal/helper'

export const nameField = genField({
  label: t('input.label.name'),
  name: 'name',
  type: 'text',
  params: { placeholder: t('input.placeholder.name'), autoFocus: true },
  rules: [
    {
      required: true,
      message: t('input.error.required', { field: t('input.label.name') }),
    },
    {
      whitespace: true,
      message: t('input.error.whitespace'),
    },
  ],
})

export const createdForField = (options: { value: string; label: string }[]) =>
  genField({
    label: t('input.label.location'),
    name: 'editorTemplateGroupId',
    type: 'select',
    params: () => {
      return {
        options,
        fluid: true,
      }
    },
    rules: [
      {
        required: true,
      },
    ],
  })

export const tagsField = ({
  sectionType,
  mode,
}: {
  sectionType?: SectionTypeEnum
  mode?: string
}) =>
  genField({
    label: t('input.label.elementGroup'),
    name: 'tags',
    type: 'select',
    params: () => ({
      disabled: sectionType === SectionTypeEnum.test,
      hidden: sectionType === SectionTypeEnum.cover,
      fluid: true,
      isMultiple: true,
      options:
        mode === 'questions'
          ? [{ value: 'questions', label: t('input.option.question') }]
          : ((sectionType && BASE_TAGS[sectionType]) || []).filter((o) => o.value !== 'all'),
    }),
    rules: [
      {
        validator:
          sectionType === SectionTypeEnum.landing
            ? (_, value) => (!value ? t('modal.saveTemplate.elementGroup') : false)
            : undefined,
        message: t('modal.saveTemplate.elementGroup'),
        required: true,
      },
    ],
  })

export const InputErrorValues = {
  TEMPLATE_GROUP_NAME_NOT_UNIQUE: t('input.error.templateNotUnique'),
}
