import { useKeyPress } from 'ahooks'

import { setCommentForm } from 'services/Store/Project/actions'
import { useProjectDispatch } from 'services/Store/Project/hooks'

export const useCourseHotkeys = () => {
  const dispatch = useProjectDispatch()

  useKeyPress('esc', () => {
    dispatch(setCommentForm(null))
  })
}
