import { SidebarFontSchemaType, SidebarSchemaType } from '@leenda/editor/lib/brand'
import { CourseStructureItem } from '@leenda/editor/lib/elements'

import { listToTree } from 'components/sections/ProjectStructure/utils'
import { ElementFontCss, ElementStyleCss } from 'services/Branding/types'

import s from './CourseStructure.module.scss'
import CourseCollapseStructure from './components/CourseCollapseStructure/CourseCollapseStructure'

type PreviewTreeSecondaryProps = {
  sections: CourseStructureItem[]
  onSectionPick: (sectionId: string) => void
  initiallyExpanded?: boolean | null
  onSelect: (activeId: string) => void
  styles: ElementStyleCss<SidebarSchemaType>
  font: ElementFontCss<SidebarFontSchemaType>
  isEditor?: boolean
  sectionId: string
}
const CourseStructure: React.FC<PreviewTreeSecondaryProps> = ({
  sections,
  onSectionPick,
  sectionId,
  styles,
  font,
}) => (
  <div className={s.root} style={styles.root}>
    <CourseCollapseStructure
      font={font}
      nodes={listToTree(sections)}
      onSectionPick={onSectionPick}
      sectionId={sectionId}
      styles={styles}
    />
  </div>
)

export default CourseStructure
