import React, { useEffect } from 'react'
import { ReactDatePickerCustomHeaderProps } from 'react-datepicker'

import { IconButton } from 'components/uiKit/Button'
import { KitSize } from 'components/uiKit/KitTypes'

import s from './DatePickerHeader.module.scss'

interface IDatePickerHeaderProps extends ReactDatePickerCustomHeaderProps {
  selected: Date | null
}

const DatePickerHeader: React.FC<IDatePickerHeaderProps> = ({
  monthDate,
  decreaseMonth,
  increaseMonth,
  changeMonth,
  changeYear,
  selected,
  nextMonthButtonDisabled,
  prevMonthButtonDisabled,
}) => {
  const resetNow = () => {
    const now = new Date()
    changeMonth(now.getMonth())
    changeYear(now.getFullYear())
  }

  useEffect(() => {
    if (selected) {
      changeMonth(selected.getMonth())
      changeYear(selected.getFullYear())
    }
  }, [changeMonth, changeYear, selected])

  return (
    <div className={s.root}>
      <IconButton
        disabled={prevMonthButtonDisabled}
        icon='iconsForElementsLeft'
        name=''
        onClick={decreaseMonth}
        size={KitSize.XS}
        styleType='ghost'
      />
      <span className={s.monthTitle} onClick={resetNow}>
        {monthDate.toLocaleString('en-US', {
          month: 'long',
          year: 'numeric',
        })}
      </span>
      <IconButton
        disabled={nextMonthButtonDisabled}
        icon='iconsForElementsRight'
        name=''
        onClick={increaseMonth}
        size={KitSize.XS}
        styleType='ghost'
      />
    </div>
  )
}

export default DatePickerHeader
