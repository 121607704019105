import React from 'react'

import s from './LayoutPage.module.scss'

interface IPropsLayoutPage {
  children?: React.ReactNode
}

const LayoutPage: React.FC<IPropsLayoutPage> = ({ children }) => (
  <div className={s.root}>{children}</div>
)

export default LayoutPage
