import {
  from,
  ApolloClient,
  HttpLink,
  InMemoryCache,
  ApolloLink,
  split,
  concat,
} from '@apollo/client'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { getMainDefinition } from '@apollo/client/utilities'
import { config } from 'config'
import { createClient } from 'graphql-ws'

import { cleanTypenameLink } from 'utils/apollo-clear-typename'

import {
  employeesAllMerge,
  fileMetaMerge,
  dashboardCommentsMerge,
  dashboardProjectsMerge,
  editorBlocksPatchesMerge,
  templatesAllMerge,
  unsplashFilesMerge,
} from './__utils__/merge'
import { errorLink } from './error.link'

// https://www.apollographql.com/docs/react/networking/network-layer/#apollo-link
// solution from apollo documentation
const middleware = new ApolloLink((operation: any, forward: any) => {
  const visitorToken = window.sessionStorage.getItem('visitorToken')
  operation.setContext({
    headers: {
      visitorToken: visitorToken || '',
    },
  })

  return forward(operation)
})

const afterwareLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    const context = operation.getContext()
    const headers = context.response?.headers
    if (headers) {
      // get version from headers through get field response
      const currentVersion = headers.get('Version-Backend-Build')
      if (currentVersion) {
        localStorage.setItem('current_version', currentVersion)
      }
    }

    return response
  })
})

const coreLink = new HttpLink({
  uri: config.io.coreEndpoint,
})

const authLink = new HttpLink({
  uri: config.io.authEndpoint,
})

const serverStorageLink = new HttpLink({
  uri: config.io.serverStorageEndpoint,
})

export const getCoreWsLink = (token?: string) => {
  const wsLink = new GraphQLWsLink(
    createClient({
      url: config.io.coreEndpointWs || '',
      connectionParams: () => {
        const visitortoken = sessionStorage.getItem('visitorToken')
        return {
          headers: {
            Authorization: token ? `Bearer ${token}` : '',
            visitortoken: `Bearer ${visitortoken}`,
          },
        }
      },
    }),
  )

  const splittedLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
    },
    wsLink,
    coreLink,
  )

  return from([cleanTypenameLink, afterwareLink, errorLink, concat(middleware, splittedLink)])
}

const gqlClient = {
  core: new ApolloClient({
    cache: new InMemoryCache({
      typePolicies: {
        EditorSectionUpdate: {
          keyFields: ['section', ['id']],
        },
        Block: {
          keyFields: ['uuid'],
        },
        EditorTemplate: {
          keyFields: ['uuid'],
        },
        ProjectTranslateAuto: {
          keyFields: ['translateId'],
        },
        // TODO have id field for EditorSectionTest
        // EditorSectionTest: {
        //   keyFields: [
        //     'time',
        //     'testRetryCount',
        //     'testLimit',
        //     'testThreshold',
        //     'isNextOnSubmit',
        //     'isValidationVisible',
        //     'isResultValuePercent',
        //     'questionOrder',
        //     'isShuffleQuestions',
        //     'isProgressShown',
        //     'isUnableToExit',
        //   ],
        // },
        Link: {
          keyFields: ['shortLink'],
        },
        BlockVersionItem: {
          keyFields: ['uuid', 'version'],
        },
        Query: {
          fields: {
            fileMetaGroupAll: fileMetaMerge,
            employeesAll: employeesAllMerge,
            dashboardComments: dashboardCommentsMerge,
            dashboardProjects: dashboardProjectsMerge,
            editorBlocksPatches: editorBlocksPatchesMerge,
            editorTemplatesAll: templatesAllMerge,
            unsplashExternalImages: unsplashFilesMerge,
          },
        },
      },
      possibleTypes: {
        ProjectBuilds: ['PdfBuild', 'ScormBuild'],
        CompanyInfoUnion: ['Company', 'CompanyWithoutAccess'],
      },
    }),

    link: getCoreWsLink(),
  }),
  auth: new ApolloClient({
    cache: new InMemoryCache({
      possibleTypes: {
        Error: ['InputError', 'ValidationError', 'DependencyError', 'CommonError', 'AttemptsError'],
      },
    }),
    link: from([cleanTypenameLink, errorLink, concat(middleware, authLink)]),
  }),
  serverStorage: new ApolloClient({
    cache: new InMemoryCache(),
    link: from([cleanTypenameLink, errorLink, concat(middleware, serverStorageLink)]),
  }),
}

export { gqlClient }
