import { useContextSelector } from 'use-context-selector'

import RichTextContext, { RichTextContextType } from './RichTextContext'

export const getToolbarForm = (context: RichTextContextType) => context.toolbarForm
export const getSetToolbarForm = (context: RichTextContextType) => context.setToolbarForm
export const getSelectedFormat = (context: RichTextContextType) => context.selectedFormat
export const getUpdateFormat = (context: RichTextContextType) => context.updateFormat
export const getStyles = (context: RichTextContextType) => context.styles
export const getPrompt = (context: RichTextContextType) => context.prompt
export const getSetPrompt = (context: RichTextContextType) => context.setPrompt

export const useRichTextContext = <S extends (context: RichTextContextType) => ReturnType<S>>(
  selector: S,
) => useContextSelector(RichTextContext, selector)
