import { Typography } from 'components/uiKit/Typography'

import s from './Modal.module.scss'

interface IModalHeaderProps {
  title?: string | JSX.Element
  description?: string | JSX.Element
  children?: React.ReactNode
}

const INDENTS_MODAL_DESCRIPTION_HINT = { marginTop: '8px' }

const ModalHeader: React.FC<IModalHeaderProps> = ({ children, title, description }) => (
  <div className={s.header}>
    {title && <div className={s.title}>{title}</div>}
    {description && (
      <Typography indents={INDENTS_MODAL_DESCRIPTION_HINT} styleType='hint'>
        {description}
      </Typography>
    )}
    {children}
  </div>
)

export default ModalHeader
