import lodash from 'lodash'
import { ScrollBehavior, BlockBehavior, Position } from 'overlayscrollbars'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import * as R from 'ramda'

import type { ScrollRef } from 'components/LayoutPage/LayoutScroll/LayoutScroll'
import { clearScrollWait } from 'services/Store/Project/actions'
import store from 'services/Store/store'

import { ScrollContainerEnum } from './enums'

const clearScroll = lodash.debounce(
  () => !R.isEmpty(store.getState().project.state.scrollReady) && store.dispatch(clearScrollWait()),
  50,
  { leading: true },
)

export const doScroll = <V>(listRef: ScrollRef | null, id?: V, options?: Partial<ScrollType>) => {
  const os = listRef?.osInstance()
  const content = os?.getElements()?.content
  const el = content?.querySelector(getScrollSelector(String(id))) as HTMLElement
  if (el && el.isConnected) {
    os?.scrollStop()
    os?.scroll({ el, block: 'nearest', scroll: 'ifneeded', ...options }, options?.duration)
  }
}
/**
 * Use only for tags div, span, etc.
 * @example
 * ```tsx
 * <div {...scrollProps(id)}>{children}</div>
 * ```
 * @param id string
 */
export const scrollProps = (id?: string) => (id ? { [DATA_SCROLL_ID]: id } : {})
export const getScrollSelector = (id?: string) => `[${DATA_SCROLL_ID}='${id}']`
const isScrollNeeded = (
  el: HTMLElement,
  container: HTMLElement,
  scroll: ScrollType['scroll'],
  intersection?: boolean,
) => {
  const elBox = el.getBoundingClientRect()
  const contBox = container.getBoundingClientRect()

  if (scroll === 'always' || scroll?.[1] === 'always') {
    return true
  }

  // // element bigger than container
  if (elBox.top <= contBox.top && elBox.bottom >= contBox.bottom) {
    return false
  }

  // check is intersection element and container
  if (
    !intersection &&
    !(
      elBox.right < contBox.left ||
      elBox.left > contBox.right ||
      elBox.bottom < contBox.top ||
      elBox.top > contBox.bottom
    )
  ) {
    return false
  }

  return true
}

export const SCROLL_DURATION = 300
const DATA_SCROLL_ID = 'data-scroll-id'
const DEFAULT_SCROLL: Omit<ScrollType, 'container'> = { block: 'nearest', scroll: 'ifneeded' }
const OFFSET = 74

class ScrollServiceClass {
  canvas: { os: OverlayScrollbars | null; ref: HTMLDivElement | null }
  blocksNav: { os: OverlayScrollbars | null; ref: HTMLDivElement | null }
  nodesNav: { os: OverlayScrollbars | null; ref: HTMLDivElement | null }
  toolbar: { os: OverlayScrollbars | null; ref: HTMLDivElement | null }
  sectionNav: { os: OverlayScrollbars | null; ref: HTMLDivElement | null }

  constructor() {
    window?.addEventListener('mousedown', clearScroll, { capture: true })
    window?.addEventListener('wheel', clearScroll, { capture: true })
    window.addEventListener('keydown', clearScroll, { capture: true })
  }

  private doScroll = (
    {
      container,
      id,
      duration = SCROLL_DURATION,
      margin: marginParam,
      intersection,
      scroll,
      easing,
      complete,
      ...position
    }: ScrollType,
    scrollContextRaw: ScrollContext | null,
  ) => {
    const scrollContext = scrollContextRaw || { blockMargin: OFFSET }
    const defaultMargin =
      container === ScrollContainerEnum.canvas ? scrollContext.blockMargin : OFFSET
    const margin = marginParam || defaultMargin || OFFSET
    const os = this[container]?.os
    if (id) {
      const el = os?.getElements().content?.querySelector(getScrollSelector(id)) as HTMLElement
      const viewport = os?.getElements().viewport
      if (el && viewport && isScrollNeeded(el, viewport, scroll, intersection)) {
        os?.scroll(
          { ...DEFAULT_SCROLL, ...position, scroll, el, margin },
          duration,
          easing,
          complete,
        )
      } else {
        complete?.()
      }
    } else {
      os?.scroll({ ...DEFAULT_SCROLL, ...position, scroll, margin }, duration, easing, complete)
    }
  }

  createContainerSetter = (container: ScrollContainerEnum) => {
    return ((ref: OverlayScrollbarsComponent | null) => {
      const os = ref?.osInstance() || null
      const viewport = (os?.getElements().viewport as HTMLDivElement) || null

      this[container] = { os: os, ref: viewport }
    }).bind(this)
  }

  /**
   * @param scroll ifneeded
   * @param block nearest
   * @param duration 500
   * @param offset 16
   */
  scroll = ({ async = 0, ...options }: ScrollType, scrollContext: ScrollContext | null) =>
    setTimeout(() => this.doScroll(options, scrollContext), async)

  enableScroll = (container: ScrollContainerEnum = ScrollContainerEnum.canvas) =>
    this[container]?.ref?.style.setProperty('overflow-y', 'scroll')

  disableScroll = (container: ScrollContainerEnum = ScrollContainerEnum.canvas) =>
    this[container]?.ref?.style.setProperty('overflow-y', 'hidden')
}

export type ScrollType = {
  container: ScrollContainerEnum
  el?: HTMLElement | null
  async?: number
  id?: string | null
  scroll?: ScrollBehavior | [ScrollBehavior, ScrollBehavior]
  block?:
    | BlockBehavior
    | { x?: BlockBehavior | undefined; y?: BlockBehavior | undefined }
    | [BlockBehavior, BlockBehavior]
    | undefined
  duration?: number
  margin?: number
  x?: Position | undefined
  y?: Position | undefined
  intersection?: boolean
  easing?: OverlayScrollbars.Easing
  complete?: () => void
}

export type ScrollContext = {
  blockMargin: number
}

const ScrollService = new ScrollServiceClass()

export default ScrollService
