export const number = (value: string) => {
  return Number(value)
}

export const string = (value: string) => {
  return value
}

export const boolean = (value: string) => {
  return value === 'true'
}

export const date = (value: string) => {
  return new Date(value)
}

export const array = (value: string | string[]) => {
  return value instanceof Array ? value : [value]
}

export const PARSE_FILTER = { number, string, boolean, date, array }

export type InferFilterFromConfig<
  C extends Record<string, (typeof PARSE_FILTER)[keyof typeof PARSE_FILTER]>,
> = {
  [K in keyof C]: ReturnType<C[K]>
}

export type FilterTemplate<T> = {
  [K in keyof T]: (typeof PARSE_FILTER)[keyof typeof PARSE_FILTER]
}
