import { IControlProps } from 'components/controls/Field/Field.types'
import ColorPicker, { IColorPickerProps } from 'components/uiKit/ColorPicker'

const FieldColorPicker: React.FC<IControlProps<IColorPickerProps>> = ({
  name,
  onChange,
  value,
  defaultValue,
  ...rest
}) => {
  const handleOnChange = (value: string) => onChange({ name, value })

  return (
    <ColorPicker {...rest} name={name} onChange={handleOnChange} value={value ?? defaultValue} />
  )
}

export default FieldColorPicker
