import { FORMAT_FIELDS, SlateFormats } from 'components/form/RichText/RichTextConstants'
import { getUpdateFormat, useRichTextContext } from 'components/form/RichText/useRichTextContext'
import { IconButton } from 'components/uiKit/Button'
import { Tooltip } from 'components/uiKit/Dropdown'
import { KeyIconsType } from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'
import { t } from 'services/Translation'

const RadioButtonTool: React.FC<{ name: SlateFormats } & { [key: string]: any }> = ({
  name,
  ...rest
}) => {
  const onUpdateFormat = useRichTextContext(getUpdateFormat)
  return (
    <Tooltip overlay={t(`uiKit.tooltip.${name}` as const)}>
      <IconButton
        icon={FORMAT_FIELDS[name]?.icon as KeyIconsType}
        name={name}
        onClick={() => onUpdateFormat(name, null)}
        size={KitSize.S}
        styleType='ghost'
        {...rest}
      />
    </Tooltip>
  )
}

export default RadioButtonTool
