import { PermissionIdentification, PermissionService, PermissionsKey } from '@leenda/crud'

import { CommentActionUiEnum, CommentMock } from 'components/comments/types'
import { IMenuOption } from 'components/uiKit/Menu'
import { notify } from 'components/uiKit/Notification'
import { NotificationType } from 'components/uiKit/Notification/types'
import { PROJECT_PATHS } from 'constants/paths'
import { CommentStatus, EmployeeListSchemaFragment } from 'gql/__generated__/graphql'
import { unSafeGenerateLink } from 'routes/generateLink'
import { t } from 'services/Translation'
import { getEnumOption } from 'utils/enum'

export interface IConfig<T extends CommentActionUiEnum> extends IMenuOption<T> {
  inline?: boolean
  active?: boolean
  styleType?: 'primary' | 'accent' | 'ghost' | 'secondary' | 'free'
}

type Args = {
  comment: CommentMock
  me: EmployeeListSchemaFragment | null
  permissions: PermissionService<PermissionIdentification>
} & Partial<IConfig<CommentActionUiEnum>>

export const subscribeMenuItem = ({ comment, permissions, me, ...rest }: Args) =>
  getEnumOption('CommentActionUiEnum', CommentActionUiEnum.SUBSCRIBE, {
    hidden: comment.isSubscribed,
    ...rest,
  })

export const unsubscribeMenuItem = ({ comment, permissions, me, ...rest }: Args) =>
  getEnumOption('CommentActionUiEnum', CommentActionUiEnum.UNSUBSCRIBE, {
    hidden: !comment.isSubscribed,
    ...rest,
  })

export const deleteMenuItem = ({ comment, permissions, me, ...rest }: Args) =>
  getEnumOption('CommentActionUiEnum', CommentActionUiEnum.DELETE, {
    hidden: comment.createdBy?.id !== me?.id && !permissions.has(PermissionsKey.project_comments_d),
    ...rest,
  })

export const editMenuItem = ({ comment, permissions, me, ...rest }: Args) =>
  getEnumOption('CommentActionUiEnum', CommentActionUiEnum.EDIT, {
    hidden: comment.createdBy?.id !== me?.id,
    ...rest,
  })

export const resolveMenuItem = ({ comment, permissions, me, ...rest }: Args) =>
  getEnumOption('CommentActionUiEnum', CommentActionUiEnum.RESOLVE, {
    hidden:
      !permissions.has(PermissionsKey.project_comments_resolve) ||
      comment.status === CommentStatus.resolved,
    icon: 'checkmarkCircle1',
    tooltip: t('uiKit.comment.resolved'),
    ...rest,
  })

export const pendingMenuItem = ({ comment, permissions, me, ...rest }: Args) =>
  getEnumOption('CommentActionUiEnum', CommentActionUiEnum.PENDING, {
    hidden:
      !permissions.has(PermissionsKey.project_comments_resolve) ||
      comment.status === CommentStatus.pending,
    icon: 'passwordSuccess',
    tooltip: t('uiKit.comment.restoreComment'),
    ...rest,
  })

export const pinMenuItem = ({
  comment,
  permissions,
  me,
  pinned,
  ...rest
}: Args & { pinned: boolean }) =>
  getEnumOption('CommentActionUiEnum', CommentActionUiEnum.PIN, {
    icon: 'pin',
    active: pinned,
    tooltip: pinned ? t('uiKit.tooltip.unpin') : t('uiKit.tooltip.pin'),
    ...rest,
  })

export const closeMenuItem = ({ comment, permissions, me, ...rest }: Args) =>
  getEnumOption('CommentActionUiEnum', CommentActionUiEnum.CLOSE, {
    icon: 'otherClose',
    tooltip: t('uiKit.tooltip.close'),
    ...rest,
  })

export const collapseMenuItem = ({
  comment,
  permissions,
  me,
  collapsed,
  ...rest
}: Args & { collapsed: boolean }) =>
  getEnumOption('CommentActionUiEnum', CommentActionUiEnum.COLLAPSE, {
    icon: collapsed ? 'otherDown' : 'otherUp',
    tooltip: collapsed ? t('uiKit.tooltip.expand') : t('uiKit.tooltip.collapse'),
    ...rest,
  })

export const copyLinkMenuItem = ({ comment, permissions, me, ...rest }: Args) =>
  getEnumOption('CommentActionUiEnum', CommentActionUiEnum.LINK, {
    ...rest,
  })

interface ICopyLinkPayload {
  projectId?: string | null
  sectionId?: string | null
  blockId?: string | null
  threadId: string
}

export const createCopyLinkAction = async (
  currentPath: string,
  { threadId, ...params }: ICopyLinkPayload,
) => {
  try {
    const link = unSafeGenerateLink(currentPath, PROJECT_PATHS.preview, params, { threadId })
    const url = `${location.protocol}//${location.host}${location.pathname}#${link}`
    await navigator.clipboard.writeText(url)
    notify({ type: NotificationType.success, message: t('notify.linkCopied') })
  } catch (err) {
    notify({ type: NotificationType.error, message: t('notify.copiedError'), duration: 2000 })
  }
}
