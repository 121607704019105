import { SCHEMA } from '@leenda/editor/lib/brand'

import { genField } from 'components/controls/Field'
import { genFormSchema, FONT_SCHEMA_FIELDS } from 'services/Branding/genFormSchema'
import { t } from 'services/Translation'

export const STYLE_FORM_SCHEMA = genFormSchema(SCHEMA.card.style, {
  icon: {
    META: { label: t('elements.card.tags.icon') },
    backgroundColor: {},
    color: { label: t('input.label.iconColor') },
    inversion: genField({
      type: 'segmented',
      layout: 'horizontal',
      label: t('elements.card.schema.inversion'),
    }),
    borderRadius: { params: { min: 0, max: 100 } },
  },
  card: {
    META: { label: t('elements.card.tags.card') },
    height: { params: { placeholder: '380px', min: 152 } },
    width: { params: { placeholder: '290px', min: 152 } },
    borderRadius: { params: { max: 40 } },
  },
  border: {
    META: { label: t('elements.card.tags.border'), hidden: true },
    borderStyle: {},
    borderColor: {},
    borderWidth: { params: { min: 1, max: 8 } },
  },
  sideA: {
    META: { label: t('elements.card.tags.sideA') },
    backgroundColor: {},
    alignItems: {},
  },
  sideB: {
    META: { label: t('elements.card.tags.sideB') },
    backgroundColor: {},
    alignItems: {},
  },
})

export const FONT_FORM_SCHEMA = genFormSchema(SCHEMA.card.font, FONT_SCHEMA_FIELDS)
