import React, { useCallback } from 'react'

import { IControlProps } from 'components/controls/Field/Field.types'
import Input, { IInputProps } from 'components/uiKit/Input'

const FieldInput: React.FC<IControlProps<IInputProps>> = ({
  name,
  onChange,
  defaultValue,
  value,
  ...rest
}) => {
  const onChangeWrapper = useCallback(
    (value: string) => onChange({ name, value }),
    [name, onChange],
  )

  return (
    <Input
      {...rest}
      name={name}
      onChange={onChangeWrapper}
      value={value ?? (defaultValue as string)}
    />
  )
}

export default FieldInput
