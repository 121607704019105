import { produce } from 'immer'
import lodash from 'lodash'
import { useCallback, useEffect, useMemo } from 'react'
import { useContextSelector } from 'use-context-selector'

import Field, { getParams } from 'components/controls/Field'
import {
  IFieldConfig,
  IFieldRule,
  IOnChange,
  IOnChangeData,
} from 'components/controls/Field/Field.types'
import { FormContext } from 'components/controls/Form'

import { getTruthValue } from '../Field/getTruthValue'
import { validateRules } from '../Field/rules'
import { useFieldData, useFormData } from './hooks'

const isRequired = <D,>(config: IFieldConfig<D>) =>
  config.rules?.some((rule: IFieldRule<any>) => rule.required)

const FormField = <D,>({ config }: { config: IFieldConfig<D> }) => {
  const { name } = config
  const { value, initialValue, meta, defaultValue, fullValues } = useFieldData(name)
  const data = useFormData() as D
  const layout = useContextSelector(FormContext, ({ layout }) => layout)
  const fieldSize = useContextSelector(FormContext, ({ size }) => size)
  const fieldConfig = useMemo(() => ({ layout, ...config }), [layout, config])
  const fieldParams = useContextSelector(FormContext, (context) =>
    getParams(config, context.inheritValues as D),
  )
  const formDisabled = useContextSelector(FormContext, ({ disabled }) => disabled)
  const params = {
    ...fieldParams,
    disabled: formDisabled || fieldParams.disabled,
  }
  const rules = config.rules

  const onChangeContext = useContextSelector(FormContext, (context) => context.onChange)
  const onChangeMeta = useContextSelector(FormContext, (context) => context.onChangeMeta)

  const onChange = useCallback(
    async (controlChangeData: IOnChangeData) => {
      const changeData = config.effect
        ? await config.effect(data, controlChangeData)
        : controlChangeData

      const { name, value } = changeData
      const validateError =
        !params.hidden && validateRules(getTruthValue(value, initialValue), rules)

      const newMeta = produce({ ...meta, ...changeData.meta }, (draft) => {
        draft.dirty = changeData.meta?.dirty || value !== initialValue
        draft.touched = true
        draft.valid = !validateError
        draft.error = validateError
        draft.hidden = params.hidden || false
        draft.disabled = params.disabled || false
      })

      onChangeContext({ name, value, meta: newMeta })
    },
    [config, initialValue, params.disabled, params.hidden, onChangeContext, rules],
  )

  const label =
    typeof config.label === 'function'
      ? config.label({ data, config, onChange, value: fullValues, defaultValue })
      : config.label

  useEffect(() => {
    const validateError = !params.hidden && validateRules(getTruthValue(value, initialValue), rules)

    const newMeta = produce(meta, (draft) => {
      draft.dirty = !lodash.isEqual(fullValues, initialValue)
      draft.valid = !validateError
      draft.error = validateError
      draft.hidden = params.hidden || false
      draft.disabled = params.disabled || false
      draft.commonError = false
    })
    onChangeMeta(name, newMeta)
    // potential bug with rules
  }, [config.rules, value, initialValue, params.disabled, onChangeMeta, params.hidden, rules])

  const handleOnChange: IOnChange = (changeData) => {
    onChange(changeData)
    config.dependsFields?.forEach(({ name, getValue }) => {
      const updateData = produce(data, (draft) => {
        lodash.set(draft as Record<string, unknown>, changeData.name, changeData.value)
      })
      const value = getValue(updateData)
      onChangeContext({ name, value })
    })
  }

  const handleOnBlur = () => onChangeMeta(name, { ...meta, touched: true })

  if (params.hidden) {
    return null
  }

  return (
    <Field
      config={fieldConfig}
      defaultValue={defaultValue}
      label={label}
      meta={meta}
      onBlur={handleOnBlur}
      onChange={handleOnChange}
      params={params}
      Parent={FormField}
      required={isRequired(config)}
      size={fieldSize}
      value={fullValues}
    />
  )
}

export default FormField
