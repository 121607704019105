import { ChartElementValue } from '@leenda/editor/lib/elements'
import { CSSProperties } from 'react'

import { useElementRootStyles } from '../../hooks/useElementRootStyles'
import s from './ChartElement.module.scss'
import { ChartElementType } from './ChartTypes'
import { BarChartRender } from './Charts/BarChartRender'
import { LineChartRender } from './Charts/LineChartRender'
import { PieChartRender } from './Charts/PieChartRender'

const CHARTS: Record<ChartElementValue['type'], ChartElementType> = {
  line: LineChartRender,
  bar: BarChartRender,
  pie: PieChartRender,
} as const

const SIZE_PROPS: Array<keyof CSSProperties> = [
  'maxWidth',
  'maxHeight',
  'minWidth',
  'minHeight',
  'width',
  'height',
]

const ChartElement: ChartElementType = (props) => {
  const { element, styles, mode } = props
  const rootStyles = useElementRootStyles(styles.root)

  const key = SIZE_PROPS.map((key) => styles.shell?.[key]).join(`-${mode.deviceMode}`)

  const Chart = CHARTS[element.value.type || 'line']

  return (
    <div className={s.root} style={rootStyles}>
      <Chart key={key} {...props} />
    </div>
  )
}
export default ChartElement
