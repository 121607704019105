"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getNodesByType = void 0;
const slate_1 = require("slate");
const typeguards_1 = require("./typeguards");
const getNodesByType = (value, type) => {
    const nodes = [];
    value.forEach((value) => {
        for (const [node] of slate_1.Node.descendants(value)) {
            if ((0, typeguards_1.isCustomElement)(node) && node.type === type) {
                nodes.push(node);
            }
        }
    });
    return nodes;
};
exports.getNodesByType = getNodesByType;
