import lodash from 'lodash'
import React, { useState } from 'react'

import { getPath } from 'components/editor-v3/context/EditorContext/selectors/block'
import NodeContainer from 'components/editor-v3/cource/layout/Node/NodeContainer'
import { DeviceMode, PreviewMode } from 'services/Store/Project/enums'
import { useProjectContext } from 'services/Store/Project/hooks'
import { getNodeId } from 'services/Store/Project/selectors'

import { useElementCompleted } from '../../hooks/useElementCompleted'
import { TabPanel } from './Tabs'
import TabsV2 from './Tabs/Tabs'
import s from './TabsElement.module.scss'
import { TabsElementType } from './TabsElement.types'

const TabsElement: TabsElementType = ({ block, font, element, styles, mode, state, setState }) => {
  const mobile = mode.deviceMode === DeviceMode.mobile
  const items = element.value.items
  const [viewedTabs, setViewedTabs] = useState<Record<string, boolean>>(
    items.reduce((acc, item, i) => ({ ...acc, [item.value]: i === 0 ? true : false }), {}),
  )

  const allViewed = lodash.every(viewedTabs)
  const controlledProps: { activeKey?: string } = {}
  const activeId = useProjectContext(getNodeId)
  if (activeId) {
    const path = getPath(block!, activeId)
    controlledProps.activeKey = path.find((id) => items.some((item) => id === item.value))
  }

  const onChangeTab = (value: string) => {
    setState?.({ active: value })
    !allViewed && setViewedTabs((prev) => ({ ...prev, [value]: true }))
  }

  useElementCompleted(block?.uuid || '', element.id, allViewed)

  const isPdf = mode.previewMode === PreviewMode.pdf
  if (isPdf) {
    return (
      <div className={s.root} style={styles.root}>
        {items.map((tab) => (
          <div className={s.tabPdf} key={tab.value}>
            <TabsV2
              activeKey={tab.value}
              font={font}
              key={tab.value}
              mobile={mobile}
              name='tabElement'
              onChange={onChangeTab}
              styleContent={styles?.content}
              styles={styles}
            >
              <TabPanel key={tab.value} name='tabPanel' tabKey={tab.value} title={tab.label}>
                <NodeContainer block={block!} id={tab.value} level={0} mode={mode} />
              </TabPanel>
            </TabsV2>
          </div>
        ))}
      </div>
    )
  }

  return (
    <div className={s.root} style={styles.root}>
      <TabsV2
        activeKey={controlledProps.activeKey || state?.active || items[0]?.value}
        font={font}
        mobile={mobile}
        name='tabElement'
        onChange={onChangeTab}
        styleContent={styles?.content}
        styles={styles}
      >
        {items.map((tab) => (
          <TabPanel key={tab.value} name='tabPanel' tabKey={tab.value} title={tab.label}>
            <NodeContainer block={block!} id={tab.value} level={0} mode={mode} />
          </TabPanel>
        ))}
      </TabsV2>
    </div>
  )
}

export default TabsElement
