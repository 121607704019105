import pako from 'pako'

export const stringifyPako = (data: any): string => {
  const str = JSON.stringify(data)
  const compressed = pako.deflate(str)
  const base64 = btoa(String.fromCharCode(...compressed))
  return base64
}

export const parsePako = (data: string): any => {
  const compressed = atob(data)
  const buffer = Uint8Array.from(compressed, (c) => c.charCodeAt(0))
  const str = pako.inflate(buffer, { to: 'string' })
  return JSON.parse(str)
}
