import { RichTextValue } from '@leenda/rich-text'
import cn from 'classnames'
import lodash from 'lodash'
import { useContext } from 'use-context-selector'

import RichText from 'components/form/RichText/RichText'
import { IconButton } from 'components/uiKit/Button'

import TestMatchingContext from '../TestMatchingContext'
import s from './AnswerTooltip.module.scss'

interface IAnswerTooltipProps {
  handleRightClick: (e: React.MouseEvent<HTMLDivElement>) => void
  hideTooltip: () => void
  title: RichTextValue
  selected: number
  disabled: number[]
  index: number
  font: any
}

const AnswerTooltip = ({
  handleRightClick,
  hideTooltip,
  title,
  selected,
  disabled,
  index,
}: IAnswerTooltipProps) => {
  const {
    right,
    styles,
    isActiveElement,
    onLabelSelect,
    activeIndex,
    onChangeLabel,
    isFill,
    waiting,
    font,
  } = useContext(TestMatchingContext)

  return (
    <div className={s.root}>
      <div className={s.navigation}>
        <IconButton icon='otherClose' name='closeTooltip' onClick={hideTooltip} styleType='ghost' />
      </div>
      <div className={s.title} style={font?.base}>
        <span className={s.text} onMouseDown={(e) => onLabelSelect(index.toString(), e)}>
          <RichText
            active={isActiveElement && activeIndex === index.toString()}
            disabled={!isFill}
            name={`answerTitle.${index}`}
            onChange={(val) => onChangeLabel(val, 'leftLabel', index)}
            styles={font}
            value={title}
            waiting={waiting}
          />
        </span>
      </div>
      <div className={cn(s.content)}>
        {right.map((item, i) => {
          const isDisabled = lodash.indexOf(disabled, i) !== -1
          const itemStyle = {
            ...font?.base,
            ...(selected === i ? styles.focusColor__var : undefined),
            ...(isDisabled ? { ...styles.selected__var } : undefined),
          }

          return (
            <div
              className={s.item}
              id={String(i)}
              key={item.value}
              onClick={handleRightClick}
              onMouseDown={(e) => onLabelSelect(index.toString(), e)}
              style={itemStyle}
            >
              <RichText
                active={isActiveElement && activeIndex === index.toString()}
                disabled={!isFill}
                name={`answer.${index}`}
                onChange={(val) => onChangeLabel(val, 'rightLabel', Number(activeIndex))}
                styles={font}
                value={item.label}
                waiting={waiting}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default AnswerTooltip
