import { PermissionIdentification, PermissionService } from '@leenda/crud'
import { useCallback, useMemo } from 'react'
import React from 'react'

import {
  copyLinkMenuItem,
  createCopyLinkAction,
  pendingMenuItem,
  pinMenuItem,
  resolveMenuItem,
  subscribeMenuItem,
  unsubscribeMenuItem,
} from 'components/comments/actions'
import { CommentActionUiEnum, CommentMock } from 'components/comments/types'
import { AccountMock } from 'components/uiKit/Employee'
import { IMenuOption } from 'components/uiKit/Menu'
import { EmployeeListSchemaFragment } from 'gql/__generated__/graphql'
import { useCommentPermissions } from 'services/Permission/PermissionContext'
import { togglePinnedComment } from 'services/Store/Project/actions'
import { useProjectContext, useProjectDispatch } from 'services/Store/Project/hooks'
import { getIsPinnedComment } from 'services/Store/Project/selectors'
import { getPath } from 'services/Store/Project/selectors/context'
import { selectCurrentEmployee } from 'services/Store/Users/selectors'
import { useAppSelector } from 'services/Store/hooks'

import { Actions, Authors, NameRow, Reply, CommentText } from '../components'
import s from './EditorThread.module.scss'

const getActions = (
  comment: CommentMock,
  permissions: PermissionService<PermissionIdentification>,
  me: EmployeeListSchemaFragment | null,
  pinned: boolean,
) => [
  subscribeMenuItem({ comment, permissions, me }),
  unsubscribeMenuItem({ comment, permissions, me }),
  copyLinkMenuItem({ comment, permissions, me }),
  resolveMenuItem({ comment, permissions, me, inline: true }),
  pendingMenuItem({ comment, permissions, me, inline: true }),
  pinMenuItem({ comment, permissions, me, inline: true, pinned }),
]

interface IEditorThreadProps {
  comment: CommentMock
  mentions: AccountMock[]
  setThread: (thread: CommentMock) => void
}

const EditorThread: React.FC<IEditorThreadProps> = ({ comment, mentions, setThread }) => {
  const path = useProjectContext(getPath)
  const permissions = useCommentPermissions(comment)
  const me = useAppSelector(selectCurrentEmployee) || null
  const dispatch = useProjectDispatch()
  const pinned = useProjectContext(getIsPinnedComment, comment.id)

  const options = useMemo(
    () => getActions(comment, permissions, me, pinned),
    [comment, permissions, pinned, me],
  )

  const handleOpenThread = useCallback(() => setThread(comment), [setThread, comment])

  const handleActionsClick = useCallback(
    async (option: IMenuOption<CommentActionUiEnum>) => {
      if (option.value === CommentActionUiEnum.PIN) {
        dispatch(togglePinnedComment({ id: comment.id }))
      } else if (option.value === CommentActionUiEnum.LINK) {
        await createCopyLinkAction(path, {
          sectionId: comment.sectionId,
          blockId: comment.blockUuid,
          threadId: comment.id,
        })
      }
    },
    [dispatch, comment, path],
  )

  return (
    <div className={s.root}>
      <div className={s.title}>
        <Authors comment={comment} styleType='default' />
        <Actions comment={comment} onClick={handleActionsClick} options={options} />
      </div>
      <NameRow comment={comment} />
      <CommentText comment={comment} mentions={mentions} />
      <Reply comment={comment} onClick={handleOpenThread} />
    </div>
  )
}

export default React.memo(EditorThread)
