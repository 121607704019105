import { gql } from 'gql/__generated__'

export const participantFragment = gql(`
  fragment ParticipantSchema on Participant {
    id
    employee {
      ...EmployeeSmallSchema
    }
    role
    permissionGroups
    createdAt
    updatedAt
  }
`)

export const participantsAll = gql(`
  query ParticipantsAll($projectId: String!, $data: ParticipantInput!) {
    data: participantsAll(projectId: $projectId, data: $data) {
      participants {
        ...ParticipantSchema
      }
      pagination {
        total
        offset
        limit
      }
    }
  }
`)

export const participantsInvite = gql(`
  mutation ParticipantsInvite($projectId: String!, $data: ParticipantCreateInput!) {
    data: participantsInvite(projectId: $projectId, data: $data) {
      participants {
        id
      }
      errors {
        ...ErrorSchema
      }
    }
  }
`)

export const participantsBulkUpdate = gql(`
  mutation ParticipantsBulkUpdate($data: ParticipantUpdateInput!) {
    data: participantsBulkUpdate(data: $data) {
      id
    }
  }
`)

export const participantsBulkDelete = gql(`
  mutation ParticipantsBulkDelete($projectId: String!, $participantIds: [String!]!) {
    data: participantsBulkDelete(projectId: $projectId, participantIds: $participantIds) {
      message
      data
    }
  }
`)
