import * as R from 'ramda'

interface ICalculatePercentage {
  value: number
  min: number
  max: number
}

export const getPosition = (e: React.MouseEvent | React.TouchEvent | MouseEvent | TouchEvent) => {
  const { pageX, pageY, clientX, clientY } = 'touches' in e ? e.touches[0] : e
  return { pageX, pageY, clientX, clientY }
}

export const getValueWithinMinMax = ({ value, min, max }: ICalculatePercentage) =>
  R.clamp(min, max, value)

export const calculatePercentage = ({ value, min, max }: ICalculatePercentage) => {
  const valueWithinMinMax = getValueWithinMinMax({ value, min, max })
  return Math.floor(((valueWithinMinMax - min) / (max - min)) * 100)
}
