import { FileUsageTypeEnum } from '@leenda/editor/lib/files'
import { FileTypeEnum } from 'utils'

import MediaPlayer from 'components/MediaPlayer'
import Icon from 'components/uiKit/Icon'
import { useProjectT } from 'services/ProjectLabels'
import { getFileByUsage } from 'utils/files'

import s from './VideoPreview.module.scss'

const VideoPreview = ({ value }: any) => {
  const pt = useProjectT()
  const mediaFile = getFileByUsage(value, FileUsageTypeEnum.video)

  return (
    <div className={s.root}>
      {mediaFile || value ? (
        <MediaPlayer
          type={FileTypeEnum.VIDEO}
          url={mediaFile?.path || value || null}
          volumeLabel={pt('elements.media.volume')}
        />
      ) : (
        <div className={s.emptyIcon}>
          <Icon name='iconsOtherVideo' />
        </div>
      )}
    </div>
  )
}

export default VideoPreview
