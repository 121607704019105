import * as R from 'ramda'

import { TaskCommentsSubscriptionSubscription } from 'gql/__generated__/graphql'

export const updateQuery = <D>(prev: D, subscriptionData: TaskCommentsSubscriptionSubscription) => {
  const { data: comment, type, id } = subscriptionData.data

  if (type === 'CREATED' && comment) {
    return R.over(R.lensPath(['data', 'comments']), R.append(comment), prev)
  } else if (type === 'UPDATED' && comment) {
    return R.over(R.lensPath(['data', 'comments']), updateComment(comment), prev)
  } else if (type === 'ARCHIVED') {
    return R.over(R.lensPath(['data', 'comments']), filterComment({ id }), prev)
  } else {
    return prev
  }
}

const updateComment = <T extends { id: string }>(newComment: T) =>
  R.map<T, T>((comment) => (comment.id === newComment.id ? newComment : comment))

const filterComment = <T extends { id: string }>({ id }: T) =>
  R.filter<T>((comment) => comment.id !== id)
