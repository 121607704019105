import * as R from 'ramda'
import { createContext, useContextSelector } from 'use-context-selector'

import { IOption, ValueType } from 'components/uiKit/KitTypes'
import { NOOP } from 'constants/commonConstans'

import { IMenuOption } from './MenuItem'

export interface IMenuContext<V extends ValueType> {
  value?: V
  hovered: IMenuOption<V> | null
  setHovered: React.Dispatch<React.SetStateAction<IOption<V> | null>>
}

const MenuContext = createContext<IMenuContext<ValueType>>({
  value: null,
  hovered: null,
  setHovered: NOOP,
})

export const useSetHovered = () => useContextSelector(MenuContext, (c) => c.setHovered)
export const useMenuItemIsHovered = <V>(value: V) =>
  useContextSelector(MenuContext, (c) => c.hovered && c.hovered?.value === value)
export const useMenuItemIsSelected = <V>(value: V) =>
  useContextSelector(MenuContext, (c) =>
    c.value && Array.isArray(c.value)
      ? !R.isNil(value) && c.value.includes(value)
      : c.value === value,
  )

export default MenuContext
