import cn from 'classnames'
import { useCallback } from 'react'

import { getSectionLeftOffsetEditor } from 'components/sections/ProjectStructure/hooks'
import { ISectionTreeNode } from 'components/sections/ProjectStructure/utils'
import Icon from 'components/uiKit/Icon'
import { SectionTypeEnum } from 'services/Store/Project/enums'
import { Section } from 'services/Store/Project/types'

import SectionTreeItemEditor from '../SectionTreeItemEditor'
import s from './SectionTreeNodeEditor.module.scss'

interface ISectionTreeNodeEditorProps<S extends Section> {
  toggle?(active?: boolean): void
  open: boolean
  node: ISectionTreeNode<S>
  onClick?: (item: Section) => void
  isEditSectionId?: string
  setIsEditSectionId: (active: string) => void
  hideActions?: boolean
  sectionId?: string
  disabledByTypes?: SectionTypeEnum[]
}

const SectionTreeNodeEditor = <S extends Section>({
  open,
  node,
  onClick,
  toggle,
  isEditSectionId,
  setIsEditSectionId,
  hideActions,
  sectionId,
  disabledByTypes,
}: ISectionTreeNodeEditorProps<S>) => {
  // const sectionId = useProjectContext(getSectionId)
  const { item, children = [] } = node
  const withChildren = Boolean(children?.length)
  const marginLeft = getSectionLeftOffsetEditor(item, withChildren)

  const contentWrapperCn = cn(s.contentWrapper, item.id === sectionId && s.active)
  const nodeCn = cn(
    s.nodeContent,
    item.type === SectionTypeEnum.cover && s.cover,
    item.isChapter && s.chapter,
  )

  const handleCollapseClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation()
    toggle?.()
  }

  const onSelect = useCallback(
    (item: Section) => {
      onClick?.(item)
      if (item.isChapter) {
        toggle?.()
      }
    },
    [onClick, toggle],
  )

  return (
    <div className={cn(s.root, !item.isChapter && s.offset)}>
      <div className={contentWrapperCn}>
        <div className={s.nodeIndent} style={{ marginLeft }}>
          {item.isChapter && toggle && (
            <div className={s.collapseIconWrapper} onClick={handleCollapseClick}>
              <Icon className={cn(s.collapseIcon, { [s.isOpen]: open })} name='collapseOpen' />
            </div>
          )}
          <div className={nodeCn}>
            <SectionTreeItemEditor
              disabledByTypes={disabledByTypes}
              hideActions={hideActions}
              isEditSectionId={isEditSectionId}
              item={node.item}
              onSelect={onSelect}
              sectionId={sectionId}
              setIsEditSectionId={setIsEditSectionId}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SectionTreeNodeEditor
