import cn from 'classnames'
import React from 'react'

import Empty from 'components/uiKit/Empty'
import { KitSize } from 'components/uiKit/KitTypes'
import { t } from 'services/Translation'
import { notEmpty } from 'utils/notEmpty'

import s from './TabContent.module.scss'

interface ITabContentProps {
  hidden?: boolean
  children: React.ReactNode
  style?: React.CSSProperties
  textError?: string
}

const TabContent: React.FC<ITabContentProps> = ({ children, hidden, style, textError }) => {
  const hasChildren = !!React.Children.map(children, (child) =>
    !React.isValidElement(child) ? null : child,
  )?.filter(notEmpty).length

  if (!hasChildren) {
    return <Empty size={KitSize.XS} text={textError || t('common.noData')} grow />
  }

  return (
    <div className={cn(s.root, { [s.hidden]: hidden })} style={style}>
      {children}
    </div>
  )
}

export default TabContent
