import { CourseStructureSchemaType, CourseStructureFontSchemaType } from '@leenda/editor/lib/brand'
import {
  CollapseIconPosition,
  CourseStructureItem,
  ProgressIconPosition,
} from '@leenda/editor/lib/elements'
import React from 'react'

import { listToTree } from 'components/sections/ProjectStructure/utils'
import { ElementFontCss, ElementStyleCss } from 'services/Branding/types'

import s from './CourseStructureTree.module.scss'
import { CourseCollapseStructure } from './components/CourseCollapseStructure'

type PreviewTreeSecondaryProps = {
  sections: CourseStructureItem[]
  onSectionPick: (sectionId: string) => void
  initiallyExpanded?: boolean | null
  onSelect: (activeId: string) => void
  displayConfig: StructureElementDisplayConfig
  style?: React.CSSProperties
  isEditor?: boolean
}

export type StructureElementDisplayConfig = {
  showDescription: boolean
  showIcon: boolean
  collapsePosition: CollapseIconPosition
  progressPosition: ProgressIconPosition
  styles?: ElementStyleCss<CourseStructureSchemaType>
  font?: ElementFontCss<CourseStructureFontSchemaType>
  initialOpen: boolean
  activeId?: string | null
}

export const getConfigElement = (
  activeId?: string,
  options?: StructureElementDisplayConfig,
): StructureElementDisplayConfig => ({
  showDescription: options?.showDescription ?? true,
  showIcon: options?.showIcon ?? true,
  collapsePosition: options?.collapsePosition || CollapseIconPosition.left,
  progressPosition: options?.progressPosition || ProgressIconPosition.none,
  activeId,
  initialOpen: options?.initialOpen ?? true,
  styles: options?.styles,
  font: options?.font,
})

const CourseStructureTree = ({
  sections,
  onSectionPick,
  displayConfig,
  style,
}: PreviewTreeSecondaryProps) => {
  const preparedSectionsAll = listToTree(sections)

  return (
    <div className={s.root} style={style}>
      <CourseCollapseStructure
        displayConfig={displayConfig}
        nodes={preparedSectionsAll}
        onSectionPick={onSectionPick}
      />
    </div>
  )
}

export default CourseStructureTree
