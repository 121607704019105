import { gql } from 'gql/__generated__'

export const blocksGetById = gql(`
  query BlocksGetById($sectionId: String!, $uuid: String!) {
    data: editorBlocksGetById(sectionId: $sectionId, uuid: $uuid) {
      ...BlockSchema
    }
  }
`)

export const blocksGetAllQuery = gql(`
  query BlocksAll($sectionId: String!) {
    data: editorBlocksAll(sectionId: $sectionId) {
      ...BlockSchema
    }
  }
`)

export const getBlockPatches = gql(`
  query EditorBlocksPatches($uuid: String!, $search: BlocksPatchesSearch) {
    data: editorBlocksPatches(uuid: $uuid, search: $search) {
      patches {
        blockId
        patch
        employeeId
        createdAt
        updatedAt
        version
        meta
        release
        id
      }
      pagination {
        limit
        offset
        total
      }
    }
  }
`)

export const blocksDeleted = gql(`
  query EditorBlocksArchived($companyId: ID!, $sectionId: ID!) {
    data: editorBlocksArchived(companyId: $companyId, sectionId: $sectionId) {
      ...BlockSchema
    }
  }
`)
