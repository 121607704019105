import s from './EmptyContent.module.scss'
import { ReactComponent as EmptyOverlay } from './empty.svg'

const EmptyContent = ({ textError }: { textError?: string }) => {
  return (
    <div className={s.root}>
      <EmptyOverlay />
      <span>{textError || 'not found'}</span>
    </div>
  )
}

export default EmptyContent
