import { PermissionsKey } from '@leenda/crud'
import { PermissionService } from '@leenda/crud'
import { colorsTypes } from 'utils'

import { useTags, useTemplates } from 'components/editor-v3/Editor/Modals/TemplatePickerModal/hooks'
import { COMPANY_COLLECTION_PATHS } from 'constants/paths'
import {
  TemplateCollectionCreatedFor,
  TemplateCollectionUpdateInput,
} from 'gql/__generated__/graphql'
import { useGetAllBrandSelectItems } from 'gql/brands.apollo'
import { useTemplatesGetGroup } from 'gql/templates.apollo'
import { usePathParams } from 'routes/hooks'
import { t } from 'services/Translation'

export const useGetInitialGroupData = (
  action: 'create' | 'update',
  companyId: string,
  selected?: string,
) => {
  const { data } = useGetAllBrandSelectItems({ companyId })
  const dataValues: TemplateCollectionUpdateInput = useGetTemplateGroup({
    companyId,
    collectionId: selected,
  })

  const defaultBrand = data?.data.brands.find((brand) => brand.isDefault)
  const defaultOption = { value: defaultBrand?.id, label: defaultBrand?.name }
  const defaultValues = {
    name: '',
    color: colorsTypes.primary,
    brandId: defaultOption.value || null,
  }
  const editValues = { name: dataValues.name, color: dataValues.color, brandId: dataValues.brandId }

  if (action === 'create') {
    return {
      title: t('modal.createTemplate.title'),
      initialValues: defaultValues,
      formLabels: { save: t('uiKit.button.create'), cancel: t('uiKit.button.cancel') },
    }
  } else {
    return {
      title: t('modal.editTemplateGroup.title'),
      initialValues: editValues,
      formLabels: { save: t('uiKit.button.save'), cancel: t('uiKit.button.cancel') },
    }
  }
}

export const useGetTemplateGroup = ({
  companyId,
  collectionId,
}: {
  companyId: string
  collectionId?: string
}) => {
  const { data } = useTemplatesGetGroup({ companyId, data: {} })

  const templateGroup = data?.data.templateCollections.find((group) => group.id === collectionId)
  return templateGroup || {}
}

export const useGetInitialCollectionData = ({
  selectedId,
  search,
}: {
  selectedId: string
  search?: string
}) => {
  const { collectionId, companyId } = usePathParams(COMPANY_COLLECTION_PATHS.collection)
  const { tag } = useTags({ tagFromLocalStorage: true })
  const data = useTemplates({
    companyId,
    editorTemplateGroupId: collectionId,
    search,
    tag,
  })
  const templates = data.templates || []

  const selectedTemplate = templates.find((template) => template.uuid === selectedId)

  return {
    initialValues: {
      name: selectedTemplate?.name || '',
      tags: selectedTemplate?.tags || '',
    },
    collection: selectedTemplate,
    companyId,
    loading: data.loading,
  }
}

export const getCollectionPermission = (
  permissions: PermissionService<any>,
  action: 'c' | 'r' | 'u' | 'd',
  createdFor = TemplateCollectionCreatedFor.company,
) => {
  let key
  if (action === 'c') {
    key = {
      [TemplateCollectionCreatedFor.general]: null,
      [TemplateCollectionCreatedFor.company]: [PermissionsKey.company_collections_company_c],
      [TemplateCollectionCreatedFor.employee]: null,
      [TemplateCollectionCreatedFor.project]: null,
    }[createdFor]
  }
  if (action === 'r') {
    key = {
      [TemplateCollectionCreatedFor.general]: null,
      [TemplateCollectionCreatedFor.company]: [PermissionsKey.company_collections_company_r],
      [TemplateCollectionCreatedFor.employee]: [PermissionsKey.company_collections_self],
      [TemplateCollectionCreatedFor.project]: [PermissionsKey.project_collection_r],
    }[createdFor]
  }
  if (action === 'u') {
    key = {
      [TemplateCollectionCreatedFor.general]: null,
      [TemplateCollectionCreatedFor.company]: [PermissionsKey.company_collections_company_u],
      [TemplateCollectionCreatedFor.employee]: [PermissionsKey.company_collections_self],
      [TemplateCollectionCreatedFor.project]: [PermissionsKey.project_collection_u],
    }[createdFor]
  }
  if (action === 'd') {
    key = {
      [TemplateCollectionCreatedFor.general]: null,
      [TemplateCollectionCreatedFor.company]: [PermissionsKey.company_collections_company_d],
      [TemplateCollectionCreatedFor.employee]: null,
      [TemplateCollectionCreatedFor.project]: null,
    }[createdFor]
  }
  return key ? permissions.has(key) : false
}
