import { useAppDispatch, useAppSelector } from '../hooks'
import { IProjectContext } from './types'

type RestState<Args extends unknown[]> = ((...p: Args) => void) extends (
  p1: infer P1,
  ...rest: infer R
) => void
  ? R
  : never

export const useProjectContext = <
  S extends (s: IProjectContext, ...p: RestState<Parameters<S>>) => ReturnType<S>,
>(
  selector: S,
  ...params: RestState<Parameters<S>>
) => useAppSelector((context) => selector(context.project, ...params))

export const useProjectPath = () => useAppSelector(({ project }) => project.path)

export const useProjectDispatch = useAppDispatch
