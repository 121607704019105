import { useCallback } from 'react'

import { IControlProps } from 'components/controls/Field/Field.types'
import Slider from 'components/uiKit/Slider'
import { ISliderProps } from 'components/uiKit/Slider/Slider'

const FieldSlider: React.FC<IControlProps<ISliderProps>> = ({
  name,
  onChange,
  defaultValue,
  value,
  ...rest
}) => {
  const onChangeWrapper = useCallback(
    (value?: number) => onChange({ name, value }),
    [name, onChange],
  )

  return <Slider {...rest} name={name} onChange={onChangeWrapper} value={value ?? defaultValue} />
}

export default FieldSlider
