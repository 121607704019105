export const saveCaretPosition = (spanRef: React.RefObject<HTMLSpanElement>) => {
  try {
    const selection = window.getSelection()
    const range = selection?.getRangeAt(0)
    const preCaretRange = range?.cloneRange()
    spanRef.current && preCaretRange?.selectNodeContents(spanRef.current)
    range && preCaretRange?.setEnd(range.endContainer, range.endOffset)
    return preCaretRange?.toString().length
  } catch (error) {
    return 0
  }
}

export const restoreCaretPosition = (
  spanRef: React.RefObject<HTMLSpanElement>,
  caretPos: number,
) => {
  try {
    const selection = window.getSelection()
    const range = document.createRange()
    spanRef.current && range.setStart(spanRef.current.childNodes[0], caretPos)
    spanRef.current && range.setEnd(spanRef.current.childNodes[0], caretPos)
    selection?.removeAllRanges()
    selection?.addRange(range)
  } catch (error) {}
}

export const clearBreaks = (text: string) => text.replace(/\r\n|\r|\n|\u2028|\u2029/g, '')
