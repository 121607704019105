import cn from 'classnames'
import React from 'react'

import { BlockMode } from 'services/Store/Project/enums'
import { getEnumLabel } from 'utils/enum'

import s from './BlockTypeLabel.module.scss'

interface IBlockTypeLabelProps {
  mode?: BlockMode
  empty?: boolean
}

const BlockTypeLabel: React.FC<IBlockTypeLabelProps> = ({ mode, empty }) => {
  const modeLabel = getEnumLabel('BlockMode', mode || BlockMode.view)

  if (mode === BlockMode.view || mode === BlockMode.questions) {
    return null
  }

  return <div className={cn(s.mode, mode && s[mode], { [s.empty]: empty })}>{modeLabel}</div>
}

export default BlockTypeLabel
