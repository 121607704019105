import { generateId } from '@leenda/editor/lib/utils/id'

import { NodeType } from 'components/editor-v3/types/date.enums'
import { DeviceMode } from 'services/Store/Project/enums'

export const createColNode = (children: string[] = []) => ({
  id: generateId(),
  type: NodeType.column,
  children: children,
  style: {
    [DeviceMode.desktop]: {},
  },
})
export const createAbsoluteNode = (children: string[] = []) => ({
  id: generateId(),
  type: NodeType.absolute,
  children: children,
  style: {
    [DeviceMode.desktop]: {},
  },
})
export const createRowNode = (children: string[] = []) => ({
  id: generateId(),
  type: NodeType.row,
  children,
  style: {
    [DeviceMode.desktop]: {
      minHeight: 40,
    },
  },
})
export const createShell = () => ({
  id: generateId(),
  type: NodeType.shell,
  [DeviceMode.desktop]: {},
  [DeviceMode.tablet]: {},
  [DeviceMode.mobile]: {},
})
