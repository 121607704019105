import * as Sentry from '@sentry/browser'

import { config } from '../config'

export const initSentry = () => {
  Sentry.init({
    dsn: 'https://125f063d47e94e2593aa54085019c05e@o4504994932523008.ingest.sentry.io/4504994936455168',
    integrations: [
      new Sentry.BrowserTracing(),
      new Sentry.Replay({
        // Additional SDK configuration goes in here, for example:
        maskAllText: true,
        blockAllMedia: true,
      }),
    ],
    release: config.default.versionFrontend,
    environment: config.default.environment,
    tracesSampleRate: 1.0,
  })
}
