import { CrossLinkElement, LinkElement, SlateElementType, INLINE_ELEMENTS } from '@leenda/rich-text'
import { Editor, Element, Transforms, Range, MaximizeMode, BaseSelection } from 'slate'

import { validateLinkUrl } from 'utils/websiteValidation'

import { clearTextMark } from '../formatOperations/commands'

const inlineCollapsed = (editor: Editor, selection: BaseSelection) => {
  if (selection) {
    const [start] = Range.edges(selection)
    const before = Editor.before(editor, start, { unit: 'word' })
    const after = Editor.after(editor, start, { unit: 'word' })

    const startPoint = before ?? start
    const endPoint = after ?? start
    const wordRange = { anchor: startPoint, focus: endPoint }

    Transforms.select(editor, wordRange)
  }
}

export const withInlines = (editor: Editor) => {
  const { insertData, insertText, isInline } = editor
  editor.isInline = (element) => INLINE_ELEMENTS.includes(element.type) || isInline(element)

  editor.insertText = (text) => {
    if (text && validateLinkUrl(text)) {
      wrapLink(editor, text)
    } else {
      insertText(text)
    }
  }

  editor.insertData = (data) => {
    const text = data.getData('text/plain')

    if (text && validateLinkUrl(text)) {
      wrapLink(editor, text)
    } else {
      insertData(data)
    }
  }

  return editor
}

export const wrapCrossLink = (editor: Editor, value: any) => {
  unwrapInline(editor)
  const { selection } = editor
  const isCollapsed = selection && Range.isCollapsed(selection)
  if (!value) {
    return
  }
  if (isCrossLinkActive(editor)) {
    updateCrossLink(editor, value)
    return
  }

  if (isCollapsed) {
    inlineCollapsed(editor, selection)
  }

  const crossLink: CrossLinkElement = {
    type: SlateElementType.crossLink,
    value,
    children: isCollapsed ? [{ text: value?.section }] : [],
  }

  Transforms.wrapNodes(editor, crossLink, { split: true })
}

const updateCrossLink = (editor: Editor, value: any) => {
  const [crossLink] = Editor.nodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) && Element.isElement(n) && n.type === SlateElementType.crossLink,
  })
  Transforms.setNodes(editor, { value }, { at: crossLink ? crossLink[1] : undefined })
}

const updateLink = (editor: Editor, url: string) => {
  const [link] = Editor.nodes(editor, {
    match: (n) => !Editor.isEditor(n) && Element.isElement(n) && n.type === SlateElementType.link,
  })
  Transforms.setNodes(editor, { url }, { at: link ? link[1] : undefined })
}

export const wrapLink = (editor: Editor, url: string) => {
  unwrapInline(editor)
  if (!url) {
    return
  }
  if (isLinkActive(editor)) {
    updateLink(editor, url)
    return
  }

  const { selection } = editor
  const isCollapsed = selection && Range.isCollapsed(selection)
  if (isCollapsed) {
    inlineCollapsed(editor, selection)
  }
  const link: LinkElement = {
    type: SlateElementType.link,
    url,
    children: isCollapsed ? [{ text: url }] : [],
  }

  clearTextMark(editor)

  Transforms.wrapNodes(editor, link, { split: true })
}

export const wrapCode = (editor: Editor, value: boolean) => {
  unwrapInline(editor)
  const { selection } = editor
  const isCollapsed = selection && Range.isCollapsed(selection)

  if (isCodeActive(editor) || !value) {
    return
  }

  const codeElement = {
    type: SlateElementType.code,
    children: isCollapsed ? [{ text: '' }] : [],
  }

  if (isCollapsed) {
    Transforms.insertNodes(editor, codeElement)
  } else {
    Transforms.wrapNodes(editor, codeElement, { split: true })
  }
}

export const unwrapAnnotation = (editor: Editor, mode?: MaximizeMode) => {
  const selection = editor.selection
  let at = undefined
  if (selection && mode === 'all') {
    const path = Editor.path(editor, selection)
    const start = Editor.start(editor, path)
    const end = Editor.end(editor, path)
    at = { anchor: start, focus: end }
  }
  Transforms.unwrapNodes(editor, {
    mode,
    match: (n) =>
      !Editor.isEditor(n) && Element.isElement(n) && n.type === SlateElementType.annotation,
    at,
  })
}

export const updateAnnotation = (editor: Editor, value: string) => {
  const { selection } = editor
  if (!selection) {
    return
  }

  const [annotation] = Editor.nodes(editor, {
    at: selection,
    match: (n) =>
      !Editor.isEditor(n) && Element.isElement(n) && n.type === SlateElementType.annotation,
  })

  if (annotation) {
    const [, path] = annotation
    Transforms.setNodes(editor, { value }, { at: path })
  }
}

export const wrapAnnotation = (editor: Editor, value: string) => {
  unwrapInline(editor)
  const { selection } = editor
  const isCollapsed = selection && Range.isCollapsed(selection)
  if (!value) {
    return
  }

  if (isAnnotationActive(editor)) {
    updateAnnotation(editor, value)
    return
  }

  if (isCollapsed) {
    inlineCollapsed(editor, selection)
  }

  const annotation = {
    type: SlateElementType.annotation,
    value,
    children: isCollapsed ? [{ text: value }] : [],
  }

  Transforms.wrapNodes(editor, annotation, { split: true })
}

export const getLinkElement = (editor: Editor) => {
  if (!editor.selection) {
    return null
  }
  const [link] = Editor.nodes(editor, {
    at: editor.selection && Editor.start(editor, editor.selection),
    match: (n) => !Editor.isEditor(n) && Element.isElement(n) && n.type === SlateElementType.link,
  })
  return link?.[0]
}

export const getCrossLinkElement = (editor: Editor) => {
  if (!editor.selection) {
    return null
  }
  const [crossLink] = Editor.nodes(editor, {
    at: editor.selection && Editor.start(editor, editor.selection),
    match: (n) => {
      return !Editor.isEditor(n) && Element.isElement(n) && n.type === SlateElementType.crossLink
    },
  })
  return crossLink?.[0]
}

export const getAnnotationElement = (editor: Editor) => {
  if (!editor.selection) {
    return null
  }
  const [annotation] = Editor.nodes(editor, {
    at: editor.selection && Editor.start(editor, editor.selection),
    match: (n) =>
      !Editor.isEditor(n) && Element.isElement(n) && n.type === SlateElementType.annotation,
  })
  return annotation?.[0]
}

export const getInlineElement = (editor: Editor) => {
  if (!editor.selection) {
    return null
  }
  const [inline] = Editor.nodes(editor, {
    at: editor.selection && Editor.start(editor, editor.selection),
    match: (n) => !Editor.isEditor(n) && Element.isElement(n) && editor.isInline(n),
  })
  return inline?.[0]
}

export const isLinkActive = (editor: Editor) => {
  return !!getLinkElement(editor)
}

export const isCrossLinkActive = (editor: Editor) => {
  return !!getCrossLinkElement(editor)
}

export const isCodeActive = (editor: Editor) => {
  const [match] = Editor.nodes(editor, {
    match: (n) => !Editor.isEditor(n) && Element.isElement(n) && n.type === SlateElementType.code,
  })

  return !!match
}

export const isAnnotationActive = (editor: Editor) => {
  return !!getAnnotationElement(editor)
}

export const unwrapInline = (editor: Editor) => {
  const { selection } = editor

  if (selection) {
    const [inline] = Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) => !Editor.isEditor(n) && Element.isElement(n) && editor.isInline(n),
    })

    if (inline) {
      Transforms.unwrapNodes(editor, {
        match: (n) => !Editor.isEditor(n) && Element.isElement(n) && editor.isInline(n),
        split: true,
      })
    }
  }
}
