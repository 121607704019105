import { PLATFORM_CONFIG } from 'platform/platformComfig'
import React from 'react'

import { useContactSales } from 'components/banner/useContactSales'
import Button, { IButtonPropsType } from 'components/uiKit/Button'
import { t } from 'services/Translation'

interface IContactSalesButtonProps extends Omit<IButtonPropsType, 'children'> {
  email: string
  name: string
}

const ContactSalesButton: React.FC<IContactSalesButtonProps> = ({
  email,
  name,
  ...buttonProps
}) => {
  const { mount, open } = useContactSales({ email, name })

  const handleClick = () => {
    if (PLATFORM_CONFIG.PlatformName === 'Parta.io') {
      window.open('https://buy.stripe.com/28o7tR8dN7BNdLacMM', '_blank')
      return
    } else {
      open()
    }
  }

  return (
    <>
      <Button
        name='contactSales'
        onClick={handleClick}
        styleType='secondary'
        inheritColor
        {...buttonProps}
      >
        {t('notify.contactSales')}
      </Button>
      {mount}
    </>
  )
}

export default ContactSalesButton
