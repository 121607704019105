import React, { useCallback } from 'react'

import { IControlProps } from 'components/controls/Field/Field.types'
import PhoneInput, { Props } from 'components/uiKit.todo/PhoneInput'

const FieldPhone: React.FC<IControlProps<Props<{ name: string }>>> = ({
  name,
  onChange,
  defaultValue,
  value,
  ...restProps
}) => {
  const onChangeWrapper = useCallback(
    (value: Props<{ name: string }>['value']) => onChange({ name, value: (value ?? null) as any }),
    [name, onChange],
  )
  return (
    <PhoneInput
      {...restProps}
      name={name}
      onChange={onChangeWrapper}
      value={value ?? (defaultValue as string)}
    />
  )
}

export default FieldPhone
