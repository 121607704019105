import { PermissionIdentification, PermissionService, PermissionsKey } from '@leenda/crud'
import { useMount } from 'ahooks'
import React, { useCallback, useMemo, useRef } from 'react'

import { LayoutScroll } from 'components/LayoutPage'
import { ScrollRef } from 'components/LayoutPage/LayoutScroll/LayoutScroll'
import CommentForm from 'components/comments/CreateComment'
import {
  closeMenuItem,
  copyLinkMenuItem,
  createCopyLinkAction,
  deleteMenuItem,
  pendingMenuItem,
  pinMenuItem,
  resolveMenuItem,
  subscribeMenuItem,
  unsubscribeMenuItem,
} from 'components/comments/actions'
import { EditorComment } from 'components/comments/comment'
import Actions from 'components/comments/comment/components/Actions/Actions'
import Path from 'components/comments/comment/components/Path'
import { CommentMock, CommentActionUiEnum } from 'components/comments/types'
import Employee from 'components/uiKit/Employee'
import { KitSize } from 'components/uiKit/KitTypes'
import { IMenuOption } from 'components/uiKit/Menu'
import { EmployeeListSchemaFragment } from 'gql/__generated__/graphql'
import { useCommentSetRead } from 'gql/comments/apollo'
import {
  SORT_BY_STATUS,
  useCommentPermissions,
  useEmployeesByPermissionInProject,
} from 'services/Permission/PermissionContext'
import { SCROLL_DURATION } from 'services/Scroll/ScrollService'
import { togglePinnedComment } from 'services/Store/Project/actions'
import { useProjectContext, useProjectDispatch } from 'services/Store/Project/hooks'
import {
  getBlock,
  getIsPinnedComment,
  getProjectId,
  getSectionId,
} from 'services/Store/Project/selectors'
import { getPath } from 'services/Store/Project/selectors/context'
import { selectCurrentEmployee } from 'services/Store/Users/selectors'
import { useAppSelector } from 'services/Store/hooks'

import s from './DropdownThread.module.scss'

const getActions = (
  comment: CommentMock,
  permissions: PermissionService<PermissionIdentification>,
  me: EmployeeListSchemaFragment | null,
  pinned: boolean,
) => [
  subscribeMenuItem({ comment, permissions, me }),
  unsubscribeMenuItem({ comment, permissions, me }),
  deleteMenuItem({ comment, permissions, me }),
  copyLinkMenuItem({ comment, permissions, me }),
  pinMenuItem({ comment, permissions, me, inline: true, pinned }),
  pendingMenuItem({ comment, permissions, me, inline: true }),
  resolveMenuItem({ comment, permissions, me, inline: true }),
  closeMenuItem({ comment, permissions, me, inline: true }),
]

interface IDropdownThreadProps {
  comment: CommentMock
  onClose: (visible: boolean) => void
}

const DropdownThread: React.FC<IDropdownThreadProps> = ({ onClose, comment }) => {
  const [setRead] = useCommentSetRead()
  const scrollRef = useRef<ScrollRef>(null)
  const dispatch = useProjectDispatch()
  const path = useProjectContext(getPath)
  const me = useAppSelector(selectCurrentEmployee) || null
  const permissions = useCommentPermissions(comment)
  const canComment = permissions.has(PermissionsKey.project_comments_c)
  const projectId = useProjectContext(getProjectId)
  const sectionId = useProjectContext(getSectionId)
  const block = useProjectContext(getBlock, { id: comment.blockUuid })
  const isPinned = useProjectContext(getIsPinnedComment, comment.id)
  const actions = useMemo(
    () => getActions(comment, permissions, me, isPinned),
    [comment, isPinned, me, permissions],
  )

  const { all } = useEmployeesByPermissionInProject({
    projectId,
    pk: PermissionsKey.project_employeesFilter_comments_mention,
    includeCommentator: true,
    excludeSelf: true,
    sort: SORT_BY_STATUS,
  })

  const onClick = useCallback(
    async (option: IMenuOption<CommentActionUiEnum>) => {
      if (option.value === CommentActionUiEnum.CLOSE) {
        onClose(false)
      }
      if (option.value === CommentActionUiEnum.PIN) {
        !isPinned && onClose(false)
        dispatch(togglePinnedComment({ id: comment.id }))
      } else if (option.value === CommentActionUiEnum.LINK) {
        await createCopyLinkAction(path, {
          sectionId: comment.sectionId,
          blockId: comment.blockUuid,
          threadId: comment.id,
        })
      }
    },
    [dispatch, onClose, comment, isPinned, path],
  )

  const handleSendComment = useCallback(() => {
    setTimeout(() => scrollRef.current?.osInstance?.()?.scroll({ y: '100%' }, SCROLL_DURATION))
  }, [])

  useMount(() => {
    setRead({ variables: { id: comment.id } })
    setTimeout(() => scrollRef.current?.osInstance?.()?.scroll({ y: '100%' }, SCROLL_DURATION))
  })

  return (
    <div className={s.root}>
      <div className={s.title}>
        <Path block={block} comment={comment} size={KitSize.S} />
        <Actions comment={comment} onClick={onClick} options={actions} />
      </div>
      <LayoutScroll ref={scrollRef} sizeAutoCapable>
        <div className={s.content}>
          <EditorComment comment={comment} key={comment.id} mentions={all} />
          {comment.children?.map((child) => (
            <EditorComment comment={child} key={child.id} mentions={all} />
          ))}
        </div>
      </LayoutScroll>
      <div className={s.input}>
        <CommentForm
          avatar={<Employee employee={me} size={KitSize.M} styleType='avatar' />}
          disabled={!canComment}
          mentions={all}
          name='dropdownThread'
          onSubmit={handleSendComment}
          payload={{ sectionId, blockUuid: comment.blockUuid, parentId: comment.id }}
          autofocus
        />
      </div>
    </div>
  )
}

export default DropdownThread
