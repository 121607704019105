import { CourseStructureItem } from '@leenda/editor/lib/elements'
import cn from 'classnames'
import React from 'react'

import Highlighter from 'components/uiKit/Highlighter'
import Icon from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'
import { BrandType } from 'services/Branding/types'
import { useProjectT } from 'services/ProjectLabels'
import { scrollProps } from 'services/Scroll/ScrollService'
import { BlockMode, SectionTypeEnum } from 'services/Store/Project/enums'
import { getEnumLabel } from 'utils/enum'
import { testPropsEl } from 'utils/test/qaData'

import { StructureElementDisplayConfig } from '../../CourseStructureTree'
import s from './StructureItemElement.module.scss'

const IconMap = {
  [SectionTypeEnum.landing]: <Icon name='contentsLanding' size={KitSize.S} />,
  [SectionTypeEnum.test]: <Icon name='contentsTest' size={KitSize.S} />,
  [SectionTypeEnum.cover]: <Icon name='contentsSlides' size={KitSize.S} />,
  [SectionTypeEnum.chapter]: <Icon name='contentsChapter' size={KitSize.S} />,
}

const NameMap = {
  [SectionTypeEnum.landing]: (pt: (key: keyof BrandType['labels']) => string) =>
    pt('courseStructure.landing'),
  [SectionTypeEnum.test]: (pt: (key: keyof BrandType['labels']) => string) =>
    pt('courseStructure.test'),
  [SectionTypeEnum.cover]: (pt: (key: keyof BrandType['labels']) => string) =>
    pt('courseStructure.cover'),
  [SectionTypeEnum.chapter]: (pt: (key: keyof BrandType['labels']) => string) =>
    pt('courseStructure.chapter'),
}

const useDescription = (
  displayConfig: StructureElementDisplayConfig,
  item: CourseStructureItem,
) => {
  const pt = useProjectT()
  if (item.isChapter || item.type === SectionTypeEnum.landing) {
    return null
  }
  const { blocks, type } = item
  const name = NameMap[type as SectionTypeEnum]

  const blocksCount = blocks.length
  // TODO: Separate logic for editor and preview to the random constraint
  const questionsCount = blocks.filter(({ mode }) => mode === BlockMode.questions).length

  const countDetails = `${
    type === SectionTypeEnum.test
      ? `${questionsCount} ${pt('courseStructure.questions')}`
      : `${blocksCount} ${pt('courseStructure.blocks')}`
  }`
  return (
    blocksCount !== undefined && (
      <div className={cn(s.details)} style={displayConfig.font?.description}>
        <span>{name(pt) || getEnumLabel('SectionTypeEnum', item.type)}</span>
        <span className={s.separator}>&bull;</span>
        <span>{countDetails}</span>
      </div>
    )
  )
}

interface IItemsProps {
  displayConfig: StructureElementDisplayConfig
  item: CourseStructureItem
  onSelect?: (sectionId: string) => void
  searchValue?: string
}

const StructureItemElement: React.FC<IItemsProps> = ({
  displayConfig,
  item,
  onSelect,
  searchValue,
}) => {
  const pt = useProjectT()
  const description = useDescription(displayConfig, item)
  const titleStyle = item.isChapter ? displayConfig.font?.chapter : displayConfig.font?.section
  const icon = IconMap[item.type as SectionTypeEnum]

  return (
    <div
      {...testPropsEl('sectionItem', { label: item.name }, { id: item.id })}
      {...scrollProps(item.id)}
      className={s.item}
      onClick={() => onSelect && onSelect(item.id)}
      style={{ color: titleStyle?.color }}
    >
      {displayConfig.showIcon && <div className={s.itemIcon}>{icon}</div>}
      <div className={s.itemContent}>
        <div className={s.itemTitle} style={titleStyle}>
          {item.name ? (
            <Highlighter search={searchValue} text={item.name} />
          ) : (
            pt('courseStructure.chapterName')
          )}
        </div>
        {displayConfig.showDescription && description}
      </div>
    </div>
  )
}

export default StructureItemElement
