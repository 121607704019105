import cn from 'classnames'
import { CSSProperties } from 'react'

import s from './TabContent.module.scss'

interface ITabContentProps {
  hidden?: boolean
  styles?: CSSProperties
  children?: React.ReactNode
}

const TabContent: React.FC<ITabContentProps> = ({ children, hidden, styles }) => (
  <div className={cn(s.root, { [s.hidden]: hidden })} style={styles}>
    {children}
  </div>
)

export default TabContent
