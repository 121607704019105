"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SlateElementType = exports.SlateMark = exports.SlateElementMark = void 0;
var SlateElementMark;
(function (SlateElementMark) {
    SlateElementMark["textAlign"] = "textAlign";
})(SlateElementMark || (exports.SlateElementMark = SlateElementMark = {}));
var SlateMark;
(function (SlateMark) {
    SlateMark["bold"] = "bold";
    SlateMark["italic"] = "italic";
    SlateMark["underline"] = "underline";
    SlateMark["strikethrough"] = "strikethrough";
    SlateMark["backgroundColor"] = "backgroundColor";
    SlateMark["color"] = "color";
    SlateMark["textTransform"] = "textTransform";
    SlateMark["register"] = "register";
})(SlateMark || (exports.SlateMark = SlateMark = {}));
var SlateElementType;
(function (SlateElementType) {
    SlateElementType["blockquote"] = "blockquote";
    SlateElementType["numberedList"] = "numberedList";
    SlateElementType["bulletedList"] = "bulletedList";
    SlateElementType["listItem"] = "listItem";
    SlateElementType["heading1"] = "heading1";
    SlateElementType["heading2"] = "heading2";
    SlateElementType["heading3"] = "heading3";
    SlateElementType["heading4"] = "heading4";
    SlateElementType["elementDefault"] = "elementDefault";
    SlateElementType["link"] = "link";
    SlateElementType["crossLink"] = "crossLink";
    SlateElementType["mention"] = "mention";
    SlateElementType["code"] = "code";
    SlateElementType["annotation"] = "annotation";
    SlateElementType["caption"] = "caption";
})(SlateElementType || (exports.SlateElementType = SlateElementType = {}));
