import { t } from 'services/Translation'

import type { SelectValueType } from '../components/uiKit/Select'

export const EMAIL_SEPARATORS = [',', '|', ' ', ';', '\n']

export const validateEmail = /^[\w.%+-]+@[\w.-]+\.\w+$/

export const validateEmailOptional = /^[\w.%+-]+@[\w.-]+\.\w+$|^\s*$/

export const validateEmails = (
  _: unknown,
  value?: string | string[] | number | number[] | boolean | null,
) => {
  const isValidEmails =
    Array.isArray(value) &&
    (value as string[])
      ?.map((val) => validateEmail.test(String(val).toLowerCase()))
      .some((el) => el === false)

  if (isValidEmails && Array.isArray(value) && value.length > 0) {
    return t('input.error.invalidEmail')
  }

  return false
}

export const emailValidation = (_rule: unknown, value?: SelectValueType) => {
  const isValidEmails =
    Array.isArray(value) &&
    (value as string[])
      ?.map((val) => validateEmail.test(String(val).toLowerCase()))
      .some((el) => el === false)

  if (isValidEmails && Array.isArray(value) && value.length > 0) {
    return t('input.error.invalidEmail')
  }

  return false
}
