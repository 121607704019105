import { PLATFORM_CONFIG } from 'platform/platformComfig'

export const passwordValidation = (_rule: any, value?: string) => {
  if (value && value.length < PLATFORM_CONFIG.passwordMinLength) {
    return true
  }
  if (value) {
    const reg = /[A-Z]/
    const upperSymbols = reg.test(value)
    if (!upperSymbols) {
      return true
    }
  }
  if (value) {
    const reg = /[a-z]/
    const lowSymbols = reg.test(value)
    if (!lowSymbols) {
      return true
    }
  }
  if (value) {
    const reg = /[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/
    const symbols = reg.test(value)
    if (!symbols) {
      return true
    }
  }
  if (value) {
    const reg = /[0-9]/
    const symbols = reg.test(value)
    if (!symbols) {
      return true
    }
  }

  return false
}
