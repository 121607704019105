import numeral from 'numeral'
import React from 'react'

import { CreatedBy } from 'components/pages/FinderPage/FinderTable/config'
import { FolderComponent } from 'components/uiKit/Table'
import { ColumnsType } from 'components/uiKit/Table/types'
import { t } from 'services/Translation'
import { FinderFolderItem } from 'store/models/entry.model'

export const COLUMNS_CONFIG: ColumnsType<FinderFolderItem> = [
  {
    title: t('input.label.name'),
    sortKey: 'name',
    dataIndex: 'name',
    width: 300,
    render: (_, record) => <FolderComponent folder={record} />,
  },
  {
    title: t('input.label.size'),
    sortKey: 'size',
    dataIndex: 'size',
    align: 'right',
    width: 100,
    render: (size) => Number.isInteger(size) && numeral(size).format('0.0 b'),
  },
  {
    title: t('common.createdBy'),
    sortKey: 'createdBy',
    dataIndex: 'createdBy',
    width: 180,
    render: (createdBy) => <CreatedBy createdBy={createdBy} />,
  },
]
