import { EditorElementError } from '@leenda/editor/lib/elements'
import { textToRtValue, validateSlate } from '@leenda/rich-text'
import { RichTextValue, SlateElementType, SlateMark } from '@leenda/rich-text'
import cn from 'classnames'
import { isCodeHotkey } from 'is-hotkey'
import * as R from 'ramda'
import React, { useCallback, useMemo, useReducer, useRef, useState } from 'react'
import { createEditor, Descendant, Transforms, Range, Editor } from 'slate'
import { withHistory } from 'slate-history'
import { Editable, ReactEditor, Slate, withReact } from 'slate-react'
import { RenderElementProps, RenderLeafProps } from 'slate-react/dist/components/editable'

import { wrapCrossLink } from 'components/form/RichText/inline/withInline'
import { AccountMock } from 'components/uiKit/Employee'
import { EMPTY_ARRAY } from 'constants/commonConstans'
import { testPropsEl } from 'utils/test/qaData'

import s from './AppText.module.scss'
import AppTextContext, { FormType, AppTextContextType } from './AppTextContext'
import { AppTextErrorBoundary } from './AppTextErrorBoundary'
import Toolbar from './Toolbar'
import {
  HOT_KEYS_CONFIG,
  isCommandFormat,
  isElementFormat,
  isElementMarkFormat,
  isMarkFormat,
  LEENDA_SLATE_CLIPBOARD,
  SlateCommand,
  SlateFormats,
} from './constants'
import { clearAllMarks, inertSymbol, insertBreak, insertMention } from './formatOperations/commands'
import { getCurrentFormat, isFormatActive } from './formatOperations/common'
import { toggleElement, updateElementMark } from './formatOperations/elements'
import { removePseudoSelection, setPseudoSelection } from './formatOperations/internal'
import { setMark } from './formatOperations/textMarks'
import { withInlines, wrapLink } from './inline/withInline'
import ElementResolver from './nodes/Elements'
import Text from './nodes/Text'
import { withLists } from './nodes/withLists'
import { withMentions } from './nodes/withMentions'
import { CustomEditor } from './slate'
import { useAutofocus } from './useAutofocus'
import { useExternalValue } from './useExternalValue'
import { useFocus } from './useFocus'
import { getCurrentElementTypes } from './utils/common'
import { htmlToSlatePaste } from './utils/htmlToSlate'

function beforeWithUnderscore(editor: Editor, at: any, options: any) {
  const { unit = 'character' } = options

  if (unit !== 'word') {
    // Для всех остальных единиц (кроме 'word'), используйте стандартное поведение.
    return Editor.before(editor, at, options)
  }

  const { path, offset } = Range.isRange(at) ? at.focus : at
  const anchor = { path, offset }
  const text = Editor.string(editor, { anchor, focus: { path, offset: 0 } })
  const regex = /[\w_]+$/
  const matches = text.match(regex)

  if (matches) {
    const wordLength = matches[0].length
    const point = { path, offset: offset - wordLength }
    return point
  }

  return null
}
export interface IAppTextProps {
  placeholder?: string
  value?: RichTextValue
  defaultValue?: RichTextValue
  disabled?: boolean
  readOnly?: boolean
  onChange?: (value: RichTextValue, error?: EditorElementError[]) => void
  name: string
  mentions?: AccountMock[]
  onBlur?: (e: React.FocusEvent<HTMLDivElement>) => void
  onFocus?: (e: React.FocusEvent<HTMLDivElement>) => void
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void
  full?: boolean
  autofocus?: boolean
}

const renderElement = (props: RenderElementProps) => <ElementResolver isEdit {...props} />
const renderLeaf = (props: RenderLeafProps) => <Text {...props} />

const RichText: React.FC<IAppTextProps> = ({
  value,
  defaultValue,
  onChange,
  disabled,
  readOnly,
  placeholder,
  mentions = EMPTY_ARRAY,
  onBlur,
  onFocus,
  onKeyDown,
  name,
  full,
  autofocus,
}) => {
  const editor = useMemo(
    () => withMentions(withLists(withInlines(withHistory(withReact(createEditor()))))),
    [],
  )
  const [, forceRender] = useReducer((s) => !s, false)
  const toolbarRef = useRef<HTMLDivElement>(null)
  const [focused, focusHandles] = useFocus(editor, toolbarRef, onBlur, onFocus)
  const [form, setForm] = useState<FormType>(null)
  const [mentionIndex, setMentionIndex] = useState(0)
  const [mentionTarget, setMentionTarget] = useState<Range | null>(null)
  const [mentionSearch, setMentionSearch] = useState<string | null>(null)
  const employees = useMemo(() => {
    if (mentionSearch) {
      const employees = mentions.filter(
        (employee) =>
          employee.kUser.name.toString()?.toLowerCase().startsWith(mentionSearch.toLowerCase()) ||
          employee.kUser.email.toLowerCase().includes(mentionSearch.toLowerCase()),
      )
      return employees
    }
    return mentions
  }, [mentionSearch, mentions])

  const mentionsMap = useMemo(() => R.indexBy(R.prop('id'), mentions), [mentions])

  const [format, setFormat] = useState({})
  const onUpdateFormat = useCallback(
    (format: SlateFormats, value: string | number | boolean) => {
      ReactEditor.focus(editor)
      if (format === SlateElementType.link) {
        wrapLink(editor, value as string)
      } else if (format === SlateElementType.crossLink) {
        wrapCrossLink(editor, value)
      } else if (format === SlateCommand.clear) {
        clearAllMarks(editor)
      } else if (isMarkFormat(format)) {
        setMark(editor, format, value)
      } else if (isElementFormat(format)) {
        toggleElement(editor, format)
      } else if (isCommandFormat(format)) {
        inertSymbol(editor, format)
      } else if (isElementMarkFormat(format)) {
        updateElementMark(editor, format, value)
      }
    },
    [editor],
  )

  const toggleFormat = useCallback(
    (editor: CustomEditor, format: SlateMark | string, value: string | number | boolean): void => {
      const isActive = isFormatActive(editor, format)
      if (isActive) {
        onUpdateFormat(format as SlateFormats, false)
      } else {
        onUpdateFormat(format as SlateFormats, value)
      }
    },
    [onUpdateFormat],
  )

  const handleBlur = useCallback(() => setPseudoSelection(editor), [editor])

  const handleFocus = useCallback(() => removePseudoSelection(editor), [editor])

  const handleChange = useCallback(
    (changeValue: Descendant[]) => {
      const { selection } = editor
      if (selection && Range.isCollapsed(selection)) {
        const [start] = Range.edges(selection)
        const charBefore = Editor.before(editor, start, { unit: 'character' })
        const charBeforeStr =
          charBefore && Editor.string(editor, Editor.range(editor, charBefore, start))
        const wordBefore = beforeWithUnderscore(editor, start, { unit: 'word' })

        const before = wordBefore && Editor.before(editor, wordBefore)
        const beforeRange =
          charBeforeStr === '@'
            ? charBefore && Editor.range(editor, charBefore, start)
            : before && Editor.range(editor, before, start)
        const beforeText = beforeRange && Editor.string(editor, beforeRange)
        const beforeMatch = beforeText && beforeText.match(/^@(\w+)$/)
        const after = Editor.after(editor, start)
        const afterRange = Editor.range(editor, start, after)
        const afterText = Editor.string(editor, afterRange)
        const afterMatch = afterText.match(/^(\s|$)/)

        if (charBeforeStr === '@' || (beforeMatch && afterMatch)) {
          setMentionTarget(beforeRange || null)
          setMentionSearch(beforeMatch?.[1] || null)
          setMentionIndex(0)
          return
        } else {
          setMentionSearch(null)
        }
      }

      setMentionTarget(null)

      setFormat(getCurrentFormat(editor))
      if (changeValue !== value) {
        onChange && onChange(changeValue)
        const valid = validateSlate(changeValue)
        if (!valid) {
          console.error(changeValue)
        }
      }
    },
    [editor, onChange, value],
  )

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      onKeyDown?.(event)
      const { selection } = editor
      // TODO move to plugins (withMentions, withLinks, withLists and others)
      const { nativeEvent } = event
      if (mentionTarget && employees.length > 0) {
        if (isCodeHotkey('down', nativeEvent)) {
          event.preventDefault()
          const prevIndex = mentionIndex >= employees.length - 1 ? 0 : mentionIndex + 1
          setMentionIndex(prevIndex)
        }
        if (isCodeHotkey('up', nativeEvent)) {
          event.preventDefault()
          const nextIndex = mentionIndex <= 0 ? employees.length - 1 : mentionIndex - 1
          setMentionIndex(nextIndex)
        }
        if (isCodeHotkey('tab', nativeEvent) || isCodeHotkey('enter', nativeEvent)) {
          event.preventDefault()
          Transforms.select(editor, mentionTarget)
          insertMention(editor, employees[mentionIndex])
          setMentionTarget(null)
          return
        }
        if (isCodeHotkey('escape', nativeEvent)) {
          event.preventDefault()
          setMentionTarget(null)
        }
      }

      // Default left/right behavior is unit:'character'.
      // This fails to distinguish between two cursor positions, such as
      // <inline>foo<cursor/></inline> vs <inline>foo</inline><cursor/>.
      // Here we modify the behavior to unit:'offset'.
      // This lets the user step into and out of the inline without stepping over characters.
      // You may wish to customize this further to only use unit:'offset' in specific cases.
      if (selection && Range.isCollapsed(selection)) {
        if (isCodeHotkey('left', nativeEvent)) {
          event.preventDefault()
          Transforms.move(editor, { unit: 'offset', reverse: true })
          return
        }
        if (isCodeHotkey('right', nativeEvent)) {
          event.preventDefault()
          Transforms.move(editor, { unit: 'offset' })
          return
        }
      }
      if (isCodeHotkey('enter', nativeEvent)) {
        event.preventDefault()
        insertBreak(editor)
        return
      }

      const hotkey = HOT_KEYS_CONFIG.find(({ hotkey }) => isCodeHotkey(hotkey, nativeEvent))

      if (hotkey) {
        const { name, value } = hotkey

        event.stopPropagation()
        event.preventDefault()
        if (name === SlateElementType.link) {
          setForm('link')
        } else {
          toggleFormat(editor, name, value)
        }
      }
    },
    [onKeyDown, mentionTarget, employees, mentionIndex, editor, toggleFormat],
  )

  const onCopy = useCallback(
    (event: React.ClipboardEvent<HTMLDivElement>) => {
      const { selection } = editor
      const fragment = selection && Editor.fragment(editor, selection)
      const serializedFragment = JSON.stringify(fragment)
      event.clipboardData.setData(LEENDA_SLATE_CLIPBOARD, serializedFragment)
    },
    [editor],
  )

  const onPaste = useCallback(
    (event: React.ClipboardEvent<HTMLDivElement>) => {
      event.preventDefault()
      const leendaClipboardData = event.clipboardData.getData(LEENDA_SLATE_CLIPBOARD)
      if (leendaClipboardData) {
        const parsedFragment = JSON.parse(leendaClipboardData)
        Transforms.insertFragment(editor, parsedFragment)
      } else {
        const type = getCurrentElementTypes(editor)
        const html = event.clipboardData.getData('text/html')
        let nodes = null
        if (html) {
          try {
            nodes = htmlToSlatePaste(html, type)
          } catch (e) {
            console.error('error in parse', e)
          }
        }
        if (nodes) {
          Transforms.insertFragment(editor, htmlToSlatePaste(html, type))
        } else {
          const text = event.clipboardData.getData('text/plain')
          Transforms.insertText(editor, text)
        }
      }
    },
    [editor],
  )

  const handleError = useCallback(() => {
    editor.selection = null
    forceRender()
  }, [editor, forceRender])

  const contextValue: AppTextContextType = useMemo(
    () => ({
      editor,
      format,
      form,
      readOnly,
      disabled,
      employees,
      mentionSearch,
      mentionIndex,
      mentionTarget,
      mentionsMap,
      setForm,
      onUpdateFormat,
    }),
    [
      editor,
      onUpdateFormat,
      format,
      form,
      setForm,
      readOnly,
      disabled,
      mentionSearch,
      mentionIndex,
      mentionTarget,
      employees,
      mentionsMap,
    ],
  )

  useAutofocus(editor, autofocus)

  useExternalValue(editor, value, defaultValue)

  return (
    <AppTextErrorBoundary onError={handleError}>
      <div {...focusHandles} className={cn(s.root, { [s.disabled]: disabled })}>
        <AppTextContext.Provider value={contextValue}>
          <Slate
            editor={editor}
            initialValue={value || defaultValue || textToRtValue('')}
            onChange={handleChange}
          >
            <Editable
              {...testPropsEl('appText', { name })}
              disabled={disabled}
              onBlur={handleBlur}
              onCopy={onCopy}
              onFocus={handleFocus}
              onKeyDown={handleKeyDown}
              onPaste={onPaste}
              placeholder={placeholder}
              readOnly={readOnly || disabled}
              renderElement={renderElement}
              renderLeaf={renderLeaf}
              // eslint-disable-next-line react/forbid-component-props
              style={{ overflowWrap: 'anywhere' }}
            />
            {focused && !disabled && !readOnly && <Toolbar full={full} ref={toolbarRef} />}
          </Slate>
        </AppTextContext.Provider>
      </div>
    </AppTextErrorBoundary>
  )
}

export default React.memo(RichText)
