"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_VALUE = exports.createItem = exports.TYPE = void 0;
const id_1 = require("../../../utils/id");
const types_1 = require("./types");
exports.TYPE = 'slider';
const createItem = () => ({
    value: (0, id_1.generateId)(),
    label: '',
});
exports.createItem = createItem;
exports.DEFAULT_VALUE = {
    items: [(0, exports.createItem)()],
    hasLoop: true,
    hasProgress: true,
    type: types_1.ProgressType.dot,
    includeSlide: {
        first: true,
        last: true,
    },
};
