"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.notEmpty = exports.getElement = exports.getNode = exports.isShellNode = void 0;
const types_1 = require("../types");
const isShellNode = (node) => node && types_1.NodeType.shell === node.type;
exports.isShellNode = isShellNode;
const getNode = (block, id) => { var _a, _b; return ((_a = block === null || block === void 0 ? void 0 : block.schema) === null || _a === void 0 ? void 0 : _a.nodes) && ((_b = block === null || block === void 0 ? void 0 : block.schema) === null || _b === void 0 ? void 0 : _b.nodes[id]); };
exports.getNode = getNode;
const getElement = (block, id) => block.elements[id];
exports.getElement = getElement;
const notEmpty = (value) => {
    return value !== null && value !== undefined;
};
exports.notEmpty = notEmpty;
