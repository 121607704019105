import { isThemeVariable } from '@leenda/editor/lib/brand'
import { StyleValue } from '@leenda/editor/lib/elements'
import * as R from 'ramda'
import React, { useEffect, useMemo } from 'react'

import { validateFontFamilyCss } from 'utils/font'

import { BrandContext } from './BrandContext'
import { mergeBrands } from './mergeBrands'
import { BrandType } from './types'

export const useTempFixWorkWithBackData = (data?: BrandType | null) => {
  let schema = {}
  if (typeof data?.schema === 'string') {
    schema = JSON.parse(data?.schema)
  } else {
    schema = data?.schema || {}
  }

  let theme = {}
  if (typeof data?.theme === 'string') {
    theme = JSON.parse(data?.theme)
  } else {
    theme = data?.theme || {}
  }

  let labels = {}
  if (typeof data?.labels === 'string') {
    labels = JSON.parse(data?.labels)
  } else {
    labels = data?.labels || {}
  }

  let font = {}
  if (typeof data?.font === 'string') {
    font = JSON.parse(data?.font)
  } else {
    font = data?.font || {}
  }
  return useMemo(() => ({ schema, theme, labels, font }), [data]) as BrandType
}

interface IProps {
  companyBrand?: BrandType | null
  brand?: BrandType
  children: React.ReactNode
}

const BrandProvider: React.FC<IProps> = ({ children, companyBrand, brand }) => {
  const upperBrand = useTempFixWorkWithBackData(companyBrand)

  const finalBrand = useMemo(() => brand || mergeBrands(upperBrand), [brand, upperBrand])

  useEffect(() => {
    const themeCss = finalBrand.theme
    const style = document.documentElement.style
    R.forEachObjIndexed((value, key) => {
      if (key.toLowerCase().includes('fontfamily')) {
        value = validateFontFamilyCss(value)
      }
      style.setProperty(`--brand-${String(key)}`, String(validateCss(value, key)))
    }, themeCss)
    return () => {
      R.forEachObjIndexed((_, key) => style.removeProperty(`--brand-${String(key)}`), themeCss)
    }
  }, [finalBrand.theme])

  return <BrandContext.Provider value={finalBrand}>{children}</BrandContext.Provider>
}

export default React.memo(BrandProvider)

const validateCss = (style: StyleValue, key: string) => {
  if (style === null || style === undefined || isThemeVariable(style)) {
    return style
  }

  switch (key) {
    case 'padding':
    case 'margin': {
      return Array.isArray(style)
        ? style.map((val) => `${parseInt(String(val || 0))}px`).join(' ')
        : String(style)
    }
    case 'width':
    case 'height':
    case 'paddingTop':
    case 'paddingLeft':
    case 'paddingRight':
    case 'paddingBottom':
    case 'marginTop':
    case 'marginLeft':
    case 'marginRight':
    case 'marginBottom':
    case 'borderWidth':
    case 'borderRadius':
    case 'fontSize': {
      return `${parseInt(String(style))}px`
    }
    case 'fontFamily': {
      return validateFontFamilyCss(style)
    }
    // case 'lineHeight': {
    //   return parseFloat(String(style))
    // }
  }

  return style
}
