import Progress, { ProgressType } from 'components/uiKit/Progress'
import { PreviewMode } from 'services/Store/Project/enums'
import { useProjectContext } from 'services/Store/Project/hooks'
import { getSection, getTestPercent } from 'services/Store/Project/selectors'

import { useElementRootStyles } from '../../hooks/useElementRootStyles'
import './TestResultElement.scss'
import { TestResultElementType } from './TestResultElement.types'
import { useGetTestResultFeedback } from './hooks/useGetTestResultFeedback'
import { useGetTestResultTitle } from './hooks/useGetTestResultTitle'

const TestResultElement: TestResultElementType = ({ element, mode, styles, font }) => {
  const section = useProjectContext(getSection)?.test
  const passPercent = useProjectContext(getTestPercent)

  const value = element.value
  const isFeedbackCommon = value.feedbackType === 'info'
  const resultTitleInEditor = useGetTestResultTitle(
    value.resultSuccessTitle,
    value.resultFailTitle,
    value.resultFeedbackSuccess,
    value.resultFeedbackFail,
    isFeedbackCommon,
  )
  const feebackInEditor = useGetTestResultFeedback(
    value.resultFeedbackCommon,
    value.resultFeedbackSuccess,
    value.resultFeedbackFail,
    value.resultSuccessTitle,
    value.resultFailTitle,
    isFeedbackCommon,
  )

  const showDiagram = value.showDiagram
  const testThreshold = section?.testThreshold || 0
  const isSuccessResult = passPercent >= testThreshold

  const isView = mode.previewMode !== PreviewMode.editor
  const percentsResult = !isView || mode.previewMode === PreviewMode.demo ? 50 : passPercent
  const position = value.position
  const resultText = isSuccessResult ? value.resultSuccessTitle : value.resultFailTitle
  const showFeedback = value.showFeedback
  const dependentFeedbackText = isSuccessResult
    ? value.resultFeedbackSuccess
    : value.resultFeedbackFail
  const feedbackText = isFeedbackCommon ? value.resultFeedbackCommon : dependentFeedbackText
  const resultFeedbackText = value.isAnyFeedback ? value.resultFeedback : feedbackText
  const resultTitle = isView ? resultText : resultTitleInEditor
  const feedbackTitle = isView ? resultFeedbackText : feebackInEditor

  const rootStyles = useElementRootStyles(styles.root)
  const { strokeColor, trailColor } = isSuccessResult
    ? {
        strokeColor: styles.chartCorrectAnswerFill.color,
        trailColor: styles.chartCorrectAnswerEmpty.color,
      }
    : {
        strokeColor: styles.chartFailAnswerFill.color,
        trailColor: styles.chartFailAnswerEmpty.color,
      }

  return (
    <div className='test-result-el' style={rootStyles}>
      <div className={`test-result-el__content test-result-el_position_${position}`}>
        {showDiagram && (
          <Progress
            customStyles={{ trail: trailColor as string, stroke: strokeColor as string }}
            font={font}
            label={`${percentsResult}%`}
            name='testResult'
            percent={percentsResult}
            styleType={ProgressType.circle}
          />
        )}

        <div
          className={`
            test-result-el__result-text
            test-result-el__result-text_position_${position}
          `}
          style={font?.h3}
        >
          {resultTitle}
          {showFeedback && (
            <div className='test-result-el__feedback-text' style={font?.h4}>
              {feedbackTitle}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default TestResultElement
