import { ChartFontSchemaType, ChartSchemaType } from '@leenda/editor/lib/brand'
import { useEffect, useRef } from 'react'
import { TooltipProps } from 'recharts'
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent'

import { Tooltip } from 'components/uiKit/Dropdown'
import { ElementFontCss, ElementStyleCss } from 'services/Branding/types'
import { DeviceMode } from 'services/Store/Project/enums'
import { IBlockMode } from 'services/Store/Project/types'

import s from './ChartElement.module.scss'

export interface IMeasureProps {
  index: number
  height?: number
  width?: number
}

interface IAxisConfigProps {
  styles: ElementStyleCss<ChartSchemaType>
  tickCount?: number
  label?: string
  width?: number
  height?: number
  onMeasure?: ({ index, width, height }: IMeasureProps) => void
  showLabel: boolean
  mode: IBlockMode
  font?: ElementFontCss<ChartFontSchemaType>
}

export const getFontStyles = (
  section: 'base' | 'caption',
  font?: ElementFontCss<ChartFontSchemaType>,
) => ({ fill: font?.[section]?.color, ...font?.[section] })

export const customTooltip =
  // eslint-disable-next-line react/display-name
  ({ active, payload, label }: TooltipProps<ValueType, NameType>) => {
    if (active && payload && payload.length) {
      return (
        <div className={s.tooltip}>
          {label}:&nbsp;
          {String(payload[0]?.value).length > 7
            ? Number(payload[0]?.value).toExponential()
            : payload[0]?.value}
        </div>
      )
    }
    return null
  }

const CustomizedXAxisTick = (props: any) => {
  const { x, y, payload, tickCount, onMeasure, index, showLabel, height, deviceMode, font } = props
  const tick = deviceMode === DeviceMode.mobile ? 3 : 6
  const wrapperWidth = tickCount > tick ? '60' : '100'

  const labelBlock = useRef<any>(null)
  const fontSize = parseInt(font?.base.fontSize)

  useEffect(() => {
    const height = labelBlock.current?.offsetHeight
    onMeasure && onMeasure({ index, height })
  }, [payload.value, fontSize, showLabel])

  return (
    <g transform={`translate(${x},${y})`}>
      <foreignObject
        height={height}
        style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          textAlign: 'center',
          ...getFontStyles('base', font),
          lineHeight: 1.2,
          transform: `translate(-${tickCount > tick ? 30 : 50}px)`,
        }}
        width={wrapperWidth}
        x='0'
        y='0'
      >
        <Tooltip overlay={payload.value} placement='topLeft' theme='light'>
          <span ref={labelBlock}>{payload.value}</span>
        </Tooltip>
      </foreignObject>
    </g>
  )
}

const CustomizedYAxisTick = (props: any) => {
  const { x, y, payload, index, onMeasure, width, showLabel, font } = props

  const labelBlock = useRef<any>(null)
  const fontSize = parseInt(font?.base.fontSize)

  useEffect(() => {
    const width = labelBlock.current?.offsetWidth
    onMeasure && onMeasure({ index, width })
  }, [payload.value, fontSize, showLabel])

  return (
    <g transform={`translate(${x},${y})`}>
      <foreignObject
        height={fontSize * 1.5}
        style={{
          ...getFontStyles('base', font),
          lineHeight: 1.2,
          textAlign: 'end',
          paddingRight: 2,
          transform: `translate(-${width}px,-${fontSize / 5}%)`,
        }}
        width={width}
        x='0'
        y='0'
      >
        <span ref={labelBlock}>{tickFormatter(payload.value)}</span>
      </foreignObject>
    </g>
  )
}

export const tickFormatter = (tick: any) => {
  return String(tick).length > 7 ? tick.toExponential() : tick
}

export const getXConfig = ({
  styles,
  label,
  tickCount,
  height,
  onMeasure,
  showLabel,
  mode,
  font,
}: IAxisConfigProps) => ({
  axisLine: false,
  dataKey: 'label',
  height,
  label: {
    value: label,
    position: 'insideBottom',
    ...getFontStyles('caption', font),
    display: showLabel ? 'block' : 'none',
  },
  tick: (
    <CustomizedXAxisTick
      deviceMode={mode.deviceMode}
      font={font}
      onMeasure={onMeasure}
      styles={styles}
      tickCount={tickCount}
    />
  ),
  tickLine: false,
  padding: { right: 50, left: 50 },
  interval: 0,
})

export const getYConfig = ({
  styles,
  label,
  onMeasure,
  width,
  showLabel,
  font,
}: Omit<IAxisConfigProps, 'mode'>) => ({
  axisLine: false,
  label: {
    value: label,
    angle: -90,
    position: 'insideLeft',
    dy: '30%',
    ...getFontStyles('caption', font),
    offset: 0,
    display: showLabel ? 'block' : 'none',
  },
  width,
  tick: <CustomizedYAxisTick font={font} onMeasure={onMeasure} styles={styles} width={width} />,
  tickLine: false,
  tickFormatter,
})
