import React, { useEffect } from 'react'
import { createPortal } from 'react-dom'
import { Range } from 'slate'
import { useSlate, useSlateSelection } from 'slate-react'

import { isLinkActive } from '../inline/withInline'
import { getForm, getMentionTarget, getSetForm, useAppTextSelector } from '../useAppTextSelector'
import DefaultForm from './DefaultForm'
import LinkForm from './LinkForm'
import MentionForm from './MentionForm'

const FORM_COMPONENTS = {
  link: LinkForm,
  default: DefaultForm,
  mention: MentionForm,
}

interface IProps {
  full?: boolean
}

const Toolbar = React.forwardRef<HTMLDivElement, IProps>(({ full }, ref) => {
  const editor = useSlate()
  const selection = useSlateSelection()
  const setForm = useAppTextSelector(getSetForm)
  const form = useAppTextSelector(getForm)
  const mentionTarget = useAppTextSelector(getMentionTarget)
  const Form = form && FORM_COMPONENTS[form]

  useEffect(() => {
    const { pseudoSelection } = editor
    try {
      const isLink = isLinkActive(editor)
      if (pseudoSelection) {
        return
      } else if (selection && Range.isCollapsed(selection) && isLink) {
        setForm('link')
      } else if (mentionTarget) {
        setForm('mention')
      } else if (selection && !Range.isCollapsed(selection)) {
        setForm('default')
      } else {
        setForm(null)
      }
    } catch (error) {
      console.error(error)
    }
  }, [setForm, selection, editor, mentionTarget])

  if (!Form) {
    return null
  }

  return createPortal(<Form full={full} ref={ref} />, document.body)
})

Toolbar.displayName = 'Toolbar'

export default React.memo(Toolbar)
