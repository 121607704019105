import { SidebarFontSchemaType, SidebarSchemaType } from '@leenda/editor/lib/brand'
import { MenuPositionType } from '@leenda/editor/lib/brand/types'
import cn from 'classnames'

import { LayoutScroll } from 'components/LayoutPage'
import { CourseHeaderLogo } from 'components/editor-v3/cource/controls/CourseHeader/components/CourseHeaderLogo'
import { CourseSearch } from 'components/editor-v3/cource/controls/CourseSidebar/components/CourseSearch'
import { ISectionTreeNode, treeToSections } from 'components/sections/ProjectStructure/utils'
import Empty from 'components/uiKit/Empty'
import Icon from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'
import Progress, { ProgressType } from 'components/uiKit/Progress'
import { ElementFontCss, ElementStyleCss } from 'services/Branding/types'
import ScrollService from 'services/Scroll/ScrollService'
import { ScrollContainerEnum } from 'services/Scroll/enums'
import { useProjectContext } from 'services/Store/Project/hooks'
import { getSection, getSidebar } from 'services/Store/Project/selectors'
import { Section } from 'services/Store/Project/types'
import { t } from 'services/Translation'

import { CourseTitle } from '../components/CourseTitle'
import s from './FixedSidebar.module.scss'
import { CourseSearchResult } from './components/CourseSearchResult'
import { SectionCourseItem } from './components/SectionCourseItem'

interface IFixedSidebarProps {
  styles: ElementStyleCss<SidebarSchemaType>
  font: ElementFontCss<SidebarFontSchemaType>
  percent: number
  projectName?: string
  projectLogoId?: string
  isMobile?: boolean
  onSectionBack: () => void
  resultSections: Section[]
  onSectionSelect: (section: Section) => void
  courseNodes: ISectionTreeNode<Section>[]
  sectionId?: string | undefined
}

const FixedSidebar: React.FC<IFixedSidebarProps> = ({
  styles,
  percent,
  projectName,
  projectLogoId,
  isMobile,
  onSectionBack,
  onSectionSelect,
  resultSections,
  courseNodes,
  sectionId,
  font,
}) => {
  const { chapter, search: searchValue } = useProjectContext(getSidebar)
  const cover = useProjectContext(getSection, { cover: true })
  const menuPosition = styles.value.position as MenuPositionType

  return (
    <div
      className={cn(s.root, {
        [s.positionRight]: menuPosition === MenuPositionType.right,
        [s.fluid]: isMobile,
      })}
    >
      <div className={s.header}>
        {!chapter && styles.value.showLogo && (
          <CourseHeaderLogo disabled={!cover} projectLogoId={projectLogoId} />
        )}
        {chapter && (
          <>
            <div className={s.back} onClick={onSectionBack}>
              <Icon name='builderInputBack' size={KitSize.M} />
              {t('common.back')}
            </div>
            <div className={s.parent}>{chapter?.name}</div>
          </>
        )}
        <CourseTitle hidden={!!chapter} projectName={projectName} titleStyles={font.title} />
        <div className={s.search}>
          <CourseSearch name='search' fluid />
        </div>
        {!searchValue && styles.progress.showLine && (
          <div className={s.progress}>
            <Progress
              customStyles={{
                stroke: styles.progress.color,
                trail: styles.progress.backgroundColor,
              }}
              name='curseProgress'
              percent={percent}
              styleType={ProgressType.line}
            />
            {styles.progress.showDescriptionProgress && (
              <div className={s.progressDescription}>
                {Number(percent.toFixed(1))}&#37; {t('course.sidebar.progress.complete')}
              </div>
            )}
          </div>
        )}
      </div>
      <div className={s.body}>
        {!!courseNodes.length && (
          <LayoutScroll ref={ScrollService.createContainerSetter(ScrollContainerEnum.sectionNav)}>
            <div className={s.content}>
              {searchValue ? (
                <CourseSearchResult
                  font={font}
                  onSelect={onSectionSelect}
                  resultSections={resultSections}
                  searchValue={searchValue}
                  sectionId={sectionId}
                  styles={styles}
                />
              ) : (
                courseNodes.map((node) => (
                  <SectionCourseItem
                    childrenList={node.children && treeToSections(node.children)}
                    font={font}
                    item={node.item}
                    key={node.item.id}
                    onSelect={onSectionSelect}
                    sectionId={sectionId}
                    styles={styles}
                  />
                ))
              )}
            </div>
          </LayoutScroll>
        )}
        {courseNodes.length === 0 && <Empty grow />}
        {searchValue && resultSections.length === 0 && <Empty grow search />}
      </div>
    </div>
  )
}
export default FixedSidebar
