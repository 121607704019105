"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.rtValueToText = exports.textToRtValue = void 0;
const index_1 = require("../types/index");
const typeguards_1 = require("./typeguards");
const textToRtValue = (text, type = index_1.SlateElementType.elementDefault) => [{ type, children: [{ text: text }] }];
exports.textToRtValue = textToRtValue;
const nodeToText = (node) => {
    if (!node)
        return '';
    if (typeof node === 'string')
        return node;
    if (Array.isArray(node))
        return node.map(nodeToText).join('');
    if ((0, typeguards_1.isMention)(node))
        return ` ${node.name} `;
    if ('children' in node)
        return node.children.map(nodeToText).join('').trim();
    if ('text' in node)
        return node.text;
    return '';
};
const rtValueToText = (value) => {
    if (Array.isArray(value))
        return value.map(nodeToText).join(' ').trim();
    return nodeToText(value);
};
exports.rtValueToText = rtValueToText;
