import { SortUiEnum } from './types'

export const ENUM_VALUE_MAP: Record<SortUiEnum, string[][]> = {
  NAME: [['name']],
  NAME_ASC: [['name', 'ASC']],
  NAME_DESC: [['name', 'DESC']],
  CREATED_AT: [['createdAt']],
  CREATED_AT_ASC: [['createdAt', 'ASC']],
  CREATED_AT_DESC: [['createdAt', 'DESC']],
  UPDATED_AT: [['updatedAt']],
  UPDATED_AT_DESC: [['updatedAt', 'DESC']],
  DEADLINE: [['deadline']],
  DEADLINE_ASC: [['deadline', 'ASC']],
  WEIGHT: [['weight']],
  WEIGHT_DESC: [['weight', 'DESC']],
}

export const valueToEnum = (field?: string, order?: string): SortUiEnum =>
  Object.entries(ENUM_VALUE_MAP).find(
    ([, value]) => field && String(value).includes(field) && order && String(value).includes(order),
  )?.[0] as SortUiEnum
