"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const constants_1 = require("../../../elements/elements/LayoutList/constants");
const genStyle_1 = require("../genStyle");
const styleSchema = {
    settings: {
        flexDirection: (0, genStyle_1.genStyle)({ value: 'row' }),
        justifyContent: (0, genStyle_1.genStyle)({ value: 'normal' }),
    },
    indents: {
        columnGap: (0, genStyle_1.genStyle)({ value: 8 }),
        rowGap: (0, genStyle_1.genStyle)({ value: 8 }),
    },
};
exports.default = { [constants_1.TYPE]: { style: styleSchema, font: {} } };
