import { MatchingItem } from '@leenda/editor/lib/elements'
import { generateId } from '@leenda/editor/lib/utils/id'
import { textToRtValue } from '@leenda/rich-text'
import * as R from 'ramda'

import ControlItem from 'components/editor-v3/cource/layout/controls/ControlItem'
import EditableProMode from 'components/editor-v3/cource/layout/controls/ElementControls/EditableProMode'
import Separator from 'components/editor-v3/cource/layout/controls/Separator'
import { Tooltip } from 'components/uiKit/Dropdown'
import { AppMode } from 'services/Store/Project/enums'
import { t } from 'services/Translation'

import { TestMatchingEditorActionsType } from './TestMatchingElement.types'

const ACTIONS = {
  ADD_ITEM: {
    icon: 'otherAdd',
    label: t('uiKit.tooltip.addAnswer'),
    value: 'ADD_ITEM',
  },
} as const

const genItem = (id: string): MatchingItem => ({
  leftLabel: textToRtValue(''),
  rightLabel: textToRtValue(''),
  value: id,
  points: 1,
})

const TestMatchingEditorActions: TestMatchingEditorActionsType = ({
  element: { value },
  onChange,
  setAppMode,
  customMode,
  mode,
}) => {
  const isFill = mode.editorMode === AppMode.fill || customMode?.editorMode === AppMode.fill
  const isPro = mode.editorMode === AppMode.pro

  const addItem = () =>
    onChange(R.assoc('items', R.append(genItem(generateId()), value.items), value))

  return (
    <>
      {isPro && <EditableProMode customMode={customMode} mode={mode} setAppMode={setAppMode} />}
      {isPro && isFill && <Separator />}
      {isFill && (
        <Tooltip overlay={t('uiKit.tooltip.addAnswer')}>
          <ControlItem handler={addItem} option={ACTIONS.ADD_ITEM} />
        </Tooltip>
      )}
    </>
  )
}

export default TestMatchingEditorActions
