import { useCallback } from 'react'

import { useProjectContext, useProjectDispatch } from 'services/Store/Project/hooks'
import { getTestEndTrigger } from 'services/Store/Project/triggers'

import BaseButtonElement from '../BaseButtonElement'
import { ButtonElementType } from '../ButtonElement.types'
import { getButtonLabel } from '../helper'

const EndButtonElement: ButtonElementType = ({ element, mode, styles, font }) => {
  const dispatch = useProjectDispatch()

  const trigger = useProjectContext(getTestEndTrigger)

  const label = getButtonLabel(
    {
      'project/testEnd': element.value.label,
      'project/setCourseNavigation': element.value.secondLabel,
      'project/courseEnd': element.value.thirdLabel,
      noop: element.value.label,
    },
    trigger.action,
  )

  const onClick = useCallback(
    () => !trigger.disabled && dispatch(trigger.actionCreator()),
    [dispatch, trigger],
  )

  return (
    <BaseButtonElement
      action={element.value.action}
      disabled={trigger.disabled}
      font={font}
      label={label}
      mode={mode}
      onClick={onClick}
      styles={styles}
    />
  )
}

export default EndButtonElement
