import React, { useState } from 'react'

import CollapseControlled from './CollapseControlled'
import { IControlledCollapseProps } from './CollapseControlled/CollapseControlled'

export type CollapseProps = Omit<IControlledCollapseProps, 'open' | 'onChangeOpen'> & {
  initialOpen?: boolean
}

const Collapse: React.FC<CollapseProps> = ({ initialOpen, ...props }) => {
  const [open, setOpen] = useState(initialOpen || false)
  return <CollapseControlled {...props} onChangeOpen={setOpen} open={open} />
}

type CollapseAccordionProps = Omit<IControlledCollapseProps, 'open' | 'onChangeOpen'> & {
  onChange: (name: string | null) => void
  value: string | null
}

export const CollapseAccordion: React.FC<CollapseAccordionProps> = ({
  onChange,
  value,
  name,
  ...props
}) => {
  const onChangeOpen = (val: boolean) => {
    if (onChange) {
      val ? onChange(name) : onChange(null)
    }
  }

  return (
    <CollapseControlled {...props} name={name} onChangeOpen={onChangeOpen} open={name === value} />
  )
}

export default Collapse
