import React, { useCallback, useEffect, useId, useRef } from 'react'
import { ReactEditor, useSlate } from 'slate-react'

import {
  getEmployees,
  getMentionIndex,
  getMentionTarget,
  useAppTextSelector,
} from 'components/AppText/useAppTextSelector'
import { LayoutScroll } from 'components/LayoutPage'
import { ScrollRef } from 'components/LayoutPage/LayoutScroll/LayoutScroll'
import { useDropDownContext } from 'components/uiKit/Dropdown/context'
import { t } from 'services/Translation'

import s from './MentionForm.module.scss'
import MentionItem from './MentionItem'

const GAP = 4

const calcTop = (mention: DOMRect, box: DOMRect) => {
  let top = mention.top - box.height - GAP
  if (top < 0) {
    top = mention.bottom + GAP
  }
  if (top + box.height > window.innerHeight) {
    top = window.innerHeight - box.height - GAP
  }
  return top
}

const calcLeft = (mention: DOMRect, box: DOMRect) => {
  let left = mention.left
  if (left + box.width > window.innerWidth) {
    left = mention.right - box.width
  }
  if (left < 0) {
    left = 0
  }
  return left
}

const MentionForm = React.forwardRef<HTMLDivElement>(({}, outerRef) => {
  const id = useId()
  const { parentContext } = useDropDownContext()
  const ref = outerRef as React.MutableRefObject<HTMLDivElement>
  const scrollRef = useRef<ScrollRef>(null)
  const editor = useSlate()
  const employees = useAppTextSelector(getEmployees)
  const mentionIndex = useAppTextSelector(getMentionIndex)
  const mention = useAppTextSelector(getMentionTarget)

  const update = useCallback(() => {
    const node = mention && ReactEditor.toDOMRange(editor, mention)?.getBoundingClientRect()
    if (ref.current && node) {
      const dropdown = ref.current.getBoundingClientRect()
      ref.current.style.top = `${calcTop(node, dropdown)}px`
      ref.current.style.left = `${calcLeft(node, dropdown)}px`
    }
  }, [editor, mention])

  useEffect(() => {
    update()
    document.addEventListener('scroll', update, true)
    return () => {
      document.removeEventListener('scroll', update, true)
    }
  }, [update])

  useEffect(() => {
    ref.current && parentContext?.registerSubPopup(id, ref.current)
  })

  return (
    <div className={s.root} ref={ref}>
      <LayoutScroll ref={scrollRef} sizeAutoCapable>
        {employees.map((employee, index) => (
          <MentionItem
            active={index === mentionIndex}
            employee={employee}
            key={employee.id}
            scrollRef={scrollRef}
          />
        ))}
        {employees.length === 0 && <div className={s.empty}>{t('page.user.notFound')}</div>}
      </LayoutScroll>
    </div>
  )
})

MentionForm.displayName = 'MentionForm'

export default MentionForm
