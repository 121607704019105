"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const constants_1 = require("../../../elements/elements/Rectangle/constants");
const theme_1 = require("../../theme");
const genStyle_1 = require("../genStyle");
const styleSchema = {
    rectangle: {
        backgroundColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.accentBackgroundColor, form: ['edit', 'fill'] }),
        borderRadius: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.borderRadius }),
    },
    border: {
        borderStyle: (0, genStyle_1.genStyle)({ value: 'none' }),
        borderColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.borderColor, form: ['edit', 'fill'] }),
        borderWidth: (0, genStyle_1.genStyle)({ value: 1 }),
    },
};
exports.default = { [constants_1.TYPE]: { style: styleSchema, font: {} } };
