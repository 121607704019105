"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.INLINE_ELEMENTS = exports.LIST_ELEMENTS = void 0;
const types_1 = require("../types");
exports.LIST_ELEMENTS = [types_1.SlateElementType.numberedList, types_1.SlateElementType.bulletedList];
exports.INLINE_ELEMENTS = [
    types_1.SlateElementType.link,
    types_1.SlateElementType.crossLink,
    types_1.SlateElementType.annotation,
    types_1.SlateElementType.code,
    types_1.SlateElementType.mention,
];
