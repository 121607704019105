import cn from 'classnames'
import React from 'react'

import s from './LayoutMain.module.scss'

interface ILayoutMain {
  size?: 'mini' | 'medium' | 'large'
  children?: React.ReactNode
}

const LayoutMain: React.FC<ILayoutMain> = ({ children, size = 'large' }) => {
  const cnRoot = cn(s.root, s[size])
  return <main className={cnRoot}>{children}</main>
}

export default LayoutMain
