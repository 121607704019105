import { SidebarFontSchemaType, SidebarSchemaType } from '@leenda/editor/lib/brand'

import { ElementFontCss, ElementStyleCss } from 'services/Branding/types'
import { Section } from 'services/Store/Project/types'

import { SectionCourseItem } from '../SectionCourseItem'
import s from './CourseSearchResult.module.scss'

interface ICourseSearchResultProps {
  searchValue: string
  onSelect?: (section: Section) => void
  resultSections: Section[]
  styles: ElementStyleCss<SidebarSchemaType>
  font: ElementFontCss<SidebarFontSchemaType>
  sectionId?: string
}

const CourseSearchResult = ({
  resultSections,
  searchValue,
  onSelect,
  styles,
  sectionId,
  font,
}: ICourseSearchResultProps) => (
  <div className={s.root}>
    {resultSections.map((item) => (
      <SectionCourseItem
        font={font}
        item={item}
        key={item.id}
        onSelect={onSelect}
        searchValue={searchValue}
        sectionId={sectionId}
        styles={styles}
      />
    ))}
  </div>
)

export default CourseSearchResult
