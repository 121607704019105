import cn from 'classnames'

import { AppMode } from 'services/Store/Project/enums'
import { useProjectContext } from 'services/Store/Project/hooks'
import { getEditorMode } from 'services/Store/Project/selectors'

import s from './Padding.module.scss'

interface IPaddingProps {
  sides?: boolean
  top?: boolean
  gap?: boolean
  bottom?: boolean
  bottomSpace?: boolean
  children: React.ReactNode
}

const Padding: React.FC<IPaddingProps> = ({ children, sides, top, gap, bottom, bottomSpace }) => {
  const isPro = useProjectContext(getEditorMode) === AppMode.pro
  return (
    <div
      className={cn(s.root, {
        [s.gap]: gap,
        [s.top]: top,
        [s.side]: sides,
        [s.bottom]: bottom,
        [s.bottomSpace]: isPro && bottomSpace,
      })}
    >
      {children}
    </div>
  )
}

export default Padding
