import { PermissionsKey } from '@leenda/crud'
import React, { useMemo } from 'react'
import { useParams } from 'react-router'

import Form from 'components/controls/Form'
import FormControls from 'components/controls/FormControls'
import FormField from 'components/controls/FormField'
import Divider from 'components/uiKit/Divider'
import { KitSize } from 'components/uiKit/KitTypes'
import Modal, { MCWithParams, ModalBody, ModalFooter } from 'components/uiKit/Modal'
import { notify } from 'components/uiKit/Notification'
import { NotificationType } from 'components/uiKit/Notification/types'
import { useTemplatesCreate, useTemplatesGetGroup } from 'gql/templates.apollo'
import { usePermissions } from 'services/Permission/PermissionContext'
import { SectionTypeEnum } from 'services/Store/Project/enums'
import { Block } from 'services/Store/Project/types'
import { t } from 'services/Translation'
import { CompanyParams } from 'store/models'

import { nameField, createdForField, tagsField, InputErrorValues } from './fieldConfigs'

interface ITemplateSaveValues {
  name: string
  tags?: string[]
  editorTemplateGroupId?: string
}

interface IParams {
  block: Block
  sectionType: SectionTypeEnum
}

const TemplateSaveModal: MCWithParams<IParams> = ({ onClose, params: { block, sectionType } }) => {
  const { projectId, companyId } = useParams<CompanyParams & { projectId: string }>()

  const permissions = usePermissions()
  const allowedCollectionTypes = ['project']
  if (permissions.has(PermissionsKey.company_collections_company_u)) {
    allowedCollectionTypes.push('company')
  }
  if (permissions.has(PermissionsKey.company_collections_self)) {
    allowedCollectionTypes.push('employee')
  }

  const { data } = useTemplatesGetGroup({ companyId, data: { projectId } })

  const collections = data?.data?.templateCollections && [
    ...data?.data?.templateCollections
      .filter((collection) => allowedCollectionTypes.includes(collection.createdFor))
      .map((group) => ({
        value: group.id,
        label: group.name,
      })),
  ]

  const initialFormValues: ITemplateSaveValues = useMemo(
    () => ({
      name: block?.name || '',
      editorTemplateGroupId: '',
      ...(sectionType === 'test' ? { tags: [block?.mode] } : {}),
    }),
    [block],
  )

  const [createTemplate] = useTemplatesCreate()

  const onFinish = async (values: ITemplateSaveValues) => {
    if (!block) {
      return {}
    }

    if (values?.editorTemplateGroupId) {
      const { data } = await createTemplate({
        variables: {
          data: {
            projectId,
            name: values?.name,
            tags: values?.tags || [],
            blockUuid: block?.uuid,
            editorTemplateGroupId: values?.editorTemplateGroupId,
          },
        },
      })
      if (data?.data.data) {
        notify({ type: NotificationType.success, message: t('notify.templateSaved') })
        onClose && onClose(!!values)
      }
      return data
    }
    return
  }

  return (
    <Form
      initialValues={initialFormValues}
      inputErrorValues={InputErrorValues}
      name='createTemplate'
      onReset={onClose}
      onSubmit={onFinish}
    >
      <Modal name='saveTemplate' title={t('modal.saveTemplate.title')}>
        <ModalBody sizeAutoCapable>
          <FormField config={nameField} />
          <FormField config={createdForField(collections || [])} />
          <Divider size={KitSize.L} />
          <FormField config={tagsField({ sectionType, mode: block.mode })} />
        </ModalBody>
        <ModalFooter>
          <FormControls justify='right' />
        </ModalFooter>
      </Modal>
    </Form>
  )
}

export default TemplateSaveModal
