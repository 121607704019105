import { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'

const PortalContent: React.FC<{ id: string; children?: React.ReactNode }> = ({ id, children }) => {
  const [portal, setPortal] = useState<HTMLElement | null>()

  useEffect(() => {
    let count = 0
    const interval = setInterval(() => {
      const portal = document.getElementById(id)
      count++
      if (portal) {
        setPortal(portal)
        clearInterval(interval)
      }
      if (count > 20) {
        clearInterval(interval)
      }
    }, 100)
    return () => clearInterval(interval)
  }, [id])

  return portal ? ReactDOM.createPortal(children, portal) : null
}

export default PortalContent
