import Breadcrumbs from './Breadcrumbs'

interface IBreadcrumbsTitleProps {
  title: string
}

const BreadcrumbsTitle: React.VFC<IBreadcrumbsTitleProps> = ({ title }) => {
  return <Breadcrumbs items={[{ name: title }]} />
}

export default BreadcrumbsTitle
