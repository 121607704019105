import { Editor, Range, Transforms } from 'slate'

import { CustomEditor } from '../RichTextTypes'

export const collapseSelectionToEnd = (editor: CustomEditor) => {
  const { selection } = editor

  if (!selection) {
    return
  }

  const range = Range.isCollapsed(selection) ? selection : Editor.range(editor, selection)
  const end = Editor.end(editor, range)
  Transforms.select(editor, end)
}
