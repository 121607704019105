import { useSize, useInViewport, useUpdateEffect } from 'ahooks'
import lodash from 'lodash'
import React, { useEffect, useMemo, useRef, useState } from 'react'

import Loader from 'components/uiKit/Loader'
import { IMenuOption } from 'components/uiKit/Menu'
import { NOOP } from 'constants/commonConstans'
import {
  EditorTemplateAllTab,
  EmployeePreferencesTab,
  TemplatesListSchemaFragment,
} from 'gql/__generated__/graphql'
import { TemplateActionsUiEnum } from 'routes/routes/App/routes/Company/routes/Templates/components/enum'
import { extendTestData } from 'utils/test/qaData'

import { TemplatePickerMode } from '..'
import CellRendererBlock from '../CellRendererBlock'
import s from './MasonaryTemplate.module.scss'

const DEFAULT_TEMPLATE_HEIGHT = 150
const SPACER = 10
const TEMPLATE_MIN_WIDTH = 298
const BOX_SHADOW = 20

export interface IItemProps {
  selected: TemplatesListSchemaFragment[]
  onClick: (value: TemplatesListSchemaFragment, e: React.MouseEvent) => void
  removeByIndex: (value: number, e: React.MouseEvent) => void
  tab: EmployeePreferencesTab | EditorTemplateAllTab
  search?: string
  companyId: string
  projectId?: string
}

interface IMasonryTemplateProps {
  items: TemplatesListSchemaFragment[]
  itemProps: IItemProps
  mode: TemplatePickerMode
  actions?: IMenuOption<TemplateActionsUiEnum>[]
  onClickActions?: (value: string, uuid: string) => void
  showFavorite?: boolean
  columns?: number
  fetchMore?: () => void
}

const cache: { [key: string]: number } = {}

let lastConfig: MasonryConfig = {
  columns: 3,
  columnWidth: TEMPLATE_MIN_WIDTH,
  xGap: SPACER,
  yGap: 40,
}

const calculateColumns = (containerWidth: number, columnWidth: number, xGap: number) => {
  const availableWidth = containerWidth + xGap
  return Math.floor(availableWidth / (columnWidth + xGap))
}

type RefData = {
  items: string[]
  recalculate: () => void
  setHeight: (key: string, height: number) => void
  config: MasonryConfig
}

type ItemPosition = {
  key: string
  style: {
    left: number
    top: number
    height: number
    width: number
  }
}

type MasonryConfig = {
  columns: number
  columnWidth: number
  xGap: number
  yGap: number
}

const useMasonry = (items: string[], configParam: MasonryConfig) => {
  const renderData = useRef<RefData>({
    items: items || [],
    recalculate: NOOP,
    config: configParam,
    setHeight: (key: string, height: number) => {
      cache[key] = height
      renderData.current.recalculate()
    },
  })
  const [positions, setPositions] = useState<ItemPosition[]>([])
  const result = renderData.current
  const getHeight = (key: string) => cache[key] || DEFAULT_TEMPLATE_HEIGHT

  useEffect(() => {
    result.config = configParam
    const recalculateForce = () => {
      const columns = Array.from(Array(result.config.columns), (_, i) => ({
        keys: [] as string[],
        index: i,
        height: 0,
      }))
      const positions = []

      for (const item of items) {
        const height = getHeight(item) + BOX_SHADOW
        const column = columns.reduceRight((prev, curr) =>
          prev.height < curr.height ? prev : curr,
        )
        column.keys.push(item)
        positions.push({
          key: item,
          style: {
            left: column.index * (result.config.columnWidth + result.config.xGap),
            top: column.height,
            height,
            width: result.config.columnWidth,
          },
        })
        column.height += height + result.config.yGap
      }
      setPositions(positions)
    }
    result.recalculate = lodash.debounce(() => recalculateForce(), 500)
    if (items.length > 0) {
      result.recalculate()
    }
  }, [items, configParam])

  return { positions, setHeight: result.setHeight }
}

const MasonryTemplate: React.FC<IMasonryTemplateProps> = ({
  items,
  itemProps,
  mode,
  actions,
  onClickActions,
  showFavorite,
  columns = 3,
  fetchMore,
}) => {
  const keys = useMemo(() => items.map((t) => t.uuid), [items])
  const [rootRef, setRootRef] = useState<HTMLDivElement | null>(null)
  const size = useSize(rootRef)
  const [config, setConfig] = useState<MasonryConfig>(lastConfig)
  const ref = useRef<any>(null)
  const [inViewport] = useInViewport(ref)

  const fakeSize = fetchMore ? 100 : 0

  useEffect(() => {
    if (size?.width) {
      const config = {
        columns,
        columnWidth: TEMPLATE_MIN_WIDTH,
        xGap: SPACER,
        yGap: 10,
      }
      const baseWidth = size.width
      if (columns === 0) {
        config.columns = calculateColumns(size?.width || 0, TEMPLATE_MIN_WIDTH, SPACER)
      }
      config.columnWidth = Math.floor(
        (baseWidth - config.xGap * (config.columns - 1)) / config.columns,
      )

      lastConfig = config
      setConfig(config)
    }
  }, [size?.width, columns])

  const { positions, setHeight } = useMasonry(keys, config)
  const height = positions.reduce(
    (acc, position) => Math.max(acc, position.style.top + position.style.height),
    0,
  )
  const renderItems = Boolean(size?.width && items.length)

  useUpdateEffect(() => {
    if (inViewport && fetchMore) {
      fetchMore()
    }
  }, [inViewport])

  return (
    <div
      className={s.root}
      ref={setRootRef}
      style={{ height: items.length ? height + fakeSize : 'auto' }}
    >
      {renderItems &&
        positions.map(({ key, style }, index) => {
          const item = items.find((t) => t.uuid === key)
          if (!item) {
            return null
          }

          return (
            <div
              className={s.item}
              key={key}
              onClick={(e: React.MouseEvent) => itemProps.onClick(item, e)}
              ref={index === positions.length - 1 ? ref : undefined}
              style={style}
            >
              <CellRendererBlock
                actions={actions}
                index={index}
                mode={mode}
                {...itemProps}
                key={key}
                onClickActions={onClickActions}
                onHeight={setHeight}
                showFavorite={showFavorite}
                template={item}
                testData={extendTestData({ label: item.name, value: item.uuid })}
                width={config.columnWidth}
              />
            </div>
          )
        })}
      {fetchMore && inViewport !== undefined && (
        <div className={s.loaderRow} style={{ top: height }}>
          <Loader name='dataFallbackLoader' />
        </div>
      )}
    </div>
  )
}

export default React.memo(MasonryTemplate)
