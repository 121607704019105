"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TYPE = void 0;
const constants_1 = require("../../../elements/elements/TestMatching/constants");
Object.defineProperty(exports, "TYPE", { enumerable: true, get: function () { return constants_1.TYPE; } });
const font_1 = require("../../font");
const theme_1 = require("../../theme");
const genStyle_1 = require("../genStyle");
const styleSchema = {
    pair: {
        backgroundColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.primaryBackgroundColor }),
        borderRadius: (0, genStyle_1.genStyle)({ value: 12 }),
    },
    selected__var: {
        backgroundColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.neutralColor }),
    },
    border: {
        borderColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.borderColor }),
        borderWidth: (0, genStyle_1.genStyle)({ value: 1 }),
    },
    focusColor__var: {
        borderColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.focusedColor }),
    },
    icon: {
        backgroundImage: (0, genStyle_1.genStyle)({ value: null }),
    },
    mobileIcon: {
        backgroundImage: (0, genStyle_1.genStyle)({ value: null }),
    },
    indents: {
        padding: (0, genStyle_1.genStyle)({ value: [16, 16, 16, 12] }),
        marginRight: (0, genStyle_1.genStyle)({ value: 12 }),
        marginBottom: (0, genStyle_1.genStyle)({ value: 8 }),
    },
};
const fontSchema = Object.assign({}, font_1.FONT_SCHEMA_EMPTY);
exports.default = { [constants_1.TYPE]: { style: styleSchema, font: fontSchema } };
